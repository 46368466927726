/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.directive('disableElement', ["$rootScope","$compile", function ($rootScope, $compile) {
    return {

        scope: { setFn: '&' },
        link: function(scope, element, attrs) {
            scope.updateMap = function() {
                //attrs.$set('tenancyAgreementCondition1', disabled);
                //$compile(elem)(scope);
                alert('inside updateMap()');
            }
            scope.setFn({theDirFn: scope.updateMap});
        }


    }
}]);