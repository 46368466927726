/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("jobSearchCtrl", ["$scope", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "apiService", function ($scope, $http, $rootScope, $q, $state, postUserActivityService, apiService) {

    console.log("inside controller jobSearchCtrl");

    console.log("Clouderty administrator:" + $rootScope.decodedToken.Clouderty_System_Administrator);
    console.log("Rootscope user level: " + $rootScope.decodedToken.User_Level);
    console.log("Rootscope account holder ID: " + $rootScope.decodedToken.Account_Holder_ID);

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.currentPage = 1;
    $scope.filename = "test" + ".csv";
    $scope.paginationData = [];
    $scope.progress = {};
    $scope.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

    $scope.tenancies = [];
    $scope.tenanciesExport = [];
    $scope.tenanciesExportHeaders = [];
    $scope.properties = [];
    $scope.properties.User = null;
    $scope.properties.User_ID = 0;
    $scope.propertiesSelect = [];
    $scope.propertiesData = {};
    $scope.propertiesData.User_ID = "";
    $scope.propertiesAll = {};

    $scope.search = "";
    $scope.searchParam ="";

    $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.tenanciesExport2 = [];

    $scope.obj = {};
    $scope.jobs = {};

    $scope.status = 0;

    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end

    //////////////////////////  Active Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )

        return q55.promise;

    }

    $scope.jobsFunction = function(id, status) {
        console.log("function jobsFunction");

        var qjobsFunction = $q.defer();

        //$scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        //$scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.obj.itemsPerPage = $scope.itemsPerPage;
        $scope.obj.pageno = $scope.pageno;
        $scope.obj.Task_ID = id;
        $scope.obj.Status = status;

        if($scope.obj.Task_ID){

            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.obj.Task_ID = "*";
        }


        if($scope.search){

            $scope.obj.search = $scope.search;
        }else{

            $scope.obj.search = "*";
        }



        apiService.jobs(id, $scope.obj).then(
            function (response) {

                $scope.tasks = response.data.data;

                $scope.jobs = response.data.data;
                $scope.paginationData = response.data.data;
                $scope.$parent.obj = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.totalTaskCount = response.data.totalCount;

                console.log("tasks API");
                qjobsFunction.resolve(response);

            },
            function () {
                console.log("API Service Error: tasks")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qjobsFunction.promise;

    }

    $scope.getData = function(pageno) {

        console.log("function getData");

        var qgetData = $q.defer();
        $scope.pageno = pageno;
        $scope.searchParam = $scope.search;




        qgetData.resolve($scope.jobsFunction('*', $scope.status));

        return qgetData.promise;
    }

    $scope.loadDataThenMap = function() {

        console.log("C1 loadDataThenMap");
        var q = $q.defer();
        $scope.myValue = 0;
        $scope.status = 0;
        $scope.promise = $scope.getData(1);

        $scope.promise
            .then(function (data) {

                console.log("A2 loadDataThenMap");

                //return $scope.usersViewAll();
                return $scope.getGlobalPromise();
            })
            .then(function (data) {

                console.log("A2 loadDataThenMap");

                //return $scope.estatesViewAll();
                return $scope.getGlobalPromise();
            })
            .then(function (data) {

                console.log("A2 loadDataThenMap");


                return $scope.showMapMultipleMarkersDetails();
            })
            .then(function (data) {

                console.log("A2 loadDataThenMap");

                //return $scope.propertiesViewAll();
                return $scope.getGlobalPromise();
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $http.get("api/tests-googlemap-locations/").success(function (data) {
        $scope.markers = [];
        $scope.data = data;
    })

    $scope.showMapMultipleMarkers = function () {

        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();

    }

    $scope.showMapMultipleMarkersDetails = function () {

        $scope.markers = [];

        geocoder = new google.maps.Geocoder();
        geocoderCentre = new google.maps.Geocoder();

        $scope.jobs.forEach(function (item) {
            geocoder.geocode({
                'address': item['Postcode']
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    $scope.lat = results[0].geometry.location.lat();
                    $scope.long = results[0].geometry.location.lng();
                }
                var i = 0;
                //console.log("array: " + i)
                $scope.markers.push({
                    id: Date.now() + i,
                    coords: {
                        latitude: $scope.lat,
                        longitude: $scope.long

                    },
                    "show": false,
                    closeClick: 'none',
                    "sessions": item['area']
                });
                $scope.$evalAsync();
                //console.log("array: " + i)
                i++;
            });

        });
        console.log("resultsCentre3: " + $scope.postcodeCentre);
        $scope.widget = {
            "map": {
                "center": {
                    "latitude": 51.8846964,
                    "longitude": 0.9216240000000653
                    //"position": $scope.postcodeCentre

                },
                "zoom": 12,
                "options": {
                    "minZoom": 3,
                    "scrollwheel": true
                }
            }
        }

    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.loadDataThenMap();

    //////////////////////////  Old Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getManager = function(managerUserID) {

        $scope.properties = [];
        console.log("function getManager");
        console.log(managerUserID);
        console.log("manager.User_ID: " + managerUserID);
        $scope.propertiesData.User_ID = managerUserID;
        $scope.status = 91;
        $scope.getData(1);
    }

    $scope.getProperty = function(propertyID) {

        $scope.properties = [];
        console.log("function getProperties");
        console.log(propertyID);
        $scope.propertiesData.Property_ID = propertyID;
        $scope.status = 92;
        $scope.getData(1);
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(progress == "EXPIRED TENANCIES") {
            //$scope.progress.date_completed = date;
            console.log("Expired Tenancies");
            $scope.getDataExpired(1)
        } else if (progress == "EXPIRED TENANCIES WITHIN 1 MONTH") {
            console.log("Expired Tenancies within 1 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredOneMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 2 MONTH") {
            console.log("Expired Tenancies within 2 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredTwoMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData(1)

        } else{

        }
 
    }

    $scope.actionUpdateTenancy = function (action, tenantID) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "EDIT") {
            //$scope.progress.date_completed = date;
            console.log("Edit Tenancy");
            $scope.editTenant(tenantID)
        } else if (action == "EXPIRED TENANCIES WITHIN 1 MONTH") {
            console.log("Expired Tenancies within 1 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredOneMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 2 MONTH") {
            console.log("Expired Tenancies within 2 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredTwoMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (action == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData(1)

        } else{

        }

    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

}]);