/**
 * Created by Aaron on 14/07/2016.
 */

clouderty.factory('emailService', ["$window", function($window) {
    return{

        openEmailSender: function(emailId, subject, message, property){


            if (property == null) {
                $window.open("mailto:" + emailId + "?subject=" + subject + "&body=" + message, "_self");
            }
            else {
                $window.open("mailto:" + emailId + "?subject=" + property + ' - ' + subject + "&body=" + message, "_self");
            }

            return 0;
        }

    };

}]);
