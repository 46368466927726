/**
 * Created by Cyril on 31/07/2016.
 */

clouderty.controller("taskAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$q", "apiService", "$uibModal", "dateInputService", "moment", "truncateSliceService", "twillioService", "$timeout", function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $q, apiService, $uibModal, dateInputService, moment, truncateSliceService, twillioService, $timeout) {

    $scope.obj = {};
    $scope.obj.taskCategories = [];
    $scope.obj.vendor_id = "";
    $scope.obj.description = "";
    $scope.obj.company_name = "";
    $scope.obj.Department = "";
    $scope.obj.contact_name = "";
    $scope.obj.Mobile_Number = "";
    $scope.obj.message = "";
    $scope.obj.Address = "";
    $scope.taskCategories = [];
    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.vendorTasks = [];
    $scope.obj.selectedVendors = [];
    $scope.vendorData = {};
    $scope.vendors = {};
    $scope.vendorObj = [];

    //$scope.vendor = {};
    $scope.units = {};

    // Relating to promises - Step 2 ///////////////// START
    var canceller, isSending = false;
    // Relating to promises - Step 2 ///////////////// START

    $('.data').hide();
    $('.accordion-1-form').show();
    // $('.project-select').select2();

    $http.get('api/projects').success(function (response) {
        $scope.projects = response;
    })

    $scope.New_Record = false;
    $scope.hidePropertySearch = false;

    if ($stateParams.id) {
        $scope.New_Record = true;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Accordion
    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function (element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.' + element_data).is(":hidden")) {
            $('.' + element).removeClass("fa-chevron-left");
            $('.' + element).addClass("fa-chevron-down");
            $('.' + element_data).slideToggle("fast");
        }
        else if ($('.' + element_data).is(":visible")) {
            $('.' + element).removeClass("fa-chevron-down");
            $('.' + element).addClass("fa-chevron-left");
        }
    }
    // Accordion End
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    $scope.searchProperty = function () {
        $scope.properties = null;
        console.log("searchProperty in");

        console.log("$scope.searchProperties");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.properties = response.data;
                console.log($scope.paymentTransactionCategories);
            },
            function () {
                console.log("There was an error")
            }
        )

    }

    $scope.getPaymentCategories = function () {

        console.log("Before getTaskCategories:" + $scope.taskCategories);

        $scope.obj.Status = 13;
        $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

        $scope.obj.itemsPerPage = $scope.itemsPerPage;
        $scope.obj.pageno = $scope.pageno;
        $scope.obj.search = "*";


        apiService.paymentTransactions('*',$scope.obj).then(
            function (response) {

                $scope.taskCategories = response.data.data;
                console.log("$scope.obj.Task_Category_ID:" + $scope.obj.Task_Category_ID);

            },
            function () {
                console.log("API Service: getTaskCategories")
            }
        )


    }


    /*
        apiService.getTaskCategories().then(
        function (response) {

            $scope.taskCategories = response.data.data;
            console.log("$scope.obj.Task_Category_ID:" + $scope.obj.Task_Category_ID);

        },
        function () {
            console.log("API Service: getTaskCategories")
        }
    )
     */

    $scope.getTaskCategoryID = function () {

        $scope.obj.Task_Category_ID = $scope.obj.taskCategories.Task_Category_ID;
        console.log("$scope.obj.Task_Category_ID:" + $scope.obj.Task_Category_ID);

    }

    $scope.obj.selectedProperties = [];

    $scope.obj.selectedProperties.Units = [];

    $scope.selectProperty = function (id) {
        console.log("selectProperty in " + id);
        apiService.getProperty(id, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.obj.selectedProperties.push({
                    "Property_ID": response.data.data.Property_ID,
                    "Address": response.data.data.Address_1,
                    "Town": response.data.data.Town,
                    "City": response.data.data.City,
                    "Postcode": response.data.data.Postcode
                })
                $scope.obj.Address_1 = response.data.data.Address_1;
                $scope.obj.Property_name = response.data.data.Property_name;
                $scope.obj.Postcode = response.data.data.Postcode;
                $scope.obj.Unit_number = response.data.data.Unit_number;
                $scope.obj.Unit_ID = response.data.data.Unit_ID;
                $scope.obj.Property_ID = response.data.data.Property_ID;
                angular.forEach(response.Units, function (unit, index) {
                    $scope.obj.selectedProperties.Units.push({
                        "Unit_ID": unit.Unit_ID,
                        "Unit_Number": unit.Unit_number
                    })
                })
                $scope.units = response.data.units;
                console.log('$scope.obj.selectedProperties.Units');
                console.log($scope.obj.selectedProperties.Units);

                console.log('units');
                console.log($scope.units);
            },
            function () {
                console.log("There was an error in getProperties")
            }
        )

        $scope.search_property = "";
        $scope.hidePropertySearch = true;
    }

    $scope.selectUnit = function (unitNumber) {
        console.log("selectProperty in " + unitNumber);
        console.log("function selectProperty on unitNumber: " + unitNumber);
        $scope.obj.Unit_number = unitNumber;
        $scope.obj.Unit_ID = unitNumber;
        $scope.Unit_ID = unitNumber;
        $scope.search_property = "";
        $scope.hidePropertySearch = true;
    }

    $scope.selectNumber = function(unitNumber) {

        console.log("function selectNumber on unitNumber" + unitNumber);

    }

    $scope.deselectProperty = function (index) {
        $scope.obj.selectedProperties.splice(index, 1);
        $scope.hidePropertySearch = false;
    }

    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Add Vendor    
    $scope.searchVendors = function() {
        $scope.vendors = null;
        $http.get('api/vendors/search/' + $scope.search_vendor).success(function(response) {
            $scope.vendors = response.data;
        })
    }

    $scope.getVendorsTasks = function(id) {
        $scope.vendorTasks = null;
        $http.get('api/vendors-tasks/' + id).success(function(response) {
            $scope.vendorTasks = response.data;
            console.log($scope.vendorTasks);
        })
    }

    $scope.obj.selectedVendorTasks = [];

    $scope.selectVendorTasks = function(id) {
        if(confirm("Do you want to add a vendor?")){
            //$scope.obj.selectedVendors = [];

            $http.post("api/vendors-tasks-add/" + $scope.obj.Task_ID + "/" + id).success(function (response) {

                $scope.vendorTasks = response.data;
                console.log($scope.vendorTasks);

                if($scope.obj.Mobile_Number){

                    if($scope.obj.SMS_Checkbox == 'true'){

                        console.log("sendTwillioSMS to: " + $scope.obj.Mobile_Number);
                        $scope.obj.Mobile_Number = $scope.obj.Mobile_Number.replace(/[\s]/g, '');
                        console.log("sendTwillioSMS to: " + $scope.obj.Mobile_Number);
                        var data = new FormData();
                        var number = $scope.obj.Mobile_Number;

                        //test mobile numbers
                        /*
                        if($rootScope.decodedToken.Account_Holder_ID == 1){
                            var number = "07949440089";
                            //var number = "07828601409";
                        }
                        else if($rootScope.decodedToken.Account_Holder_ID == 37){
                            var number = "07949440089";
                            //var number = "07828601409";
                        }

*/
                        //var message = $scope.message;

                        data.append("number" , number);
                        data.append("message" , $scope.obj.message);
                        data.append('method', "sendSMS");

                        twillioService.sendMessage(data,function(res,err) {
                            if(err){
                                flashService.Error(err,false);
                                resetForm();
                            }  else{
                                if(res.data.status=='success'){
                                    flashService.Success(res.data.message,false);
                                    resetForm();
                                    $("html, body").animate({ scrollTop: 0 }, "slow");
                                    $timeout( function(){
                                        $('.alert').html('');
                                        $('.alert').hide();
                                    }, 5000 );
                                }
                            }
                        });


                    }

                }

            })
        }else{

        }

        $scope.search_vendor = "";

    }

    $scope.selectVendor = function(id) {

        console.log("inside function selectVendor");
        console.log(id);


        $scope.obj.vendor_id = id;
        $scope.obj.Vendor_ID = id;

        /*
        $scope.obj.description = dataObj.description;
        $scope.obj.company_name = dataObj.company_name;
        $scope.obj.Department = dataObj.Department;
        $scope.obj.contact_name = dataObj.contact_name;
        $scope.obj.Mobile_Number = dataObj.Mobile_Number;
        console.log(dataObj);
        console.log("$scope.obj.Mobile_Number: " + $scope.obj.Mobile_Number);
        $scope.obj.Mobile_Number = '099808 700807';
        console.log("$scope.obj.Mobile_Number: " + $scope.obj.Mobile_Number);
        */
        $scope.vendorData.Vendor_ID = id;
        $scope.vendorData.Status = 5;

        $scope.vendorData.Task_Status_ID = 5;
        apiService.vendors("*", $scope.vendorData).then(
            function(response) {

                $scope.vendor = response.data.data;
                $scope.obj.Mobile_Number = $scope.vendor.Mobile_Number;
                //$scope.obj.Mobile_Number = "07949440089";
                $scope.obj.vendor_id = $scope.vendor.vendor_id;

                $scope.obj.Customer_ID = $scope.vendor.Customer_ID;
                console.log("API vendors: " );

                console.log($scope.vendors);
                console.log("$scope.obj.Mobile_Number: " + $scope.obj.Mobile_Number);

            },
            function() {
                console.log("We are in vendors");
            }
        )




    }

    $scope.deselectVendorm = function (index) {
        $scope.obj.selectedVendors.splice(index, 1);
    }
    $scope.deselectVendor = function (index) {
        $scope.obj.selectedVendors.splice(index, 1);
    }

    $scope.vendorsViewAll = function () {
        console.log("inside function vendorsViewAll");
        $scope.vendorData = {};
        $scope.vendorData.Status = 0;
        $scope.vendorData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

        apiService.vendors("*", $scope.vendorData).then(
            function(response) {

                $scope.vendors = response.data.data;
                console.log("API vendors: " );

                console.log($scope.vendors);
            },
            function() {
                console.log("We are in vendors");
            }
        )

    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Add Task

    $scope.tasks = function() {
        if ($stateParams.id == null) {

            if (confirm('Are you sure you want to add a task?')) {


                //$scope.addTask = function(property_address) {

                    if($scope.obj.taskCategories == null){

                        confirm('Please select a category');
                        $('.data').hide();
                        $('.accordion-1-form').show();
                        return "";
                    }

                    //if (confirm('Are you sure you want to create a task?')) {

                    var date = new Date();
                    date.setHours(date.getHours()+1);

                    //var v_property_address = property_address;
                    $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                    $scope.obj.User_ID = $rootScope.decodedToken.id;
                    $scope.obj.Date_started = date;
                    $scope.obj.Status = 8;
                    $scope.obj.Task_Status_ID = 8;
                    //$scope.note.id = $stateParams.id;

                    console.log('attempt to set time and date fields');

                    if($scope.obj.To_be_completed_by){
                        $scope.obj.To_be_completed_by = dateInputService.dateInputDayService($scope.obj.To_be_completed_by, 0);
                        $scope.obj.To_be_completed_by2 = $filter('date')($scope.obj.To_be_completed_by, "dd-MM-yyyy");
                    }

                    if($scope.obj.To_be_completed_by_time){
                        //$scope.obj.To_be_completed_by_time = moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate();
                        console.log(' $scope.obj.To_be_completed_by_time' +  $scope.obj.To_be_completed_by_time);

                        //console.log(' $scope.obj.To_be_completed_by_time' +  $filter('date')($scope.obj.To_be_completed_by_time, "HH:mm"));
                        $scope.obj.To_be_completed_by_time2 = $filter('date')($scope.obj.To_be_completed_by_time, "HH:mm");
                        //console.log(' $scope.obj.To_be_completed_by_time' +   dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 0));
                        //console.log(' $scope.obj.To_be_completed_by_time' +  dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 1));
                        //console.log(' $scope.obj.To_be_completed_by_time' +  moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate());
                        //$scope.obj.To_be_completed_by_time = dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 0);
                        //$scope.obj.To_be_completed_by_time = $filter('date')($scope.obj.To_be_completed_by_time, "HH:mm");
                        $scope.obj.To_be_completed_by_time = dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 1);
                        //$scope.obj.To_be_completed_by_time =  moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate();

                        console.log(' $scope.obj.To_be_completed_by_time' +  $scope.obj.To_be_completed_by_time);
                    }

                    console.log('add task now');
                    $http.post('api/task', $scope.obj).success(function(response) {

                        $scope.obj = response;

                        $scope.obj.Date_started = $filter('date')($scope.obj.Date_started, 'dd/MM/yyyy hh:mm:ss');

                        $scope.obj.DescriptionTruncate = truncateSliceService.truncateSliceService($scope.obj.Description, 15);

                        if(!$scope.obj.Address_1){
                            var descriptionNotification = $scope.obj.Description;
                            $scope.obj.Address_1_Email = "";
                            console.log("$scope.obj.Address_1_Email is empty");
                        }else{
                            var descriptionNotification = $scope.obj.Address_1 + " - " + $scope.obj.Description;
                            $scope.obj.Address_1_Email = " | " + $scope.obj.Address_1 + " | ";
                            console.log("$scope.obj.Address_1_Email: " + $scope.obj.Address_1_Email);
                        }

                        var urlNotification = $rootScope.cloudertyBaseURL + "lettings/tasks/view-task//task/" + $scope.obj.Task_ID;
                        $rootScope.saveNotification(8, descriptionNotification, urlNotification);


                        if($scope.obj.Address_1){
                            $scope.obj.Address = $scope.obj.Address_1
                        }else{

                            $scope.obj.Address = $scope.obj.Address_1

                        }

                        /*
                        if($scope.obj.To_be_completed_by_time){

                            $scope.obj.To_be_completed_by_time =  moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate();
                            //$filter('date')($scope.obj.To_be_completed_by_time, format || 'h:mm');

                        }*/

                        if($scope.vendor.First_Name){

                            var taskAssignedTo = $scope.vendor.First_Name + " - " + $scope.vendor.company_name;
                        }else{

                            var taskAssignedTo = "No vendor assigned";
                        }
                        if($scope.obj.To_be_completed_by_time){
                            console.log(' $scope.obj.To_be_completed_by_time' +  $scope.obj.To_be_completed_by_time);
                            console.log(' $scope.obj.To_be_completed_by_time' +   dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 0));
                            console.log(' $scope.obj.To_be_completed_by_time' +  dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 1));
                            console.log(' $scope.obj.To_be_completed_by_time' +  moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate());
                            //$scope.obj.To_be_completed_by_time =  moment($scope.obj.To_be_completed_by_time, "HH:mm").toDate();

                            //$scope.obj.To_be_completed_by_time = dateInputService.timeInputService($scope.obj.To_be_completed_by_time, 1);
                            console.log(' $scope.obj.To_be_completed_by_time' +  $scope.obj.To_be_completed_by_time);
                        }

                        var from_email = "Clouderty | Task Created " + $scope.obj.Task_ID + $scope.obj.Address_1_Email + "<notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = "Created by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
                        var message = "Task ID: " + $scope.obj.Task_ID + "\n\n" +
                            "Created By: " + $rootScope.decodedToken.Name + "\n\n" +
                            "Job assigned to: " + taskAssignedTo + "\n\n" +
                            "Date to be completed by: " + $scope.obj.To_be_completed_by2 + "\n\n" +
                            "Time to be completed by: " + $scope.obj.To_be_completed_by_time2 + "\n\n" +
                            "Address: "  + $scope.obj.Address + "\n\n" +
                            "Unit: " + $scope.obj.Unit_number + "\n\n" +
                            "Task Title: " +  $scope.obj.Description  + "\n\n" +
                            "Task Description: " +  $scope.obj.Detailed_Description;

                        $scope.obj.message = message;

                        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);

                        if($scope.obj.Mobile_Number){

                            if($scope.obj.SMS_Checkbox == 'true'){

                                console.log("sendTwillioSMS to: " + $scope.obj.Mobile_Number);
                                $scope.obj.Mobile_Number = $scope.obj.Mobile_Number.replace(/[\s]/g, '');
                                console.log("sendTwillioSMS to: " + $scope.obj.Mobile_Number);
                                var data = new FormData();
                                var number = $scope.obj.Mobile_Number;

                                //test mobile numbers
                                /*
                                if($rootScope.decodedToken.Account_Holder_ID == 1){
                                    var number = "07949440089";
                                    //var number = "07828601409";
                                }
                                else if($rootScope.decodedToken.Account_Holder_ID == 37){
                                    var number = "07949440089";
                                    //var number = "07828601409";
                                }

        */
                                //var message = $scope.message;

                                data.append("number" , number);
                                data.append("message" , $scope.obj.message);
                                data.append('method', "sendSMS");

                                twillioService.sendMessage(data,function(res,err) {
                                    if(err){
                                        //flashService.Error(err,false);
                                        //resetForm();
                                    }  else{
                                        if(res.data.status=='success'){
                                            //flashService.Success(res.data.message,false);
                                            //resetForm();
                                            $("html, body").animate({ scrollTop: 0 }, "slow");
                                            $timeout( function(){
                                                $('.alert').html('');
                                                $('.alert').hide();
                                            }, 5000 );
                                        }
                                    }
                                });


                            }

                        }
                        //$scope.selectVendorTasks($scope.obj.Task_ID);



                        $state.go('lettings-main-nav.tasks-side.task-view.notes', {id: $scope.obj.Task_ID});


                    });
                    //} else {
                        // Do nothing!
                    //}
                //}



            }
        }else {

            if (confirm('Are you sure you want to update this task?')) {

                apiService.updateTask($scope.itemsPerPage, '1', $stateParams.id, '1', 'C', 'D', '2', 'E', 'F', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
                    function(response) {

                        console.log("We are in apiService.updateTask")

                        $scope.$parent.obj = $scope.getTenancy();
                        console.log(response);
                        //console.log(response.data);
                        //console.log(response.data.data);
                        //console.log($scope.obj.D_O_B );

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);

                        $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $stateParams.id, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
                        console.log(response);
                        console.log(response.data);
                        var from_email = "Clouderty | Tenancy Details Updated | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = $scope.obj.First_Name + " | Tenancy Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "First name: " + $scope.obj.First_Name + "\n\n" +
                            "Last Name: " + $scope.obj.Last_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Tenancy_start_date: " + $scope.obj.Tenancy_start_date + "\n\n" +
                            "Tenancy_end_date: " + $scope.obj.Tenancy_end_date + "\n\n" +
                            "Date_leaving: " + $scope.obj.Date_leaving + "\n\n" +
                            "Monthly_rental_price: " + $scope.obj.Monthly_rental_price + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.obj.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.$parent.objBackup.First_Name + "\n\n" +
                            "Last Name: " + $scope.$parent.objBackup.Last_Name + "\n\n" +
                            "Email: " + $scope.$parent.objBackup.Email + "\n\n" +
                            "DOB: " + $scope.$parent.objBackup.D_O_B + "\n\n" +
                            "Tenancy_start_date: " + $scope.$parent.objBackup.Tenancy_start_date + "\n\n" +
                            "Tenancy_end_date: " + $scope.$parent.objBackup.Tenancy_end_date + "\n\n" +
                            "Date_leaving: " + $scope.$parent.objBackup.Date_leaving + "\n\n" +
                            "Monthly_rental_price: " + $scope.$parent.objBackup.Monthly_rental_price + "\n\n" +
                            "Contact number: " + $scope.$parent.objBackup.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy updated by ' + $rootScope.decodedToken.Name + ' - Tenant ID: ' + $scope.$parent.objBackup.Tenant_ID);

                    },
                    function() {
                        console.log("There was an error")
                    }
                )

            }
        }
    }


    $scope.getTasks = function () {

        apiService.getTasks($scope.itemsPerPage, '1', $stateParams.id, '1', 'C', 'D', '9', 'E', 'F', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {
                console.log("getTasks API");

                $scope.obj = response.data.data;

                $scope.obj.taskCategories = [];
                $scope.obj.taskCategories.Task_Category_ID = $scope.obj.Task_Category_ID ;
                $scope.obj.To_be_completed_by = dateInputService.dateInputDayService($scope.obj.To_be_completed_by, 0);
                $scope.selectProperty($scope.obj.Property_ID);
                $scope.selectVendor($scope.obj.Task_ID);

                //$scope.$parent.obj = response.data.data;
                console.log("$scope.obj.vendor_id: " + $scope.obj.Task_ID);
                console.log($scope.obj);
                return $scope.obj;

            },
            function () {
                console.log("API Service Error: getTasks")
            }
        );

    }

    if ($stateParams.id == null) {
        //$scope.obj.selectedTenants = [];
        //$scope.obj.selectedUnits = [];
        $scope.New_Record = true;
    }
    else{
        $scope.New_Record = false;
        $scope.obj.Task_ID = $stateParams.id;
        //$scope.getTasks();
        //$scope.getVendorsTasks($scope.obj.Task_ID);

        var qloadPageData = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("getTasks");
                return $scope.getTasks();

                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("getVendorsTasks");
                return $scope.getVendorsTasks($scope.obj.Task_ID);

                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7');


                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        qloadPageData.resolve($scope.promise);


    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    $scope.getPaymentCategories();
    $scope.propertiesViewAll();
    $scope.vendorsViewAll();

}])