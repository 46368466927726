/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("apiService", ["$http", "$q", "$httpParamSerializer", "$stateParams", "$state",  function ($http, $q, $httpParamSerializer, $stateParams, $state) {
    console.log("apiService service");
    // * or 0 = view all record
    // 1 = view records
    // 2 = update record
    // 3 = delete record
    // 4 = new record
    // 5 = get a record

    /////////////////// Cancel previous AJAX code/API Calls (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code/API Calls (A) end

    /////////////////////////// NOTIFICATIONS ///////////////////////////

    this.notifications = function(id, dataObj) {
        console.log("apiService service notifications");

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        /*
        if(isSending) {

            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        */
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        console.log(dataObj);

        if (dataObj.Status == 4){
            //update tenancy agreement condition status

            console.log("API service: documents Post");
            /*
                     return $http.post('api/notifications', dataObj,{
                         transformRequest: angular.identity,
                         headers: {'Content-Type': undefined}
                     }).then(function(res){

                         $rootScope.notificstionAlertLastID = res.notificstionAlertLastID;
                         callback(res);

                    unction(err){
                         callback(null,err);
                     });
          */

                     return $http({
                         method: 'POST',
                         url: "api/notifications",
                         params: $httpParamSerializer(dataObj),
                         paramSerializer: '$httpParamSerializerJQLike'
                     });

        }
        else if(dataObj.Status == 11){
            console.log("API service: documents Get all records");
            return $http({
                method: 'GET',
                url: "api/notifications",
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function (response) {

                console.log("success");
            });

        }
        else{
            console.log("API service: documents Get all records");
            return $http({
                method: 'GET',
                url: "api/notifications",
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }



    /*
    this.saveNotification = function(notification, callback) {

        return $http.post('api/notifications', notification,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        }).then(function(res){
            callback(res);
        }, function(err){
            callback(null,err);
        });
    }

    */
    /////////////////////////// SIGNUPS ///////////////////////////

    this.postAccountHolder = function(accountHoldersDetails) {
        return $http.post("api/signup", accountHoldersDetails);
        console.log("API service: postAccountHolder");
    }

    this.usersViewAll = function(accountHolderID, cloudertySystemAdministrator) {
        return $http.get("api/users-view/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }

    /////////////////////////// DOCUMENTS ///////////////////////////

    this.documents = function(id, dataObj) {
        console.log("this.documents");

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: documents Post try");


        if (dataObj.Status == 4){
            //update tenancy agreement condition status

            console.log("API service: documents Post");
            return $http({
                method: 'POST',
                url: "api/documents/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });
        }else{
            console.log("API service: documents Get all records");
            return $http({
                method: 'GET',
                url: "api/documents/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }


    /////////////////////////// JOBS ///////////////////////////

    this.jobs = function(id, dataObj) {
        console.log("this.properties");

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: jobs Post try");


        if (dataObj.Status == 4){
            //update tenancy agreement condition status

            console.log("API service: jobs Post");
            return $http({
                method: 'POST',
                url: "api/jobs/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });
        }else{
            console.log("API service: jobs Get all records");
            return $http({
                method: 'GET',
                url: "api/jobs/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    /////////////////////////// TIMESHEETS ///////////////////////////

    this.postTimesheet = function() {
        return $http.post("api/timesheet");
    }

    this.getTimesheets = function(accountHolderID) {
        return $http.get("api/timesheets/" + accountHolderID);
    }


    /////////////////////////// AWARDS ///////////////////////////
    this.awards = function(id, dataObj) {
        console.log("API service: this.awards");

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        /*
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        */
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: awards Post try");
        if (dataObj.Status == 4){
            //

            console.log("API service: awards Post");
            return $http.post("api/awards/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }
        else if (dataObj.Status == 37){
            //update tenancy agreement condition status

            console.log("API service: ng/ng-services/apiService.js:61 Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/awards/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
                //timeout: canceller.promise
            });

        }
        else if (dataObj.Status == 11){
            //update tenancy agreement condition status
            console.log("API service: ng/ng-services/apiService.js:61 Post");
            console.log("dataObj");

            if(isSending) {
                canceller.resolve()
            }
            isSending = true;
            canceller = $q.defer();

            return $http({
                method: 'POST',
                url: "api/awards/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else if (dataObj.Status == 112){
            //update tenancy agreement condition status
            console.log("API service: ng/ng-services/apiService.js:61 Post");
            console.log("dataObj");

            if(isSending) {
                canceller.resolve()
            }
            isSending = true;
            canceller = $q.defer();

            return $http({
                method: 'POST',
                url: "api/awards/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else{
            console.log("API service: awards Get all records");
            return $http({
                method: 'GET',
                url: "api/awards/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
                //timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    /////////////////////////// PROPERTIES ///////////////////////////
    this.properties = function(id, dataObj) {
        console.log("this.properties");

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: properties Post try");
        if (dataObj.Status == 4){
            //

            console.log("API service: properties Post");
            /*
            return $http.post("api/properties/" + id, dataObj).success(function(response){
                //console.log(response);
            });
            */

            return $http({
                method: 'GET',
                url: "api/properties/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }else if (dataObj.Status == 37){
            //update tenancy agreement condition status

            console.log("API service: ng/ng-services/apiService.js:61 Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/properties/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }else{
            console.log("API service: tenancies Get all records nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
            return $http({
                method: 'GET',
                url: "api/properties/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    this.searchProperties = function(search, accountHolderID) {
        console.log("this.searchProperties");

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/properties/" + search + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });
    }

    this.getProperty = function(propertyID, accountHolderID) {
        return $http.get("api/property/" + propertyID + "/" + accountHolderID);
    }

    this.getProperties = function(accountHolderID) {
        return $http.get("api/property-all/" + accountHolderID);
    }


    this.getEstates = function(accountHolderID) {
        return $http.get("api/estates-all/" + accountHolderID);
    }

    this.addProperty = function(propertyDetails) {
        return $http.post("api/property-add", propertyDetails);
        console.log("API service: createProperty");
    }
    /////////////////////////// INSPECTION REPORTS ///////////////////////////

    this.postInspectionReport = function(inspectionReport) {
        return $http.post("api/inspection-reports", inspectionReport);
        console.log("API service: postInspectionReport");
    }

    /////////////////////////// INSPECTION REPORTS END ///////////////////////////

    ///////////////////////////  LETTINGS ///////////////////////////

    /* CERTIFICATES */


    this.gasCertificates = function(itemsPerPage, pageno, searchcriteria, id, propertyActive, propertyID, estateID,  status,  userID, accountHolderID, cloudertySystemAdministrator) {
        return $http.get("api/lettings/certificate-gas/"  + itemsPerPage + "/" + pageno + "/" + searchcriteria + "/" +  id  + "/" + propertyActive + "/" +  propertyID + "/" + estateID + "/" + status + "/" +   userID + "/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }


    this.getAllCertificatesGas = function(itemsPerPage, pageno, accountHolderID) {
        return $http.get("api/lettings/certificate-gas/"  + itemsPerPage + "/" + pageno + "/" + accountHolderID);
    }

    this.getAllCertificatesElectric = function(itemsPerPage, pageno, accountHolderID) {
        return $http.get("api/lettings/certificate-electric/"  + itemsPerPage + "/" + pageno + "/" + accountHolderID);
    }

    /* MAINTENANCE */

    this.maintenance = function(id, dataObj) {
        console.log("API service: maintenance");
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: maintenance Post try");
        //|| dataObj.Status == 41
        if (dataObj.Status == 0 ){

            console.log("API service: maintenance Post");
            /*
           return $http({
               method: 'POST',
               url: "api/maintenance/" + id,
               params: $httpParamSerializer(dataObj),
               paramSerializer: '$httpParamSerializerJQLike',
               timeout: canceller.promise
           }).success(function (response) {
               isSending = false;
               console.log(response);
               console.log("success");
           });
*/
           return $http.post("api/maintenance/" + id, dataObj).success(function(response){
               //console.log(response);
           });
        }
        else if (dataObj.Status == 2){
            //update tenancy agreement condition status
            console.log("API service: maintenance PUT");
            console.log("dataObj");
            return $http({
                method: 'PUT',
                url: "api/maintenance/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log(response);
                console.log("success");
            });

        }
        else if (dataObj.Status == 31){
            //update tenancy agreement condition status
            console.log("API service: maintenance DELETE");
            console.log("dataObj");
            return $http({
                method: 'DELETE',
                url: "api/maintenance/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log(response);
                console.log("success");
            });

        }
        else{
            console.log("API service: maintenance Get all records");
            return $http({
                method: 'GET',
                url: "api/maintenance/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log(response);
                console.log("success");
            });
        }
    }

    /* DEBTS */

    this.debts = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: debts Post try");
        if (dataObj.Status == '4'){
            //

            console.log("API service: debts Post");
            return $http.post("api/debts/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }else if (dataObj.Status == '37'){
            //update tenancy agreement condition status


            console.log("API service: debts Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/debts/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }else if (dataObj.Status == '20'){
            console.log("API service: tenancies status 20 - Tenancy agreement");
            return $http({
                method: 'GET',
                url: "api/debts/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
                //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: dataObj});


            });
        }else{
            console.log("API service: tenancies Get all records nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
            return $http({
                method: 'GET',
                url: "api/debts/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    /* TENANCIES */

    this.states = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: states Post try");
        if (dataObj.Status == '4'){
            //

            console.log("API service: states Post");
            return $http.post("api/states/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }
        else if (dataObj.Status == '37'){
            //update states
            console.log("API service: states Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/states/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });
        }
        else{
            console.log("API service: states Get all records");
            return $http({
                method: 'GET',
                url: "api/states/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    /* TENANCIES */

    this.tenancies = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: tenancies");
        if (dataObj.Status == 4){
            //

            console.log("API service: tenancies Post");
            return $http.post("api/tenancies/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }
        else if (dataObj.Status == '37'){
            //update tenancy agreement condition status


            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/tenancies/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else if (dataObj.Status == 20){
            console.log("API service: tenancies status 20 - Tenancy agreement");
            return $http({
                method: 'GET',
                url: "api/lettings/tenancy-agreement/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
                //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: dataObj});


            });
            /*
            return $http.get("api/lettings/tenancy-agreement/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key, dataObj).success(function(response){
                //console.log(response);
            });
            */
        }
        else{
            console.log("API service: tenancies Get all records nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
            console.log(dataObj);

            return $http({
                method: 'GET',
                url: "api/tenancies/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    this.getTenancy = function(tenancyID, accountHolderID, cloudertySystemAdministrator) {
        console.log('api getTenancy');
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        /* var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/tenancy/" + tenancyID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){


            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end
        });
    }

    this.getTenantRentBalancesAll = function(itemsPerPage, pageno, accountHolderID) {
        return $http.get("api/tenant-rental-account-balances/" + itemsPerPage + "/" + pageno + "/" + accountHolderID);
    }

    this.getAllTenancies = function() {
        return $http.get("api/tenancy-all/:itemsPerPage/:pagenumber/:accountHolderID");
    }

    this.getAllTenanciesSearch = function() {
        return $http.get("api/tenancy-all/:itemsPerPage/:pagenumber/:searchcriteria/:accountHolderID");
    }

    this.getTenanciesUnit = function(unitID, accountHolderID) {
        return $http.get("api/tenancy-property/" + unitID + "/" + accountHolderID);
    }

    this.getAllTenanciesCurrentExport = function() {

        return $http.get("api/tenancy_current_export");
    }

    this.getAllTenanciesCurrent = function(itemsPerPage, pageno, accountHolderID, cloudertySystemAdministrator) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        /* var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        return $http.get("api/tenancy_current/" + itemsPerPage + "/" + pageno + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
    }
    this.getTenancies = function(itemsPerPage, pageno, search, current, monthsExpiring, propertyID, estateID, tenancyID, accountHolderID, cloudertySystemAdministrator) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        /* var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        return $http.get("api/tenancies/" + itemsPerPage + "/" + pageno + "/" + search + "/" + current + "/" + monthsExpiring + "/" +  propertyID + "/" + estateID  + "/" +  tenancyID + "/" +  accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
    }

    this.getAllTenanciesCurrentProperty = function(itemsPerPage, pageno, id, accountHolderID, cloudertySystemAdministrator) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        /* var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        return $http.get("api/tenancy_current_property/" + itemsPerPage + "/" + pageno + "/" + id + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
    }

    this.getAllTenanciesCurrentEstate = function(itemsPerPage, pageno, estateID, accountHolderID, cloudertySystemAdministrator) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/tenancy_current_estate/" + itemsPerPage + "/" + pageno + "/" + estateID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }

    this.getAllTenanciesSearchCurrent = function(itemsPerPage, pageno, search, accountHolderID, cloudertySystemAdministrator) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/tenancy_search_current/" + itemsPerPage + "/" + pageno + "/" + search + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }

    this.getAllTenanciesExpired = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/tenancy_expired/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID);
    }

    this.getAllTenanciesExpiredOneMonth = function() {
        return $http.get("api/tenancy_expired_one_month/:itemsPerPage/:pagenumber");
    }

    this.getAllTenanciesExpiredTwoMonth = function() {
        return $http.get("api/tenancy_expired_two_month/:itemsPerPage/:pagenumber");
    }

    this.getAllTenanciesExpiredThreeMonth = function() {
        return $http.get("api/tenancy_expired_three_month/:itemsPerPage/:pagenumber");
    }

    this.getAllTenanciesSearchExpired = function(itemsPerPage, pagenumber, searchcriteria, accountHolderID) {
        return $http.get("api/tenancy_search_expired/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + accountHolderID);
    }

    this.getAllTenanciesSearchExpiredOneMonth = function() {
        return $http.get("api/tenancy_search_expired_one_month/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.getAllTenanciesSearchExpiredTwoMonths = function() {
        return $http.get("api/tenancy_search_expired_two_months/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.getAllTenanciesSearchExpiredThreeMonths = function() {
        return $http.get("api/tenancy_search_expired_three_months/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.createTenancy = function() {
        return $http.post("api/tenancy");
    }

    this.updateTenancyCurrentStatus = function() {
        return $http.post("api/tenancy_current_status_change/:id/:status");
    }

    this.updateTenancy = function(id, accountHolderID, dataTenancy) {
        return $http.post("api/tenancy-update/" + id + "/" + accountHolderID, dataTenancy);
    }

    /* TENANTS */
    this.tenants = function(id, dataObj) {

        console.log('api tenants');

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        if (dataObj.Status == '4' || dataObj.Status == '2'){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");

            //update - Post = 4
            //update - Status = 2

            console.log("API service: tenants Post or update");

            return $http.post("api/tenants/" + id, dataObj, {timeout: canceller.promise}).success(function(response){
                /////////////////// Cancel previous AJAX code/API Calls (D)
                isSending = false;
                console.log("success");
                console.log(response);

            });

        }
        else{


            console.log("API service: Get tenant");
            return $http({

                method: 'GET',
                url: "api/tenants/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',


            }).success(function (response) {

                isSending = false;
                console.log("success");

            });

        }

        ///Cancel previous AJAX code/API Calls (B)
        /*
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        ////////////////// Cancel previous AJAX code/API Calls (B) end
        return $http.get("api/tenancies/" + itemsPerPage + "/" + pageno + "/" + search + "/" + current + "/" + monthsExpiring + "/" +  propertyID + "/" + estateID  + "/" +  tenancyID + "/" +  accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
        */
    }



    this.getTenants = function(itemsPerPage, pagenumber, searchcriteria, status, id, userID, accountHolderID, cloudertySystemAdministrator) {

        console.log('api getTenants');
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        return $http.get("api/tenants/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + status + "/" + id + "/" + userID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){


            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end
        });


    }

    this.getTenant = function(searchcriteria, accountHolderID) {
        return $http.get("api/tenant/"  + searchcriteria + "/" + accountHolderID);
    }

    this.searchTenant = function(searchcriteria, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/tenants/" + searchcriteria + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }

    this.getAllTenants = function() {
        return $http.get("api/tenants/:itemsPerPage/:pagenumber");
    }

    this.getAllTenantsSearch = function() {
        return $http.get("api/tenants/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.createTenant = function(tenantDetails) {
        return $http.post("api/tenant", tenantDetails);
    }

    this.createTenantViewing = function(dataTenantViewing) {
        return $http.post("api/tenant-viewing", dataTenantViewing);
    }

    this.getTenantViewing = function(id, accountHolderID, cloudertySystemAdministrator) {
        console.log('api getTenantViewing');

        return $http.get("api/tenant-viewing/" + id + "/" + accountHolderID + "/" + cloudertySystemAdministrator);

    }
    this.getTenantViewingStatus = function() {
        console.log('api getTenantViewingStatus');

        return $http.get("api/tenant-viewing-status");

    }

    this.updateTenantViewingStatus = function(id, accountHolderID, cloudertySystemAdministrator, dataTenancy) {
        return $http.post("api/tenant-viewing-status-update/" + id + "/" + accountHolderID + "/" + cloudertySystemAdministrator, dataTenancy);
    }

    this.updateTenantViewing = function(id, accountHolderID, cloudertySystemAdministrator, dataTenancy) {
        return $http.post("api/tenant-viewing-update/" + id + "/" + accountHolderID + "/" + cloudertySystemAdministrator, dataTenancy);
    }

    this.getAllTenantViewings = function(itemsPerPage, pagenumber, userID, userTypeID, compareToDate, accountHolderID, cloudertySystemAdministrator) {
        console.log('api getAllTenantViewings');
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        return $http.get("api/tenant-viewings/" + itemsPerPage + "/"  + pagenumber + "/" + userID + "/" + userTypeID + "/" + compareToDate + "/" +  accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){


            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end
        });
    }

    this.updateTenant = function(id, accountHolderID, dataTenant) {
       return $http.post("api/tenant-update/" + id + "/" + accountHolderID, dataTenant);
    }

    /* TENANT POTENTIAL */

    this.getAllTenantsPotential = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/tenants_potential_search/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID );
    }

    this.getAllTenantsPotentialSearch = function(itemsPerPage, pagenumber, searchcriteria, accountHolderID) {

        console.log('api getAllTenantsPotentialSearch');
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        return $http.get("api/tenants_potential_search/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){


            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end
        });


    }

    this.getAllTenantsPotentialSearchNoPromiseCancellation = function(itemsPerPage, pagenumber, searchcriteria, accountHolderID) {

        return $http.get("api/tenants_potential_search/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + accountHolderID);

    }
    this.addTenantsPotential = function() {
        return $http.post("api/tenants_potential_add");
    }

    this.updateTenantsPotential = function() {
        return $http.post("api/tenants_potential_update_status/:id/:status");
    }

    /* UNITS */

    this.getUnits = function(id, itemsPerPage, pagenumber, status, accountHolderID, cloudertySystemAdministrator) {

        return $http.get("api/units/" + id + "/" + itemsPerPage + "/" + pagenumber + "/" + status + "/" + accountHolderID + "/" + cloudertySystemAdministrator);

    }
  /*
    this.getUnits = function(id, itemsPerPage, pagenumber, status, accountHolderID, cloudertySystemAdministrator) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/units/" + id + "/" + itemsPerPage + "/" + pagenumber + "/" + status + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }
*/
    this.searchUnits = function(searchcriteria, accountHolderID) {
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/units/" + searchcriteria + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }

    this.getUnit = function(id, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/unit/" + id + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){

            isSending = false;
            console.log("success");

        });
    }


    this.getAllUnits = function(itemsPerPage, pagenumber, accountHolderID, vacant) {
        return $http.get("api/units/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID + "/" + vacant);
    }
    this.getAllUnitsAvailable = function(itemsPerPage, pagenumber, accountHolderID, vacant) {
        return $http.get("api/units-available/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID + "/" + vacant);
    }

    /* COUNTRIES */
    this.countries = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record
        console.log(dataObj);
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == 4 || dataObj.Status == 64){

            console.log("API post service: create countries account");

            return $http({
                method: 'POST',
                url: "api/countries/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else{
            console.log("API service: countries Get all records");
            return $http({
                method: 'GET',
                url: "api/countries/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("api countries success");
            });
        }
    }


    /* Account Holders Users */

    this.accountHoldersUsers = function(id, dataObj) {
        // * or 0 = view all record
        // 1 = view records
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record
        console.log(dataObj);
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == 4){

            console.log("API post service: create accountHoldersUsers account");

            return $http({
                method: 'POST',
                url: "api/account-holders-users/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else{
            console.log("API service: accountHoldersUsers Get all records");
            return $http({
                method: 'GET',
                url: "api/account-holders-users/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("api accountHoldersUsers success");
            });
        }
    }

    /* PROCESSES */

    this.awardsProcesses = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record
        console.log(dataObj);
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4'){
            console.log("API post service: create awardsProcesses account");

            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/awards-processes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });
            /*return $http.post("api/processes", dataObj).success(function(response){
                //console.log(response);
            });*/
        }
        else{
            console.log("API service: processes Get all records");
            return $http({
                method: 'GET',
                url: "api/awards-processes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("api processes success");
            });
        }
    }

    /* PROCESSES */

    this.processes = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record
        console.log(dataObj);
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4'){
            console.log("API post service: create process account");

            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/processes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });
            /*return $http.post("api/processes", dataObj).success(function(response){
                //console.log(response);
            });*/
        }
        else{
            console.log("API service: processes Get all records");
            return $http({
                method: 'GET',
                url: "api/processes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("api processes success");
            });
        }
    }

    /* FINANCIAL TRANSACTIONS */

    this.paymentTransactions = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == 4){
            console.log("API service: paymentTransactions Post");
            return $http.post("api/payment-transactions", dataObj).success(function(response){
                //console.log(response);
            });
        }
        else if (dataObj.Status == 0){
            console.log("API service: paymentTransactions Get all records");
            return $http({
                method: 'GET',
                url: "api/payment-transactions/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("api paymentTransactions success");
            });
        }
        else{
            console.log("API service: paymentTransactions Get all records");
            return $http({
                method: 'GET',
                url: "api/payment-transactions/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function(response){
                isSending = false;
                console.log("api paymentTransactions success");
            });
        }
    }

    this.rentalTransactions = function(id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        /*if (dataObj.Status == '4'){
            console.log("API service: paymentTransactions Post");
            return $http.post("api/rental-transactions", dataObj).success(function(response){
                //console.log(response);
            });
        }
        else{*/
            console.log("API service: paymentTransactions Get all records");
            return $http({
                method: 'GET',
                url: "api/rental-transactions/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function(response){
                isSending = false;
                console.log("success");
            });
        //}
    }



    /*
    this.paymentTransactions = function(dataPaymentTransactions) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        console.log(dataPaymentTransactions);
        console.log("dataPaymentTransactions.status: " + dataPaymentTransactions.Status);

        if (dataPaymentTransactions.Status == '4'){
            console.log("API service: paymentTransactions Post");
            return $http.post("api/payment-transactions", dataPaymentTransactions).success(function(response){
                //console.log(response);
            });
        }
        else{
            console.log("API service: paymentTransactions Get all records");
            return $http({
                method: 'GET',
                url: "api/payment-transactions",
                params: $httpParamSerializer(dataPaymentTransactions),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function(response){
                isSending = false;
                console.log("success");
            });
        }
    }
    */


    this.getTenantsRentalTransactions = function() {
        return $http.get("api/tenants_rental_transactions/:itemsPerPage/:pagenumber/:accountHolderID");
    }

    this.getTenantsRentalTransactionsTenancy = function(itemsPerPage, pagenumber, id, accountHolderID) {
        return $http.get("api/tenants_rental_transactions/" + itemsPerPage + "/" + pagenumber + "/" + id + "/" + accountHolderID);
    }

    this.getTenantsRentalTransactionsSearch = function() {
        return $http.get("api/tenants_rental_transactions_search/:itemsPerPage/:pagenumber/:searchcriteria/:accountHolderID");
    }

    this.getTenantsRentExpectedAllSearch = function() {
        return $http.get("api/tenants-rent-expected/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.getTenantsRentExpectedAll = function() {
        return $http.get("api/tenants-rent-expected/:itemsPerPage/:pagenumber");
    }

    this.getTenantsRentExpected = function() {
        return $http.get("api/tenants-rent-expected/:itemsPerPage/:pagenumber/:id");
    }





    this.addCashTransaction = function() {
        return $http.post("api/cash_transaction");
    }

    this.getCashTransactionsAll = function() {
        return $http.get("api/cash-transactions-search/:itemsPerPage/:pagenumber");
    }

    this.updateCashTransaction = function() {
        return $http.post("api/cash-transactions-search/:id/:received");
    }

    this.getCashTransactionsSearch = function() {
        return $http.get("api/cash-transactions-search/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.addCardTransaction = function() {
        return $http.post("api/card_transaction");
    }

    this.getCardTransactionsAll = function() {
        return $http.get("api/card-transactions-search/:itemsPerPage/:pagenumber");
    }

    this.updateCardTransaction = function() {
        return $http.post("api/card-transactions-search/:id/:received");
    }

    this.getCardTransactionsSearch = function() {
        return $http.get("api/card-transactions-search/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.addPaymentTransaction = function(paymentTransaction) {
        return $http.post("api/payment-transaction-add", paymentTransaction);
        console.log("API service: addPaymentTransaction");
    }
    this.getPaymentTransaction = function(id, accountHolderID, accountHolderID, cloudertySystemAdministrator) {
        return $http.get("api/payment-transaction/" + id + "/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }
    this.getPaymentTransactionsAll = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/payment-transactions-all/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID);
    }
    this.getPaymentTransactionsRecurringAll = function(itemsPerPage, pagenumber) {
        return $http.get("api/payment-transactions-recurring-search/" + itemsPerPage + "/" + pagenumber);
    }
    this.addPaymentTransactionRecurring = function(paymentTransactionRecurring) {
        return $http.post("api/payment-transaction-recurring", paymentTransactionRecurring);
    }
    this.updatePaymentTransaction = function(cpTransactionID, received, accountHolderID, cloudertySystemAdministrator) {
        return $http.post("api/payment-transactions-update-received/" + cpTransactionID  + "/" + received + "/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }
    this.updatePaymentTransactionReceived = function(cpTransactionID, received, accountHolderID, cloudertySystemAdministrator) {
        return $http.post("api/payment-transactions-update-received/" + cpTransactionID  + "/" + received + "/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }

    this.getPaymentTransactionCategories = function() {
        return $http.get("api/payment-transactions-categories/");
    }

    this.getPaymentTransactionCategory = function(id) {
        return $http.get("api/payment-transactions-category/" + id);
    }

    this.getPaymentTransactionType = function(id) {
        return $http.get("api/payment-transactions-transaction-type/" + id);
    }

    this.getPaymentTransactionTypes = function() {
        return $http.get("api/payment-transactions-transaction-types");
    }

    this.getPaymentTransactionsSearch = function(itemsPerPage, pagenumber, serachcriteria, accountHolderID) {

        ///Cancel previous AJAX code/API Calls (B)
         if(isSending) {
         canceller.resolve()
         }
         isSending = true;
         canceller = $q.defer();
        /* var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/payment-transactions-search/" + itemsPerPage + "/" + pagenumber + "/" + serachcriteria + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
    }

    this.getFinancialTransaction = function() {
        return $http.get("api/financial-transaction-view-edit/:id");
    }

    /////////////////////////// BUSINESSES ///////////////////////////

    this.searchBusinesses = function(searchcriteria) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/businesses-search/" + searchcriteria, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("API searchBusinesses success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });

    }

    /////////////////////////// CUSTOMERS ///////////////////////////

    this.customers = function(id, dataObj) {


        ///Cancel previous AJAX code/API Calls (B)
        if (isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4') {

            console.log("API service: customers Post");

            return $http.post("api/customers", dataObj).success(function (response) {

                console.log(response);

            });

        }
        else {
            console.log("API service: customers Get all records");
            return $http({

                method: 'GET',
                url: "api/customers/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise

            }).success(function (response) {

                isSending = false;
                console.log("success");

            });

        }
    }

    this.getCustomers = function(itemsPerPage, pageno,  propertyID, estateID, status, searchcriteria, customerID, accountHolderID, cloudertySystemAdministrator) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/customers/"  + itemsPerPage + "/" + pageno + "/" +  propertyID + "/" + estateID + "/" + status  + "/" + searchcriteria + "/" + customerID + "/" +  accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("API searchBusinesses success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });

    }

    this.createCustomer = function(customerDetails) {
        return $http.post("api/customer", customerDetails);
        console.log("API service: customerDetails");
    }

    /////////////////////////// TASKS ///////////////////////////



    this.tasks = function(id, dataObj) {
        console.log("API service: tasks");
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        console.log(dataObj);
        if (dataObj.Status == 4){

            console.log("API service: tasks Post");
            return $http.post("api/tasks", dataObj).success(function(response){
                console.log(response);
            });
        }
        else if(dataObj.Status == 4 || dataObj.Status == 101 || dataObj.Status == 102 || dataObj.Status == 103 || dataObj.Status == 1){
            console.log("API service: tasks Get all records");
            return $http({
                method: 'GET',
                url: "api/tasks/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }
        else{
            console.log("API service: tasks get  records");
            return $http({
                method: 'GET',
                url: "api/tasks/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }

    }

    this.updateTask = function(itemsPerPage, pagenumber, id, vendor_id, propertyID, estateID,  status, searchcriteria, userID, accountHolderID, cloudertySystemAdministrator, dataTask) {
        return $http.post("api/task-update/" + itemsPerPage + "/" + pagenumber + "/" +  id + "/" +  vendor_id  + "/" +  propertyID + "/" + estateID + "/" + status + "/" +  searchcriteria + "/" +  userID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, dataTask);
    }

    this.deleteTask = function(itemsPerPage, pagenumber, id, vendor_id, propertyID, estateID,  status, searchcriteria, userID, accountHolderID, cloudertySystemAdministrator, dataTask) {
        return $http.delete("api/task-delete/" + itemsPerPage + "/" + pagenumber + "/" +  id + "/" +  vendor_id  + "/" +  propertyID + "/" + estateID + "/" + status + "/" +  searchcriteria + "/" +  userID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, dataTask);
    }

    this.getTask = function() {
        return $http.get("api/task/:id");
    }


    this.getTasks = function(itemsPerPage, pagenumber, id, vendor_id, propertyID, estateID,  status, searchcriteria, userID, accountHolderID, cloudertySystemAdministrator) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/tasks/"   + itemsPerPage + "/" + pagenumber + "/" +  id + "/" +  vendor_id  + "/" +  propertyID + "/" + estateID + "/" + status + "/" +  searchcriteria + "/" +  userID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });


    }

    this.getTaskCompleted = function() {
        return $http.get("api/task_completed/:id");
    }

    this.getTask = function() {
        return $http.get("api/task/:task_id/:vendor_id");
    }

    this.getAllUsersTasks = function() {
        return $http.get("api/tasks/:itemsPerPage/:pagenumber");
    }

    this.getAllUsersTasksNotCompleted = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/tasks/lettings_not_completed/"  + itemsPerPage + "/" + pagenumber + "/" + accountHolderID);
    }

    this.getAllUsersTasksNotCompletedSearch = function(itemsPerPage, pagenumber, searchcriteria, accountHolderID) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/tasks/lettings_not_completed/"   + itemsPerPage + "/" + pagenumber + "/" +  searchcriteria + "/" +  accountHolderID, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });
    }

    this.getAllUsersTasksCompleted = function() {
        return $http.get("api/tasks/lettings_completed/:itemsPerPage/:pagenumber");
    }

    this.getAllUsersTasksCompletedSearch = function() {
        return $http.get("api/tasks/lettings_completed/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.getAllUsersTasksSearch = function() {
        return $http.get("api/tasks/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    this.getVendorsTasks = function(itemsPerPage, pagenumber, taskID, accountHolderID, cloudertySystemAdministrator, dataObj) {
        return $http.get("api/vendors-tasks/" + itemsPerPage + "/" + pagenumber + "/" +  taskID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, dataObj);
    }
    this.vendorsViewTaskRecords = function(itemsPerPage, pagenumber, id, accountHolderID, cloudertySystemAdministrator) {
        return $http.get("api/vendors-tasks/" + itemsPerPage + "/" + pagenumber + "/" +  id + "/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }
    this.getVendorsTasksAll = function() {
        return $http.get("api/tasks/vendor_all/:vendor_id/:itemsPerPage/:pagenumber");
    }


    this.getVendorsTasksAllUser = function(vendorID, itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/tasks/vendor_user/" + vendorID + "/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID);
    }



    this.getVendorsTasksSearch = function(vendorID, itemsPerPage, pagenumber, searchcriteria, accountHolderID) {

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        return $http.get("api/tasks/vendor/" + vendorID + "/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){

            /////////////////// Cancel previous AJAX code/API Calls (D)
            isSending = false;
            console.log("success");
            ////////////////// Cancel previous AJAX code/API Calls (D) end

        });

        //return $http.get("api/tasks/vendor/" + vendorID + "/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + accountHolderID);
    }

    this.updateTaskProgress = function() {
        return $http.put("api/tasks/progress");
    }

    this.updateTaskProgressNotComplete = function() {
        return $http.put("api/tasks/progress_not_complete");
    }

    this.taskPostRecord = function() {
        return $http.post("api/task");
    }
    this.getTaskCategories = function() {
        return $http.get("api/task-categories");
    }



    /////////////////////////// PROJECTS ///////////////////////////

    this.getProjects = function() {
        return $http.get("api/projects");
    }

    /////////////////////////// AUTHENTICATION ///////////////////////////

    this.authenticateUser = function() {
        return $http.post("api/authenticate");
    }

    /////////////////////////// NOTES ///////////////////////////

    this.notes = function(id, dataObj) {

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4'){
            //

            console.log("API service: notes Post");
            return $http.post("api/notes/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }//post record
        else if (dataObj.Status == '37'){
            //update tenancy agreement condition status


            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/notes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else if (dataObj.Status == '20'){
            console.log("API service: tenancies status 20 - Tenancy agreement");
            return $http({
                method: 'GET',
                url: "api/notes/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
                //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: dataObj});


            });
            /*
            return $http.get("api/lettings/tenancy-agreement/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key, dataObj).success(function(response){
                //console.log(response);
            });
            */
        }
        else{
            console.log("API service: notes Get all records");
            return $http({
                method: 'GET',
                url: "api/notes/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }//get all records

    }

    this.postNote = function() {
        return $http.post("api/notes");
    }

    this.getNotes = function() {
        return $http.get("api//notes/:note_type/:id(/:vendor_id)");
    }
    /////////////////////////// CERTIFICATES ASSESSMENTS ///////////////////////////

    this.certificatesAssessments = function(id, dataObj) {

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4'){
            //

            console.log("API service: notes Post");
            return $http.post("api/certificates-assessments/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }//post record
        else if (dataObj.Status == '37'){
            //update tenancy agreement condition status


            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/certificates-assessments/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else if (dataObj.Status == '20'){
            return $http({
                method: 'GET',
                url: "api/certificates-assessments/" + id + "/" + dataObj.Licence_Key,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
                //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: dataObj});


            });
            /*
            return $http.get("api/lettings/tenancy-agreement/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key, dataObj).success(function(response){
                //console.log(response);
            });
            */
        }
        else{
            return $http({
                method: 'GET',
                url: "api/certificates-assessments/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }//get all records

    }
    /////////////////////////// VEHICLES ///////////////////////////

    this.vehicles = function(id, dataObj) {

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == '4'){
            //

            console.log("API service: notes Post");
            return $http.post("api/vehicles/" + id, dataObj).success(function(response){
                //console.log(response);
            });

        }//post record
        else if (dataObj.Status == '37'){
            //update tenancy agreement condition status


            console.log("API service: tenancies Post");
            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/vehicles/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            });

        }
        else if (dataObj.Status == '20'){
            console.log("API service: tenancies status 20 - Tenancy agreement");
            return $http({
                method: 'GET',
                url: "api/vehicles/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
                //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: dataObj});


            });
            /*
            return $http.get("api/lettings/tenancy-agreement/" + dataObj.Tenancy_ID + "/" + dataObj.Licence_Key, dataObj).success(function(response){
                //console.log(response);
            });
            */
        }
        else{
            console.log("API service: notes Get all records");
            return $http({
                method: 'GET',
                url: "api/vehicles/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        }//get all records

    }

    /////////////////////////// EMAIL ///////////////////////////

    this.sendSimpleEmail = function() {
        return $http.post("api/email");
    }

    this.sendSimpleEmail = function() {
        return $http.get("api/email");
    }

    /////////////////////////// VENDORS ///////////////////////////

    this.vendors = function(id, dataObj) {

        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        /*if (dataObj.Status == '4'){
            //

            console.log("API service: notes Post");
            return $http.post("api/vendors", dataObj).success(function(response){
                //console.log(response);
            });

        }//post record*/
        //else{

        /*
                    console.log("API service: notes Get all records");
            return $http({
                method: 'GET',
                url: "api/vendors",
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });
         */
            console.log("API service: notes Get all records");
            return $http({
                method: 'GET',
                url: "api/vendors",
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

        //}//get all records

    }

    this.vendorsViewAll = function() {
        return $http.get("api/vendors");
    }

    this.vendorsSimpleSearch = function() {
        return $http.get("api/vendors/search/:search");
    }

    this.vendorsViewRecord = function() {
        return $http.get("api/vendors/:vendor_id");
    }

    this.vendorsPostRecord = function() {
        return $http.post("api/vendors");
    }

    this.vendorsPutRecord = function() {
        return $http.put("api/vendors/:vendor_id");
    }

    this.getAllVendors = function() {
        return $http.get("api/vendors/:itemsPerPage/:pagenumber");
    }

    this.getAllVendorsSearch = function() {
        return $http.get("api/vendors/:itemsPerPage/:pagenumber/:searchcriteria");
    }

    /////////////////////////// TEST ///////////////////////////

    this.testAngular = function() {
        return $http.get("api/tests/:itemsPerPage/:pagenumber");
    }

    /////////////////////////// FILES ///////////////////////////

    this.uploadFile = function() {
        return $http.post("api/files");
    }

    this.getFileNameIDs = function() {
        return $http.get("api/file-mames-ids/");
    }

    this.getFileNameID = function(id) {
        return $http.get("api/file-mames-id/" + id);
    }


    /////////////////////////// GLOBAL ///////////////////////////

    this.getGlobalPromise = function() {
        console.log("API getGlobalPromise service");


        return $http.get("api/global-promise").then(function (user) {

        });
    }

    this.fileDirectories = function(id, dataObj) {
        console.log("this.fileDirectories");
        var qfileDirectories = $q.defer();
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end
        console.log("API service: fileDirectories Post try");
        if (dataObj.Status == 4){
            //

            console.log("API service: fileDirectories Post");
            return $http.post("api/create-file-directory/" + id, dataObj).success(function(response){
                //console.log(response);
                qfileDirectories.resolve(response);
            });

        }else if (dataObj.Status == 41){
            //update tenancy agreement condition status

            console.log("dataObj");
            return $http({
                method: 'POST',
                url: "api/create-file-directory/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;

                qfileDirectories.resolve(response);
                console.log("success");
            });

        }else{
            console.log("API service: fileDirectories Get all records nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
            return $http({
                method: 'GET',
                url: "api/create-file-directory/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;

                qfileDirectories.resolve(response);
                console.log("success");
            });

        }

        return qfileDirectories.promise;
    }

    /*
    this.fileDirectories = function() {

        console.log("API createFileDirectory service");
        var qcreateFileDirectory = $q.defer();
        $http.post("api/create-file-directory").then(function (response) {

            qcreateFileDirectory.resolve(response);

        });

        return qcreateFileDirectory.promise;
    }

    */

    this.smsReceive = function() {

        console.log("API smsReceive service");
        var qsmsReceive = $q.defer();
         $http.post("api/twillio-sms-receive").then(function (response) {

            qsmsReceive.resolve(response);

        });

        return qsmsReceive.promise;
    }
    this.getSources = function() {
        console.log("API getSources service");
        return $http.get("api/sources");
    }
    /*
        this.getGlobalPromise = function() {
        console.log("API getGlobalPromise service");
        return $http.get("api/global-promise");
    }
     */

    this.frequencies = function() {
        return $http.get("api/frequencies");
    }

    this.getUserActivity = function() {
        return $http.get("api/global");
    }

    this.getUserLevels = function() {
        return $http.get("api/global_userlevels/:userLevelID");
    }

    this.postUserActivity = function() {
        return $http.post("api/global");
    }

    this.getAllUserActivity = function() {
        return $http.get("api/global/:itemsPerPage/:pagenumber/");
    }

    this.testUploadResizeSendImage = function() {
        return $http.post("api/tests-upload-resize-send-images", imageDetails);
    }
    /////////////////////////// USERS ///////////////////////////

    this.getUsers = function(accountHolderID, cloudertySystemAdministrator) {
        return $http.get("api/users/" + accountHolderID + "/" + cloudertySystemAdministrator);
    }
    /////////////////////////// LANDLORDS ///////////////////////////

    this.landlordStatementTransactions = function(id, dataObj) {
        console.log("inside API service: landlordStatementTransactions");

        var qlandlordStatementTransactions = $q.defer();
        ///Cancel previous AJAX code/API Calls (B)
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == 4){

            console.log("API service: landlordStatementTransactions Post");

            return $http.post("api/landlord-statement-transactions", dataObj).success(function(response){

                console.log(response);
                qlandlordStatementTransactions.resolve(response);

            });

        }
        else if (dataObj.Status == 21){
            console.log("API service: landlordStatementTransactions Get all records");
            return $http({

                method: 'GET',
                url: "api/landlord-statement-transactions/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise

            }).success(function (response) {

                isSending = false;
                console.log("success");
                qlandlordStatementTransactions.resolve(response);

            });

        }
        else{
            console.log("API service: landlordStatementTransactions Get all records");
            return $http({

                method: 'GET',
                url: "api/landlord-statement-transactions/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike'

            }).success(function (response) {

                isSending = false;
                console.log("success");
                qlandlordStatementTransactions.resolve(response);

            });

        }




    }

    this.landlordStatementView = function(itemsPerPage, pagenumber, propertyID, accountHolderID, yearRelatedTo, monthRelatedTo) {
        return $http.get("api/landlord-statement/" + itemsPerPage + "/" + pagenumber + "/" + propertyID + "/" + accountHolderID + "/" + yearRelatedTo + "/" + monthRelatedTo);
    }


    this.landlordStatementTransactionsViewAll = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/landlord-statement-transactions/"  + itemsPerPage + "/" + pagenumber + "/" +  accountHolderID);
    }
    this.landlordStatementTransactionsViewProperties = function(itemsPerPage, pagenumber, propertyID) {
        return $http.get("api/landlord-statement-transactions/"  + itemsPerPage + "/" + pagenumber + "/" + propertyID );
    }


    this.landlordsViewAll = function(itemsPerPage, pagenumber, accountHolderID) {
        return $http.get("api/landlord-view-all/" + itemsPerPage + "/" + pagenumber +  "/" + accountHolderID);
    }
    /*
    this.landlordStatementTransactions = function(itemsPerPage, pagenumber, accountHolderID, landlordStatementData) {


        return $http.get("api/landlord-statement-transactions-search/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID, landlordStatementData);


    }



    this.landlordStatementTransactions = function(itemsPerPage, pagenumber, accountHolderID, landlordStatementData) {


        return $http.get("api/landlord-statement-transactions-search/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID, {params: {landlordStatementData}, paramSerializer: '$httpParamSerializerJQLike'});


    }
     */
    /*
    this.landlordStatementTransactions = function(itemsPerPage, pagenumber, searchcriteria, status, paymentTransactionID, paymentTransactionCategoryID, yearRelatedTo, monthRelatedTo, propertyID,  accountHolderID, cloudertySystemAdministrator) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria + "/" + status  + "/" + paymentTransactionID  + "/" + paymentTransactionCategoryID + "/" + yearRelatedTo + "/" + monthRelatedTo + "/" + propertyID + "/" + accountHolderID + "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }
    */

    this.landlordStatementTransactionsSearch = function(itemsPerPage, pagenumber, searchcriteria, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions-search/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria +  "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

        //return $http.get("api/landlord-statement-transactions-search/" + itemsPerPage + "/" + pagenumber + "/" + searchcriteria +  "/" + accountHolderID);
    }

    this.landlordStatementTransactionsProperties = function(itemsPerPage, pagenumber, propertyID, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions-property/" + itemsPerPage + "/" + pagenumber + "/" + propertyID +  "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }

    this.landlordStatementTransactionsCategories = function(itemsPerPage, pagenumber, propertyID, paymentTransactionCategoryID, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions-category/" + itemsPerPage + "/" + pagenumber + "/" + propertyID + "/" + paymentTransactionCategoryID +  "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }

    this.landlordStatementTransactionsCategoriesYear = function(itemsPerPage, pagenumber, propertyID, paymentTransactionCategoryID, yearRelatedTo, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions-category-year/" + itemsPerPage + "/" + pagenumber + "/" + propertyID + "/" + paymentTransactionCategoryID +  "/" + yearRelatedTo +  "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }

    this.landlordStatementTransactionsCategoriesYearMonth = function(itemsPerPage, pagenumber, propertyID, paymentTransactionCategoryID, yearRelatedTo, monthRelatedTo, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-statement-transactions-category-year-month/" + itemsPerPage + "/" + pagenumber + "/" + propertyID + "/" + paymentTransactionCategoryID +  "/" + yearRelatedTo +  "/" + monthRelatedTo +  "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }

    this.landlordRegularTransactionsView = function(itemsPerPage, pagenumber, propertyID, accountHolderID) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/landlord-regular-costs/" + itemsPerPage + "/" + pagenumber + "/" + propertyID + "/" + accountHolderID, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }
    this.addLandlordRegularPaymentsTransaction = function(transactionDate, propertyID, userID, accountHolderID, LandlordRegularPaymentsTransactions) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.post("api/landlord-regular-transactions-add/" + transactionDate + "/" + propertyID + "/" + userID + "/" + accountHolderID, LandlordRegularPaymentsTransactions, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }


    /////////////////////////// METER READINGS ///////////////////////////

    this.postMeterReading = function(meterReading) {
        return $http.post("api/meter-reading", meterReading);
    }


    this.getMeterReadings = function(itemsPerPage, pagenumber, accountHolderID, cloudertySystemAdministrator) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();

        return $http.get("api/meter-readings/" + itemsPerPage + "/" + pagenumber + "/" + accountHolderID +  "/" + cloudertySystemAdministrator, {timeout: canceller.promise}).success(function(response){
            isSending = false;
            console.log("success");
        });

    }

    this.getMeterTypes = function() {

        return $http.get("api/meter-types");

    }
    /////////////////////////// METER READINGS END ///////////////////////////

}])
