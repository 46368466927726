/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.directive('showHide', ["$rootScope","$compile", function ($rootScope, $compile) {
    return {
        restrict: 'EA',
        transclude: true,
        replace: true,
        scope: {
            showCloudertyAdmin: '=',
            showLettings: '@',
            cbisarrow: '@'
        },
        template: function(scope, $element, $attrs, showCloudertyAdmin, showLettings) {
            var html = "";
            self.isVisible = true;
            console.log("scope.showCloudertyAdmin: " + scope.showCloudertyAdmin);
            console.log("showCloudertyAdmin: " + showCloudertyAdmin);
            console.log("scope.showLettings: " + scope.showLettings);
            console.log("showLettings: " + showLettings);
            if ($rootScope.decodedToken.Clouderty_System_Administrator == 1) {
                console.log("Testing Directives");
                html = '<div ng-show="false"></div>';
            }

            return html;
        }
    }
}]);