/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("exportCSVService", ["$http", "$q", "fCsv", "$timeout", function ($http, $q, fCsv, $timeout) {


    this.exportCSVService = function(csvData) {
        console.log("In exportCSVService");
        var qexportCSVService = $q.defer();
        $timeout(function() {
            angular.element('#MyButtonTest').triggerHandler('click');
        }, 0);

        qexportCSVService.resolve(csvData);
        return qexportCSVService.promise;

    }
    this.exportCSV = function(csvData, elementID) {
        console.log("In exportCSV");

        var qexportCSV = $q.defer();
        console.log(csvData);

        elementID = '#' + elementID;

        $timeout(function() {
            angular.element(elementID).triggerHandler('click');
        }, 0);

        qexportCSV.resolve(csvData);
        return qexportCSV.promise;

    }
}])
