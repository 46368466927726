/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('fileUpload', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            objdynamic: '=', //scope dynamic variable
            textvariable: '@', //string variable
            fileresults: '='
        },
        templateUrl: 'views/global/file-upload.html',
        link:function(scope,elem,attrs){

        },
        controller: 'fieUploadCtrl'

    }
}])