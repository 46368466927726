clouderty.controller("awardsBuyTicketsCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", "$window", "$cookies", "stringModifierService", "truncateSliceService",  function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService, $window, $cookies, stringModifierService, truncateSliceService) {


    console.log("inside controller awardsNominationStage2AddEditCtrl");

    $('.data').hide();

    console.log("inside watch and $rootScope.watchPaymentAddEdit is: " + $rootScope.watchAwardsSurround);

    $scope.$watch('watchAwardsSurround', function (toVariable, fromVariable) {

        console.log("inside watch and $rootScope.watchAwardsSurround is: " + $rootScope.watchAwardsSurround);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

        if (toVariable == 1) {

            console.log("inside watch and obj is: " + $rootScope.watchAwardsSurround);
            console.log("inside watch and fromVariable is: " + fromVariable);
            console.log("inside watch and toVariable is: " + toVariable);



            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;
            $scope.search = "";
            $scope.progress = {};
            $scope.DateToday = new Date();
            $scope.date = new Date();

            $scope.awardNominations = {};
            $scope.awardCategories = {};
            $scope.awards = {};
            $scope.awards.awardCategories = {};
            $scope.awards.awardNominationRequest = {};
            $scope.awards.awardNominator = {};
            $scope.awardsData = {};
            $scope.countriesData = {};
            $scope.countries = {};

            $scope.nomineesDetailsBloc = 1;
            $scope.nomineesDetailsVisibleBloc = 1;
            $scope.nominationSubmittedBlock = 0;
            $scope.paymentTransactionsData = {};
            $scope.payment_transaction = {};
            $scope.payment_transaction.cardDetails = {};
            $scope.payment_transaction.Ticket_Price_Usual = null;
            $scope.payment_transaction.VAT_Usual = null;
            $scope.payment_transaction.Amount_Usual = null;
            $scope.payment_transaction.Savings = null;
            $rootScope.payment_transaction = {};
            $rootScope.payment_transaction.Payment_Transaction_Card_Completed = 0;

            $scope.payment_transaction.Payment_Transaction_Card_Completed = 0;
            $scope.paymentBlockSpinner = 0;

            $(document).ready(function () {
                $('.nav-tabs li').on("click", function() {
                    console.log('is this it 1');
                    $('.nav-tabs li').removeClass("active");
                    $(this).addClass("active");
                })
            })
            //$('.data').hide();

            //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////


            $scope.nomineeType = {
                Nominee_Type_ID: '1'
            };

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )

                return q55.promise;

            }

            $scope.awardsFunction = function(id, status) {

                var qawardsFunction = $q.defer();

                console.log("function processesAccountFunction");
                console.log("$scope.pageno" + $scope.pageno);

                $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.awardsData.id = $rootScope.decodedToken.id;
                $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
                $scope.awardsData.pageno = $scope.pageno;
                $scope.awardsData.Status = status;
                $scope.awardsData.Process_ID = id;


                if($scope.search){

                    $scope.awardsData.search = $scope.search;

                }
                else{

                    $scope.awardsData.search = "*";

                }

                apiService.awards(id, $scope.awardsData).then(
                    function (response) {
                        console.log("apiService awards service");

                        if(status == 60){

                            $scope.awardCategories = response.data.awardsCategories;

                        }
                        else if(status == 71){

                            $scope.awards.awardsNomination = response.data.awardsNomination;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }
                        else if(status == 64){


                            $scope.awards.awardNominationRequest.First_Name = "";
                            $scope.awards.awardNominationRequest.Last_Name = "";
                            $scope.awards.awardNominationRequest.Email = "";
                            $scope.awardNominations = response.data.data;
                            $scope.nominatorsDetailsBloc = 1;
                            $scope.nomineesDetailsVisibleBloc = 0;

                        }
                        else if(status == 641){

                            $scope.awards.awardNominator.First_Name = "";
                            $scope.awards.awardNominator.Last_Name = "";
                            $scope.awards.awardNominator.Email = "";
                            $scope.awardNominator = response.data.data;
                            $scope.nominationSubmittedBlock = 1;

                        }

                        console.log("processes API successful");


                        console.log(response);
                        qawardsFunction.resolve(response);


                    },
                    function () {

                        console.log("API Service Error: awards");

                    }

                );

                return qawardsFunction.promise;

            }

            $scope.paymentTransactionsFunction = function (id, status) {
                console.log("function paymentTransactionsFunction");
                var qpaymentTransactionsFunction = $q.defer();
                //$scope.paymentTransactionsData = $scope.payment_transaction;
                $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.paymentTransactionsData.Property_ID = 0;

                console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);
                //check if this is a tenancy signup rent payment
                /*
                if($state.current.name == $scope.stateTenancyAgreementExternal) {
                    console.log("check if this is a tenancy signup rent payment");

                    $scope.paymentTransactionsData.Recurring_Payment_Amount = $scope.payment_transaction.cardDetails.Recurring_Payment_Amount;

                    if (!$rootScope.tenancy_id) {
                        //check if a first rent payment exists if not add payment id to tenancy
                        console.log("check if a first rent payment exists if not add payment id to tenancy");
                    }else{
                        console.log("show payment function that first rent has been paid");
                        $scope.paymentTransactionsData.Tenancy_ID = $rootScope.tenancy_id;
                        if (!$rootScope.Rent_Payment_ID_First) {
                            //check if a first rent payment exists if not add payment id to tenancy
                            console.log("check if a first rent payment exists if not add payment id to tenancy");
                        }else{
                            console.log("show payment function that first rent has been paid");
                        }
                    }

                    $scope.unitText = " U " + $rootScope.property.Unit_number;
                }
                */

                /*
                if ($rootScope.tenancy_id) {
                    console.log("$rootScope.property.Property_name: " + $rootScope.property.Property_name);
                    $scope.paymentTransactionsData.Address_1 =  $rootScope.property.Property_name;
                    $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                    $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;

                    $scope.unitText = " U " + $rootScope.property.Unit_number;
                }

                */



                console.log($scope.paymentTransactionsData);

                apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                    function (response) {
                        $scope.paymentTransactions = response.data;
                        console.log($scope.paymentTransactions);
                        console.log("API Service: paymentTransactions");
                        console.log(response);
                        console.log("response.Payment_Transaction_ID: " + response.Payment_Transaction_ID);
                        console.log("response.data.Payment_Transaction_ID: " + response.data.Payment_Transaction_ID);
                        //console.log("response.Payment_Transaction_Card_Completed: >>>>>>>>>>> " + response.Payment_Transaction_Card_Completed);
                        console.log($scope.paymentTransactions);
                        $scope.payment_transaction.Payment_Transaction_ID = $scope.paymentTransactions.Payment_Transaction_ID;
                        console.log("$scope.payment_transaction.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        $scope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $rootScope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $scope.payment_transaction.Failed_Response_Message = $scope.paymentTransactions.Failed_Response_Message;
                        $scope.payment_transaction.First_Payment = $scope.paymentTransactions.First_Payment;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Amount = $scope.paymentTransactions.Payment_Transactions_Recurring_Amount;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Order_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_Order_ID;
                        $scope.payment_transaction.Payment_Transactions_Recurring_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_ID;

                        if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){
                            console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.paymentTransactions.Payment_Transactions_Recurring_Completed);

                            $rootScope.payment_transaction.Payment_Transactions_Recurring_Completed = $scope.paymentTransactions.Payment_Transactions_Recurring_Completed;

                        }

                        if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1'){
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " SUCCESSFUL";
                            $rootScope.paymentResultText = $scope.paymentResultText;

                            $scope.payment_transaction.paymentDiv = '0';
                            //check if this is a tenancy signup rent payment

                            console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv $state.current.name: " + $state.current.name);
                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                console.log("check if this is a tenancy signup rent payment");



                                //var el = document.getElementById("agreeToClauses");
                                //el.scrollIntoView();
                                if (!$rootScope.Rent_Payment_ID_First) {
                                    //check if a first rent payment exists if not add payment id to tenancy
                                    console.log("check if a first rent payment exists if not add payment id to tenancy");
                                    $rootScope.Rent_Payment_ID_First = $scope.paymentTransactions.Payment_Transaction_ID;
                                    //$scope.tenanciesFunction($stateParams.tenancyID, '25');

                                }

                            }


                        }else{
                            //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            console.log("API Service: paymentTransactions");
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " FAILED - " + $scope.payment_transaction.Failed_Response_Message;
                            $rootScope.paymentResultText = $scope.paymentResultText;
                        }

                        console.log("$scope.paymentTransactions.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.payment_transaction.Payment_Transactions_Recurring_Completed);
                        console.log("$scope.paymentTransactions.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                        qpaymentTransactionsFunction.resolve(response);
                    }/*,
                    function (response) {
                        console.log("There was an error in paymentTransactions");
                        qpaymentTransactionsFunction.resolve(response);
                    }*/
                )
                //});
                return qpaymentTransactionsFunction.promise;
            }

            $scope.getCategories = function () {

                var qgetCategories = $q.defer();

                qgetCategories.resolve($scope.awardsFunction('*', 60));

                return qgetCategories.promise;

            }

            $scope.getNomination = function () {
                console.log("inside function getNomination");
                var qgetNomination = $q.defer();


                console.log("$stateParams.awardsNominationID: " + $stateParams.awardsNominationID);
                $scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                qgetNomination.resolve($scope.awardsFunction($stateParams.awardsNominationID, 71));

                return qgetNomination.promise;

            }

            $scope.getCountries = function () {

                var qgetCountries = $q.defer();

                qgetCountries.resolve(
                    apiService.countries('*', $scope.countriesData).then(
                        function (response) {
                            $scope.countries = response.data.data;
                            qgetCountries.resolve(response);
                        }
                    )
                );

                return qgetCountries.promise;

            }

            $scope.addNomination = function () {

                console.log("inside function addNomination");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nomination?")){
                    $scope.awardsData.First_Name = $scope.awards.awardNominationRequest.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominationRequest.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominationRequest.Contact_number;
                    $scope.awardsData.Address_1 = $scope.awards.awardNominationRequest.Address_1;
                    $scope.awardsData.Address_2 = $scope.awards.awardNominationRequest.Address_2;
                    $scope.awardsData.Town = $scope.awards.awardNominationRequest.Town;
                    $scope.awardsData.City_Region = $scope.awards.awardNominationRequest.City_Region;
                    $scope.awardsData.Postcode = $scope.awards.awardNominationRequest.Postcode;

                    /*
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    */

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 64));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nomination Submitted " + $scope.Awards_Nomination_Request_ID + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Adam' + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + $scope.Awards_Nomination_Request_ID + "<br /><br />" +
                                "Awards Category: " + $scope.awardsData.Description + "<br /><br />" +
                                "Nominee Name: " + $scope.awardsData.First_Name + "<br /><br />" +
                                "Nominee Contact Number: " + $scope.awardsData.Contact_number + "<br /><br />" +
                                "Nominee Email: " + $scope.awardsData.Email + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.addNominator = function () {

                console.log("inside function addNominator");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nominator?")){
                    $scope.awardsData.First_Name = $scope.awards.awardNominator.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominator.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominator.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominator.Contact_number;

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 641));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nominator Added " + '18' + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Adam' + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + "18" + "<br /><br />" +
                                "Awards Category: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Name: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Contact Number: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Email: " + $scope.date + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.getCategory = function () {

                $scope.awardsData.Description = $scope.awards.awardCategories.Description;
                $scope.awardsData.Awards_Category_ID = $scope.awards.awardCategories.Awards_Category_ID;
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Description);
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Awards_Category_ID);

            }

            $scope.getCountry = function () {

                $scope.awardsData.Country_ID = $scope.awards.countries.Country_ID;
                $scope.awardsData.Country = $scope.awards.countries.Country;
                console.log("$scope.awardsData.Country_ID: " + $scope.awardsData.Country_ID);
                console.log("$scope.awardsData.Country: " + $scope.awardsData.Country);

            }

            $scope.loadAwardsData = function () {
                console.log("inside function loadAwardsData");
                $('.data').hide();
                $scope.accordionOpen('ticket-information', 'ticket-information-data');

                //$('.data').hide();
                var qloadAwardsData = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {
                        console.log('A7a decode');
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                        $rootScope.logoutExternalPage();
                        return $rootScope.decode();

                        //return $scope.getGlobalPromise(data);

                    }, function (err) {
                        $scope.myValue = err;
                    })

                qloadAwardsData.resolve($scope.promise);

            };

            $scope.addPaymentTransactions = function () {
                console.log("Some test 1: Add transaction");
                //if (confirm("Do you want to add this transaction?")) {
                console.log("Add transaction");

                $scope.paymentBlockSpinner = 1;

                var q = $q.defer();

                $scope.myValue = 0;
                $scope.promise = loginExternalWebPageService.init("1");

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");
                        $rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - paymentTransactionsFunction");

                        $scope.paymentBlockSpinner = '1';
                        $scope.pageBlock = '0';
                        $window.scrollTo(0, 0);


                        //$scope.paymentTransactionsData.Amount = 0.01;
                        $scope.paymentTransactionsData.Amount = $scope.payment_transaction.Amount;

                        console.log("$rootScope.decodedToken.Clouderty_System_Administrator: " + $rootScope.decodedToken.Clouderty_System_Administrator);
                        console.log("$rootScope.decodedToken.Account_Holder_ID: " + $rootScope.decodedToken.Account_Holder_ID);

                        var date = new Date();

                        $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                        $scope.paymentTransactionsData.CVC = $scope.payment_transaction.cardDetails.CVC;
                        $scope.paymentTransactionsData.Date = date;
                        $scope.paymentTransactionsData.Full_Name = $scope.payment_transaction.cardDetails.Full_Name;
                        $scope.paymentTransactionsData.Card_Number = $scope.payment_transaction.cardDetails.Card_Number;
                        $scope.paymentTransactionsData.Expiry = $scope.payment_transaction.cardDetails.Expiry;
                        $scope.paymentTransactionsData.Payment_Transaction_Type_ID = 2;
                        $scope.paymentTransactionsData.Status = 4;
                        $scope.paymentTransactionsData.Payment_Transaction_Category_ID = 51;

                        $scope.paymentTransactionsData.Payment_Transaction_Category_Description = "AWARDSTICKET-X" + $scope.payment_transaction.Quantity + $scope.payment_transaction.cardDetails.Full_Name;
                        $scope.paymentTransactionsData.Payment_Transaction_Category_Description = stringModifierService.replaceHTMLTags($scope.paymentTransactionsData.Payment_Transaction_Category_Description);
                        $scope.paymentTransactionsData.Payment_Transaction_Category_Description = truncateSliceService.truncateSliceService($scope.paymentTransactionsData.Payment_Transaction_Category_Description, 35);
                        $scope.paymentTransactionsData.Receipt_Name = $scope.paymentTransactionsData.Payment_Transaction_Category_Description;
                        $scope.paymentTransactionsData.Payment_ID = $scope.paymentTransactionsData.Payment_Transaction_Category_Description;
                        $scope.paymentTransactionsData.Description = $scope.paymentTransactionsData.Payment_Transaction_Category_Description;

                        $scope.paymentTransactionsData.Email = $scope.payment_transaction.cardDetails.Email;


                        console.log("$scope.paymentTransactionsData.Payment_Transaction_Category_Description: " + $scope.paymentTransactionsData.Payment_Transaction_Category_Description);

                        $scope.payment_transaction.Receipt_Name = $scope.paymentTransactionsData.Payment_Transaction_Category_Description;
                        $scope.paymentTransactionsData.Receipt_Name = $scope.payment_transaction.Receipt_Name;
                        return $scope.paymentTransactionsFunction("*", 4);

                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A3");

                        if($scope.payment_transaction.Payment_Transaction_Card_Completed == 1 && $scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                            var from_email = "Clouderty | PIA Ticket Payment Successful " + $scope.payment_transaction.Payment_Transaction_ID + " | " + " <notifications@clouderty.co.uk>";
                            var subject = "Property Investors Awards | Amount " + $scope.payment_transaction.Amount + "GBP";
                            var message = "Dear " + $scope.payment_transaction.cardDetails.Full_Name + ",<br /><br />" +
                                "Congratulations on securing your ticket(s) to the Property Investors Awards." + "<br /><br />" +
                                "Payment Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                "Description: " + $scope.paymentTransactionsData.Payment_Transaction_Category_Description + "<br /><br />" +
                                "Quantity of tickets bought: " + $scope.payment_transaction.Quantity + "<br /><br />" +
                                "Date purchased: " + $scope.paymentTransactionsData.Date + "<br /><br />" +
                                "Ticket type ID: " + $scope.payment_transaction.Ticket_Type + "<br /><br />" +
                                "Email: " + $scope.payment_transaction.cardDetails.Email + "<br /><br />" +
                                "Ticket type description: " + $scope.payment_transaction.Ticket_Type_Description + "<br /><br />" +
                                "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br /><br /><br />" +
                                "Event date: Saturday 30th November 2019" + "<br /><br />" +
                                "Location: Royal National Hotel, Russell Square, WC1H ODG" + "<br /><br />" +
                                "Property Investors Awards " + "<br />" +
                                "Tel: 01206 700 123"  + "<br />" +
                                "https://propertyinvestorsawards.co.uk/";

                        }
                        else{
                            if($scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                                console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | PIA Ticket Payment Failed " + $scope.payment_transaction.Payment_Transaction_ID + " | " + " <notifications@clouderty.co.uk>";
                                var subject = "Property Investors Awards | Amount " + $scope.payment_transaction.Amount  + "GBP";
                                var message = "Dear " + $scope.payment_transaction.cardDetails.Full_Name + ",<br /><br />" +
                                    "Unfortunately, your payment failed." + "<br /><br />" +
                                    "Payment Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                    "Description: " + $scope.paymentTransactionsData.Payment_Transaction_Category_Description + "<br /><br />" +
                                    "Quantity of tickets required: " + $scope.payment_transaction.Quantity + "<br /><br />" +
                                    "Date purchased: " + $scope.paymentTransactionsData.Date + "<br /><br />" +
                                    "Ticket type ID: " + $scope.payment_transaction.Ticket_Type + "<br /><br />" +
                                    "Email: " + $scope.payment_transaction.cardDetails.Email + "<br /><br />" +
                                    "Ticket type description: " + $scope.payment_transaction.Ticket_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br /><br /><br />" +
                                    "Property Investors Awards " + "<br />" +
                                    "Tel: 01206 700 123"  + "<br />" +
                                    "https://propertyinvestorsawards.co.uk/";

                            }
                            else{

                            }
                        }

                        $scope.postUserActivityServiceMessage = subject;
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                        to_email = $scope.paymentTransactionsData.Email;
                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                        to_email = "awards@propertyinvestorsawards.co.uk";
                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                        $scope.payment_transaction.cardDetails.Full_Name = "";
                        $scope.payment_transaction.cardDetails.Card_Number = "";
                        $scope.payment_transaction.cardDetails.Expiry = "";
                        $scope.payment_transaction.Amount = "";
                        $scope.payment_transaction.cardDetails.CVC = "";
                        //$scope.payment_transaction.cardDetails.Recurring_Payment = "";


                        //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        $scope.paymentBlockSpinner = '0';
                        $scope.pageBlock = '1';
                        //$('.data').hide();

                        $scope.message = message;


                        $window.scrollTo(0, 0);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');




                        $scope.paymentBlockSpinner = 0;
                        $scope.myValue = data;

                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
                //}

            };

            $scope.accordionOpen = function(element, element_data) {
                console.log("inside function accordionOpen");


                $('.'+element).removeClass("fa-chevron-left");
                $('.'+element).addClass("fa-chevron-down");
                $('.'+element_data).slideToggle("fast");

            }

            $scope.calculateTicketPrice = function(element, element_data) {
                console.log("inside function calculateTicketPrice");
                $scope.payment_transaction.Ticket_Price_Usual = "";
                $scope.payment_transaction.VAT_Usual = "";
                $scope.payment_transaction.Amount_Usual = "";
                $scope.payment_transaction.Savings = "";

                $scope.payment_transaction.Ticket_Price = "";
                $scope.payment_transaction.VAT = "";
                $scope.payment_transaction.Amount = "";

                console.log("$scope.payment_transaction.Ticket_Type: " + $scope.payment_transaction.Ticket_Type);
                console.log("$scope.payment_transaction.Quantity:  " + $scope.payment_transaction.Quantity);
                if($scope.payment_transaction.Ticket_Type == "" || $scope.payment_transaction.Quantity == ""){

                    $scope.payment_transaction.Ticket_Price_Usual = "";
                    $scope.payment_transaction.VAT_Usual = "";
                    $scope.payment_transaction.Amount_Usual = "";
                    $scope.payment_transaction.Savings = "";

                    $scope.payment_transaction.Ticket_Price = "";
                    $scope.payment_transaction.VAT = "";
                    $scope.payment_transaction.Amount = "";

                }
                else {


                    if($scope.payment_transaction.Ticket_Type == 1){

                        $scope.payment_transaction.Ticket_Price = 97;
                        $scope.payment_transaction.Ticket_Price_Usual = 187;

                    }else if($scope.payment_transaction.Ticket_Type == 2){

                        $scope.payment_transaction.Ticket_Price = 187;
                        $scope.payment_transaction.Ticket_Price_Usual = 397;

                    }else if($scope.payment_transaction.Ticket_Type == 3){

                        $scope.payment_transaction.Ticket_Price = 187;
                    }else if($scope.payment_transaction.Ticket_Type == 4){

                        $scope.payment_transaction.Ticket_Price = 397;
                    }

                    $scope.payment_transaction.Savings = "";
                    $scope.payment_transaction.Ticket_Price = $scope.payment_transaction.Ticket_Price * $scope.payment_transaction.Quantity;
                    $scope.payment_transaction.VAT = $scope.payment_transaction.Ticket_Price * 0.2;
                    $scope.payment_transaction.Amount = $scope.payment_transaction.Ticket_Price + $scope.payment_transaction.VAT;

                    if($scope.payment_transaction.Ticket_Price_Usual){
                        $scope.payment_transaction.Ticket_Price_Usual = $scope.payment_transaction.Ticket_Price_Usual * $scope.payment_transaction.Quantity;
                        $scope.payment_transaction.VAT_Usual = $scope.payment_transaction.Ticket_Price_Usual * 0.2;
                        $scope.payment_transaction.Amount_Usual = $scope.payment_transaction.Ticket_Price_Usual + $scope.payment_transaction.VAT_Usual;
                        $scope.payment_transaction.Savings = $scope.payment_transaction.Amount_Usual - $scope.payment_transaction.Amount;
                    }

                    if($scope.payment_transaction.discountCode == "THABO10"){
                        $scope.payment_transaction.Ticket_Price = $scope.payment_transaction.Ticket_Price * 0.9;
                        $scope.payment_transaction.VAT = $scope.payment_transaction.Ticket_Price * 0.2;
                        $scope.payment_transaction.Amount = $scope.payment_transaction.Ticket_Price + $scope.payment_transaction.VAT;
                        $scope.payment_transaction.Savings = $scope.payment_transaction.Amount_Usual - $scope.payment_transaction.Amount;
                    }

                    console.log("$scope.payment_transaction.Amount_Usual : " + $scope.payment_transaction.Amount_Usual );

                    console.log("$scope.payment_transaction.Amount: " + $scope.payment_transaction.Amount);



                }



            }

            $scope.selectTicketType = function() {
                console.log("inside function calculateTicketPrice");


                console.log("$scope.payment_transaction.Ticket_Type: " + $scope.payment_transaction.Ticket_Type);
                console.log("$scope.payment_transaction.Quantity:  " + $scope.payment_transaction.Quantity);

                if($scope.payment_transaction.Ticket_Type == ""){

                    $scope.payment_transaction.Quantity = "";

                }

                if($scope.payment_transaction.Ticket_Type == 1){

                    $scope.payment_transaction.Ticket_Type_Description = "Gold ticket (early bird)";

                }
                else if($scope.payment_transaction.Ticket_Type == 2){

                    $scope.payment_transaction.Ticket_Type_Description = "VIP ticket (early bird)";
                }
                else if($scope.payment_transaction.Ticket_Type == 3){

                    $scope.payment_transaction.Ticket_Type_Description = "Gold ticket (standard)";
                }
                else if($scope.payment_transaction.Ticket_Type == 4){

                    $scope.payment_transaction.Ticket_Type_Description = "VIP ticket (standard)";
                }
                $scope.calculateTicketPrice();

            }


            $scope.selectTicketQuantity = function() {
                console.log("inside function calculateTicketPrice");


                console.log("$scope.payment_transaction.Ticket_Type: " + $scope.payment_transaction.Ticket_Type);
                console.log("$scope.payment_transaction.Quantity:  " + $scope.payment_transaction.Quantity);

                if($scope.payment_transaction.Quantity == ""){

                    $scope.payment_transaction.Quantity = "";

                }
                $scope.calculateTicketPrice();

            }

            $scope.loadAwardsData();
            //$('.data').hide();




        }
    })

}])