
//clouderty.controller("tenancyAgreementCtrl", ["$scope", "obj", "$rootScope", "$http", "$stateParams", "$state", "apiService", "reverseFilter", "sumOfValueFilter", "totalSumPriceQtyFilter", "yesNoFilter", "$filter", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$q", "$window", "$cookies", "postUserActivityService", "loginExternalWebPageService", '$location', '$anchorScroll',  function ($scope, obj, $rootScope, $http, $stateParams, $state, apiService, reverseFilter, sumOfValueFilter, totalSumPriceQtyFilter, yesNoFilter, $filter, $mdDialog, $mdMedia, Upload, $timeout, $q, $window, $cookies, postUserActivityService, loginExternalWebPageService, $location, $anchorScroll) {
clouderty.controller("tenancyAgreementCtrl", ["$scope", "$rootScope", "$http", "$stateParams", "$state", "apiService", "reverseFilter", "sumOfValueFilter", "totalSumPriceQtyFilter", "yesNoFilter", "$filter", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$q", "$window", "$cookies", "postUserActivityService", "loginExternalWebPageService", '$location', '$anchorScroll',  function ($scope, $rootScope, $http, $stateParams, $state, apiService, reverseFilter, sumOfValueFilter, totalSumPriceQtyFilter, yesNoFilter, $filter, $mdDialog, $mdMedia, Upload, $timeout, $q, $window, $cookies, postUserActivityService, loginExternalWebPageService, $location, $anchorScroll) {

    console.log("obj >>>>>>>>>>>");
    console.log("tenancyID: " + $stateParams.tenancyID);
    console.log("licenseKey: " + $stateParams.licenseKey);

    $scope.obj = {};
    $rootScope.watchPaymentAddEdit = 0;

    $scope.getGlobalPromise = function() {

        console.log("inside function getGlobalPromise");
        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.DataLoad = function() {

        $scope.submitSignedTenancyButton = 0;
        //$scope.tenancies = [];

        console.log("tenancyID: " + $stateParams.tenancyID);
        console.log("licenseKey: " + $stateParams.licenseKey);
        //deferred3.resolve(auth);

        var deferred4 = $q.defer();
        //$scope.promise = statesFunction();


        $scope.tenancies = {};



        console.log($scope.tenancies);

        $scope.promise = loginExternalWebPageService.init("1");

        $scope.promise
            .then(function (data) {

                console.log("A2a loginfunction");
                $rootScope.decode();
                return $scope.getGlobalPromise(data);

            })
            .then(function () {
                console.log("A2 tenancies");

                $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.tenancies.itemsPerPage = 20;
                $scope.tenancies.pageno = 1;
                $scope.tenancies.Tenancy_ID = $stateParams.tenancyID;
                $scope.tenancies.Estate_ID = 0;
                $scope.tenancies.Property_ID = 0;
                $scope.tenancies.monthsExpiring = 0;
                $scope.tenancies.Licence_Key = $stateParams.licenseKey;
                $scope.tenancies.Status = 20;
                $scope.tenancies.Payment_Transaction_Type_ID = "";
                $scope.tenancies.paymentTransactionInputs = "";
                $scope.tenancies.search = "*";

                console.log($scope.tenancies);
                 apiService.tenancies($scope.tenancies.Tenancy_ID, $scope.tenancies).then(
                    function (response) {
                        $scope.tenancies = response.data;

                        $scope.$parent.obj = response.data;
                        $scope.obj = response.data;
                        console.log('tenancies route resolve >>>>');
                        console.log(response);
                        console.log($scope.obj);
                        console.log($scope.$parent.obj);

                    },
                    function () {
                        console.log("API Service Error: processes")
                    }
                );
                return $scope.getGlobalPromise;
            })
            .then(function (data) {

                console.log("A3 getGlobalPromise");

                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {
                console.log('A7');
                myValue = data;
                $scope.tenancyAgreementBlock = 1;
            }, function (err) {
                myValue = err;
            })

        deferred4.resolve($scope.promise);

        return deferred4.promise;
    }

    $scope.$watch('obj', function (toVariable, fromVariable) {

        console.log("inside watch and obj is: " + $scope.obj);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);
        console.log($scope.obj);
        //console.log(toVariable.data.Property_ID);
        if(toVariable.data.Property_ID > 0){

            console.log("inside watch and toVariable is: " + toVariable);


            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;

            $scope.search = "";
            $scope.progress = {};
            $scope.business = {};
            $scope.properties = {};
            $scope.units = [];
            $scope.payment_transaction = {};
            $scope.LandlordRegularCosts = [];
            $scope.paymentTransactionCategory = [];
            $scope.paymentTransactionCategories = [];
            $scope.hideBusinessSearch = false;
            $scope.DateToday = new Date();
            $scope.date = new Date();
            $scope.property = [];
            $rootScope.property = {};
            $rootScope.property.Property_name = "";
            $rootScope.property.Unit_number = "";
            $rootScope.Rent_Payment_ID_First = "";
            //$scope.$parent.obj.data.Property_ID = "";

            $scope.tenancies = [];
            $scope.monthsExpiring = 0;
            $scope.landlord = [];
            $scope.landlord.Estate = "";
            $scope.landlord.Estate_ID = 0;
            $scope.landlord.Property = "";
            $scope.landlord.Property_ID = 0;
            $scope.Date_Tenancy_Signed = $scope.$parent.Date_Tenancy_Signed;
            $scope.payment_transaction.paymentDiv = '1';

            //$scope.$parent.obj2 = {};
            //$scope.$parent.obj = obj;
            $rootScope.property = {};
            $rootScope.property.Property_name = "";
            $scope.payment_transaction = {};
            $scope.$parent.payment_transaction = {};
            $rootScope.payment_transaction = {};


            //$scope.obj = obj;
            //console.log("$scope.obj.data.Property_ID: " + $scope.$parent.obj.data.Property_ID);
            console.log($scope.$parent.obj);
            console.log($scope.obj);
            console.log("$scope.obj.data.Property_ID: " + $scope.obj.data.Property_ID);
            $rootScope.Property_ID = $scope.$parent.obj.data.Property_ID;
            $rootScope.property.Property_name = $scope.$parent.obj.data.Property_name;
            $rootScope.property.Unit_number = $scope.$parent.obj.data.Unit_number;
            $scope.$parent.obj.Rent_Payment_ID_First = $scope.$parent.obj.data.Rent_Payment_ID_First;
            $rootScope.Rent_Payment_ID_First = $scope.$parent.obj.data.Rent_Payment_ID_First;
            console.log($scope.obj);
            $rootScope.Signature_Signed_Tenancy_Text = $scope.$parent.obj.data.Signature_Signed_Tenancy_Text;
            $rootScope.Date_Tenancy_Signed = $scope.$parent.obj.data.Date_Tenancy_Signed;
            $rootScope.tenancyAgreementConditions = $scope.$parent.obj.tenancyAgreementConditions;
            //console.log("xxxxxxxxxxxxxx - response.data.Rent_Payment_ID_First: " + response.data.Rent_Payment_ID_First);
            $scope.unitText = " U " + $rootScope.property.Unit_number;
            $rootScope.payment_transaction.Receipt_Name = $rootScope.property.Property_name + "" + $scope.unitText + " - ";
            $scope.$parent.payment_transaction.Receipt_Name = $rootScope.property.Property_name + "" + $scope.unitText + " - ";
            $rootScope.payment_transaction.Payment_Transaction_Card_Completed = 0;
            $rootScope.Payment_Transaction_Rent_Completed = 0;

            if($scope.$parent.obj.data.Rent_Payment_ID_First){
                console.log("activate tenancySignAnchor");
                $scope.gotoAnchor('tenancySignAnchor');

            }

            console.log("$rootScope.Rent_Payment_ID_First >>>>>>>>>>>>>>>>>>>>>>> : £" + $rootScope.Rent_Payment_ID_First);

            $scope.payment_transaction.paymentDiv = '1';
            $rootScope.tenancyBlock = 1;
            $rootScope.tenancyAgreementBlock = 1;

            if($rootScope.Rent_Payment_ID_First){

                console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.Payment_Transaction_Rent_Completed >>>>>>>>>>>>>>>>>>>>>>> " + $rootScope.Payment_Transaction_Rent_Completed);
                console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>> " + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);
                $rootScope.payment_transaction.Payment_Transaction_Card_Completed = 1;
                $rootScope.Payment_Transaction_Rent_Completed = 1;
                console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>> " + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);
                console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.Payment_Transaction_Rent_Completed >>>>>>>>>>>>>>>>>>>>>>> " + $rootScope.Payment_Transaction_Rent_Completed);


                if($rootScope.Date_Tenancy_Signed){
                    console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj: " + $rootScope.Date_Tenancy_Signed);
                    $rootScope.isDisabledAfterTenancySubmitted = true;
                    //$rootScope.obj.data.Signature = $rootScope.Signature_Signed_Tenancy_Text;

                    $scope.$parent.obj.data.Signature = $rootScope.Signature_Signed_Tenancy_Text;
                    $rootScope.tenancySubmittedRevisit = 1;

                }else{
                    console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj: " + $rootScope.Date_Tenancy_Signed);

                    $rootScope.isDisabledAfterTenancySubmitted = false;
                }

            }

            console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>>" + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);

            $scope.init = function () {
                //$rootScope.logoutExternalPage();
                console.log("init inside ");
                //$scope.progress.progress = $scope.obj.data.Status;

                // Delete record
                console.log("ACTION: 1");
                var q5 = $q.defer();
                $scope.myValue = 0;
                $scope.promise = loginExternalWebPageService.init("1");
                //$scope.promise = $scope.getGlobalPromise();


                $scope.promise
                    .then(function (data) {

                        console.log("A2a loginfunction");
                        $rootScope.decode();

                        console.log("A3 init");
                        console.log($stateParams);
                        console.log("StateParams: " + $stateParams);
                        console.log("$stateParams.tenancyID: " + $stateParams.tenancyID);
                        $rootScope.tenancy_id = $stateParams.tenancyID;
                        $rootScope.licenseKey = $stateParams.licenseKey;
                        console.log("$rootScope.tenancy_id >>>>>>>>>>>>>>>>>>>>>>>> : " + $rootScope.tenancy_id);


                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log("A6 decode");
                        console.log("main area");

                        $scope.tenanciesFunction = function(id, status) {

                            console.log("function tenanciesFunction zzzzzzzzzzzzzzzzzzzzzzzzzzzz");

                            var qtenanciesFunction2 = $q.defer();

                            //console.log(obj);

                            qtenanciesFunction2.resolve($scope.obj);

                            if($scope.search == "*"){
                                $scope.search = "";
                            }
                            else{

                            };

                            return qtenanciesFunction2.promise;
                        }

                        console.log('tenancyAgreementCtrl');
                        //console.log('$stateParams.id: ' + $stateParams.id);
                        $scope.print = function () {
                            window.print();
                        }

                        $("html body").css("overflow-y","auto"); //enables multiple print pages



                        return $scope.tenanciesFunction($stateParams.tenancyID, '20');

                        //return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log('$rootScope.Property_ID >>>>>>>>>>>>>>>>>>>>>: ' + $rootScope.Property_ID);
                        console.log("$rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>>" + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);


                        console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh $rootScope.Rent_Payment_ID_First" + $rootScope.Rent_Payment_ID_First)


                        console.log("A7 decode");
                        /*
                        $scope.payment_transaction.Property_name = $scope.obj.data.Property_name;
                        $rootScope.property.Property_name = $scope.obj.data.Property_name;
                        $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                        */
                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        $scope.tenancyAgreementBlock = 1;
                        $rootScope.watchPaymentAddEdit = 1;
                        console.log('A7 watchPaymentAddEdit');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q5.resolve($scope.promise);
                return q5.promise;



            }

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )


                return q55.promise;
            }

            $scope.gotoAnchor = function(x) {
                console.log("inside function gotoAnchor");
                var newHash = x;
                if ($location.hash() !== newHash) {
                    // set the $location.hash to `newHash` and
                    // $anchorScroll will automatically scroll to it
                    $location.hash(x);
                } else {
                    // call $anchorScroll() explicitly,
                    // since $location.hash hasn't changed
                    $anchorScroll();
                }
            };

            $scope.init();


        }

    })

    $scope.DataLoad();

}])