/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("showHideService", ["$http", "$q",  function ($http, $q) {

    this.showHide = function(showLettings, showVendor, showLandlord, showTenant, showViewings, showAccountHolder, showCloudertyAdmin,  accountHolder, cloudertyAdmin, userType) {

        var self = this;
        self.isVisible = false;
        //console.log("********** showHide service *********");

        if(showCloudertyAdmin == 1){

            if(cloudertyAdmin == '1'){
                //console.log("showCloudertyAdmin: " + showCloudertyAdmin);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showLettings == 1){

            if(userType == '1'){
                //console.log("showLettings: " + showLettings);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(userType == '2'){

            if(showVendor == '1'){
                //console.log("userType: " + userType);
                //console.log("showVendor: " + showVendor);
                //console.log("self.isVisible: " + self.isVisible);
                self.isVisible = true;
                return self.isVisible;
            }/*else{

                self.isVisible = false;
                return self.isVisible;
            }*/

        }
        if(showLandlord == 1){

            if(userType == '3'){
                //console.log("showLandlord: " + showLandlord);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showTenant == 1){

            if(userType == '4'){
                //console.log("showTenant: " + showTenant);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showViewings == 1){

            if(userType == '5'){
                //console.log("showViewings: " + showViewings);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        //console.log("self.isVisible end: " + self.isVisible);
        return self.isVisible;
    }


}])
