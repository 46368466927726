/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("tenantArrearsAccountViewCtrl", ["$scope", "$http", "$stateParams", "Upload", "$rootScope", "emailService", "$window", "apiService", "$q", "truncateSliceService", "postUserActivityService", "sendEmailInternalPostNote", "$state", function ($scope, $http, $stateParams, Upload, $rootScope, emailService, $window, apiService, $q, truncateSliceService, postUserActivityService, sendEmailInternalPostNote, $state)  {


    //$scope.obj = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.search = "";
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();
    $scope.property = [];
    $rootScope.property = {};
    $rootScope.property.Property_name = "";
    $rootScope.property.Unit_number = "";
    $rootScope.tenancy_id = $stateParams.tenancyID;

    $scope.tenancies = [];
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.tenantsRentalTransactions = {};
    $scope.notes = {};
    $scope.arrearsProcessesLinkedToAccount = [];
    //$scope.obj.notes = {};

    console.log('$stateParams.id: ' + $stateParams.tenancyID);

    /*     Rental Income     */


    $scope.tenanciesFunction = function(id, status) {

        console.log("function tenanciesFunction zzzzzzzzzzzzzzzzzzzzzzzzzzzz");
        $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
        $scope.tenancies.pageno = $scope.pageno;
        $scope.tenancies.Tenancy_ID = id;
        $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
        $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
        $scope.tenancies.Status = status;
        //$scope.pageno = 1;

        if($scope.tenancies.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.tenancies.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.tenancies.search = $scope.search;
        }else{

            $scope.tenancies.search = "*";
        }

        //$scope.obj.search = "*";

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.tenancies(id, $scope.tenancies).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
                /*
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.paginationData = response.data.data;
                $scope.tenancies = response.data.data;
                $scope.rentTotal = response.data.rentTotal;
                $scope.totalTaskCount = response.data.totalCount;
                */
                if(status == '60'){

                    $scope.arrearsProcessesLinkedToAccount = response.data.arrearsProcessesLinkedToAccount;

                }else{

                    $scope.$parent.obj2 = {};
                    $scope.$parent.obj = response.data;

                    $rootScope.Property_ID = $scope.obj.data.Property_ID;
                    $rootScope.property.Property_name = $scope.obj.data.Property_name;
                    $rootScope.property.Unit_number = $scope.obj.data.Unit_number;


                }

                console.log(response);
                console.log("tenancies API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }



    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })


    //close all accordions
    $('.data').hide();
    $('.tenancy-arrears-processes-data').is(":visible");
    $('.tenancy-arrears-processes-data').show();
    //$scope.$parent.tenancy_id = $stateParams.id;



    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.addProcessNote = function (arrearsProcessesLinkedToAccount) {



        console.log('arrearsProcessesLinkedToAccount.NotesAdd: ' + arrearsProcessesLinkedToAccount.NotesAdd);


        var message = arrearsProcessesLinkedToAccount.NotesAdd;
        var to_email = "c.thomas@platinumcrown.co.uk";
        var subject = "Test subject line";
        //message  = "";
        var property = "";
        var task_name = "Arrears note";
        var note_type = "Arrears";
        var note = message;
        var note_category_type = "Arrears";
        var tenancy_id = $rootScope.tenancy_id;
        var unit_number = 4;
        var email_signature = "My ma";
        var note_type_id = "3";

        $scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, tenancy_id, unit_number, email_signature, note_type_id);

    }



    $scope.updateProcessCompleted = function (arrearsProcessesLinkedToAccountRecord) {

        var q62 = $q.defer();
        console.log('$rootScope.tenancy_id: ' + $rootScope.tenancy_id);

        console.log(arrearsProcessesLinkedToAccountRecord);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID: ' + arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Completed_Checkbox: ' + arrearsProcessesLinkedToAccountRecord.Completed_Checkbox);
        if(arrearsProcessesLinkedToAccountRecord.Completed_Checkbox == 'true'){


            $scope.tenancies.Completed = 1;

            //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);

        }else if(arrearsProcessesLinkedToAccountRecord.Completed_Checkbox == 'false'){

            $scope.tenancies.Completed = 0;

        }

        $scope.tenancies.Processes_Linked_to_Account_ID = arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID;

        $scope.tenanciesFunction($rootScope.tenancy_id, '61');

        return q62.promise;
    }

    $scope.getTenancy = function () {

        var q5 = $q.defer();
        console.log('$rootScope.tenancy_id: ' + $rootScope.tenancy_id);
        $scope.tenanciesFunction($rootScope.tenancy_id, '5');

        return q5.promise;
    }

    $scope.getArrearsProcessesLinkedToAccount = function (pageno) {

        var q60 = $q.defer();
        console.log('in function getArrearsProcessesLinkedToAccount');
        $scope.tenanciesFunction($rootScope.tenancy_id, '60');

        return q60.promise;
    }

    $scope.tenancyAgreementView = function () {

        console.log("$stateParams.id: " + $stateParams.tenancyID);
        $state.go('tenancy-agreement', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});


    }






    $scope.getTenancies = function(pageno) {
        //$scope.getRentalTransactions = function(pageno) {
        console.log('Function getTenancies');
        $scope.itemsPerPage = '20';
        $scope.tenancies = [];
        var q64 = $q.defer();
        if ($scope.search)
        {
            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response){


                $scope.tenancies = response;
                $scope.$parent.tenancies = $scope.tenancies;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View Tenancy: ' +  $scope.tenancies.Property_name + ' - ' + $scope.tenancies.First_Name + ' - ' + $stateParams.tenancyID);
                console.log('Function getTenancies complete');
                q64.resolve(response);
            });

        }
        else
        {

            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;

                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View Tenancy: ' +  $scope.tenancies.Property_name + ' - ' + $scope.tenancies.First_Name + ' - ' + $stateParams.tenancyID);
                q64.resolve(response);
                $scope.sort('ID');
            });
        }
        return q64.promise;
    }


    //$scope.getTenancies($scope.pageno);
    //$scope.getTenantRentalTransactions3();

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }



    if($stateParams.tenancyID) {
        $scope.getTenancyPromise();
    }

    $scope.uploadFile = function(file) {

        if ($scope.picFile) {
            Upload.upload({
                url: 'api/files',
                method: 'POST',
                file: file,
                data: {
                    'Account_Holder_ID': $rootScope.decodedToken.Account_Holder_ID,
                    'File_Type': 'note',
                    'ID': $scope.posted_note.note_id,
                    'File_Category': 4
                }
            }).then(function() {
                $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                    $scope.obj.notes = response.notes;
                    $scope.note_loading = false;
                })
            });
        } else {
            $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                $scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })
        }

    }

    $scope.note = {};
    $scope.note.id = $stateParams.tenancyID;
    $scope.note_loading = false;


    $scope.postNote = function (note_content) {

        console.log("Am I in notes:" + note_content);

        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.note_loading = true;
        $scope.note.user_id = $scope.decodedToken.id;
        $scope.note.note_type = "Arrears";
        $scope.note.date = date;
        $scope.note.note = note_content;
        $scope.note.note_type_id = 3;

        var note_type = $scope.note.note_type;


        $http.post("api/notes", $scope.note)

            .success(function (response) {

                $scope.posted_note = response.posted;

                $scope.uploadFile($scope.picFile, note_type);
                $scope.note.note = "";

                $scope.getNotes();
            })


    }



    $scope.getNotes = function () {

        console.log("Tenancy ID: " + $stateParams.tenancyID);
        var q59 = $q.defer();
        var note_type = "Arrears";
        if ($rootScope.decodedToken.User_Type_ID == 1) {
            console.log("getTaskNotes 1: " + $rootScope.decodedToken.User_Level);
            $http.get("api/notes/" + note_type + '/' + $stateParams.tenancyID, $scope.note).success(function (response) {
                $scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })

            return q59.promise;
        }
        else if ($rootScope.decodedToken.User_Type_ID == 2) {
            console.log("getTaskNotes 2: " + $rootScope.decodedToken.User_Level);
            console.log("Task ID: " + $stateParams.tenancyID);
            $http.get("api/notes/" + note_type + '/' + $stateParams.tenancyID + '/' + $rootScope.decodedToken.Vendor_ID, $scope.note).success(function (response) {
                $scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })

            return q59.promise;
        }
    }





    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.actionSelect = function(search_documents_action){

        if(search_documents_action == "1") {
            console.log("$scope.obj.data.Licence_Key: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  " + $scope.obj.data.Licence_Key);

            //$scope.tenanciesFunction($stateParams.tenancyID, '20');


            $rootScope.paymentTransactionInputs = {};
            $rootScope.paymentTransactionInputs.Status = '20';
            $rootScope.paymentTransactionInputs.Payment_Transaction_Fixed = 1;
            $rootScope.paymentTransactionInputs.Monthly_rental_price = $scope.obj.data.Monthly_rental_price;
            //$scope.pageno = 1;

            if($scope.tenancies.Tenancy_ID){
                //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{

                $scope.tenancies.Tenancy_ID = "*";
            }


            if($scope.search){

                $scope.tenancies.search = $scope.search;
            }else{

                $scope.tenancies.search = "*";
            }




            //apiService.tenancies($scope.obj.data.Tenancy_ID, $scope.tenancies);


            console.log($scope.tenancies);

            $state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});



            console.log("View venancy agreement - room let");
        }else if(search_documents_action == "2") {
            $state.go('tenancy-agreement-single-let', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("View venancy agreement - single let");
        }else if(search_documents_action == "4") {
            $state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  $scope.obj.data.Tenant_ID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Edit tenant");
        }else if(search_documents_action == "3") {
            $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Edit tenancy");
        }else if(search_documents_action == "5") {
            $scope.tenancyAgreementSend("1");
            //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Send tenancy");
        }else {

        }
    };

    $scope.sendEmailInternalPostNote = function(to_email,subject,message, property, task_name, note_type, note, note_category_type, tenancy_id, unit_number, email_signature, note_type_id){

        message = message + '\n\n' + email_signature;

        if(to_email==null){
            alert("Enter an email address");
        } else {

            note_category_type = "Email";
            $scope.obj.select_note_type = "Email";
            //from_email = "notifications@clouderty.co.uk"

            if (unit_number == null) {


            } else {

                property = property + " - Unit" + unit_number
            }
            ;

            console.log("sendEmailInternalPostNote Controller Function - 1: " + to_email);
            //$scope.b = sendEmailInternalPostNote.postNote(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email, subject, message, property, task_id, unit_number, email_signature);


            var date = new Date();
            date.setHours(date.getHours() + 1);

            $scope.note_loading = true;
            $scope.note.user_id = $scope.decodedToken.id;
            $scope.note.note_type = note_type;
            $scope.note.date = date;
            $scope.note.note = note;
            $scope.note.id = tenancy_id;
            $scope.note.Tenancy_ID = tenancy_id;
            $scope.note.note_type_id = note_type_id;

            console.log("sendEmailInternalPostNote - 1: " + $scope.note.id);


            //$scope.note.note_category_type = note_category_type;
            $scope.note.select_note_type = note_category_type;

            $scope.task_name = task_name;




            console.log("sendEmailInternalPostNote - 3: hello apinotes");
            $http.post("api/notes", $scope.note)
                .success(function (response) {

                    $scope.posted_note = response.posted;

                    $scope.uploadFile($scope.picFile, note_type);
                    $scope.obj.note = "";
                    console.log("sendEmailInternalPostNote - 4: in apinotes");
                    // Send email confirmation | Task Notes
                    /*
                    if (confirm('Are you sure you want to send an email notification?')) {

                        //var from_email = "Clouderty | Note Created <notifications@clouderty.co.uk>";
                        if ($scope.obj.data.Address_1 == null){
                            var from_email = "Clouderty | Note Created"  + " <notifications@clouderty.co.uk>";
                            var subject = "By: " + $rootScope.decodedToken.Name + " | " + $scope.task_name + " | " + "Task ID: " + $scope.posted_note.id + " | Note ID: " + $scope.posted_note.note_id;
                            var message = "Note ID: " + $scope.posted_note.note_id + "\n" +
                                "As Part Of Task: " + $scope.posted_note.id + "\n" +
                                "Created By: " + $rootScope.decodedToken.Name + "\n" +
                                //"Task name: " + task_name + "\n\n" +
                                "Task name: " + $scope.task_name + "\n\n" +

                                "Note Content: " + $scope.posted_note.note;


                        } else {
                            var from_email = "Clouderty | Note Created | " +  $scope.obj.data.Property_name  + " <notifications@clouderty.co.uk>";
                            var subject = "By: " + $rootScope.decodedToken.Name + " | "  + $scope.obj.data.Property_name + " | " + $scope.task_name + " | " + "Task ID: " + $scope.posted_note.id + " | Note ID: " + $scope.posted_note.note_id;
                            var message = "Note ID: " + $scope.posted_note.note_id + "\n" +
                                "As Part Of Task: " + $scope.posted_note.id + "\n" +
                                "Created By: " + $rootScope.decodedToken.Name + "\n" +
                                //"Task name: " + task_name + "\n\n" +
                                "Task name: " + $scope.task_name + "\n" +
                                "Property: " + $scope.obj.data.Address_1 + "\n\n" +
                                "Note Content: " + $scope.posted_note.note;
                            console.log("sendEmailInternalPostNote - 6: Service alert3");

                        }
                        var to_email = "rentals@platinumcrown.co.uk";


                        //"Note Content: " + $scope.posted_note.Description + "\n\n" +
                        //"Note Content: " + $scope.posted_note.data.Description + "\n\n";
                        //"Task name: " + $scope.posted_note.obj.data.Description;
                        //
                        console.log("Send email");
                        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                        console.log("Email sent");
                        // Send email code | Task Notes

                    } else {
                        // Do nothing!
                    }
                    */
                    // email send finish
                    console.log("Service alert4");

                })

            /*

           */

            return 0;


            //$state.reload();

        }


    };

    $scope.tenancyAgreementSend = function (status) {

        //console.log("Property_ID =" + $scope.landlordTransactions.Property_ID)
        //console.log("Property_ID 2 =" + $scope.landlord.Property_ID)
        //$state.go('landlord-statement', {itemsPerPage: '200', pagenumber: '1', propertyID: $scope.landlord.Property_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, yearRelatedTo:  $scope.landlord.landlord_transaction_year, monthRelatedTo: $scope.landlord.landlord_transaction_month});
        $scope.url = "";
        $scope.url = $state.href('tenancy-agreement-room',
            {
                id:  $scope.obj.data.Tenancy_ID,
                accountHolderID: $rootScope.decodedToken.Account_Holder_ID

            });
        //window.open($scope.url,'_blank');

        $scope.url2 = "https://management.clouderty.co.uk/" + $scope.url;

        console.log("$scope.url: " + $scope.url);
        console.log("$scope.url2: " + $scope.url2);

        console.log("Promise landlordStatementSend");
        var q73 = $q.defer();
        $scope.url = "";
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();

        //$scope.$parent.obj.DescriptionTruncated = $scope.obj.sampleText = truncateSliceService.truncateSliceService($scope.$parent.obj.Description, 25);

        var from_email = "Clouderty | Tenancy Agreement | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " <notifications@clouderty.co.uk>";
        var subject = "Tenancy sent by: " + $rootScope.decodedToken.Name +  " | " + "Tenancy ID: " + $scope.obj.data.Tenancy_ID;
        var message = "Tenancy ID: " + $scope.obj.data.Tenancy_ID + "\n" +
            "Tenancy Agreement: " +$scope.url2 + "\n" +
            "Created By: " + $rootScope.decodedToken.Name + "\n";
        /*"Task name: " + task_name + "\n\n" +
        "Task name: " + $scope.$parent.obj.Description + "\n" +
        "Property: " + $scope.$parent.obj.Property_name + "\n\n" +
        "Note Content: " + $scope.posted_note.note;*/


        var to_email = $rootScope.decodedToken.Accounts_Email;
        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
        var to_email = $rootScope.decodedToken.Notifications_Email;

        //"Note Content: " + $scope.posted_note.Description + "\n\n" +
        //"Note Content: " + $scope.posted_note.data.Description + "\n\n";
        //"Task name: " + $scope.posted_note.obj.Description;
        //$rootScope.sendEmail(from_email, to_email, subject, message);
        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);



        q73.resolve($scope.promise);

        return q73.promise;

    }

    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

    };

    var map;
    var geocoder;

    $scope.getTenancyPromise = function(){


        console.log("Some test 2: Add transaction");
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A1");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2");
                $scope.getTenancy();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A5");
                $scope.getTenancies($scope.pageno);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A5b");
                $scope.getArrearsProcessesLinkedToAccount('1');
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log(" A3");
                console.log("tenancy payment transaction - $scope.obj.data.Property_name: " + $scope.obj.data.Property_name);
                $scope.payment_transaction.Property_name = $scope.obj.data.Property_name;
                $rootScope.property.Property_name = $scope.obj.data.Property_name;
                $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                //$rootScope.tenancy_id = $stateParams.id;
                console.log("xxxxxxxxxxxxxx - $rootScope.Unit_number: " + $rootScope.property.Unit_number);
                console.log("xxxxxxxxxxxxxx - $rootScope.Property_name: " + $rootScope.property.Property_name);
                console.log("xxxxxxxxxxxxxx - $rootScope.tenancy_id: " + $rootScope.tenancy_id);

                //$scope.payment_transaction.Property_name = $scope.$parent.tenancies.Property_name;
                //$scope.initialPageLoad();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                //$scope.getNotes();
                console.log('A7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

        //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});

    };

    $scope.initMap = function() {

        geocoder = new google.maps.Geocoder();
        map = new google.maps.Map(document.getElementById('bndmap'), {
            zoom: 15,
            center: {lat: 51.8846631, lng: 0.922104},
            mapTypeId: 'terrain',
            scrollwheel: true,
        });


        //initialize();
        console.log($scope.obj.data.Postcode);
        var address = $scope.obj.data.Postcode;
        geocoder.geocode( { 'address': address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    title:"Location of user"
                });

            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });

    }

    $scope.ExportPaymentTransactions = function() {

        console.log('$scope.tenantsRentalTransactions in parent controller:' + $scope.tenantsRentalTransactions);
        console.log($rootScope.tenantsRentalTransactions.data);
        //console.log($scope.tenantsRentalTransactions);
        console.log('$scope.totalCount in parent controller:' + $scope.totalCount);

        $scope.getHeader =  ["Property Name", "First Name", "Surname", "Monthly Rent", "Amount Received", "Amount Esxpected", "Account Balance", "Current"];
        $scope.filename = "Payment Transactions - " + $scope.obj.data.Property_name + " - " + $scope.obj.data.Full_name;
        $scope.exportCSVData = exportCSVService.exportCSVService($rootScope.tenantsRentalTransactions.data);
    }

}])