/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("userActivitySearchCtrl", ["$scope", "$http", "$state", "$rootScope", "$q", "apiService", "postUserActivityService", function ($scope, $http, $state, $rootScope, $q, apiService, postUserActivityService) {

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("Test A");
    $scope.userActivity = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.viewUserActivity = function(userActivityID){
        $state.go("lettings-main-nav.lettings-side.user-activity-view", {id: userActivityID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});

    }

    $scope.getData = function(pageno) {
        $scope.tenancies = [];
    
        if ($scope.search)
        {
            $http.get("api/user-activity-search/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){
    
                $scope.userActivity = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/user-activity-all/" + $scope.itemsPerPage + "/" + pageno).success(function(response){
    
                $scope.userActivity = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                //$scope.sort('ID');
            });
        }
    }



 
   
    $scope.getData($scope.pageno);

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }




    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(progress == "EXPIRED TENANCIES") {
            //$scope.progress.date_completed = date;
            console.log("Expired Tenancies");
            $scope.getDataExpired(1)
        } else if (progress == "EXPIRED TENANCIES WITHIN 1 MONTH") {
            console.log("Expired Tenancies within 1 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredOneMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 2 MONTH") {
            console.log("Expired Tenancies within 2 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredTwoMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getDataCurrent(1)
            //$scope.progress.date_completed = null;
        }
        else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData(1)

        } else{

        }
 
    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

}]);