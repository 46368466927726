/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("DialogController", ["$scope", "$mdDialog", "$rootScope", function ($scope, $mdDialog, $rootScope) {

    $scope.userName = $rootScope.decodedToken.Name;
    $scope.userEmail = $rootScope.decodedToken.Email;
    console.log($rootScope.decodedToken.Name);
    $scope.logout = function() {
        $mdDialog.cancel();
        $rootScope.logout();

    }

    $scope.hide = function() {
        $mdDialog.hide();
    };
    $scope.cancel = function() {
        $mdDialog.cancel();
    };
    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };

    console.log("DialogController singular");

}])