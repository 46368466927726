/**
 * Created by Aaron on 04/07/2016.
 */
clouderty.controller("accountHolderUserAddEditCtrl", ["$scope", "$rootScope", "$state", "postUserActivityService", "$location", "$http", "$window", "$cookies", function ($scope, $rootScope, $state, postUserActivityService, $location, $http, $window, $cookies) {
    //$rootScope.decode = function
     $scope.init = function() {
        if($cookies.getObject("userName")!==undefined && $cookies.getObject("password")!==undefined){
            self.emailAddress=$cookies.getObject("userName");
            self.password=$cookies.getObject("password");
            //console.log("Hello 2:" + $cookies.getObject("userName"));
            //console.log("Password 2:" + $cookies.getObject("password"));


            $scope.login_details = {};

            $scope.login_details.email = $cookies.getObject("userName");
            //$scope.login_details.email = "khulugkulglh";
            $scope.login_details.password =  $cookies.getObject("password");


            $http.post("api/authenticate", $scope.login_details)
                .success(function(response) {

                    if (response.success == false) {
                        alert("Incorrect Login Details");
                    }
                    else  {
                        if ($window.sessionStorage.token) {
                            $window.sessionStorage.removeItem("token");
                        }
                        $window.sessionStorage.token = response.token;
                        $rootScope.decode();

                        //console.log("User 3:" + $scope.login_details.email);
                        //console.log("Password 3:" + $scope.login_details.password);

                        //pre-load states for auth checks


                        if ($rootScope.decodedToken.User_Type_ID == 1) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard");
                        } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                        } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                        }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard");
                        }

                    }
                })

        }
         else{

        }

    }

    $scope.init();

    $scope.goToSignup = function() {

        console.log("In goToSignup function");
        $state.go("signup-header.signup");

    }

    $scope.login = function () {
        $http.post("api/authenticate", $scope.login_details)
            .success(function(response) {

                if (response.success == false) {
                    alert("Incorrect Login Details");
                }
                else  {
                    if ($window.sessionStorage.token) {
                        $window.sessionStorage.removeItem("token");
                    }
                    $window.sessionStorage.token = response.token;
                    $rootScope.decode();

                    //$rootScope.$apply();
                    $cookies.putObject("userName", $scope.login_details.email);
                    $cookies.putObject("password", $scope.login_details.password);
                    //console.log("Hello:" + $scope.login_details.email);
                    //console.log("Password:" + $scope.login_details.password);

                    console.log("$rootScope.decodedToken.User_Type_ID: " + $rootScope.decodedToken.User_Type_ID);

                    console.log("$rootScope.decodedToken.User_Level_ID: " + $rootScope.decodedToken.User_Level_ID);

                    if ($rootScope.decodedToken.User_Type_ID == 1) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard");
                    } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                    } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                    }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard");
                    }


                }
            })
    }

    $scope.loginAuto = function () {

        $scope.login_details.email = $cookies.getObject("userName");
        $scope.login_details.password =  $cookies.getObject("password");


        $http.post("api/authenticate", $scope.login_details)
        .success(function(response) {

            if (response.success == false) {
                alert("Incorrect Login Details");
            }
            else  {
                if ($window.sessionStorage.token) {
                    $window.sessionStorage.removeItem("token");
                }
                $window.sessionStorage.token = response.token;
                $rootScope.decode();

                console.log("User 3:" + $scope.login_details.email);
                console.log("Password 3:" + $scope.login_details.password);

                if ($rootScope.decodedToken.User_Type_ID == 1) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard");
                } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard");
                }

            }
        })
    }
}])