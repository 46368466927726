/**
 * Created by Aaron on 04/07/2016.
 */

clouderty.run(["$rootScope", "$location", "$http", "$cacheFactory", "$window", "$state", "jwtHelper", "$cookies", "$stateParams", "$state", "openNewWindowService", "apiService", "$q", "stateAuthCheckService", "$timeout", "notificationsService", function ($rootScope, $location, $http, $cacheFactory, $window, $state, jwtHelper, $cookies, $stateParams, $state, openNewWindowService, apiService, $q, stateAuthCheckService, $timeout, notificationsService) {

    //var fs = require('fs');

    var timer = 0;

    var statesInput = {};
    var stateResponse = {};

    setInterval(timerInc, 11111111113000);

    setInterval(function(){
        console.log("function setInterval fetchNotification");
        if($rootScope.notificstionAlertLastID == 0){
            console.log("function setInterval fetchNotification inside if");
            $rootScope.fetchNotification()
        }

    //}, 300000); //every five minutes /// 10000 = 1 second
    }, 30000); //every five minutes /// 10000 = 1 second



    //set common routeScope variables
    $rootScope.watchPaymentAddEdit = 0;
    $rootScope.watchAwardsSurround = 0;
    $rootScope.watchNotes = 0;
    $rootScope.$state = $state;
    $rootScope.cloudertyBaseURL = "https://management.clouderty.co.uk/#/";

    $rootScope.stateProcessAccountView = "lettings-main-nav.lettings-side.process-account-view.notes";
    $rootScope.stateAwardsProcessAccountView = "lettings-main-nav.lettings-side.awards-process-account-view.notes";
    $rootScope.statePaymentTransactionAddSurround = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
    $rootScope.stateTenancyAgreementExternal = "tenancy-agreement-room.payment";
    $rootScope.stateTenancyView = "lettings-main-nav.lettings-side.lettings-view-tenancy.notes";
    $rootScope.stateTaskView = "lettings-main-nav.tasks-side.task-view.notes";
    $rootScope.stateTaskContractorsView = "contractors-main-nav.contractors-tasks-side.task-view.notes";


    /*
    $rootScope.$watch('notificstionAlertLastID' , function(newValues, oldValues) {

        if($rootScope.countNotificationsExisting > 0){



        }

    })
    */

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {

        /*
        if (error.redirectTo) {
            console.log('errooorrr 1');
            //$state.go(login.signin);
            //event.preventDefault();
        } else {
            $state.go('error', {status: error.status})
            //login.signin
            console.log('errooorrr 2');
        }
        console.log('Trying state change cancelled');
        */


        //here you can go to whatever state you want, and you also have a lot of information to save if needed
    });

    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
        console.log("State change occurred resolve >>>>");

        console.log("SSSSSSSSSSSSS - You are coming from state: " + fromState.name);
        console.log("SSSSSSSSSSSSS - You are going to state: " +  toState.name);

    });

    $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
        console.log('$stateChangeStart');
        console.log('fromState: ' + fromState.name);
        if(!fromState.name){

            console.log('fromState is empty: ' + fromState.name);
            fromState.name = 'lettings-main-nav';
            $rootScope.noFromState = 1;
            console.log('fromState: ' + fromState.name);
        }

    })

    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
        console.log("internal or external login");
        //if logged in as an external user, logout on state change

        console.log("AAAAAAAAAAAAAAAAAAA - You are coming from state: " + fromState.name);
        console.log("AAAAAAAAAAAAAAAAAAA - You are going to state: " +  toState.name);

        console.log("$cookies.getObject('userName'): " +  $cookies.getObject('userName'));

        if ($cookies.getObject('userName') === "external@clouderty.co.uk") {


            console.log('if external@clouderty.co.uk');
            var deferred = $q.defer();

            getGlobalPromise = function() {
                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {
                        console.log("API getGlobalPromise");
                        q55.resolve(response);
                    }
                )
                return q55.promise;
            }

            var statesObj = {};
            var statesObjResolved = {};
            var myValue = "";

            statesFunction = function() {

                console.log('in function statesFunction');
                var q55555 = $q.defer();

                statesObj.Account_Holder_ID = 35;
                statesObj.Clouderty_System_Administrator = 0;
                /*
                statesObj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                */
                statesObj.User_Level = 100;
                statesObj.User_Type_ID = 6;
                statesObj.pageno = 1;
                statesObj.itemsPerPage = 20;
                //statesObj.Account_Holder_ID = 1;
                statesObj.Status = 51;
                statesObj.search = toState.name;
                console.log(statesObj);

                apiService.states('*', statesObj).then(
                    function (response) {

                        console.log('State results');
                        console.log(response);
                        statesObjResolved = response.data.data;

                        console.log(statesObjResolved);
                        q55555.resolve(response);
                    }
                )

                return q55555.promise;
            }
            var promise = statesFunction();

            promise
                .then(function (data) {

                    console.log("A2");
                    console.log("statesObjResolved.State_Description " + statesObjResolved.State_Description);

                    if(toState.name === statesObjResolved.State_Description){
                        console.log("statesObjResolved.State_Description " + statesObjResolved.State_Description);
                    }
                    else{

                        console.log("Take logout action inside: " + toState.name);
                        //$rootScope.logoutExternalPage();

/*
                        if(toState.name == 'landlord-statement-license'){

                        }else{



                        }
                        */
                        //$rootScope.logoutExternal();

                    }

                    return getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log('A7');
                    myValue = data;
                }
                , function (err) {
                    myValue = err;
                })

            deferred.resolve(promise);
            return deferred.promise;

        }



    });

    getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $rootScope.statesFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        //var statesInput = {};

        statesInput.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        statesInput.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        statesInput.itemsPerPage = 20;
        statesInput.pageno = 1;
        statesInput.Status = status;
        statesInput.State_ID = id;

        if(statesInput.State_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            statesInput.State_ID = "*";
        }



        var qstatesFunction = $q.defer();

        console.log(statesInput);
        apiService.states(id, statesInput).then(
            function (response) {

                console.log("status: " + status);

                stateResponse = response.data;
                states = response.data.data;
                stateAdded = 1;





                console.log("states API successful");

                console.log(stateResponse);
                console.log(stateResponse.data.State_Description);
                qstatesFunction.resolve(response.data.data);


            },
            function () {
                console.log("API Service Error: states")
            }

        );



        return qstatesFunction.promise;
    }

    $rootScope.stateSecurityCheck = function (path) {
        return $location.path() == path;
    };

    /*
    function externalPages() {


    }
    */

    //*/

    //logout on timeout
    function timerInc() {


        /*
        if ($window.sessionStorage.token != undefined) {
            timer++;
            if(timer === 600) {
                alert("You have been inactive for 10 mins, now logging out.");
                $rootScope.logout();
            }
        }
        */
    }

    $("body").mousemove(function () {
        timer = 0;
    })

    //console.log("$stateParams.propertyID mmmm: " + $stateParams.propertyID);

    console.log("$stateParams.propertyID: " + $stateParams.propertyID);

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
        console.log("to state " + toState.name);
        console.log("from state  " + fromState.name);
        console.log("settings flag");
        //if ( toState.data.auth === 'LoggedIn' && !$window.sessionStorage.token) { //modified: 31/01/2018
        if ( toState.data.auth === 'LoggedIn' && !$window.sessionStorage.token) {

            console.log("LoggedIn page");
            event.preventDefault();
            $state.go('login.sign-up');
            return false;
        }else if(toState.data.auth === 'LoggedInExternal'){
            //event.preventDefault();
            console.log("External page ");
            return false;
        }
        console.log("end of watch $stateChangeStart");
    })

    $rootScope.reload = function () {
        console.log("state reloaded");
        //$window.location.reload();
        $state.go($state.current, {}, {reload: true});
        //$route.reload();
        /*$state.transitionTo($state.current, $stateParams, {
            reload: true, inherit: false, notify: true
        });*/
    }

    //}
    $rootScope.openNewWindow = function (url, var1, var2, var3, var4, var5, var6, var7, var8, var9, var10) {
        openNewWindowService.newWindow(url, var1, var2, var3, var4, var5, var6, var7, var8, var9, var10);
        console.log("openNewWindow function");
    }

    $rootScope.isCurrentPath = function (path) {
        return $location.path() == path;
    };

    $rootScope.decode = function () {
        console.log("inside function decode");
        if ($window.sessionStorage.token != undefined) {

            console.log("assign data to decodedToken");
            $rootScope.decodedToken = jwtHelper.decodeToken($window.sessionStorage.token);
        }
    }

    $rootScope.sendEmail = function (from, to, subject, message, recipientName, cc, attachmentFileName, attachmentFileLocation) {
         console.log("inside rootscope function sendEmail");
         $rootScope.email = {};
         $rootScope.email.from = from;
         $rootScope.email.to = to;
         $rootScope.email.subject = subject;
         $rootScope.email.message = message;
         $rootScope.email.recipientName = recipientName;
         $rootScope.email.attachmentFileName = attachmentFileName;
         $rootScope.email.attachmentFileLocation = attachmentFileLocation;
         $rootScope.email.cc = cc;
         $http.post('api/email', $rootScope.email).success(function(response) {
         });
     };

    $rootScope.sendEmail2 = function (from, to, subject, message, recipientName, cc) {

        console.log("Email value: " + to);
        $rootScope.email = {};
        $rootScope.email.from = from;
        $rootScope.email.to = to;
        $rootScope.email.subject = subject;
        $rootScope.email.message = message;
        $rootScope.email.recipientName = recipientName;
        $rootScope.email.cc = cc;
        //$http.get('/api/email.php', $rootScope.email).success(function(response) {
        //$http.get('api/email.php', $rootScope.email).success(function(response) {
        $http.post('api/email', $rootScope.email).success(function(response) {
        });
    };

    $rootScope.logout = function () {
        console.log("logout!");

        if(confirm("Do you want to logout", 'CLOUDERTY NOTIFICATION')){
            window.location.href = "#/login";
            $window.sessionStorage.removeItem("token");
            $rootScope.decodedToken = "";

            $cookies.remove("userName");
            $cookies.remove("password");

        }else{


        }


    }

    $rootScope.logoutExternal = function () {
        console.log("logout!");

        if(confirm("Do you want to logout", 'CLOUDERTY NOTIFICATION')){
            window.location.href = "#/login";
            $window.sessionStorage.removeItem("token");
            $rootScope.decodedToken = "";

            $cookies.remove("userName");
            $cookies.remove("password");

        }else{


        }


    }


    /*
    $rootScope.reload = function () {
        console.log("state reloaded");
        $window.location.reload();
        //$route.reload();
        /*$state.transitionTo($state.current, $stateParams, {
            reload: true, inherit: false, notify: true
        });
    }
    */

    $rootScope.logoutExternalPage = function () {
        console.log("logout!");
        $window.sessionStorage.removeItem("token");
        $rootScope.decodedToken = "";
        //window.location.href = "#/login";
        $cookies.remove("userName");
        $cookies.remove("password");
        $cookies.remove("Notifications_Email");
    }

    $rootScope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )

        return q55.promise;

    }

    $rootScope.notificationsAlertsCountChange = function () {
        console.log("inside function notificationsAlertsCountChange");
        var audioElement = document.createElement('audio');

        //browser notification count
        //var title = document.title;
        var title = "Clouderty - The all-in-one property management software";
        //var titleOriginal = document.title;
        var newTitle = '(' + $rootScope.count + ') ' + title;

        console.log("$rootScope.count: " + $rootScope.count);
        console.log("$rootScope.countNotificationsExisting: " + $rootScope.countNotificationsExisting);

        if($rootScope.countNotificationsExisting == 0){
            console.log("if countNotificationsExisting");
            if($rootScope.count > 0){
                $rootScope.countNotificationsExisting = $rootScope.count;

                if($rootScope.count > 0){

                    newTitle = '(' + $rootScope.count + ') ' + title;
                    document.title = newTitle;

                }else{

                    document.title = title;

                }


            }


        }else if($rootScope.countNotificationsExisting != $rootScope.count){
            console.log("if countNotificationsExisting 2");
            $rootScope.countNotificationsExisting = $rootScope.count;

            //browser notification count

            if($rootScope.count > 0) {

                newTitle = '(' + $rootScope.count + ') ' + title;
                document.title = newTitle;

            }else{

                document.title = title;

            }


            if($rootScope.count != 0 && $rootScope.count){
                console.log("Play notiifcationsound");

                audioElement.setAttribute('src', "sounds/angular-beep.mp3");
                audioElement.play();
            }


        }

        console.log("$rootScope.count 2: " + $rootScope.count);
        console.log("$rootScope.countNotificationsExisting 2: " + $rootScope.countNotificationsExisting);

    }

    $rootScope.saveNotification = function (notificationTypesID, notificationDescriptionAdditional, urlNotification, notificationObj) {
        console.log("inside function saveNotification");
        //console.log("URL >>> " + $location.absUrl());

        var qsaveNotification = $q.defer();

        //notification_id 1 => New Task Created
        //notification_id 2 => Task Started
        //notification_id 1 => Task Completed
        //notification_id 1 => New Task Note
        //notification_id 1 => Vendor Assigned
        //notification_id 1 => Addition Of Budget Items
        //notification_id 1 => Send Quote For Cost Of Job

        //pass any of ID you want to trigger as notification automatically shows on notifications
        //notificatioData.append("notification_id" , '2');

        //notification_from_id (Notifier Id means person ID from notification comes)
        //user_id_pm_1 (Not mandatory leave it blank if dont want to show to Proprty manager 1)
        //user_id_pm_2 (Not mandatory leave it blank if dont want to show to Proprty manager 2)

        var notificationData = {};

        var audioElement = document.createElement('audio');
        //var notificationData = new FormData();

        notificationData.notification_from_id = 0;
        notificationData.Notification_Types_ID = notificationTypesID;
        notificationData.Description_Notification_Additional = notificationDescriptionAdditional;
        notificationData.user_id_pm_1 = '0';
        notificationData.user_id_pm_2 = '0';
        notificationData.user_id_vendor = '0';
        notificationData.user_id_am = '0';
        notificationData.Property_ID = '1';
        notificationData.Status = '4';



        if(urlNotification){
            notificationData.URL_Notification = urlNotification;
        }
        else{
            notificationData.URL_Notification = $location.absUrl();
        }

        notificationData.fetchnot = true;




        console.log(notificationData);
        console.log("$rootScope.decodedToken.id: " + $rootScope.decodedToken.id);


        if($rootScope.decodedToken.id){

            notificationData.User_ID_Action = $rootScope.decodedToken.id;
            notificationData.User_ID = $rootScope.decodedToken.id;

            notificationData.User_ID_Created_By = $rootScope.decodedToken.id;
        }
        else{

            notificationData.User_ID_Action = '0';
            notificationData.User_ID = '0';
        }
        if($rootScope.decodedToken.Account_Holder_ID){

            notificationData.Account_Administrator = $rootScope.decodedToken.Account_Administrator;

        }
        else{

            notificationData.Account_Administrator = '0';

        }
        if($rootScope.decodedToken.Account_Holder_ID){

            notificationData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

        }
        else{

            notificationData.Account_Holder_ID = '0';

        }
        if($rootScope.decodedToken.Account_Holder_ID){

            notificationData.User_ID_Account_Holder = $rootScope.decodedToken.Account_Holder_ID;

        }
        else{

            notificationData.User_ID_Account_Holder = '0';

        }

        if($rootScope.decodedToken.Clouderty_System_Administrator){

            notificationData.User_ID_Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;

        }
        else{

            notificationData.User_ID_Clouderty_System_Administrator = '0';

        }

        if($rootScope.decodedToken.Clouderty_System_Administrator){

            console.log("$rootScope.decodedToken.Clouderty_System_Administrator: " + $rootScope.decodedToken.Clouderty_System_Administrator);
            notificationData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;

        }
        else{

            notificationData.Clouderty_System_Administrator = '0';

        }

        notificationData.method = "saveNotification";





        console.log("notificationData");
        console.log(notificationData);

        //console.log("notificationObj.vendors[0].vendor_id: " + notificationObj.vendors[0].vendor_id);




        var promise = $rootScope.getGlobalPromise();

        promise
            .then(function (data) {


                console.log("A2 - getNomination");


                return $rootScope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 - vendor notifications");
                console.log(notificationObj);
                if(notificationObj){
                    console.log(notificationObj.vendors);
                    notificationData.Vendors = notificationObj.vendors;
                    angular.forEach(notificationObj.vendors, function(value, key) {
                        console.log("forEach notificationObj.vendors: " + key);
                        //notificationData.Vendors = notificationObj.vendors;
                        //console.log(notificationObj.vendors);
                        //console.log("notificationObj[0].User_ID: " + notificationObj.vendors[key].User_ID);
                        //console.log("notificationObj[0].vendor_id: " + notificationObj.vendors[key].vendor_id);
                        if(notificationObj.vendors[key].User_ID){

                            console.log(notificationObj.vendors);
                            console.log("notificationObj[0].User_ID: " + notificationObj.vendors[key].User_ID);
                            console.log("notificationObj[0].vendor_id: " + notificationObj.vendors[key].vendor_id);
                            notificationData.User_ID = notificationObj.vendors[key].User_ID;

                            notificationData.Vendors = notificationObj.vendors[key];
                            notificationData.Vendor_ID = notificationObj.vendors[key].vendor_id;
                            //notificationData.Vendor_ID = notificationObj.vendors[key];

                            console.log(notificationData.Vendors);

                            apiService.notifications('*', notificationData).then(
                                function(response) {
                                    //if(err){
                                    //flashService.Error(err,false);
                                    //}else{
                                    $rootScope.notificationList = response.data.data;
                                    $rootScope.count = response.data.unreadCount;


                                    $rootScope.notificationsAlertsCountChange();

                                    //$rootScope.notificstionAlertLastID = 0;
                                    console.log("$rootScope.notificationList");
                                    console.log($rootScope.notificationList);


                                    return $rootScope.getGlobalPromise(data);
                                    //}
                                });

                            notificationData.Vendor_ID = "";
                        }

                    })

                }
                else{

                    return $rootScope.getGlobalPromise(data);

                }

                //return $scope.getGlobalPromise(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 - account holder notfication");

                apiService.notifications('*', notificationData).then(
                    function(response) {
                        //if(err){
                        //flashService.Error(err,false);
                        //}else{
                        $rootScope.notificationList = response.data.data;
                        $rootScope.count = response.data.unreadCount;


                        $rootScope.notificationsAlertsCountChange();

                        //$rootScope.notificstionAlertLastID = 0;
                        console.log("$rootScope.notificationList");
                        console.log($rootScope.notificationList);

                        return $rootScope.getGlobalPromise(data);

                    });

                //return $scope.getGlobalPromise(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                    console.log('A7a');
                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                    //audioElement.setAttribute('src', "sounds/angular-beep.mp3");
                    //audioElement.play();

                    $('.data').hide();
                },
                function (err) {


                    //myValue = err;

                })

        qsaveNotification.resolve(promise);

    }

    $rootScope.decode();

}]);

clouderty.factory('authInterceptor', ['$rootScope', '$q', '$window', function ($rootScope, $q, $window) {
    return {
        request: function (config) {
            config.headers = config.headers || {};
            if ($window.sessionStorage.token) {
                config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
            }
            return config;
        },
        response: function (response) {
            if (response.status === 401) {
                // handle the case where the user is not authenticated
            }
            return response || $q.when(response);
        }
    };
}]);

clouderty.config(['$httpProvider',function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');
}]);

clouderty.config(['$mdGestureProvider',function ($mdGestureProvider) {
    $mdGestureProvider.skipClickHijack();
}]);

clouderty.constant('moment', moment);

