/**
 * Created by Aaron on 12/07/2016.
 */
clouderty.controller("mainNavCtrl", ["$http", "$scope", "$rootScope","$mdDialog", "$mdMedia", "showHideService", "notificationsService", function($http, $scope, $rootScope, $mdDialog, $mdMedia, showHideService, notificationsService) {

    console.log("inside controller mainNavCtrl");

    $scope.notificationData = {};

    $scope.notificationData.User_ID_Action = 0;

    $scope.responsiveNavExpand = function() {
        $('.responsive-nav-menu').slideToggle("fast");
    }

    $scope.status = '  ';

    $scope.notificationList = {};

    $scope.date = new Date();

    $scope.showHide = function (showLettings, showVendor, showLandlord, showTenant, showViewings, showAccountHolder, showCloudertyAdmin) {
        //console.log("********** showHide enter *********");
        return showHideService.showHide(showLettings, showVendor, showLandlord, showTenant, showViewings,showAccountHolder, showCloudertyAdmin, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $rootScope.decodedToken.User_Type_ID);
    }


    /* Notifications other end */

}])