/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('exportCsv', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            exportobj: '=', //scope dynamic variable
            exportobjtotalcount: '=', //scope dynamic variable
            csvheader: '=', //scope dynamic variable
            filenamecsvobj: '=', //scope dynamic variable
            filenamecsv: '@', //string variable
            elementid: '@' //string variable
        },
        //template: '<div ng-hide="1"><button type="button" id="ExportCSVEle" ng-csv="exportCSVData" csv-header="getHeader"  lazy-load="true" field-seperator="," filename="{{ filename }}.csv">Export</button></div> <a ng-click="exportCSVConstruct()"> <span class="fa fa-download"></span></a>',
        template: '<div ng-hide="1"><button type="button" id="{{getElementID}}" ng-csv="exportCSVData" csv-header="getHeader"  lazy-load="true" field-seperator="," filename="{{filenamecsv}}.csv">Export</button></div> <a ng-click="exportCSVConstruct()"> <span class="fa fa-download"></span></a>',
        //templateUrl: 'views/lettings/tenancy-related.html',
        link:function(scope,elem,attrs){

        },
        controller: 'exportCSVCtrl'
    }
}])