/**
 * Created by Aaron on 22/07/2016.
 */

clouderty.controller("landlordStatementTemplateCtrl", ["$scope", "exportCSVService", "$rootScope", "$http", "$stateParams", "$state", "apiService", "reverseFilter", "sumOfValueFilter", "totalSumPriceQtyFilter", "yesNoFilter", "$filter", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$q", "$window", "$cookies", "postUserActivityService", "loginExternalWebPageService",  function ($scope, exportCSVService, $rootScope, $http, $stateParams, $state, apiService, reverseFilter, sumOfValueFilter, totalSumPriceQtyFilter, yesNoFilter, $filter, $mdDialog, $mdMedia, Upload, $timeout, $q, $window, $cookies, postUserActivityService, loginExternalWebPageService) {

    $scope.date = new Date();
    $scope.getHeaderLandlordStatement =  ["Property", "Unit", "First_Name", "Last_Name",  "Category", "Amount", "Date", "Payment_Transaction_ID", "Tenancy_ID", "Payment_Type", "Date_Received", "Description", "Proeprty_ID", "Notes", "User"];
    $scope.fileNameObj = "Landlord_Statement_Transactions";
    $scope.landlordPropertyTransactionsAll = {};


    $scope.init = function () {

        console.log("init inside ");
        //$scope.progress.progress = $scope.obj.data.Status;

            // Delete record
            console.log("ACTION: 1");
            var q5 = $q.defer();
            $scope.myValue = 0;
            $scope.promise = loginExternalWebPageService.init("1");


            $scope.promise
                .then(function (data) {

                    console.log("A2 loginfunction");
                    $rootScope.decode();
                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log("main area");
                    console.log($stateParams);
                    console.log("StateParams: " + $stateParams);
                    console.log("StateParams.id: " + $stateParams.id);
                    $scope.print = function () {
                        window.print();
                    }

                    $("html body").css("overflow-y","auto");

                    $scope.date = new Date();
                    $scope.obj = [];
                    $scope.landlordState = [];
                    $scope.landlordState.monthRelatedTo = $stateParams.monthRelatedTo;
                    $scope.landlordState.yearRelatedTo = $stateParams.yearRelatedTo;

                    $scope.landlordTransactions = [];
                    $scope.pageno = 1;
                    $scope.totalCount = 0;
                    $scope.itemsPerPage = 20;
                    $scope.properties = [];
                    $scope.properties.Property_ID = null;
                    $scope.myJsonString = [];
                    $scope.getHeader = [];
                    $scope.tenancyID = "current";
                    $scope.monthsExpiring = 0;
                    $scope.landlord = [];
                    $scope.landlord.Estate = "";
                    $scope.landlord.Estate_ID = 0;
                    $scope.landlord.Property = "";
                    $scope.landlord.Property_ID = 0;
                    $scope.landlord.Payment_Transaction_Category = "";
                    $scope.landlord.Payment_Transaction_Category_ID = 0;
                    $scope.landlord.landlord_payment_transaction_year_cbo = "";
                    $scope.landlord.landlord_transaction_year = 0;
                    $scope.landlord.landlord_transaction_month_cbo = "";
                    $scope.landlord.landlord_transaction_month = 0;
                    $scope.landlord.incomeExpenditure = "*";
                    $scope.landlordStatementTransactionsAmountLandlordPaymentSum = "";
                    //$scope.filename = "test" + ".csv";
                    $scope.landlordUpdate = {};
                    $scope.landlordUpdate.yearRelatedToBackup = "";
                    $scope.landlordUpdate.yearRelatedToBackup = "";
                    $scope.mainBlock = '1';
                    $scope.landlordTransactionsExport = {};
                    $scope.landlordPayments = {};

                    $scope.transactionTotalRent = "";
                    $scope.transactionTotalAgentManagementFee = "";
                    $scope.transactionTotalAgentManagementFeeBalance = "";


                    $scope.getGlobalPromise = function() {

                        var q55 = $q.defer();
                        apiService.getGlobalPromise().then(
                            function (response) {

                                //$scope.landlords = response.data;
                                console.log("API getGlobalPromise");

                                q55.resolve(response);

                            }
                        )


                        return q55.promise;
                    }

                    console.log("do nothing in landlordStatementTemplateCtrl");

                    $scope.landlordStatementTransactions = function(id, status) {
                        //$scope.tenancies = [];
                        //$scope.pageno = pagenumber;

                        $scope.landlordTransactions = [];

                        if ($scope.search) {
                            console.log("trying search");
                            $scope.landlord.search = $scope.search;

                        }
                        else if (!$scope.search) {
                            $scope.search = "*";
                            console.log("search null")
                        }

                        var q = $q.defer();

                        console.log("function tasksFunction");
                        $scope.landlord.Clouderty_System_Administrator = "0";
                        $scope.landlord.Account_Holder_ID = "0";
                        $scope.landlord.User_ID = "0";
                        $scope.landlord.itemsPerPage = $scope.itemsPerPage;
                        $scope.landlord.pageno = $scope.pageno;
                        $scope.landlord.Status = status;

                        console.log("$stateParams.itemsPerPage: " + $stateParams.itemsPerPage);
                        console.log("$stateParams.pagenumber: " + $stateParams.pagenumber);
                        console.log("$stateParams.propertyID: " + $stateParams.propertyID);
                        console.log("$stateParams.accountHolderID: " + $stateParams.accountHolderID);
                        console.log("$stateParams.yearRelatedTo: " + $stateParams.yearRelatedTo);
                        console.log("$stateParams.monthRelatedTo: " + $stateParams.monthRelatedTo);

                        $scope.landlord.Payment_Transaction_ID = id;
                        $scope.landlord.itemsPerPage = $stateParams.itemsPerPage;
                        $scope.landlord.pagenumber = $stateParams.pagenumber;
                        $scope.landlord.Property_ID = $stateParams.propertyID;
                        $scope.landlord.accountHolderID = $stateParams.accountHolderID;
                        $scope.landlord.yearRelatedTo = $stateParams.yearRelatedTo;
                        $scope.landlord.monthRelatedTo = $stateParams.monthRelatedTo;
                        $scope.landlord.search = "*";
                        $scope.landlord.Licence_Key = $stateParams.licenseKey;

                        console.log("$scope.landlord.User_ID" + $scope.landlord.User_ID);

                        apiService.landlordStatementTransactions(id, $scope.landlord).then(
                            function (response) {

                                //$scope.landlords = response.data;
                                console.log("API landlordStatementTransactions 111");

                                if(status == 31){

                                    $scope.landlordPropertyTransactionsAll = response.data.landlordPropertyTransactionsAll;

                                }
                                console.log(response);
                                $scope.landlordTransactions = response.data;
                                $scope.landlordTransactionsPlus = response.data.dataIncome;
                                $scope.landlordTransactionsMinus = response.data.dataExpenditure;
                                $scope.landlord = response.data.landlord[0];
                                $scope.dateLastDayofTheMonth = response.data.dateLastDayofTheMonth;
                                $scope.dateFirstDayofTheMonth = response.data.dateFirstDayofTheMonth;
                                $scope.landlordStatementTransactionsAmountSum = $filter('sumOfValue')($scope.landlordStatementTransactions, "Amount");
                                $scope.landlordStatementTransactionsAmountLandlordPaymentSum = response.data.landlordPaymentCategorySum;
                                $scope.landlordStatementTransactionsAmountAllSum = response.data.dataAllSum;

                                $scope.transactionTotalRent = response.data.transactionTotalRent;
                                $scope.transactionTotalAgentManagementFee = response.data.transactionTotalAgentManagementFee;
                                $scope.transactionTotalAgentManagementFeeBalance = response.data.transactionTotalAgentManagementFeeBalance;

                                console.log("landlordStatementTransactionsAmountAllSum: " + $scope.landlordPropertyTransactionsAll);
                                console.log($scope.landlordPropertyTransactionsAll);

                                console.log("landlordStatementTransactionsAmountAllSum: " + $scope.landlordStatementTransactionsAmountAllSum);



                                if ($scope.search == "*") {
                                    $scope.search = "";
                                }
                                else {

                                }
                                q.resolve(response);

                            }
                        )

                        if ($scope.search == "*") {
                            $scope.search = "";
                        }
                        else {

                        }
                        return q.promise;
                    }

                    console.log("landlordStatementTransactions >>>>>>>>>>>");

                    $scope.landlordStatementTransactions("0", 11);

                    $scope.getTotal = function(){
                        var total = 0;
                        for(var i = 0; i < $scope.cart.products.length; i++){
                            var product = $scope.cart.products[i];
                            total += (product.price * product.quantity);
                        }
                        return total;
                    }

                    //$scope.greeting = 'hello';
                    //$scope.filteredGreeting = reverseFilter($scope.greeting);


                    /*********  PDF Create and send email **************/

                    var form = $('.form'),
                        cache_width = form.width(),
                        a4 = [595.28, 8841.89]; // for a4 size paper width and height

                    $('#create_pdf').on('click', function () {
                        $('body').scrollTop(0);
                        createPDF();
                    });

                    //create pdf
                    function createPDF() {
                        getCanvas().then(function (canvas) {
                            var
                                img = canvas.toDataURL("image/png"),
                                doc = new jsPDF('p', 'pt');

                            doc.addImage(img, 'JPEG', 20, 20);
                            doc.save('img/techumber-html-to-pdf.pdf');
                            var pdf = btoa(doc.output());
                            var data = new FormData();
                            data.append("data", pdf);
                            var xhr = new XMLHttpRequest();
                            xhr.open('post', '/project/mail/public_html/sendgrid.php', true); //Post to php Script to save to server
                            xhr.send(data);
                            form.width(cache_width);
                        });
                    }

                    // create canvas object
                    function getCanvas() {
                        form.width((a4[0] * 1.33333) - 80).css('max-width', 'none');
                        return html2canvas(form, {
                            imageTimeout: 2000,
                            removeContainer: true
                        });
                    }

                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log("promise 6 logout");


                    $scope.propertiesLandlordViewAll = function () {
                        var qpropertiesLandlordViewAll = $q.defer();
                        console.log("in function propertiesLandlordViewAll <<<<<<<<<<<<<<<<<<<<<: " );
                        $scope.landlord.Landlord_ID = $stateParams.landlordID;
                        $scope.landlord.Licence_Key = $stateParams.licenseKey;
                        $scope.landlord.Status = '20';
                        $scope.landlord.Payment_Transaction_ID = '0';
                        $scope.landlord.itemsPerPage = '20';
                        $scope.landlord.pagenumber = '1';
                        $scope.landlord.Property_ID = '0';
                        $scope.landlord.accountHolderID = '0';
                        $scope.landlord.yearRelatedTo = '0';
                        $scope.landlord.monthRelatedTo = '0';
                        $scope.landlord.search = "*";
                        console.log($scope.landlord);

                        apiService.landlordStatementTransactions('0', $scope.landlord).then(
                            function(response) {

                                $scope.properties = response.data.data;
                                qpropertiesLandlordViewAll.resolve(response);
                                console.log("API landlordStatementTransactions: " );
                            },
                            function() {
                                console.log("api service landlordStatementTransactions failed");
                            }
                        )

                    }

                    return $scope.propertiesLandlordViewAll();

                    //return $scope.getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log("promise 6 logout");
                    //$scope.login();
                    $rootScope.logoutExternalPage
                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {
                    console.log('7');
                    $scope.myValue = data;
                }, function (err) {
                    $scope.myValue = err;
                })

            q5.resolve($scope.promise);


            return q5.promise;



    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.ExportLandlordStatementTransactions = function() {
        console.log("C1 ExportLandlordStatementTransactions");

        var qExportLandlordStatementTransactions = $q.defer();

        $scope.myValue = 0;
        $scope.itemsPerPage = 100000;
        $scope.status = 1212;

        console.log("status: " + $scope.status);

        $scope.promise = $scope.landlordStatementTransactionsUpdate('*', $scope.status);

        $scope.promise
            .then(function (data) {

                console.log("A2 getGlobalPromise");
                return $scope.ExportLandlordStatementTransactionsDetails(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        qExportLandlordStatementTransactions.resolve($scope.promise);



    }

    $scope.ExportLandlordStatementTransactionsDetails = function() {
        //var q = $q.defer();
        $scope.filename = "";
        $scope.getHeader = [];

        $scope.getHeader =  ["Property", "Unit", "First_Name", "Last_Name",  "Category", "Amount", "Date", "Payment_Transaction_ID", "Tenancy_ID", "Payment_Type", "Date_Received", "Description", "Proeprty_ID", "Notes", "User"];
        $scope.filename = "Landlord Statement Transactions";
        //$scope.itemsPerPage = 200000;
        //q.resolve($scope.itemsPerPage);

        $scope.exportCSVData = exportCSVService.exportCSVService($scope.landlordTransactionsExport);
        //return q.promise;

    }

    $scope.landlordStatementTransactionsUpdate = function(id, status) {
        //$scope.tenancies = [];
        //$scope.pageno = pagenumber;


        console.log("status: " + status);

        $scope.landlordTransactions = [];

        if ($scope.search) {
            console.log("trying search")
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {
            $scope.search = "*";
            console.log("search null")
        }

        var q = $q.defer();

        console.log("function landlordStatementTransactionsUpdate");

        $scope.landlordUpdate.Clouderty_System_Administrator = "0";
        $scope.landlordUpdate.Account_Holder_ID = "0";
        $scope.landlordUpdate.User_ID = "0";
        $scope.landlordUpdate.itemsPerPage = "20";
        $scope.landlordUpdate.pageno = "1";
        $scope.landlordUpdate.Status = status;

        /*
        console.log("$stateParams.itemsPerPage: " + $stateParams.itemsPerPage);
        console.log("$stateParams.pagenumber: " + $stateParams.pagenumber);
        console.log("$stateParams.propertyID: " + $stateParams.propertyID);
        console.log("$stateParams.accountHolderID: " + $stateParams.accountHolderID);
        console.log("$stateParams.yearRelatedTo: " + $scope.landlordUpdate.yearRelatedTo);
        console.log("$stateParams.monthRelatedTo: " + $stateParams.monthRelatedTo);
        */
        $scope.landlordUpdate.Payment_Transaction_ID = "0";
        $scope.landlordUpdate.itemsPerPage = "20";
        $scope.landlordUpdate.pagenumber = "1";
        $scope.landlordUpdate.Property_ID = $scope.landlord.Property;
        $scope.landlordUpdate.Licence_Key = $stateParams.licenseKey;
        //$scope.landlordUpdate.accountHolderID = $stateParams.accountHolderID;
        console.log("$scope.landlordUpdate.Property_ID" + $scope.landlordUpdate.Property_ID);
        console.log("$scope.landlordUpdate.Licence_Key" + $scope.landlordUpdate.Licence_Key);

        $scope.landlordUpdate.search = "*";
        console.log($scope.landlordUpdate);
        console.log("$scope.landlord.User_ID" + $scope.landlord.User_ID);
        $scope.landlordTransactions = {};

        apiService.landlordStatementTransactions("*", $scope.landlordUpdate).then(
            //apiService.landlordStatementTransactions(itemsPerPage, $scope.pageno, searchcriteria, status, paymentTransactionID, paymentTransactionCategoryID, yearRelatedTo, monthRelatedTo, propertyID, accountHolderID, cloudertySystemAdministrator).then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API landlordStatementTransactions mmmmmmmmmmmmmmmmmm");


                console.log(response);

                if(status == 161){

                    $scope.landlordTransactions = response.data;

                }
                if(status == 1212){

                    $scope.landlordTransactionsExport = response.data.data;
                    console.log($scope.landlordTransactionsExport);

                }
                else{

                    $scope.landlordTransactions = response.data;
                    $scope.landlordTransactionsPlus = response.data.dataIncome;
                    $scope.landlordTransactionsMinus = response.data.dataExpenditure;
                    $scope.landlord = response.data.landlord[0];

                    $scope.landlordPayments = response.data.landlordPayments;
                    $scope.dateLastDayofTheMonth = response.data.dateLastDayofTheMonth;
                    $scope.dateFirstDayofTheMonth = response.data.dateFirstDayofTheMonth;
                    $scope.landlordStatementTransactionsAmountSum = $filter('sumOfValue')($scope.landlordStatementTransactions, "Amount");
                    $scope.landlordStatementTransactionsAmountLandlordPaymentSum = response.data.landlordPaymentCategorySum;
                    $scope.landlordStatementTransactionsAmountAllSum = response.data.dataAllSum;
                    $scope.obj = 1;
                    $scope.mainBlock = '1';

                }

                if(status == 31){

                    $scope.landlordPropertyTransactionsAll = response.data.landlordPropertyTransactionsAll;
                    $scope.transactionTotalRent = response.data.transactionTotalRent;
                    $scope.transactionTotalAgentManagementFee = response.data.transactionTotalAgentManagementFee;
                    $scope.transactionTotalAgentManagementFeeBalance = response.data.transactionTotalAgentManagementFeeBalance;


                }

                if($scope.landlordTransactionsPlus){

                    $scope.noData = 1;
                }
                if($scope.landlordTransactionsMinus){

                    $scope.noData = 1;
                }

                if($scope.landlordUpdate.propertyBackup){
                    console.log("xxxxxxxxxxxxxxxxxxxxxxxxx $scope.landlordUpdate.propertyBackup: " + $scope.landlordUpdate.propertyBackup);

                    $scope.landlord.Property = $scope.landlordUpdate.propertyBackup;

                }
                if($scope.landlordUpdate.monthRelatedToBackup){
                    console.log("xxxxxxxxxxxxxxxxxxxxxxxxx $scope.landlordUpdate.monthRelatedToBackup: " + $scope.landlordUpdate.monthRelatedToBackup);

                    $scope.landlord.landlord_transaction_month_cbo = $scope.landlordUpdate.monthRelatedToBackup;

                }

                if($scope.landlordUpdate.yearRelatedToBackup){
                    console.log("xxxxxxxxxxxxxxxxxxxxxxxxx $scope.landlordUpdate.yearRelatedToBackup: " + $scope.landlordUpdate.yearRelatedToBackup);

                    $scope.landlord.landlord_payment_transaction_year_cbo = $scope.landlordUpdate.yearRelatedToBackup;

                }
                if($scope.landlordUpdate.landlordIDBackup){
                    console.log("xxxxxxxxxxxxxxxxxxxxxxxxx $scope.landlordUpdate.landlordIDBackup: " + $scope.landlordUpdate.yearRelatedToBackup);

                    $scope.landlord.Property = $scope.landlordUpdate.landlordIDBackup;

                }
                /*
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.transactionTotal = response.data.transactionTotal;


                $scope.landlordStatementTransactionsAmountLandlordPaymentSum = response.data.landlordPaymentCategorySum;
                $scope.landlordStatementTransactionsAmountAllSum = response.data.dataAllSum;

                */

                console.log("landlordStatementTransactionsAmountAllSum: " + $scope.landlordStatementTransactionsAmountAllSum);

                if ($scope.search == "*") {
                    $scope.search = "";
                }
                else {
                }
                q.resolve(response);
            }
        )

        if ($scope.search == "*") {
            $scope.search = "";
        }
        else {

        }
        return q.promise;
    }

    /*
    $scope.getData = function(pageno) {

        $scope.landlordTransactions = [];

        canceller = $q.defer();

        if ($scope.search) {
            console.log("trying search")
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {
            $scope.search = "*";
            console.log("search null")
        }

        console.log("$scope.landlord.Property_ID = " + $scope.landlord.Property_ID);
        $scope.currentPage = pageno;
        $scope.pageno = pageno;
        $scope.landlordStatementTransactionsUpdate('0',11);

        //$scope.landlordStatementTransactionsUpdate($scope.itemsPerPage, pageno, $scope.search, "0", "*", $scope.landlord.Payment_Transaction_Category_ID, $scope.landlord.landlord_transaction_year, $scope.landlord.landlord_transaction_month, $scope.landlord.Property_ID, $rootScope.decodedToken.Account_Holder_ID,  $rootScope.decodedToken.Clouderty_System_Administrator);
    }

    */
    $scope.getDataPaymentTransactionMonth = function(pageno) {
        console.log("function getDataPaymentTransactionMonth " + $scope.landlord.landlord_transaction_year);
        delete $scope.mainBlock;
        delete $scope.noData;
        delete $scope.landlordTransactions;
        delete $scope.landlordTransactionsPlus;
        delete $scope.landlordTransactionsMinus;

        $scope.landlordUpdate = {};
        //$stateParams.monthRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
        //$scope.landlordUpdate.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
        //$scope.landlordUpdate = 0;

        $scope.landlordUpdate.landlord_transaction_month_cbo = $stateParams.monthRelatedTo;
        if($scope.landlord.Property){

            $scope.landlordUpdate.propertyBackup = $scope.landlord.Property;
        }else{
            alert("Select a property");
            return "";
        }


        if(!$scope.landlord.landlord_transaction_month_cbo){

            $scope.landlord.monthRelatedTo = "0";
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_year);

        }else{
            delete $scope.obj;
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_payment_transaction_year_cbo);
            $scope.landlordUpdate.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
            $scope.landlordUpdate.monthRelatedToBackup = $scope.landlord.landlord_transaction_month_cbo;
            $scope.landlordUpdate.Estate_ID = 0;
            $scope.landlordUpdate.Payment_Transaction_Category_ID = 0;
            $scope.landlordUpdate.yearRelatedTo = $stateParams.yearRelatedTo;

        }

        if($scope.landlord.landlord_payment_transaction_year_cbo){

            $scope.landlordUpdate.yearRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
            $scope.landlordUpdate.yearRelatedToBackup = $scope.landlord.landlord_payment_transaction_year_cbo;


            $scope.landlordStatementTransactionsUpdate(0, 31);
            //$scope.getData($scope.pageno);

        }else{

            alert("Select a year");
            return "";

        }


    }

    $scope.getDataPaymentTransactionYear = function(pageno) {
        console.log("function getDataPaymentTransactionYear " + $scope.landlord.landlord_transaction_year);

        $scope.landlordUpdate = {};
        //$stateParams.monthRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
        //$scope.landlordUpdate.yearRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
        //$scope.landlordUpdate = 0;
        $scope.landlordUpdate.landlord_transaction_month_cbo = $stateParams.monthRelatedTo;
        /*
        if($scope.landlord.Property){

            $scope.landlordUpdate.landlordIDBackup = $scope.landlord.Property;
        }else{
            alert("Select a property");
            return "";
        }
        */
        if($scope.landlord.Property){

            $scope.landlordUpdate.propertyBackup = $scope.landlord.Property;
        }else{
            alert("Select a property");
            return "";
        }
        if(!$scope.landlord.landlord_payment_transaction_year_cbo){

            $scope.landlord.yearRelatedTo = "0";
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_year);
        }else{
            delete $scope.obj;
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_payment_transaction_year_cbo);
            $scope.landlordUpdate.yearRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
            $scope.landlordUpdate.yearRelatedToBackup = $scope.landlord.landlord_payment_transaction_year_cbo;

            $scope.landlordUpdate.Estate_ID = 0;
            $scope.landlordUpdate.Payment_Transaction_Category_ID = 0;
            //$scope.landlordUpdate.monthRelatedTo = $stateParams.monthRelatedTo;
            //$scope.landlordUpdate.monthRelatedTo = "JANUARY";

        }

        if($scope.landlord.landlord_transaction_month_cbo){

            $scope.landlordUpdate.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
            $scope.landlordUpdate.monthRelatedToBackup = $scope.landlord.landlord_transaction_month_cbo;
            //$scope.landlordUpdate.Status = '21';
            $scope.landlordStatementTransactionsUpdate('0', '31');
            //$scope.getData($scope.pageno);

        }else{
            alert("Select a month");
            return "";
        }



    }

    $scope.getDataPaymentTransactionProperty = function(pageno) {
        console.log("function getDataPaymentTransactionProperty " + $scope.landlord.landlord_transaction_year);

        /*
        delete $scope.mainBlock;
        delete $scope.noData;
        delete $scope.landlordTransactions;
        delete $scope.landlordTransactionsPlus;
        delete $scope.landlordTransactionsMinus;

        $scope.landlordUpdate = {};
        //$stateParams.monthRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
        //$scope.landlordUpdate.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
        //$scope.landlordUpdate = 0;

        */

        //$scope.landlordUpdate.landlord_transaction_month_cbo = $stateParams.monthRelatedTo;
        if($scope.landlord.Property){

            $scope.landlordUpdate.propertyBackup = $scope.landlord.Property;

        }else{
            alert("Select a property");
            return "";
        }


        //delete $scope.obj;
        console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_payment_transaction_year_cbo);
        //$scope.landlordUpdate.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
        //$scope.landlordUpdate.monthRelatedToBackup = $scope.landlord.landlord_transaction_month_cbo;
        $scope.landlordUpdate.Estate_ID = 0;
        $scope.landlordUpdate.Payment_Transaction_Category_ID = 0;
        //$scope.landlordUpdate.yearRelatedTo = $stateParams.yearRelatedTo;



        $scope.landlordStatementTransactionsUpdate(0, 1212);
        //$scope.getData($scope.pageno);




    }

    $scope.init();

    $scope.createPDF = function(){
        console.log("inside function createPDF");

        var to_email = "cyril.thomas.uk@gmail.com";
        var attachmentFileLocation = "../uploads/";
        var fileName = 'landlord-statement';
        var from_email = "Clouderty | Landlord Statement " + " | " + " <notifications@clouderty.co.uk>";
        var subject = "Created by Cyril" ;
        var message = "Test content 1: " + "<br />" +
            "Test content 2: " + "<br />";

        pdfCreateService.pdfAddImage(fileName, attachmentFileLocation, status, to_email, from_email, subject, message);

    }

}])