/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("openNewWindowService", ["$window", "$q", "$http", "$state", "$httpParamSerializer", function ($window, $q, $http, $state, $httpParamSerializer) {

    this.newWindow = function(url, var1, var2, var3, var4, var5, var6, var7, var8, var9, var10) {

        /*
        console.log("paramsHREF: " + paramsHREF);
        console.log("inside openNewWindowService newWindow service");
        var urlHREF = $state.href(url,
            paramsHREF);
        console.log("urlHREF: " + urlHREF);
        window.open(urlHREF + paramsHREF,'_blank');

        */


        console.log("url: " + url);

        var urlHREF = $state.href(url);


        var res = urlHREF.charAt(urlHREF.length-1);
        var res2 = urlHREF.charAt(urlHREF.length-2);

        console.log("res: " + res);
        if(res == '/'){

            if(res2 == '/'){
                console.log("res2: " + res2);
                urlHREF = urlHREF.substring(0, urlHREF.length - 1);
            }

        }

        //console.log("urlHREF: " + urlHREF);

        //var paramsHREF2 = "?id=4739";




        //var urlHREF = urlHREF + paramsHREF2;
        console.log("urlHREF: " + urlHREF);

        //var qs = $httpParamSerializer(urlHREF);
        //console.log("qs: " + qs);

        var paramsHREF = "";

        if(!var1){

        }else if(var2){
            paramsHREF = var1 + '/' + var2;
            urlHREF = urlHREF + paramsHREF;
            console.log("paramsHREF: " + paramsHREF);
            console.log("urlHREF: " + urlHREF);
            window.open(urlHREF,'_blank');
            return "";
        }else if(var1){
            paramsHREF = var1;
            urlHREF = urlHREF + paramsHREF;
            console.log("paramsHREF: " + paramsHREF);
            console.log("urlHREF: " + urlHREF);
            window.open(urlHREF,'_blank');
            return "";
        }







    }


}])
