/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("inspectionReportAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$q", "yesNoFilter", "apiService" ,  function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $q, yesNoFilter, apiService) {
    $scope.obj = {};


    // Relating to promises - Step 2 ///////////////// START
    var canceller, isSending = false;
    // Relating to promises - Step 2 ///////////////// START

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });


    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];

    $('.data').hide();
    $('.accordion-1-form').show();

    $scope.New_Record = false;

    if ($stateParams.id) {
        $scope.New_Record = true;
    }


    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.searchTenant = function() {
        $scope.tenants = null;
        $http.get('api/tenants/' + $scope.search_tenant).success(function(response) {
            $scope.tenants = response;
        })
    }

    $scope.obj.selectedTenants = [];

    $scope.selectTenant = function(id) {

        $http.get("api/tenant/" + id).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.Tenant_ID,
                "First_Name": response.First_Name,
                "Last_Name": response.Last_Name,
                "Email": response.Email,
                "Contact_number": response.Contact_number,
                "D_O_B": response.D_O_B
            })
            $scope.obj.Tenant_ID = response.Tenant_ID;
            if (response.Last_Name == null){$scope.obj.Last_Name = " ";} else { };
            //If no last name is present attach blank value to obj scope
            $scope.obj.Full_Name = response.First_Name + "" + $scope.obj.Last_Name;
            $scope.obj.Contact_number = response.Contact_number;
            $scope.obj.Email = response.Email;

            console.log('Tenant id:' + $scope.obj.Tenant_ID);
        })

        $scope.search_tenant = "";

    }


    $scope.hideSearch = false;



    $scope.searchUnit = function() {
        $scope.units = null;

        // Relating to promises - Step 2 ///////////////// START
        console.log("callajax");
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        var deferred = $q.defer();
        // Relating to promises - Step 2 ///////////////// END

        $http.get('api/units/' + $scope.search_unit, {timeout: canceller.promise} /* Relating to promises - Step 3 */).success(function(response) {


            $scope.units = response;
            /////////////////// Relating to promises - Step 4
            isSending = false;
            console.log("success");
            ////////////////// Relating to promises - Step 4  end



        });

        // Relating to promises - Step 5 ///////////////// START
        return deferred.promise;
        // Relating to promises - Step 5 ///////////////// START
    }

    $scope.obj.selectedUnits = [];
    $scope.selectUnit = function(id) {

        $http.get("api/unit/" + id).success(function (response) {
            $scope.obj.selectedUnits.push({
                "Unit_ID": response.Unit_ID,
                "Address": response.Address_1,
                "Unit_Number": response.Unit_number,
                "Town": response.Town,
                "City": response.City,
                "Postcode": response.Postcode
            })

            $scope.obj.Address_1 =  response.Address_1;
            $scope.obj.Property_name =  response.Property_name;
            $scope.obj.Postcode =  response.Postcode;
            $scope.obj.Unit_number =  response.Unit_number;

        })

        $scope.search_unit = "";
        $scope.hideSearch = true;
    }

    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }

    $scope.addMonths = function(months) {
        var date_string = $scope.obj.Tenancy_start_date;
        var tenancy_start_date = new Date(date_string);

        $scope.obj.Tenancy_end_date = new Date(tenancy_start_date.setMonth(tenancy_start_date.getMonth() + months));

    };

    $scope.addInspectionReport = function() {
        $scope.a = [];
        $scope.inspectionReport = {};
        $scope.inspectionReport.Property_ID = 1;
        $scope.inspectionReport.Inspection_Report_Type_ID = 1;
        $scope.inspectionReport.User_ID = $rootScope.decodedToken.id;

        $scope.inspectionReportItems

        if (confirm('Are you sure you want to add an inspection report?')) {
            console.log('Inspection Report add confirm');

            apiService.postInspectionReport($scope.inspectionReport).then(
                function (response) {
                    $scope.a = response;
                    console.log($scope.a);
                },
                function () {
                    alert("There was an error in postInspectionReport")
                }
            )

            /*

            var from_email = "Clouderty | Tenancy Created | " + $scope.obj.Property_name  + " |" + " <notifications@clouderty.co.uk>";
            var to_email = "office@platinumcrown.co.uk";
            var subject = $scope.obj.Property_name + " | " + $scope.obj.Full_Name +  " | Created by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " +  $scope.obj.Tenancy_ID;
            var message = "Tenant name: " +  $scope.obj.Full_Name + "\n\n" +
                "Address: "  + $scope.obj.Property_name + "\n\n" +

                "Unit number: "  + $scope.obj.Unit_number + "\n\n" +
                "Email: "  + $scope.obj.Email + "\n\n" +
                "Contact number: "  + $scope.obj.Contact_number + "\n\n\n\n" +
                "Time/Date: " + Date() + "\n\n" +
                "Tenancy ID: " + $scope.obj.Tenancy_ID + "\n\n" +
                "Created By: " + $rootScope.decodedToken.Name + "\n\n" ;
            */


            //$rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);

            //$state.go('lettings-main-nav.lettings-side.lettings-view-tenancy.notes', {id: $scope.obj.Tenancy_ID});

        }else {
            // Do nothing!
        }
    }
}])