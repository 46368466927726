/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("testCtrl", ["$scope", "$http", "$stateParams", "$rootScope", "authenticateUserLevelService", "apiService", "$q", "fileUploadService", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$filter", "dateInputService", "pdfCreateService", "postcodeRadiusSearchService", "notificationsService", function ($scope, $http, $stateParams, $rootScope, authenticateUserLevelService, apiService, q, fileUploadService, $mdDialog, $mdMedia, Upload, $timeout, $filter, dateInputService, pdfCreateService, postcodeRadiusSearchService, notificationsService) {




    $scope.changeState = function() {
        console.log('changeState');
    }

    //Change user
    $scope.apacheUser = function () {
        console.log("in function sendTextMessage");

        return $http.get("api/tests/apache-process-owner");

    }

    /* CAROUSEL SLIDER */
    $scope.resultsCarousel = '';

    var obj = [{
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        },
        {
            "url":"image/logo.png"
        }];
    /*

    var obj = [{
        "url":"https://image.freepik.com/free-vector/luxury-letter-e-logo-design_1017-8903.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/3d-box-logo_1103-876.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/blue-tech-logo_1103-822.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/colors-curl-logo-template_23-2147536125.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/abstract-cross-logo_23-2147536124.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/football-logo-background_1195-244.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/background-of-spots-halftone_1035-3847.jpg"
        },
        {
            "url":"https://image.freepik.com/free-vector/retro-label-on-rustic-background_82147503374.jpg"
        }];
     */
    $scope.resultsCarousel = obj;
    /* CAROUSEL SLIDER END */

    /* POSTCODES */

    $scope.results = '';

    $scope.searchProperty = function() {
        $('.alert').show('');
        $('.search-results').hide();
        $('#mydiv').show();
        var data = new FormData();
        var postcode = $scope.postcode;
        var radius = $scope.radius;

        data.append("postcode" , postcode);
        data.append("radius" , radius);
        data.append('method', "search");

        postcodeRadiusSearchService.searchProperty(data,function(res,err) {
            if(err) {
                //flashService.Error(err,false);
                resetForm();
            } else {
                if(res.statusText == "OK"){
                    resetForm();
                    $('#mydiv').hide();
                    $('.search-results').show();
                    $scope.results = res.data;
                }
            }
        });
    }

    function resetForm() {
        $scope.postcode = null;
        $scope.radius = null;
    }

    /* POSTCODES END */

    $scope.postcodeCentre = null;
    $scope.status = '  ';

    $scope.obj = {};
    $scope.obj.result = "";
    $scope.obj.DateInput = "";
    var d = new Date();
    $scope.obj.Date = d;
    $scope.obj.Date_Final = $filter('date')(d, 'dd/MM/yyyy hh:mm:ss');
    $scope.obj.Date_Final_timeZone = d.getTimezoneOffset();
    $scope.obj.To_be_completed_by = dateInputService.dateInputDayService(d, $scope.obj.Date_Final_timeZone);
    $scope.obj.To_be_completed_by_1 = dateInputService.dateInputDayService(d,'3');

    $scope.createDate = function () {
        $scope.obj.result = dateInputService.dateInputDayService($scope.DateInput,0);
        $scope.obj.result2 = dateInputService.dateInputDayService($scope.DateInput,1);
        $scope.obj.result3 = dateInputService.timeInputService($scope.DateInput,0);
        $scope.obj.result4 = dateInputService.timeInputService($scope.DateInput,1);
    }

    $scope.boundingBox = {
        width: 700,
        height: 300
    };

    $scope.sendTextMessage = function () {
        console.log("in function sendTextMessage");

        return $http.post("api/twillio-sms-receive");

    }

    $http.get("api/tests-googlemap-locations/").success(function (data) {
        $scope.markers = [];
        $scope.data = data;
    })
    .error(function () {
        $scope.data = "error in fetching data";
    });

    $scope.showMapMultipleMarkers = function () {

        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();
        $scope.showMapMultipleMarkersDetails();


    }

    $scope.showMapMultipleMarkersDetails = function () {

        geocoder = new google.maps.Geocoder();
        geocoderCentre = new google.maps.Geocoder();
        var postcodeCentre = "co1 2nf"
        //geocoder.geocode( { 'address': postcodeCentre}, function(resultsCentre, status) {
            //console.log("resultsCentre: " + resultsCentre.geometry.location.lat());
            //console.log("resultsCentre1: " + resultsCentre.geometry.location);
            //console.log("resultsCentre2: " + postcodeCentre);
            //$scope.postcodeCentreLat = resultsCentre.geometry.location.lat();
        //})

        $scope.data.forEach(function (item) {
            geocoder.geocode({
                'address': item['postcode']
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    $scope.lat = results[0].geometry.location.lat();
                    $scope.long = results[0].geometry.location.lng();
                }
                var i = 0;
                console.log("array: " + i)
                $scope.markers.push({
                    id: Date.now() + i,
                    coords: {
                        latitude: $scope.lat,
                        longitude: $scope.long

                    },
                    "show": false,
                    closeClick: 'none',
                    "sessions": item['area']
                });
                $scope.$evalAsync();
                console.log("array: " + i)
                i++
            });

        });
        console.log("resultsCentre3: " + $scope.postcodeCentre);
        $scope.widget = {
            "map": {
                "center": {
                    "latitude": 51.8846964,
                    "longitude": 0.9216240000000653
                    //"position": $scope.postcodeCentre

                },
                "zoom": 14,
                "options": {
                    "minZoom": 3,
                    "scrollwheel": true
                }
            }
        }

            /*
            $scope.data = data;

            var i = 0;
            data.forEach(function (item) {
                $scope.markers.push({
                    id: Date.now() + i,
                    coords: {
                        latitude: item['latitude'],
                        longitude: item['longitude']
                    },
                    "show": false,
                    "sessions": item['area']

                });
                i++
            });

            console.log("MARKER: ", $scope.markers);

            // for default loaction and zoom

            $scope.widget = {
                "map": {
                    "center": {
                        "latitude": 51.8846964,
                        "longitude": 0.9216240000000653
                    },
                    "zoom": 14,
                    "options": {
                        "minZoom": 1,
                        "scrollwheel": true
                    }
                }
            }
            */
        //})
        //.error(function () {
            //$scope.data = "error in fetching data";
        //});

    }

    $scope.showTabDialog = function(ev) {
        $mdDialog.show({
            controller: 'DialogController',
            templateUrl: 'views/tests/tabDialog.tmpl.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true
        })
            .then(function(answer) {
                $scope.status = 'You said the information was "' + answer + '".';
            }, function() {
                $scope.status = 'You cancelled the dialog.';
            });
    };

    $scope.getDataCurrent = function(pageno) {
        $scope.tenancies = [];
        $scope.pageno = 1;
        $scope.totalCount = 0;
        $scope.itemsPerPage = 20;


        $scope.tenanciesExport = [];
        $scope.tenanciesExportHeaders = [];
        $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
        $scope.tenanciesExport2 = [];

        $scope.filename = "test" + ".csv";

        $scope.getArray5 = [{a: 1, b:2}];
        //$scope.getArray4 = '[' +  '{a: 1, b:2}' + ']';
        $scope.getArray3 = function () { return [{a: 1, b:2}];};
        $scope.getArray6 = function () { return $scope.tenanciesExport;};
        //$scope.getArray7 = $scope.tenanciesExport();
        $scope.getArray2 = function () { $scope.getArray2 = [{a: 1, b:2}, {a:3, b:4}]; };


        /////////////////// Cancel previous AJAX code (A)
        var canceller, isSending = false;
        ///////////////////  Cancel previous AJAX code (A) end

        $scope.getHeader = function () {
            return ["ID", "Property Name", "Address 1", "Tenancy ID", "Unit Number", "Postcode", "Rental Amount", "Date Start", "Date End", "Tenant Name"]
        };



        $scope.tenanciesExport = function () {

            var deferred = $q.defer();

            $http.get("api/tenancy_current_export").success(function (data, status, headers) {


                deferred.resolve($scope.tenancies.data);

            });

            console.log("deferred.promise:" + deferred.promise);
            return deferred.promise;
            //return [{a: 1, b:2}];

            //console.log("Test tenancies 2:" + $scope.tenancies);
            //return temp.promise;

        }


        if ($scope.search)
        {

            apiService.getAllTenanciesSearchCurrent($scope.itemsPerPage, $scope.pageno, $scope.search).then(

                function(response) {
                    console.log("getAllTenanciesSearchCurrent API");
                    $scope.tenancies = response.data;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    $scope.sort('ID');
                },
                function() {
                    alert("There was an error: getAllTenanciesSearchCurrent API")
                }
            )


        }
        else
        {

            apiService.getAllTenanciesCurrent($scope.itemsPerPage, $scope.pageno).then(

                function(response) {
                    console.log("getAllTenanciesCurrent API");
                    $scope.tenancies = response.data;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    console.log("getAllTenanciesCurrent pageno:" + $scope.pageno);
                    $scope.sort('ID');
                },
                function() {
                    alert("There was an error: getTimesheets API")
                }
            );



        }
    }

    $scope.uploadFile = function () {
        var file = $scope.myFile;
        var uploadUrl = "api/upload-csv", //Url of web service
            promise = fileUploadService.uploadFileToUrl(file, uploadUrl);

        promise.then(function (response) {
            $scope.serverResponse = response;
            console.log("resp after promise",response);
        }, function () {
            $scope.serverResponse = 'An error has occurred';
        })
    };

    ////////////////// Upload and resize image //////////////////

    $scope.uploadPic = function(file) {
        //$scope.f = file;
        //$scope.errFile = errFiles && errFiles[0];
        if (file) {
            file.upload = Upload.upload({
                url: 'api/tests-upload-resize-send-images',
                data: {file: file}
            });
            console.log(file);
            console.log(file.name);
            file.upload.then(function (response) {
                $timeout(function () {


                    console.log("Email sent")
                    var from_email = "Clouderty | Image Uploaded | " + " | " + " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Image Uploaded ";
                    var message = "Payment  ID: " + "wfsdfsdfd" + "\n\n" +
                        "File name: " + file.name + "\n\n" +
                        "Date: " + "fsfss" + "\n\n" +
                        "Image URL: " + "<img src='domain/image/" + "jhdjwhdk.jpg" + "'>" + "\n";

                    var to_email = $rootScope.decodedToken.Accounts_Email;
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);



                    file.result = response.data;

                });
            }, function (response) {
                if (response.status > 0)
                    $scope.errorMsg = response.status + ': ' + response.data;



                return;
            }, function (evt) {

                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));


            });
        }


    }

    /*********  PDF Create and send email **************/

    var form = $('.form'),
        cache_width = form.width(),
        a4 = [595.28, 841.89]; // for a4 size paper width and height

    $('#create_pdf').on('click', function () {
        $('body').scrollTop(0);
        createPDF();
    });

    function createPDF() {


        pdfCreateService.pdfAddImage('img/techumber-html-to-pdf.pdf');
        /*
        return html2canvas(document.body, {
            onrendered: function (canvas){

                var imgData = canvas.toDataURL("image/png", 1.0);
                window.open(imgData);

                //var  pdf = new jsPDF('', 'mm', [canvas.width, canvas.height]);
                if(canvas.width > canvas.height){
                    var pdf = new jsPDF('l', 'mm', [canvas.width, canvas.height]);
                }
                else{
                    var pdf = new jsPDF('p', 'mm', [canvas.height, canvas.width]);
                }


                pdf.addImage(imgData, 'png', 10, 10, canvas.width, canvas.height);
                pdf.save('img/techumber-html-to-pdf.pdf');
            }

        });
        */
    }
    /*

    function createPDF() {
        return html2canvas(document.body, {
            onrendered: function (canvas){
                console.log("a1");
                var imgData = canvas.toDataURL("image/png", 1.0);
                window.open(imgData);
                console.log("a2");
                //var  pdf = new jsPDF('', 'mm', [canvas.width, canvas.height]);
                if(canvas.width > canvas.height){
                    var pdf = new jsPDF('l', 'mm', [canvas.width, canvas.height]);
                    console.log("a3");
                }
                else{
                    var pdf = new jsPDF('p', 'mm', [canvas.height, canvas.width]);
                }

                console.log("a4");


                var options = {
                    format: 'JPEG',
                    //pagesplit: true,
                    "background": '#000',
                };
                console.log("a5");

                var fullPage = imgData,
                    firstPartPage = imgData,
                    secondPartPage = imgData;

                pdf.addHTML(firstPartPage, 15, 20, options, function(){ pdf.addPage() });
                pdf.addHTML(secondPartPage, 15, 20, options, function(){});


            }

        });
    }

    */
    /*
    //create pdf
    function createPDF() {
        getCanvas().then(function (canvas) {
            var
                img = canvas.toDataURL("image/png"),
                doc = new jsPDF('p', 'pt');

            doc.addImage(img, 'JPEG', 20, 20);
            doc.save('img/techumber-html-to-pdf.pdf');
            var pdf = btoa(doc.output());
            var data = new FormData();
            data.append("data", pdf);
            var xhr = new XMLHttpRequest();
            xhr.open('post', '/project/mail/public_html/sendgrid.php', true); //Post to php Script to save to server
            xhr.send(data);
            form.width(cache_width);
        });
    }

    // create canvas object
    function getCanvas() {
        form.width((a4[0] * 1.33333) - 80).css('max-width', 'none');
        return html2canvas(form2, {
            imageTimeout: 2000,
            removeContainer: true
        });
    }
    */
    /*********  PDF Create and send email end**************/

}])