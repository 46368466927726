/**
 * Created by Aaron on 04/07/2016.
 */
clouderty.controller("loginCtrl", ["$scope", "$rootScope", "$state", "postUserActivityService", "$location", "$http", "$window", "$cookies", "$q", "$httpParamSerializer", "loginExternalWebPageService", "apiService", "randomTextGeneratorService", function ($scope, $rootScope, $state, postUserActivityService, $location, $http, $window, $cookies, $q, $httpParamSerializer, loginExternalWebPageService, apiService, randomTextGeneratorService) {
    //$rootScope.decode = function
    $scope.signup = {};
    $scope.signupData = {};

    /////////////////// Cancel previous AJAX code/API Calls (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code/API Calls (A) end

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )
        return q55.promise;
    }

    $scope.init = function() {
        if($cookies.getObject("userName")!==undefined && $cookies.getObject("password")!==undefined){
            self.emailAddress=$cookies.getObject("userName");
            self.password=$cookies.getObject("password");
            //console.log("Hello 2:" + $cookies.getObject("userName"));
            //console.log("Password 2:" + $cookies.getObject("password"));


            $scope.login_details = {};

            $scope.login_details.email = $cookies.getObject("userName");
            //$scope.login_details.email = "khulugkulglh";
            $scope.login_details.password =  $cookies.getObject("password");


            $http.post("api/authenticate", $scope.login_details)
                .success(function(response) {

                    if (response.success == false) {
                        alert("Incorrect Login Details");
                    }
                    else  {
                        if ($window.sessionStorage.token) {
                            $window.sessionStorage.removeItem("token");
                        }
                        $window.sessionStorage.token = response.token;
                        $rootScope.decode();

                        console.log("login notification added");
                        var descriptionNotification = "User " +  $rootScope.decodedToken.id + " - " + $rootScope.decodedToken.User_Name;

                        var urlNotification = window.location.href;
                        $rootScope.saveNotification(11, descriptionNotification, urlNotification);

                        if ($rootScope.decodedToken.User_Type_ID == 1) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard");
                        } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                        } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                        }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                            $state.go("lettings-main-nav.lettings-side.main-dashboard");
                        }

                    }
                })

        }
         else{

        }

    }

    //$scope.init();

    $scope.goToSignup = function() {

        console.log("In goToSignup function");
        $state.go("signup-header.signup");

    }

    $scope.goToLogin = function() {

        console.log("In goToLogin function");
        $state.go('login');

    }

    $scope.createAccountHolderUser = function () {
        console.log("In createAccountHolderUser function");



        if ($scope.signup.Admin_Password == null) {

            confirm('Enter a password');
            return "";

        }
        else if ($scope.signup.Email == null) {

            confirm('Enter an email');
            return "";

        }
        else if ($scope.signup.Account_Holders_Name == null) {

            confirm('Enter a username');
            return "";

        }

        $scope.login_details = {};

        $scope.login_details.email = "c.thomas@platinumcrown.co.uk"
        $scope.login_details.password = "platinumlaptop1"





        $scope.promise = loginExternalWebPageService.init("1");

        $scope.promise
            .then(function (data) {

                console.log("A1 login external");
                $rootScope.decode();

                $scope.signup.Licence_Key = randomTextGeneratorService.generator(50);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 awardsFunction");


                return $http.post("api/authenticate", $scope.login_details)
                    .success(function(response) {

                        if (response.success == false) {
                            alert("Incorrect Login Details");
                        }
                        else  {
                            if ($window.sessionStorage.token) {
                                $window.sessionStorage.removeItem("token");
                            }
                            $window.sessionStorage.token = response.token;
                            $rootScope.decode();


                            $cookies.putObject("userName", "external@clouderty.co.uk");
                            $cookies.putObject("password", "platinumlaptop");





                            return $scope.accountHoldersUsersFunction(0, 4);


                            console.log('$scope.signupData >>>');

                            console.log($scope.signupData);
                        }
                    })


            })
            .then(function (data) {

                console.log("A6 getGlobalPromise");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7a send email');

                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                console.log(">>>>>>>>>>> $scope.signupData: " + $scope.signupData);
                console.log($scope.signupData);

                var from_email = "Clouderty | New Account Created " + $scope.signupData.Account_Holder_ID + " | " + $scope.signupData.Account_Holder_ID + " <notifications@clouderty.co.uk>";
                var subject = "New Account Created Created by:" +  $scope.signup.Account_Holders_Name  + " | Account Holders " + $scope.signupData.Account_Holder_ID  + " | " + $scope.signupData.Account_Holder_ID ;
                var message = "Account Holders ID: " + $scope.signupData.Account_Holder_ID  + "<br /><br />" +
                    "User Name: " + $scope.signup.Account_Holders_Name + "<br /><br />" +
                    "User ID: " + $scope.signupData.User_ID  + "<br /><br />" +
                    "Customer ID: " + $scope.signupData.Customer_ID  + "<br /><br />" +
                    "Email: " + $scope.signup.Email  + "<br /><br />" +
                    "Click on the following link to confirm your account: https://management.clouderty.co.uk/#/account-holders-users/confirmation/" + $scope.signupData.Account_Holder_ID + "/" + $scope.signupData.User_ID   + "/" + $scope.signup.Licence_Key  + "<br /><br />";


                $scope.postUserActivityServiceMessage = subject;
                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                to_email = $rootScope.Notifications_Email;

                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                    console.log('A7a logout');

                    $rootScope.logoutExternalPage();
                    return $scope.getGlobalPromise(data);
                },
                function (err) {
                    $scope.myValue = err;
                })



    }

    $scope.accountHoldersUsersFunction = function(id, status) {

        var qaccountHoldersUsersFunction = $q.defer();

        $scope.signup.Status = status;
        qaccountHoldersUsersFunction.resolve($scope.accountHoldersUsers(0, $scope.signup));

        return qaccountHoldersUsersFunction.promise;

    }

    $scope.accountHoldersUsers = function (id, dataObj) {
        // * or 0 = view all record
        // 2 = update record
        // 3 = delete record
        // 4 = new record
        // 5 = get a record

        var qaccountHoldersUsers = $q.defer();

        console.log(dataObj);
        ///Cancel previous AJAX code/API Calls (B)
        if (isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();
        ////////////////// Cancel previous AJAX code/API Calls (B) end

        if (dataObj.Status == 4) {

            console.log("API post service: create accountHoldersUsers account");

            return $http({
                method: 'POST',
                url: "api/account-holders-users/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                $scope.signupData = response;
                console.log(response);
                qaccountHoldersUsers.resolve(response);
                console.log("api accountHoldersUsers success");
            });

        }
        else {
            console.log("API service: accountHoldersUsers Get all records");
            return $http({
                method: 'GET',
                url: "api/account-holders-users/" + id,
                params: $httpParamSerializer(dataObj),
                paramSerializer: '$httpParamSerializerJQLike',
                timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                $scope.signupData = response;
                console.log(response);
                qaccountHoldersUsers.resolve(response);
                console.log("api accountHoldersUsers success");
            });
        }

    }

    $scope.login = function () {

        console.log("Loging in");
        console.log($scope.login_details);
        $http.post("api/authenticate", $scope.login_details)
            .success(function(response) {

                if (response.success == false) {
                    alert("Incorrect Login Details");
                }
                else  {
                    if ($window.sessionStorage.token) {
                        $window.sessionStorage.removeItem("token");
                    }
                    $window.sessionStorage.token = response.token;
                    $rootScope.decode();

                    //$rootScope.$apply();
                    $cookies.putObject("userName", $scope.login_details.email);
                    $cookies.putObject("password", $scope.login_details.password);
                    //console.log("Hello:" + $scope.login_details.email);
                    //console.log("Password:" + $scope.login_details.password);

                    console.log("$rootScope.decodedToken.User_Type_ID: " + $rootScope.decodedToken.User_Type_ID);
                    console.log("$rootScope.decodedToken.User_Level_ID: " + $rootScope.decodedToken.User_Level_ID);

                    console.log("login notification added");
                    //var descriptionNotification = "User " +  $rootScope.decodedToken.id + " - " + $rootScope.decodedToken.Name;

                    //var urlNotification = window.location.href;
                    //$rootScope.saveNotification(11, descriptionNotification, urlNotification);


                    if ($rootScope.decodedToken.User_Type_ID == 1) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard");
                    } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                    } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                    }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.main-dashboard");
                    }


                }
            })
    }

    $scope.loginAuto = function () {

        $scope.login_details.email = $cookies.getObject("userName");
        $scope.login_details.password =  $cookies.getObject("password");


        $http.post("api/authenticate", $scope.login_details)
        .success(function(response) {

            if (response.success == false) {
                alert("Incorrect Login Details");
            }
            else  {
                if ($window.sessionStorage.token) {
                    $window.sessionStorage.removeItem("token");
                }
                $window.sessionStorage.token = response.token;
                $rootScope.decode();

                console.log("User 3:" + $scope.login_details.email);
                console.log("Password 3:" + $scope.login_details.password);

                if ($rootScope.decodedToken.User_Type_ID == 1) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard");
                } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard-vendors");
                } else if ($rootScope.decodedToken.User_Type_ID == 3) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("landlords-main-nav.landlords-tasks-side.main-dashboard");
                }  else if ($rootScope.decodedToken.User_Type_ID == 5) {
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                    $state.go("lettings-main-nav.lettings-side.main-dashboard");
                }

            }
        })
    }


}])