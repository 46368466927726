/**
 * Created by Aaron on 27/07/2016.
 */
clouderty.directive('dynamicDateInput', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngFieldLevel: '@'
        },
        template: function($element, $attrs) {
            var html = "";
            if ($rootScope.decodedToken.User_Level > $attrs.ngFieldLevel) {
                html = '<input class="form-input" required>';
            } else if ($rootScope.decodedToken.User_Level == $attrs.ngFieldLevel)  {
                html = '<input class="form-input" disabled>';
            } else {
                html = '<span></span>';
            }

            return html;
        }
    }
}]);