/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('spinnerLoad', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            spinnervariable: '=', //scope dynamic variable
            spinnerviewvariable: '=', //scope dynamic variable
            spinnerobj: '=', //scope dynamic variable
            spinnerobjsingleid: '=', //scope dynamic variable
            spinnertype: '=', //scope dynamic id variable - 1: ID - 2: object not null
            maintenancedescription: '=', //scope dynamic id variable - 1: ID - 2: object not null
            loadingtext: '@' //string variable
        },
        templateUrl: 'views/global/spinner.html',
        link:function(scope,elem,attrs){

        },
        controller: 'spinnerLoadCtrl'
    }
}])