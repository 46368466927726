/**
 * Created by Aaron on 25/07/2016.
 */
clouderty.controller("emailConfirmCtrl", ["$scope", "$http", "$uibModalInstance", "obj", "$rootScope", "$cookies", "postUserActivityService", "$location", "$state", "$window", function($scope, $http, $uibModalInstance, obj, $rootScope, $cookies, postUserActivityService, $location, $state, $window) {

    $scope.obj = obj;
    console.log("emailConfirmCtrl");


    $scope.ok = function () {


        var from_email = "Clouderty | Task Completed | "  + $scope.obj.data.Property_name + " | " + $scope.obj.data.Description + " | " +  " <notifications@clouderty.co.uk>";
        var subject = "Clouderty | Task Completed | " + " - Completed by: " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + " | " + $scope.obj.data.Description + " - Created By: " + $scope.obj.data.Created_By;
        var message = "Clouderty | Task Completed | " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + "\n Task Name: " + $scope.obj.data.Description + "\nDate started: " + $scope.obj.data.Date_started + " | Date completed  " + $scope.obj.data.Date_completed + "\n\n Created by: " + $scope.obj.data.Created_By + "\n\n Completed By: " + $rootScope.decodedToken.Name;




        var to_email = "rentals@platinumcrown.co.uk";
        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);




        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Task complete | Task ID' + $scope.obj.data.Task_ID);
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        console.log("Inside $scope.cancel ");
        $uibModalInstance.dismiss('cancel');
    };
    
}])