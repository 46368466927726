/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("meterReadingsSearchCtrl", ["$scope", "$rootScope", "$http", "apiService", function($scope, $rootScope, $http, apiService) {

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.searchUnit = function() {
        $scope.units = null;

        apiService.searchUnits($scope.search_unit, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data;
            },
            function() {
                alert("There was an error apiService.searchTenant")
            }
        )

    }


   apiService.getMeterReadings($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
     function(response) {
         // Stores the response from the API into the scope, making it accessible by Angular
         console.log("getMeterReadings API");
         $scope.timesheets = response.data;
         $scope.totalCount = response.data.totalCount;
     },
     //On error
     function() {
         console.log("There was an error: getMeterReadings API")
     }
   )


   $scope.sort = function(keyname){
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
   }

   
}])