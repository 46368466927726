/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('tenancyRelated', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            tenanciesrelatedobj: '=', //scope dynamic variable
            maintenancematerialslabour: '=', //scope dynamic variable
            tenantid: '=', //scope dynamic variable
            propertyid: '=', //scope dynamic id variable - 1: ID - 2: object not null
            spinnerobj: '=', //scope dynamic variable
            maintenancedescription: '=', //scope dynamic variable
            descriptiontext: '@' //string variable
        },
        templateUrl: 'views/lettings/tenancy-related.html',
        link:function(scope,elem,attrs){

        },
        controller: 'tenancyRelatedCtrl'
    }
}])