/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("postcodeRadiusSearchService", ["$http", "$q", "$rootScope", "Upload", "fileUploadService", "apiService", "truncateSliceService", function ($http, $q, $rootScope, Upload, fileUploadService, apiService, truncateSliceService) {
    console.log("inside service postcodeRadiusSearchService");

    'use strict';
    var service = {};

    service.searchProperty = searchProperty;

    return service;

    function searchProperty(data,callback) {
        return $http.post('api/postcode-radius-search', data,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        }).then(function(res){
            callback(res);
        }, function(err){
            callback(null,err);
        });
    }

}])
