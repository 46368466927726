/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("dashboardCtrl", ["$scope", "stateAuthCheckService", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "$filter", "apiService", "yesNoFilter", "$uibModal", "fCsv", "$timeout", "exportCSVService", "dateInputService", "moment", "showHideService", function ($scope, stateAuthCheckService, $http, $rootScope, $q, $state, postUserActivityService, $filter, apiService, yesNoFilter, $uibModal, fCsv, $timeout, exportCSVService, dateInputService, moment, showHideService) {


    //alert("Test auto cache");
    console.log("Rootscope user level: " + $rootScope.decodedToken.User_Level);
    console.log("Rootscope account holder ID: " + $rootScope.decodedToken.Account_Holder_ID);
    $scope.tenancies = [];
    $scope.tenanciesExport = [];
    $scope.tenanciesExportHeaders = [];
    $scope.tenanciesBecomingAvailable = [];
    //$scope.tenanciesBecomingAvailableCount = [];
    $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.tenanciesExport2 = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 200000;
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.tenancyID = "current";
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.tenantViewingsTomorrow = [];
    $scope.tenantViewingsToday = [];
    $scope.paymentTransactionsData = {};
    //$scope.search = "*";

    //$scope.filename = "test" + ".csv";

    $scope.getArray5 = [{a: 1, b:2}];
    //$scope.getArray4 = '[' +  '{a: 1, b:2}' + ']';
    $scope.getArray3 = function () { return [{a: 1, b:2}];};
    $scope.getArray6 = function () { return $scope.tenanciesExport;};
    //$scope.getArray7 = $scope.tenanciesExport();
    $scope.getArray2 = function () { $scope.getArray2 = [{a: 1, b:2}, {a:3, b:4}]; };

    /*$scope.showHide = function () {

        var self = this;
        self.isVisible = false;

        console.log("self.isVisible" + self.isVisible)
        return self.isVisible;

    }*/

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )
        return q55.promise;
    }



    $scope.showHide = function (showLettings, showVendor, showLandlord, showTenant, showViewings, showAccountHolder, showCloudertyAdmin) { console.log("********** showHide enter *********");
        return showHideService.showHide(showLettings, showVendor, showLandlord, showTenant, showViewings,showAccountHolder, showCloudertyAdmin, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $rootScope.decodedToken.User_Type_ID);
    }

    /*

    $scope.showHide = function (showCloudertyAdmin, showAccountHolder, showVendor, showLandlord, showTenant) {

        var self = this;
        self.isVisible = false;
        console.log("********** showHide ender *********");

        if(showCloudertyAdmin == 1){

            if($rootScope.decodedToken.Clouderty_System_Administrator == '1'){
                console.log("showCloudertyAdmin: " + showCloudertyAdmin);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showAccountHolder == 1){

            if($rootScope.decodedToken.Account_Holder_ID == '1'){
                console.log("showAccountHolder: " + showAccountHolder);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showVendor == 1){

            if($rootScope.decodedToken.User_Type_ID == '2'){
                console.log("showVendor: " + showVendor);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showLandlord == 1){

            if($rootScope.decodedToken.User_Type_ID == '3'){
                console.log("showLandlord: " + showLandlord);
                self.isVisible = true;
                return self.isVisible;
            }

        }
        if(showTenant == 1){

            if($rootScope.decodedToken.User_Type_ID == '4'){
                console.log("showTenant: " + showTenant);
                self.isVisible = true;
                return self.isVisible;
            }

        }

        console.log("self.isVisible" + self.isVisible);

        return self.isVisible;

    }

    */

    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end


    $scope.getUnits = function(pageno) {
        $scope.units = [];
        console.log("$scope.getData");

        $scope.status = 1;
        $scope.id = 2;

        apiService.getUnits($scope.id, $scope.itemsPerPage, pageno, $scope.status, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {
                console.log('apiService getUnits');
                window.onunload = function() { debugger; };

                if(response.data.data.length){

                    $scope.units = response.data;
                    console.log("not null")
                }
                else{

                    $scope.units = response.data;
                    console.log("null")

                }

                $scope.units.totalCountVacantUnits = response.data.totalCountVacantUnits;
                $scope.units.totalCountVacantUnitsAdmin = response.data.totalCountVacantUnitsAdmin;
                $scope.units.rentTotalVacantUnits = response.data.rentTotalVacantUnits;
                $scope.units.rentTotalVacantUnitsAdmin = response.data.rentTotalVacantUnitsAdmin;

                console.log($scope.units);
                if($scope.units.rentTotalVacantUnits){

                }
                else{
                    $scope.units.rentTotalVacantUnits = 0;
                }
                if($scope.units.rentTotalVacantUnitsAdmin){

                }
                else{
                    $scope.units.rentTotalVacantUnitsAdmin = 0;
                }
                if($scope.units.totalCountVacantUnits){

                }
                else{
                    $scope.units.totalCountVacantUnits = 0;
                }
                if($scope.units.totalCountVacantUnitsAdmin){

                }
                else{
                    $scope.units.totalCountVacantUnitsAdmin = 0;
                }
                $scope.vacantUnits = response.data.vacantUnits;

                console.log("$scope.vacantUnits");
                console.log($scope.vacantUnits);
                //$scope.unints.vacantUnits = response.data.totalCountVacantUnits;

                  /*
                $scope.rentTotal = response.data.rentTotal;

                $scope.currentPage = pageno;


                */

            },
            function() {
                console.log('apiService getUnits error');
                console.log("There was an error")

            }
        )

    }



    $scope.getTenancyInformation = function(pageno) {
        $scope.tenancies = [];
        $scope.searchGetTenancies = "*";
        //$scope.tenancyID = "current";
        $scope.monthsExpiring = 0;
        var q = $q.defer();
        apiService.getTenancies($scope.itemsPerPage, '1', $scope.searchGetTenancies, 0, $scope.monthsExpiring, $scope.landlord.Property_ID, $scope.landlord.Estate_ID, $scope.tenancyID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
             function(response) {
                console.log("getAllTenanciesCurrent API");
                $scope.tenancies = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.currentTenanciesTotalCount = response.data.totalCount;
                $scope.expiredTenanciesCount = response.data.expiredTenanciesCount;
                $scope.expiredTenancies = response.data.expiredTenancies;
                $scope.tenantsHandedInNotice = response.data.tenantsHandedInNotice;
                $scope.tenantsHandedInNoticeCount = response.data.tenantsHandedInNoticeCount;
                console.log("Expired tenancies:" + $scope.expiredTenancies);
                console.log("Tenancies becoming available:" + $scope.tenanciesBecomingAvailable);
                 $scope.totalCount = response.data.totalCount;
                 $scope.rentTotal = response.data.rentTotal;
            },
            function() {
                console.log("API Service Error: getAllTenanciesSearchCurrent")
            }
        );
    }

    $scope.getTenancyInformationView = function() {
        $scope.tenancies = [];
        $scope.searchGetTenancies = "*";
        //$scope.tenancyID = "current";
        $scope.monthsExpiring = 0;


        var q3 = $q.defer();
        apiService.getTenancies($scope.itemsPerPage, '1', $scope.searchGetTenancies, 0, $scope.monthsExpiring, $scope.landlord.Property_ID, $scope.landlord.Estate_ID, $scope.tenancyID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                console.log("getTenancyInformationView nowwwwwwwwwwww");
                $scope.tenancies = response.data.tenantsHandedInNotice;
                $scope.currentPage = $scope.pageno;
                $scope.currentTenanciesTotalCount = response.data.totalCount;
                $scope.expiredTenanciesCount = response.data.expiredTenanciesCount;
                $scope.expiredTenancies = response.data.expiredTenancies;
                $scope.tenantsHandedInNotice = response.data.tenantsHandedInNotice;
                $scope.tenantsHandedInNoticeCount = response.data.tenantsHandedInNoticeCount;
                $scope.totalCount = response.data.totalCount;
                $scope.rentTotal = response.data.rentTotal;

                if($scope.expiredTenancies){

                }
                else{
                    $scope.expiredTenancies = 0;
                }

                if($scope.tenantsHandedInNotice){

                }
                else{
                    $scope.tenantsHandedInNotice = 0;
                }

                if($scope.currentTenanciesTotalCount){

                }
                else{
                    $scope.currentTenanciesTotalCount = 0;
                }

                if($scope.tenantsHandedInNoticeCount){

                }
                else{
                    $scope.tenantsHandedInNoticeCount = 0;
                }





                q3.resolve(response);


            },
            function() {
                console.log("API Service Error: getAllTenanciesSearchCurrent")
            }
        );

        return q3.promise;
    }

    $scope.getTenantArrearsInformation = function(id, status) {


        if(status == "2"){
            q2.resolve($state.go('lettings-main-nav.financials-side.payment-transaction-edit', {id:  id}));

        }else{

            $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.paymentTransactionsData.itemsPerPage = 1000000;
            $scope.paymentTransactionsData.pageno = 1;
            $scope.paymentTransactionsData.Status = status;

            if ($scope.paymentTransactionsData.Payment_Transaction_ID) {
                console.log("$scope.paymentTransactionsData.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            } else {
                $scope.paymentTransactionsData.Payment_Transaction_ID = "*";
            }

            if ($rootScope.search) {
                $scope.paymentTransactionsData.search = $rootScope.search;
            } else {
                $scope.paymentTransactionsData.search = "*";
            }

            apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                function (response) {

                    var q4 = $q.defer();
                    console.log("getTenantRentBalancesAll API");
                    console.log(response.data);
                    //$scope.paginationData = response.data;
                    $scope.arrears = response.data;
                    if ($scope.arrears.arrearsTotal) {

                    }
                    else {
                        $scope.arrears.arrearsTotal = 0;
                    }
                    //$scope.$parent.obj = response.data.data;
                    //$scope.currentPage = $scope.pageno;
                    $scope.arrears.arrearsCount = $scope.arrears.totalCount;

                    q4.resolve(response);
                    return q4.promise;

                },
                function () {
                    console.log("API Service Error: rentalTransactions")
                }
            );
        }
        /*
        apiService.getTenantRentBalancesAll($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {

                var q4 = $q.defer();
                console.log("getTenantRentBalancesAll API");

                //$scope.paginationData = response.data;
                $scope.arrears = response.data;
                if($scope.arrears.arrearsTotal){

                }
                else{
                    $scope.arrears.arrearsTotal = 0;
                }
                //$scope.$parent.obj = response.data.data;
                //$scope.currentPage = $scope.pageno;
                //$scope.totalArrearsCount = response.data.totalCount;

                q4.resolve(response);
                return q4.promise;

            },
            function () {
                console.log("API Service Error: getTenantRentBalancesAll")
            }
        );
        */
    }

    $scope.getTaskInformation = function(pageno) {

        apiService.getAllUsersTasksNotCompleted($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                console.log("getAllUsersTasksNotCompleted API");

                $scope.paginationData = response.data;
                $scope.tasks = response.data.data;
                //$scope.$parent.obj = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalTaskCount = response.data.totalCount;

            },
            function () {
                console.log("API Service Error: getAllUsersTasksNotCompleted")
            }
        );
    }

    $scope.getTenantViewingsInformationToday = function() {
        var q6 = $q.defer();
        $scope.compareToDate = '1';
        apiService.getAllTenantViewings($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.id, $rootScope.decodedToken.User_Type_ID, $scope.compareToDate, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {


                console.log('apiService getTenantViewing');


                console.log("gettenantViewings API");
                $scope.tenantViewingsToday = response.data.data;
                $scope.currentPagetenantViewingsToday = $scope.pageno;
                $scope.tenantViewingsTodayCount = response.data.totalCount;
                $scope.rentTotaltenantViewingsToday = response.data.rentTotal;

                if($scope.tenantViewingsTodayCount){

                }
                else{
                    $scope.tenantViewingsTodayCount = 0;
                }

                q6.resolve(response);

            },
            function() {
                console.log("API Service: getAllTenantViewings")
            }
        );

        return q6.promise;
    }

    $scope.getTenantViewingsInformationTomorrow = function() {
        var q5 = $q.defer();
        $scope.compareToDate = '2';
        apiService.getAllTenantViewings($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.id, $rootScope.decodedToken.User_Type_ID, $scope.compareToDate, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {


                console.log('apiService getTenantViewing');


                console.log("gettenantViewings API");
                $scope.tenantViewingsTomorrow = response.data.data;
                $scope.currentPagetenantViewingsTomorrow = $scope.pageno;
                $scope.tenantViewingsTomorrowCount = response.data.totalCount;
                $scope.rentTotaltenantViewingsTomorrow = response.data.rentTotal;

                if($scope.tenantViewingsTommorrowCount){

                }
                else{
                    $scope.tenantViewingsTommorrowCount = 0;
                }

                q5.resolve(response);

            },
            function() {
                console.log("API Service: getAllTenantViewings")
            }
        );

        return q5.promise;
    }
    $scope.getGasCertificateInformation = function() {

        apiService.gasCertificates('20000', '1', '*', '*', '*', '*', '*', '*', '*', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {
                console.log("getAllCertificatesGas API");

                //$scope.paginationData = response.data;
                $scope.certificatesGas = response.data.data;
                $scope.expiredGasCertificates = response.data.expiredGasCertificates;
                //$scope.$parent.obj = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.totalCount;
                $scope.expiredGasCertificatesCount = response.data.expiredGasCertificatesCount;

            },
            function () {
                console.log("API Service Error: getAllCertificatesGas")
            }
        );
    }

    $scope.getElectricCertificateInformation = function() {

        apiService.getAllCertificatesElectric($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                console.log("getAllCertificatesElectric API");

                //$scope.paginationData = response.data;
                $scope.certificatesElectric = response.data.data;
                $scope.expiredElectricCertificates = response.data.expiredElectricCertificates;
                //$scope.$parent.obj = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.totalCount;
                $scope.expiredElectricCertificatesCount = response.data.expiredElectricCertificatesCount;

            },
            function () {
                console.log("API Service Error: getAllCertificatesElectric")
            }
        );
    }
    $scope.viewTenantArrears = function() {


        $state.go("lettings-main-nav.lettings-side.tenants-rental-transactions-search");
    }
    $scope.viewAvailableUnits = function() {


        $state.go("lettings-main-nav.lettings-side.units-search");
    }
    $scope.viewUnitsBecomingAvailable = function() {

        console.log("viewUnitsBecomingAvailable");
        $state.go("lettings-main-nav.lettings-side.lettings-search-tenancy-becoming-available", {becomingAvailable:1});



    }
    $scope.viewtenantViewingsToday = function(compareToDate) {

        console.log("viewtenantViewingsToday");
        $state.go("lettings-main-nav.lettings-side.lettings-tenant-viewings-search-filtered", {compareToDate:compareToDate});

    }
    $scope.viewtenantViewingsTomorrow = function(compareToDate) {

        console.log("viewtenantViewingsToday");
        $state.go("lettings-main-nav.lettings-side.lettings-tenant-viewings-search-filtered", {compareToDate:compareToDate});

    }

    $scope.viewIncompleteTasks = function() {
        $state.go("lettings-main-nav.tasks-side.tasks-search");
    }
    $scope.viewActiveTenancies = function() {
        $state.go("lettings-main-nav.lettings-side.lettings-search-tenancy");
    }

    $scope.ExportExpiredElectricCertificates = function() {
        $scope.getHeader =  [];
        $scope.filename = "Expired electric certificates";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.expiredElectricCertificates);
    }

    $scope.ExportExpiredGasCertificates = function() {
        $scope.exportCSVData = [];
        $scope.getHeader =  [];
        $scope.filename = "Expired gas certificates";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.expiredGasCertificates);
    }

    $scope.ExportTenantsHandedInNotice = function() {
        $scope.exportCSVData = [];
        $scope.getHeader =  ["Tenant_ID", "Tenancy_ID", "Name", "Address_1", "Property_name", "Rent", "Unit_ID", "Unit_Number", "Vacant", "Tenancy_start_date",  "Tenancy_end_date", "Date_Leaving", "Current", "Email", "Postcode", "Account Holder"];
        $scope.filename = "Tenants that handed in notice";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.tenantsHandedInNotice);
    }

    $scope.ExportVacantUnits = function() {
        $scope.exportCSVData = [];
        $scope.getHeader =  ["Property Name", "Unit Number", "Postcode", "Target Rent", "Unit ID", "Address 1", "Account Holder ID", "Vacant"];
        $scope.filename = "Available Rental Units";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.vacantUnits);
    }

    $scope.ExportExpiredTenancies = function() {
        $scope.exportCSVData = [];
        $scope.getHeader =  ["Tenant_ID", "Tenancy_ID", "Name", "Address_1", "Property_name", "Rent", "Unit_ID", "Unit_Number", "Vacant", "Tenancy_start_date",  "Tenancy_end_date", "Date_Leaving", "Current", "Email", "Postcode", "Account Holder"];
        $scope.filename = "Expired Tenancies";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.expiredTenancies);
    }

    $scope.ExportRentalArrearsBalances = function() {
        $scope.getHeader =  ["Property Name", "First Name", "Surname", "Monthly Rent", "Amount Received", "Amount Esxpected", "Account Balance", "Current"];
        $scope.filename = "Rental Arrears Balances";
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.arrears.arrears);
    }

    $scope.ExportTenantViewingsToday = function() {
        $scope.getHeader = ["Property Name", "Unit number", "First name", "Last name", "Postcode", "Date", "Time", "Rent", "Viewing by", "Notes"];
        $scope.filename = "Tenant Viewings - " + dateInputService.dateInputDayService(Date(), 0);
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.tenantViewingsToday);
    }

    $scope.ExportTenantViewingsTomorrow = function() {
        //var date = moment();
        $scope.getHeader = ["Property Name", "Unit number", "First name", "Last name",  "Postcode", "Date", "Time", "Rent", "Viewing by", "Notes"];
        $scope.filename = "Tenant Viewings - " + dateInputService.dateInputDayService(Date(), 1);
        $scope.exportCSVData = exportCSVService.exportCSVService($scope.tenantViewingsTomorrow);
    }
    /*
    $scope.ExportCSV = function () {

        var deferred = $q.defer();
        //deferred.resolve($scope.tenancies222);
        console.log($scope.exportCSVData)
        deferred.resolve($scope.exportCSVData);
        return deferred.promise;
    }*/



    $scope.loadData = function() {

        console.log("C1 loadDataThenMap");
        var q = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2");
                //$scope.getTenantArrearsInformation("0", "12");
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);
    }

    $scope.loadData();



}])