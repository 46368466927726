/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("paymentTransactionsRecurringSearchCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "postUserActivityService", "authenticateUserLevelService", "apiService", function ($scope, $rootScope, $state, $location, $http,  $window, $stateParams, $rootScope, $filter, postUserActivityService, authenticateUserLevelService, apiService) {


    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("Test A");

    $scope.tenancies = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.paymentTransactionsRecurring = [];



    $scope.getData = function(pageno) {
        $scope.tenancies = [];

        if ($scope.search)
        {


            /*
            $http.get("api/payment-transactions-search/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){
                console.log("Test B");
                if( $scope.a = authenticateUserLevelService.authenticateUserLevelService($rootScope.decodedToken.User_Level, 1, "View payment transactions") == "Exit"){ return; }

                $scope.paymentTransactions = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;

            });
            */
        }
        else
        {

            apiService.getPaymentTransactionsRecurringAll($scope.itemsPerPage, pageno).then(
                function (response) {
                    $scope.paymentTransactionsRecurring = response.data;
                    $scope.currentPage = pageno;
                    $scope.totalCount = response.data.totalCount;
                    console.log("API Service: getPaymentTransactionsAll");

                },
                function () {
                    alert("There was an error in getPaymentTransactionsAll")
                }
            )

        }
    }

    $scope.paymentReceivedAccountsDept = function(cpTransactionID, received) {

        if (confirm('Are you mark the payment receipt as received by accounts?')) {

            console.log("Test 1");

            console.log("Transaction ID: " + cpTransactionID);


            $http.post("api/payment-transactions-search/" + cpTransactionID + "/" + '1').success(function(response){

                $scope.paymentTransactions = {};
                $scope.paymentTransactions = response.data2[0];

                var from_email = "Clouderty | Payment cash received by accounts | " + response.data2[0].Received + " |" + " <notifications@clouderty.co.uk>";
                var to_email = "rentals@platinumcrown.co.uk";
                var subject = "Clouderty | Payment received by accounts | " + $scope.paymentTransactions.Description + " | Created by: " + $rootScope.decodedToken.Name + "| Cash Payment ID: " + $scope.paymentTransactions.CP_Transaction_ID;
                var message = "Payment ID: " + $scope.paymentTransactions.Payment_ID + "\n" +
                    "Transaction ID: " + $scope.paymentTransactions.Payment_Transaction_ID + "\n" +
                    "Card payment reference: " + $scope.paymentTransactions.Amount + "\n" +
                    "Created By: " + $rootScope.decodedToken.Name + "\n" +
                    "Date payment received: " + $scope.paymentTransactions.Date + "\n\n" +
                    "Date payment receipt received: " + Date()+ "\n\n" +
                    "Category: " + $scope.paymentTransactions.Category + "\n" +
                    "Payment amount: " + $scope.paymentTransactions.Amount;

                var to_email = "office@platinumcrown.co.uk";
                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                $scope.postUserActivityServiceMessage = subject;
                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);


            });
            console.log("Route reload ");
            $state.reload("lettings-main-nav.lettings-side.payment-transactions-search");

        }
        else{
            console.log("Transaction ID: " + cpTransactionID);


            $http.post("api/payment-transactions-search/" + cpTransactionID + "/" + '-1').success(function(response){
            });

        }

    }

    $scope.getData($scope.pageno);

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }


    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

}]);