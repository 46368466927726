/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("tasksSearchCtrl", ["$scope", "$http", "$rootScope", "$state", "emailService", "$uibModal", "$cookies", "postUserActivityService", "$location", "$q", "$window", "apiService", "openNewWindowService", function ($scope, $http, $rootScope, $state, emailService, $uibModal, $cookies, postUserActivityService, $location, $q, $window, apiService, openNewWindowService) {

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.currentPage = 1;
    $scope.filename = "test" + ".csv";
    $scope.paginationData = [];
    $scope.progress = {};
    $scope.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

    $scope.taskDataHeader = ["Task ID", "Description", "Detailed_Description", " ", "Status", "Priority",  "Property_ID", "Project_Name", "Task_Category_ID", "Address_1", "Address_1", "Account_Holder_ID", "company_name", "contact_name", "User_ID", "To_be_completed_by", "Date_Started"];;

    $scope.tasks = [];
    //$scope.users = [];

    $scope.obj = {};
    $scope.search = "";
    $rootScope.search = "";
    $scope.obj.search = "";

    $scope.obj.User = "";
    $scope.obj.User_ID = 0;

    $scope.obj.Estate = "";
    $scope.obj.Estate_ID = 0;
    $scope.obj.Property = "";
    $scope.obj.Property_ID = 0;

    console.log("tasksSearchCtrl");
    $scope.User_Level = $rootScope.decodedToken.User_Level;

    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end

    $scope.$watch('status', function (toVariable, fromVariable) {

        console.log("inside watch and status is: " + $scope.status);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

    });

    $scope.propertiesClear = function() {
        $scope.obj.Property = "";
    }

    $scope.estatesClear = function() {
        $scope.obj.Estate = "";
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.tasksFunction = function(id, status) {

        $scope.tasks.length = null;
        console.log("function tasksFunction");
        $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.obj.itemsPerPage = $scope.itemsPerPage;
        $scope.obj.pageno = $scope.pageno;
        $scope.obj.Task_ID = id;
        $scope.obj.Vendor_ID = "0";
        //$scope.obj.Property_ID = "0";
        $scope.obj.Status = status;
        //scope.pageno = 1;

        if($scope.obj.Task_ID){

            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.obj.Task_ID = "*";
        }


        if($scope.search){

            $scope.obj.search = $scope.search;
        }else{

            $scope.obj.search = "*";
        }

        //$scope.obj.search = "*";

        var q2 = $q.defer();
        console.log($scope.obj);

        apiService.tasks(id, $scope.obj).then(
            function (response) {
                $scope.tasks = response.data.data;
                $scope.paginationData = response.data.data;
                $scope.$parent.obj = response.data.data;

                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.totalTaskCount = response.data.totalCount;

                console.log("tasks API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tasks")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.getDataSearch = function(pageno) {
        console.log("inside function getDataSearch");
        $scope.tasks = [];

        if (!$scope.search && $scope.search_action == 2 && $scope.obj.Property && $scope.obj.User){
            $scope.status = 142;
        }
        if (!$scope.search && $scope.search_action == 2 && $scope.obj.User){
            $scope.status = 143;
        }
        if (!$scope.search && $scope.search_action == 2 && $scope.obj.Property){
            $scope.status = 141;
        }
        else if (!$scope.search && $scope.search_action == 2){
            $scope.status = 14;
        }
        else if(!$scope.search && $scope.search_action == 3){

            $scope.status = 15;
        }
        else if(!$scope.search && $scope.search_action == 1){

            $scope.status = 0;
        }
        else if(!$scope.obj.Property && !$scope.obj.Estate && !$scope.obj.User && !$scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Estate && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.obj.User){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.obj.User){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Estate && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Estate && $scope.obj.User && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.obj.Estate && $scope.obj.User && search_action){
            $scope.status = 0;
        }
        else if($scope.obj.Property && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.Estate && $scope.search){
            $scope.status = 0;
        }
        else if($scope.obj.User && $scope.search){
            $scope.status = 0;
        }

        else if($scope.search && $scope.search_action == 1){
            $scope.status = 101;
        }
        else if($scope.search && $scope.search_action == 2){
            $scope.status = 102;
        }
        else if($scope.search && $scope.search_action == 3){
            $scope.status = 103;
        }
        else if($scope.search){
            $scope.status = 1;
        }
        else if($scope.obj.User){
            $scope.status = 13;
        }
        else if($scope.obj.Estate){
            $scope.status = 12;
        }
        else if($scope.obj.Property){
            $scope.status = 11;
        }

        return $scope.status;

    }

    $scope.getData = function(pageno) {
        console.log("inside function getData");
        $scope.tasks = [];


        if ($scope.obj.Estate == "") {
            console.log("$scope.obj.Estate_ID: " + $scope.obj.Estate_ID);
            console.log("$scope.obj.Property_ID " + $scope.obj.Property_ID);
            $scope.obj.Estate_ID = "0";
        }
        else {

            $scope.obj.Estate_ID = $scope.obj.Estate;
            console.log("$scope.obj.Estate_ID: " + $scope.obj.Estate_ID);
        }
        if ($scope.obj.Property == "") {
            delete $scope.obj.Property;
            console.log("$scope.obj.Estate_ID: " + $scope.obj.Estate_ID);
            console.log("$scope.obj.Property_ID " + $scope.obj.Property_ID);
            $scope.obj.Property_ID = "0";

        }
        else {

            $scope.obj.Property_ID = $scope.obj.Property;
            console.log("$scope.obj.Property_ID " + $scope.obj.Property_ID);
        }
        if ($scope.obj.User == "") {
            console.log("$scope.obj.User_ID " + $scope.obj.User_ID);
            $scope.obj.User_ID = "0";
        }
        else {
            $scope.obj.User_ID = $scope.obj.User;


        }


        $scope.getDataSearch();
        /////////////////// Cancel previous AJAX code (B)

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        if ($rootScope.decodedToken.User_Type_ID == 1) {
            //console.log("canceler.resolve(): ");
            console.log("inside function  getData");

            $scope.obj.User_Type_ID = $rootScope.decodedToken.User_Type_ID;



            console.log("$scope.status: " + $scope.status);
            $scope.currentPage = pageno;
            $scope.pageno = pageno;
            $scope.tasksFunction('*', $scope.status);


        }
    }

    $scope.getTask = function(status, id) {

        console.log("Where in getTask()");

        if (status == 'Completed') {

            console.log("Clicked a completed task.");

        } else {


            console.log("Clicked an incomplete task.");

            $http.get("api/task_completed/" + id).success(function(response){

                $scope.task = response;

            });

        }

    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.updateProgress = function (progress, taskID) {
        console.log("inside function updateProgress");
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Task ID: " + taskID);

        $scope.progress.progress = progress;
        $scope.progress.Status = 21;

        $scope.progress.Task_ID = taskID;
        $scope.progress.Task_Status_ID = progress;
        $scope.progress.Date_Completed = date;
        $scope.progress.progress = progress;
        $scope.progress.id = taskID;
        $scope.progress.date_completed = date;


        if($scope.progress.progress == 5) {



            console.log("Task completed: " + progress);

            apiService.tasks(taskID, $scope.progress).then(
                function (response) {
                    console.log("Task updated as completed");

                    $scope.obj = response;

                    console.log("$scope.obj");
                    var from_email = "Clouderty | Task Completed | "  + $scope.obj.data.Property_name + " | " + $scope.obj.data.Description + " | " +  " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Task Completed | " + " - Completed by: " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + " | " + $scope.obj.data.Description + " - Created By: " + $scope.obj.data.Created_By;
                    var message = "Clouderty | Task Completed | " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + "\n Task Name: " + $scope.obj.data.Description + "\nDate started: " + $scope.obj.data.Date_started + " | Date completed  " + $scope.obj.data.Date_completed + "\n\n Created by: " + $scope.obj.data.Created_By + "\n\n Completed By: " + $rootScope.decodedToken.Name;
                    var to_email = "rentals@platinumcrown.co.uk";
                    $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Task complete | Task ID' + $scope.obj.data.Task_ID);

                },
                function () {
                    console.log("API Service Error: tenancies")
                }
            );




        }
        else {

            console.log("Task not completed: " + progress);
            $scope.progress.id = taskID;
            $scope.progress.date_completed = null;
            $scope.progress.Date_Completed = null;

            apiService.tasks(taskID, $scope.progress).then(
                function (response) {
                    console.log("Task updated as completed");

                    $scope.obj = response;
                    $state.reload();

                    /*
                    console.log("$scope.obj");
                    var from_email = "Clouderty | Task Completed | "  + $scope.obj.data.Property_name + " | " + $scope.obj.data.Description + " | " +  " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Task Completed | " + " - Completed by: " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + " | " + $scope.obj.data.Description + " - Created By: " + $scope.obj.data.Created_By;
                    var message = "Clouderty | Task Completed | " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + "\n Task Name: " + $scope.obj.data.Description + "\nDate started: " + $scope.obj.data.Date_started + " | Date completed  " + $scope.obj.data.Date_completed + "\n\n Created by: " + $scope.obj.data.Created_By + "\n\n Completed By: " + $rootScope.decodedToken.Name;
                    var to_email = "rentals@platinumcrown.co.uk";
                    $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Task complete | Task ID' + $scope.obj.data.Task_ID);


                    */

                },
                function () {
                    console.log("API Service Error: tenancies")
                }
            );

            /*
            $http.put("api/tasks/progress", $scope.progress).success(function(response) {


            })
            */

        }

    }

    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(progress == "1") {
            console.log("Not Completed Tasks");
            $scope.getData(1);
            //$scope.tasksFunction('*', 0);
        } else if (progress == "2") {
            console.log("Completed Tasks");
            //$scope.tasksFunction('*', 14);
            $scope.getData(1);
        } else if (progress == "3") {
            console.log("All Tasks");
            $scope.getData(1);
            //$scope.tasksFunction('*', 15);
            //$scope.progress.date_completed = null;
        }  else{

        }

    }

    $scope.usersViewAll = function () {

        apiService.getUsers($rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                $scope.users = response.data.data;
                console.log("API getUsers: " );
            },
            function() {
                console.log("We are in getUsers");
            }
        )

    }

    $scope.estatesViewAll = function () {

        apiService.getEstates($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.estates = response.data.data;
                console.log("API estatesViewAll: " );
            },
            function() {
                console.log("We are in estatesViewAll");
            }
        )

    }

    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }

    $scope.actionTask = function (action, id) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "1") {
            //$scope.progress.date_completed = date;
            console.log("Edit Tenant");
            //$scope.editTenant(tenantID);
        } else if (action == "2") {
            console.log("Edit Tenant");
            //$scope.getDataExpired(1)
            //$scope.editTenancy(tenancyID);
            //$scope.progress.date_completed = null;
        } else if (action == "3") {
            console.log("View Rental Statement");

            console.log("tenancyID: " + tenancyID);

            console.log("licenseKey: " + licenseKey);
            //$scope.getDataExpired(1)
            //$scope.viewTenancyRentalStatement(tenancyID, licenseKey);
            //$scope.progress.date_completed = null;
        } else if (action == "4") {
            console.log("View task account");
            //$scope.getDataExpired(1)
            $scope.viewTask(id);
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (action == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData($scope.pageno)

        } else{

        }

    }

    $scope.viewTask = function(id) {

        console.log("function viewProcessAccount");
        //$scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View process account ' +  processAccountID);

        console.log("Process Account ID = " + id);

        var url = $state.href('lettings-main-nav.tasks-side.task-view.notes', {id:  id});
        window.open(url,'_blank');

    }


    $scope.getData($scope.pageno);
    $scope.usersViewAll();
    $scope.estatesViewAll();
    $scope.propertiesViewAll();
    //$scope.properties();

}]);