/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.directive('showHide2', ["$rootScope","$compile", function ($rootScope, $compile) {
    return {
        template: function($scope, $element, $attrs) {
            var html = "";
            self.isVisible = true;

            if ($rootScope.decodedToken.Clouderty_System_Administrator == 1) {
                console.log("Testing Directives");
                html = '<div ng-show="false">hhhhh</div>';
            }

            return html;
        }
    }
}]);