
clouderty.controller("awardsNominationRequestsAddEditCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService) {


    console.log("inside controller awardsNominationRequestsAddEditCtrl");

    $('.data').hide();

    $scope.$watch('watchAwardsSurround', function (toVariable, fromVariable) {

        console.log("inside watch and $rootScope.watchAwardsSurround is: " + $rootScope.watchAwardsSurround);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

        if (toVariable == 1) {


            $('.data').hide();




            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;
            $scope.search = "";
            $scope.progress = {};
            $scope.DateToday = new Date();
            $scope.date = new Date();

            //Directive variables
            $scope.pageTitleScope = "";

            $scope.awardNominations = {};
            $scope.awardCategories = {};
            $scope.awards = {};
            $scope.awards.awardCategories = {};
            $scope.awards.awardNominationRequest = {};
            $scope.awards.awardNominator = {};
            $scope.awardsData = {};
            $scope.countriesData = {};
            $scope.countries = {};

            $scope.nomineesDetailsBloc = 1;
            $scope.nomineesDetailsVisibleBloc = 1;
            $scope.nominationSubmittedBlock = 0;
            $scope.nominationSubmittedEntireBlock = 0;
            $scope.nominationSubmittedEntireSpinner= 0;

            $(document).ready(function () {
                $('.nav-tabs li').on("click", function() {
                    console.log('is this it 1');
                    $('.nav-tabs li').removeClass("active");
                    $(this).addClass("active");
                })
            })
            $('.data').hide();



            $scope.nomineeType = {
                Nominee_Type_ID: '1'
            };

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )

                return q55.promise;

            }

            $scope.awardsFunction = function(id, status) {

                var qawardsFunction = $q.defer();

                console.log("function processesAccountFunction");
                console.log("$scope.pageno" + $scope.pageno);

                $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.awardsData.id = $rootScope.decodedToken.id;
                $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
                $scope.awardsData.pageno = $scope.pageno;
                $scope.awardsData.Status = status;
                $scope.awardsData.Process_ID = id;


                if($scope.search){

                    $scope.awardsData.search = $scope.search;

                }
                else{

                    $scope.awardsData.search = "*";

                }

                apiService.awards(id, $scope.awardsData).then(
                    function (response) {
                        console.log("apiService awards service");

                        if(status == 60){

                            $scope.awardCategories = response.data.awardsCategories;

                            console.log(response.data.awardsCategories);

                            console.log(response.awardsCategories);

                        }
                        if(status == 64){


                            console.log("apiService awards service results status 64");

                            //$("#awardCategories").attr('disabled', true);
                            //$scope.testButton();

                            $scope.awards.awardNominationRequest.First_Name = "";
                            $scope.awards.awardNominationRequest.Last_Name = "";
                            $scope.awards.awardNominationRequest.Email = "";
                            $scope.awardNominations = response.data;
                            $scope.nominatorsDetailsBloc = 1;
                            $scope.nomineesDetailsVisibleBloc = 0;
                            $scope.nominationSubmittedEntireBlock = 0;
                            //$scope.nominationSubmittedEntireBlock = 1;
                            $scope.nominationSubmittedEntireSpinner = 0;

                            console.log(response.data);
                            console.log($scope.awardNominations);

                        }
                        if(status == 641){

                            $scope.awards.awardNominator.First_Name = "";
                            $scope.awards.awardNominator.Last_Name = "";
                            $scope.awards.awardNominator.Email = "";
                            $scope.awardNominator = response.data.data;


                            $scope.nominationSubmittedBlock = 1;
                            $scope.nominationSubmittedEntireBlock = 1;
                            $scope.nominationSubmittedEntireSpinner = 0;

                        }

                        console.log("awards API successful");


                        console.log(response);
                        qawardsFunction.resolve(response);


                    },
                    function () {

                        console.log("API Service Error: awards");

                    }

                );

                return qawardsFunction.promise;

            }
            $scope.testButton = function () {
                console.log("inside function testButton");
                $("#awardCategories").attr('disabled', true);
            }
            $scope.disableAwardsCategoriesSelect = function () {
                console.log("inside function testButton");
                $("#awardCategories").attr('disabled', true);
            }

            $scope.getCategories = function () {

                var qgetCategories = $q.defer();

                qgetCategories.resolve($scope.awardsFunction('*', 60));

                return qgetCategories.promise;

            }


            $scope.getCountries = function () {

                var qgetCountries = $q.defer();

                qgetCountries.resolve(
                    apiService.countries('*', $scope.countriesData).then(
                        function (response) {
                            $scope.countries = response.data.data;
                            qgetCountries.resolve(response);
                        }
                    )
                );

                return qgetCountries.promise;

            }


            $scope.addNomination = function () {

                console.log("inside function addNomination");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nomination?")){


                    $scope.nominationSubmittedEntireSpinner = 1;
                    $scope.nominationSubmittedEntireBlock = 2;

                    $scope.awardsData.First_Name = $scope.awards.awardNominationRequest.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominationRequest.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominationRequest.Contact_number;
                    $scope.awardsData.Address_1 = $scope.awards.awardNominationRequest.Address_1;
                    $scope.awardsData.Address_2 = $scope.awards.awardNominationRequest.Address_2;
                    $scope.awardsData.Town = $scope.awards.awardNominationRequest.Town;
                    $scope.awardsData.City_Region = $scope.awards.awardNominationRequest.City_Region;
                    $scope.awardsData.Postcode = $scope.awards.awardNominationRequest.Postcode;

                    $scope.awardsData.Company_Name = $scope.awards.awardNominationRequest.Company_Name;
                    $scope.awardsData.Company_Website = $scope.awards.awardNominationRequest.Company_Website;
                    $scope.awardsData.Awards_Nominee_Type_ID = $scope.awards.awardNominationRequest.Awards_Nominee_Type_ID;


                    console.log("$scope.awards.awardNominationRequest.Company_Website" + $scope.awards.awardNominationRequest.Company_Website);

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2 - awardsFunction");
                            //$scope.getCategories();


                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2 - awardsFunction");
                            //$scope.getCategories();


                            return $scope.awardsFunction('*', 64);
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2 - awardsFunction");
                            //$scope.getCategories();

                            //$("#awardCategories").attr('disabled', true);
                            //$scope.testButton();


                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a - Email confirmation');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                            $scope.nominationSubmittedBlock = 1;


                            var from_email = "Clouderty | Awards Nomination Submitted - " + $scope.awardNominations.Awards_Nomination_Request_ID + " | " + $scope.awardsData.Category + " <notifications@clouderty.co.uk>";
                            var subject = "Property Investors Awards | " + $scope.awardsData.Category;
                            var message = "Awards Nomination Request ID: " + $scope.awardNominations.Awards_Nomination_Request_ID + "<br /><br />" +
                                "Awards Category: " + $scope.awardsData.Category + "<br /><br />" +
                                "Nominee Name: " + $scope.awardsData.First_Name + "<br /><br />" +
                                "Nominee Contact Number: " + $scope.awardsData.Contact_number + "<br /><br />" +
                                "Nominee Email: " + $scope.awardsData.Email + "<br /><br />" +
                                "If you have not yet reserved your tickets to the property investors celebration of the year on 1st December 2018 in Central London, you can do so now at " +
                                "https://propertyinvestorsawards.co.uk/secure-your-ticket. <br /><br />";

                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            //to_email = $rootScope.decodedToken.Notifications_Email;
                            to_email = "notifications@clouderty.co.uk";

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                            $scope.disableAwardsCategoriesSelect();
                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }


            $scope.addNominator = function () {

                console.log("inside function addNominator");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nominator?")){
                    $scope.awardsData.Nominators_First_Name = $scope.awards.awardNominator.Nominators_First_Name;
                    $scope.awardsData.Nominators_Last_Name = $scope.awards.awardNominator.Nominators_Last_Name;
                    $scope.awardsData.Nominators_Email = $scope.awards.awardNominator.Nominators_Email;
                    $scope.awardsData.Nominators_Contact_Number = $scope.awards.awardNominator.Nominators_Contact_Number;

                    $scope.awardsData.Awards_Nomination_Request_ID = $scope.awardNominations.Awards_Nomination_Request_ID;


                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 641));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nominator Added " + '18' + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + "18" + "<br /><br />" +
                                "Awards Category: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Name: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Contact Number: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Email: " + $scope.date + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.getAwardsNomineeType = function () {
                console.log("$scope.awards.awardNominationRequest.Awards_Nominee_Type_ID " + $scope.awards.awardNominationRequest.Awards_Nominee_Type_ID);

                $scope.awardsData.Awards_Nominee_Type_ID = $scope.awards.awardNominationRequest.Awards_Nominee_Type_ID;

            }

            $scope.getCategory = function () {
                $scope.awardsData.Category = $scope.awards.awardCategories.Category;
                $scope.awardsData.Description = $scope.awards.awardCategories.Description;
                $scope.awardsData.Awards_Category_ID = $scope.awards.awardCategories.Awards_Category_ID;
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Description);
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Awards_Category_ID);

            }

            $scope.getCountry = function () {

                $scope.awardsData.Country_ID = $scope.awards.countries.Country_ID;
                $scope.awardsData.Country = $scope.awards.countries.Country;
                console.log("$scope.awardsData.Country_ID: " + $scope.awardsData.Country_ID);
                console.log("$scope.awardsData.Country: " + $scope.awardsData.Country);

            }

            $scope.loadAwardsData = function () {
                console.log("inside function loadAwardsData");


                var qloadAwardsData = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A2");
                        //$scope.getCategories();


                        return $scope.getCategories(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A3");
                        //$scope.getCountries();
                        return $scope.getCountries(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7a');
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);



                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qloadAwardsData.resolve($scope.promise);

            };

            $scope.random = function() {
                return 0.5 - Math.random();
            }

            $scope.loadAwardsData();
            $('.data').hide();
            //$scope.init();











        }








    })

    $('.data').hide();
}])