/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("notesCtrl", ["$scope", "$http", "$stateParams", "Upload", "$rootScope", "emailService", "postUserActivityService", "$filter", "apiService", "yesNoFilter", "$uibModal", "fCsv", "$timeout", "exportCSVService", "$state", "dateInputService", "$q", "fileUploadService", "notePostService", "truncateSliceService", function ($scope, $http, $stateParams, Upload, $rootScope, emailService, postUserActivityService, $filter, apiService, yesNoFilter, $uibModal, fCsv, $timeout, exportCSVService, $state, dateInputService, $q, fileUploadService, notePostService, truncateSliceService)  {

    console.log("inside controller notesCtrl");

    //$scope.obj = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.search = "";
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();
    $scope.property = [];
    $rootScope.property = {};
    $rootScope.property.Property_name = "";
    $rootScope.property.Unit_number = "";
    $rootScope.tenancy_id = $stateParams.id;

    $scope.noteGet = {};
    $scope.notes = {};
    $scope.notesData = [];
    $scope.notesInputsData = [];
    $scope.notesInputsData.Tenancy_ID = "";
    $scope.note = {};
    $scope.note.id = null;
    $scope.note_loading = false;
    $scope.processAccount = {};
    $scope.posted_note = {};

    $scope.processes_account = [];
    $scope.processAccountID = "";

    $rootScope.noteSpinner = 0;

    $scope.notificationObj = {};

    $scope.stateProcessAccountView = "lettings-main-nav.lettings-side.process-account-view.notes";
    $scope.stateAwardsProcessAccountView = "lettings-main-nav.lettings-side.awards-process-account-view.notes";
    $scope.statePaymentTransactionAddSurround = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
    $scope.stateTenancyAgreementExternal = "tenancy-agreement-room.payment";
    $scope.stateTenancyView = "lettings-main-nav.lettings-side.lettings-view-tenancy.notes";
    $scope.stateTaskView = "lettings-main-nav.tasks-side.task-view.notes";
    $scope.stateTaskContractorsView = "contractors-main-nav.contractors-tasks-side.task-view.notes";
    $scope.stateCurrent = "";


    console.log('$stateParams.id: ' + $stateParams.id);
    console.log('$stateParams.processAccountID: ' + $stateParams.processAccountID);

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.postNote = function (note_content, file) {
        console.log("inside function postNote");

        if(confirm("Are you sure you want to add this note?") == true) {

            $rootScope.noteSpinner = 0;

            var date = new Date();
            date.setHours(date.getHours() + 1);


            if($scope.obj.hasOwnProperty('data')){
                console.log("$scope.obj.data.Property_name: " + $scope.obj.data.Property_name);

                $scope.noteGet.Property_name = $scope.obj.data.Property_name;
            }
            else if($scope.obj.hasOwnProperty('Property_name')){
                console.log("$scope.obj.Property_name: " + $scope.obj.Property_name);
                $scope.noteGet.Property_name = $scope.obj.Property_name;
            }


            if($scope.obj.hasOwnProperty('data')){
                console.log("$scope.obj.data.First_Name: " + $scope.obj.data.First_Name);

                $scope.noteGet.First_Name = $scope.obj.data.Property_name;
            }
            else if($scope.obj.hasOwnProperty('First_Name')){
                console.log("$scope.obj.Property_name: " + $scope.obj.First_Name);
                $scope.noteGet.First_Name = $scope.obj.First_Name;
            }
             

            $scope.note_loading = true;
            //$scope.noteGet.Process_Account_ID = $stateParams.processAccountID;
            //$scope.noteGet.Tenancy_ID = $scope.processAccount.Tenancy_ID;
            $scope.noteGet.User_ID = $rootScope.decodedToken.id;
            //$scope.noteGet.Notes_Type = "Arrears";
            $scope.noteGet.Date_Entered = date;
            $scope.noteGet.Note_Content = note_content;
            //$scope.noteGet.Note_Type_ID = 3;
            //$scope.noteGet.Processes_Linked_to_Account_ID = 5544;
            $scope.noteGet.picFile = file;

            //hide picture and clear note section
            $scope.obj.note_content = "";
            $scope.picFile = null;

            console.log("$scope.noteGet.Tenancy_ID: " + $scope.noteGet.Tenancy_ID);
            console.log("$scope.note.Note_Type_ID: " + $scope.noteGet.Note_Type_ID);
            var q11 = $q.defer();
            var myValue = 0;
            var promise = $scope.getGlobalPromise();



            console.log("$scope.noteGet " + $scope.noteGet);

            promise
                .then(function (data) {
                    console.log($scope.noteGet);
                    console.log("A1a - postNote");
                    //return $scope.posted_note = notePostService.postNote($scope.noteGet);

                    return notePostService.postNote($scope.noteGet);

                    //return $scope.getGlobalPromise(data);
                })//post note
                .then(function (data) {

                    console.log("A1c");

                    console.log($scope.posted_note);
                    console.log("console.log($scope.posted_note) >>>> " + $scope.posted_note);

                   //$scope.note_loading = false;

                    if($state.current.name == $scope.stateProcessAccountView || $state.current.name == $scope.stateAwardsProcessAccountView){
                        $scope.processes_account.Process_Account_ID = processAccountID;
                        $scope.processAccount = $scope.$parent.processData;
                        console.log($scope.processAccount);
                        $scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                        console.log("Tenancy ID: " + $scope.notesInputsData.Tenancy_ID);

                    }

                    $scope.posted_note.note = "";
                    $scope.obj.note = "";
                    //$scope.getNotes($scope.notesInputsData);

                    return $scope.getGlobalPromise(data);
                })/*
                .then(function (data) {

                    console.log("promise getNotes()");

                    return $scope.getNotes();
                })*/
                // set variables
               .then(function (data) {

                    console.log("A5 loadProcessDataStateProcessAccountView");


                    return $scope.loadProcessDataStateProcessAccountView(data);
                })
                .then(function (data) {
                        console.log('A7 notes reset variables');
                    if($state.current.name == $scope.stateProcessAccountView || $state.current.name == $scope.stateAwardsProcessAccountView) {
                        console.log("$scope.stateProcessAccountView: " + $state.current.name);
                        $scope.processAccountID = $stateParams.processAccountID;

                        //$scope.processes_account.Process_Account_ID = processAccountID;
                        //$scope.processAccount = $scope.$parent.processData;
                        //console.log($scope.processAccount);
                        //$scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                        //console.log("Tenancy ID: " + $scope.notesInputsData.Tenancy_ID);
                        var descriptionNotification = "Process ID: " + $scope.processAccountID;

                    }
                    if($state.current.name == $scope.stateTenancyView) {
                        console.log("$scope.stateProcessAccountView: " + $scope.stateTenancyView);

                        //$scope.noteGet.Tenancy_ID = $stateParams.id;
                        //$scope.noteGet.Notes_Type_ID = 1;
                        //$scope.noteGet.Notes_Type = "Tenancy";
                        var descriptionNotification = "Tenancy ID: " + $stateParams.id;

                    }
                    if($state.current.name == $scope.stateTaskView) {
                        console.log("$scope.stateProcessAccountView: " + $scope.stateTaskView);

                        //$scope.noteGet.Notes_Type_ID = 2;
                        //$scope.noteGet.Notes_Type = "Task";
                        //$scope.noteGet.Task_ID = $stateParams.id;



                        if ($scope.$parent.obj.Address_1 == null){


                            //$scope.noteGet.Description = $scope.$parent.obj.Description;
                            var descriptionNotification = "Task ID: " + $stateParams.id + " - " + $scope.$parent.obj.Address_1 + " - " + $scope.$parent.obj.Description;

                        }
                        else {

                            /*
                            $scope.noteGet.Description = $scope.$parent.obj.Description;
                            $scope.noteGet.Property_name = $scope.$parent.obj.Property_name;

                            $scope.noteGet.Address_1 = $scope.$parent.obj.Address_1;
                            */

                            var descriptionNotification = "Task ID: " + $stateParams.id + " - " + $scope.$parent.obj.Description;
                        }

                    }


                    var urlNotification = window.location.href;
                    //var urlNotification = $rootScope.cloudertyBaseURL + "lettings/tasks/view-task//task/" + $scope.obj.Task_ID;
                    if($state.current.name == $scope.stateTaskView) {

                        $scope.notificationObj.vendors = $scope.$parent.vendorTasks;
                        console.log('notificationObj.vendors');
                        console.log($scope.notificationObj.vendors);

                    }


                    $rootScope.saveNotification(4, descriptionNotification, urlNotification, $scope.notificationObj);
                    $scope.posted_note.note = "";
                    //$scope.obj.note_content = "";
                    $scope.note_loading = false;
                    //$scope.picFile = null;
                    console.log('$scope.stateCurrent' + $scope.stateCurrent);
                    //$state.reload($scope.stateCurrent);
                    //console.log("stop spinner");
                    //$rootScope.noteSpinner = 1;

                    myValue = data;
                }//reload notes
                ,function (err) {
                    myValue = err;
                })//end promise

            q11.resolve(promise);
        }

    }

    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

    };

    $scope.processesAccountFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
        $scope.processes_account.pageno = $scope.pageno;
        $scope.processes_account.Status = status;
        $scope.processes_account.Process_ID = id;

        if($scope.processes_account.Process_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            $scope.processes_account.Procress_ID = "*";
        }

        if($scope.search){

            $scope.processes_account.search = $scope.search;
        }
        else{

            $scope.processes_account.search = "*";
        }

        var qprocessesAccountFunction2notes = $q.defer();

        console.log($scope.processes_account);
        apiService.processes(id, $scope.processes_account).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                $scope.processData = response.data.data;

                console.log("status: " + status);
                if(status == 4 || status == 5){

                    $scope.processAccount = response.data.data;
                    $scope.processAccountAdded = '1';
                    $scope.note.id = $scope.processAccount.Tenancy_ID;
                }
                if(status == 63){

                    $scope.processTypes = response.data.processTypes;
                }
                if(status == 64){

                    $scope.processesLinkedToAccount = response.data.processesLinkedToAccount;
                    $scope.processesTotalCount = response.data.processesTotalCount;
                    //console.log("$scope.processes: " + $scope.processesLinkedToAccount);
                    //console.log($scope.processesLinkedToAccount);

                }
                else{

                    $scope.process_accounts = response.data.data;
                    $scope.totalCount = response.data.totalCount;

                }

                console.log("processes API successful");

                console.log(response);
                qprocessesAccountFunction2notes.resolve(response);


            },
            function () {
                console.log("API Service Error: processes")
            }

        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qprocessesAccountFunction2notes.promise;
    }

    $scope.getNoteType = function (){

        return "";
    }

    $scope.getNotes = function () {

        console.log("inside function getNotes XXXXXXXXXXXXXXZZZ");


        var qgetNotes = $q.defer();


        $scope.noteGet.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.noteGet.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.noteGet.itemsPerPage = $scope.itemsPerPage;
        $scope.noteGet.pageno = $scope.pageno;
        $scope.noteGet.Vendor_ID = 0;
        $scope.noteGet.Status = status;

        //console.log("Tenancy ID: " + $scope.noteGet.Tenancy_ID);

        if ($rootScope.decodedToken.User_Type_ID == 1) {

            console.log("getNotes 1: " + $rootScope.decodedToken.User_Level);

            apiService.notes(0, $scope.noteGet).then(
                function (response) {
                    $scope.notesData = response.data.notes;
                    $rootScope.watchNotes = 0;
                    console.log(response);
                    console.log($scope.notesData);
                    qgetNotes.resolve(response);
                }
            );

            $scope.note_loading = false;
            return qgetNotes.promise;
        }
        else if ($rootScope.decodedToken.User_Type_ID == 2) {

            console.log("getNotes 1: " + $rootScope.decodedToken.User_Level);

            apiService.notes(0, $scope.noteGet).then(
                function (response) {
                    $scope.notesData = response.data.notes;

                    console.log(response);
                    console.log($scope.notesData);
                    qgetNotes.resolve(response);
                }
            );

            $scope.note_loading = false;
            return qgetNotes.promise;

        }
    }

    console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);

    if($state.current.name == $scope.stateProcessAccountView || $state.current.name == $scope.stateAwardsProcessAccountView) {

        console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);

        console.log($state.current.name);
        if($state.current.name == $scope.stateProcessAccountView) {

            $scope.stateCurrent = $scope.stateProcessAccountView;

        }
        else if($state.current.name == $scope.stateAwardsProcessAccountView) {

            $scope.stateCurrent = $scope.stateAwardsProcessAccountView;

        }
        console.log($scope.stateCurrent);
        var processAccountID = $stateParams.processAccountID;

        console.log("processAccountID XXXXXXXXXXXXXXZZZ: " + processAccountID);

        $scope.loadProcessDataStateProcessAccountView = function () {
            console.log("inside function loadProcessData");

            var qloadProcessDataStateProcessAccountView = $q.defer();


            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A3");

                    $scope.noteGet.Notes_Type_ID = 3;
                    $scope.noteGet.Notes_Type = "Process";
                    $scope.noteGet.Process_Account_ID = processAccountID;

                    $scope.processes_account.Process_Account_ID = processAccountID;
                    //$scope.processAccount = $scope.$parent.processData;
                    $scope.processAccount = $scope.$parent.processAccount;
                    console.log($scope.processAccount);
                    console.log($scope.noteGet);

                    $scope.noteGet.Tenancy_ID = $scope.processAccount.Tenancy_ID;

                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {


                    console.log($scope.processAccount);

                    //$scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                    //console.log("Tenancy ID: " + $scope.noteGet.Tenancy_ID);

                    console.log("promise loadProcessDataStateProcessAccountView getNotes()");


                    return $scope.getNotes();
                    //return $scope.getGlobalPromise(data);

                })
                .then(function (data) {


                    console.log("stop spinner");

                    //$scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                    //console.log("Tenancy ID: " + $scope.noteGet.Tenancy_ID);


                    $rootScope.noteSpinner = 1;

                    return "";
                    //return $scope.getGlobalPromise(data);

                }, function (err) {
                    $scope.myValue = err;
                })

            qloadProcessDataStateProcessAccountView.resolve($scope.promise);

            return qloadProcessDataStateProcessAccountView.promise;
        }
    }
    else if($state.current.name == $scope.stateTenancyView) {
        console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);
        $scope.stateCurrent = $scope.stateTenancyView;
        console.log("Tenancy ID XXXXXXXXXXXXXXZZZ: " + $stateParams.id);
        $scope.loadProcessDataStateProcessAccountView = function () {

            console.log("inside function loadProcessData");
            var qloadProcessDataStateProcessAccountView = $q.defer();
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A3");
                    $scope.noteGet.Tenancy_ID = $stateParams.id;
                    $scope.noteGet.Notes_Type_ID = 1;
                    $scope.noteGet.Notes_Type = "Tenancy";
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("promise loadProcessDataStateProcessAccountView getNotes()");
                    return $scope.getGlobalPromise(data);
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("promise loadProcessDataStateProcessAccountView getNotes()");
                    return $scope.getNotes();
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {


                        console.log("stop spinner");

                        //$scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                        //console.log("Tenancy ID: " + $scope.noteGet.Tenancy_ID);


                    $rootScope.noteSpinner = 1;

                        return "";
                        //return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            qloadProcessDataStateProcessAccountView.resolve($scope.promise);

            return qloadProcessDataStateProcessAccountView.promise;
        }
    }
    else if($state.current.name == $scope.stateTaskView) {

        console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);
        $scope.stateCurrent = $scope.stateTaskView;
        console.log("Task ID XXXXXXXXXXXXXXZZZ: " + $stateParams.id);

        $scope.loadProcessDataStateProcessAccountView = function () {

            console.log("inside function loadProcessData");
            var qloadProcessDataStateProcessAccountView = $q.defer();
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A3");


                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A3");

                    $scope.noteGet.Notes_Type_ID = 2;
                    $scope.noteGet.Notes_Type = "Task";
                    $scope.noteGet.Task_ID = $stateParams.id;




                    if ($scope.$parent.obj.Address_1 == null){


                        $scope.noteGet.Description = $scope.$parent.obj.Description;


                    }else {

                        $scope.noteGet.Description = $scope.$parent.obj.Description;
                        $scope.noteGet.Property_name = $scope.$parent.obj.Property_name;

                        $scope.noteGet.Address_1 = $scope.$parent.obj.Address_1;

                    }

                    console.log($scope.noteGet);

                    return $scope.getGlobalPromise(data);
                })/*
                .then(function (data) {

                    console.log("A3a");

                    return $scope.getGlobalPromise(data);
                })*/
                .then(function (data) {

                    console.log("promise loadProcessDataStateProcessAccountView getNotes()");

                    return $scope.getNotes();
                    //return $scope.getGlobalPromise(data);

                })
                .then(function (data) {


                    console.log("stop spinner");

                    //$scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                    //console.log("Tenancy ID: " + $scope.noteGet.Tenancy_ID);


                    $rootScope.noteSpinner = 1;

                    return "";
                    //return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            qloadProcessDataStateProcessAccountView.resolve($scope.promise);

            return qloadProcessDataStateProcessAccountView.promise;
        }

    }
    else if(1){

    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.$watch('watchNotes', function (toVariable, fromVariable) {
        console.log('watch watchNotes');
        if($rootScope.watchNotes == 1){

            console.log('watch watchNotes inside watchNotes = 1');
            $scope.loadProcessDataStateProcessAccountView();

        }

    })

}])