/**
 * Created by Cyril on 06/07/2017.
 */
clouderty.controller("dashboardFinancialCtrl", ["$scope", "stateAuthCheckService", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "$location", "$q", "apiService", function($scope, stateAuthCheckService, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, $location, $q) {

    //stateAuthCheckService.runAuthCheckRootScope('lettings-main-nav.financials-side.dashboard-financial');

    //this.resovle1 = backendData;
    /*
    if (auth === false) {
        console.log('Resolved failed, Redirect');
        $state.go('login.signin', {param:4});
    }
*/
    //console.log(myFriendsHotel.hotelName);

    // var vm = this;

    //vm.students = myFriendsHotel;

    //console.log(backendData);

    //$scope.hello = backendData;

    //console.log(myFriendsHotel.roomNo);

    /*
    var self = this;
    apiService.getGlobalPromise().then(function(user) {

        self.user = user;

    });


    var qdashboardFinancialCtrl = $q.defer();


    apiService.all().then(
        function (response) {

            //$scope.landlords = response.data;
            console.log("API getGlobalPromise dashboardFinancialCtrl");

            $scope.response = response;

        }
    )
    */
    $scope.detectmob = function()
    {
        console.log("detectmob function");

        if(window.innerWidth <= 800 && window.innerHeight <= 600){
        //if(window.innerWidth <= 800){

            alert("mobile view - width: " + window.innerWidth);
            return true;
        }
        else{

            alert("mobile not view - width: " + window.innerWidth);
            return false;
        }
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }


    $scope.go = function go(action) {
        //$location.path('lettings-main-nav.lettings-side.lettings-search-tenancy');
        if (action == 'payment-transaction-add'){
            $state.go('lettings-main-nav.financials-side.payment-transaction-add');
        }else if (action == 'payment-transactions-view'){

            $state.go('lettings-main-nav.financials-side.payment-transactions-search');

        }
        else if (action == 'payment-transaction-recurring-add'){

            $state.go('lettings-main-nav.lettings-side.payment-transaction-recurring-add');

        }
        else if (action == 'cash-transactions-view'){

            $state.go('lettings-main-nav.lettings-side.cash-transactions-search');

        }else if (action == 'payment-transactions-recurring-view'){

            $state.go('lettings-main-nav.lettings-side.payment-transactions-recurring-search');

        }
        else if (action == 'cash-transactions-view'){

            $state.go('lettings-main-nav.lettings-side.cash-transactions-search');

        }
        else if (action == 'card-transactions-view'){

            $state.go('lettings-main-nav.lettings-side.card-transactions-search');

        }
        else if (action == 'rental-transactions-view'){

            $state.go('lettings-main-nav.lettings-side.tenants-rental-transactions-search');

        }
        else if (action == 'rental-account-balances-view'){

            $state.go('lettings-main-nav.lettings-side.tenants-rental-account-balances');

        }
        else if (action == 'payment-transaction-request'){

            $state.go('lettings-main-nav.financials-side.payment-transaction-request');

        }
    };
    
}])