/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("websiteVisitorDetailsService", ["$http", "$q", "$rootScope", "Upload", "fileUploadService", "apiService", "truncateSliceService", function ($http, $q, $rootScope, Upload, fileUploadService, apiService, truncateSliceService) {
    console.log("inside service notePostService   xxxxxxxxxxxxxxxxxxxxxxxx");
    this.geoData = function () {

        var getGlobalPromise = function () {

            var q55 = $q.defer();
            apiService.getGlobalPromise().then(
                function (response) {
                    console.log("API getGlobalPromise");
                    q55.resolve(response);
                }
            )
            return q55.promise;
        }

        var qpostNote = $q.defer();
        var posted_note = [];
        var file = [];

        console.log("inside function: postNote");

        console.log("noteData  >>>> " + noteData);

        console.log(noteData);


        $http.post("api/notes", noteData)

            .success(function (response) {

                posted_note = response.posted;

                console.log(posted_note);
                console.log("response posted_note: " + posted_note);

                console.log(response);
                console.log(posted_note.note_id);


                var myValue = 0;

                var promise = getGlobalPromise();

                promise
                    .then(function (data) {

                        console.log("A1a");
                        console.log(posted_note);
                        return getGlobalPromise(data);
                    })
                    .then(function (data) {

                        //create pdf

                        console.log("A1b");
                        console.log(posted_note);

                        //remove picture thumbnail
                        file = fileUploadService.uploadFile(noteData.picFile, posted_note.note_id);

                        //return $scope.uploadFile($scope.picFile);

                        return getGlobalPromise(data);
                    })
                    .then(function (data) {

                        //create pdf

                        console.log("A1bn");


                        console.log("file >>>>>>>>>>>" + file);
                        console.log(file);


                        console.log("noteData" + noteData);
                        console.log(noteData);



                        if(!file){

                        }else{

                            console.log(file.$$state.value.data.File_ID);
                            posted_note.File_ID = file.$$state.value.data.File_ID;
                            posted_note.fileName = posted_note.Account_Holder_ID + "_" + posted_note.File_ID + ".jpg";
                            posted_note.fileURL = " https://management.clouderty.co.uk/uploads/" + posted_note.fileName;

                        }

                        console.log("posted_note.fileName: " + posted_note.fileName);
                        console.log("posted_note.fileURL: " + posted_note.fileURL);
                        console.log("posted_note.fileURL: " + posted_note.fileURL);


















                        if (noteData.Address_1 == null){
                            console.log("email 5: " + noteData.Address_1);
                            console.log("email 5: " + noteData.Property_name);
                            console.log("email 5: " + noteData.Description);


                            var from_email = "Clouderty | Note Created " + posted_note.note_id + " | " + " <notifications@clouderty.co.uk>";
                            var subject = "Created by: " + $rootScope.decodedToken.Name + " | " + noteData.Description + " | " + "Task ID: " + posted_note.id;
                            var message = "Note ID: " + posted_note.note_id + "<br />" +
                                "As Part Of Task: " + posted_note.id + "<br />" +
                                "Created By: " + $rootScope.decodedToken.Name + "<br />" +
                                //"Task name: " + task_name + "<br /><br />" +
                                "Task name: " + noteData.Description + "<br /><br />" +

                                "Note Content: " + posted_note.note;




                        }
                        else {

                            console.log("email 6: " + noteData.Address_1);
                            console.log("email 6: " + noteData.Property_name);
                            console.log("email 6: " + noteData.Description);

                            posted_note.DescriptionTruncated = truncateSliceService.truncateSliceService(noteData.Description, 25);

                            var from_email = "Clouderty | Note Created " + posted_note.note_id + " | " +  noteData.Property_name  + " <notifications@clouderty.co.uk>";
                            var subject = "Created by: " + $rootScope.decodedToken.Name + " | " +  noteData.Property_name + " | " + posted_note.DescriptionTruncated + " | " + "Task ID: " + posted_note.id;
                            var message = "Note ID: " + posted_note.note_id + "<br />" +
                                "As Part Of Task: " + posted_note.Task_ID + "<br />" +
                                "Created By: " + $rootScope.decodedToken.Name + "<br />" +
                                //"Task name: " + task_name + "<br /><br />" +
                                "Task name: " + noteData.Description + "<br />" +
                                "Property: " + noteData.Property_name + "<br /><br />";



                            if(posted_note.Note_Content){
                                message = message + "Note Content: " + posted_note.Note_Content;

                            }
                        }

                        var to_email = $rootScope.decodedToken.Notifications_Email;

                        console.log("posted_note.fileURL: " + posted_note.fileURL);



                        if(noteData.picFile){
                            message = message + "<br /><br />" +
                                "Image attached: " + posted_note.fileURL +  "<br /><br />";
                        }


                        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);


















                        return getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A5");
                        console.log(posted_note);
                        console.log('A7');
                        myValue = data;
                        console.log(posted_note);

                        qpostNote.resolve(promise);

                        console.log("posted_note >>>>>>>>>>>>>>> " + posted_note);
                        console.log(posted_note);


                        console.log("posted_note >>>>>>>>>>>>>>> " + posted_note);
                        console.log(posted_note);

                        qpostNote.promise;
                        return getGlobalPromise(data);
                    })
                    .then(function () {
                        //$state.reload('lettings-main-nav.lettings-side.process-account-view.notes');

                        console.log(posted_note);
                        return posted_note;

                    }, function (err) {
                        myValue = err;
                    })





            })


    }


}])
