/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("tenancyRelatedCtrl", ["$scope", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "apiService", "$stateParams", "exportCSVService",  function ($scope, $http, $rootScope, $q, $state, postUserActivityService, apiService, $stateParams, exportCSVService) {

    console.log("tenancyRelatedCtrl");

    $scope.date = new Date();
    $scope.getHeaderTenancyRelated =  ["Tenant_ID", "Tenancy_ID", "First_Name", "Last_Name",  "Address_1", "Unit_number", "Monthly_rental_price", "Tenancy_start_date", "Tenancy_end_date", "Date_leaving", "Tenants", "Current"];
    $scope.fileNameObjTenancyRelated = "Tenancies";
    $scope.tenancies = {};
    $scope.totalCountTenancyRelated = 0;

    $scope.$watch(['tenantid', 'propertyid'], function (newValues, oldValues){

        if($scope.tenantid > 0 || $scope.propertyid > 0){


            console.log("inside watch and scope.tenantid is: " + $scope.tenantid);
            console.log("inside watch and scope.propertyid is: " + $scope.propertyid);
            console.log($scope.tenanciesrelatedobj);

            console.log("Clouderty administrator:" + $rootScope.decodedToken.Clouderty_System_Administrator);
            console.log("Rootscope user level: " + $rootScope.decodedToken.User_Level);
            console.log("Rootscope account holder ID: " + $rootScope.decodedToken.Account_Holder_ID);

            console.log('$scope.tenanciesrelatedobj');
            console.log($scope.tenanciesrelatedobj);

            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;

            $scope.tenancies = {};
            $scope.tenanciesData = {};
            $scope.tenancy = [];
            $scope.tenanciesExport = [];
            $scope.tenanciesExportHeaders = [];
            $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
            $scope.tenanciesExport2 = [];
            $scope.myJsonString = [];
            $scope.getHeader = [];
            $scope.tenancyID = "current";
            $scope.monthsExpiring = 0;
            $scope.landlord = [];
            $scope.landlord.Estate = "";
            $scope.landlord.Estate_ID = 0;
            $scope.landlord.Property = "";
            $scope.landlord.Property_ID = 0;
            $scope.filename = "test" + ".csv";
            $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;



            /////////////////// Cancel previous AJAX code (A)
            var canceller, isSending = false;
            ///////////////////  Cancel previous AJAX code (A) end

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )


                return q55.promise;
            }

            $scope.tenanciesFunction = function(id, status) {

                console.log("function tenanciesFunction");

                $scope.tenanciesData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.tenanciesData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                console.log("status" + status);
                if(status == 121){

                    $scope.tenanciesData.itemsPerPage = 10000000;

                }else{

                    $scope.tenanciesData.itemsPerPage = $scope.itemsPerPage;
                }
                $scope.tenanciesData.pageno = $scope.pageno;
                $scope.tenanciesData.Tenancy_ID = id;
                //$scope.tenanciesData.Estate_ID = $scope.landlord.Estate_ID;
                //$scope.tenanciesData.Property_ID = $scope.landlord.Property_ID;
                $scope.tenanciesData.monthsExpiring = $scope.monthsExpiring;
                $scope.tenanciesData.Status = status;

                /*
                $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
                $scope.tenancies.pageno = $scope.pageno;
                $scope.tenancies.Tenancy_ID = id;
                $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
                $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
                $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
                $scope.tenancies.Status = status;
                */
                //$scope.pageno = 1;

                if($scope.tenanciesData.Tenancy_ID){
                    //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
                }else{

                    $scope.tenanciesData.Tenancy_ID = "*";
                }


                if($scope.search){

                    $scope.tenanciesData.search = $scope.search;
                }else{

                    $scope.tenanciesData.search = "*";
                }

                //$scope.obj.search = "*";
                console.log($scope.tenancies);
                var q2 = $q.defer();
                //console.log($scope.paymentTransactionsData);

                apiService.tenancies(id, $scope.tenanciesData).then(
                    function (response) {

                        if(status == 121){

                            $scope.tenancies = response.data.data;
                            $scope.totalCount = response.data.totalCount;
                            $scope.itemsPerPage = 1000000;

                            $('.data').hide();
                            $('.other-tenancies-data').hide();
                            $('.other-tenancies').hide();
                        }else{
                            $scope.currentPage = $scope.pageno;
                            $scope.totalCount = response.data.totalCount;
                            $scope.paginationData = response.data.data;
                            $scope.tenancies = response.data.data;
                            $scope.rentTotal = response.data.rentTotal;
                            $scope.totalTaskCount = response.data.totalCount;

                        }

                        $scope.totalCountTenancyRelated = response.data.totalCount;

                        console.log($scope.tenancies);
                        console.log("tenancies API");
                        q2.resolve(response);
                    },
                    function () {
                        console.log("API Service Error: tenancies")
                    }
                );

                if($scope.search == "*"){
                    $scope.search = "";
                }
                else{

                };

                return q2.promise;
            }

            $scope.tenanciesExport = function () {
                var deferred = $q.defer();
                $scope.itemsPerPage = 10000;
                deferred.resolve($scope.tenancies);
                console.log($scope.tenancies);
                return deferred.promise;

            }

            $scope.actionSelect = function(search_documents_action, dataObj){

                console.log(search_documents_action);
                console.log(dataObj);

                //console.log("datObj.Licence_Key: " + datObj.Licence_Key);

                console.log(dataObj.Licence_Key);

                if(search_documents_action == 1) {

                    console.log("View venancy agreement - room let");

                    var url = $state.href('tenancy-agreement-room.payment', {tenancyID: dataObj.Tenancy_ID, licenseKey: dataObj.Licence_Key, myParam: dataObj});
                    window.open(url,'_blank');


                }
                else if(search_documents_action == "2") {
                    $state.go('tenancy-agreement-single-let', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
                    console.log("View venancy agreement - single let");
                    var url = $state.href('tenancy-agreement-single-let', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
                    window.open(url,'_blank');


                }
                else if(search_documents_action == "4") {
                    $state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  $scope.obj.data.Tenant_ID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                    console.log("Edit tenant");

                    var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  $scope.obj.data.Tenant_ID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                    window.open(url,'_blank');

                }
                else if(search_documents_action == "3") {
                    $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                    console.log("Edit tenancy");

                    var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                    window.open(url,'_blank');

                }
                else if(search_documents_action == "5") {
                    $scope.tenancyAgreementSend("1");
                    //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                    console.log("Send tenancy");
                }
                else if(search_documents_action == "6") {
                    $scope.tenancyInitialDocumentRequestSend();
                    console.log("tenancyInitialDocumentRequestSend");
                }
                else {

                }
            };

            $scope.sort = function(keyname){

                console.log("inside sort function");
                $scope.sortKey = keyname;   //set the sortKey to the param passed
                $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            }

            $scope.getData = function(pageno) {

                $scope.tenancies = [];
                $scope.currentPage = pageno;
                $scope.pageno = pageno;

                /////////////////// Cancel previous AJAX code (B)
                console.log("callajax");
                if(isSending) {
                    canceller.resolve()
                }
                isSending = true;
                canceller = $q.defer();


                console.log("about to enter tenanciesFunction");
                $scope.tenanciesFunction($scope.id, $scope.status);

                if($scope.search == "*"){
                    $scope.search = "";
                }
                else{
                }

            }




















            ////*
            $scope.propertiesViewAll = function () {

                apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
                    function(response) {

                        $scope.properties = response.data.data;
                        console.log("API propertiesViewAll: " );
                    },
                    function() {
                        console.log("We are in propertiesViewAll");
                    }
                )

            }

            $scope.estatesViewAll = function () {

                apiService.getEstates($rootScope.decodedToken.Account_Holder_ID).then(
                    function(response) {

                        $scope.estates = response.data.data;
                        console.log("API estatesViewAll: " );
                    },
                    function() {
                        console.log("We are in estatesViewAll");
                    }
                )

            }



                      $scope.getDataSearch = function(pageno) {

                          $scope.tenancies = [];
                          $scope.id = '*';

                          if($scope.landlord.Property && $scope.landlord.Property != 0){

                              $scope.landlord.Property_ID = $scope.landlord.Property;
                              $scope.status = 881;

                          }else if($scope.search_action == "3"){


                              $scope.status = 111;

                          }else{

                              $scope.status = 7;
                              //$scope.status = 0;

                          }//search tenancies by property id and search text


                          $scope.getData(pageno);

                      }

                      $scope.getDataProperties = function(pageno) {

                          $scope.tenancies = [];
                          $scope.landlord.Property_ID = $scope.landlord.Property;


                          if($scope.landlord.Property && $scope.search_action == 3){
                              $scope.status = 112;
                          }else{

                              $scope.status = 88;
                          }




                          $scope.getData(pageno);
                          //$scope.tenanciesFunction(0, 88);
                          //$scope.getData(1);

                      }

                      $scope.getDataExpiredTenancies = function(pageno) {

                          $scope.tenancies = [];

                          if($scope.landlord.Property && $scope.search_action == 3){
                              $scope.status = 112;
                          }else{

                              $scope.status = 11;
                          }

                          $scope.getData(pageno);

                      }

                      $scope.getAllTenanciesCurrentEstate = function(pageno) {
                          $scope.tenancies = [];

                          $scope.landlord.Estate_ID = $scope.landlord.Estate;
                          $scope.currentPage = pageno;
                          $scope.getData($scope.pageno);


                      }


                      $scope.actionUpdate = function (progress) {
                          var date = new Date();
                          date.setHours(date.getHours()+1);
                          console.log("Progress 1: ");

                          if(progress == "3") {
                              //$scope.progress.date_completed = date;
                              console.log("Expired Tenancies");
                              //$scope.tenancyID = "expired";
                              //$scope.monthsExpiring = 0;
                              //$scope.currentPage = 1;
                              //$scope.pageno = 1;
                              //$scope.tenancies = [];
                              //$scope.status = 11;
                              //$scope.id = '*';
                              $scope.getDataExpiredTenancies($scope.pageno);
                              //$scope.tenanciesFunction('*', '11');
                          } else if (progress == "4") {
                              console.log("Expired Tenancies within 1 Month");
                              $scope.tenancyID = "expired";
                              $scope.monthsExpiring = 1;
                              console.log("about to enter tenanciesFunction");
                              $scope.tenanciesFunction('*', 0);
                              //$scope.progress.date_completed = null;
                          } else if (progress == "5") {
                              console.log("Expired Tenancies within 2 Month");
                              $scope.tenancyID = "expired";
                              $scope.monthsExpiring = 12;
                              $scope.getData($scope.pageno);
                          } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {
                              console.log("Expired Tenancies within 3 Month");
                              //$scope.getDataExpired(1)
                              $scope.getDataExpiredThreeMonth(1);
                              //$scope.progress.date_completed = null;
                          } else if (progress == "1") {
                              console.log("VIEW CURRENT");
                              //$scope.getDataExpired(1)
                              $scope.tenancyID = "current";
                              $scope.getData($scope.pageno);
                              //$scope.progress.date_completed = null;
                          }
                          else if (progress == "2") {
                              console.log("VIEW ALL TENACIES");
                              $scope.tenancyID = "all";

                              $scope.monthsExpiring = 0;
                              $scope.currentPage = 1;
                              $scope.pageno = 1;
                              $scope.tenancies = [];
                              $scope.status = '12';
                              $scope.id = '*';
                              $scope.getData($scope.pageno);
                              //$scope.getData($scope.currentPage);
                          } else{

                          }

                      }

                      $scope.actionUpdateTenancy = function (action, tenantID, tenancyID) {
                          var date = new Date();
                          date.setHours(date.getHours()+1);
                          console.log("Progress 1: ");
                          //$scope.progress.progress = $scope.obj.data.Status;

                          if(action == "1") {
                              //$scope.progress.date_completed = date;
                          console.log("Edit Tenant");
                              $scope.editTenant(tenantID);
                          } else if (action == "2") {
                              console.log("Edit Tenant");
                              //$scope.getDataExpired(1)
                              $scope.editTenancy(tenancyID);
                              //$scope.progress.date_completed = null;
                          } else if (action == "EXPIRED TENANCIES WITHIN 2 MONTH") {
                              console.log("Expired Tenancies within 2 Month");
                              //$scope.getDataExpired(1)
                              $scope.getDataExpiredTwoMonth(1)
                              //$scope.progress.date_completed = null;
                          } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
                              console.log("Expired Tenancies within 3 Month");
                              //$scope.getDataExpired(1)
                              $scope.getDataExpiredThreeMonth(1)
                              //$scope.progress.date_completed = null;
                          } else if (action == "VIEW CURRENT") {
                              console.log("Progress 3: ");
                              //$scope.getDataExpired(1)
                              $scope.getData(1)
                              //$scope.progress.date_completed = null;
                          }
                          else if (action == "VIEW ALL") {
                              console.log("Progress 3: ");
                              $scope.getData($scope.pageno)

                          } else{

                          }

                      }
                      /*
                          $scope.editTenant = function(tenantID) {
                              //$scope.tenants = null;
                              console.log("Tenant ID = " + tenantID);
                              //console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
                              $http.get('api/tenant/' + tenantID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response) {
                                  $scope.tenants = response;

                                  console.log(response);
                                  $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenanct: ' + $scope.tenants.First_Name + ' - ' + tenantID);

                                  var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                                  window.open(url,'_blank');
                                  //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});



                                  return $scope.tenants;
                              })
                          }

                          $scope.editTenancy = function(tenancyID) {

                              console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
                              $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  tenancyID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});

                              $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenancy: ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + $scope.tenancy.Tenancy_ID);

                              console.log("apiService.searchTenancy");

                          }

                          $scope.open = function (size) {
                              $uibModal.open({
                                  animation: $scope.animationsEnabled,
                                  templateUrl: 'views/modals/email-confirm.html',
                                  controller: 'emailConfirmCtrl',
                                  size: size,
                                  resolve: {
                                      obj: function () {
                                          return $scope.obj;
                                      }
                                  }
                              });
                          };

                          $scope.currentTenancyChange = function(tenancyID, current) {

                              $scope.currentStatus = 0;
                              console.log("Current  current: " + current);
                              //console.log("Current  $scope.tenancy.Current: " + $scope.tenancy.Current);

                              if (current == '-1' || current == '1') {


                                  if (confirm('Are you sure you want to change the status of this tenancy to not current?')) {


                                      console.log("Tenancy ID: " + tenancyID);


                                      $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + 0).success(function(response){

                                          $scope.tenancyCurrentStatus = {};
                                          $scope.tenancyCurrentStatus = response.data2[0];

                                          console.log("$scope.tenancyCurrentStatus: " + $scope.tenancyCurrentStatus);
                                          console.log($scope.tenancyCurrentStatus);

                                          $scope.Tenant_Name = "";
                                          if($scope.tenancyCurrentStatus.Last_name == null) {
                                              $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                                          }else{
                                              $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                                          }
                                          console.log($scope.Tenant_Name);

                                          if($scope.tenancyCurrentStatus.Current == 1){

                                              $scope.currentStatus = "Current";

                                          }else if($scope.tenancyCurrentStatus.Current == -1){

                                              $scope.currentStatus = "Not Current";

                                          }else if($scope.tenancyCurrentStatus.Current == 0){

                                              $scope.currentStatus = "Not Current";

                                          }

                                          //var from_email = "Clouderty | Tenancy Status | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                                          //var subject = "Changed by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + tenancyID;

                                          var from_email = "Clouderty | Tenancy Status | " + $scope.tenancyCurrentStatus.First_Name + " | Tenancy ID " + tenancyID  + " | " + " <notifications@clouderty.co.uk>";
                                          var subject = "Changed by: " + $rootScope.decodedToken.Name + " | " + $scope.currentStatus;
                                          var message = "Tenancy ID: " + tenancyID + "\n" +
                                              "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                                              "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                                              "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                                              "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                                              "Date changed: " + Date()+ "\n\n" +
                                              "Current tenancy status: " + $scope.currentStatus  + "\n";

                                          var to_email = $rootScope.decodedToken.Notifications_Email;
                                          $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                                          $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                                          $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);
                                      });
                                      console.log("Route reload ");
                                      $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

                                  }
                                  else{
                                      console.log("Transaction ID: " + tenancyID);



                                  }


                              } else{

                                  if (confirm('Are you sure you want to change the status of this tenancy to current?')) {

                                      console.log("Test 1");

                                      console.log("Tenancy ID: " + tenancyID);


                                      $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '1').success(function(response){

                                          $scope.tenancyCurrentStatus = {};
                                          $scope.tenancyCurrentStatus = response.data2[0];


                                          $scope.Tenant_Name = "";
                                          if($scope.tenancyCurrentStatus.Last_name == null) {
                                              $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                                          }else{
                                              $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                                          }
                                          console.log($scope.Tenant_Name);


                                          if($scope.tenancyCurrentStatus.Current == 1){

                                              $scope.currentStatus = "Current";

                                          }else if($scope.tenancyCurrentStatus.Current == -1){

                                              $scope.currentStatus = "Not Current";

                                          }else if($scope.tenancyCurrentStatus.Current == 0){

                                              $scope.currentStatus = "Not Current";

                                          }


                                          var from_email = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                                          var subject = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " | Changed by: " + $rootScope.decodedToken.Name + "| Tenancy ID: " + tenancyID;
                                          var message = "Tenancy ID: " + tenancyID + "\n" +
                                              "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                                              "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                                              "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                                              "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                                              "Date changed: " + Date()+ "\n\n" +
                                              "Current tenancy status: " + $scope.currentStatus  + "\n";

                                          var to_email = $rootScope.decodedToken.Notifications_Email;
                                          $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                                   ope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                                          $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);


                                      });
                                      console.log("Route reload ");
                                      $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

                                  }
                                  else{

                                  }

                              }

                          }

                          /*
                          $scope.ExportTenancies = function() {

                              console.log("C1 ExportLandlordStatementTransactions");
                              var q = $q.defer();
                              $scope.myValue = 0;
                              $scope.promise = $scope.getGlobalPromise();

                              $scope.promise
                                  .then(function (data) {
                                      $scope.itemsPerPage = 10000;
                                      console.log("A2 ExportTenanciesDetails");

                                      return $scope.getGlobalPromise(data);
                                  })
                                  .then(function (data) {
                                      $scope.itemsPerPage = 10000;
                                      console.log("A2 ExportTenanciesDetails");
                                      $scope.ExportTenanciesDetails();
                                      return $scope.getGlobalPromise(data);
                                  })
                                  .then(function (data) {

                                      console.log("A2 getData");
                                      $scope.getData();
                                      return $scope.getGlobalPromise(data);
                                  })
                                  .then(function (data) {
                                      console.log('7');
                                      $scope.myValue = data;
                                  }, function (err) {
                                      $scope.myValue = err;
                                  })

                              q.resolve($scope.promise);
                          }

                          $scope.ExportTenanciesDetails = function() {
                              //var q = $q.defer();
                              $scope.getHeader =  ["Property1", "Property2", "Unit", "First_Name", "Last_Name",  "Category", "Amount", "Date", "Payment_Transaction_ID", "Tenancy_ID", "Payment_Type", "Date_Received", "Category", "Description", "Proeprty_ID"];
                              $scope.filename = "Tenancy Details";
                              //$scope.itemsPerPage = 200000;
                              //q.resolve($scope.itemsPerPage);

                              $scope.exportCSVData = exportCSVService.exportCSVService($scope.tenancies);
                              //return q.promise;

                          }


                      $scope.estatesViewAll();
                      $scope.propertiesViewAll();
                      $scope.currentPage = 1;
                      $scope.pageno = 1;
                      console.log("and again >>>>>>>>>>>>>>>>>>>>> ");



                  */
            if($scope.propertyid){

                $scope.tenanciesData.Property_ID = $scope.propertyid;
                $scope.status = 121;

            }
            else if($scope.tenantid){

                $scope.tenanciesData.Tenant_ID = $scope.tenantid;
                $scope.status = 12;

            }


            $scope.id = '*';
            $scope.getData($scope.pageno);



        }
    })


}]);