/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("detectMobileViewService", ['apiService', '$q', '$rootScope', '$state', '$timeout',  function (apiService, $q, $rootScope, $state, $timeout) {
    console.log("detectMobileViewService service");
    this.detectMob = function() {

        console.log("detectMobileViewService detectMob service");

        if(window.innerWidth <= 800 && window.innerHeight <= 600){
            console.log("mobile view - width: " + window.innerWidth);
            return 1;
        }
        else{
            console.log("not in mobile view - width: " + window.innerWidth);
            return 0;
        }

    }

}])
