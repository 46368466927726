/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("fileNamesIDsCreateCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "yesNoFilter", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, yesNoFilter) {
    console.log("End 1");
    $scope.search
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();


    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    $scope.totalCount = 0;





    $scope.unitBlock = { result: false};
    $scope.unitBlock.result = false;


    $scope.getFileNameIDs = function () {
        console.log("$scope.getFileNameIDs");
        apiService.getFileNameIDs().then(
            function(response) {
                $scope.fileTypes  = response.data.data;
                console.log($scope.fileTypes);
            },
            function() {
                alert("There was an error")
            }
        )
    }
    $scope.getFileNameID = function () {
        $scope.payment_transaction.File_Names_and_IDs_ID = $scope.payment_transaction.fileTypes.File_Names_and_IDs_ID;
        $scope.payment_transaction.Name_Label = $scope.payment_transaction.fileTypes.Name_Label;
        $scope.payment_transaction.Name_Defined = $scope.payment_transaction.fileTypes.Name_Defined;
        console.log("Name_Label: " + $scope.payment_transaction.Name_Label);
        console.log("Name_Defined: " + $scope.payment_transaction.Name_Defined);
    }

    $scope.getFileNameIDs();

    $(".unitBlock-result").hide();
    //$scope.unitBlock-result = false;

    $(".mobile-notes-button").click(function() {
        $('.content').animate({
            scrollTop: $(".notes-search-section").offset().top
        }, 500);
        $(".mobile-notes-button").hide();
    });

    $('.progress-select').select2({
        minimumResultsForSearch: -1
    });


    $scope.getBusinesses = function () {

        $scope.business = null;
        $http.get('api/businesses-search/' + $scope.search_business).success(function(response) {
            $scope.businesses = response;
        })
    }


    $scope.selectBusiness = function (business_id, business_name, Bank_ref) {
        $scope.search_business = "";
        $scope.hideBusinessSearch = true;
        $scope.selected_business_name = business_name;

        $scope.payment_transaction.Business_ID = business_id;
        console.log("Bank ref: " + Bank_ref);
        $scope.payment_transaction.Business_Bank_ref = Bank_ref;
        console.log("Bank ref: 2" + $scope.payment_transaction.Business_Bank_ref);
    }

    $scope.getPaymentTransactionCategoriesAll = function () {
        console.log("$scope.getPaymentTransactionCategories");
        apiService.getPaymentTransactionCategories().then(
            function(response) {
                $scope.paymentTransactionCategories  = response.data.data;
                console.log($scope.paymentTransactionCategories);
            },
            function() {
                alert("There was an error")
            }
        )
    }

    $scope.getCategories = function () {
        $scope.payment_transaction.Payment_Transaction_Category_ID = $scope.payment_transaction.paymentTransactionCategories.Payment_Transaction_Category_ID;
        $scope.payment_transaction.Payment_Transaction_Category_Description = $scope.payment_transaction.paymentTransactionCategories.Description;
        console.log("Payment_Transaction_Category_ID: " + $scope.payment_transaction.Payment_Transaction_Category_ID);
        console.log("Payment_Transaction_Category_Description: " + $scope.payment_transaction.Payment_Transaction_Category_Description);
    }

    $scope.getPaymentTypes = function () {
        $scope.payment_transaction.Payment_Transaction_Type_ID = $scope.payment_transaction.paymentTransactionTypes.Payment_Transaction_Type_ID;
        $scope.payment_transaction.Payment_Transaction_Type_Description = $scope.payment_transaction.paymentTransactionTypes.Description;
        console.log("Payment_Transaction_Type_ID: " + $scope.payment_transaction.Payment_Transaction_Type_ID);
        console.log("Payment_Transaction_Type_Description: " + $scope.payment_transaction.Payment_Transaction_Type_Description);
    }

    $scope.getPaymentTransactionTypes = function () {
        console.log("$scope.getPaymentTransactionTypes");
        apiService.getPaymentTransactionTypes().then(
            function(response) {
                $scope.paymentTransactionTypes  = response.data.data;
                console.log($scope.payment_transaction.Payment_Transaction_Type_ID);
            },
            function() {
                alert("There was an error")
            }
        )
    }

    $scope.getPaymentTransactionTypes();
    $scope.getPaymentTransactionCategoriesAll();

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;
        console.log("$scope.getPropertiesSearch");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.properties  = response.data;
            },
            function() {
                alert("There was an error")
            }
        )
    }

    $scope.getProperties = function () {
        console.log("We are in in getProperties");
        apiService.getProperty($scope.payment_transaction.Property_ID , $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data.units;
            },
            function() {
                alert("There was an error in getProperties")
            }
        )
    }

    $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {
        $scope.search_property = "";
        $scope.hidePropertySearch = true;
        $scope.selected_property_name = property_name;
        $scope.payment_transaction.selected_property_name = property_name;
        $scope.payment_transaction.Property_name = property_name;
        $scope.payment_transaction.Address_1 = address_1;
        $scope.payment_transaction.Bank_ref = bank_ref;
        console.log("Bank_Ref: " + $scope.payment_transaction.Bank_ref);
        $scope.payment_transaction.Property_ID = property_id;
        $scope.getProperties();
        $scope.getLandlordRegularCosts();
    }

    $scope.getLandlordRegularCosts = function () {
        $scope.properties = null;
        console.log("$scope.getLandlordRegularCosts");
        apiService.landlordRegularTransactionsView($scope.itemsPerPage, $scope.pageno,  $scope.payment_transaction.Property_ID, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.LandlordRegularCosts  = response.data;
                $scope.payment_transaction.LandlordRegularCosts = response.data;
                console.log($scope.LandlordRegularCosts.data);
            },
            function() {
                alert("There was an error")
            }
        )
    }



    $scope.getUnit = function () {

        $scope.payment_transaction.Unit_number = $scope.payment_transaction.units.Unit_number;
        console.log("Unit_ID: " + $scope.payment_transaction.units.Unit_ID);

        apiService.getTenanciesUnit($scope.payment_transaction.units.Unit_ID , $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenancies = response.data;
                $scope.payment_transaction.tenancies = response.data;
                console.log($scope.tenancies.data);
            },
            function() {
                alert("There was an error in getTenanciesUnit")
            }
        )
    }

    $scope.getTenancy = function () {
        $scope.payment_transaction.Tenancy_ID = $scope.payment_transaction.tenancies.Tenancy_ID;
        $scope.payment_transaction.Tenancy_Name = $scope.payment_transaction.tenancies.First_Name + " " + $scope.payment_transaction.tenancies.Last_Name;;

        console.log("Tenancy ID: " + $scope.payment_transaction.Tenancy_ID);
        console.log("Tenancy_Name: " + $scope.payment_transaction.Tenancy_Name);
    }

    $scope.addTransaction = function(Transaction, Date) {

        $scope.a =  [];

        $scope.a = Transaction;

        if(Date == null){

            confirm('Enter the date that this transaction was received')
            return "";
        }


        $http.get("api/tenancy/" + $stateParams.id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response)  {

            $scope.b = [];
            $scope.b = response;
            $scope.a.Cheque_processed = 0;
            if ($scope.a.Payment_Transaction_Type == "CHEQUE") {
                $scope.a.Cheque = 1;
            }else{
                $scope.a.Cheque = 0;
            }
            $scope.a.Received = 0;
            $scope.a.Business_ID = 1;
            $scope.a.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID
            $scope.a.User_ID = $rootScope.decodedToken.id;
            $scope.a.Date_Received = $scope.DateToday;
            if(!$scope.payment_transaction.Notes){
                $scope.payment_transaction.Notes = "None"
            }else{

            }

            console.log($scope.payment_transaction.Date);
            var payment_transaction_date = $filter('date')($scope.payment_transaction.Date, 'dd') + "/" + $filter('date')($scope.payment_transaction.Date, 'MM') + "/" + $filter('date')($scope.payment_transaction.Date, 'yyyy');
            $scope.a.Date = $scope.payment_transaction.Date;

            if (confirm('Are you sure you want to add a' + $scope.payment_transaction.Payment_Transaction_Category_Description + 'transaction?')) {

                apiService.addPaymentTransaction($scope.payment_transaction).then(
                    function (response) {
                        $scope.a = response;
                        console.log($scope.a);
                    },
                    function () {
                        alert("There was an error in getTenanciesUnit")
                    }
                )

            }else{
                return;
            }





            if($scope.payment_transaction.Transaction_Type == "Business") {


                var from_email = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Business_Bank_ref + " <notifications@clouderty.co.uk>";
                var subject = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description + " | "  + $rootScope.decodedToken.Name + " | " + $scope.payment_transaction.Property_name;
                var message = "Payment  ID: " + $scope.payment_transaction.Payment_ID + "\n\n" +
                    "Payment taken by: " + $rootScope.decodedToken.Name + "\n\n" +
                    "Business: " + $scope.payment_transaction.Business_Bank_ref + "\n" +
                    "Date received: " + $scope.payment_transaction.Date + "\n" +
                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "\n" +
                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "\n\n" +
                    "Amount:" + " " + $filter('currency')($scope.payment_transaction.Amount, '£') + "\n" +
                    "Notes: " + $scope.payment_transaction.Notes + "\n\n";

                var to_email = "office@platinumcrown.co.uk";

                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                $('.data').hide();

            }
            else if ($scope.payment_transaction.Transaction_Type == "Property") {



                var from_email = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
                var subject = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description  + " | " + $rootScope.decodedToken.Name + " | " + $scope.payment_transaction.Property_name;
                var message = "Payment  ID: " + $scope.payment_transaction.Payment_ID + "\n\n" +
                    "Payment taken by: " + $rootScope.decodedToken.Name + "\n\n" +
                    "Date received: " + $scope.payment_transaction.Date + "\n\n" +
                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "\n\n" +
                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "\n\n" +
                    "Amount:" + " " + $filter('currency')($scope.payment_transaction.Amount, '£') + "\n" +
                    "Notes: " + $scope.payment_transaction.Notes + "\n\n" +
                    "Address: " + $scope.payment_transaction.Address_1 + "\n";

                var to_email = "office@platinumcrown.co.uk";

                $scope.postUserActivityServiceMessage = subject;
                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                $('.data').hide();
            }

        })
    }


    $scope.paymentCategoryDescription = function (categoryID) {
        apiService.getPaymentTransactionCategory(categoryID).then(
            function(response) {

                $scope.payment_transaction.Payment_Category = response.data.Description;
                console.log("API getPaymentTransactionCategory: " + response.data.Description);
            },
            function() {
                console.log("Where in");
                alert("There was an error")
            }
        )
    }

    $scope.paymentTransactionTypeDescription = function (paymentTypeID) {
        apiService.getPaymentTransactionType(paymentTypeID).then(
            function(response) {
                $scope.payment_transaction.Payment_Transaction_Type = response.data.Description;
                console.log("API paymentTransactionTypeDescription: " + response.data.Description);
                $scope.createPaymentIDPaymentTypeCategory();
            },
            function() {
                console.log("Where in");
                alert("There was an error")
            }
        )
    }


    $scope.createPaymentIDBusiness = function() {
        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref;

        $scope.payment_transaction.Description = payment_transaction.Name_Label  + " - " + $scope.payment_transaction.Business_Bank_ref;
    }
    $scope.createPaymentIDPaymentType = function() {

        $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label;

        if($scope.payment_transaction.Transaction_Type == "Business"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_Description;
                $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " +  $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                console.log("createPaymentIDPaymentType Business");

            }
        }
        else if ($scope.payment_transaction.Transaction_Type == "Property"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.selected_property_name + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Payment_Transaction_Type_Description;
                //$scope.payment_transaction.File_Name = $scope.payment_transaction.selected_property_name  + " Unit " + payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " +  $scope.payment_transaction.selected_property_name + " " + $scope.payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                console.log("createPaymentIDPaymentType Property");
            }
        }
    }

    $scope.createPaymentIDPaymentTypeUnit = function() {
        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_Description;
        $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.selected_property_name  + " Unit " + $scope.payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

    }

    $scope.createPaymentIDPaymentTypeCategory = function() {




        if($scope.payment_transaction.Transaction_Type == "Business"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_File_Name  + $scope.payment_transaction.Payment_Transaction_Category_Description;
                $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - "  + $scope.payment_transaction.Payment_Transaction_Category_Description;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;

                console.log("createPaymentIDPaymentType Business");

            }
        }
        else if ($scope.payment_transaction.Transaction_Type == "Property"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Payment_Transaction_Type_Description  + $scope.payment_transaction.Payment_Transaction_Category_Description;
                $scope.payment_transaction.File_Name =  $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.selected_property_name + " " + $scope.payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - "  + $scope.payment_transaction.Payment_Transaction_Category_Description;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;

                console.log("createPaymentIDPaymentType Property");
            }
        }



        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

    }
    $scope.createPaymentIDPaymentTypeCategoryOther = function() {

        if($scope.payment_transaction.Transaction_Type == "Business"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_File_Name  + $scope.payment_transaction.Other;
                //$scope.payment_transaction.File_Name = $scope.payment_transaction.selected_property_name  + " Unit " + payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - "  + $scope.payment_transaction.Other;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other;

                console.log("createPaymentIDPaymentType Business");

            }
        }
        else if ($scope.payment_transaction.Transaction_Type == "Property"){

            if (!$scope.payment_transaction.Transaction_Type) {
                alert("Select a transaction type");
            }
            else{
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Payment_Transaction_Type_Description  + $scope.payment_transaction.Other;
                $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.selected_property_name + " " + $scope.payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - "  + $scope.payment_transaction.Other;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.selected_property_name + " " + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other;
                console.log("createPaymentIDPaymentType Property");
            }
        }


        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

    }

    $scope.createPaymentIDPaymentTypeCategoryDate = function(ptDate) {

        if($scope.payment_transaction.Transaction_Type == "Property"){
            if($scope.selected_property_name == null){
                alert("Select a property or change this to a business transaction");
                $scope.payment_transaction.Date = null;
                return;
            }else{

            }

        }else{

        }

        console.log("createPaymentIDPaymentTypeCategoryDate inside");
        //$scope.date = $filter('date')(date[ $scope.payment_transaction.Date, "dd/MM/yyyy"]);
        console.log($scope.payment_transaction.Date);
        var dateFilitered = $filter('date')($scope.payment_transaction.Date, 'yyyy');
        var dateFiliteredSpace = dateFilitered + " " + $filter('date')($scope.payment_transaction.Date,'MMMM');
        dateFilitered = dateFilitered + $filter('date')(ptDate, 'MMMM');
        console.log(dateFilitered);
        var payment_transaction_date = $filter('date')($scope.payment_transaction.Date, 'dd') + "/" + $filter('date')($scope.payment_transaction.Date, 'MM') + "/" + $filter('date')($scope.payment_transaction.Date, 'yyyy');
        console.log(payment_transaction_date);

        if($scope.payment_transaction.Other) {



            if($scope.payment_transaction.Transaction_Type == "Business"){

                if (!$scope.payment_transaction.Transaction_Type) {
                    alert("Select a transaction type");
                }
                else{
                    $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_File_Name  + $scope.payment_transaction.Other  + dateFilitered;
                    //$scope.payment_transaction.File_Name = $scope.payment_transaction.selected_property_name  + " Unit " + payment_transaction.Unit_number + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                    $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + dateFiliteredSpace;
                    $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other  + " - " + dateFiliteredSpace;

                    console.log("createPaymentIDPaymentType Business");

                }
            }
            else if ($scope.payment_transaction.Transaction_Type == "Property"){

                if (!$scope.payment_transaction.Transaction_Type) {
                    alert("Select a transaction type");
                }
                else{
                    $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Payment_Transaction_Type_Description  + $scope.payment_transaction.Other + dateFilitered;
                    $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " + $scope.payment_transaction.selected_property_name + " Unit " + $scope.payment_transaction.Unit_number + " - " + $scope.payment_transaction.Tenancy_Name + " - "  + dateFiliteredSpace;
                    $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.selected_property_name + " Unit " + $scope.payment_transaction.Unit_number + " - " +  $scope.payment_transaction.Other  + " - " + dateFiliteredSpace;
                    console.log("createPaymentIDPaymentType Property");
                }
            }

        }
        else{

            if($scope.payment_transaction.Transaction_Type == "Business"){

                if (!$scope.payment_transaction.Transaction_Type) {
                    alert("Select a transaction type");
                }
                else{
                    $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_File_Name  + $scope.payment_transaction.Payment_Transaction_Category_Description  + dateFilitered;
                    $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label + " - " + dateFiliteredSpace;
                    $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.Business_Bank_ref + " - " +  $scope.payment_transaction.Payment_Transaction_Category_Description  + " - " + dateFiliteredSpace;
                    console.log("createPaymentIDPaymentType Business ELSE");

                }
            }
            else if ($scope.payment_transaction.Transaction_Type == "Property"){

                if (!$scope.payment_transaction.Transaction_Type) {
                    alert("Select a transaction type ELSE");
                }
                else{
                    $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Unit_number + $scope.payment_transaction.Payment_Transaction_Type_Descrption + $scope.payment_transaction.Payment_Transaction_Category_Description  + dateFilitered;
                    $scope.payment_transaction.File_Name = $scope.payment_transaction.Name_Label  + " - " +  $scope.payment_transaction.selected_property_name + " Unit " + $scope.payment_transaction.Unit_number + " - "  + $scope.payment_transaction.Tenancy_Name + " - "  + dateFiliteredSpace;
                    $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - "  + $scope.payment_transaction.selected_property_name + " Unit " + $scope.payment_transaction.Unit_number + " - " +  $scope.payment_transaction.Payment_Transaction_Category_Description  + " - " + dateFiliteredSpace;
                    console.log("createPaymentIDPaymentType Property");
                }
            }

        }




        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

    }
    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

    };

    console.log("End 10");
}]);