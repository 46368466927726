/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("vehicleSearchCtrl", ["$scope", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "apiService", "$stateParams", "exportCSVService",  function ($scope, $http, $rootScope, $q, $state, postUserActivityService, apiService, $stateParams, exportCSVService) {

    console.log("in controller vehicleSearchCtrl");

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.currentPage = 1;
    $scope.pageno = 1;
    $scope.status = '0';

    $scope.vehicleID = '*';
    $scope.vehicles = {};
    $scope.vehicle = [];
    $scope.vehiclesExport = [];
    $scope.vehiclesExportHeaders = [];
    $scope.vehiclesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.vehiclesExport2 = [];
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.todaysDate = new Date();

    var canceller, isSending = false;

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.vehiclesFunction = function(id, status) {

        console.log("function vehiclesFunction");
        $scope.vehicles.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.vehicles.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.vehicles.itemsPerPage = $scope.itemsPerPage;
        $scope.vehicles.pageno = $scope.pageno;
        $scope.vehicles.Vehicle_ID = id;
        $scope.vehicles.Status = status;
        //$scope.pageno = 1;

        if($scope.vehicles.Vehicle_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.vehicles.Vehicle_ID = "*";
        }


        if($scope.search){

            $scope.vehicles.search = $scope.search;
        }else{

            $scope.vehicles.search = "*";
        }

        //$scope.obj.search = "*";

        var qVehiclesFunction = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.vehicles(id, $scope.vehicles).then(
            function (response) {
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.paginationData = response.data.data;
                $scope.vehicles = response.data.data;
                $scope.rentTotal = response.data.rentTotal;
                $scope.vehicleCount = response.data.totalCount;
                console.log($scope.vehicles);
                console.log("vehicles API");
                qVehiclesFunction.resolve(response);
            },
            function () {
                console.log("API Service Error: vehicles")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qVehiclesFunction.promise;
    }

    $scope.getData = function(pageno) {

        $scope.vehicles = [];

        $scope.currentPage = pageno;
        $scope.pageno = pageno;
        console.log("about to enter vehiclesFunction");
        $scope.vehiclesFunction($scope.id, $scope.status);

        if($scope.search == "*"){
            $scope.search = "";
        }

    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.getData($scope.pageno);

    //////////////////////////  Redundant  /////////////////////////////////////////////////////////////////////////////////


    $scope.tenanciesExport = function () {
        var deferred = $q.defer();
        $scope.itemsPerPage = 10000;
        deferred.resolve($scope.tenancies);
        console.log($scope.tenancies);
        return deferred.promise;

    }


    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }


    $scope.estatesViewAll = function () {

        apiService.getEstates($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.estates = response.data.data;
                console.log("API estatesViewAll: " );
            },
            function() {
                console.log("We are in estatesViewAll");
            }
        )

    }


    $scope.getDataProperties = function(pageno) {
        $scope.tenancies = [];
        $scope.landlord.Property_ID = $scope.landlord.Property;

        $scope.getData(1);

    }

    $scope.getAllTenanciesCurrentEstate = function(pageno) {
        $scope.tenancies = [];

        $scope.landlord.Estate_ID = $scope.landlord.Estate;
        $scope.currentPage = pageno;
        $scope.getData($scope.pageno);


    }



    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");

        if(progress == "3") {
            //$scope.progress.date_completed = date;
            console.log("Expired Tenancies");
            $scope.tenancyID = "expired";
            $scope.monthsExpiring = 0;
            $scope.currentPage = 1;
            $scope.pageno = 1;
            $scope.tenancies = [];
            $scope.status = '11';
            $scope.id = '*';
            $scope.getData($scope.pageno);
            //$scope.tenanciesFunction('*', '11');
        } else if (progress == "4") {
            console.log("Expired Tenancies within 1 Month");
            $scope.tenancyID = "expired";
            $scope.monthsExpiring = 1;
            console.log("about to enter tenanciesFunction");
            $scope.tenanciesFunction('*', '0');
            //$scope.progress.date_completed = null;
        } else if (progress == "5") {
            console.log("Expired Tenancies within 2 Month");
            $scope.tenancyID = "expired";
            $scope.monthsExpiring = 12;
            $scope.getData($scope.pageno);
        } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1);
            //$scope.progress.date_completed = null;
        } else if (progress == "1") {
            console.log("VIEW CURRENT");
            //$scope.getDataExpired(1)
            $scope.tenancyID = "current";
            $scope.getData($scope.pageno);
            //$scope.progress.date_completed = null;
        }
        else if (progress == "2") {
            console.log("VIEW ALL TENACIES");
            $scope.tenancyID = "all";

            $scope.monthsExpiring = 0;
            $scope.currentPage = 1;
            $scope.pageno = 1;
            $scope.tenancies = [];
            $scope.status = '12';
            $scope.id = '*';
            $scope.getData($scope.pageno);
            //$scope.getData($scope.currentPage);
        } else{

        }
 
    }


    $scope.actionUpdateTenancy = function (action, tenantID, tenancyID) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "1") {
            //$scope.progress.date_completed = date;
            console.log("Edit Tenant");
            $scope.editTenant(tenantID);
        } else if (action == "2") {
            console.log("Edit Tenant");
            //$scope.getDataExpired(1)
            $scope.editTenancy(tenancyID);
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 2 MONTH") {
            console.log("Expired Tenancies within 2 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredTwoMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (action == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData($scope.pageno)

        } else{

        }

    }


    $scope.editTenant = function(tenantID) {
        //$scope.tenants = null;
        console.log("Tenant ID = " + tenantID);
        //console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
        $http.get('api/tenant/' + tenantID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response) {
            $scope.tenants = response;

            console.log(response);
            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenanct: ' + $scope.tenants.First_Name + ' - ' + tenantID);

            var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            window.open(url,'_blank');
            //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});



            return $scope.tenants;
        })
    }

    $scope.editTenancy = function(tenancyID) {

        console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
        $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  tenancyID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});

        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenancy: ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + $scope.tenancy.Tenancy_ID);

        console.log("apiService.searchTenancy");

    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

    $scope.currentTenancyChange = function(tenancyID, current) {

        $scope.currentStatus = 0;
        console.log("Current  current: " + current);
        //console.log("Current  $scope.tenancy.Current: " + $scope.tenancy.Current);

        if (current == '-1' || current == '1') {


            if (confirm('Are you sure you want to change the status of this tenancy to not current?')) {


                console.log("Tenancy ID: " + tenancyID);


                $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '0').success(function(response){

                    $scope.tenancyCurrentStatus = {};
                    $scope.tenancyCurrentStatus = response.data2[0];

                    console.log("$scope.tenancyCurrentStatus: " + $scope.tenancyCurrentStatus);
                    console.log($scope.tenancyCurrentStatus);

                    $scope.Tenant_Name = "";
                    if($scope.tenancyCurrentStatus.Last_name == null) {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                    }else{
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                    }
                    console.log($scope.Tenant_Name);

                    if($scope.tenancyCurrentStatus.Current == 1){

                        $scope.currentStatus = "Current";

                    }else if($scope.tenancyCurrentStatus.Current == -1){

                        $scope.currentStatus = "Not Current";

                    }else if($scope.tenancyCurrentStatus.Current == 0){

                        $scope.currentStatus = "Not Current";

                    }

                    //var from_email = "Clouderty | Tenancy Status | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                    //var subject = "Changed by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + tenancyID;

                    var from_email = "Clouderty | Tenancy Status | " + $scope.tenancyCurrentStatus.First_Name + " | Tenancy ID " + tenancyID  + " | " + " <notifications@clouderty.co.uk>";
                    var subject = "Changed by: " + $rootScope.decodedToken.Name + " | " + $scope.currentStatus;
                    var message = "Tenancy ID: " + tenancyID + "\n" +
                        "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                        "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                        "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                        "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                        "Date changed: " + Date()+ "\n\n" +
                        "Current tenancy status: " + $scope.currentStatus  + "\n";

                    var to_email = $rootScope.decodedToken.Notifications_Email;
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);
                });
                console.log("Route reload ");
                $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

            }
            else{
                console.log("Transaction ID: " + tenancyID);



            }


        } else{

            if (confirm('Are you sure you want to change the status of this tenancy to current?')) {

                console.log("Test 1");

                console.log("Tenancy ID: " + tenancyID);


                $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '1').success(function(response){

                    $scope.tenancyCurrentStatus = {};
                    $scope.tenancyCurrentStatus = response.data2[0];


                    $scope.Tenant_Name = "";
                    if($scope.tenancyCurrentStatus.Last_name == null) {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                    }else{
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                    }
                    console.log($scope.Tenant_Name);


                    if($scope.tenancyCurrentStatus.Current == 1){

                        $scope.currentStatus = "Current";

                    }else if($scope.tenancyCurrentStatus.Current == -1){

                        $scope.currentStatus = "Not Current";

                    }else if($scope.tenancyCurrentStatus.Current == 0){

                        $scope.currentStatus = "Not Current";

                    }


                    var from_email = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " | Changed by: " + $rootScope.decodedToken.Name + "| Tenancy ID: " + tenancyID;
                    var message = "Tenancy ID: " + tenancyID + "\n" +
                        "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                        "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                        "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                        "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                        "Date changed: " + Date()+ "\n\n" +
                        "Current tenancy status: " + $scope.currentStatus  + "\n";

                    var to_email = $rootScope.decodedToken.Notifications_Email;
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);


                });
                console.log("Route reload ");
                $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

            }
            else{

            }

        }

    }


    $scope.ExportTenancies = function() {

        console.log("C1 ExportLandlordStatementTransactions");
        var q = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {
                $scope.itemsPerPage = 10000;
                console.log("A2 ExportTenanciesDetails");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                $scope.itemsPerPage = 10000;
                console.log("A2 ExportTenanciesDetails");
                $scope.ExportTenanciesDetails();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 getData");
                $scope.getData();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);
    }

    $scope.ExportTenanciesDetails = function() {
        //var q = $q.defer();
        $scope.getHeader =  ["Property1", "Property2", "Unit", "First_Name", "Last_Name",  "Category", "Amount", "Date", "Payment_Transaction_ID", "Tenancy_ID", "Payment_Type", "Date_Received", "Category", "Description", "Proeprty_ID"];
        $scope.filename = "Tenancy Details";
        //$scope.itemsPerPage = 200000;
        //q.resolve($scope.itemsPerPage);

        $scope.exportCSVData = exportCSVService.exportCSVService($scope.tenancies);
        //return q.promise;

    }


    $scope.estatesViewAll();
    $scope.propertiesViewAll();


}]);