/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("taskViewCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "apiService", "$q", "truncateSliceService",  function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, apiService, $q, truncateSliceService) {


    $scope.obj = {};

    $scope.obj.Task_ID = $stateParams.id;
    //$scope.search;
    $scope.progress = {};
    $scope.vendorTasks = {};
    $scope.vendorTasks2 = {};
    $scope.vendorData = {};
    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    // reset record counter
    $scope.totalCount = "";
    $scope.obj.vendor_id = "";
    $scope.posted_note = [];
    $scope.posted_note.File_ID = "";
    $scope.posted_note.fileName = "";
    $scope.posted_note.fileURL = "";


    /*
    $scope.maintenance = {};
    $scope.maintenanceData = {};
    $scope.maintenanceMaterialsLabourView = {};
    $scope.maintenanceMaterialsLabourViewCount = {};

    */

    if ($stateParams.id) {
        console.log("ID: " + $stateParams.id);
    }

    console.log("Task ID: " + $stateParams.id);
    console.log("email: " + $rootScope.decodedToken.Notifications_Email)

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $(".mobile-notes-button").click(function() {
        $('.content').animate({
            scrollTop: $(".notes-search-section").offset().top
        }, 500);
        $(".mobile-notes-button").hide();
    });

    $('.progress-select').select2({
        minimumResultsForSearch: -1
    });

    $(document).ready(function () {
        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })
    $('.data').hide();
    $('.maintenance-account-data').hide();
    $('.add-transactions-data').hide();

    console.log("taskViewCtrl: " + $rootScope.decodedToken.User_Level);

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.tasksFunction = function(id, status) {

        console.log("function tasksFunction");
        $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.obj.itemsPerPage = $scope.itemsPerPage;
        $scope.obj.pageno = $scope.pageno;
        $scope.obj.Task_ID = id;
        $scope.obj.Vendor_ID = "*";
        $scope.obj.Property_ID = "*";
        $scope.obj.Status = status;


        if($scope.obj.Task_ID){

            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.obj.Task_ID = "*";
        }


        if($scope.search){

            $scope.obj.search = $scope.search;
        }else{

            $scope.obj.search = "*";
        }

        //$scope.obj.search = "*";

        var q22 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.tasks(id, $scope.obj).then(
            function (response) {


                if(status == "11"){

                    $scope.taskStatuses = response.data.data;

                }else{
                    $scope.paginationData = response.data.data;
                    $scope.tasks = response.data.data;
                    $scope.$parent.obj = response.data.data;
                    $scope.currentPage = pageno;
                    $scope.totalTaskCount = response.data.totalCount;
                    $scope.totalCount = response.data.totalCount;


                }

                console.log("tasks API");
                q22.resolve(response);
            },
            function () {
                console.log("API Service Error: getAllUsersTasksNotCompletedSearch")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q22.promise;
    }

    $scope.listJob = function() {
        console.log("inside function listJob");

        $scope.obj.Status = 4;

        var qlistJob = $q.defer();

        apiService.jobs('*', $scope.obj).then(
            function (response) {

                console.log("inside controller apiService jobs");
                console.log("API jobs");
                console.log(response);
                qlistJob.resolve(response);

            }
        )

        return qlistJob.promise;

    }

    $scope.getTaskStatus = function () {
        console.log(" >>>>>>>>>>>>>>>>>  inside function getTaskStatus");
        //$scope.tasksFunction('*', '11');
    }

    $scope.getData = function () {

        console.log("Task ID: " + $stateParams.id);
        console.log("Task ID: " + $stateParams.id);
        var q5 = $q.defer();

        if ($rootScope.decodedToken.User_Type_ID == 1) {
            console.log("getTask 1: " + $rootScope.decodedToken.User_Level);

            $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.obj.Task_ID = $stateParams.id;
            $scope.obj.Status = 5;
            $scope.Status = 5;

            apiService.tasks("*", $scope.obj).then(
                function(response) {
                    $scope.$parent.obj = response.data.data;
                    $scope.obj = response.data.data;
                    //$scope.$parent.progress.id = response.data.Task_ID;
                    $scope.obj.Task_ID = $stateParams.id;
                    $scope.obj.Task_ID2 = $stateParams.id;
                    $rootScope.watchNotes = 1;
                    console.log(response);
                    q5.resolve(response);
                },
                function() {
                    console.log("We are in vendors");
                }
            )



            return q5.promise;
        }
        else if ($rootScope.decodedToken.User_Type_ID == 2) {
            console.log("getTask 2: " + $rootScope.decodedToken.User_Level);
            console.log("Task ID: " + $stateParams.id);
            $http.get("api/task/" + $stateParams.id + "/" + $rootScope.decodedToken.Vendor_ID).success(function (response) {
                $scope.$parent.obj = response.data;
                $scope.$parent.progress.id = response.data.Task_ID;

                q5.resolve(response);
            })

            return q5.promise;
        }
    }

    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.obj.select_note_type = "Email";

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

        //$scope.b = postNoteService.postNote('Test Task', 'Task', 'Content of test note', 'Email', $scope, $http, $rootScope);
        //postNote("ssssssssss", 'Task', obj.note, obj.select_note_type);
        //console.log("What now3");

    };

    $scope.checkUnitNumber = function(unit_number){

        //console.log(unit_number);
        $scope.obj.unit_number_text = ' - Unit ' + unit_number;

        if(unit_number == null){


        }else{
            return $scope.obj.unit_number_text;
        };

    };

    $scope.reloadView = function(){

        //$route.reload('lettings-main-nav.tasks-side.task-view.notes');
    };

    $scope.sendEmailInternalPostNote = function(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature){

        message = message + '<br /><br />' + email_signature;

        if(to_email==null){
            alert("Enter an email address");
        } else {

            note_category_type = "Email";
            $scope.obj.select_note_type = "Email";
            //from_email = "notifications@clouderty.co.uk"

            if (unit_number == null) {


            } else {

                property = property + " - Unit" + unit_number
            }
            ;

            //$scope.a = emailService.openEmailSender(to_email, subject, message, property);
            // $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            console.log("sendEmailInternalPostNote Controller Function - 1: " + to_email);
            $scope.a = $rootScope.sendEmail('notifications@clouderty.co.uk',to_email,subject,message,$rootScope.decodedToken.Name, email_signature);
            //console.log("Email sent");
            $scope.b = sendEmailInternalPostNote.postNote(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email, subject, message, property, task_id, unit_number, email_signature);


            $state.reload();

        }


    };

    $scope.updateProgress = function (progress, taskID) {
        console.log("inside function updateProgress");
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Task ID: " + taskID);

        $scope.progress.progress = progress;
        $scope.progress.Status = 21;

        $scope.progress.Task_ID = taskID;
        $scope.progress.Task_Status_ID = progress;
        $scope.progress.Date_Completed = date;
        $scope.progress.progress = progress;
        $scope.progress.id = taskID;
        $scope.progress.date_completed = date;

        if($scope.progress.progress == 5) {



            console.log("Task completed: " + progress);

            apiService.tasks(taskID, $scope.progress).then(
                function (response) {
                    console.log("Task updated as completed");
                    if($scope.progress.progress == "Completed") {                //$scope.open();
                        console.log("Task updated as completed");






                        //$scope.obj = response.data;
                        //$state.reload();
                    }
                    /*
                    $scope.obj = response;

                    console.log("$scope.obj");
                    var from_email = "Clouderty | Task Completed | "  + $scope.obj.data.Property_name + " | " + $scope.obj.data.Description + " | " +  " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Task Completed | " + " - Completed by: " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + " | " + $scope.obj.data.Description + " - Created By: " + $scope.obj.data.Created_By;
                    var message = "Clouderty | Task Completed | " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + "\n Task Name: " + $scope.obj.data.Description + "\nDate started: " + $scope.obj.data.Date_started + " | Date completed  " + $scope.obj.data.Date_completed + "\n\n Created by: " + $scope.obj.data.Created_By + "\n\n Completed By: " + $rootScope.decodedToken.Name;
                    var to_email = "rentals@platinumcrown.co.uk";
                    $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Task complete | Task ID' + $scope.obj.data.Task_ID);
                    */
                },
                function () {
                    console.log("API Service Error: tenancies")
                }
            );
            var urlNotification = window.location.href;
            console.log("Send email: " + progress);
            $rootScope.saveNotification(3, $scope.obj.Description, urlNotification);
            var from_email = "Clouderty | Task Completed " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Closed by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date completed: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");



        }
        else {

            console.log("Task not completed: " + progress);
            $scope.progress.id = taskID;
            $scope.progress.date_completed = null;
            $scope.progress.Date_Completed = null;

            apiService.tasks(taskID, $scope.progress).then(
                function (response) {
                    console.log("Task updated as completed");

                    //$scope.obj = response;
                    $state.reload();



                },
                function () {
                    console.log("API Service Error: tenancies")
                }
            );

            var from_email = "Clouderty | Task Updated " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Updated by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date updated: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");

        }

        /*
        if($scope.progress.progress == "Completed") {




            console.log("Task completed: " + progress);

            $http.put("api/tasks/progress", $scope.progress).success(function(response) {
                if($scope.progress.progress == "Completed") {                //$scope.open();
                    console.log("Task updated as completed");
                    //$scope.obj = response.data;
                    //$state.reload();
                }


            })

            var urlNotification = window.location.href;
            console.log("Send email: " + progress);
            $rootScope.saveNotification(3, $scope.obj.Description, urlNotification);
            var from_email = "Clouderty | Task Completed " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Closed by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date completed: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");



        }
        else {
            //$scope.progress.date_completed = null;

            console.log("Task not completed: " + progress);
            $scope.progress.id = taskID;
            console.log("Task Date Null: " + progress);
            $scope.progress.date_completed = null;
            $http.put("api/tasks/progress", $scope.progress).success(function(response) {
                //$state.reload();
            })

            var from_email = "Clouderty | Task Updated " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Updated by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date updated: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");
        }
        */



    }

    $scope.emailSignature = function() {

        $scope.email_signature = 'Kind regards, <br />' + $rootScope.decodedToken.Name + '<br /><br />Platinum Crown <br />The Colchester Centre <br />Hawkins Road <br />Colchester <br />CO2 8JX';

    };

    $scope.actionSelect = function(search_documents_action){

        if(search_documents_action == "1") {
            console.log("$stateParams.id:" + $stateParams.id);
            $state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("Task Edit");
        }else if(search_documents_action == "2") {
            $state.go('tenancy-agreement-single-let', {id: $scope.obj.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("View venancy agreement - single let");
        }else {

        }
    };

    $scope.selectVendor = function(id) {

        console.log("API Service: selectVendor");
        $scope.obj.selectedVendors = [];



        //apiService.vendors().then(

        apiService.getVendorsTasks('1', '1', $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
            function(response) {
            //$http.get("api/vendors-tasks/" + id).success(function (response) {
                $scope.obj.selectedVendors.push({
                    "Vendor_ID": response.data.data.vendor_id,
                    "Description": response.data.data.description,
                    "Company_Name": response.data.data.company_name,
                    "Department": response.data.data.Department,
                    "Contact_Name": response.data.data.contact_name

                })
                $scope.obj.vendor_id = id;
                //$scope.obj.vendor_id = response.data.data.vendor_id;
                $scope.obj.description = response.data.data.description;
                $scope.obj.company_name = response.data.data.company_name;
                $scope.obj.Department = response.data.data.Department;
                $scope.obj.contact_name = response.data.data.contact_name;

                console.log("$scope.obj.vendor_id: " + $scope.obj.vendor_id);

            },
            function() {
                console.log("API Service: getVendorsTasks error")
            }
        );
        $scope.search_vendor = "";


    }

    $scope.actionSelectVendors = function(action_select, vendorsTasksID){

        if(action_select == "1") {
            if(confirm("Do you want to remove this vendor?")) {
                console.log("Remove vendor");
                var q = $q.defer();


                var vendorTaskData = {};

                vendorTaskData.itemsPerPage = $scope.itemsPerPage;
                vendorTaskData.pageno = 1;
                vendorTaskData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                vendorTaskData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                vendorTaskData.Task_ID = $scope.obj.Task_ID;
                vendorTaskData.Status = 31;
                vendorTaskData.id = '*';
                vendorTaskData.Vendors_Tasks_ID = vendorsTasksID;
                console.log(vendorTaskData);

                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1");
                        return apiService.vendors("*", vendorTaskData).then(

                            //apiService.deleteTask($scope.itemsPerPage, '1', $scope.obj.Task_ID, vendor_id, 'C', 'D', '3', 'E', 'F', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
                            function (response) {
                                console.log("tasks delete vendor API");
                                //$scope.loadData();
                                return response.data.data;

                            },
                            function () {
                                console.log("tasks delete vendor API failed");
                            }
                        );
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2");
                        $state.reload();
                        //$scope.loadData();
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);




                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            }
        }else if(search_documents_action == "2") {

        }else {

        }
    };

    $scope.searchVendors = function() {
        console.log("in function searchVendors");
        var q3 = $q.defer();
        $scope.vendors = null;
        $http.get('api/vendors/search/' + $scope.search_vendor).success(function(response) {
            $scope.vendors = response.data;
            q3.resolve(response);
        })
        return q3.promise;
    }


    $scope.getVendorsTasks = function(pageno) {

        console.log("in function getVendorsTasks");
        $scope.$parent.vendorTasks = null;
        console.log("$stateParams.id :" + $stateParams.id);
        $scope.vendorTasks = {};
        $scope.$parent.vendorTasks = {};
        $scope.vendor = {};

        var q2a = $q.defer();
        var vendorTaskData = {};

        vendorTaskData.itemsPerPage = $scope.itemsPerPage;
        vendorTaskData.pageno = pageno;
        vendorTaskData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        vendorTaskData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        vendorTaskData.Task_ID = $scope.obj.Task_ID;
        vendorTaskData.Status = 11;
        vendorTaskData.id = '*';
        console.log(vendorTaskData);
        //apiService.vendorsViewTaskRecords($scope.itemsPerPage, pageno, $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(

        apiService.vendors("*", vendorTaskData).then(
            function(response) {

                console.log("API Service: vendorsViewTaskRecords inside $scope.obj.Task_ID: " + $scope.obj.Task_ID);
                console.log("API Service: vendorsViewTaskRecords inside bbbbbbbbbbbbbbbbbbbbbbbbbbbb: " + $stateParams.id);
                $scope.vendorTasks = response.data.data;
                $scope.$parent.vendorTasks = response.data.data;
                $scope.totalCount = response.data.totalCount;
                $scope.vendorTasks.totalCount = response.data.totalCount;

                console.log("$scope.vendorTasks.totalCount = " + $scope.vendorTasks.totalCount);
                $scope.$parent.totalCount =  $scope.totalCount;
                //$scope.$parent.itemsPerPage = $scope.itemsPerPage;
                $scope.itemsPerPage = $scope.itemsPerPage;
                $scope.currentPage = pageno;
                //console.log(response);
                //console.log(response.data);
                console.log(response);
                q2a.resolve(response);

            },
            function() {
                console.log("API Service: vendorsViewTaskRecords error: " + $stateParams.id);

            }

        );
        return q2a.promise;
    }

    $scope.obj.selectedVendorTasks = [];

    $scope.selectVendorTasks = function(vendor) {
        if(confirm("Do you want to add a vendor?")){
            console.log("locatoIn 1");
            var qselectVendorTasks = $q.defer();
            $scope.obj.selectedVendors = [];

            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 getData");
                    return apiService.vendorsViewTaskRecords($scope.itemsPerPage, $scope.pageno, $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                        function(response) {
                            //$http.get("api/vendors-tasks/" + id).success(function (response) {
                            $scope.obj.selectedVendors.push({
                                "Vendor_ID": response.data.data.vendor_id,
                                "Description": response.data.data.description,
                                "Company_Name": response.data.data.company_name,
                                "Department": response.data.data.Department,
                                "Contact_Name": response.data.data.contact_name
                            })

                            //$scope.obj.Task_ID = response.data.data.Task_ID;
                            //$scope.$parent.obj.vendor_id = id;
                            /*
                            $scope.obj.vendor_id = id;
                            $scope.obj.description = response.data.data.description;
                            $scope.obj.company_name = response.data.data.company_name;
                            $scope.obj.Department = response.data.data.Department;
                            $scope.obj.contact_name = response.data.data.contact_name;
                            */
                            console.log("id ---- : ");
                            console.log(vendor);
                            console.log(response);
                            $scope.vendorTasks = {};
                            console.log("$stateParams.id: " +  $stateParams.id);





                            /*
                            $scope.vendorData.company_name = response.data.data.company_name;
                            $scope.vendorData.Department = response.data.data.Department;
                            $scope.vendorData.contact_name = response.data.data.contact_name;
                            */



                            $scope.vendorData.vendor_id = vendor.vendor_id;
                            $scope.vendorData.Vendor_ID = vendor.vendor_id;
                            $scope.vendorData.Task_ID = $scope.obj.Task_ID;
                            $scope.vendorData.Customer_ID = vendor.Customer_ID;
                            $scope.vendorData.Status = 4;
                            console.log("rest vendorTasks");

                            apiService.vendors("*", $scope.vendorData).then(
                                function(response) {

                                    //$scope.vendor = response.data.data;
                                    //$scope.obj.Mobile_Number = $scope.vendor.Mobile_Number;
                                    //$scope.obj.Mobile_Number = "07949440089";
                                    //$scope.obj.vendor_id = $scope.vendor.vendor_id;

                                    //$scope.obj.Customer_ID = $scope.vendor.Customer_ID;
                                    console.log("API vendors: " );

                                    console.log(response);
                                    console.log("$scope.obj.Mobile_Number: " + $scope.obj.Mobile_Number);

                                }
                            )


                            //location.reload();
                            //$scope.getVendorsTasks(1);
                            //$scope.loadData();

                            //})
                        },
                        function() {
                            console.log("API Service: getVendorsTasks error")
                        }
                    );
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log("A2 getGlobalPromise");
                    $scope.vendorTasks = {};
                    $scope.$parent.vendorTasks = {};
                    $scope.obj.selectedVendors = [];
                    $state.reload();
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log("A2 getVendorsTasks");
                    //$scope.vendorTasks = [];
                    //$scope.$parent.vendorTasks = [];
                    console.log("A3");
                    return $scope.getVendorsTasks(1);
                    //$scope.getTaskNotes();
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $('.maintenance-account-data').hide();

                    $('.data').hide();
                    $scope.myValue = data;
                }, function (err) {
                    $scope.myValue = err;
                })

            qselectVendorTasks.resolve($scope.promise);





        }else{
            console.log("locatopn 2");

        }

        $scope.search_vendor = "";
    }

    $scope.accordion = function(element, element_data) {
        console.log('inside function accordion');
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.getMaintenanceAccount = function () {

        console.log('inside function getMaintenanceAccount');
        var qGetMaintenanceAccount = $q.defer();
        qGetMaintenanceAccount.resolve($scope.maintenanceFunction($scope.obj.Maintenance_Account_ID, 68));

        return qGetMaintenanceAccount.promise;
    }

    $scope.maintenanceFunction = function(id, status) {

        var qMaintenancesFunction = $q.defer();
        console.log("function debtsFunction");
        $scope.maintenance.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.maintenance.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.maintenance.itemsPerPage = $scope.itemsPerPage;
        $scope.maintenance.pageno = $scope.pageno;
        /*
        $scope.maintenance.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.maintenance.Debt_Account_ID = $scope.processAccount.Process_Account_ID;
        $scope.maintenance.Estate_ID = $scope.landlord.Estate_ID;
        $scope.maintenance.Property_ID = $scope.landlord.Property_ID;
        $scope.maintenance.monthsExpiring = $scope.monthsExpiring;
        */
        $scope.maintenance.Status = status;
        $scope.maintenance.Maintenance_Account_ID = id;
        //$scope.pageno = 1;

        if($scope.maintenance.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.maintenance.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.maintenance.search = $scope.search;
        }else{

            $scope.maintenance.search = "*";
        }

        $scope.maintenanceData = $scope.maintenance;
        $scope.maintenance = {};


        apiService.maintenance(id, $scope.maintenanceData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");


                if(status == 31 || status == 41){

                    $scope.maintenance.Maintenance_Account_ID = $scope.obj.Maintenance_Account_ID;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;

                }
                else{

                    $scope.maintenance = response.data.data;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;
                    //console.log(decodeURIComponent("https%3A%2F%2Fw3schools.com%2Fmy%20test.asp%3Fname%3Dst%C3%A5le%26car%3Dsaab%C2%A3%C2%A3%C2%A3"));
                    $scope.maintenance.Materials_Required = decodeURIComponent($scope.maintenance.Materials_Required);

                }

                $scope.maintenanceVariable = response.data;
                $scope.maintenanceSpinner = 1;
                console.log("$scope.maintenanceVariable: " +  $scope.maintenanceVariable);
                console.log($scope.maintenanceVariable);

                console.log("$scope.maintenance.Clients_Budget: " +  $scope.maintenance.Maintenance_Account_ID);
                console.log(response);
                console.log("maintenance API");
                qMaintenancesFunction.resolve(response);

            },
            function () {
                console.log("API Service Error: maintenance")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qMaintenancesFunction.promise;
    }

    $scope.addMaintenanceItem = function (dataObj) {

        console.log("inside function addMaintenanceItem");

        console.log(dataObj);

        if(confirm("Do you want to add this item?"), 'Clouderty notification'){
            $scope.maintenanceMaterialsLabourView = [];
            $scope.maintenanceSpinner = "";
            $scope.gotoAnchor('maintenance');

            console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.obj.Maintenance_Account_ID);
            $scope.maintenance.Maintenance_Account_ID = $scope.obj.Maintenance_Account_ID;
            $scope.maintenance.Description = dataObj.descriptionAdd;
            $scope.maintenance.Cost = dataObj.costAdd;
            //$scope.paymentBlockSpinner = 1;

            var q = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    $scope.maintenanceFunction($scope.obj.Maintenance_Account_ID, 41);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A2");



                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }


    }

    $scope.deleteMaintenanceItem = function (dataObj) {

        console.log("inside function deleteDirItem");

        console.log(dataObj);

        if(confirm("Do you want to delete this item?"), 'Clouderty notification'){
            console.log("in function dirTests");
            $scope.maintenanceMaterialsLabourView = [];
            $scope.maintenance = {};
            $scope.maintenanceSpinner = "";

            console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.processAccount.data.Maintenance_Account_ID);
            $scope.maintenance.Maintenance_Account_ID = $scope.obj.Maintenance_Account_ID;
            $scope.maintenance.Maintenance_Materials_Labour_ID = dataObj.Maintenance_Materials_Labour_ID;
            //$scope.paymentBlockSpinner = 1;

            var q = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    $scope.maintenanceFunction($scope.obj.Maintenance_Account_ID, 31);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A2");



                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }


    }

    console.log("$scope.obj.Task_IDddddddddddddddddddddddd: " + $scope.obj.Task_ID);
    console.log("$stateParams.idcccccccccccccccccccccccccc: " + $stateParams.id);

    $scope.loadData = function() {

        console.log("C1 loadData");
        var qa = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A1 getData");
                return $scope.getData();
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log("A2 getVendorsTasks");
                //$scope.vendorTasks = [];
                //$scope.$parent.vendorTasks = [];
                console.log("A3");
                return $scope.getVendorsTasks(1);
                //$scope.getTaskNotes();
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7');

                $('.maintenance-account-data').hide();

                $('.data').hide();
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        qa.resolve($scope.promise);
    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////


    //$scope.getTaskStatus();
    $scope.loadData();


}]);