/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("timesheetAddEditCtrl", ["$rootScope", "$scope", "$http", "$state",  "postUserActivityService", "apiService", function($rootScope, $scope, $http, $state, postUserActivityService, apiService) {


   $scope.timesheet = {};



   $scope.hidePropertySearch = false;

   $scope.getProperties = function () {
       $scope.properties = null;

       apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
           function(response) {

               $scope.properties = response.data;
               console.log("API Service: searchProperties");
           },
           function() {

               console.log("There was an error");

           }
       )


      /*$http.get('api/properties/' + $scope.search_property).success(function(response) {
         $scope.properties = response;
         
      })*/
   }

   $scope.selectProperty = function (property_id, property_name) {
      $scope.search_property = "";
      $scope.hidePropertySearch = true;
      $scope.selected_property_name = property_name;
      
      $scope.timesheet.Property_ID = property_id;
   }




   $scope.addTimesheet = function(property_name) {


      if (confirm('Are you sure you want to add a time sheet record?')) {


         var date = new Date();
         date.setHours(date.getHours()+1);
         console.log("Variable 1: " + $rootScope.decodedToken.Vendor_ID);
         console.log("Variable 2: " + $rootScope.Vendor_ID);

         $scope.timesheet.selected_property_name = property_name;
         $scope.timesheet.User_ID = $rootScope.decodedToken.id;
         $scope.timesheet.Action_Time = date;
         //$scope.timesheet.Action_Type = 'Job Started';
         //$scope.timesheet.Property_ID = '1';
         $scope.timesheet.Vendor_ID = $rootScope.decodedToken.Vendor_ID;
         $scope.timesheet.Job_Location = 'Colchester';
         //$scope.timesheet.Job_Type = "Cleaning";

         $http.post('api/timesheet', $scope.timesheet).success(function(response) {

            $scope.obj = response;

            //console.log($scope.obj.vendors_timesheet_id);
            //console.log($scope.obj.vendors_timesheet_id2);

            var from_email = "Clouderty | Job Timesheet | " + $scope.timesheet.Action_Type + " | " +  $scope.timesheet.selected_property_name + " <notifications@clouderty.co.uk>";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            //var subject = "Vendor Timesheet | " +  $scope.timesheet.selected_property_name + " - " + $scope.timesheet.Vendor_Timesheet_ID;
            var subject = "Job Timesheet ID: " + + $scope.obj.vendors_timesheet_id + " | " + $scope.timesheet.Job_Type + " | " +  $scope.timesheet.selected_property_name;
            var message = "Timesheet ID: " + $scope.obj.vendors_timesheet_id + "<br />" +
                "Created By: " + $rootScope.decodedToken.Name + "<br /><br />" +
                "Property: " + $scope.timesheet.selected_property_name + "<br /><br />" +
                "Time/Date: " + Date() + "<br /><br />" +
                "Action Type: " +  $scope.timesheet.Action_Type;

            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            $scope.postUserActivityServiceMessage = "Job Timesheet | " + $scope.timesheet.Action_Type + " | " +  $scope.timesheet.selected_property_name + " | Job Timesheet ID: " + + $scope.obj.vendors_timesheet_id + " | " + $scope.timesheet.Job_Type + " | " +  $scope.timesheet.selected_property_name;
            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);
            $state.reload()
            //$state.go('contractors-main-nav.contractors-tasks-side.timesheet-add-edit');

         });
      } else {
         // Do nothing!
      }
   }



   
}])