/*(function () {
  'use strict';
  angular
  .module('app')
  .controller('templateCtrl', templateCtrl)

  templateCtrl.$inject = ['$http', '$location', "$stateParams", '$scope', 'FlashService' , 'UploadService'];

  function templateCtrl( $http , $location, $stateParams, $scope, FlashService, UploadService ) {
*/
clouderty.controller("templateCtrl", ['$http', '$location', "$stateParams", '$scope', 'FlashService' , 'UploadService', '$timeout', "templateService", function ($http, $location, $stateParams, $scope, FlashService, UploadService, $timeout, templateService)  {

    console.log("inside controller templateCtrl");
    //////////////////////notifications start

    $scope.notificationList = '';

    templateService.fetchNotifications(function(res,err) {
        if(err){
            console.log("error fetchNotifications");
            //flashService.Error(err,false);
            //resetForm();
        }else{
            if(res.data.status=='success'){
                flashService.Success(res.data.message,false);

                console.log(res);
            }
        }
    });

    $scope.saveNotification = function() {

        /*****
         notification_id 1 => New Task Created
         notification_id 2 => Task Started
         notification_id 1 => Task Completed
         notification_id 1 => New Task Note
         notification_id 1 => Vendor Assigned
         notification_id 1 => Addition Of Budget Items
         notification_id 1 => Send Quote For Cost Of Job

         pass any of ID you want to trigger as notification automatically shows on notifications
         notificatioData.append("notification_id" , '2');

         notification_from_id (Notifier Id means person ID from notification comes)
         user_id_pm_1 (Not mandatory leave it blank if dont want to show to Proprty manager 1)
         user_id_pm_2 (Not mandatory leave it blank if dont want to show to Proprty manager 2)

         *******/

        var notificationData = new FormData();

        notificationData.append("notification_from_id" , '11');
        notificationData.append("notification_id" , '2');
        notificationData.append("user_id_pm_1" , '10');
        notificationData.append("user_id_pm_2" , '10');
        notificationData.append("user_id_vendor" , '10');
        notificationData.append("user_id_am" , '');
        notificationData.append("property_id" , '1');

        notificationData.append('method', "saveNotification");

        templateService.saveNotification(notificationData,function(res,err) {
            if(err){
                flashService.Error(err,false);
                //resetForm();
            }else{
                if(res.data.status=='success'){
                    flashService.Success(res.data.message,false);
                }
            }
        });
    };

    // function for fetching the incoming notification on body load
    $scope.onInit = function() {
        templateService.fetchNotifications(function(res,err) {
            if(err){
                flashService.Error(err,false);
                //resetForm();
            }else{
                $scope.notificationList = res.data.data;
                $scope.count = res.data.unreadCount;
            }
        });
    }


    // watch function for checking whether new notifications comes or not

    $scope.$watch('notificationList' , function() {
        templateService.fetchNotifications(function(res,err) {
            if(err){
                flashService.Error(err,false);
            }else{
                $scope.notificationList = res.data.data;
                $scope.count = res.data.unreadCount;
            }
        });
    });


    // Update the Notification read count on click of notification bell.

    $scope.updateNotificationStatus = function() {
        templateService.updateNotificationStatus(function(res,err) {
            if(err){
                flashService.Error(err,false);
                resetForm();
            }else{
                if(res.data.status=='success'){
                    flashService.Success(res.data.message,false);
                }
            }
        });
    };

    // function for fetching the incoming notification on body load
    $scope.updateClickedStatus = function(notificationId) {
        console.log(notificationId);
        templateService.updateClickedStatus(notificationId , function(res,err) {
            if(err){
                flashService.Error(err,false);
                resetForm();
            }else{
                if(res.data.status=='success'){
                    flashService.Success(res.data.message,false);
                }
            }
        });
    }

    $scope.changeState = function() {
        console.log('changeState');
    }


    //////////////////////notifications end



    $scope.convertPDf = function() {
        $('.alert').show('');
        var doc =  new jsPDF('p','pt','letter');
        var specialElementHandlers = {
            '#maincont': function (element, renderer) {
                return true;
            }
        };
        doc.setFontSize(16);
        doc.fromHTML($('body').html(), 40, 80, {
            'width': 500,
            'margin': 1,
            'pagesplit': true,
            'elementHandlers': specialElementHandlers
        }, function (dispose) {
            var pdf = doc.output(); //returns raw body of resulting PDF returned as a string as per the plugin documentation.
            var data = new FormData();
            var filename = $scope.filename;
            var email    = $scope.email;

            data.append("file" , pdf);
            data.append('filename', filename);
            data.append('email', email);
            data.append('method', "savePdf");

            UploadService.uploadImage(data,function(res,err) {
                if(err){
                    flashService.Error(err,false);
                    resetForm();
                }  else{
                    if(res.data.status=='success'){
                        FlashService.Success(res.data.message,false);
                        resetForm();
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                        $timeout( function(){
                            $('.alert').html('');
                            $('.alert').hide();
                        }, 5000 );
                    }
                }
            });
        });
    }

    function resetForm() {
        angular.element("input[type='file']").val(null);
        $('#image-preview').attr('src', $scope.preview);
        $scope.filename = null;
        $scope.email = null;
    }

}])
