/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("paymentTransactionAddEditCtrl", ["$anchorScroll", '$location', "$scope", "$http", "stringModifierService", "$stateParams", "noteService", "$state", "$uibModal", "Upload", "emailService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "dateInputService", "moment", "scrollToService", "scrollToService", function($anchorScroll, $location, $scope, $http, stringModifierService, $stateParams, noteService, $state, $uibModal, Upload, emailService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, dateInputService, moment, scrollToService) {
    console.log("inside function paymentTransactionAddEditCtrl");

    $scope.statePaymentTransactionAdd = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
    $scope.statePaymentTransactionAddSurround = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
    $scope.stateTenancyAgreementExternal = "tenancy-agreement-room.payment";
    $scope.stateTenancyView = "lettings-main-nav.lettings-side.lettings-view-tenancy.notes";
    $scope.stateProcessAccountView = "lettings-main-nav.lettings-side.process-account-view.notes";

    if($state.current.name == $scope.statePaymentTransactionAdd){

        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
        $rootScope.watchPaymentAddEdit = 1;

        //added 06-01-2019 to stop incorrect tenancy ID's being added to payments
        $rootScope.tenancy_id = "";

    }
    else if($state.current.name == $scope.stateTenancyAgreementExternal){

        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

    }
    else if($state.current.name == $scope.stateTenancyView || $state.current.name == $scope.stateProcessAccountView){

        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
        $rootScope.watchPaymentAddEdit = 1;
    }
    else {

        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

    }

    $scope.$watch('watchPaymentAddEdit', function (toVariable, fromVariable) {

        console.log("inside watch and watchPaymentAddEdit is: " + $scope.tenancyAgreementBlock);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);


        console.log("$rootScope.watchPaymentAddEdit: " + $rootScope.watchPaymentAddEdit);

        /*

        write the below sentence in code

        if state is ad a payment state then paymentView should equal 1

         */

        if($rootScope.watchPaymentAddEdit == 1){

            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;
            $scope.search = "";

            $scope.New_Record = "";
            $scope.viewPage = "";
            $scope.pageBlock = '1';
            $scope.progress = {};
            $scope.pageBlock = '0';
            $scope.testBlock = '5';
            $scope.counter = '0';
            $scope.message = "";

            $scope.business = {};
            $scope.hideBusinessSearch = false;
            $scope.properties = {};
            $scope.units = [];
            $scope.unitText = "";
            $scope.unitBlock = {result: false};
            $scope.unitBlock.result = false;
            $scope.tenancies = [];

            $scope.monthsExpiring = 0;
            $scope.landlord = [];
            $scope.landlord.Estate = "";
            $scope.landlord.Estate_ID = 0;
            $scope.landlord.Property = "";
            $scope.landlord.Property_ID = 0;

            $scope.paymentAdded = null;
            $scope.paymentTransactions = {};
            $scope.payment_transaction = {};
            $scope.payment_transaction.cardDetails = {};
            $scope.paymentTransactionsData = {};
            $scope.payment_transaction.paymentTransactionCategories = {};
            $scope.paymentTransactionCategories = [];
            $scope.paymentTransactionsData.Payment_Transaction_Category_Description = '0';
            $scope.customers = {};
            $scope.payment_transaction.customer = [];
            $scope.payment_transaction.Business_Bank_refCust = "";
            $scope.paymentResultText = "";
            $scope.payment_transaction.cardDetails.Recurring_Payment = '0';
            $scope.paymentBlockSpinner = '1';
            $scope.payment_transaction.addButton = 1;
            $scope.recurringPaymentToggle = '0';
            $scope.transactionTypeBlock = '1';
            $scope.tenancyPaymentBlock = '0';
            $scope.$parent.tenancyAgreementBlock = null;
            $scope.tenancyAgreementBlock = null;
            //$rootScope.tenancyAgreementBlock = null;
            $scope.payment = {};
            $scope.payment.obj = {};
            $scope.payment_transaction.Amount = null;

            $scope.note = {};
            $rootScope.paymentResultText = "";

            $scope.statePaymentTransactionAdd = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
            $scope.statePaymentTransactionAddSurround = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
            $scope.stateTenancyAgreementExternal = "tenancy-agreement-room.payment";
            $scope.stateTenancyView = "lettings-main-nav.lettings-side.lettings-view-tenancy.notes";
            $scope.stateProcessAccountView = "lettings-main-nav.lettings-side.process-account-view.notes";

            $rootScope.payment_transaction = {};
            $rootScope.property = {};
            $rootScope.payment_transaction.Payment_Transaction_Card_Completed = "";
            console.log("$rootScope.payment_transaction.Payment_Transaction_Card_Completed " + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);

            $(".unitBlock-result").hide();
            //$scope.unitBlock-result = false;

            $(".mobile-notes-button").click(function () {
                $('.content').animate({
                    scrollTop: $(".notes-search-section").offset().top
                }, 500);
                $(".mobile-notes-button").hide();
            });

            $('.progress-select').select2({
                minimumResultsForSearch: -1
            });

            $scope.getGlobalPromise = function () {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )


                return q55.promise;
            }

            $scope.paymentTransactionsFunction = function (id, status) {
                console.log("function paymentTransactionsFunction");

                $scope.paymentTransactionsData = $scope.payment_transaction;
                $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.paymentTransactionsData.Property_ID = $scope.payment_transaction.Property_ID;
                console.log("$scope.payment_transaction.Property_ID: " + $scope.payment_transaction.Property_ID);

                console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);
                //check if this is a tenancy signup rent payment
                if($state.current.name == $scope.stateTenancyAgreementExternal) {
                    console.log("check if this is a tenancy signup rent payment");

                    $scope.paymentTransactionsData.Recurring_Payment_Amount = $scope.payment_transaction.cardDetails.Recurring_Payment_Amount;

                    if (!$rootScope.tenancy_id) {
                        //check if a first rent payment exists if not add payment id to tenancy
                        console.log("check if a first rent payment exists if not add payment id to tenancy");
                    }else{
                        console.log("show payment function that first rent has been paid");
                        $scope.paymentTransactionsData.Tenancy_ID = $rootScope.tenancy_id;
                        if (!$rootScope.Rent_Payment_ID_First) {
                            //check if a first rent payment exists if not add payment id to tenancy
                            console.log("check if a first rent payment exists if not add payment id to tenancy");
                        }else{
                            console.log("show payment function that first rent has been paid");
                        }
                    }

                    $scope.unitText = " U " + $rootScope.property.Unit_number;
                }


                if ($rootScope.tenancy_id) {
                    console.log("$rootScope.property.Property_name: " + $rootScope.property.Property_name);
                    $scope.paymentTransactionsData.Address_1 =  $rootScope.property.Property_name;
                    $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                    $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;

                    $scope.unitText = " U " + $rootScope.property.Unit_number;
                }



                var q2 = $q.defer();
                console.log($scope.paymentTransactionsData);

                apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                    function (response) {
                        $scope.paymentTransactions = response.data;
                        console.log($scope.paymentTransactions);
                        console.log("API Service: paymentTransactions");
                        console.log(response);
                        console.log("response.Payment_Transaction_ID: " + response.Payment_Transaction_ID);
                        console.log("response.data.Payment_Transaction_ID: " + response.data.Payment_Transaction_ID);
                        //console.log("response.Payment_Transaction_Card_Completed: >>>>>>>>>>> " + response.Payment_Transaction_Card_Completed);
                        console.log($scope.paymentTransactions);
                        $scope.payment_transaction.Payment_Transaction_ID = $scope.paymentTransactions.Payment_Transaction_ID;
                        console.log("$scope.payment_transaction.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        $scope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $rootScope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $scope.payment_transaction.Failed_Response_Message = $scope.paymentTransactions.Failed_Response_Message;
                        $scope.payment_transaction.First_Payment = $scope.paymentTransactions.First_Payment;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Amount = $scope.paymentTransactions.Payment_Transactions_Recurring_Amount;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Order_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_Order_ID;
                        $scope.payment_transaction.Payment_Transactions_Recurring_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_ID;

                        if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){
                            console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.paymentTransactions.Payment_Transactions_Recurring_Completed);

                            $rootScope.payment_transaction.Payment_Transactions_Recurring_Completed = $scope.paymentTransactions.Payment_Transactions_Recurring_Completed;

                        }

                        if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1'){
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " SUCCESSFUL";
                            $rootScope.paymentResultText = $scope.paymentResultText;

                            $scope.payment_transaction.paymentDiv = '0';
                            //check if this is a tenancy signup rent payment

                            console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv $state.current.name: " + $state.current.name);
                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                console.log("check if this is a tenancy signup rent payment");



                                //var el = document.getElementById("agreeToClauses");
                                //el.scrollIntoView();
                                if (!$rootScope.Rent_Payment_ID_First) {
                                    //check if a first rent payment exists if not add payment id to tenancy
                                    console.log("check if a first rent payment exists if not add payment id to tenancy");
                                    $rootScope.Rent_Payment_ID_First = $scope.paymentTransactions.Payment_Transaction_ID;
                                    //$scope.tenanciesFunction($stateParams.tenancyID, '25');

                                }

                            }


                        }else{
                            //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            console.log("API Service: paymentTransactions");
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " FAILED - " + $scope.payment_transaction.Failed_Response_Message;
                            $rootScope.paymentResultText = $scope.paymentResultText;
                        }

                        console.log("$scope.paymentTransactions.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.payment_transaction.Payment_Transactions_Recurring_Completed);
                        console.log("$scope.paymentTransactions.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                        q2.resolve(response);
                    },
                    function () {
                        console.log("There was an error in paymentTransactions");
                    }
                )
                //});
                return q2.promise;
            }

            $scope.customersFunction = function (id, status) {
                console.log("function customersFunction");

                $scope.paymentTransactionsData = $scope.payment_transaction;
                $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.paymentTransactionsData.Status = status;
                $scope.paymentTransactionsData.search = $scope.search_customer;
                $scope.paymentTransactionsData.pageno = 1;
                $scope.paymentTransactionsData.itemsPerPage = 100;

                if(status == 5){
                    console.log("status should be 5: >>>> " + status);

                    $scope.paymentTransactionsData.Customer_ID = id;

                }

                var q21 = $q.defer();
                apiService.customers(id, $scope.paymentTransactionsData).then(
                    function (response) {
                        $scope.customers = response.data.data;

                        console.log($scope.customers);
                        console.log("API Service: customers");
                        q21.resolve(response);
                    },
                    function () {
                        console.log("There was an error in api service customers");
                    }
                )
                //});
                return q21.promise;
            }

            $scope.getBusinesses = function () {

                $scope.business = null;
                $http.get('api/businesses-search/' + $scope.search_business).success(function (response) {
                    $scope.businesses = response;
                    $scope.createPaymentIDBusiness();
                })
            }

            $scope.selectBusiness = function (business_id, business_name, Bank_ref) {
                $scope.search_business = "";
                $scope.hideBusinessSearch = true;
                $scope.selected_business_name = business_name;

                $scope.payment_transaction.Business_ID = business_id;
                console.log("Bank ref: " + Bank_ref);
                $scope.payment_transaction.Business_Bank_ref = Bank_ref;
                console.log("Bank ref: 2" + $scope.payment_transaction.Business_Bank_ref);
            }

            //////////////////////////  searchProperty  /////////////////////////////////////////////////////////////////////////////////

            $scope.searchProperty = function () {
                $scope.properties = null;
                console.log("searchProperty in");

                console.log("$scope.searchProperties");
                apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        $scope.properties = response.data;
                        console.log($scope.paymentTransactionCategories);
                    },
                    function () {
                        console.log("There was an error")
                    }
                )

            }

            $scope.payment_transaction.selectedProperties = [];

            $scope.payment_transaction.selectedProperties.Units = [];

            $scope.selectPropertyEdit = function(id) {
                console.log("selectProperty in");
                console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzz");
                apiService.getProperty(id, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        //$http.get("api/property/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                        $scope.payment_transaction.selectedProperties.push({
                            "Property_ID": response.data.data.Property_ID,
                            "Address": response.data.data.Address_1,
                            "Town": response.data.data.Town,
                            "City": response.data.data.City,
                            "Postcode": response.data.data.Postcode
                        })
                        $scope.payment_transaction.Address_1 = response.data.data.Address_1;
                        $scope.payment_transaction.Property_name = response.data.data.Property_name;
                        $scope.payment_transaction.Postcode = response.data.data.Postcode;
                        $scope.payment_transaction.Unit_number = response.data.data.Unit_number;
                        $scope.payment_transaction.Unit_ID = response.data.data.Unit_ID;
                        angular.forEach(response.Units, function (unit, index) {
                            $scope.payment_transaction.selectedProperties.Units.push({
                                "Unit_ID": unit.Unit_ID,
                                "Unit_Number": unit.Unit_number
                            })
                        })

                        $scope.createPaymentIDProperty();
                    },
                    function () {
                        console.log("There was an error in getProperties")
                    }
                )


            }

            $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {
                var qselectProperty23 = $q.defer();
                console.log("selectProperty in property_id: >>>>>>>> " + property_id);
                console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzzbbbbbbbbbbbbbbbbbbbb");
                $http.get("api/property/" + property_id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                    $scope.payment_transaction.selectedProperties.push({
                        "Property_ID": response.data.Property_ID,
                        "Address": response.data.Address_1,
                        "Town": response.data.Town,
                        "City": response.data.City,
                        "Postcode": response.data.Postcode
                    })
                    angular.forEach(response.Units, function (unit, index) {
                        $scope.payment_transaction.selectedProperties.Units.push({
                            "Unit_ID": unit.Unit_ID,
                            "Unit_Number": unit.Unit_number
                        })
                    })
                    console.log(response);
                    console.log(response.data);

                    $scope.payment_transaction.Property_ID = property_id;
                    $scope.selected_property_name = property_name;
                    $scope.payment_transaction.selected_property_name = property_name;
                    $scope.payment_transaction.Property_name = property_name;
                    $scope.payment_transaction.Address_1 = address_1;
                    $scope.payment_transaction.Bank_ref = bank_ref;
                    $rootScope.property.Property_name = property_name;
                    //$scope.createPaymentIDPaymentTypeProperty();
                    $scope.createPaymentIDProperty();
                    console.log("$scope.payment_transaction.Property_ID: " + $scope.payment_transaction.Property_ID);

                    $scope.getProperties();

                    qselectProperty23.resolve(response);

                })
                $scope.search_property = "";
                $scope.hidePropertySearch = true;
                return qselectProperty23.promise;


            }

            $scope.deselectProperty = function (index) {
                $scope.payment_transaction.selectedProperties.splice(index, 1);
                $scope.hidePropertySearch = false;
                $scope.units = [];
                $scope.properties = {};
            }

            ///////////////////////////////////////////////////////

            $scope.getPropertiesSearch = function () {
                $scope.properties = null;
                console.log("$scope.getPropertiesSearch");
                apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        $scope.properties = response.data;
                    },
                    function () {
                        console.log("There was an error")
                    }
                )
            }

            $scope.getProperties = function () {
                console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmm");
                if($scope.payment_transaction.Property_ID){
                    console.log("We are in in getProperties if statement");
                    apiService.getProperty($scope.payment_transaction.Property_ID, $rootScope.decodedToken.Account_Holder_ID).then(
                        function (response) {
                            $scope.units = response.data.units;
                            //$scope.payment_transaction.Unit_number = response.data.units.Unit_number;
                            //$rootScope.property.Unit_number = response.data.units.Unit_number;
                            console.log("$scope.units: " + $scope.units);
                            console.log("$scope.payment_transaction.Unit_number: " + $scope.payment_transaction.Unit_number);

                            return response;
                        },
                        function () {
                            console.log("There was an error in getProperties")
                        }
                    )

                }

            }

            //////////////////////////  searchCustomer  /////////////////////////////////////////////////////////////////////////////////

            $scope.searchCustomer = function () {
                $scope.customers = null;
                console.log("searchCustomer function");

                $scope.customersFunction("*", 61);

            }

            $scope.payment_transaction.selectedCustomers = [];

            $scope.payment_transaction.selectedCustomers.Units = [];

            $scope.selectCustomerEdit = function(id) {
                console.log("selectProperty in");
                apiService.getProperty(id, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        //$http.get("api/property/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                        $scope.payment_transaction.selectedProperties.push({
                            "Property_ID": response.data.data.Property_ID,
                            "Address": response.data.data.Address_1,
                            "Town": response.data.data.Town,
                            "City": response.data.data.City,
                            "Postcode": response.data.data.Postcode
                        })
                        $scope.payment_transaction.Address_1 = response.data.data.Address_1;
                        $scope.payment_transaction.Property_name = response.data.data.Property_name;
                        $scope.payment_transaction.Postcode = response.data.data.Postcode;
                        $scope.payment_transaction.Unit_number = response.data.data.Unit_number;
                        $scope.payment_transaction.Unit_ID = response.data.data.Unit_ID;
                        angular.forEach(response.Units, function (unit, index) {
                            $scope.payment_transaction.selectedProperties.Units.push({
                                "Unit_ID": unit.Unit_ID,
                                "Unit_Number": unit.Unit_number
                            })
                        })
                    },
                    function () {
                        console.log("There was an error in getProperties")
                    }
                )
            }

            $scope.selectCustomer = function (customer_id, first_name, surname) {
                console.log("selectProperty in");
                $scope.customersFunction(customer_id, 5);
                //$http.get("api/customers/" + customer_id).success(function (response) {
                $scope.payment_transaction.selectedCustomers.push({
                    "Customer_ID": customer_id,
                    "First_name": first_name,
                    "Surname": surname
                })

                $scope.payment_transaction.customer.First_name = first_name;
                $scope.createPaymentIDPaymentTypeCustomer();

                console.log("$scope.payment_transaction.Customer_ID: " + $scope.payment_transaction.Customer_ID);

                //return($scope.getCustomers());
                $scope.getCustomers();

                //})
                $scope.search_customer = "";
                $scope.hideCustomerSearch = true;
            }

            $scope.deselectCustomer = function (index) {
                $scope.payment_transaction.selectedCustomers.splice(index, 1);
                $scope.hideCustomerSearch = false;
                //$scope.units = [];
                $scope.customers = {};
            }

            ///////////////////////////////////////////////////////

            $scope.getCustomersSearch = function () {
                $scope.customers = null;
                console.log("$scope.getCustomersSearch");
                apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        $scope.customers = response.data;
                    },
                    function () {
                        console.log("There was an error")
                    }
                )
            }

            $scope.getCustomers = function () {
                console.log("We are in in getCustomers");
                $scope.customersFunction($scope.paymentTransactionsData.Customer_ID, 5);

            }

            $scope.getFrequencies = function () {
                //console.log("Unit_ID: " + $scope.payment_transaction.units.Unit_ID);

                apiService.frequencies().then(
                    function (response) {
                        $scope.frequencies = response.data.data;

                        console.log(response.data);

                        console.log("$scope.payment_transaction.Unit_number: " + $scope.payment_transaction.Unit_number);

                    },
                    function () {
                        console.log("There was an error in getTenanciesUnit")
                    }
                )


            }

            $scope.selectFrequency = function () {

                console.log(">>>>>>>>>>>> $scope.payment_transaction.frequencies.Description_ePDQ: " + $scope.payment_transaction.frequencies.Description_ePDQ);
                $scope.payment_transaction.Recurring_Payment_Frequency = $scope.payment_transaction.frequencies.Description_ePDQ;

            }

            //////////////////////////////////////////////////////////////////////////////

            $scope.getUnit = function (unit) {
                $scope.payment_transaction.Unit_number = $scope.payment_transaction.units.Unit_number;
                console.log("Unit_ID: " + $scope.payment_transaction.units.Unit_ID);

                apiService.getTenanciesUnit($scope.payment_transaction.units.Unit_ID, $rootScope.decodedToken.Account_Holder_ID).then(
                    function (response) {
                        $scope.tenancies = response.data;
                        $scope.payment_transaction.tenancies = response.data;
                        $scope.payment_transaction.Unit_number = $scope.payment_transaction.tenancies.Unit_number;

                        console.log(response.data);

                        console.log("$scope.payment_transaction.Unit_number: " + $scope.payment_transaction.Unit_number);
                        console.log("unit.Unit_number: " + unit.Unit_number);

                        //if(!$scope.payment_transaction.Unit_number){
                        //$scope.unitText = "";
                        //}else{
                        //$scope.unitText = " U " + $scope.payment_transaction.Unit_number;
                        $scope.unitText = " U " + unit.Unit_number;
                        //}

                        $scope.createPaymentIDPropertyUnit();
                        console.log($scope.tenancies.data);
                    },
                    function () {
                        console.log("There was an error in getTenanciesUnit")
                    }
                )


            }

            $scope.getTenancy = function () {

                $scope.payment_transaction.Tenancy_ID = $scope.payment_transaction.tenancies.Tenancy_ID;
                $scope.payment_transaction.Unit_number = $scope.payment_transaction.tenancies.Unit_number;
                console.log("Tenancy ID: " + $scope.payment_transaction.Tenancy_ID);
                console.log("Unit_number: " + $scope.payment_transaction.Unit_number);
                //$scope.unitText = " U " + $scope.payment_transaction.Unit_number;

            }

            $scope.amountChange = function () {
                $scope.payment_transaction.addButton = 1;
            }

            $scope.addTransaction = function (Transaction, Date) {
                console.log("addTransaction.");

                if($state.current.name == $scope.stateTenancyAgreementExternal){
                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                    $scope.$parent.tenancyAgreementBlock = null;
                    $scope.tenancyAgreementBlock = null;
                    //$scope.$parent.tenancyAgreementBlock = null;
                    //$rootScope.tenancyAgreementBlock = null;

                }

                $scope.payment_transaction.addButton = '1';
                delete $scope.payment_transaction.addButton.value;
                delete $scope.payment_transaction.addButton;

                if ($scope.paymentAdded == '1') {

                    $scope.payment_transaction.addButton = '1';
                    delete $scope.payment_transaction.addButton.value;
                    delete $scope.payment_transaction.addButton;

                    var answer = confirm("You have already added a transaction, do you want to add another one?");

                    if (answer == true) {
                        $scope.payment_transaction.addButton = '1';
                        delete $scope.payment_transaction.addButton.value;
                        delete $scope.payment_transaction.addButton;

                    }
                    else {
                        console.log("You cancelled add transaction.");
                        $scope.payment_transaction.addButton = 1;
                        return "";
                    }
                }
                else {
                    $scope.payment_transaction.addButton = '1';
                    delete $scope.payment_transaction.addButton.value;
                    delete $scope.payment_transaction.addButton;


                }

                $scope.a = [];

                $scope.a = Transaction;

                $scope.a.Date = dateInputService.dateInputDayService($scope.payment_transaction.Date, 0);

                if (Date == null) {

                    confirm('Enter the date that this transaction was received');
                    return "";
                }

                if($scope.payment_transaction.cardDetails.Recurring_Payment_Amount){

                    console.log('Recurring Payment Amount >>>>>>>>> Amount not needed');

                }else{

                    if ($scope.a.Amount == null || $scope.a.Amount == 0 || $scope.a.Amount == "") {

                        confirm('Enter an amount');
                        return "";
                    }


                }



                $scope.payment_transaction.tenancies = {};
                $scope.payment_transaction.tenancies.Tenancy_ID = null;
                if (!$scope.payment_transaction.tenancies.Tenancy_ID) {
                    console.log("null: " + $scope.payment_transaction.tenancies.Tenancy_ID);
                    $scope.payment_transaction.tenancies.Tenancy_ID = 0;
                } else {
                    console.log("not null: " + $scope.payment_transaction.tenancies.Tenancy_ID);
                }

                $http.get("api/tenancy/" + $scope.payment_transaction.tenancies.Tenancy_ID + "/" + $rootScope.decodedToken.Account_Holder_ID + "/" + $rootScope.decodedToken.Clouderty_System_Administrator).success(function (response) {

                    $scope.b = [];
                    $scope.b = response;
                    $scope.a.Cheque_processed = 0;
                    console.log(response);
                    console.log("response >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> " + response);
                    if ($scope.a.Payment_Transaction_Type == "CHEQUE") {
                        $scope.a.Cheque = 1;
                    } else {
                        $scope.a.Cheque = 0;
                    }
                    $scope.a.Received = 0;
                    $scope.a.Business_ID = 1;
                    $scope.a.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                    $scope.a.User_ID = $rootScope.decodedToken.id;
                    $scope.a.Date_Received = $scope.DateToday;
                    if (!$scope.payment_transaction.Notes) {
                        $scope.payment_transaction.Notes = "None"
                    } else {

                    }

                    console.log($scope.payment_transaction.Date);

                    var answer = confirm('Are you sure you want to add a transaction?');
                    delete $scope.payment_transaction.addButton;
                    $scope.payment_transaction.addButton = '';
                    delete $scope.payment_transaction.addButton.value;

                    if (answer == true) {

                        console.log("Add payment transaction.");


                        if (($scope.payment_transaction.Payment_Transaction_Type_ID == "2") || ($scope.payment_transaction.Payment_Transaction_Type_ID == "9")) {

                            $scope.payment_transaction.Full_Name = $scope.payment_transaction.cardDetails.Full_Name;
                            $scope.payment_transaction.Card_Number = $scope.payment_transaction.cardDetails.Card_Number;
                            $scope.payment_transaction.Expiry = $scope.payment_transaction.cardDetails.Expiry;



                            //Check if this is recurrring payment setup and if it is make the normal payment £0
                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                //setup recurring payment in external  tenancy agreement
                                console.log("set addTransaction variables: $scope.stateTenancyAgreementExternal");

                                console.log("$scope.payment.obj.Monthly_rental_price: " + $scope.payment.obj.Monthly_rental_price);
                                $scope.payment_transaction.Payment_Transaction_Type_ID = '9';
                                $scope.payment_transaction.Date = '2099-12-31';

                                //$scope.payment_transaction.Recurring_Payment_Amount = 0.02;
                                $scope.payment_transaction.Recurring_Payment_Amount = $scope.payment.obj.Monthly_rental_price;
                                //$scope.payment_transaction.Amount = 0.05; //as a temporary measure to make the system work, 1p needs to be deducted to setup the RP
                                $scope.payment_transaction.Amount = $scope.payment.obj.Monthly_rental_price;
                                //$scope.payment_transaction.Card_Amount = 0.04;
                                $scope.payment_transaction.Card_Amount = $scope.payment.obj.Monthly_rental_price;
                                $scope.payment_transaction.addButton = "1";
                            }
                            else if($scope.payment_transaction.Payment_Transaction_Type_ID == 9){
                                console.log("set addTransaction variables: $scope.stateTenancyAgreementExternal: $scope.payment_transaction.Payment_Transaction_Type_ID == 9 ");
                                $scope.payment_transaction.Date = '2099-12-31';
                                $scope.payment_transaction.Card_Amount = 0.01;
                                $scope.payment_transaction.Amount = 0.01; //as a temporary measure to make the system work, 1p needs to be deducted to setup the RP
                                //$scope.payment_transaction.Card_Amount = $scope.payment.obj.Monthly_rental_price;;
                                //$scope.payment_transaction.Amount = $scope.payment.obj.Monthly_rental_price;; //as a temporary measure to make the system work, 1p needs to be deducted to setup the RP


                            }
                            else{
                                console.log("set addTransaction variables: $scope.stateTenancyAgreementExternal: standard");
                                $scope.payment_transaction.Card_Amount = $scope.payment_transaction.Amount;
                            }

                            $scope.payment_transaction.CVC = $scope.payment_transaction.cardDetails.CVC;
                            //$scope.payment_transaction.CVC = "";

                            if($scope.payment_transaction.cardDetails.Recurring_Payment_Amount){

                                $scope.payment_transaction.Recurring_Payment_Amount = $scope.payment_transaction.cardDetails.Recurring_Payment_Amount;
                                console.log("xxxxxxxxxxxxxx - recurring payment amount added manually >>> " +  $scope.payment_transaction.Recurring_Payment_Amount);

                            }else{

                                //console.log("xxxxxxxxxxxxxx - $scope.$parent.obj.Rental_Amount >>> " +  $scope.$parent.obj.Rental_Amount);

                                if($scope.payment.obj.Monthly_rental_price){

                                    $scope.payment_transaction.Recurring_Payment_Amount = $scope.payment.obj.Monthly_rental_price;

                                }

                                console.log("xxxxxxxxxxxxxx - no recurring payment amount added manually >>> " +  $scope.payment_transaction.Recurring_Payment_Amount);

                            }
                            $scope.payment_transaction.Recurring_Payment_Frequency = $scope.payment_transaction.cardDetails.Recurring_Payment_Frequency;
                            $scope.payment_transaction.Recurring_Payment = $scope.payment_transaction.cardDetails.Recurring_Payment;
                            //$scope.payment_transaction.Recurring_Payment_Date = $scope.payment_transaction.cardDetails.Recurring_Payment_Date;
                            $scope.payment_transaction.Recurring_Payment_Date = dateInputService.dateInputMonthService($scope.payment_transaction.cardDetails.Recurring_Payment_Date, 0);

                            //console.log("cardDetails load card number: " + $scope.payment_transaction.Card_Number);

                        }

                        $scope.paymentTransactionsData = $scope.payment_transaction;
                        $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

                        //$scope.paymentTransactionsData.Status = '4';
                        //$scope.paymentTransactionsFunction(1);
                        console.log($scope.payment_transaction);
                        $scope.addPaymentTransactions();



                    } else {
                        console.log("You cancelled add transaction.");
                        $scope.payment_transaction.addButton = 1;
                        return;
                    }


                })

                //}

                $scope.paymentAdded = '1';

            }

            $scope.paymentCategoryDescription = function (categoryID) {
                apiService.getPaymentTransactionCategory(categoryID).then(
                    function (response) {

                        $scope.payment_transaction.Payment_Category = response.data.Description;
                        console.log("API getPaymentTransactionCategory: " + response.data.Description);
                    },
                    function () {
                        console.log("Where in");
                        console.log("There was an error")
                    }
                )
            }

            $scope.paymentTransactionTypeDescription = function (paymentTypeID) {
                apiService.getPaymentTransactionType(paymentTypeID).then(
                    function (response) {
                        $scope.payment_transaction.Payment_Transaction_Type = response.data.Description;
                        console.log("API paymentTransactionTypeDescription: " + response.data.Description);
                    },
                    function () {
                        console.log("Where in");
                        console.log("There was an error")
                    }
                )
            }

            $scope.createPaymentIDBusiness = function () {
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref;
                $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref;
            }

            $scope.createPaymentIDProperty = function () {
                console.log("createPaymentIDProperty");
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name;
                $scope.payment_transaction.Description = $scope.payment_transaction.Property_name;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name;

            }

            $scope.createPaymentIDPropertyUnit = function () {
                console.log("createPaymentIDPropertyUnit");
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + $scope.unitText;
                $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + $scope.unitText;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + $scope.unitText;

            }

            $scope.createPaymentIDPaymentTypeCustomer = function () {

                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {
                    console.log("outside createPaymentIDPaymentType Property ");
                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        console.log("inside createPaymentIDPaymentType Property ");
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        console.log("createPaymentIDPaymentType Property");
                    }
                }
            }

            $scope.createPaymentIDPaymentTypePropertyBusiness = function () {
                //this does property and business
                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        if($scope.payment_transaction.customer.First_name){
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                            $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        }else{
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_Description;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                            $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        }
                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {
                    console.log("outside createPaymentIDPaymentType Property");
                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        console.log("inside createPaymentIDPaymentType Property");
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        console.log("createPaymentIDPaymentType Property");
                    }
                }
            }

            $scope.createPaymentIDPaymentTypeProperty = function () {

                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {


                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;


                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {
                    console.log("outside createPaymentIDPaymentType Property ");
                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        console.log("inside createPaymentIDPaymentType Property ");
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        if($rootScope.tenancy_id){
                            console.log("$scope.payment_transaction.Receipt_Name: ooooooooooooooooo " + $scope.payment_transaction.Receipt_Name);
                            console.log("$scope.payment.obj.Property_name: ooooooooooooooooo " + $scope.payment.obj.Property_name);
                            console.log("$scope.payment.obj.Unit_number: ooooooooooooooooo " + $scope.payment.obj.Unit_number);
                            $scope.payment_transaction.Receipt_Name = $rootScope.payment_transaction.Receipt_Name  + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        }else{
                            console.log("$scope.payment_transaction.Receipt_Name: ooooooooooooooooo " + $scope.payment_transaction.Receipt_Name);
                            //$scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        }
                        console.log("createPaymentIDPaymentType Property");
                    }
                }
            }

            $scope.createPaymentIDPaymentType = function () {

                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type;
                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        console.log("createPaymentIDPaymentType Property");
                    }
                }
            }

            $scope.createPaymentIDPaymentTypeUnit = function () {
                console.log("createPaymentIDPaymentTypeUnit Property");
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

            }

            $scope.createPaymentIDPaymentTypeCategory = function () {


                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {

                        if($scope.payment_transaction.customer.First_name){
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;
                            $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;

                        }else{
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type_Description + $scope.payment_transaction.Payment_Transaction_Category_Description;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;
                            $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type_Description + " Receipt - " + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description;

                        }

                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {
                    console.log("createPaymentIDPaymentTypeUnit Property");
                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;

                        console.log("createPaymentIDPaymentTypeCategory Property");
                    }
                }


                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

            }

            $scope.createPaymentIDPaymentTypeCategoryOther = function () {

                if ($scope.payment_transaction.Transaction_Type == "Business") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type + $scope.payment_transaction.Other;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type + " - " + $scope.payment_transaction.Other;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type + " Receipt - " + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other;

                        console.log("createPaymentIDPaymentType Business");

                    }
                }
                else if ($scope.payment_transaction.Transaction_Type == "Property") {

                    if (!$scope.payment_transaction.Transaction_Type) {
                        console.log("Select a transaction type");
                    }
                    else {
                        $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.unitText + $scope.payment_transaction.Payment_Transaction_Type + $scope.payment_transaction.Other;
                        $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + " " + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type + " - " + $scope.payment_transaction.Other;
                        $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Payment_Transaction_Type + " Receipt - " + $scope.payment_transaction.Property_name + " " + $scope.unitText + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other;
                        console.log("createPaymentIDPaymentType Property");
                    }
                }


                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

            }

            $scope.createPaymentIDPaymentTypeCategoryDate = function () {

                var dateFilitered = $filter('date')($scope.payment_transaction.Date, 'yyyy');
                var dateFiliteredSpace = dateFilitered + " " + $filter('date')($scope.payment_transaction.Date, 'MMMM');
                dateFilitered = dateFilitered + $filter('date')($scope.payment_transaction.Date, 'MMMM');


                if ($scope.payment_transaction.Other) {

                    $scope.payment_transaction.addButton = 1;
                    if ($scope.payment_transaction.Transaction_Type == "Business") {

                        if (!$scope.payment_transaction.Transaction_Type) {
                            console.log("Select a transaction type");
                        }
                        else {
                            if($scope.payment_transaction.customer.First_name){

                                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Other + dateFilitered;
                                $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Other + dateFilitered;
                                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Other + dateFilitered;


                            }else{

                                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type + $scope.payment_transaction.Other + dateFilitered;
                                $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type + " - " + $scope.payment_transaction.Other + " - " + dateFilitered;
                                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " Receipt - " + $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Other + " - " + dateFiliteredSpace;

                            }

                            console.log("createPaymentIDPaymentType Business");

                        }
                    }
                    else if ($scope.payment_transaction.Transaction_Type == "Property") {

                        if (!$scope.payment_transaction.Transaction_Type) {
                            console.log("Select a transaction type");
                        }
                        else {
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.unitText + $scope.payment_transaction.Payment_Transaction_Type_Description + $scope.payment_transaction.Other + dateFilitered;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + " " + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + $scope.payment_transaction.Other + " - " + dateFilitered;
                            $scope.payment_transaction.Receipt_Name = "Receipt - " + $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Other + " - " + dateFiliteredSpace;
                            console.log("createPaymentIDPaymentType Property");
                        }
                    }


                    //$scope.payment_transaction.Description = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Unit_number  + $scope.payment_transaction.Payment_Transaction_Type + " - " + " - " + $scope.payment_transaction.Payment_Category  + " - " + dateFilitered;

                    //$scope.payment_transaction.Payment_ID = $scope.payment_transaction.Bank_ref + $scope.payment_transaction.Payment_Transaction_Type + $scope.payment_transaction.Payment_Category + dateFilitered;

                }
                else {

                    if ($scope.payment_transaction.Transaction_Type == "Business") {

                        if (!$scope.payment_transaction.Transaction_Type) {
                            console.log("Select a transaction type");
                        }
                        else {

                            if($scope.payment_transaction.customer.First_name){
                                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;
                                $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;
                                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.customer.First_name + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;


                            }else{

                                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Business_Bank_ref + $scope.payment_transaction.Payment_Transaction_Type + $scope.payment_transaction.Payment_Category + dateFilitered;
                                $scope.payment_transaction.Description = $scope.payment_transaction.Business_Bank_ref + " - " + $scope.payment_transaction.Payment_Transaction_Type + " - " + $scope.payment_transaction.Payment_Category + " - " + dateFilitered;
                                $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Business_Bank_ref + " Receipt - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;

                            }
                            console.log("createPaymentIDPaymentType Business ELSE");

                        }
                    }
                    else if ($scope.payment_transaction.Transaction_Type == "Property") {

                        if (!$scope.payment_transaction.Transaction_Type) {
                            console.log("Select a transaction type ELSE");
                        }
                        else {
                            $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;
                            $scope.payment_transaction.Description = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;
                            $scope.payment_transaction.Receipt_Name = $scope.payment_transaction.Property_name + $scope.unitText + " - " + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description + " - " + dateFiliteredSpace;
                            console.log("createPaymentIDPaymentType Property");
                        }
                    }

                }


                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.split(' ').join('');
                $scope.payment_transaction.Payment_ID = $scope.payment_transaction.Payment_ID.toUpperCase();

            }

            $scope.openMailSender = function (emailId, subject, message, property) {

                $scope.a = emailService.openEmailSender(emailId, subject, message, property);

            };

            $scope.getPaymentTransactionCategoriesAll = function () {
                console.log("$scope.getPaymentTransactionCategories");
                var q42 = $q.defer();
                apiService.getPaymentTransactionCategories().then(
                    function (response) {
                        console.log("apiService getPaymentTransactionCategories is a success 0");
                        $scope.paymentTransactionCategories = response.data.data;
                        console.log("apiService getPaymentTransactionCategories is a success 1");
                        console.log($scope.paymentTransactionCategories);
                        q42.resolve(response);
                    },
                    function () {
                        console.log("There was an error in $scope.getPaymentTransactionCategoriesAll")
                    }
                )
                return q42.promise;
            }

            $scope.getCategories = function () {
                $scope.payment_transaction.Payment_Transaction_Category_ID = $scope.payment_transaction.paymentTransactionCategories.Payment_Transaction_Category_ID;
                $scope.payment_transaction.Payment_Transaction_Category_Description = $scope.payment_transaction.paymentTransactionCategories.Description;
                console.log("Payment_Transaction_Category_ID: " + $scope.payment_transaction.Payment_Transaction_Category_ID);
                console.log("Payment_Transaction_Category_Description: " + $scope.payment_transaction.Payment_Transaction_Category_Description);
                $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;
                //$scope.unitText = " U " + $rootScope.property.Unit_number;
                $scope.createPaymentIDPaymentTypeCategory();
            }

            $scope.getPaymentTypes = function () {
                $scope.payment_transaction.Payment_Transaction_Type_ID = $scope.payment_transaction.paymentTransactionTypes.Payment_Transaction_Type_ID;
                $scope.payment_transaction.Payment_Transaction_Type_Description = $scope.payment_transaction.paymentTransactionTypes.Description;
                console.log("Payment_Transaction_Type_ID: " + $scope.payment_transaction.Payment_Transaction_Type_ID);
                console.log("Payment_Transaction_Type_Description: " + $scope.payment_transaction.Payment_Transaction_Type_Description);
                console.log("$rootScope.property.Property_name : " + $rootScope.property.Property_name);
                console.log("$rootScope.property.Unit_number : " + $rootScope.property.Unit_number);

                $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;
                //$scope.unitText = " U " + $rootScope.property.Unit_number;


                $scope.createPaymentIDPaymentTypePropertyBusiness();
            }

            $scope.getPaymentTransactionTypes = function () {
                console.log("$scope.getPaymentTransactionTypes");
                var q41 = $q.defer();
                apiService.getPaymentTransactionTypes().then(
                    function (response) {
                        $scope.paymentTransactionTypes = response.data.data;

                        console.log("$rootScope.property.Property_name dddddddddddddddddddd : " + $rootScope.property.Property_name);
                        console.log("$rootScope.property.Unit_number ddddddddddddddddddd: " + $rootScope.property.Unit_number);

                        $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                        $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;
                        //$scope.unitText = " U " + $rootScope.property.Unit_number;

                        q41.resolve(response);
                        console.log($scope.payment_transaction.Payment_Transaction_Type_ID);
                    },
                    function () {
                        console.log("There was an error $scope.getPaymentTransactionTypes")
                    }
                )
                return q41.promise;
            }

            $scope.addPaymentTransactions = function () {
                console.log("Some test 1: Add transaction");
                //if (confirm("Do you want to add this transaction?")) {
                console.log("Add transaction");
                var q = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1");

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - paymentTransactionsFunction");

                        $scope.paymentBlockSpinner = '1';
                        $scope.pageBlock = '0';
                        $window.scrollTo(0, 0);

                        $scope.paymentTransactionsData.Status = '4';
                        return $scope.paymentTransactionsFunction("*", "4");
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A3");
                        if ($scope.payment_transaction.Transaction_Type == "Business") {

                            if($scope.payment_transaction.customer.First_name){
                                $scope.payment_transaction.Business_Bank_refCust = $scope.payment_transaction.Business_Bank_ref + " | " + $scope.payment_transaction.Business_Bank_ref;
                            }else{
                                $scope.payment_transaction.Business_Bank_refCust = $scope.payment_transaction.Business_Bank_ref;
                            }

                            if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1' && $scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Payment Successful " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                var message = "Payment Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "\n\n" +
                                    "Payment ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Business: " + $scope.payment_transaction.Business_Bank_ref + "<br />" +
                                    "Customer name: " + $scope.payment_transaction.customer.First_name + "<br />" +
                                    "Date received: " + $scope.payment_transaction.Date + "<br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";



                            }else{

                                if($scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                    //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                                    console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                    var from_email = "Clouderty | Failed Payment " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                    var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                    var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                        "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                        "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                        "Business: " + $scope.payment_transaction.Business_Bank_ref + "<br />" +
                                        "Customer name: " + $scope.payment_transaction.customer.First_name + "<br />" +
                                        "Date received: " + $scope.payment_transaction.Date + "<br />" +
                                        "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br />" +
                                        "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                        "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br />" +
                                        "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";

                                }else{

                                }


                            }



                            //var to_email = $rootScope.decodedToken.Accounts_Email;



                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            console.log("cccccccccccccccccccccccccccccccccccccccccccccc  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                            if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){

                                console.log("sssssssssssssssssssssssssssssssssssssssssss  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Recurring Payment Setup " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Recurring_Payment_Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "\n\n" +
                                    "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Business: " + $scope.payment_transaction.Business_Bank_ref + "<br />" +
                                    "Customer name: " + $scope.payment_transaction.customer.First_name + "<br />" +
                                    "Start date: " + $scope.payment_transaction.Date + "<br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Recurring_Payment_Amount + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";

                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                            }





                            $scope.payment_transaction.cardDetails.Full_Name = "";
                            $scope.payment_transaction.cardDetails.Card_Number = "";
                            $scope.payment_transaction.cardDetails.Expiry = "";
                            $scope.payment_transaction.Amount = "";
                            $scope.payment_transaction.cardDetails.CVC = "";
                            //$scope.payment_transaction.cardDetails.Recurring_Payment = "";


                            //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            //$('.data').hide();

                        }
                        else if ($scope.payment_transaction.Transaction_Type == "Property") {

                            console.log($scope.payment_transaction);

                            console.log("$rootScope.tenancy_id" + $rootScope.tenancy_id);
                            if ($rootScope.tenancy_id) {
                                console.log("$rootScope.property.Property_name" + $rootScope.property.Property_name);
                                $scope.paymentTransactionsData.Address_1 =  $rootScope.property.Property_name;
                            }

                            console.log("Before $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1' && $scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Payment Successful " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description + " | " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Property_name;
                                var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                    "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Date received: " + $scope.payment_transaction.Date + "<br /><br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br /><br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />" +
                                    "Address: " + $scope.payment_transaction.Property_name + "<br />";

                                $scope.paymentBlockSpinner = '0';
                            }
                            else if($state.current.name == $scope.stateTenancyAgreementExternal){

                                console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Payment Successful " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description + " | " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Property_name;
                                var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                    "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Date received: " + $scope.payment_transaction.Date + "<br /><br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br /><br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />" +
                                    "Address: " + $scope.payment_transaction.Property_name + "<br />";

                                $scope.paymentBlockSpinner = '0';
                            }else{

                                if($scope.payment_transaction.Payment_Transaction_Type_ID != 9){

                                    //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                                    console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                    var from_email = "Clouderty | Failed Payment " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
                                    var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                    var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                        "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                        "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                        "Date received: " + $scope.payment_transaction.Date + "<br /><br />" +
                                        "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br /><br />" +
                                        "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                        "Amount:" + " &#163;" + $scope.payment_transaction.Amount + "<br />" +
                                        "Notes: " + $scope.payment_transaction.Notes + "<br /><br />" +
                                        "Address: " + $scope.payment_transaction.Property_name + "<br />";

                                }else{

                                }

                                $scope.paymentBlockSpinner = '0';
                            }

                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            to_email = $rootScope.decodedToken.Notifications_Email;
                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                            console.log("cccccccccccccccccccccccccccccccccccccccccccccc  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                            if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){

                                console.log("sssssssssssssssssssssssssssssssssssssssssss  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);


                                if($scope.payment_transaction.Recurring_Payment_Amount){

                                    $scope.payment_transaction.amountEmail = $scope.payment_transaction.Recurring_Payment_Amount;

                                }else{

                                    $scope.payment_transaction.amountEmail = $scope.payment_transaction.Amount;

                                }

                                var from_email = "Clouderty | Recurring Payment Setup " + $scope.payment_transaction.Payment_Transactions_Recurring_ID + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.amountEmail + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description + " | " + $scope.payment_transaction.Payment_Transactions_Recurring_ID + " | " + $scope.payment_transaction.Property_name;
                                var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transactions_Recurring_ID + "<br /><br />" +
                                    "Order  ID: " + $scope.payment_transaction.Payment_Transactions_Recurring_Order_ID + "<br /><br />" +
                                    "Setup by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Date of first recurring payment: " + $scope.payment_transaction.First_Payment + "<br /><br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br /><br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Recurring Payment Amount:" + " " + $filter('currency')($scope.payment_transaction.Recurring_Payment_Amount, '£') + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />" +
                                    "Address: " + $scope.payment_transaction.Property_name + "<br />";

                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                                $scope.payment_transaction.message = message;
                                $scope.paymentBlockSpinner = '0';
                            }

                            $scope.payment_transaction.cardDetails.Full_Name = "";
                            $scope.payment_transaction.cardDetails.Card_Number = "";
                            $scope.payment_transaction.cardDetails.Expiry = "";
                            $scope.payment_transaction.Amount = "";
                            $scope.payment_transaction.cardDetails.CVC = "";
                            //$scope.payment_transaction.cardDetails.Recurring_Payment = "";

                            //$('.data').hide();
                            //$scope.payment_transaction.cardDetails.Card_Number = "";
                            //$scope.payment_transaction.cardDetails.CVC = "";
                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            //$('.paymentBlock').show();


                            if($state.current.name == $scope.stateTenancyAgreementExternal){
                                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                                $scope.$parent.tenancyAgreementBlock = '1';
                                $scope.tenancyAgreementBlock = '1';

                                //$scope.gotoAnchor('tenancySignAnchor');

                            }

                        }

                        $scope.message = message;
                        if($state.current.name == $scope.stateTenancyView){

                            //add details to a note
                            if ($rootScope.tenancy_id) {

                                $scope.postNote();
                                //notePostService.postNote($scope.message);
                                //$scope.postNote($scope.message);
                            }
                        }



                        $window.scrollTo(0, 0);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');



                        if($state.current.name == $scope.stateTenancyAgreementExternal) {
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            console.log("check if this is a tenancy signup rent payment");
                            console.log("$scope.scrollTo: >>>>>>>>>>> scrollSignature");
                            $scope.scrollTo('scrollSignature');

                            //$scope.gotoAnchor('tenancySignAnchor');

                        }
                        $scope.myValue = data;

                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
                //}

            };

            $scope.getPaymentTransaction = function () {
                console.log("function getPaymentTransaction ");

                apiService.getPaymentTransaction($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                    function (response) {

                        $scope.payment_transaction = response.data.data;
                        $scope.payment_transaction.Date = dateInputService.dateInputDayService(response.data.data.Date, 0);
                        $scope.payment_transaction.Transaction_Type = "Property";
                        //$scope.payment_transaction.selectedProperties = {};
                        //$scope.payment_transaction.selectedProperties.Units = [];
                        $scope.selectPropertyEdit($scope.payment_transaction.Property_ID);
                        $scope.search_property = "";
                        $scope.hidePropertySearch = true;
                        $scope.payment_transaction.paymentTransactionCategories.Payment_Transaction_Category_ID = $scope.payment_transaction.Payment_Transaction_Category_ID;

                        return $scope.payment_transaction;
                    },
                    function () {
                        console.log("There was an error apiService.searchTenant")
                    }
                )
            }

            $scope.initialPageLoad = function() {
                console.log("inside function initialPageLoad");
                var qinitialPageLoad66 = $q.defer();

                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                if($state.current.name == $scope.statePaymentTransactionAdd){
                    if ($stateParams.id == null) {
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        console.log("$stateParams.id == null");

                        $scope.New_Record = true;
                        qinitialPageLoad66.resolve($scope.getGlobalPromise());
                    }
                    else {
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        console.log("$rootScope.tenancy_id doesn't  exist <<<<<<<<<<<<<<<<< " + $rootScope.tenancy_id);
                        $scope.New_Record = false;
                        console.log("$stateParams.id not null");
                        $scope.obj = $scope.getPaymentTransaction();

                    }

                    if ($stateParams.tenancyID != null) {

                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                        if ($state.current.name == $scope.statePaymentTransactionAdd) {

                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                        }
                    }



                }
                else if($rootScope.paymentTransactionAddEditDorective == 1){

                    if ($stateParams.id == null) {
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        console.log("$stateParams.id == null");

                        $scope.New_Record = true;
                        qinitialPageLoad66.resolve($scope.getGlobalPromise());
                    }
                    else {
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        console.log("$rootScope.tenancy_id doesn't  exist <<<<<<<<<<<<<<<<< " + $rootScope.tenancy_id);
                        $scope.New_Record = false;
                        console.log("$stateParams.id not null");
                        $scope.obj = $scope.getPaymentTransaction();

                    }

                    if ($stateParams.tenancyID != null) {

                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                        if ($state.current.name == $scope.statePaymentTransactionAdd) {

                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                        }
                    }

                }
                else if($state.current.name == $scope.stateTenancyAgreementExternal){

                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                    //console.log("$rootScope.paymentTransactionInputs.Payment_Transaction_Fixed: " + $rootScope.paymentTransactionInputs.Payment_Transaction_Fixed);
                    if ($rootScope.tenancy_id) {
                        console.log("$rootScope.tenancy_id exists " + $rootScope.tenancy_id);
                        //status for adding a payment from the view tenancy screen

                        console.log("tenancy payment transaction");
                        $scope.paymentTransactionsData.Status = '4';
                        $scope.New_Record = true;
                        $scope.transactionTypeBlock = '0';
                        console.log("tenancy payment transaction - Property");
                        $scope.payment_transaction.Transaction_Type = 'Property';

                        //console.log("tenancy payment transaction - $rootScope.Property_name: " + $rootScope.Property_name );
                        $scope.payment_transaction.Tenancy_ID = $rootScope.tenancy_id;

                        $scope.payment_transaction.Payment_Transaction_Type_ID = '2';
                        $scope.payment_transaction.Payment_Transaction_Type_Description = 'Clouderty Online Payment';
                        $scope.payment_transaction.Payment_Transaction_Category_ID = '1';
                        $scope.payment_transaction.Payment_Transaction_Category_Description = 'Rent';
                        $scope.payment_transaction.Amount = $scope.payment.obj.Monthly_rental_price;
                        //$scope.payment_transaction.Recurring_Payment_Amount = $scope.$parent.obj.Monthly_rental_price;
                        $scope.payment_transaction.Recurring_Payment_Amount = $scope.payment.obj.Monthly_rental_price;
                        $scope.payment_transaction.Date = new Date();
                        $scope.categoryBlock = '1';
                        $scope.paymentBlock = '1';
                        $scope.amountBlock = '1';
                        $scope.datetBlock = '1';


                        console.log($scope.payment_transaction);

                        /*
                        console.log("xxxxxxxxxxxxxx - $rootScope.Unit_number: " + $rootScope.property.Unit_number);
                        console.log("xxxxxxxxxxxxxx - $rootScope.Property_name: " + $rootScope.property.Property_name);
                        console.log("xxxxxxxxxxxxxx - $rootScope.tenancy_id: " + $rootScope.tenancy_id);

                        $scope.payment_transaction.Property_name = $rootScope.property.Property_name;
                        $scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;
                        $scope.unitText = " U " + $rootScope.property.Unit_number;


                        */

                        //$scope.payment_transaction.Receipt_Name = $rootScope.payment_transaction.Receipt_Name;

                        //$scope.createPaymentIDPaymentTypePropertyBusiness();

                        console.log("tenancy payment transaction - $rootScope.Property_name: " + $rootScope.Property_name);

                        qinitialPageLoad66.resolve($scope.selectProperty($rootScope.Property_ID, $scope.payment_transaction.Property_name, 'hjhjhjh', 'mkmkmkmk'));


                    }
                    else {

                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        console.log("$rootScope.tenancy_id doesn't  exist <<<<<<<<<<<<<<<<< " + $rootScope.tenancy_id);
                        $scope.New_Record = false;
                        console.log("$stateParams.id not null");
                        $scope.obj = $scope.getPaymentTransaction();

                    }
                }
                else if($state.current.name == $scope.stateTenancyView || $state.current.name == $scope.stateProcessAccountView){

                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                    console.log("$rootScope.tenancy_id exists " + $rootScope.tenancy_id);
                    //status for adding a payment from the view tenancy screen

                    console.log("tenancy payment transaction");
                    $scope.paymentTransactionsData.Status = '4';
                    $scope.New_Record = true;
                    $scope.transactionTypeBlock = '0';
                    console.log("tenancy payment transaction - Property");
                    $scope.payment_transaction.Transaction_Type = 'Property';

                    //console.log("tenancy payment transaction - $rootScope.Property_name: " + $rootScope.Property_name );
                    $scope.payment_transaction.Tenancy_ID = $rootScope.tenancy_id;
                    console.log("tenancy payment transaction - $rootScope.Property_name: " + $rootScope.Property_name);

                    qinitialPageLoad66.resolve($scope.selectProperty($rootScope.Property_ID, $scope.payment_transaction.Property_name, 'hjhjhjh', 'mkmkmkmk'));

                }
                else {

                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                }
                $scope.viewPage = 1;
                return qinitialPageLoad66.promise;


            }

            $scope.tenanciesFunction = function(id, status) {


                console.log("function tenanciesFunction zzzzzzzzzzzzzzzzzzzzzzzzzzzz");

                $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
                $scope.tenancies.pageno = $scope.pageno;
                $scope.tenancies.Tenancy_ID = id;

                if(status == '25'){

                }else{

                    $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
                    $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
                    $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
                    $scope.tenancies.Licence_Key = $stateParams.licenseKey;
                }


                $scope.tenancies.Status = status;
                $scope.tenancies.Payment_Transaction_Type_ID = "0";
                $scope.tenancies.paymentTransactionInputs = "0";


                if($scope.tenancies.Tenancy_ID){
                    //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
                }else{
                    $scope.tenancies.Tenancy_ID = "*";
                }


                if($scope.search){
                    $scope.tenancies.search = $scope.search;
                }else{
                    $scope.tenancies.search = "*";
                }

                //$scope.obj.search = "*";

                var qtenanciesFunction22 = $q.defer();
                //console.log($scope.paymentTransactionsData);
                console.log("trying to access apiService.tenancies");
                console.log($scope.tenancies);
                apiService.tenancies(id, $scope.tenancies).then(
                    function (response) {
                        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
                        /*
                        $scope.currentPage = $scope.pageno;
                        $scope.totalCount = response.data.totalCount;
                        $scope.paginationData = response.data.data;
                        $scope.rentTotal = response.data.rentTotal;
                        $scope.totalTaskCount = response.data.totalCount;
                        */

                        if(status == '6') {

                            $scope.payment.obj = {};
                            $scope.payment.obj = response.data.data;

                            console.log('status = 6');
                            console.log(response);
                            console.log($scope.payment.obj.Tenancy_start_date);

                            /*if(response.data.Rent_Payment_ID_First){
                                console.log('for to anchor tenancySignAnchor');
                                $scope.gotoAnchor('tenancySignAnchor');
                            }*/
                        } else{

                            $rootScope.Property_ID = $scope.obj.data.Property_ID;
                            $rootScope.property.Property_name = $scope.obj.data.Property_name;
                            $rootScope.property.Unit_number = $scope.obj.data.Unit_number;

                            $scope.$parent.obj.Rental_Amount = response.data.Rental_Amount;
                            $scope.$parent.obj.Rent_Payment_ID_First = response.data.Rent_Payment_ID_First;
                            $rootScope.obj.Rent_Payment_ID_First = response.data.Rent_Payment_ID_First;
                            console.log("$rootScope.Unit_number: " + $rootScope.property.Unit_number);
                            console.log("$rootScope.Property_name: " + $rootScope.property.Property_name);
                            console.log("$rootScope.tenancy_id: " + $rootScope.tenancy_id);
                            console.log("response.data.Rent_Payment_ID_First: " + response.data.Rent_Payment_ID_First);
                            //$scope.unitText = " U " + $rootScope.property.Unit_number;

                            $rootScope.payment_transaction.Receipt_Name = $rootScope.property.Property_name + " " + $scope.unitText + " - ";
                            console.log("$rootScope.payment_transaction.Receipt_Name: " + $rootScope.payment_transaction.Receipt_Name);
                            $scope.payment_transaction.Receipt_Name = $rootScope.payment_transaction.Receipt_Name;

                            /*if(response.data.Rent_Payment_ID_First){

                                console.log('for to anchor tenancySignAnchor');
                                $scope.gotoAnchor('tenancySignAnchor');
                            }*/

                        }


                        console.log(response);
                        console.log("tenancies API");
                        qtenanciesFunction22.resolve(response);
                    },
                    function () {
                        console.log("API Service Error: tenancies")
                    }
                );

                if($scope.search == "*"){
                    $scope.search = "";
                }
                else{

                };

                return qtenanciesFunction22.promise;
            }

            $scope.postNote = function () {

                //console.log('data.NotesAdd: ' + data.NotesAdd);

                //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, tenancy_id, unit_number, email_signature, note_type_id);

                var date = new Date();
                date.setHours(date.getHours() + 1);

                $scope.note_loading = true;
                $scope.note.Process_Account_ID = 1;
                $scope.note.Tenancy_ID = 2071;
                $scope.note.User_ID = 1;
                $scope.note.Notes_Type = "Tenancy";
                $scope.note.Date_Entered = date;
                $scope.note.Note_Type_ID = 1;
                $scope.note.Processes_Linked_to_Account_ID = 0;
                //$scope.note.picFile = file;
                $scope.note.Payment_Plan = "";
                $scope.note.Note_Content = $scope.payment_transaction.message;
                //$scope.note.Note_Content = stringModifierService.replaceHTMLTags($scope.note.Note_Content);


                var qpostProcessNote = $q.defer();
                var myValue = 0;
                var promise = $scope.getGlobalPromise();

                promise
                    .then(function (data) {

                        console.log("A1a - postNote");

                        //noteService.postNote();
                        noteService.postNote($scope.note);
                        //postNoteTenantService.postNote(2071, "Email", "jhjhjhvjgvjgvg", "Tenancy");

                        return $scope.getGlobalPromise(data);
                    })//post note
                    .then(function (data) {

                        console.log("A1c");

                        /*
                        $scope.note_loading = false;
                        $scope.processes_account.Process_Account_ID = processAccountID;
                        $scope.processAccount = $scope.$parent.processData;
                        console.log($scope.processAccount);
                        $scope.notesInputsData.Tenancy_ID = $scope.processAccount.data.Tenancy_ID;
                        console.log("Tenancy ID: " + $scope.notesInputsData.Tenancy_ID);

                        //$scope.getNotes($scope.notesInputsData);
                        */
                        return $scope.getGlobalPromise(data);
                    })//set variables
                    .then(function (data) {
                        //send email and attachement
                        console.log("A4 - send email and attachement");


                        return $scope.getGlobalPromise(data);

                    })//send email
                    .then(function (data) {

                        console.log("A5 ");


                        return $scope.getGlobalPromise(data);
                    })//reset variables
                    .then(function (data) {
                            console.log('A7 notes $state.reload');
                            $state.reload('lettings-main-nav.lettings-side.lettings-view-tenancy.notes');
                            //$scope.paymentPlanModification == 0;

                            myValue = data;
                        }//reload notes
                        ,function (err) {
                            myValue = err;
                        })//end promise

                qpostProcessNote.resolve(promise);

                return qpostProcessNote.promise;
            }

            $scope.updateAmount = function(){
                console.log("in function updateAmount");

                if($scope.payment_transaction.Payment_Transaction_Type_ID == 9){
                    console.log("in function updateAmount");
                    $scope.payment_transaction.Amount = 0;
                    if(!$scope.payment_transaction.cardDetails.Recurring_Payment_Amount){

                        $scope.payment_transaction.Amount = null;
                    }
                }


            }

            $scope.updateDate = function(){
                console.log("in function updateDate");

                if($scope.payment_transaction.Payment_Transaction_Type_ID == 9){
                    console.log("in function updateDate");
                    $scope.payment_transaction.Date = "2099-12-31";
                }


            }

            $scope.reload = function () {
                console.log("state reloaded");
                //$window.location.reload();
                $state.go($state.current, {}, {reload: true});
                //$route.reload();
                /*$state.transitionTo($state.current, $stateParams, {
                    reload: true, inherit: false, notify: true
                });*/
            }

            $scope.scrollTo = function(element){
                scrollToService.scroll(element);
            }

            $scope.$watch('$rootScope.Property_name',function(){
                console.log("tenancy payment transaction - $rootScope.Property_name: " + $rootScope.Property_name);

            });

            $scope.gotoAnchor = function(x) {
                console.log("inside function gotoAnchor");
                var newHash = x;
                if ($location.hash() !== newHash) {
                    // set the $location.hash to `newHash` and
                    // $anchorScroll will automatically scroll to it
                    $location.hash(x);
                } else {
                    // call $anchorScroll() explicitly,
                    // since $location.hash hasn't changed
                    $anchorScroll();
                }
            };

            $scope.loadPaymentTransactionData = function () {
                console.log("inside function loadPaymentTransactionData");



                console.log("$stateParams.id: " + $stateParams.id);
                //$scope.$state = $state;
                var q = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A2");
                        $scope.getFrequencies();
                        return $scope.getPaymentTransactionCategoriesAll(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A3");

                        return $scope.getPaymentTransactionTypes(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A4");

                        return $scope.initialPageLoad(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A4 - tenanciesFunction");

                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        if($state.current.name == $scope.statePaymentTransactionAdd){

                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            if ($stateParams.id == null) {
                                console.log("$stateParams.id == null");
                                $scope.New_Record = true;
                            }
                            if($rootScope.tenancy_id){
                                $scope.tenanciesFunction($rootScope.tenancy_id, '20');
                            }

                        }
                        else if($rootScope.paymentTransactionAddEditDorective == 1){

                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            if ($stateParams.id == null) {
                                console.log("$stateParams.id == null");
                                $scope.New_Record = true;
                            }
                            if($rootScope.tenancy_id){
                                $scope.tenanciesFunction($rootScope.tenancy_id, '20');
                            }

                        }
                        else if($state.current.name == $scope.stateTenancyAgreementExternal){

                            console.log("inside stateTenancyAgreementExternal");

                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            $scope.recurringPaymentToggle = '1';
                            if ($stateParams.tenancyID == null) {

                                console.log("$stateParams.id == null");

                                $scope.New_Record = true;
                            }

                            console.log("$rootScope.tenancy_id: " + $rootScope.tenancy_id);

                            $scope.tenanciesFunction($rootScope.tenancy_id, "6");

                            //console.log("activate tenancySignAnchor");
                            //$scope.gotoAnchor('tenancySignAnchor');

                        }

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A5");
                        $scope.payment_transaction.Tenancy_ID = $rootScope.tenancy_id;
                        console.log("$scope.payment_transaction.Tenancy_ID: " + $scope.payment_transaction.Tenancy_ID);

                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                        if($state.current.name == $scope.statePaymentTransactionAdd) {
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            if ($scope.payment_transaction.Tenancy_ID) {
                                console.log("$scope.payment_transaction.Tenancy_ID: " + $scope.payment_transaction.Tenancy_ID);
                                $rootScope.property.Property_name = $scope.obj.data.Property_name;
                                $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                                console.log("$rootScope.property.Property_name: " + $rootScope.property.Property_name);
                            }
                        }else if($state.current.name == $scope.stateProcessAccountView) {

                            $scope.payment_transaction.Receipt_Name = $rootScope.property.Property_name + " U " + $rootScope.property.Unit_number;

                        }
                        else if($state.current.name == $scope.stateTenancyAgreementExternal) {
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
                            if ($rootScope.tenancy_id) {
                                console.log("variables relating to a tenancy agreement signup payment $rootScope.tenancy_id: " + $rootScope.tenancy_id);
                                console.log("$scope.$parent.obj.Monthly_rental_price: " + $scope.$parent.obj.Monthly_rental_price);
                                console.log("$scope.payment.obj.Monthly_rental_price: " + $scope.payment.obj.Monthly_rental_price);
                                $rootScope.property.Property_name = $rootScope.property.Property_name;
                                $rootScope.property.Unit_number = $rootScope.property.Unit_number;
                                $scope.payment_transaction.Receipt_Name = $rootScope.payment_transaction.Receipt_Name + $scope.payment_transaction.Payment_Transaction_Category_Description + " - " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                $scope.payment_transaction.cardDetails.Recurring_Payment = 'true';

                                console.log("$scope.payment.obj.Tenancy_start_date: " + $scope.payment.obj.Tenancy_start_date);
                                console.log("$scope.payment_transaction.cardDetails.Recurring_Payment_Date: " + $scope.payment_transaction.cardDetails.Recurring_Payment_Date);
                                $scope.payment_transaction.Amount =  $scope.payment.obj.Monthly_rental_price;
                                $scope.payment_transaction.Card_Amount = $scope.payment.obj.Monthly_rental_price;

                                $scope.payment_transaction.Recurring_Payment_Amount =  $scope.payment.obj.Monthly_rental_price;
                                $scope.payment_transaction.cardDetails.Recurring_Payment_Amount =  $scope.payment.obj.Monthly_rental_price;
                                $scope.payment_transaction.cardDetails.Recurring_Payment_Date =  dateInputService.dateInputMonthService($scope.payment.obj.Tenancy_start_date, 1);
                                //$scope.payment_transaction.cardDetails.Recurring_Payment_Date =  dateInputService.dateInputMonthService($scope.obj.data.Tenancy_start_date, 1);
                                console.log("$scope.payment_transaction.cardDetails.Recurring_Payment_Date: " + $scope.payment_transaction.cardDetails.Recurring_Payment_Date);
                                console.log("Tennacy start date: " + $scope.payment.obj.Tenancy_start_date);
                                console.log("$scope.payment_transaction.Property_name: " + $scope.payment_transaction.Property_name);
                                console.log("$scope.payment_transaction.Receipt_Name: " + $scope.payment_transaction.Receipt_Name);
                                //console.log("$rootScope.property.Property_name: " + $rootScope.property.Property_name);

                                $scope.recurringPaymentBlock = '1';
                                $scope.tenancyPaymentBlock = '1';
                                $scope.payment_transaction.addButton = "1";



                            }

                        }


                        console.log($scope.payment_transaction);
                        $scope.paymentBlockSpinner = '0';
                        $scope.pageBlock = '1';
                        return $scope.getGlobalPromise(data);


                    })
                    .then(function (data) {
                        console.log('A7a');
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);



                        return $scope.getGlobalPromise(data);

                        //$scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

            };

            $scope.loadPaymentTransactionData();









        }







    })



}]);