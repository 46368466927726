/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("processAccountViewCtrl", ["$scope", "$http", "$stateParams", "notePostService", "noteService", "Upload", "$rootScope", "emailService", "$window", "apiService", "$q", "postUserActivityService", "sendEmailInternalPostNote", "$state", "fileUploadService", "dateInputService", '$location', '$anchorScroll', '$state', '$timeout',  function ($scope, $http, $stateParams, notePostService, noteService, Upload, $rootScope, emailService, $window, apiService, $q, postUserActivityService, sendEmailInternalPostNote, $state, fileUploadService, dateInputService, $location, $anchorScroll, $state)  {
    ///['statesLoad', '$q', '$stateParams', 'apiService', 'auth', '$timeout', '$rootScope', '$state', function (statesLoad, $q, $stateParams, apiService, auth, $timeout, $rootScope, $state) {

    console.log("auth >>>>>>>");
    $scope.processAccountDataLoaded = 0;
    var auth;

    //$scope.$watch('processAccountDataLoaded', function (toVariable, fromVariable) {

    /*
    console.log("inside watch and obj is: " + auth);
    console.log("inside watch and fromVariable is: " + fromVariable);
    console.log("inside watch and toVariable is: " + toVariable);
    */

    //console.log("$scope.processAccount.data.Process_Account_ID: " + $scope.processAccount.data.Process_Account_ID);
    $(document).ready(function () {
        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })
    $('.data').hide();
    $('.maintenance-account-data').hide();
    $('.add-transactions-data').hide();

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 2000000;

    $scope.search = "";
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();
    $scope.property = [];
    $rootScope.property = {};
    $rootScope.property.Property_name = "";
    $rootScope.property.Unit_number = "";
    //$rootScope.tenancy_id = $stateParams.tenancyID;

    $scope.tenancies = [];
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.tenantsRentalTransactions = {};

    $scope.processes_account = {};
    $scope.processesAccountData = {};
    $scope.processes_account.User_ID = "";
    $scope.processes_account.Date_Completed = "";
    $scope.paymentPlanModification = 0;
    $scope.paymentTransactionsData = {};
    $scope.processLinkedToAccount = {};
    $scope.processesTotalCount = {};
    $scope.processLinkedToAccount.picFileProcessLinkedToAccount = [];
    $scope.countIndex = 0;
    $scope.tenanciesInput = {};
    $scope.maintenance = {};
    $scope.maintenanceData = {};
    $scope.maintenanceMaterialsLabourView = {};
    $scope.maintenanceMaterialsLabourViewCount = {};

    $scope.processData = {};
    $scope.obj = {};
    $scope.obj.note = {};
    $scope.obj.notes = {};
    $scope.notesData = [];
    $scope.notes = [];
    $scope.note = {};

    $scope.debts = {};
    $scope.tenancies = {}
    $rootScope.processAccountID = $stateParams.processAccountID;
    $scope.processes_account.Process_Account_ID = $stateParams.processAccountID;

    //load route variables

    $scope.processAccount = {};
    $scope.processesLinkedToAccount = {};
    $scope.processFilesLinked = {};
    $scope.processesTotalCount = {};

    /*
    $scope.processAccount = processAccount;
    $scope.processesLinkedToAccount = processAccount.processesLinkedToAccount;
    $scope.processFilesLinked = processAccount.processFilesLinked;
    $scope.processesTotalCount = processAccount.processesTotalCount;
    */
    $scope.propertiesData = {};
    $scope.properties = {};
    $scope.propertiesSelect = {};
    $scope.awardsData = {};
    $scope.awards = {};
    $scope.awards.awardsNomination = {};

    //console.log('processesTotalCount: ' + processAccount.processesTotalCount);
    console.log('$stateParams.processAccountID: ' + $stateParams.processAccountID);

    $scope.getGlobalPromise = function() {

        console.log("inside function getGlobalPromise");
        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

        /*
        $scope.DataLoad = function() {
            console.log("inside function DataLoad");

            var qdataLoad = $q.defer();
            //console.log("state auth1: " + $state.is('stateName'));
            //console.log("state auth2: " + $state.current.name);
            console.log("state auth2: " + 'lettings-main-nav.lettings-side.process-account-view.notes');
            qdataLoad.resolve(stateAuthCheckService.runAuthCheckRootScope('lettings-main-nav.lettings-side.process-account-view.notes'));
            return qdataLoad.promise;


        }
        */

        /*
        $scope.processAccountFunction = function(){

            console.log("inside function processAccount");



            var deferred4 = $q.defer();
            var promise = $scope.getGlobalPromise();

            //var processes_account = {};
            //var processesAccountData = {};

            $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.processes_account.User_Level_ID = $rootScope.decodedToken.User_Level_ID;
            $scope.processes_account.itemsPerPage = 20;
            $scope.processes_account.pageno = 1;
            $scope.processes_account.Status = 64;
            $scope.processes_account.Process_ID = '*';
            $scope.processes_account.Process_Account_ID = $stateParams.processAccountID;
            $scope.processes_account.search = "*";
            console.log($scope.processes_account);

            promise
                .then(function () {
                    console.log("A2");

                    return $scope.processesAccountFunction($stateParams.processAccountID, 64);



                })
                .then(function () {

                    return deferred4.promise;
                })
                .then(function (data) {
                        console.log('A7');
                        myValue = data;
                    },
                    function (err) {
                        myValue = err;
                    })

            return deferred4.promise;

        }
        */

    $scope.processesAccountFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        console.log("$scope.pageno" + $scope.pageno);
        $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.processes_account.id = $rootScope.decodedToken.id;
        $scope.processes_account.User_ID = $rootScope.decodedToken.id;
        $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
        $scope.processes_account.pageno = $scope.pageno;
        $scope.processes_account.Status = status;
        $scope.processes_account.Process_ID = id;

        if($scope.processes_account.Process_ID){



        }
        else{

            $scope.processes_account.Process_ID = "*";
        }

        if($scope.search){

            $scope.processes_account.search = $scope.search;

        }
        else{

            $scope.processes_account.search = "*";

        }

        var qprocessesAccountFunction2 = $q.defer();

        console.log($scope.processes_account);


        apiService.processes(id, $scope.processes_account).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                $scope.processData = response.data.data;

                $scope.paymentPlanModification = 0;

                console.log("status: " + status);
                if(status == 4 || status == 5){

                    $scope.processAccount = response.data;


                }
                if(status == 63){

                    $scope.processTypes = response.data.processTypes;
                }
                else if(status == 64){

                    console.log('loading all status 64 variables');
                    $scope.processData = response.data;
                    $scope.processAccount = response.data;
                    $scope.processesLinkedToAccount = response.data.processesLinkedToAccount;
                    $scope.processFilesLinked = response.data.processFilesLinked;
                    $scope.processesTotalCount = response.data.processesTotalCount;
                    $scope.processAccountDataLoaded = 1;

                    console.log('$scope.processesLinkedToAccount');
                    console.log($scope.processesLinkedToAccount);

                    console.log('$scope.processAccountDataLoaded' + $scope.processAccountDataLoaded);

                }
                else if(status == 61 || status == 65 || status == 66 || status == 67){


                    //$scope.processData = response.data;
                    //$scope.processAccount = response.data;


                    $scope.processAccount.processessProgress = response.data.processessProgress;


                    //$scope.processesLinkedToAccount = response.data.processesLinkedToAccount;
                    //$scope.processFilesLinked = response.data.processFilesLinked;
                    //$scope.processesTotalCount = response.data.processesTotalCount;

                    $scope.process_accounts = response.data.data;
                    $scope.totalCount = response.data.totalCount;
                }
                else{

                    $scope.process_accounts = response.data.data;
                    $scope.totalCount = response.data.totalCount;

                }

                console.log("processes API successful");

                $rootScope.watchNotes = 1;
                console.log(response);
                qprocessesAccountFunction2.resolve(response);


            },
            function () {
                console.log("API Service Error: processes")
            }


        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qprocessesAccountFunction2.promise;




    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.propertiesFunction = function(id, status) {

        $scope.propertiesData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.propertiesData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.propertiesData.id = $rootScope.decodedToken.id;
        $scope.propertiesData.User_ID = $rootScope.decodedToken.id;
        $scope.propertiesData.itemsPerPage = $scope.itemsPerPage;
        $scope.propertiesData.pageno = $scope.pageno;
        $scope.propertiesData.Status = status;
        $scope.propertiesData.Process_ID = id;

        if($scope.propertiesData.Property_ID){



        }
        else{

            $scope.propertiesData.Property_ID = "*";
        }

        if($scope.search){

            $scope.propertiesData.search = $scope.search;

        }
        else{

            $scope.propertiesData.search = "*";

        }

        if(!$scope.searchParam){

            $scope.searchParam = "*";

        }else{

        }

        if($scope.propertiesSelect.User){

            $scope.propertiesData.User_ID = $scope.propertiesSelect.User;
            //
            console.log("$scope.properties.User_ID " + $scope.properties.User_ID);

        }else if(!$scope.propertiesSelect.User){


            $scope.propertiesData.User_ID = "0";

            console.log("$scope.properties.User_ID " + $scope.properties.User_ID);

        }


        var qpropertiesFunction = $q.defer();

        console.log($scope.propertiesData);

        apiService.properties('*', $scope.propertiesData).then(
            function(response) {


                if(status == 90){
                    $scope.propertiesAll = response.data.data;
                }else{

                    $scope.properties = response.data.data;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;

                    console.log("API properties: " );
                    console.log("$scope.search: " + $scope.search );



                    if($scope.search == "*"){

                        $scope.search = "";

                    }else{

                    }
                    console.log("$scope.search2 : " + $scope.search );
                }



                qpropertiesFunction.resolve(response);

            },
            function() {
                console.log("Error in properties API");
            }
        )


        return qpropertiesFunction.promise;
    }

    $scope.awardsFunction = function(id, status) {

        console.log("status >>>> " + status);
        var qawardsFunction = $q.defer();

        console.log("function processesAccountFunction");
        console.log("$scope.pageno" + $scope.pageno);

        $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.awardsData.id = $rootScope.decodedToken.id;
        $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
        $scope.awardsData.pageno = $scope.pageno;
        $scope.awardsData.Status = status;
        $scope.awardsData.Process_ID = id;


        if($scope.search){

            $scope.awardsData.search = $scope.search;

        }
        else{

            $scope.awardsData.search = "*";

        }

        apiService.awards(id, $scope.awardsData).then(
            function (response) {
                console.log("apiService awards service");

                //$scope.awardsData.Awards_Category_Questions_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                if(status == 60){
                    console.log(response);
                    console.log("apiService awards service successful status 60");
                    $scope.awardCategories = response.data.awardsCategories;

                }
                else if(status == 12){
                    console.log(response);
                    console.log("apiService awards service successful status 12");
                    $scope.awards.awardsNominationValidation = response.data.data;
                }
                else if(status == 70){
                    console.log(response);
                    console.log("apiService awards service successful status 70");
                    $scope.awards.awardsNominations = response.data.awardsNominations;
                    console.log($scope.awards.awardsNominations);
                }
                else if(status == 71){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNomination = response.data.awardsNomination;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }
                else if(status == 72){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNominationRequest = response.data.awardsNominationRequest;
                    /*
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                    */
                }
                else if(status == 64){

                    console.log(response);
                    console.log("apiService awards service successful status 64");
                    $scope.awards.awardNominationRequest.First_Name = "";
                    $scope.awards.awardNominationRequest.Last_Name = "";
                    $scope.awards.awardNominationRequest.Email = "";
                    $scope.awardNominations = response.data.data;
                    $scope.nominatorsDetailsBloc = 1;
                    $scope.nomineesDetailsVisibleBloc = 0;

                }
                else if(status == 641){
                    console.log(response);
                    console.log("apiService awards service successful status 641");
                    $scope.awards.awardNominator.First_Name = "";
                    $scope.awards.awardNominator.Last_Name = "";
                    $scope.awards.awardNominator.Email = "";
                    $scope.awardNominator = response.data.data;
                    $scope.nominationSubmittedBlock = 1;

                }
                else if(status == 80){//update awards question answer
                    console.log(response);
                    console.log("apiService awards service successful status 70");
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }//update awards question answer
                else if(status == 81){//update property details question completed
                    console.log("apiService awards service successful status 81");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                }//update property details question completed
                else if(status == 82){//update awards question completed
                    console.log("apiService awards service successful status 82");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards question completed
                else if(status == 83){//update property details question answer
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update property details question answer
                else if(status == 84){//update awards files displayed
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards files displayed
                console.log("awards API successful");

                console.log(response);

                qawardsFunction.resolve(response);


            },
            function () {

                console.log("API Service Error: awards");

            }

        );

        return qawardsFunction.promise;

    }

    $scope.getAwardNomination = function () {
        console.log("inside function getPropertyType");
        $scope.awardsData.Awards_Nomination_ID = $scope.processAccount.data.Awards_Nomination_ID;
        $scope.awardsFunction($scope.processAccount.data.Awards_Nomination_ID, 71);
    }

    $scope.fileChange = function (file, countIndex) {

        console.log(file);
        console.log(countIndex);

    }

    $scope.loadProcessData = function () {
        console.log("inside function loadProcessData");
        //$scope.$state = $state;
        var qloadProcessData = $q.defer();

        $('.data').hide();
        $('.maintenance-account-data').hide();
        $('.add-transactions-data').hide();

        if($scope.processAccount.data.Tenancy_ID > 0){
            console.log('$scope.processAccount.Tenancy_ID <<<<<<<<<<<<<<< ');

            $scope.promise =  $scope.getTenancy();

        }
        else{

            $scope.promise = $scope.getGlobalPromise();

        }

        $scope.promise
            .then(function () {

                console.log('promise then getProperty');
                if($scope.processAccount.data.Property_ID > 0){


                    return $scope.getProperty();
                }
            })
            .then(function () {

                console.log('promise then getMaintenanceAccount');
                //$('.data').hide();
                $('.maintenance-account-data').hide();
                $('.add-transactions-data').hide();
                if($scope.processAccount.data.Maintenance_Account > 0){


                    return $scope.getMaintenanceAccount();
                }
            })
            .then(function () {

                console.log('promise then awardsFunction');



                return $scope.getAwardNomination();
            })
            .then(function () {

                console.log('promise then getFrequencies');

                return $scope.getFrequencies();
            })
            .then(function () {
                    console.log("$scope.maintenance.Clients_Budget: " +  $scope.maintenance.Maintenance_Account_ID);

                    console.log('A7b hide data');
                    $('.add-transactions-data').hide();

                    //$scope.myValue = data;
                }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

        qloadProcessData.resolve($scope.promise);

    }

    $scope.initialPageLoad = function() {
        console.log("inside function initialPageLoad");
        var qinitialPageLoad66 = $q.defer();


        qinitialPageLoad66.resolve($scope.obj = $scope.loadProcessData());


        return qinitialPageLoad66.promise;


    }

    $scope.getProperty = function() {

        $scope.properties = [];
        console.log("function getProperties");

        $scope.propertiesData.Property_ID = $scope.processAccount.data.Property_ID;

        console.log("$scope.propertiesData.Property_ID: " + $scope.propertiesData.Property_ID);

        $scope.status = 92;
        $scope.propertiesFunction($scope.propertiesData.Property_ID, 92);

    }

    $scope.postProcessNote = function (processLinkedToAccount, file) {

        console.log('arrearsProcessesLinkedToAccount.NotesAdd: ' + processLinkedToAccount.NotesAdd);
        console.log(file);
        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.note_loading = true;
        $scope.note.Process_Account_ID = $stateParams.processAccountID;
        $scope.note.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.note.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.note.User_ID = $scope.decodedToken.id;
        $scope.note.Notes_Type = "Processes";
        $scope.note.Date_Entered = date;
        $scope.note.Note_Type_ID = 3;
        $scope.note.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;
        $scope.note.picFile = file;
        $scope.note.Payment_Plan = $scope.processes_account.Payment_Plan;
        $scope.note.Description = processLinkedToAccount.Description;
        $scope.note.Process_Account_ID = processLinkedToAccount.Process_Account_ID;
        $scope.note.sendEmailNotificationNot = 1;

        if(processLinkedToAccount.Property_name){
            $scope.note.Address_1 = processLinkedToAccount.Address_1;
            $scope.note.Property_name = processLinkedToAccount.Property_name;
        }


        if(processLinkedToAccount.NotesAdd){
            console.log(processLinkedToAccount);
            console.log("processLinkedToAccount.NotesAdd: " + processLinkedToAccount.NotesAdd);
            $scope.note.note = processLinkedToAccount.Process_Description + " - " + processLinkedToAccount.NotesAdd;

        }else{
            processLinkedToAccount.NotesAdd = "";
        }

        if(processLinkedToAccount.Completed_Checkbox == 'true'){
            console.log(processLinkedToAccount);
            $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Completed - ' + processLinkedToAccount.Process_Description;
            $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Completed - ' + processLinkedToAccount.Process_Description;


            if(processLinkedToAccount.NotesAdd){
                $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;
                $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;


            }

        }else if(processLinkedToAccount.Completed_Checkbox == 'false'){
            console.log(processLinkedToAccount);

            $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Not complete - ' + processLinkedToAccount.Process_Description;
            $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Not complete - ' + processLinkedToAccount.Process_Description;


            if(processLinkedToAccount.NotesAdd){
                $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;
                $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;


            }
        }

        if($scope.paymentPlanModification == 1){
            $scope.note.Note_Content = "Debt account updated. Debt Account ID: " + $scope.tenancies.tenancyDebtAccount.Debt_Account_ID;
            $scope.note.note = "Debt account updated. Debt Account ID: " + $scope.tenancies.tenancyDebtAccount.Debt_Account_ID;
            "Payment plan details: " + $scope.note.Payment_Plan_Details;
        }

        var qpostProcessNote = $q.defer();
        var myValue = 0;
        var promise = $scope.getGlobalPromise();

        promise
            .then(function (data) {

                console.log("A1a - postNote");
                noteService.postNote($scope.note);

                return $scope.getGlobalPromise(data);
            })//post note
            .then(function (data) {

                console.log("A1c");

                return $scope.getGlobalPromise(data);
            })//set variables
            .then(function (data) {
                //send email and attachement
                console.log("A4 - send email and attachement");


                return $scope.getGlobalPromise(data);

            })//send email
            .then(function (data) {

                console.log("A5 ");


                return $scope.getGlobalPromise(data);
            })//reset variables
            .then(function (data) {
                    console.log('A7 notes $state.reload');
                    $state.reload('lettings-main-nav.lettings-side.process-account-view.notes');
                    $scope.paymentPlanModification == 0;

                    myValue = data;
                }//reload notes
                ,function (err) {
                    myValue = err;
                })//end promise

        qpostProcessNote.resolve(promise);

        return qpostProcessNote.promise;
    }

    $scope.postProcessAccountNote = function (postNoteData, file) {

        console.log('postNoteData.Note_Content: ' + postNoteData.Note_Content);

        console.log(file);
        var date = new Date();
        date.setHours(date.getHours() + 1);
        $scope.note.Note_Content = postNoteData.Note_Content;
        $scope.note.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.note_loading = true;
        $scope.note.Process_Account_ID = $stateParams.processAccountID;
        $scope.note.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.note.User_ID = $scope.decodedToken.id;
        $scope.note.Notes_Type = "Processes";
        $scope.note.Date_Entered = date;
        $scope.note.Note_Type_ID = 3;
        $scope.note.Processes_Linked_to_Account_ID = "";
        $scope.note.picFile = file;
        $scope.note.Payment_Plan = $scope.processes_account.Payment_Plan;

        var qpostProcessNote = $q.defer();
        var myValue = 0;
        var promise = $scope.getGlobalPromise();

        promise
            .then(function (data) {

                console.log("A1a - postNote");
                noteService.postNote($scope.note);

                return $scope.getGlobalPromise(data);
            })//post note
            .then(function (data) {

                console.log("A1c");

                return $scope.getGlobalPromise(data);
            })//set variables
            .then(function (data) {
                //send email and attachement
                console.log("A4 - send email and attachement");


                return $scope.getGlobalPromise(data);

            })//send email
            .then(function (data) {

                console.log("A5 ");


                return $scope.getGlobalPromise(data);
            })//reset variables
            .then(function (data) {
                    console.log('A7 notes $state.reload');
                    $state.reload('lettings-main-nav.lettings-side.process-account-view.notes');
                    //$scope.paymentPlanModification == 0;

                    myValue = data;
                }//reload notes
                ,function (err) {
                    myValue = err;
                })//end promise

        qpostProcessNote.resolve(promise);

        return qpostProcessNote.promise;
    }

    $scope.updateProcessCompleted = function (processLinkedToAccount) {

        var q62 = $q.defer();
        console.log('$rootScope.tenancy_id: ' + $rootScope.tenancy_id);

        $rootScope.noteSpinner = 0;

        console.log(processLinkedToAccount);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID: ' + processLinkedToAccount.Processes_Linked_to_Account_ID);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Completed_Checkbox: ' + processLinkedToAccount.Completed_Checkbox);
        $scope.processes_account.Completed = 1;
        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;

        //$scope.processes_account.Date_Completed = date;
        if(processLinkedToAccount.Completed_Checkbox == 'true'){
            $scope.processes_account.Completed = 1;

            //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
        }else if(processLinkedToAccount.Completed_Checkbox == 'false'){
            $scope.processes_account.Completed = 0;
        }

        //$scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;
        $scope.processesAccountFunction(0, 61);

        $scope.postProcessNote(processLinkedToAccount);

        alert("This process has been updated: " + processLinkedToAccount.Process_Description)
        return q62.promise;
    }

    $scope.updateResetProcess = function (processLinkedToAccount) {

        console.log('in function updateResetProcess');
        processLinkedToAccount.Not_Applicable = 0;
        var q62 = $q.defer();
        console.log('$rootScope.tenancy_id: ' + $rootScope.tenancy_id);

        $rootScope.noteSpinner = 0;

        console.log(processLinkedToAccount);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID: ' + processLinkedToAccount.Processes_Linked_to_Account_ID);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Completed_Checkbox: ' + processLinkedToAccount.Completed_Checkbox);
        $scope.processes_account.Not_Applicable = 1;
        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;

        $scope.processesAccountFunction(0, 67);

        $scope.postProcessNote(processLinkedToAccount);

        alert("This process has been updated: " + processLinkedToAccount.Process_Description)
        return q62.promise;
    }

    $scope.updateNotApplicable = function (processLinkedToAccount) {

        console.log('in function updateNotApplicable');

        var q62 = $q.defer();
        console.log('$rootScope.tenancy_id: ' + $rootScope.tenancy_id);

        processLinkedToAccount.Not_Applicable = 1;
        $rootScope.noteSpinner = 0;

        console.log(processLinkedToAccount);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Processes_Linked_to_Account_ID: ' + processLinkedToAccount.Processes_Linked_to_Account_ID);
        console.log('$scope.arrearsProcessesLinkedToAccountRecord.Completed_Checkbox: ' + processLinkedToAccount.Completed_Checkbox);
        $scope.processes_account.Not_Applicable = 1;
        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;

        /*
        //$scope.processes_account.Date_Completed = date;
        if(processLinkedToAccount.Completed_Checkbox == 'true'){
            $scope.processes_account.Completed = 1;

            //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
        }else if(processLinkedToAccount.Completed_Checkbox == 'false'){
            $scope.processes_account.Completed = 0;
        }
        */
        $scope.processesAccountFunction(0, 66);

        $scope.postProcessNote(processLinkedToAccount);

        alert("This process has been updated: " + processLinkedToAccount.Process_Description)
        return q62.promise;
    }

    $scope.updateProcessAccountCompleted = function (after, before) {

        var q62 = $q.defer();
        console.log('before completed_checkbox: ' + before);
        console.log('after completed_checkbox: ' + after);

        console.log('processessProgress: ' + ($scope.processAccount.processessProgress * 100));
        console.log('processessProgress: ' + ($scope.processAccount.processessProgress));



        $scope.postNoteData = {};

        var localCompletedCheckbox = $scope.completed_checkbox;

        if(confirm("Do you want to update the status?", 'CLOUDERTY NOTIFICATION')){



            if($scope.processAccount.processessProgress == 0.1){



                console.log($scope.processAccount.data.Process_Account_ID);
                $scope.processes_account.Completed = 1;
                var date = new Date();
                date.setHours(date.getHours() + 1);

                $scope.processes_account.Process_Account_ID = $scope.processAccount.data.Process_Account_ID;

                //$scope.processes_account.Date_Completed = date;
                if($scope.processAccount.data.Completed_Checkbox == 'true'){
                    $scope.processes_account.Completed = 1;
                    $scope.postNoteData.Note_Content = "Process complete";

                    //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
                }else if($scope.processAccount.data.Completed_Checkbox == 'false'){
                    $scope.processes_account.Completed = 0;
                    $scope.postNoteData.Note_Content = "Process not complete";
                }


                var myValue = 0;
                var promise = $scope.getGlobalPromise();


                $scope.promise
                    .then(function (data) {

                        console.log('A7b hide data');
                        return $scope.processesAccountFunction(0, 65);
                    })
                    .then(function () {

                        console.log('A7b hide data');

                        return $scope.postProcessAccountNote($scope.postNoteData);

                    })
                    .then(function () {

                            console.log('A7c send email confirmation');
                            $scope.date = new Date();
                            $scope.propertyText = "";


                            $scope.propertyText = $scope.processes_account.Property_name;

                            if($scope.processes_account.Unit_number){
                                $scope.propertyText = $scope.propertyText + " U " + $scope.processes_account.Unit_number;
                            }

                            var from_email = "Clouderty | Process Account Completed " + $scope.processAccountID + " | " + $scope.processes_account.Description + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + $scope.propertyText + " | " + $scope.processes_account.Description;
                            var message = "Process Account ID: " + $scope.processAccountID + "<br /><br />" +
                                "Property: " + $scope.propertyText + "<br /><br />" +
                                "Process type: " + $scope.processes_account.Description + "<br /><br />" +
                                "Process completed by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                "Date completed: " + $scope.date + "<br /><br />";

                            var message =  message + "Tenant Name: " + $scope.processes_account.tenantNameText + "<br /><br />";

                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                        }
                        , function (err) {

                            console.log('A7c error');


                            $scope.myValue = err;
                        })

                alert("This process has been updated: " + $scope.processAccount.data.Process_Account_ID);
                return q62.promise;






            }
            else{

                $scope.completed_checkbox = before;
                alert("Ensure all individual processes are complete first", 'CLOUDERTY NOTIFICATION');
            }


        }
        else{

            console.log('cancelled');
            $scope.completed_checkbox = before;
        }

    }

    $scope.updateProcessAccountStatus = function (after, before) {

        var q62 = $q.defer();
        console.log('before completed_checkbox: ' + before);
        console.log('after completed_checkbox: ' + after);

        console.log('processessProgress: ' + ($scope.processAccount.processessProgress * 100));
        console.log('processessProgress: ' + ($scope.processAccount.processessProgress));

        $scope.postNoteData = {};

        var statusUpdate = $scope.statusUpdate;

        if(confirm("Do you want to update the status?", 'CLOUDERTY NOTIFICATION')){



            if($scope.processAccount.processessProgress == 1){



                console.log($scope.processAccount.data.Process_Account_ID);
                $scope.processes_account.Status_ID = after;
                var date = new Date();
                date.setHours(date.getHours() + 1);

                $scope.processes_account.Process_Account_ID = $scope.processAccount.data.Process_Account_ID;

                /*
                //$scope.processes_account.Date_Completed = date;
                if($scope.processAccount.data.Completed_Checkbox == 'true'){
                    $scope.processes_account.Completed = 1;
                    $scope.postNoteData.Note_Content = "Process complete";

                    //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
                }else if($scope.processAccount.data.Completed_Checkbox == 'false'){
                    $scope.processes_account.Completed = 0;
                    $scope.postNoteData.Note_Content = "Process not complete";
                }
                */

                var myValue = 0;
                var promise = $scope.getGlobalPromise();


                $scope.promise
                    .then(function (data) {

                        console.log('A7b hide data');
                        return $scope.processesAccountFunction(0, 65);
                    })
                    .then(function () {

                        console.log('A7b hide data');

                        return $scope.postProcessAccountNote($scope.postNoteData);

                    })
                    .then(function () {

                            console.log('A7c send email confirmation');
                            $scope.date = new Date();
                            $scope.propertyText = "";


                            $scope.propertyText = $scope.processes_account.Property_name;

                            if($scope.processes_account.Unit_number){
                                $scope.propertyText = $scope.propertyText + " U " + $scope.processes_account.Unit_number;
                            }

                            var from_email = "Clouderty | Process  " + $scope.processAccountID + " Status:" + + " | " + $scope.processes_account.Description + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + $scope.propertyText + " | " + $scope.processes_account.Description;
                            var message = "Process Account ID: " + $scope.processAccountID + "<br /><br />" +
                                "Property: " + $scope.propertyText + "<br /><br />" +
                                "Process type: " + $scope.processes_account.Description + "<br /><br />" +
                                "Process completed by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                "Date completed: " + $scope.date + "<br /><br />";

                            var message =  message + "Tenant Name: " + $scope.processes_account.tenantNameText + "<br /><br />";

                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                        }
                        , function (err) {

                            console.log('A7c error');


                            $scope.myValue = err;
                        })

                alert("This process has been updated: " + $scope.processAccount.data.Process_Account_ID);
                return q62.promise;






            }
            else{

                $scope.statusUpdate = before;
                alert("Ensure all individual processes are complete first", 'CLOUDERTY NOTIFICATION');
            }


        }
        else{

            console.log('cancelled');
            $scope.statusUpdate  = before;
        }

    }

    $scope.picShowFlagFunction = function () {

        console.log("function picShowFlagFunction");
        $scope.picShowFlag = 0;

    }

    $scope.postProcessNoteButton = function (processLinkedToAccount, file, index) {


        if(confirm("Are you sure you want to add this note?") == true){

            $rootScope.noteSpinner = 0;
            //processLinkedToAccount.Description = processLinkedToAccount.NotesAdd;
            console.log("processLinkedToAccount.Property_name: " + $scope.processAccount.data.Property_name);
            console.log("processLinkedToAccount.Process_Description: " + processLinkedToAccount.Process_Description);
            console.log("processLinkedToAccount.NotesAdd: " + processLinkedToAccount.NotesAdd);


            processLinkedToAccount.Property_name = $scope.processAccount.data.Property_name;
            processLinkedToAccount.Address_1 = $scope.processAccount.data.Address_1;
            //processLinkedToAccount.NotesAdd = processLinkedToAccount.NotesAdd;

            //processLinkedToAccount.Process_Description = processLinkedToAccount.Process_Description;

            console.log("index: " + index);
            processLinkedToAccount.index = index;
            $scope.postProcessNote(processLinkedToAccount, file);

            processLinkedToAccount.NotesAdd = "";
            console.log(this.picFileProcessLinkedToAccount);

            this.picFileProcessLinkedToAccount[index] = null;
            this.picFileProcessLinkedToAccount = null;
            this.processLinkedToAccount.NotesAdd = null;
            //console.log("$scope.picFileProcessLinkedToAccountImage[index]: " + $scope.picFileProcessLinkedToAccountImage[index]);
            console.log("index: " + index);
            console.log(file);
            console.log("file: " + file);
            //file = null;
            //$scope.picShowFlag = 1;
            //$scope.picFileProcessLinkedToAccount[index] = null;
        }

    }

    $scope.updateProcessPaymentPlan = function () {


        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.paymentPlanModification = 1;

        //$scope.processes_account = $scope.tenancies.tenancyDebtAccount;

        $scope.processes_account.User_ID = $rootScope.decodedToken.id;
        //$scope.processes_account.Date_Completed = date;


        $scope.processes_account.Debt_Account_ID = $scope.processAccount.data.Debt_Account_ID;
        $scope.processes_account.Tenancy_ID = $scope.processAccount.Tenancy_ID;

        $scope.processes_account.Process_Account_ID = $scope.processAccount.Process_Account_ID;
        $scope.processes_account.Payment_Plan_Details = $scope.tenancies.tenancyDebtAccount.Payment_Plan_Details;
        $scope.processes_account.Address_1_Trace = $scope.tenancies.tenancyDebtAccount.Address_1_Trace;
        $scope.processes_account.Address_2_Trace = $scope.tenancies.tenancyDebtAccount.Address_2_Trace;
        $scope.processes_account.Tracing_Agent_Vendor_ID = $scope.tenancies.tenancyDebtAccount.Tracing_Agent_Vendor_ID;
        $scope.processes_account.Tracing_Cost = $scope.tenancies.tenancyDebtAccount.Tracing_Cost;
        $scope.processes_account.Credit_Check_Cost = $scope.tenancies.tenancyDebtAccount.Credit_Check_Cost;
        $scope.processes_account.Court_Fee = $scope.tenancies.tenancyDebtAccount.Court_Fee;
        $scope.processes_account.City_Region_Trace = $scope.tenancies.tenancyDebtAccount.City_Region_Trace;
        $scope.processes_account.Town_Trace = $scope.tenancies.tenancyDebtAccount.Town_Trace;
        $scope.processes_account.Postcode_Trace = $scope.tenancies.tenancyDebtAccount.Postcode_Trace;
        $scope.processes_account.CCJ_Claim_No = $scope.tenancies.tenancyDebtAccount.CCJ_Claim_No;
        $scope.processes_account.CCJ_Particulars_of_Claim = $scope.tenancies.tenancyDebtAccount.CCJ_Particulars_of_Claim;
        $scope.processes_account.CCJ_Claimant_Customer_ID = $scope.tenancies.tenancyDebtAccount.CCJ_Claimant_Customer_ID;
        $scope.processes_account.Trace_Requested = $scope.tenancies.tenancyDebtAccount.Trace_Requested;
        $scope.processes_account.Tracing_Fee = $scope.tenancies.tenancyDebtAccount.Tracing_Fee;
        $scope.processes_account.Debt_Account_Closed = $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed;
        $scope.processes_account.Customer_ID = $scope.tenancies.tenancyDebtAccount.Customer_ID;

        console.log($scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date);
        if(!isNaN($scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date)){
            console.log("$scope.tenanciesInput.CCJ_Claim_Issue_Date >>>>>>>>>>>>>>>>>" + dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date, 0));
            $scope.processes_account.CCJ_Claim_Issue_Date = dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date, 0);

        }

        console.log($scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date);
        if(!isNaN($scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date)){
            console.log("$scope.tenanciesInput.Trace_Results_Received_Date >>>>>>>>>>>>>>>>>" + dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date, 0));

            $scope.processes_account.Trace_Results_Received_Date = dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date, 0);

        }

        console.log($scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date);
        if(!isNaN($scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date)) {
            console.log("$scope.tenanciesInput.Tracing_Fee_Paid_Date >>>>>>>>>>>>>>>>>" + dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date, 0));
            $scope.processes_account.Tracing_Fee_Paid_Date = dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date, 0);


        }

        console.log($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date);
        if(!isNaN($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date)){
            console.log("$scope.tenanciesInput.Debt_Account_Closed_Date >>>>>>>>>>>>>>>>>" + dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date, 0));
            $scope.processes_account.Debt_Account_Closed_Date = dateInputService.dateInputDayService($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date, 0);

        }

        if($scope.tenancies.tenancyDebtAccount.Trace_Requested_Checkbox == "true"){

            $scope.tenancies.tenancyDebtAccount.Trace_Requested = 1;
            $scope.processes_account.Trace_Requested = $scope.tenancies.tenancyDebtAccount.Trace_Requested;

        }else if($scope.tenancies.tenancyDebtAccount.Trace_Requested_Checkbox == "false"){

            $scope.tenancies.tenancyDebtAccount.Trace_Requested = 0;
            $scope.processes_account.Trace_Requested = $scope.tenancies.tenancyDebtAccount.Trace_Requested;

        }

        if($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Checkbox == "true"){

            $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed = 1;
            $scope.processes_account.Debt_Account_Closed = $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed;

        }else if($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Checkbox == "false"){

            $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed = 0;
            $scope.processes_account.Debt_Account_Closed = $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed;
        }

        console.log($scope.processes_account);


        $scope.postProcessNote($scope.processes_account);

        var q62 = $q.defer();

        $scope.promise = $scope.getGlobalPromise();


        $scope.promise
            .then(function () {

                console.log("A4 - processesAccountFunction");


                return $scope.tenanciesFunction($scope.processAccount.data.Tenancy_ID, 72);
                //$scope.getGlobalPromise(data);

            })
            .then(function (data) {
                console.log('A7a');


                return $scope.getGlobalPromise(data);

                //$scope.myValue = data;
            })
            .then(function () {

                    console.log('A7b');

                    $state.reload("lettings-main-nav.lettings-side.process-account-view");

                    //$scope.myValue = data;
                }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

        q62.resolve($scope.promise);

        //alert("The payment plan has been updated: " + $scope.processes_account.Payment_Plan_Details);
        return q62.promise;
    }

    $scope.getFrequencies = function () {

        console.log('inside function getFrequencies');
        var qgetFrequencies = $q.defer();

        apiService.frequencies().then(
            function (response) {
                $scope.maintenance.frequencies = response;

                qgetFrequencies.resolve(response);

                console.log(response);
                console.log($scope.maintenance.frequencies);

                //$scope.maintenance.frequencies.data.data.Frequency_ID = $scope.maintenance.frequencies.data.data.Frequency_ID;

                console.log("$scope.payment_transaction.Unit_number: " + $scope.payment_transaction.Unit_number);

            },
            function () {
                console.log("There was an error in getTenanciesUnit")
            }
        )
        return qgetFrequencies.promise;

    }

    $scope.getDebt = function () {

        console.log('inside function getDebt');
        var qGetDebt = $q.defer();
        qGetDebt.resolve($scope.debtsFunction($scope.processAccount.Tenancy_ID, 5));

        return qGetDebt.promise;
    }

    $scope.getTenancy = function () {

        var q5 = $q.defer();
        //console.log('$rootScope.tenancy_id: ' + $scope.processAccount.data.Tenancy_ID);


        $scope.tenanciesInput.Debt_Account_ID = $scope.processAccount.data.Debt_Account_ID;
        q5.resolve($scope.tenanciesFunction($scope.processAccount.data.Tenancy_ID, 75));


        return q5.promise;
    }

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.sendEmailInternalPostNote = function(to_email,subject,message, property, task_name, note_type, note, note_category_type, tenancy_id, unit_number, email_signature, note_type_id){

        message = message + '\n\n' + email_signature;

        if(to_email==null){
            alert("Enter an email address");
        } else {

            //note_category_type = "Email";
            //$scope.obj.select_note_type = "Email";
            //from_email = "notifications@clouderty.co.uk"

            if (unit_number == null) {


            } else {

                property = property + " - Unit" + unit_number
            }


            console.log("sendEmailInternalPostNote Controller Function - 1: " + to_email);
            //$scope.b = sendEmailInternalPostNote.postNote(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email, subject, message, property, task_id, unit_number, email_signature);


            var date = new Date();
            date.setHours(date.getHours() + 1);

            $scope.note_loading = true;


            $scope.note.Process_Account_ID = $stateParams.processAccountID;
            $scope.note.Tenancy_ID = $scope.processAccount.Tenancy_ID;
            $scope.note.User_ID = $scope.decodedToken.id;
            $scope.note.Notes_Type = "Arrears";
            $scope.note.Date_Entered = date;
            $scope.note.Note_Content = message;
            $scope.note.Note_Type_ID = 3;
            $scope.note.Processes_Linked_to_Account_ID = 5544;

            /*
            $scope.note.user_id = $scope.decodedToken.id;
            $scope.note.note_type = note_type;
            $scope.note.date = date;
            $scope.note.note = note;
            $scope.note.id = tenancy_id;
            $scope.note.Tenancy_ID = tenancy_id;
            $scope.note.note_type_id = note_type_id;
            */

            console.log("sendEmailInternalPostNote - 1: " + $scope.note.id);


            //$scope.note.note_category_type = note_category_type;
            $scope.note.select_note_type = note_category_type;

            $scope.task_name = task_name;




            console.log("sendEmailInternalPostNote - 3: hello apinotes");
            $http.post("api/notes", $scope.note)
                .success(function (response) {

                    $scope.posted_note = response.posted;

                    fileUploadService.uploadFile($scope.processLinkedToAccount.picFileProcessLinkedToAccount, $scope.posted_note.note_id);

                    //$scope.uploadFile($scope.picFile, note_type);
                    //$scope.obj.note = {};
                    $state.reload('lettings-main-nav.lettings-side.process-account-view.notes');
                    console.log("sendEmailInternalPostNote - 4: in apinotes");
                    // Send email confirmation | Task Notes

                    // email send finish
                    console.log("Service alert4");

                })



            return 0;


            //$state.reload();

        }


    };

    $scope.uploadFile = function(file) {

        if ($scope.picFile) {
            Upload.upload({
                url: 'api/files',
                method: 'POST',
                file: file,
                data: {
                    'Account_Holder_ID': $rootScope.decodedToken.Account_Holder_ID,
                    'File_Type': 'note',
                    'ID': $scope.posted_note.note_id,
                    'File_Category': 4
                }
            }).then(function() {
                $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                    //$scope.obj.notes = response.notes;
                    $scope.note_loading = false;
                })
            });
        } else {
            $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                //$scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })
        }

    }

    $scope.processesLinkedToAccountFunction = function (pageno) {

        console.log('in function processesLinkedToAccount');

        var qprocessesLinkedToAccount = $q.defer();

        qprocessesLinkedToAccount.resolve($scope.processesAccountFunction($stateParams.processAccountID, 64));

        return qprocessesLinkedToAccount.promise;

    }

    $scope.debtsFunction = function(id, status) {

        console.log("function debtsFunction");
        $scope.debts.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.debts.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.debts.itemsPerPage = $scope.itemsPerPage;
        $scope.debts.pageno = $scope.pageno;
        $scope.debts.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.debts.Debt_Account_ID = $scope.processAccount.Process_Account_ID;
        $scope.debts.Estate_ID = $scope.landlord.Estate_ID;
        $scope.debts.Property_ID = $scope.landlord.Property_ID;
        $scope.debts.monthsExpiring = $scope.monthsExpiring;
        $scope.debts.Status = status;
        //$scope.pageno = 1;

        if($scope.debts.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.debts.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.debts.search = $scope.search;
        }else{

            $scope.debts.search = "*";
        }

        //$scope.obj.search = "*";

        var qDebtsFunction = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.debts(id, $scope.debts).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");


                $scope.debts = response.data;



                console.log(response);
                console.log("debts API");
                qDebtsFunction.resolve(response);
            },
            function () {
                console.log("API Service Error: debts")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qDebtsFunction.promise;
    }

    $scope.getMaintenanceAccount = function () {

        console.log('inside function getMaintenanceAccount');
        var qGetMaintenanceAccount = $q.defer();
        qGetMaintenanceAccount.resolve($scope.maintenanceFunction($scope.processAccount.data.Maintenance_Account_ID, 68));

        return qGetMaintenanceAccount.promise;
    }

    $scope.maintenanceFunction = function(id, status) {

        var qMaintenancesFunction = $q.defer();
        console.log("function debtsFunction");
        $scope.maintenance.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.maintenance.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.maintenance.itemsPerPage = $scope.itemsPerPage;
        $scope.maintenance.pageno = $scope.pageno;
        /*
        $scope.maintenance.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.maintenance.Debt_Account_ID = $scope.processAccount.Process_Account_ID;
        $scope.maintenance.Estate_ID = $scope.landlord.Estate_ID;
        $scope.maintenance.Property_ID = $scope.landlord.Property_ID;
        $scope.maintenance.monthsExpiring = $scope.monthsExpiring;
        */
        $scope.maintenance.Status = status;
        $scope.maintenance.Maintenance_Account_ID = id;
        //$scope.pageno = 1;

        if($scope.maintenance.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.maintenance.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.maintenance.search = $scope.search;
        }else{

            $scope.maintenance.search = "*";
        }

        $scope.maintenanceData = $scope.maintenance;
        $scope.maintenance = {};


        apiService.maintenance(id, $scope.maintenanceData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");


                if(status == 31 || status == 41){

                    $scope.maintenance.Maintenance_Account_ID = $scope.processAccount.data.Maintenance_Account_ID;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;

                }
                else{

                    $scope.maintenance = response.data.data;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;
                    //console.log(decodeURIComponent("https%3A%2F%2Fw3schools.com%2Fmy%20test.asp%3Fname%3Dst%C3%A5le%26car%3Dsaab%C2%A3%C2%A3%C2%A3"));
                    $scope.maintenance.Materials_Required = decodeURIComponent($scope.maintenance.Materials_Required);

                }

                $scope.maintenanceVariable = response.data;
                $scope.maintenanceSpinner = 1;
                console.log("$scope.maintenanceVariable: " +  $scope.maintenanceVariable);
                console.log($scope.maintenanceVariable);

                console.log("$scope.maintenance.Clients_Budget: " +  $scope.maintenance.Maintenance_Account_ID);
                console.log(response);
                console.log("maintenance API");
                qMaintenancesFunction.resolve(response);

            },
            function () {
                console.log("API Service Error: maintenance")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qMaintenancesFunction.promise;
    }

    $scope.addMaintenanceItem = function (dataObj) {

        console.log("inside function addMaintenanceItem");

        console.log(dataObj);

        if(confirm("Do you want to add this item?"), 'Clouderty notification'){
            $scope.maintenanceMaterialsLabourView = [];
            $scope.maintenanceSpinner = "";
            $scope.gotoAnchor('maintenance');

            console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.processAccount.data.Maintenance_Account_ID);
            $scope.maintenance.Maintenance_Account_ID = $scope.processAccount.data.Maintenance_Account_ID;
            $scope.maintenance.Description = dataObj.descriptionAdd;
            $scope.maintenance.Cost = dataObj.costAdd;
            //$scope.paymentBlockSpinner = 1;

            var q = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    $scope.maintenanceFunction($scope.processAccount.data.Maintenance_Account_ID, 41);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A2");



                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }


    }

    $scope.deleteMaintenanceItem = function (dataObj) {

        console.log("inside function deleteDirItem");

        console.log(dataObj);

        if(confirm("Do you want to delete this item?"), 'Clouderty notification'){
            console.log("in function dirTests");
            $scope.maintenanceMaterialsLabourView = [];
            $scope.maintenance = {};
            $scope.maintenanceSpinner = "";

            console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.processAccount.data.Maintenance_Account_ID);
            $scope.maintenance.Maintenance_Account_ID = $scope.processAccount.data.Maintenance_Account_ID;
            $scope.maintenance.Maintenance_Materials_Labour_ID = dataObj.Maintenance_Materials_Labour_ID;
            //$scope.paymentBlockSpinner = 1;

            var q = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    $scope.maintenanceFunction($scope.processAccount.data.Maintenance_Account_ID, 31);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A2");



                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }


    }

    $scope.updateMaintenanceAccount = function () {

        console.log("inside function updateMaintenanceAccount");

        var date = new Date();
        date.setHours(date.getHours() + 1);


        $scope.maintenance.User_ID = $rootScope.decodedToken.id;


        $scope.maintenance.Labour_Time = $scope.maintenance.Labour_Time;
        $scope.maintenance.Labour_Cost = $scope.maintenance.Labour_Cost;
        $scope.maintenance.Material_Cost = $scope.maintenance.Material_Cost;
        $scope.maintenance.Materials_Required = $scope.maintenance.Materials_Required;
        $scope.maintenance.Clients_Budget = $scope.maintenance.Clients_Budget;
        $scope.maintenance.Waste_Disposal_Required = $scope.maintenance.Waste_Disposal_Required;
        $scope.maintenance.Tracing_Cost = $scope.maintenance.Tracing_Cost;
        $scope.maintenance.Credit_Check_Cost = $scope.maintenance.Credit_Check_Cost;


        /*
        console.log($scope.processes_account);


        $scope.postProcessNote($scope.processes_account);

        */
        var q62 = $q.defer();

        $scope.promise = $scope.getGlobalPromise();


        $scope.promise
            .then(function (data) {
                console.log('maintenanceFunction');


                return $scope.maintenanceFunction($scope.processAccount.data.Maintenance_Account_ID, 2);

                //$scope.myValue = data;
            })
            /*.then(function (data) {
                console.log('getMaintenanceAccount()');


                return $scope.getMaintenanceAccount();

                //$scope.myValue = data;
            })*/
            .then(function () {

                    console.log('A7b');

                    console.log("$scope.maintenance.Clients_Budget: " +  $scope.maintenance.Maintenance_Account_ID);

                    //$state.reload("lettings-main-nav.lettings-side.process-account-view");

                    //$scope.myValue = data;
                }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

        q62.resolve($scope.promise);

        //alert("The payment plan has been updated: " + $scope.processes_account.Payment_Plan_Details);
        return q62.promise;
    }

    $scope.tenanciesFunction = function(id, status) {

        if(status == 72){
            $scope.tenancies.tenancyDebtAccount = [];
        }

        console.log("function tenanciesFunction zzzzzzzzzzzzzzzzzzzzzzzzzzzz");
        $scope.tenanciesInput.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenanciesInput.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenanciesInput.itemsPerPage = $scope.itemsPerPage;
        $scope.tenanciesInput.pageno = $scope.pageno;
        $scope.tenanciesInput.Tenancy_ID = id;
        $scope.tenanciesInput.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenanciesInput.Property_ID = $scope.landlord.Property_ID;
        $scope.tenanciesInput.monthsExpiring = $scope.monthsExpiring;
        $scope.tenanciesInput.Status = status;

        $scope.tenanciesInput.Process_Account_ID = $scope.processes_account.Process_Account_ID;
        $scope.tenanciesInput.Payment_Plan_Details = $scope.processes_account.Payment_Plan_Details;
        $scope.tenanciesInput.Address_1_Trace = $scope.processes_account.Address_1_Trace;
        $scope.tenanciesInput.Address_2_Trace = $scope.processes_account.Address_2_Trace;
        $scope.tenanciesInput.Tracing_Agent_Vendor_ID = $scope.processes_account.Tracing_Agent_Vendor_ID;
        $scope.tenanciesInput.Tracing_Cost = $scope.processes_account.Tracing_Cost;
        $scope.tenanciesInput.Credit_Check_Cost = $scope.processes_account.Credit_Check_Cost;
        $scope.tenanciesInput.Court_Fee = $scope.processes_account.Court_Fee;
        $scope.tenanciesInput.Town_Trace = $scope.processes_account.Town_Trace;
        $scope.tenanciesInput.Postcode_Trace = $scope.processes_account.Postcode_Trace;
        $scope.tenanciesInput.City_Region_Trace = $scope.processes_account.City_Region_Trace;
        $scope.tenanciesInput.CCJ_Claim_No = $scope.processes_account.CCJ_Claim_No;
        if($scope.processes_account.CCJ_Claim_Issue_Date) {
            $scope.tenanciesInput.CCJ_Claim_Issue_Date = $scope.processes_account.CCJ_Claim_Issue_Date;
        }
        $scope.tenanciesInput.CCJ_Particulars_of_Claim = $scope.processes_account.CCJ_Particulars_of_Claim;
        $scope.tenanciesInput.CCJ_Claimant_Customer_ID = $scope.processes_account.CCJ_Claimant_Customer_ID;
        $scope.tenanciesInput.Trace_Requested = $scope.processes_account.Trace_Requested;
        if($scope.processes_account.Trace_Results_Received_Date) {
            $scope.tenanciesInput.Trace_Results_Received_Date = $scope.processes_account.Trace_Results_Received_Date;
        }
        $scope.tenanciesInput.Tracing_Fee = $scope.processes_account.Tracing_Fee;
        if($scope.processes_account.Tracing_Fee_Paid_Date) {
            $scope.tenanciesInput.Tracing_Fee_Paid_Date = $scope.processes_account.Tracing_Fee_Paid_Date;
        }
        $scope.tenanciesInput.Debt_Account_Closed = $scope.processes_account.Debt_Account_Closed;
        if($scope.processes_account.Debt_Account_Closed_Date) {
            $scope.tenanciesInput.Debt_Account_Closed_Date = $scope.processes_account.Debt_Account_Closed_Date;
        }
        $scope.tenanciesInput.Customer_ID = $scope.processes_account.Customer_ID;









        //$scope.pageno = 1;

        if($scope.tenanciesInput.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.tenanciesInput.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.tenanciesInput.search = $scope.search;
        }else{

            $scope.tenanciesInput.search = "*";
        }

        //$scope.obj.search = "*";

        console.log("$scope.tenanciesInput >>>>>>>>>>>>>>>>>");
        console.log($scope.tenanciesInput);

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.tenancies(id, $scope.tenanciesInput).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                if(status == '60'){

                    $scope.arrearsProcessesLinkedToAccount = response.data.arrearsProcessesLinkedToAccount;


                }
                else if(status == 72){
                    console.log(response);
                }
                else{

                    //$scope.$parent.obj2 = {};
                    $scope.tenancies = response.data;
                    $rootScope.tenancy_id = $scope.processAccount.data.Tenancy_ID;
                    $rootScope.property.Property_name = $scope.tenancies.data.Property_name;
                    $rootScope.property.Unit_number = $scope.tenancies.data.Unit_number;

                    console.log(response);

                    console.log(response.data);
                    console.log(response.data.data);

                    console.log(response.data.data.Tenancy_ID);
                    console.log("$rootScope.tenancy_id exists " + $rootScope.tenancy_id);
                    console.log("$rootScope.property.Unit_number " + $rootScope.property.Unit_number);
                    console.log("$rootScope.property.Property_name " + $rootScope.property.Property_name);

                    console.log("set variable $scope.tenancies.tenancyDebtAccount");
                    $scope.tenancies.tenancyDebtAccount = response.data.tenancyDebtAccount;

                    if($scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date && $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date != "0000-00-00"){
                        $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.CCJ_Claim_Issue_Date, 0);
                    }
                    if($scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date && $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date != "0000-00-00") {
                        $scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Trace_Results_Received_Date, 0);

                    }
                    if(!isNaN($scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date && $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date != "0000-00-00")) {
                        $scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Tracing_Fee_Paid_Date, 0);

                    }
                    if(!isNaN($scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date && $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date != "0000-00-00")){

                        $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Debt_Account_Closed_Date, 0);

                    }

                    console.log(response);
                    //$scope.tenancies.tenancyDebtAccount = response.data

                    /*
                    $rootScope.Property_ID = $scope.obj.data.Property_ID;
                    $rootScope.property.Property_name = $scope.obj.data.Property_name;
                    $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                    */

                }
                /*
                if(status == 72){
                    console.log("set variable $scope.tenancies.tenancyDebtAccount");
                    $scope.tenancies.tenancyDebtAccount = response.data.tenancyDebtAccount;

                    $scope.tenancies.tenancyDebtAccount.CCJ_Claim_Issue_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Trace_Results_Received_Date, 0);
                    $scope.tenancies.tenancyDebtAccount.Trace_Results_Received_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Trace_Results_Received_Date, 0);
                    $scope.tenancies.tenancyDebtAccount.Tracing_Fee_Paid_Date = dateInputService.dateInputDayService(response.data.tenancyDebtAccount.Trace_Results_Received_Date, 0);
                    $scope.tenancies.tenancyDebtAccount.Debt_Account_Closed_Date = dateInputService.dateInputDayService(response.tenancyDebtAccount.Trace_Results_Received_Date, 0);

                    console.log(response);

                }
                */
                console.log(response);
                console.log("tenancies API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.paymentTransactionsFunction = function(id, status) {
        console.log("function paymentTransactionsFunction bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");

        //$scope.paymentTransactionsData.search = "*";

        var qpaymentTransactionsFunction5 = $q.defer();
        console.log($scope.paymentTransactionsData);



        if(status == "2"){
            q2.resolve($state.go('lettings-main-nav.financials-side.payment-transaction-edit', {id:  id}));

        }else{

            $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.paymentTransactionsData.itemsPerPage = $scope.itemsPerPage;
            $scope.paymentTransactionsData.pageno = $scope.pageno;
            $scope.paymentTransactionsData.Status = status;

            if($rootScope.tenancy_id){
                $scope.paymentTransactionsData.Tenancy_ID = $rootScope.tenancy_id;
            }


            if($scope.paymentTransactionsData.Payment_Transaction_ID){
                console.log("$scope.paymentTransactionsData.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{
                $scope.paymentTransactionsData.Payment_Transaction_ID = "0";
            }

            if($rootScope.search){
                $scope.paymentTransactionsData.search = $rootScope.search;
            }else{
                $scope.paymentTransactionsData.search = "0";
            }

            console.log("About to enter API Service: paymentTransactions");
            console.log($scope.paymentTransactionsData);

            apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                function (response) {

                    console.log("API Service: paymentTransactions");

                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    //$scope.paymentTransactions = response.data;
                    //$scope.$parent.totalCount = $scope.totalCount;
                    //$scope.$parent.itemsPerPage = 100000;

                    $scope.tenantsRentalTransactions = response.data;
                    $rootScope.tenantsRentalTransactions = $scope.tenantsRentalTransactions;
                    console.log("$scope.tenantsRentalTransactions: " +  $scope.tenantsRentalTransactions);
                    console.log($scope.tenantsRentalTransactions);
                    return $scope.tenantsRentalTransactions;

                    qpaymentTransactionsFunction5.resolve(response);




                },
                function () {
                    console.log("There was an error in paymentTransactions");
                }
            )
        }


        return qpaymentTransactionsFunction5.promise;
    }

    $scope.gotoAnchor = function(x) {
        console.log("inside function gotoAnchor");
        var newHash = x;
        if ($location.hash() !== newHash) {
            // set the $location.hash to `newHash` and
            // $anchorScroll will automatically scroll to it
            $location.hash(x);
        } else {
            // call $anchorScroll() explicitly,
            // since $location.hash hasn't changed
            $anchorScroll();
        }
    };

    $scope.loadPageData = function () {
            console.log("inside function loadPageData");
            //$scope.$state = $state;
            var qloadPageData = $q.defer();

            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function () {

                    console.log('promise then processAccount');
                    //console.log(auth);
                    return $scope.processesAccountFunction($stateParams.processAccountID, 64);
                })
                .then(function () {

                    console.log('promise then initialPageLoad');
                    return $scope.initialPageLoad();
                })
                .then(function () {

                    console.log('A7b hide data');
                    $('.add-transactions-data').hide();

                    //$scope.myValue = data;
                }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

            qloadPageData.resolve($scope.promise);

        }

    $scope.variableSpinner = 1;

    console.log('$scope.processAccountDataLoaded' + $scope.processAccountDataLoaded);
    console.log($scope.processAccount);


    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    //$scope.initialPageLoad();

    //////////////////////////  Redundant  /////////////////////////////////////////////////////////////////////////////////

    $scope.loadPageData();
    //})



}])