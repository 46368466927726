/**
 * Created by cyril on 12/03/2017.
 */
clouderty.directive('andrei_import', ['$scope','$http', function () {
    return{
        restrict: 'E',
        template: "<button class='btn btn-default'></button>",
        link: function (scope, element, attr) {
            var postData = [
                {"Test_Column_1":"A test 1",
                 "Test_Column_2":"A test 2",
                 "Test_Column_3":"A test 3",
                 "Test_Column_4":"A test 4"
                },
                {"Test_Column_1":"A test 11",
                "Test_Column_2":"A test 22",
                "Test_Column_3":"A test 33",
                "Test_Column_4":"A test 44"
                }
            ];

            function importData(){
                for(var index = 0; index < postData.length; index++){
                    $http.post('api/tests/dataImportAndrei', postData[index]).success(function(response) {
                        console.log(response);
                    });

                }
            }
            $(element).on('click touch', importData);
        }
    }
}]);