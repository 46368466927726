/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.factory('authenticateUserLevelService', ["$window", "$http", "$location", "$state", function($window, $http, $location, $state) {
    return{

        authenticateUserLevelService: function(userLevel, setSecurityLevel, activity){

            $http.get("api/global_userlevels/" + setSecurityLevel).success(function(response){

                var userLevels = response;

                console.log("userLevels.data.User_Level: " + userLevels.data.User_Level);
                console.log("userLevel: " + userLevel);
                console.log("setSecurityLevel: " + setSecurityLevel);


                if (userLevel >= userLevels.data.User_Level) {

                    console.log("User level is okay.");


                } else {
                    console.log("bbbbbbbbbbbbb");
                    confirm('You do not have the necessary security clearacence to perform this function')
                    $state.go("lettings-main-nav.lettings-side.lettings-search-tenancy");
                    return "Exit";
                }




            });



        }
    };
}]);
