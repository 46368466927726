/**
 * Created by Aaron on 14/07/2016.
 */


/*clouderty.factory('postNoteService', ["$scope", "$http", "$stateParams", "$uibModal", "Upload", "$rootScope", "$window", function($scope, $http, $stateParams, $uibModal, Upload, $rootScope, $window) {*/
clouderty.factory('sendEmailInternalPostNote', ["$state", "$window", function($state, $window) {

    return{

        postNote: function(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email,subject,message, property, task_id, $state, email_signature){



            var date = new Date();
            date.setHours(date.getHours() + 1);

            $scope.note_loading = true;
            $scope.note.user_id = $scope.decodedToken.id;
            $scope.note.note_type = note_type;
            $scope.note.date = date;
            $scope.note.note = note;
            $scope.note.id = task_id;
            $scope.note.Task_ID = task_id;

            console.log("sendEmailInternalPostNote - 1: " + $scope.note.id);


            //$scope.note.note_category_type = note_category_type;
            $scope.note.select_note_type = note_category_type;



            console.log("sendEmailInternalPostNote - 2: " + $scope.note.select_note_type);

            //Task testing
            //task_name: task_name;
            $scope.task_name = task_name;


            //console.log("hello world2");
            console.log("sendEmailInternalPostNote - 3: hello apinotes");
            $http.post("api/notes", $scope.note)

                .success(function (response) {

                    $scope.posted_note = response.posted;

                    $scope.uploadFile($scope.picFile, note_type);
                    $scope.obj.note = "";
                    console.log("sendEmailInternalPostNote - 4: in apinotes");
                    // Send email confirmation | Task Notes
                    if (confirm('Are you sure you want to send an email notification?')) {

                        //var from_email = "Clouderty | Note Created <notifications@clouderty.co.uk>";
                        if ($scope.obj.data.Address_1 == null){
                            var from_email = "Clouderty | Note Created"  + " <notifications@clouderty.co.uk>";
                            var subject = "By: " + $rootScope.decodedToken.Name + " | " + $scope.task_name + " | " + "Task ID: " + $scope.posted_note.id + " | Note ID: " + $scope.posted_note.note_id;
                            var message = "Note ID: " + $scope.posted_note.note_id + "\n" +
                                "As Part Of Task: " + $scope.posted_note.id + "\n" +
                                "Created By: " + $rootScope.decodedToken.Name + "\n" +
                                //"Task name: " + task_name + "\n\n" +
                                "Task name: " + $scope.task_name + "\n\n" +

                                "Note Content: " + $scope.posted_note.note;


                        } else {
                            var from_email = "Clouderty | Note Created | " +  $scope.obj.data.Property_name  + " <notifications@clouderty.co.uk>";
                            var subject = "By: " + $rootScope.decodedToken.Name + " | "  + $scope.obj.data.Property_name + " | " + $scope.task_name + " | " + "Task ID: " + $scope.posted_note.id + " | Note ID: " + $scope.posted_note.note_id;
                            var message = "Note ID: " + $scope.posted_note.note_id + "\n" +
                                "As Part Of Task: " + $scope.posted_note.id + "\n" +
                                "Created By: " + $rootScope.decodedToken.Name + "\n" +
                                //"Task name: " + task_name + "\n\n" +
                                "Task name: " + $scope.task_name + "\n" +
                                "Property: " + $scope.obj.data.Address_1 + "\n\n" +
                                "Note Content: " + $scope.posted_note.note;
                            console.log("sendEmailInternalPostNote - 6: Service alert3");

                        }
                        var to_email = "rentals@platinumcrown.co.uk";


                        //"Note Content: " + $scope.posted_note.Description + "\n\n" +
                        //"Note Content: " + $scope.posted_note.data.Description + "\n\n";
                        //"Task name: " + $scope.posted_note.obj.data.Description;
                        //
                        console.log("Send email");
                        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                        console.log("Email sent");
                        // Send email code | Task Notes

                    } else {
                        // Do nothing!
                    }

                    // email send finish
                    console.log("Service alert4");

                })

            /*

           */
            
            return 0;
        }

    };

}]);
