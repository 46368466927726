/**
 * Created by Aaron on 04/07/2016.
 */
var clouderty = angular.module("clouderty", ['ui.router','angularUtils.directives.dirPagination','angular-jwt', 'ui.bootstrap', 'ngFileUpload', 'ngCookies', 'ngSanitize', 'ngCsv', 'ngCsvImport', 'fCsv', 'ngAnimate', 'ngRoute', 'ngMaterial', 'uiGmapgoogle-maps', 'ngFileUpload', 'signature'])

    .filter('positive', function() {
        return function(input) {
            if (!input) {
                return 0;
            }

            return Math.abs(input);
        };
    })

    .filter('reverse', function() {
        return function(input, uppercase) {
            input = input || '';
            var out = '';
            for (var i = 0; i < input.length; i++) {
                out = input.charAt(i) + out;
            }
            // conditional based on optional argument
            if (uppercase) {
                out = out.toUpperCase();
            }
            return out;
        };
    })
    .filter('sumOfValue', function () {
        return function (data, key) {
            if (angular.isUndefined(data) || angular.isUndefined(key))
                return 0;
            var sum = 0.00;
            angular.forEach(data,function(value){
                sum = sum + parseFloat(value[key]);
            });
            return sum;
        }
    })
    .filter('totalSumPriceQty', function () {
        return function (data, key1, key2) {
            if (angular.isUndefined(data) || angular.isUndefined(key1)  || angular.isUndefined(key2))
                return 0;
            var sum = 0.00;
            angular.forEach(data,function(value){
                sum = sum + (parseFloat(value[key1]) * parseFloat(value[key2]));
            });
            return sum;
        }

    })
    .filter('yesNo', function() {
        return function(input) {
            return input ? 'yes' : 'no';
        }
    })