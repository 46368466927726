/**
 * Created by Cyril & Aaron on 25/07/2016.
 */
clouderty.controller("vendorsSearchCtrl", ["$scope", "$http", "$rootScope", function ($scope, $http, $rootScope) {
    $scope.vendors = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.getData = function(pageno) {
        $scope.vendors = [];

        if ($scope.search)
        {
            $http.get("api/vendors/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){
        
                $scope.vendors = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/vendors/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.vendors = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
    }

    $scope.getData($scope.pageno);

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }
}]);