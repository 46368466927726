/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("loginExternalWebPageService", ["$window", "$q", "$http", "$cookies", "$rootScope", function ($window, $q, $http, $cookies, $rootScope) {

    this.init = function(status) {

        console.log("inside loginExternalWebPageService init service");

        var q12 = $q.defer();
        var email = "external@clouderty.co.uk";
        var Notifications_Email = "notifications@clouderty.co.uk";
        var password = "platinumlaptop";
        //$scope.myValue = 0;

        self.emailAddress = email;
        self.password = password;
        self.password = Notifications_Email;

        $rootScope.Notifications_Email = Notifications_Email;

        console.log(">>>>>>>>>>> $rootScope.Notifications_Email: " + $rootScope.Notifications_Email);


        $cookies.putObject("userName", email);
        $cookies.putObject("password", password);
        $cookies.putObject("Notifications_Email", Notifications_Email);
        //console.log("Hello 2:" + $cookies.getObject("userName"));
        //console.log("Password 2:" + $cookies.getObject("password"));

        login_details = {};

        login_details.email = email;
        login_details.password = password;
        login_details.Notifications_Email = Notifications_Email;

        console.log(login_details);

        $http.post("api/authenticate", login_details)
            .success(function(response) {

                if (response.success == false) {
                    console.log("Incorrect Login Details");

                    alert("Incorrect Login Details");
                }
                else  {
                    console.log("Correct Login Details");
                    if ($window.sessionStorage.token) {
                        $window.sessionStorage.removeItem("token");
                    }
                    $window.sessionStorage.token = response.token;

                }
                q12.resolve(response);
            })

        return q12.promise;

    }


}])
