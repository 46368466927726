/**
 * Created by Aaron on 05/07/2016.
 */
clouderty.controller("sideMenuCtrl", ['$scope', '$http', "$state", "$stateParams", "showHideService", "$rootScope", function ($scope, $http, $state, $stateParams,showHideService, $rootScope) {

    $(document).ready(function() {
        var nav_collapse = true;

        $scope.showHide = function (showLettings, showVendor, showLandlord, showTenant, showViewings, showAccountHolder, showCloudertyAdmin) { //
            // console.log("********** showHide enter *********");
            return showHideService.showHide(showLettings, showVendor, showLandlord, showTenant, showViewings,showAccountHolder, showCloudertyAdmin, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $rootScope.decodedToken.User_Type_ID);
        }

        function calculateContentWidth () {
            var side_nav_width = $(".side-nav").width();
            console.log(side_nav_width);
            $(".content").css("width", "calc(100% - " + side_nav_width + "px)");
        }


        function navCollapse(width, collapse) {

            $(".side-nav").css("width", width);

            if (collapse) {
                console.log("Collapse");
                $(".nav-expand-collapse").show();
                $(".side-nav-text").show();
                $(".i.fa-bars").show();
            }
            else  {
                console.log("!Collapse");
                //$(".nav-expand-collapse").hide();
                $(".side-nav-text").hide();
                $(".nav-expand-collapse").show();

                $(".nav-expand-collapse").show();
                //$(".fa-bars").hide();
            }
            calculateContentWidth();
        }





        calculateContentWidth();

        $(".side-nav-text").hide();

        $(".side-nav-collapse").click(function () {
            if (nav_collapse) {
                console.log("Collapse2");
                navCollapse(300, nav_collapse)
                $(".side-nav-text").show();
                nav_collapse = false;
            } else {
                console.log("!Collapse2");
                navCollapse(68, nav_collapse)
                $(".side-nav-text").hide();
                $(".nav-expand-collapse").show();
                nav_collapse = true;
            }
        })
    })

    $scope.back = function() {
        window.history.back();
    }

    $scope.forward = function() {
        window.history.forward();
    }


    $scope.locationChangeFunction = function(){
        $state.go($scope.contractors-main-nav.contractors-tasks-side.tasks-search);
    }


    $scope.selectSideNavOption = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        if(progress == "0") {
            console.log("Dashboard");
            $state.go('lettings-main-nav.lettings-side.main-dashboard');

        }
        else if(progress == "1") {
            console.log("Add tenant");
            $state.go('lettings-main-nav.lettings-side.lettings-add-tenant');

        }
        else if (progress == "2") {
            console.log("View Tenants");
            $state.go('lettings-main-nav.lettings-side.lettings-search-tenant');
        }
        else if (progress == "3") {
            console.log("Add Viewings");
            $state.go('lettings-main-nav.lettings-side.lettings-add-tenant-viewing');
        }
        else if (progress == "4") {
            console.log("Tenant viewing");
            $state.go('lettings-main-nav.lettings-side.lettings-tenant-viewings-search');
        }
        else if (progress == "5") {
            console.log("Add Tenancy");
            $state.go('lettings-main-nav.lettings-side.lettings-add-tenancy');
        }
        else if (progress == "6") {
            console.log("Tenancy Search");
            $state.go('lettings-main-nav.lettings-side.lettings-search-tenancy');
        }
        else if (progress == "7") {
            console.log("Add Payment");
            $state.go('lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add');
        }
        else if (progress == "8") {
            console.log("");
            $state.go('');
        }
        else if (progress == "9") {
            console.log("Search Process Account");
            $state.go('lettings-main-nav.lettings-side.process-account-search');
        }
        else if (progress == "10") {
            console.log("Processes Search");
            $state.go('lettings-main-nav.lettings-side.process-account-add-edit');
        }
        else if (progress == "11") {
            console.log("Task Search");
            $state.go('lettings-main-nav.tasks-side.tasks-search');
        }
        else if (progress == "12") {
            console.log("Add Task");
            $state.go('lettings-main-nav.tasks-side.task-add');
        }
        else if (progress == "13") {
            console.log("Add Timesheet");
            $state.go('lettings-main-nav.tasks-side.timesheet-add-edit');
        }
        else if (progress == "14") {
            console.log("View Timesheet");
            $state.go('lettings-main-nav.tasks-side.timesheet-search');
        }
        else if (progress == "15") {
            console.log("Add Meter Reading");
            $state.go('lettings-main-nav.tasks-side.meter-reading-add-edit');
        }
        else if (progress == "16") {
            console.log("View Meter Readings");
            $state.go('lettings-main-nav.tasks-side.meter-readings-search');
        }
        else{

        }

    }
}])

