clouderty.service("templateService", [ '$http', function ($http) {
        'use strict';

        var service = {};

        service.saveNotification = saveNotification;
        service.fetchNotifications = fetchNotifications;
        service.updateNotificationStatus = updateNotificationStatus;
        service.updateClickedStatus = updateClickedStatus;
        return service;

        function saveNotification(notification,callback) {
            return $http.post('api/notifications', notification,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){
						callback(res);
				  }, function(err){
						callback(null,err);
				});
        }



        function fetchNotifications(callback) {

        	console.log("inside fetchNotifications");
        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1 
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor

        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.
            return $http.get('api/notifications?fetchnot=true&user_id_pm_1='+user_id_pm_1,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){

				  	console.log(res);
						callback(res);
				  }, function(err){

				  	console.log(err);
				  	callback(null,err);

				});
        }

        function updateNotificationStatus(callback) {


        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1 
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor


        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.
        	return $http.get('api/template_php_mysql_function.php?updateStatus=true&user_id_pm_1='+user_id_pm_1,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){
						callback(res);
				  }, function(err){
						callback(null,err);
				});
        }

        function updateClickedStatus(notificationId , callback) {

        	
        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1 
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor


        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.
        	return $http.get('api/template_php_mysql_function.php?updateClickedStatus=true&user_id_pm_1='+user_id_pm_1+'&notification_id='+notificationId,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){
						callback(res);
				  }, function(err){
						callback(null,err);
				});
        }
}]);
