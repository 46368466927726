/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("paymentTransactionRequestCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "dateInputService", "moment", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, dateInputService, moment) {
    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    console.log("we are in paymentTransactionRequestCtrl");

    $scope.openPaymentPage = function () {

        //var url = $state.href('lettings-main-nav.financials-side.payment-transaction-request', {parameter: "parameter"});
        var url = $state.href('payment-transaction-request-invoice', {id: "1"});
        window.open(url, '_blank');
    }

}]);