/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("tenantsRentalTransactionsCtrl", ["$scope", "$http", "$stateParams", "$filter", "Upload", "$rootScope", "$state", "$q", "apiService", "yesNoFilter", "postUserActivityService", function ($scope, $http, $stateParams, filter, Upload, $rootScope, $state, $q, apiService, yesNoFilter, postUserActivityService)  {

    console.log("inside controler tenantsRentalTransactionsCtrl jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj")

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });


    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.tenantsRentalTransactions = {};
    $rootScope.tenantsRentalTransactions = {};
    $scope.paymentTransactions = {};
    $scope.paymentTransactionsData = {};


    $scope.tenancies = {};
    $scope.tenancy = [];
    $scope.tenanciesExport = [];
    $scope.tenanciesExportHeaders = [];
    $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.tenanciesExport2 = [];
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.tenancyID = "current";
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.filename = "test" + ".csv";
    $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.paymentTransactionsFunction = function(id, status) {
        console.log("function paymentTransactionsFunction bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");

        //$scope.paymentTransactionsData.search = "*";

        var qpaymentTransactionsFunction5 = $q.defer();
        console.log($scope.paymentTransactionsData);



        if(status == "2"){
            q2.resolve($state.go('lettings-main-nav.financials-side.payment-transaction-edit', {id:  id}));

        }else{

            $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.paymentTransactionsData.itemsPerPage = $scope.itemsPerPage;
            $scope.paymentTransactionsData.pageno = $scope.pageno;
            $scope.paymentTransactionsData.Status = status;

            if($rootScope.tenancy_id){
                $scope.paymentTransactionsData.Tenancy_ID = $rootScope.tenancy_id;
            }


            if($scope.paymentTransactionsData.Payment_Transaction_ID){
                console.log("$scope.paymentTransactionsData.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{
                $scope.paymentTransactionsData.Payment_Transaction_ID = "0";
            }

            if($rootScope.search){
                $scope.paymentTransactionsData.search = $rootScope.search;
            }else{
                $scope.paymentTransactionsData.search = "0";
            }

            console.log("About to enter API Service: paymentTransactions");
            console.log($scope.paymentTransactionsData);

            apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                function (response) {

                    console.log("API Service: paymentTransactions");

                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    //$scope.paymentTransactions = response.data;
                    //$scope.$parent.totalCount = $scope.totalCount;
                    //$scope.$parent.itemsPerPage = 100000;

                    $scope.tenantsRentalTransactions = response.data;
                    $rootScope.tenantsRentalTransactions = $scope.tenantsRentalTransactions;
                    console.log("$scope.tenantsRentalTransactions: " +  $scope.tenantsRentalTransactions);
                    console.log($scope.tenantsRentalTransactions);
                    return $scope.tenantsRentalTransactions;

                    qpaymentTransactionsFunction5.resolve(response);




                },
                function () {
                    console.log("There was an error in paymentTransactions");
                }
            )
        }


        return qpaymentTransactionsFunction5.promise;
    }

    $scope.getData = function(pageno) {

        console.log('function getData');



        console.log("function tenanciesFunction");
        $scope.tenantsRentalTransactions = {};
        $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
        $scope.tenancies.pageno = $scope.pageno;
        $scope.tenancies.Tenancy_ID = 0;
        $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
        $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
        $scope.tenancies.Status = '51';

        if($scope.tenancies.Tenancy_ID){
        }else{
            $scope.tenancies.Tenancy_ID = "*";
        }


        if($scope.search){
            $scope.tenancies.search = $scope.search;
        }else{
            $scope.tenancies.search = "*";
        }

        var q2 = $q.defer();

        apiService.tenancies($scope.tenancies.Status, $scope.tenancies).then(
            function (response) {

                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;


                //$scope.paginationData = response.data.data;
                //$scope.tenancies = response.data.data;
                $scope.rentTotal = response.data.rentTotal;
                //$scope.totalTaskCount = response.data.totalCount;

                $scope.tenantsRentalTransactions = response.data;
                $rootScope.tenantsRentalTransactions = response.data;
                //$scope.tenantsRentalTransactionsOther = response.accountBalanceNotRent;
                console.log($scope.tenancies);

                //console.log(response.accountBalanceNotRent);
                console.log("tenancies API in getData");
                console.log(response);
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;



    }

    $scope.getDataTenancies = function(pageno) {



        console.log('function getDataTenancies:');
        $scope.tenantsRentalTransactions = [];

        if ($scope.search)
        {

            $http.get("api/tenants_rental_transactions/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                $scope.tenantsRentalTransactions = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            })
        }
        else
        {



            $scope.paymentTransactionsFunction("0","11");




        }



    }

    $scope.actionUpdateTenancy = function (action, tenantID, tenancyID, licenseKey) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "1") {
            //$scope.progress.date_completed = date;
            console.log("Edit Tenant");
            $scope.editTenant(tenantID);
        } else if (action == "2") {
            console.log("Edit Tenant");
            //$scope.getDataExpired(1)
            $scope.editTenancy(tenancyID);
            //$scope.progress.date_completed = null;
        } else if (action == "3") {
            console.log("View Rental Statement");

            console.log("tenancyID: " + tenancyID);

            console.log("licenseKey: " + licenseKey);
            //$scope.getDataExpired(1)
            $scope.viewTenancyRentalStatement(tenancyID, licenseKey);
            //$scope.progress.date_completed = null;
        } else if (action == "4") {
            console.log("View arrears account");
            //$scope.getDataExpired(1)
            $scope.viewTenancyArrearsAccount(tenancyID)
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (action == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData($scope.pageno)

        } else{

        }

    }

    $scope.editTenant = function(tenantID) {
        //$scope.tenants = null;
        console.log("Tenant ID = " + tenantID);
        //console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
        $http.get('api/tenant/' + tenantID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response) {
            $scope.tenants = response;

            console.log(response);
            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenanct: ' + $scope.tenants.First_Name + ' - ' + tenantID);

            var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            window.open(url,'_blank');
            //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  tenantID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});



            return $scope.tenants;
        })
    }

    $scope.editTenancy = function(tenancyID) {

        console.log("Tenancy ID = " + $scope.tenancy.Tenancy_ID);
        $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  tenancyID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});

        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenancy: ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + $scope.tenancy.Tenancy_ID);

        console.log("apiService.searchTenancy");

    }

    $scope.viewTenancyRentalStatement = function(tenancyID, licenseKey) {

        console.log("function viewTenancyRentalStatement");
        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View tenancy rental statement - ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + tenancyID);

        console.log("Tenancy ID = " + tenancyID);

        var url = $state.href('tenancy-rental-statement-license', {tenancyID:  tenancyID, licenseKey: licenseKey});
        window.open(url,'_blank');
        //$state.go('tenancy-rental-statement-license', {tenancyID:  tenancyID, licenseKey: licenseKey});


    }

    $scope.viewTenancyArrearsAccount = function(tenancyID) {

        console.log("function viewTenancyArrearsAccount");
        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View tenancy arrears account - ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + tenancyID);

        console.log("Tenancy ID = " + tenancyID);

        var url = $state.href('lettings-main-nav.lettings-side.lettings-view-tenancy-arrears-account.notes', {tenancyID:  tenancyID});
        window.open(url,'_blank');
        //$state.go('tenancy-rental-statement-license', {tenancyID:  tenancyID, licenseKey: licenseKey});


    }

    if ($state.is('lettings-main-nav.lettings-side.tenants-rental-transactions-search')) {
     console.log('Tenancy ID 1');
        $scope.getData($scope.pageno);
    }
    else {
     console.log('Tenancy ID 2: $stateParams.id');
        $scope.getDataTenancies($scope.pageno);
    }

    // sort function
    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }
    console.log("Next 7");

}])