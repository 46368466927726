/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("jobViewCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "apiService", "$q", "truncateSliceService",  function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, apiService, $q, truncateSliceService) {
    /* Promises

    q - function actionSelectVendors
    qa - function loadData
    q2 - function uploadFile
    q2a - function getVendorsTasks
    q3 - function searchVendors
    q5 - function getData
    q11 - post api/notes
    q22 - function tasksFunction
    q55 - function getGlobalPromise
    q59 - function getTaskNotes


    */

    $scope.obj = {};

    $scope.obj.Task_ID = $stateParams.id;
    //$scope.search;
    $scope.progress = {};
    $scope.vendorTasks = {};
    $scope.vendorTasks2 = {};
    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    // reset record counter
    $scope.totalCount = "";
    $scope.obj.vendor_id = "";
    $scope.posted_note = [];
    $scope.posted_note.File_ID = "";
    $scope.posted_note.fileName = "";
    $scope.posted_note.fileURL = "";

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    if ($stateParams.id) {
        console.log("ID: " + $stateParams.id);
    }

    console.log("Task ID: " + $stateParams.id);
    console.log("email: " + $rootScope.decodedToken.Notifications_Email)

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $(".mobile-notes-button").click(function() {
        $('.content').animate({
            scrollTop: $(".notes-search-section").offset().top
        }, 500);
        $(".mobile-notes-button").hide();
    });

    $('.progress-select').select2({
        minimumResultsForSearch: -1
    });
    console.log("taskViewCtrl: " + $rootScope.decodedToken.User_Level);

    $scope.tasksFunction = function(id, status) {

        console.log("function tasksFunction");
        $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.obj.itemsPerPage = $scope.itemsPerPage;
        $scope.obj.pageno = $scope.pageno;
        $scope.obj.Task_ID = id;
        $scope.obj.Vendor_ID = "*";
        $scope.obj.Property_ID = "*";
        $scope.obj.Status = status;


        if($scope.obj.Task_ID){

            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.obj.Task_ID = "*";
        }


        if($scope.search){

            $scope.obj.search = $scope.search;
        }else{

            $scope.obj.search = "*";
        }

        //$scope.obj.search = "*";

        var q22 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.tasks(id, $scope.obj).then(
            function (response) {


                if(status == "11"){

                    $scope.taskStatuses = response.data.data;

                }else{
                    $scope.paginationData = response.data.data;
                    $scope.tasks = response.data.data;
                    $scope.$parent.obj = response.data.data;
                    $scope.currentPage = pageno;
                    $scope.totalTaskCount = response.data.totalCount;
                    $scope.totalCount = response.data.totalCount;


                }

                console.log("tasks API");
                q22.resolve(response);
            },
            function () {
                console.log("API Service Error: getAllUsersTasksNotCompletedSearch")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q22.promise;
    }

    $scope.getTaskStatus = function () {
        console.log(" >>>>>>>>>>>>>>>>>  inside function getTaskStatus");
        $scope.tasksFunction('*', '11');
    }

    $scope.getTaskStatus();

    $scope.getData = function () {

        console.log("Task ID: " + $stateParams.id);
        console.log("Task ID: " + $stateParams.id);
        var q5 = $q.defer();

        if ($rootScope.decodedToken.User_Type_ID == 1) {
            console.log("getTask 1: " + $rootScope.decodedToken.User_Level);
            $http.get("api/task/" + $stateParams.id).success(function (response) {
                $scope.$parent.obj = response.data;
                $scope.obj = response.data;
                //$scope.$parent.progress.id = response.data.Task_ID;
                $scope.obj.Task_ID = $stateParams.id;
                $scope.obj.Task_ID2 = $stateParams.id;

                console.log(response);
                q5.resolve(response);
            })

            return q5.promise;
        }
        else if ($rootScope.decodedToken.User_Type_ID == 2) {
            console.log("getTask 2: " + $rootScope.decodedToken.User_Level);
            console.log("Task ID: " + $stateParams.id);
            $http.get("api/task/" + $stateParams.id + "/" + $rootScope.decodedToken.Vendor_ID).success(function (response) {
                $scope.$parent.obj = response.data;
                $scope.$parent.progress.id = response.data.Task_ID;
                q5.resolve(response);
            })

            return q5.promise;
        }
    }


    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.obj.select_note_type = "Email";

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

        //$scope.b = postNoteService.postNote('Test Task', 'Task', 'Content of test note', 'Email', $scope, $http, $rootScope);
        //postNote("ssssssssss", 'Task', obj.note, obj.select_note_type);
        //console.log("What now3");

    };

    $scope.checkUnitNumber = function(unit_number){

        //console.log(unit_number);
        $scope.obj.unit_number_text = ' - Unit ' + unit_number;

        if(unit_number == null){


        }else{
            return $scope.obj.unit_number_text;
        };

    };

    $scope.reloadView = function(){

        //$route.reload('lettings-main-nav.tasks-side.task-view.notes');
    };

    $scope.sendEmailInternalPostNote = function(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature){

        message = message + '<br /><br />' + email_signature;

        if(to_email==null){
            alert("Enter an email address");
        } else {

            note_category_type = "Email";
            $scope.obj.select_note_type = "Email";
            //from_email = "notifications@clouderty.co.uk"

            if (unit_number == null) {


            } else {

                property = property + " - Unit" + unit_number
            }
            ;

            //$scope.a = emailService.openEmailSender(to_email, subject, message, property);
            // $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            console.log("sendEmailInternalPostNote Controller Function - 1: " + to_email);
            $scope.a = $rootScope.sendEmail('notifications@clouderty.co.uk',to_email,subject,message,$rootScope.decodedToken.Name, email_signature);
            //console.log("Email sent");
            $scope.b = sendEmailInternalPostNote.postNote(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email, subject, message, property, task_id, unit_number, email_signature);


            $state.reload();

        }


    };

    $scope.updateProgress = function (progress, taskID) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Task 1: " + $scope.obj.Status);

        console.log("Task ID: " + taskID);
        $scope.progress.progress = progress;
        $scope.progress.id = taskID;
        $scope.progress.date_completed = date;

        if($scope.progress.progress == "Completed") {




            console.log("Task completed: " + progress);

            $http.put("api/tasks/progress", $scope.progress).success(function(response) {
                if($scope.progress.progress == "Completed") {                //$scope.open();
                    console.log("Task updated as completed");
                    //$scope.obj = response.data;
                    //$state.reload();
                }


            })


            console.log("Send email: " + progress);

            var from_email = "Clouderty | Task Completed " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Closed by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date completed: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");

            /*
            var from_email = "Clouderty | Task Completed | "  + $scope.obj.data.Property_name + " | " + $scope.obj.data.Description + " | " +  " <notifications@clouderty.co.uk>";
            var subject = "Clouderty | Task Completed | " + " - Completed by: " + $rootScope.decodedToken.Name + " | Task ID: " + $scope.obj.data.Task_ID + " | " + $scope.obj.data.Description + " - Created By: " + $scope.obj.data.Created_By;
            var message = "Clouderty | Task Completed | " +
                $rootScope.decodedToken.Name +
                " | Task ID: " + $scope.obj.data.Task_ID +
                "\n Task Name: " + $scope.obj.data.Description +
                "\nDate started: " + $scope.obj.data.Date_started +
                " | Date completed  " + $scope.obj.data.Date_completed +
                "\n\n Created by: " + $scope.obj.data.Created_By +
                "\n\n Completed By: " + $rootScope.decodedToken.Name;
            var to_email = "rentals@platinumcrown.co.uk";
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Task complete | Task ID' + $scope.obj.data.Task_ID);
            */


        } else {
            //$scope.progress.date_completed = null;

            console.log("Task not completed: " + progress);
            $scope.progress.id = taskID;
            console.log("Task Date Null: " + progress);
            $scope.progress.date_completed = null;
            $http.put("api/tasks/progress", $scope.progress).success(function(response) {
                //$state.reload();
            })

            var from_email = "Clouderty | Task Updated " + $scope.obj.Task_ID + " | " + " <notifications@clouderty.co.uk>";
            var subject = "Updated by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.Description;
            var message = "Task ID: " + $scope.obj.Task_ID + "<br />" +
                "Task status: " + progress + "<br />" +
                "Closed By: " + $rootScope.decodedToken.Name + "<br />" +
                "Date updated: " + $scope.progress.date_completed + "<br /><br />" +
                "Task name: " + $scope.obj.Description + "<br /><br />";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            alert("This task has been updated");
        }




    }

    $scope.emailSignature = function() {

        $scope.email_signature = 'Kind regards, <br />' + $rootScope.decodedToken.Name + '<br /><br />Platinum Crown <br />The Colchester Centre <br />Hawkins Road <br />Colchester <br />CO2 8JX';

    };

    $scope.actionSelect = function(search_documents_action){

        if(search_documents_action == "1") {
            console.log("$stateParams.id:" + $stateParams.id);
            $state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("Task Edit");
        }else if(search_documents_action == "2") {
            $state.go('tenancy-agreement-single-let', {id: $scope.obj.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("View venancy agreement - single let");
        }else {

        }
    };

    $scope.selectVendor = function(id) {

        console.log("API Service: selectVendor")
        $scope.obj.selectedVendors = [];
        apiService.getVendorsTasks('1', '1', $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
            function(response) {
        //$http.get("api/vendors-tasks/" + id).success(function (response) {
                $scope.obj.selectedVendors.push({
                    "Vendor_ID": response.data.data.vendor_id,
                    "Description": response.data.data.description,
                    "Company_Name": response.data.data.company_name,
                    "Department": response.data.data.Department,
                    "Contact_Name": response.data.data.contact_name

                })
                $scope.obj.vendor_id = id;
                //$scope.obj.vendor_id = response.data.data.vendor_id;
                $scope.obj.description = response.data.data.description;
                $scope.obj.company_name = response.data.data.company_name;
                $scope.obj.Department = response.data.data.Department;
                $scope.obj.contact_name = response.data.data.contact_name;

                console.log("$scope.obj.vendor_id: " + $scope.obj.vendor_id);

            },
            function() {
                console.log("API Service: getVendorsTasks error")
            }
        );
        $scope.search_vendor = "";


    }

    $scope.actionSelectVendors = function(action_select, vendor_id){

        if(action_select == "1") {
            if(confirm("Do you want to remove this vendor?")) {
                console.log("Remove vendor");
                var q = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1");
                        apiService.deleteTask($scope.itemsPerPage, '1', $scope.obj.Task_ID, vendor_id, 'C', 'D', '3', 'E', 'F', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
                            function (response) {
                                console.log("tasks delete vendor API");
                                $scope.loadData();
                                return response.data.data;

                            },
                            function () {
                                console.log("tasks delete vendor API failed");
                            }
                        );
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2");

                        $scope.loadData();
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);




                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            }
        }else if(search_documents_action == "2") {

        }else {

        }
    };

    $scope.searchVendors = function() {
        console.log("in function searchVendors");
        var q3 = $q.defer();
        $scope.vendors = null;
        $http.get('api/vendors/search/' + $scope.search_vendor).success(function(response) {
            $scope.vendors = response.data;
            q3.resolve(response);
        })
        return q3.promise;
    }

    $scope.getVendorsTasks = function(pageno) {
        $scope.$parent.vendorTasks = null;
        console.log("$stateParams.id :" + $stateParams.id);
        var q2a = $q.defer();
        //$scope.$parent.vendorTasks = [];
        apiService.vendorsViewTaskRecords($scope.itemsPerPage, pageno, $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                console.log("API Service: vendorsViewTaskRecords inside $scope.obj.Task_ID: " + $scope.obj.Task_ID);
                console.log("API Service: vendorsViewTaskRecords inside bbbbbbbbbbbbbbbbbbbbbbbbbbbb: " + $stateParams.id);
                $scope.vendorTasks = response.data.data;
                $scope.$parent.vendorTasks = response.data.data;
                $scope.totalCount = response.data.totalCount;
                $scope.vendorTasks.totalCount = response.data.totalCount;

                console.log("$scope.vendorTasks.totalCount = " + $scope.vendorTasks.totalCount);
                $scope.$parent.totalCount =  $scope.totalCount;
                //$scope.$parent.itemsPerPage = $scope.itemsPerPage;
                $scope.itemsPerPage = $scope.itemsPerPage;
                $scope.currentPage = pageno;
                //console.log(response);
                //console.log(response.data);
                console.log(response.data.data);
                q2a.resolve(response);

            },
            function() {
                console.log("API Service: vendorsViewTaskRecords error: " + $stateParams.id);

            }

        );
        return q2a.promise;
    }
    $scope.obj.selectedVendorTasks = [];
    $scope.selectVendorTasks = function(id) {
        if(confirm("Do you want to add a vendor?")){
            console.log("locatoIn 1");
            $scope.obj.selectedVendors = [];
            apiService.vendorsViewTaskRecords($scope.itemsPerPage, $scope.pageno, $scope.obj.Task_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                function(response) {
                    //$http.get("api/vendors-tasks/" + id).success(function (response) {
                    $scope.obj.selectedVendors.push({
                        "Vendor_ID": response.data.data.vendor_id,
                        "Description": response.data.data.description,
                        "Company_Name": response.data.data.company_name,
                        "Department": response.data.data.Department,
                        "Contact_Name": response.data.data.contact_name
                    })

                    //$scope.obj.Task_ID = response.data.data.Task_ID;
                    //$scope.$parent.obj.vendor_id = id;
                    $scope.obj.vendor_id = id;
                    $scope.obj.description = response.data.data.description;
                    $scope.obj.company_name = response.data.data.company_name;
                    $scope.obj.Department = response.data.data.Department;
                    $scope.obj.contact_name = response.data.data.contact_name;
                    console.log("id ---- : " + id);
                    $scope.vendorTasks = [];
                    console.log("$stateParams.id" +  $stateParams.id);
                    console.log("$stateParams.id" +  $stateParams.id);
                    $http.post("api/vendors-tasks-add/" + $scope.obj.Task_ID + "/" + $scope.obj.vendor_id).success(function (response) {

                        //$scope.vendorTasks = response.data;
                        console.log($scope.vendorTasks)
                        return response;

                    })

                    $scope.loadData();

                //})
                },
                function() {
                    console.log("API Service: getVendorsTasks error")
                }
            );
        }else{
            console.log("locatopn 2");

        }

        $scope.search_vendor = "";
    }

    console.log("$scope.obj.Task_IDddddddddddddddddddddddd: " + $scope.obj.Task_ID);
    console.log("$stateParams.idcccccccccccccccccccccccccc: " + $stateParams.id);
    //$scope.obj.Task_ID = $stateParams.id;

    $scope.loadData = function() {

        console.log("C1 loadData");
        var qa = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A1");
                $scope.getData();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2");
                $scope.vendorTasks = [];
                $scope.$parent.vendorTasks = [];
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A3");
                $scope.getVendorsTasks(1);
                //$scope.getTaskNotes();
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        qa.resolve($scope.promise);
    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////



    $scope.loadData();


}]);