/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("fileUploadService", ["$http", "$q", "$rootScope", "Upload", "$httpParamSerializer",  function ($http, $q, $rootScope, Upload, $httpParamSerializer) {
    console.log("in upload service");

    var canceller, isSending = false;

    this.uploadFileToUrl = function (file, uploadUrl, dataObj) {

        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        var deffered = $q.defer();

        var fileFormData = new FormData();
            fileFormData.append('file', file);
            fileFormData.append('tableName', dataObj.tableName);

            $http.post(uploadUrl, fileFormData, {

                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}

            }).success(function (response) {
                console.log("suss",response)
                $rootScope.pageBlockSpinner = 0;
                deffered.resolve(response);

            }).error(function (response) {
                console.log("errr",response)
                $rootScope.pageBlockSpinner = 0;
                deffered.reject(response);
            });


        return deffered.promise;

    }
    this.uploadFile = function (file, note_id, path){


        console.log(file);
        if(file){


            if(file.$ngfName){
                console.log(file.$ngfName);
            }
            else{
                file.$ngfName = "";
            }

            console.log(file.fileTypeDescription);
            console.log(file.fileTypeID);
            dataObj = {};
            dataObj.path = path;
            dataObj.path1 = path;
            dataObj.path2 = path;
            dataObj.path3 = path;

            dataObj.randomParams = 111;
            dataObj.randomParams2 = "1212";

            console.log(">>>>:" + path);
            console.log(path);
            var quploadFile = $q.defer();
            console.log("inside function: uploadFile" );
            if (file) {
                console.log("inside function: uploadFile $scope.picFile loaded");
                Upload.upload({
                    url: 'api/files',
                    method: 'POST',
                    params: $httpParamSerializer(dataObj),
                    file: file,
                    data: {
                        'Account_Holder_ID': $rootScope.decodedToken.Account_Holder_ID,
                        'File_Type': 'note',
                        'ID': note_id,
                        'File_Category': 4,
                        'Path': path,
                        'File_Name_Previous': file.$ngfName,
                        'File_Type_Description': file.fileTypeDescription,
                        'File_Type_ID': file.fileTypeID
                    }
                }).then(function(response) {
                    quploadFile.resolve(response);

                });
                return quploadFile.promise;
            } else {
                //$scope.obj.notes = response.notes;
                console.log("inside function: uploadFile $scope.picFile empty");

            }



        }


    }
}])
