/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("fileDirectoriesService", ["$http", "$q", "$rootScope", "Upload", "fileUploadService", "apiService", "truncateSliceService", function ($http, $q, $rootScope, Upload, fileUploadService, apiService, truncateSliceService) {

    console.log("inside service fileDirectoriesService");

    this.createDirectory = function (dataObj) {
        var q5createDirectory = $q.defer();
        console.log("inside this createDirectory");

        apiService.fileDirectories(dataObj.id, dataObj).then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API createFileDirectory");


                console.log(response.data);
                q5createDirectory.resolve(response);


            }
        )
        return q5createDirectory.promise;
    }
    this.getFiles = function (dataObj) {
        var q5getFiles = $q.defer();
        console.log("inside this createDirectory");

        apiService.fileDirectories(dataObj.id, dataObj).then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API createFileDirectory");

                console.log(response.data);
                q5getFiles.resolve(response.data);


            }
        )
        return q5getFiles.promise;
    }

}])
