/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("meterReadingsAddEditCtrl", ["$rootScope", "$scope", "$http", "$state",  "postUserActivityService", "apiService", function($rootScope, $scope, $http, $state, postUserActivityService, apiService) {

   $scope.obj = {};
   $scope.objBackup = {};
   $scope.timesheet = {};
   $scope.meterTypes = {};
   $scope.meter_reading = {};
   $scope.units = {};

   $scope.hidePropertySearch = false;

   /*
   $scope.getProperties = function () {
       $scope.properties = null;

       apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
           function(response) {

               $scope.properties = response.data;
               console.log("API Service: searchProperties");
           },
           function() {

               console.log("There was an error");

           }
       )


      ///$http.get('api/properties/' + $scope.search_property).success(function(response) {
         ///$scope.properties = response;
         
      ///})
   }

   $scope.selectProperty = function (property_id, property_name) {
      $scope.search_property = "";
      $scope.hidePropertySearch = true;
      $scope.selected_property_name = property_name;
      
      $scope.timesheet.Property_ID = property_id;
   }*/

   $scope.searchUnit = function() {
        $scope.units = null;

        apiService.searchUnits($scope.search_unit, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data.data;
                //console.log("$scope.units.Unit_ID: " + response.data.data.Unit_ID);
                console.log("$scope.units.Unit_ID: " + response.data.Unit_ID);
            },
            function() {
                console.log("There was an error apiService.searchTenant")
            }
        )

    }

   $scope.selectUnit = function(id) {
        console.log("selectUnit");
        $scope.obj.selectedUnits = [];

       apiService.getUnit(id, $rootScope.decodedToken.Account_Holder_ID).then(
           function(response) {

               $scope.obj.selectedUnits.push({
                   "Unit_ID": response.data.data.Unit_ID,
                   "Address": response.data.data.Address_1,
                   "Unit_Number": response.data.data.Unit_number,
                   "Town": response.data.data.Town,
                   "City": response.data.data.City,
                   "Postcode": response.data.data.Postcode
               })
               console.log("selectedUnits.push");
               $scope.obj.Address_1 =  response.data.data.Address_1;
               $scope.obj.Property_name =  response.data.data.Property_name;
               $scope.obj.Postcode =  response.data.data.Postcode;
               $scope.obj.Unit_number =  response.data.data.Unit_number;
               $scope.obj.Unit_ID =  response.data.data.Unit_ID;
               $scope.obj.Property_ID =  response.data.data.Property_ID;
               $scope.meter_reading.Property_ID = $scope.obj.Property_ID;
               $scope.meter_reading.Property_name = $scope.obj.Property_name;
               console.log("$scope.meter_reading.Property_ID: " + $scope.meter_reading.Property_ID);
               console.log("$scope.meter_reading.Property_ID: " + $scope.obj.Property_ID);

           }
       )


        $scope.search_unit = "";
        $scope.hideSearch = true;
    }
   $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
   $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }



   $scope.getMeterTypesAll = function () {
      console.log("$scope.getMeterTypes");
      apiService.getMeterTypes().then(
         function(response) {
             $scope.meterTypes  = response.data.data;
             console.log(response.data);
             console.log(response.data.data);
             console.log(response.data.data.Meter_Type_ID);
             console.log("Meter Type: " + $scope.meterTypes.Meter_Type);
             //$scope.meter_reading.Meter_Type = $scope.meterTypes.Meter_Type;
             //$scope.meter_reading.Meter_Type_ID = $scope.meterTypes.Meter_Type_ID;
             console.log("$scope.meterTypes: " + $scope.meterTypes);
             //$scope.meterTypes = $scope.meterTypes.Meter_Type;
         },
         function() {
             console.log("API error getMeterTypes")
         }
      )
   }

   $scope.getMeterType = function () {
     $scope.meter_reading.Meter_Type_ID = $scope.meter_reading.meterTypes.Meter_Type_ID;
     $scope.meter_reading.Meter_Type = $scope.meter_reading.meterTypes.Meter_Type;
     console.log("$scope.meter_reading.Meter_Type_ID : " + $scope.meter_reading.Meter_Type_ID );
     console.log("$scope.meter_reading.Meter_Type: " + $scope.meter_reading.Meter_Type);
     console.log("$scope.meter_reading.Meter_Type: " + $scope.meter_reading.meterTypes.Meter_Type);
   }


   $scope.addMeterReading = function() {


           if (confirm('Are you sure you want to add a meter reading?')) {
               //$scope.obj = response;

               var date = new Date();
               date.setHours(date.getHours()+1);

               $scope.meter_reading.Date_Meter_Read = date;
               $scope.meter_reading.User_ID = $rootScope.decodedToken.id;

               console.log("$scope.meter_reading.Property_ID: " + $scope.meter_reading.Property_ID);
               console.log("$scope.meter_reading.Property_ID: " + $scope.meter_reading.Property_ID);
               console.log("$scope.meter_reading.Property_ID: " + $scope.meter_reading.Property_ID);
               console.log("$scope.meter_reading.Meter_Type_ID: " + $scope.meter_reading.Meter_Type_ID);
               console.log("$scope.meter_reading.Meter_Reading_1: " + $scope.meter_reading.Meter_Reading_1);

               apiService.postMeterReading($scope.meter_reading).then(
                   function (response) {

                       var from_email = "Clouderty | Meter Reading Added | " + $scope.meter_reading.selected_property_name + " <notifications@clouderty.co.uk>";
                       var to_email = $rootScope.decodedToken.Notifications_Email;
                       var subject = "Meter Reading ID: " + $scope.meter_reading.Meter_Type_ID + " | " + $scope.meter_reading.Property_name;
                       var message = "Meter Type ID: " + $scope.meter_reading.Meter_Type_ID + "\n" +
                           "Created by: " + $rootScope.decodedToken.Name + "\n" +
                           "Property: " + $scope.meter_reading.Property_name + "\n" +
                           "Meter type: " + $scope.meter_reading.Meter_Type + "\n" +
                           "Date: " + Date() + "\n\n" +
                           "Meter reading 1: " + $scope.meter_reading.Meter_Reading_1 + "\n\n" +
                           "Meter reading 2: : " + $scope.meter_reading.Meter_Reading_2 + "\n\n" +
                           "Meter reading 3 : " + $scope.meter_reading.Meter_Reading_3 + "\n\n"

                       $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
                       $scope.postUserActivityServiceMessage = "Meter Read Added | " +  $scope.meter_reading.Property_name + " | Job Timesheet ID: " + $scope.meter_reading.Meter_Type_ID + " | " + $scope.meter_reading.Meter_Type + " | " +  $scope.timesheet.selected_property_name;
                       $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);
                       $state.reload()
                       //$state.go('contractors-main-nav.contractors-tasks-side.timesheet-add-edit');

                   },
                   function () {
                       console.log("There was an error in addProperty")
                   }
               )

           } else {
               // Do nothing!
           }
       }




    $scope.getMeterTypesAll();

}])