/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("tenantSearchCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "apiService", "$q", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, apiService, $q) {

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    $scope.tenants = {};
    $scope.tenantsPotential = [];
    //$scope.tenancies = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.search = "";
    $scope.searchParam ="";


    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }


    $scope.tenantsFunction = function(id, status) {

        console.log("function tasksFunction");

        $scope.tenants.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenants.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenants.itemsPerPage = $scope.itemsPerPage;
        $scope.tenants.pageno = $scope.pageno;
        $scope.tenants.Tenant_ID = id;
        //$scope.obj.Property_ID = "0";
        $scope.tenants.Status = status;


        if($scope.tenants.Tenant_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{
            $scope.tenants.Tenant_ID = "*";
        }


        if($scope.search){
            $scope.tenants.search = $scope.search;
        }else{
            $scope.tenants.search = "*";
        }

        //$scope.obj.search = "*";

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.tenants(id, $scope.tenants).then(
            function (response) {

                $scope.paginationData = response.data.data;
                $scope.tenants = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalTaskCount = response.data.totalCount;
                $scope.totalCount = response.data.totalCount;
                $scope.rentTotal = response.data.rentTotal;

                $scope.tenantsPotential = response.data.data;

                console.log("tenants API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenants")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }


    $scope.getData = function(pageno) {
        //$scope.tenancies = [];
        $scope.tenants = [];
        $scope.pageno = pageno;
        $scope.searchParam = $scope.search;

        if(!$scope.searchParam){

            $scope.searchParam = "*";

        }else{

        }

        console.log("about to enter tenantsFunction");
        $scope.tenantsFunction('*', '0');

        /*
        apiService.getTenants($scope.itemsPerPage, pageno, $scope.searchParam, '*', '*', $rootScope.decodedToken.id, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                $scope.tenantsPotential = response.data.data;
                $scope.tenants = response.data.data;
                $scope.currentPage = pageno;
                $scope.totalCount = response.data.totalCount;
                console.log("API getAllTenantsPotentialSearch");


                if($scope.search == "*"){

                    $scope.search = "";

                }else{

                }
            },
            function() {
                console.log("There was an error")
            }
        )
        //}
        */
    }

    $scope.getData($scope.pageno);

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.potentialTenantUpdate = function(potentialTenantID, status) {

        if (confirm('Do you want to update the status?')) {


            console.log("Potential Tenant ID: " + potentialTenantID);
            console.log("Status: " + status);

            $http.post("api/tenants_potential_update_status/" + potentialTenantID + "/" + status).success(function(response){

                $scope.tenant_potential = [];
                $scope.tenant_potential = response.data2;
                $scope.potentialTenantName = "";

                if (!$scope.tenant_potential.Last_Name){

                    $scope.potentialTenantName = $scope.tenant_potential.First_Name
                    console.log("Potential Tenant Name: " + $scope.tenant_potential.First_Name);
                }
                else {
                    $scope.potentialTenantName = $scope.tenant_potential.First_Name + " " + $scope.tenant_potential.Last_Name;
                    console.log("Potential Tenant Name: " + $scope.tenant_potential.First_Name);
                    console.log("Potential Tenant Name: " + $scope.tenant_potential.Last_Name);

                }




                var from_email = "Clouderty | Potential Tenant Status Update | " + $scope.potentialTenantName + " | " + status + " |" + " <notifications@clouderty.co.uk>";
                var to_email = $rootScope.decodedToken.Notifications_Email;
                var subject = "Clouderty | Potential Tenant Status Update | " + $scope.potentialTenantName + " | " + status + " | Updated by: " + $rootScope.decodedToken.Name + "| Potential Tenant ID: " + potentialTenantID;
                var message = "Potential Tenant ID: " + potentialTenantID + "\n" +
                    "Status: " + status + "\n" +
                    "Potential tenant name: " + $scope.potentialTenantName + "\n" +
                    "Created By: " + $rootScope.decodedToken.Name + "\n" +
                    "Date updated: " + Date() + "\n\n";

                var to_email = $rootScope.decodedToken.Notifications_Email;
                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


            });
            console.log("Route reload ");
            $state.reload();

        }
        else{


        }

    }

    $scope.actionUpdate = function (progress) {
        //var date = new Date();
        //date.setHours(date.getHours()+1);
        //console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(progress == "1") {
            //$scope.progress.date_completed = date;
            console.log("Add viewing");
            $state.go('lettings-main-nav.lettings-side.lettings-add-tenant-viewing');
            //$scope.getDataExpired(1)
        }else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            //$scope.getData(1)

        } else{

        }

    }

}]);