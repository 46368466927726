/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("awardsProcessAccountsSearchCtrl", ["$scope", "detectMobileViewService", "$rootScope",  "$http", "$stateParams", "$state", "apiService", "fCsv",  "exportCSVService", "dateInputService", "$filter", "$interval", "$q", "openNewWindowService", "$route", function ($scope, detectMobileViewService, $rootScope, $http, $stateParams, $state, apiService, fCsv, exportCSVService, dateInputService, $filter, $interval, $q, openNewWindowService, $route) {

    console.log("StateParams: " + $stateParams);

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.search = "";
    $scope.status = "";

    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end

    $scope.New_Record = "";
    $scope.pageBlock = '1';
    $scope.progress = {};
    $scope.pageBlock = '0';
    $scope.testBlock = '5';
    $scope.counter = '0';
    $scope.message = "";
    $scope.myJsonString = [];
    $scope.getHeader = [];

    $scope.business = {};
    $scope.hideBusinessSearch = false;
    $scope.properties = {};
    $scope.properties = [];
    $scope.properties.Property_ID = null;
    $scope.units = [];
    $scope.unitText = "";
    $scope.unitBlock = {result: false};
    $scope.unitBlock.result = false;
    $scope.tenancies = [];

    $scope.processes_account = {};
    $scope.processes_account.tenancies = {};
    $scope.processes = {};

    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.users = {};

    $scope.tenancyID = "current";
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.landlord.Payment_Transaction_Category = "";
    $scope.landlord.Payment_Transaction_Category_ID = 0;
    $scope.landlord.landlord_payment_transaction_year_cbo = "";
    $scope.landlord.landlord_transaction_year = 0;
    $scope.landlord.landlord_transaction_month_cbo = "";
    $scope.landlord.landlord_transaction_month = 0;
    $scope.landlord.incomeExpenditure = "*";
    $scope.landlord.Transaction_Date = new Date();
    $scope.landlord.Transaction_Cut_of_Date = new Date();
    $scope.filename = "test" + ".csv";
    $scope.paymentTransactionCategories = {};
    $scope.landlordSelected = {};
    $scope.landlord.yearRelatedTo = "0";
    $scope.landlord.monthRelatedTo = "0";


    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("Test A");

    delete $scope.landlordTransactions;
    $scope.landlordTransactions = [];
    console.log("$scope.landlordTransactions: " + $scope.landlordTransactions);
    console.log($scope.landlordTransactions);

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.processesAccountFunction = function(id, status) {
        //$scope.processesAccountData = {};
        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        var qprocessesAccountFunction2 = $q.defer();

        //$scope.processes_account = [];

        $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
        $scope.processes_account.pageno = $scope.pageno;
        $scope.processes_account.Status = status;
        $scope.processes_account.Process_ID = id;

        if($scope.processes_account.Process_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            $scope.processes_account.Process_ID = "*";
        }

        if($scope.search){

            $scope.processes_account.search = $scope.search;
        }
        else{

            $scope.processes_account.search = "*";
        }


        console.log($scope.processes_account);
        $scope.processesAccountData = $scope.processes_account;

        //$scope.processes_account = {};
        console.log("status: " + status);
        apiService.awardsProcesses(id, $scope.processesAccountData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                $scope.processData = response.data.data;
                $scope.totalCount = response.data.totalCount;
                $scope.currentPage = $scope.pageno;

                console.log("status: " + status);
                if(status == 4){

                    $scope.processAccount = response.data.data;
                    $scope.processAccountAdded = '1';

                }
                if(status == 63){

                    $scope.processTypes = response.data.processTypes;
                }
                else{

                    $scope.process_accounts = response.data.data;
                    //$scope.totalCount = response.data.totalCount;
                    //$scope.process_accounts.processessProgress = response.data.processessProgress;
                    //console.log("status: " + response.data.processessProgress);
                }

                console.log("processes API successful");

                console.log(response);
                qprocessesAccountFunction2.resolve(response);


            },
            function () {
                console.log("API Service Error: processes")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qprocessesAccountFunction2.promise;
    }

    $scope.getData = function(pageno) {




        //canceller = $q.defer();

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{
        }

        $scope.currentPage = pageno;
        $scope.pageno = pageno;

        console.log("$scope.status" + $scope.status);

        console.log("about to enter processesAccountFunction");
        $scope.processesAccountFunction(pageno, $scope.status);

    }

    $scope.getDataSearch = function() {
        console.log("inside function getDataSearch");
        $scope.process_accounts = [];
        $scope.totalCount = null;

        $scope.processes_account.search = $scope.search;
        $scope.status = 7;
        $scope.processesAccountFunction('*', $scope.status);

        //$scope.getData(1);

    }

    $scope.getManager = function(managerUserID) {
        console.log("function getManager");
        $scope.process_accounts = [];
        $scope.totalCount = null;

        console.log(managerUserID);
        console.log("manager.User_ID: " + managerUserID);
        $scope.processes_account.User_ID = managerUserID;
        console.log("$scope.processes_account.User_ID: " + $scope.processes_account.User_ID);
        $scope.status = 14;
        $scope.getData(1);
        //$scope.processesAccountFunction('*', $scope.status);

    }

    $scope.usersViewAll = function () {

        var qusersViewAll = $q.defer();

        apiService.getUsers($rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                $scope.users = response.data.data;

                qusersViewAll.resolve(response);
                console.log("API getUsers: " );
            },
            function() {
                console.log("We are in getUsers");
            }
        )

        return qusersViewAll.promise;
    }

    $scope.getDataProperty = function() {
        $scope.process_accounts = [];
        $scope.totalCount = null;
        console.log("$scope.landlord.Property: " + $scope.landlord.Property);
        $scope.landlord.Property_ID = $scope.landlord.Property;
        $scope.processes_account.Property_ID = $scope.landlord.Property;
        $scope.status = 12;
        $scope.getData(1);


    }

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;

        console.log("$scope.getPropertiesSearch landlord");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties  = response.data;
                //console.log("$scope.properties.Property_ID searchProperties: " + $scope.properties[1].Property_ID);
            },
            function() {

                console.log("There was an error getPropertiesSearch")

            }
        )
    }

    $scope.getDataProcessType = function() {
        console.log("$scope.processes.ProcessType: " + $scope.processes.ProcessType);

        $scope.process_accounts = [];
        $scope.totalCount = null;

        $scope.processes_account.Process_Type_ID = $scope.processes.ProcessType;
        $scope.status = 13;
        $scope.getData(1);
        //$scope.processesAccountFunction('*', $scope.status);

    }

    $scope.getProcessTypes = function () {
        console.log("$scope.getPaymentTransactionTypes");
        var qgetProcessTypes41 = $q.defer();

        $scope.status = 63;
        qgetProcessTypes41.resolve($scope.processesAccountFunction("*", $scope.status));

        return qgetProcessTypes41.promise;
    }

    $scope.loadProcessData = function () {
        console.log("inside function loadProcessData");


        console.log("$stateParams.id: " + $stateParams.id);
        //$scope.$state = $state;
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2");

                //return $scope.getPaymentTransactionCategoriesAll(data);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A3 getProcessTypes()");

                $scope.propertiesViewAll();

                return $scope.getProcessTypes(data);
            })
            .then(function (data) {

                console.log("A2 loadDataThenMap");

                //$scope.showMapMultipleMarkersDetails();
                return $scope.usersViewAll();
            })
            .then(function (data) {

                console.log("A4");
                $scope.status = 0;
                $scope.getData($scope.pageno);
                //$scope.processesAccountFunction("*", 0);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {


                return $scope.getGlobalPromise(data);


            })
            .then(function (data) {
                console.log('A7a');

                $scope.paymentBlockSpinner = '0';
                $scope.pageBlock = '1';

                return $scope.getGlobalPromise(data);

                //$scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

    }

    $scope.initialPageLoad = function() {
        console.log("inside function initialPageLoad");
        var qinitialPageLoad66 = $q.defer();

        $scope.status = 0;
        qinitialPageLoad66.resolve($scope.obj = $scope.loadProcessData());


        return qinitialPageLoad66.promise;


    }

    $scope.actionProcessAccount = function (action, processAccountID) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "1") {
            //$scope.progress.date_completed = date;
            console.log("Edit Tenant");
            $scope.editTenant(tenantID);
        } else if (action == "2") {
            console.log("Edit Tenant");
            //$scope.getDataExpired(1)
            $scope.editTenancy(tenancyID);
            //$scope.progress.date_completed = null;
        } else if (action == "3") {
            console.log("View Rental Statement");

            console.log("tenancyID: " + tenancyID);

            console.log("licenseKey: " + licenseKey);
            //$scope.getDataExpired(1)
            $scope.viewTenancyRentalStatement(tenancyID, licenseKey);
            //$scope.progress.date_completed = null;
        } else if (action == "4") {
            console.log("View process account");
            //$scope.getDataExpired(1)
            $scope.viewProcessAccount(processAccountID)
            //$scope.progress.date_completed = null;
        } else if (action == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getData(1)
            //$scope.progress.date_completed = null;
        }
        else if (action == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData($scope.pageno)

        } else{

        }

    }

    $scope.viewProcessAccount = function(processAccountID) {

        console.log("function viewProcessAccount");
        //$scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View process account ' +  processAccountID);

        //console.log("Process Account ID = " + processAccountID);
        console.log(detectMobileViewService.detectMob());


        if(detectMobileViewService.detectMob() == 1){

            console.log("mobile view - width: " + window.innerWidth);
            //alert("in mobile view - width: " + window.innerWidth);
            $state.go('lettings-main-nav.lettings-side.process-account-view.notes', {processAccountID:  processAccountID})

        }else{

            console.log("not in mobile view - width: " + window.innerWidth);
            //alert("not in mobile view - width: " + window.innerWidth);
            var url = $state.href('lettings-main-nav.lettings-side.process-account-view.notes', {processAccountID:  processAccountID});
            window.open(url,'_blank');
        }


    }

    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }

    $scope.reload = function () {
        console.log("state reloaded");
        //$window.location.reload();
        $state.go($state.current, {}, {reload: true});

    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

    ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

    $scope.initialPageLoad();

    ////////////////////////// Void /////////////////////////////////////////////////////////////////////////////////

}]);