/**
 * Created by Aaron on 04/07/2016.
 */
clouderty.controller("signupCtrl", ["$scope", "$rootScope", "$state", "postUserActivityService", "$location", "$http", "$window", "$cookies", "apiService", "$q", function ($scope, $rootScope, $state, postUserActivityService, $location, $http, $window, $cookies, apiService, $q) {

    $scope.signup = {};
    $scope.obj = {};

    $scope.addAccountHolder = function() {

        //$scope.login();

        console.log($scope.signup);

        if($scope.signup.Admin_Password == null){

            confirm('Enter a password')
            return "";

        } else if($scope.signup.Email == null){

            confirm('Enter an email')
            return "";

        } else if($scope.signup.Account_Holders_Name == null){

            confirm('Enter a username')
            return "";

        }

        $scope.login();



        /*
        apiService.postAccountHolder($scope.signup).then(
            function (response) {
                $scope.a = response;
                console.log($scope.a);
            },
            function () {
                console.log("There was an error in postAccountHolder")
            }
        )*/

        /*
        $scope.b = [];
        $scope.b = response;
        $scope.a.Cheque_processed = 0;
        if ($scope.a.Payment_Transaction_Type == "CHEQUE") {
            $scope.a.Cheque = 1;
        }else{
            $scope.a.Cheque = 0;
        }
        $scope.a.Received = 0;
        $scope.a.Business_ID = 1;
        $scope.a.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID
        $scope.a.User_ID = $rootScope.decodedToken.id;
        $scope.a.Date_Received = $scope.DateToday;
        if(!$scope.payment_transaction.Notes){
            $scope.payment_transaction.Notes = "None"
        }else{

        }

        console.log($scope.payment_transaction.Date);
        var payment_transaction_date = $filter('date')($scope.payment_transaction.Date, 'dd') + "/" + $filter('date')($scope.payment_transaction.Date, 'MM') + "/" + $filter('date')($scope.payment_transaction.Date, 'yyyy');
        $scope.a.Date = $scope.payment_transaction.Date;

        if (confirm('Are you sure you want to add a' + $scope.payment_transaction.Payment_Transaction_Category_Description + 'transaction?')) {

            apiService.addPaymentTransaction($scope.payment_transaction).then(
                function (response) {
                    $scope.a = response;
                    console.log($scope.a);
                },
                function () {
                    alert("There was an error in getTenanciesUnit")
                }
            )

        }else{
            return;
        }





        if($scope.payment_transaction.Transaction_Type == "Business") {


            var from_email = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Business_Bank_ref + " <notifications@clouderty.co.uk>";
            var subject = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description + " | "  + $rootScope.decodedToken.Name + " | " + $scope.payment_transaction.Property_name;
            var message = "Payment  ID: " + $scope.payment_transaction.Payment_ID + "\n\n" +
                "Payment taken by: " + $rootScope.decodedToken.Name + "\n\n" +
                "Business: " + $scope.payment_transaction.Business_Bank_ref + "\n" +
                "Date received: " + $scope.payment_transaction.Date + "\n" +
                "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "\n" +
                "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "\n\n" +
                "Amount:" + " " + $filter('currency')($scope.payment_transaction.Amount, '£') + "\n" +
                "Notes: " + $scope.payment_transaction.Notes + "\n\n";

            var to_email = "office@platinumcrown.co.uk";

            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
            $('.data').hide();

        }
        else if ($scope.payment_transaction.Transaction_Type == "Property") {



            var from_email = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Property_name + " <notifications@clouderty.co.uk>";
            var subject = "Clouderty | Payment | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description  + " | " + $rootScope.decodedToken.Name + " | " + $scope.payment_transaction.Property_name;
            var message = "Payment  ID: " + $scope.payment_transaction.Payment_ID + "\n\n" +
                "Payment taken by: " + $rootScope.decodedToken.Name + "\n\n" +
                "Date received: " + $scope.payment_transaction.Date + "\n\n" +
                "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "\n\n" +
                "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "\n\n" +
                "Amount:" + " " + $filter('currency')($scope.payment_transaction.Amount, '£') + "\n" +
                "Notes: " + $scope.payment_transaction.Notes + "\n\n" +
                "Address: " + $scope.payment_transaction.Address_1 + "\n";

            var to_email = "office@platinumcrown.co.uk";

            $scope.postUserActivityServiceMessage = subject;
            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
            $('.data').hide();
        }

         */
    }

    $scope.login = function () {

        $scope.login_details = {};

        $scope.login_details.email = "c.thomas@platinumcrown.co.uk"
        $scope.login_details.password = "platinumlaptop1"
        $http.post("api/authenticate", $scope.login_details)
            .success(function(response) {

                if (response.success == false) {
                    alert("Incorrect Login Details");
                }
                else  {
                    if ($window.sessionStorage.token) {
                        $window.sessionStorage.removeItem("token");
                    }
                    $window.sessionStorage.token = response.token;
                    $rootScope.decode();

                    //$rootScope.$apply();
                    $cookies.putObject("userName", "c.thomas@platinumcrown.co.uk");
                    $cookies.putObject("password", "platinumlaptop1");
                    //console.log("Hello:" + $scope.login_details.email);
                    //console.log("Password:" + $scope.login_details.password);

                    /*
                    if ($rootScope.decodedToken.User_Type_ID == 1) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("lettings-main-nav.lettings-side.lettings-search-tenancy");
                    } else if ($rootScope.decodedToken.User_Type_ID == 2) {
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'User Login');
                        $state.go("contractors-main-nav.contractors-tasks-side.tasks-search");
                    }
                    */

                    $http.post('api/account-holders-users/' + '0', $scope.signup).success(function(response) {


                        $scope.obj = response;
                        $rootScope.logout();
                        console.log("Logged out");
                    });


                }
            })
    }



}])