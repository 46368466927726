/**
 * Created by Cyril on 31/07/2016.
 */


clouderty.controller("tenantAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$stateParams", "apiService", "dateInputService", "$q", "fileUploadService", "$mdDialog", "$mdMedia", "postUserActivityService", function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $stateParams, apiService, dateInputService, $q, fileUploadService, $mdDialog, $mdMedia, postUserActivityService) {

    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    $scope.obj = {};
    $scope.obj.sources = {};
    $scope.objBackupPre = {};
    $scope.objBackup = {};
    $scope.tenants  = {};
    $scope.tenantsPost = {};
    $scope.searchTenant = {};
    $scope.tenantsDetailsPreUpdate = [];
    $scope.objre = new Date();

    $scope.obj.contactNumberCheckExisting = null;
    $scope.obj.emailCheckExisting = null;

    $scope.sources = {};
    $scope.sources.Source_ID = 0;


    //$scope.obj.How_did_you_find_us = "";
    //$scope.obj.Address_1 = "";


    console.log("State ParamsID: = " + $stateParams.id);

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];
    $scope.New_Record = false;
    $('.data').hide();
    $('.accordion-1-form').show();



    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }
    $scope.getSources = function() {

        var qgetSources = $q.defer();
        apiService.getSources().then(
            function (response) {

                console.log("API getSources");
                console.log(response);
                $scope.obj.sources = response.data.data;
                qgetSources.resolve(response);

            }
        )


        return qgetSources.promise;
    }

    $scope.getSource = function(sources) {


        console.log(sources);
        console.log(sources.Source_ID);
        console.log("sourceID: " + sources);
        $scope.obj.Source_ID = sources.Source_ID;

    }

    $scope.tenantsFunction = function(id, status) {

        $scope.tenants = $scope.obj;

        //$scope.obj.How_did_you_find_us = "1";
        //$scope.obj.Address_1 = "1";
        //$scope.tenants.How_did_you_find_us = "";
        //$scope.tenants.Address_1 = "";



        //console.log("function tasksFunction");
        console.log("function tasksFunction status: " + status);
        $scope.tenants.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenants.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenants.itemsPerPage = $scope.itemsPerPage;
        $scope.tenants.pageno = $scope.pageno;
        $scope.tenants.Tenant_ID = id;
        //$scope.obj.Property_ID = "0";
        $scope.tenants.Status = status;
        //$scope.pageno = 1;

        if($scope.tenants.Tenant_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{
            $scope.tenants.Tenant_ID = "*";
        }


        if($scope.search){
            $scope.tenants.search = $scope.search;
        }else{
            $scope.tenants.search = "*";
        }


        //$scope.obj.search = "*";

        var q222 = $q.defer();
        //console.log($scope.paymentTransactionsData);
        $scope.obj = [];
        apiService.tenants(id, $scope.tenants).then(
            function (response) {

                console.log("status 2 = " + status);
                if(status == 2 || status == 4){

                    console.log("status 2 inside = " + status);
                    $scope.obj = response;
                    console.log(response);
                    console.log($scope.obj);
                    console.log($scope.obj.Tenant_ID);
                //$scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B);
                }else{
                    console.log("status 2 outside = " + status);
                    $scope.obj = response.data.data;
                    console.log(response);
                    console.log($scope.obj);
                    console.log($scope.obj.D_O_B);


                }
                //$scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B);
                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);


                $scope.paginationData = response.data.data;
                $scope.tenants = response.data.data;
                $scope.tenantsPost = response.data;

                $scope.tenantsPost = response.data;
                $scope.$parent.tenants = response.data.data;
                //$scope.obj = response.data.data;
                $scope.currentPage = $scope.pageno;
                $scope.totalTaskCount = response.data.totalCount;
                $scope.totalCount = response.data.totalCount;
                $scope.rentTotal = response.data.rentTotal;

                $scope.tenantsPotential = response.data.data;

                //console.log($scope.tenantsPost);
                console.log(response.data);
                console.log($scope.tenantsPost.Tenant_ID);

                q222.resolve(response);

                console.log("tenants API");

                return $scope.tenantsPost;
            },
            function () {
                console.log("API Service Error: tenants")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q222.promise;
    }



    $scope.uploadFile = function () {
        var file = $scope.myFile;
        var uploadUrl = "api/tenants-upload-csv", //Url of web service
            promise = fileUploadService.uploadFileToUrl(file, uploadUrl);

        promise.then(function (response) {
            $scope.serverResponse = response;
            console.log("resp after promise",response);
        }, function () {
            $scope.serverResponse = 'An error has occurred';
        })
    };

    $scope.searchTenant = function() {


        $scope.tenantsFunction($stateParams.id, '5');
        //$http.get('api/tenant/' + $stateParams.id + "/" + $stateParams.accountHolderID).success(function(response) {
        /*
        apiService.getTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenants  = response.data.data;
                $scope.obj = response.data.data;
                $scope.$parent.objBackup = angular.copy($scope.obj);
                console.log("Set objBackup: " + $scope.$parent.objBackup);
                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);


                $scope.obj.First_Name = response.data.data.First_Name;

                //console.log("newDate: " + newDate);
                console.log($scope.obj.D_O_B);

                //console.log(response);

                //return $scope.tenants;
            },
            function() {
                console.log("There was an error apiService.getTenant")
            }
        )
        */
    }


    if ($stateParams.id == null) {
        $scope.getSources();
        $scope.New_Record = true;
    }else{
        $scope.New_Record = false;
        $scope.obj = $scope.searchTenant();
    }



    //$scope.obj.selectedtenants  = [];

    $scope.selectTenant = function(id) {
        console.log("In selected tenant");
        $http.get("api/tenant/" + id).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.Tenant_ID,
                "First_Name": response.First_Name,
                "Last_Name": response.Last_Name,
                "Email": response.Email,
                "Contact_number": response.Contact_number,
                "D_O_B": response.D_O_B
            })
        })

        $scope.search_tenant = "";
    }


    $scope.hideSearch = false;

    $scope.searchUnit = function() {
        $scope.units = null;
        $http.get('api/units/' + $scope.search_unit).success(function(response) {
            $scope.units = response;
        });
    }


    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }


    $scope.addEditTenantDetails = function() {

        //console.log($scope.obj.User_ID);
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        console.log("$rootScope.decodedToken.Account_Holder_ID: " + $rootScope.decodedToken.Account_Holder_ID);
        $scope.obj.User_ID = $rootScope.decodedToken.id;
        $scope.obj.Date_Added = new Date();

        console.log("check $scope.obj.D_O_B");
        if(!$scope.obj.D_O_B){
            $scope.obj.D_O_B = null;
        }else{

            $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);


        }

        console.log("Date DOB: " + $scope.obj.D_O_B);

        if ($stateParams.id == null) {

            if (confirm('Are you sure you want to add a tenant?')) {

                console.log("adding a tenant: 2");
                var q12 = $q.defer();
                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();


                $scope.promise
                    .then(function (data) {

                        console.log("A2");

                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {

                        $scope.tenants = $scope.tenantsFunction('*', '4');
                        console.log($scope.tenantsPost.Tenant_ID);

                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log($scope.tenantsPost);
                        console.log($scope.tenantsPost.Tenant_ID);


                        var from_email = "Clouderty | Tenant Added " + $scope.tenantsPost.Tenant_ID + " | " + $scope.tenantsPost.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = "Created by: " + $rootScope.decodedToken.Name;
                        var message = "Tenant name: " + $scope.tenantsPost.First_Name + "\n\n" +
                            "Email: " + $scope.tenantsPost.Email + "\n\n" +
                            "DOB: " + $scope.tenantsPost.D_O_B + "\n\n" +
                            "Contact number: " + $scope.tenantsPost.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.tenantsPost.Tenant_ID + "\n\n" +
                            "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        //console.log('Tenancy end date' + $scope.obj.Tenancy_ID);
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenant Added: ' + $scope.obj.First_Name + ' - ' + $scope.obj.Tenant_ID);

                        if (confirm('Do you want to add a new tenancy?')){

                            $state.go('lettings-main-nav.lettings-side.lettings-add-tenancy');

                        }

                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log('7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                    q12.resolve($scope.promise);


                    return q12.promise;


            }
            else {

            }
        }
        else {
            if (confirm('Are you sure you want to update this tenant?')) {


                $scope.tenantsDetailsPreUpdate2 = [];
                $scope.tenantsDetailsPreUpdate2 = $scope.tenantsDetailsPreUpdate;

                console.log("check $scope.obj.D_O_B");
                if(!$scope.obj.D_O_B){
                    $scope.obj.D_O_B = null;
                }else{

                    $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);

                }


                console.log("update a tenant: bbb");
                var q12 = $q.defer();
                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();


                $scope.promise
                    .then(function (data) {

                        console.log("A2");
                        return $scope.getSources();
                        //return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {

                        console.log("A222");
                        $scope.sources.Source_ID = $scope.obj.Source_ID;
                        $scope.$parent.objBackupPre = $scope.obj;
                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {

                        console.log("A22");

                        if(!$scope.objBackup.D_O_B){

                            $scope.objBackup.D_O_B = null;
                            console.log('undefined dob: ' + $scope.obj.D_O_B);
                        }else{

                            $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);
                            //$scope.objBackup.D_O_B = "2017-01-12";
                            console.log('defined dob: ' + $scope.obj.D_O_B);
                        }

                        $scope.tenantsFunction($stateParams.id, '5');

                        console.log('$scope.$parent.objBackup: ' + $scope.obj.objBackup);
                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {

                        $scope.$parent.objBackup = $scope.obj;
                        $scope.obj = $scope.$parent.objBackupPre;
                        console.log('update tenant function about to answer - Tenant ID: ' + $stateParams.id);
                        $scope.tenants = $scope.tenantsFunction($stateParams.id, 2);

                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log('Reload tenant data: ' + $stateParams.id);

                        if(!$scope.obj.D_O_B){

                            $scope.obj.D_O_B = null;
                            console.log('undefined dob: ' + $scope.obj.D_O_B);
                        }else{

                            //$scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B);
                            $scope.obj.D_O_B = "2017-01-12";
                            console.log('defined dob: ' + $scope.obj.D_O_B);
                        }
                        $scope.tenantsFunction($stateParams.id, '5');

                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {

                        $scope.obj = $scope.$parent.objBackupPre;
                        console.log('update tenant email send');



                        var from_email = "Clouderty | Tenant Updated " + $scope.$parent.objBackup.Tenant_ID + " | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = "Updated by: " + $rootScope.decodedToken.Name;
                        var message = "First name: " + $scope.obj.First_Name + "\n\n" +
                            "Last Name: " + $scope.obj.Last_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.$parent.objBackup.First_Name + "\n\n" +
                            "Last Name: " + $scope.$parent.objBackup.Last_Name + "\n\n" +
                            "Email: " + $scope.$parent.objBackup.Email + "\n\n" +
                            "DOB: " + $scope.$parent.objBackup.D_O_B + "\n\n" +
                            "Contact number: " + $scope.$parent.objBackup.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenant Updated: ' + $scope.obj.First_Name + ' - ' + $scope.obj.Tenant_ID);
                        //$state.reload("lettings-main-nav.lettings-side.lettings-edit-tenant", {id: $stateParams.id});


                        return $scope.getGlobalPromise(data);

                    })
                    .then(function (data) {
                        console.log('7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q12.resolve($scope.promise);


                return q12.promise;

                /*
                apiService.updateTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $scope.obj).then(
                    function(response) {

                        $scope.$parent.obj = apiService.getTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID).then(
                            function(response) {
                                $scope.tenants  = response.data.data;
                                $scope.obj = response.data.data;
                                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);
                                $scope.obj.First_Name = response.data.data.First_Name;
                                return $scope.tenants;
                            },
                            function() {
                                console.log("There was an error apiService.getTenant")
                            }
                        )



                        console.log(response);


                        console.log(response);
                        console.log(response.data);
                        var from_email = "Clouderty | Tenant Details Updated | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = $scope.obj.First_Name + " | Tenant Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "First name: " + $scope.obj.First_Name + "\n\n" +
                            "Last Name: " + $scope.obj.Last_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.$parent.objBackup.First_Name + "\n\n" +
                            "Last Name: " + $scope.$parent.objBackup.Last_Name + "\n\n" +
                            "Email: " + $scope.$parent.objBackup.Email + "\n\n" +
                            "DOB: " + $scope.$parent.objBackup.D_O_B + "\n\n" +
                            "Contact number: " + $scope.$parent.objBackup.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenant Updated: ' + $scope.obj.First_Name + ' - ' + $scope.obj.Tenant_ID);
                        $state.reload("lettings-main-nav.lettings-side.lettings-edit-tenant", {id: $stateParams.id});

                    },
                    function() {
                        console.log("There was an error");
                    }
                )
                */
            }

        }

    }



    $scope.addEditTenant = function() {


        console.log("addEditTenant");
        console.log("$stateParams.id: " + $stateParams.id );
        if ($stateParams.id == null) {
            if (confirm('Are you sure you want to add a tenant?')) {
                console.log("C1");
                var q = $q.defer();
                $scope.myValue = 0;
                $scope.promise = $scope.checkTenantExistingEmail();

                $scope.promise
                    .then(function (data) {
                        console.log("C2 $scope.emailCheckExisting: " + $scope.obj.emailCheckExisting);
                        return $scope.checkTenantExistingContactNumber(data);
                    })
                    .then(function (data) {
                        console.log("C3 $scope.emailCheckExisting: " + $scope.obj.emailCheckExisting);
                        console.log("C4 $scope.checkTenantExistingEmail: " + $scope.obj.contactNumberCheckExisting);
                        if ((!$scope.obj.emailCheckExisting && !$scope.obj.contactNumberCheckExisting)) {
                            console.log("C6");
                            return $scope.addEditTenantDetails(data);
                        } else {
                            console.log("C7");
                            alert('Tenant already exists so will not be added. Email.');
                            return "";
                        }
                    })
                    .then(function (data) {
                        console.log('7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })
                q.resolve($scope.promise);
            }
        }else{
            console.log("Attempt to update tenant");
            $scope.addEditTenantDetails();
        }


    }


    $scope.checkTenantExistingEmail = function () {
        console.log("A2 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
        var q66 = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.obj.Email + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(response) {
                    $scope.obj.emailCheckExisting = null;
                    console.log("A3 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    if ($scope.obj.Email) {

                        $scope.obj.emailCheckExisting = response.data.data;
                        console.log("A4 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);

                        if($scope.obj.emailCheckExisting.length == 0){
                            console.log("B4b $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            $scope.obj.emailCheckExisting = null;
                        }else{
                            console.log("B4c $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            //$scope.obj.contactNumberCheckExisting = null;

                        }

                    }else{
                        console.log("A5 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                        //$scope.obj.emailCheckExisting = [];
                        console.log("A6 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    }
                    console.log("A7 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    q66.resolve(response);
                })

        return q66.promise;

    }

    $scope.checkTenantExistingContactNumber = function () {
        console.log("B2 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
        var q67 = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.obj.Contact_number + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(response) {
                    $scope.obj.contactNumberCheckExisting = null;
                    console.log("B3 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                    if ($scope.obj.Contact_number) {
                        $scope.obj.contactNumberCheckExisting = response.data.data;
                        console.log("B4 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting.length);
                        console.log("B4-- $scope.contactNumberCheckExisting: " + response.data.length);
                        console.log("B4--- $scope.contactNumberCheckExisting: " + response.data.data.length);
                        if($scope.obj.contactNumberCheckExisting.length == 0){
                            console.log("B4b $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            $scope.obj.contactNumberCheckExisting = null;
                        }else{
                            console.log("B4c $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            //$scope.obj.contactNumberCheckExisting = null;

                        }
                    }else{

                        //$scope.obj.contactNumberCheckExisting = [];
                        console.log("B5 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                    }

                    q67.resolve(response);
                })
        console.log("B6 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
        return q67.promise;

    }



}])