/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("unitsSearchCtrl", ["$scope", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "$filter", "apiService", "yesNoFilter", "$uibModal", function ($scope, $http, $rootScope, $q, $state, postUserActivityService, $filter, apiService, yesNoFilter, $uibModal) {

    //$scope.yesNoFilter = yesNoFilter;

    console.log("Rootscope user level: " + $rootScope.decodedToken.User_Level);
    console.log("Rootscope account holder ID: " + $rootScope.decodedToken.Account_Holder_ID);
    $scope.tenancies = [];
    $scope.tenanciesExport = [];
    $scope.tenanciesExportHeaders = [];
    $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.tenanciesExport2 = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 200000;
    $scope.myJsonString = [];
    $scope.getHeader = [];

    $scope.filename = "test" + ".csv";

    $scope.getArray5 = [{a: 1, b:2}];
    //$scope.getArray4 = '[' +  '{a: 1, b:2}' + ']';
    $scope.getArray3 = function () { return [{a: 1, b:2}];};
    $scope.getArray6 = function () { return $scope.tenanciesExport;};
    //$scope.getArray7 = $scope.tenanciesExport();
    $scope.getArray2 = function () { $scope.getArray2 = [{a: 1, b:2}, {a:3, b:4}]; };


    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end

    $scope.getHeader = function () {
        return ["ID", "Property Name", "Address 1", "Tenancy ID", "Unit Number", "Postcode", "Rental Amount", "Date Start", "Date End", "Tenant Name"]
    };

    $scope.tenanciesExport = function () {

        var deferred = $q.defer();

        $http.get("api/tenancy_current_export").success(function (data, status, headers) {


            deferred.resolve($scope.tenancies.data);

        });

        console.log("deferred.promise:" + deferred.promise);
        return deferred.promise;


    }




    $scope.getData = function(pageno) {
        $scope.units = [];
        console.log("$scope.getData");
        var q = $q.defer();


        $scope.vacant = 1;
        if ($scope.search)
        {
            $http.get("api/units/" + $scope.itemsPerPage + "/" + $scope.pageno + "/" + $scope.search + "/" + $rootScope.decodedToken.Account_Holder_ID + "/" + $scope.vacant).success(function(response){
    
                $scope.units = response;
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.totalCount;
                $scope.rentTotal = response.rentTotal;
                q.resolve(response);

            });
        }
        else
        {

            apiService.getAllUnitsAvailable($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID, $scope.vacant).then(
                function(response) {
                    console.log('apiService getAllUnitsAvailable');

                    $scope.units = response.data.vacant;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    $scope.rentTotal = response.data.rentTotal;


                    console.log(response.data.data)
                    q.resolve(response);


                },
                function() {
                    console.log("There was an error")
                }
            )

        }

        return q.promise;

    }



    $scope.getDataExpired = function(pageno) {
        $scope.tenancies = [];

        if ($scope.search)
        {
            $http.get("api/tenancy_search_expired/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/tenancy_expired/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
            });
        }
    }

    $scope.getDataExpiredOneMonth = function(pageno) {
        $scope.tenancies = [];

        if ($scope.search)
        {
            $http.get("api/tenancy_search_expired_one_month/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/tenancy_expired_one_month/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
            });
        }
    }


    $scope.getDataExpiredTwoMonth = function(pageno) {
        $scope.tenancies = [];

        if ($scope.search)
        {
            $http.get("api/tenancy_search_expired_two_months/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/tenancy_expired_two_month/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
            });
        }
    }


    $scope.getDataExpiredThreeMonth = function(pageno) {
        $scope.tenancies = [];

        if ($scope.search)
        {
            $http.get("api/tenancy_search_expired_three_months/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });
        }
        else
        {
            $http.get("api/tenancy_expired_three_month/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
            });
        }
    }

    $scope.getDataCurrent = function(pageno) {
        $scope.tenancies = [];
        console.log("Test 1");




        /////////////////// Cancel previous AJAX code (B)
        console.log("callajax");
        if(isSending) {
            canceller.resolve()
        }
        isSending = true;
        canceller = $q.defer();


        /*var data = {
         html: "<p>Text echoed back to request</p>",
         delay: 5
         }*/
        ////////////////// Cancel previous AJAX code (B) end


        if ($scope.search)
        {

            console.log("Test 2");
            $http.get("api/tenancy_search_current/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search, {timeout: canceller.promise}).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;


                /////////////////// Cancel previous AJAX code (D)
                isSending = false;
                console.log("success");
                ////////////////// Cancel previous AJAX code (D) end
            });
        }
        else
        {
            console.log("Test 3 now");
            $http.get("api/tenancy_current/" + $scope.itemsPerPage + "/" + pageno).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
                console.log("Current tenancy: " + $scope.tenancies.data.Current_Tenancy);
                console.log("Current Tenancy ID's: " + $scope.tenancies.data.Tenancy_ID);
                console.log("Tenancies: " + $scope.tenancies.data);
            });
        }
    }
    
   


    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }



    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(progress == "EXPIRED TENANCIES") {
            //$scope.progress.date_completed = date;
            console.log("Expired Tenancies");
            $scope.getDataExpired(1)
        } else if (progress == "EXPIRED TENANCIES WITHIN 1 MONTH") {
            console.log("Expired Tenancies within 1 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredOneMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 2 MONTH") {
            console.log("Expired Tenancies within 2 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredTwoMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            $scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (progress == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getDataCurrent(1)
            //$scope.progress.date_completed = null;
        }
        else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData(1)

        } else{

        }
 
    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

    $scope.currentTenancyChange = function(tenancyID, current) {

        $scope.currentStatus = 0;
        console.log("Current  current: " + current);
        //console.log("Current  $scope.tenancy.Current: " + $scope.tenancy.Current);

        if (current == '-1' || current == '1') {


            if (confirm('Are you sure you want to change the status of this tenancy to not current?')) {


                console.log("Tenancy ID: " + tenancyID);


                $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '0').success(function(response){

                    $scope.tenancyCurrentStatus = {};
                    $scope.tenancyCurrentStatus = response.data2[0];




                    $scope.Tenant_Name = "";
                    if($scope.tenancyCurrentStatus.Last_name == null) {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                    }else{
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                    }
                    console.log($scope.Tenant_Name);

                    if($scope.tenancyCurrentStatus.Current == 1){

                        $scope.currentStatus = "Current";

                    }else if($scope.tenancyCurrentStatus.Current == -1){

                        $scope.currentStatus = "Not Current";

                    }else if($scope.tenancyCurrentStatus.Current == 0){

                        $scope.currentStatus = "Not Current";

                    }


                    var from_email = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " | Changed by: " + $rootScope.decodedToken.Name + "| Tenancy ID: " + tenancyID;
                    var message = "Tenancy ID: " + tenancyID + "\n" +
                        "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                        "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                        "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                        "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                        "Date changed: " + Date()+ "\n\n" +
                        "Current tenancy status: " + $scope.currentStatus  + "\n";

                    var to_email = "office@platinumcrown.co.uk";
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);
                });
                console.log("Route reload ");
                $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

            }
            else{
                console.log("Transaction ID: " + tenancyID);


                //$http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '-1').success(function(response){
                //});

            }


        } else{

            if (confirm('Are you sure you want to change the status of this tenancy to current?')) {

                console.log("Test 1");

                console.log("Tenancy ID: " + tenancyID);


                $http.post("api/tenancy_current_status_change/" + tenancyID + "/" + '1').success(function(response){

                    $scope.tenancyCurrentStatus = {};
                    $scope.tenancyCurrentStatus = response.data2[0];


                    $scope.Tenant_Name = "";
                    if($scope.tenancyCurrentStatus.Last_name == null) {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                    }else{
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                    }
                    console.log($scope.Tenant_Name);


                    if($scope.tenancyCurrentStatus.Current == 1){

                        $scope.currentStatus = "Current";

                    }else if($scope.tenancyCurrentStatus.Current == -1){

                        $scope.currentStatus = "Not Current";

                    }else if($scope.tenancyCurrentStatus.Current == 0){

                        $scope.currentStatus = "Not Current";

                    }


                    var from_email = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " |" + " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus  + " | Changed by: " + $rootScope.decodedToken.Name + "| Tenancy ID: " + tenancyID;
                    var message = "Tenancy ID: " + tenancyID + "\n" +
                        "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                        "Tenant's name: " + $scope.Tenant_Name  + "\n" +
                        "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                        "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                        "Date changed: " + Date()+ "\n\n" +
                        "Current tenancy status: " + $scope.currentStatus  + "\n";

                    var to_email = "office@platinumcrown.co.uk";
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number  + " - " +  $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);


                });
                console.log("Route reload ");
                $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

            }
            else{

            }

        }

    }

    //////////// Google map code ////////////

    $scope.loadDataThenMap = function() {

        console.log("C1 loadDataThenMap");
        var q = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getData();

        $scope.promise
            .then(function (data) {

                console.log("A2 loadDataThenMap");
                $scope.showMapMultipleMarkersDetails();
                return "";
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);
    }

    $scope.showMapMultipleMarkersDetails = function () {

        $scope.markers = [];

        geocoder = new google.maps.Geocoder();
        geocoderCentre = new google.maps.Geocoder();

        $scope.units.forEach(function (item) {
            geocoder.geocode({
                'address': item['Postcode']
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    $scope.lat = results[0].geometry.location.lat();
                    $scope.long = results[0].geometry.location.lng();
                }
                var i = 0;
                console.log("array: " + i)
                $scope.markers.push({
                    id: Date.now() + i,
                    coords: {
                        latitude: $scope.lat,
                        longitude: $scope.long

                    },
                    "show": false,
                    closeClick: 'none',
                    "sessions": item['area']
                });
                $scope.$evalAsync();
                console.log("array: " + i)
                i++
            });

        });
        console.log("resultsCentre3: " + $scope.postcodeCentre);
        $scope.widget = {
            "map": {
                "center": {
                    "latitude": 51.8846964,
                    "longitude": 0.9216240000000653
                    //"position": $scope.postcodeCentre

                },
                "zoom": 12,
                "options": {
                    "minZoom": 3,
                    "scrollwheel": true
                }
            }
        }

    }

    $scope.loadDataThenMap();

    //////////// Google map code end ////////////

}]);