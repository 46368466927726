
clouderty.controller("fieUploadCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", "$window", "fileDirectoriesService", "notePostService", "dateInputService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService, $window, fileDirectoriesService, notePostService, dateInputService) {

    console.log("inside controller fieUploadCtrl");

    console.log($scope.fileresults);
    $scope.fileresults = [];

    $scope.noteGet = {};
    $scope.note_loading = false;
    $scope.posted_note = {};

    $scope.directoryData = {};
    $scope.directoryDataRetrieved = {};
    $scope.directoryData.directoryInformation = {};
    $scope.directoryData.directoryInformation.Path2Files = {};
    $scope.fileDeleteData = {};
    $scope.stateCurrent = "";

    $scope.uploadFile = function (description, file, fileTypeID) {
        console.log("inside function uploadFile");

        var quploadFile = $q.defer();
        var date = new Date();
        var fileTypeDescription = "";


        date.setHours(date.getHours() + 1);

        console.log(fileTypeID);

        if(fileTypeID == 5){

            fileTypeDescription = "Floor_Plan";

        }
        else if(fileTypeID == 4){

            fileTypeDescription = "Pictures";

        }
        else if(fileTypeID == 6){

            fileTypeDescription = "Testimonials";

        }
        else if(fileTypeID == 7){

            fileTypeDescription = "Other";

        }


        $scope.note_loading = true;
        //$scope.noteGet.Process_Account_ID = $stateParams.processAccountID;
        //$scope.noteGet.Tenancy_ID = $scope.processAccount.Tenancy_ID;
        $scope.noteGet.User_ID = $rootScope.decodedToken.id;
        //$scope.noteGet.Notes_Type = "Arrears";
        $scope.noteGet.Date_Entered = date;
        $scope.noteGet.Note_Content = description;
        //$scope.noteGet.Note_Type_ID = 3;
        //$scope.noteGet.Processes_Linked_to_Account_ID = 5544;
        $scope.noteGet.picFile = file;
        $scope.noteGet.picFile.fileTypeDescription = fileTypeDescription;
        $scope.noteGet.picFile.fileTypeID = fileTypeID;

        console.log(file);
        console.log(file.$ngfName);
        console.log("$scope.noteGet.dirPath: " + $scope.noteGet.dirPath);
        console.log("$scope.note.Note_Type_ID: " + $scope.noteGet.Note_Type_ID);

        var myValue = 0;
        var promise = $scope.makeDir();

        console.log($scope.noteGet);

        promise
            .then(function (response) {
                console.log("A1a - makeDir");

                console.log(response);

                return $scope.getGlobalPromise();

            })//post make directory if it doesn't exist
            .then(function (data) {
                console.log("A1a - makeDir");

                return $scope.getGlobalPromise(data);
            })//post make directory if it doesn't exist
            .then(function (data) {

                console.log("A1a - postNote");
                $scope.$parent.directoryData = {};

                $scope.noteGet.dirPath = $scope.directoryDataRetrieved.Path2;
                console.log("$scope.directoryDataRetrieved.Path2: " + $scope.directoryDataRetrieved.Path2);
                console.log("$scope.noteGet.dirPath: " + $scope.noteGet.dirPath);

                //$scope.posted_note = notePostService.postNote($scope.noteGet);
                $scope.note_loading = false;
                file.picFile = null;
                file.picFileImage = null;
                //return $scope.getGlobalPromise(data);

                return $scope.posted_note = notePostService.postNote($scope.noteGet);
            })//post note
            .then(function (data) {

                console.log("A1c");

                console.log($scope.posted_note);
                console.log("console.log($scope.posted_note) >>>> " + $scope.posted_note);

                $scope.note_loading = false;

                return $scope.getGlobalPromise(data);
            })//set variables
            .then(function (data) {

                console.log("A5 ");

                $scope.note_loading = false;
                $scope.picFile = null;
                $scope.picFileImage = null;
                $scope.getDirItems();
                //$scope.posted_note.note = "";
                //$scope.obj.note = "";

                return $scope.getGlobalPromise(data);
            })//reset variables
            .then(function (data) {
                    console.log('A7 notes $state.reload');
                    //$state.reload($scope.stateCurrent);


                    myValue = data;
                }//reload notes
                ,function (err) {
                    myValue = err;
                })//end promise

        quploadFile.resolve(promise);

    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )

        return q55.promise;

    }

    $scope.makeDir = function () {

        console.log("in function makeDir");

        $scope.paymentBlockSpinner = 1;

        var qmakeDir = $q.defer();
        var qcreateDirectory = $q.defer();
        $scope.myValue = 0;
        var promise = loginExternalWebPageService.init("1");

        promise
            .then(function (data) {

                console.log("A1 login external");
                $rootScope.decode();

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 - apiService.fileDirectories");



                $scope.directoryData = {};
                $scope.directoryData.Path1 = "nominations";
                $scope.directoryData.Path2 = $stateParams.awardsNominationID;
                $scope.directoryData.Status = 41;
                $scope.directoryData.id = 0;

                fileDirectoriesService.createDirectory($scope.directoryData).then(
                    function (response) {

                        console.log("service fileDirectoriesService createDirectory");
                        $scope.directoryDataRetrieved = response;
                        console.log(response.data);
                        console.log(response.Path2);
                        $scope.directoryDataRetrieved.Path2 = response.data.Path2;
                        qcreateDirectory.resolve(response);


                    }
                );

                qcreateDirectory.promise;
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7 - directoryDataRetrieved');

                $scope.myValue = data;


                console.log($scope.directoryDataRetrieved);
                console.log("$scope.directoryDataRetrieved.Path2: " + $scope.directoryDataRetrieved.Path2);

                return $scope.getGlobalPromise(data);
            }, function (err) {
                $scope.myValue = err;
            })

        qmakeDir.resolve(promise);
        return qmakeDir.promise;
    }

    $scope.getDirItems = function () {


        console.log("in function dirTests");

        //$scope.fileresults = [];
        $scope.paymentBlockSpinner = 1;

        var q = $q.defer();

        $scope.myValue = 0;
        $scope.promise = loginExternalWebPageService.init("1");

        $scope.promise
            .then(function (data) {

                console.log("A1 login external");
                $rootScope.decode();

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2 - apiService.fileDirectories");



                $scope.directoryData.Path1 = "nominations";
                $scope.directoryData.Path2 = $stateParams.awardsNominationID;
                $scope.directoryData.Status = 51;
                $scope.directoryData.id = 0;



                fileDirectoriesService.getFiles($scope.directoryData).then(
                    function (response) {
                        console.log("API Service fileDirectoriesService successful");
                        console.log(response);
                        $scope.directoryData.directoryInformation = response;

                        $scope.$parent.directoryData.directoryInformation = response;
                        $scope.fileresults = response;
                    },
                    function () {

                        console.log("API Service Error: awards");

                    }
                );

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7 $scope.directoryData.directoryInformation');

                //$scope.directoryData.directoryInformation.Path2FilesSanitised = [];

                $scope.directoryData.directoryInformation.Path2FilesSanitised = $scope.directoryData.directoryInformation.Path2FilesSanitised;
                console.log($scope.directoryData.directoryInformation);
                console.log($scope.directoryData.directoryInformation.Path2Files);

                $scope.myValue = data;

                return $scope.getGlobalPromise(data);
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

    }

}])