/*(function () {
  'use strict';
  angular
  .module('app')
  .controller('templateCtrl', templateCtrl)

  templateCtrl.$inject = ['$http', '$location', "$stateParams", '$scope', 'FlashService' , 'UploadService'];

  function templateCtrl( $http , $location, $stateParams, $scope, FlashService, UploadService ) {
*/

clouderty.controller("twillioCtrl", ['$http', '$location', "$stateParams", '$scope', 'flashService' , 'twillioService', '$timeout', function ($http , $location, $stateParams, $scope, flashService, twillioService, $timeout)  {

    console.log("in twillioCtrl")

    $scope.sendTwillioSMS = function() {

        console.log("inside function sendTwillioSMS");
        $('.alert').show('');
        var data = new FormData();
        var number = $scope.number;
        var message = $scope.message;

        data.append("number" , number);
        data.append("message" , message);
        data.append('method', "sendSMS");

        twillioService.sendMessage(data,function(res,err) {
            if(err){
                flashService.Error(err,false);
                resetForm();
            }  else{
                if(res.data.status=='success'){
                    flashService.Success(res.data.message,false);
                    resetForm();
                    $("html, body").animate({ scrollTop: 0 }, "slow");
                    $timeout( function(){
                        $('.alert').html('');
                        $('.alert').hide();
                    }, 5000 );
                }
            }
        });
    }

    function resetForm() {
        $scope.number = null;
        $scope.message = null;
    }
}])