/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("awardsTicketsGuestListCtrl", ["$scope", "$rootScope",  "$http", "$stateParams", "$state", "apiService", "fCsv",  "exportCSVService", "dateInputService", "$filter", "$interval", "$q", "openNewWindowService", "$route", "postUserActivityService", "loginExternalWebPageService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, fCsv, exportCSVService, dateInputService, $filter, $interval, $q, openNewWindowService, $route, postUserActivityService, loginExternalWebPageService) {

    console.log("inside controller awardsTicketsManageCtrl");

    $rootScope.watchAwardsSurround = 1;


    $scope.$watch('watchAwardsSurround', function (toVariable, fromVariable) {

        console.log("inside watch and $rootScope.watchAwardsSurround is: " + $rootScope.watchAwardsSurround);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

        if (toVariable == 1) {

            console.log("inside watch and obj is: " + $rootScope.watchAwardsSurround);
            console.log("inside watch and fromVariable is: " + fromVariable);
            console.log("inside watch and toVariable is: " + toVariable);

            $('.data').hide();

            $scope.pageno = 1;
            $scope.totalCount = 20;
            $scope.itemsPerPage = 20;
            $scope.search = "";
            $scope.progress = {};
            $scope.DateToday = new Date();
            $scope.date = new Date();

            $scope.awardNominations = {};
            $scope.awardCategories = {};
            $scope.awards = {};
            $scope.awards.awardCategories = {};
            $scope.awards.awardNominationRequest = {};
            $scope.awards.awardsNominationRequest = {};
            $scope.awards.awardNominator = {};
            $scope.awards.awardTickets = [];
            $scope.awards.awardsTickets = [];
            $scope.awards.awardsTicketsAll = [];
            $scope.awardsData = {};
            $scope.awardsTickets = {};
            $scope.awardsTableNo = "";

            $scope.awards.awardsTicketsAllMenus = [];
            $scope.getAwardsMealOrders =  ["Tenant_ID", "Tenancy_ID", "First_Name", "Last_Name",  "Address_1", "Unit_number", "Monthly_rental_price", "Tenancy_start_date", "Tenancy_end_date", "Date_leaving", "Tenants", "Current"];
            $scope.fileNameAwardsMealOrders  = "Tenancies";
            $scope.totalCountAwardsMealOrders = 0;

            $scope.countriesData = {};
            $scope.countries = {};

            $scope.nomineesDetailsBloc = 1;
            $scope.nomineesDetailsVisibleBloc = 1;
            $scope.nominationSubmittedBlock = 0;
            $scope.paymentTransactionsData = {};
            $scope.payment_transaction = {};
            $scope.payment_transaction.cardDetails = {};

            $scope.directoryData = {};
            $scope.directoryDataRetrieved = {};
            $scope.directoryData.directoryInformation = {};
            $scope.directoryData.directoryInformation.Path2Files = {};
            $scope.fileDeleteData = {};
            $scope.stateCurrent = "";

            $scope.noteGet = {};
            $scope.note_loading = false;
            $scope.posted_note = {};

            /////////////////// Cancel previous AJAX code (A)
            var canceller, isSending = false;

            //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )

                return q55.promise;

            }

            $scope.sort = function(keyname){

                console.log("inside sort function");
                $scope.sortKey = keyname;   //set the sortKey to the param passed
                $scope.reverse = !$scope.reverse; //if true make it false and vice versa
            }

            $scope.awardsFunction = function(id, status) {

                console.log("status >>>> " + status);
                var qawardsFunction = $q.defer();

                console.log("function processesAccountFunction");
                console.log("$scope.pageno" + $scope.pageno);

                $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.awardsData.id = $rootScope.decodedToken.id;
                $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
                $scope.awardsData.pageno = $scope.pageno;
                $scope.awardsData.Status = status;
                $scope.awardsData.Process_ID = id;

                if(status == 56){

                    $scope.awardsData.itemsPerPage = 999999999;
                    $scope.itemsPerPage = 999999999;

                }

                if($scope.search){

                    $scope.awardsData.search = $scope.search;

                }
                else{

                    $scope.awardsData.search = "*";

                }

                console.log($scope.awardsData);

                apiService.awards(id, $scope.awardsData).then(
                    function (response) {
                        console.log("apiService awards service");

                        //$scope.awardsData.Awards_Category_Questions_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                        if(status == 60){
                            console.log(response);
                            console.log("apiService awards service successful status 60");
                            $scope.awardCategories = response.data.awardsCategories;

                        }
                        else if(status == 12){
                            console.log(response);
                            console.log("apiService awards service successful status 12");
                            $scope.awards.awardsNominationValidation = response.data.data;
                        }
                        else if(status == 11 || status == 112 || status == 53){// view awards tickets
                            console.log(response);
                            console.log("apiService awards service successful status 53");
                            $scope.awards.awardsTickets = response.data.awardsTickets;
                            $scope.awards.awardsTicketsCount = response.data.awardsTicketsCount;
                            $scope.totalCount = response.data.awardsTicketsCount;
                            $scope.currentPage = $scope.pageno;
                            console.log($scope.awards.awardsTickets);
                            console.log($scope.awards.awardsTicketsCount);
                        }
                        else if(status == 56){// view awards tickets
                            console.log(response);
                            console.log("apiService awards service successful status 53");
                            $scope.awards.awardsTickets = response.data.awardsTickets;
                            $scope.awards.awardsTicketsCount = response.data.awardsTicketsCount;
                            $scope.totalCount = response.data.awardsTicketsCount;
                            $scope.currentPage = $scope.pageno;
                            console.log($scope.awards.awardsTickets);
                            console.log($scope.awards.awardsTicketsCount);
                        }
                        else if(status == 54){// view awards tickets
                            console.log(response);
                            console.log("apiService awards service successful status 54");
                            $scope.awards.awardsTicketsAll = response.data.awardsTicketsAll;
                            console.log($scope.awards.awardsTicketsAll);
                        }
                        else if(status == 55){// view awards tickets all menu
                            console.log(response);
                            console.log("apiService awards service successful status 54");
                            $scope.awards.awardsTicketsAllMenus = response.data.awardsTicketsAllMenus;
                            console.log($scope.awards.awardsTicketsAllMenus);
                        }
                        else if(status == 70 || status == 701 || status == 702 || status == 703){
                            console.log(response);
                            console.log("apiService awards service successful status 71");
                            $scope.awards.awardsNominations = response.data.awardsNominations;
                            $scope.awards.awardsNominationsCount = response.data.awardsNominationsCount;
                            $scope.totalCount = response.data.awardsNominationsCount;
                            /*
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                            */
                        }
                        else if(status == 71){
                            console.log(response);
                            console.log("apiService awards service successful status 71");
                            $scope.awards.awardsNomination = response.data.awardsNomination;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }
                        else if(status == 72){
                            console.log(response);
                            console.log("apiService awards service successful status 71");
                            $scope.awards.awardsNominationRequest = response.data.awardsNominationRequest;
                            /*
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                            */
                        }
                        else if(status == 64){

                            console.log(response);
                            console.log("apiService awards service successful status 64");
                            $scope.awards.awardNominationRequest.First_Name = "";
                            $scope.awards.awardNominationRequest.Last_Name = "";
                            $scope.awards.awardNominationRequest.Email = "";
                            $scope.awardNominations = response.data.data;
                            $scope.nominatorsDetailsBloc = 1;
                            $scope.nomineesDetailsVisibleBloc = 0;

                        }
                        else if(status == 641){
                            console.log(response);
                            console.log("apiService awards service successful status 641");
                            $scope.awards.awardNominator.First_Name = "";
                            $scope.awards.awardNominator.Last_Name = "";
                            $scope.awards.awardNominator.Email = "";
                            $scope.awardNominator = response.data.data;
                            $scope.nominationSubmittedBlock = 1;

                        }
                        else if(status == 80){//update awards question answer
                            console.log(response);
                            console.log("apiService awards service successful status 70");
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }//update awards question answer
                        else if(status == 81){//update property details question completed
                            console.log("apiService awards service successful status 81");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                        }//update property details question completed
                        else if(status == 82){//update awards question completed
                            console.log("apiService awards service successful status 82");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards question completed
                        else if(status == 83){//update property details question answer
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update property details question answer
                        else if(status == 84){//update awards files displayed
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards files displayed
                        console.log("awards API successful");

                        console.log(response);

                        qawardsFunction.resolve(response);


                    },
                    function () {

                        console.log("API Service Error: awards");

                    }

                );

                return qawardsFunction.promise;

            }

            $scope.getData = function(pageno) {
                console.log("inside function getData");

                var qgetData = $q.defer();
                //$scope.awards.awardsTickets = [];


                console.log("callajax");
                if(isSending) {
                    canceller.resolve()
                }

                isSending = true;
                canceller = $q.defer();

                $scope.currentPage = pageno;
                $scope.pageno = pageno;

                qgetData.resolve($scope.awardsFunction('*', $scope.status));

                /*
                if ($scope.search) {
                    console.log("trying search")
                    $scope.landlord.search = $scope.search;

                }
                else if (!$scope.search) {
                    $scope.search = "*";
                    console.log("search null")
                }

        */



                return qgetData.promise;

            }

            $scope.getAwardTickets = function () {
                console.log("inside function getAwardTicket");
                var qgetAwardTickets = $q.defer();
                $scope.awards = {};

                //$scope.awardsQuestionsShow = 1;
                //console.log("$stateParams.awardsTicketID: " + $stateParams.awardsTicketID);
                //$scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                //$scope.awardsData.Awards_Ticket_ID = $stateParams.awardsTicketID;
                //$scope.awardsData.Licence_Key = $stateParams.licenceKey;

                $scope.status = 56;
                qgetAwardTickets.resolve($scope.awardsFunction('*', $scope.status));
                //$scope.getDirItems();
                return qgetAwardTickets.promise;

            }

            $scope.getAwardTicketsAll = function () {
                console.log("inside function getAwardTicketsAll");
                var qgetAwardTicketsAll = $q.defer();

                $scope.status = 54;
                qgetAwardTicketsAll.resolve($scope.awardsFunction('*', $scope.status));
                return qgetAwardTicketsAll.promise;

            }

            $scope.getAwardTicketsAllMenus = function () {
                console.log("inside function getAwardTicketsAll");
                var qgetAwardTicketsAllMenus = $q.defer();

                $scope.status = 55;
                qgetAwardTicketsAllMenus.resolve($scope.awardsFunction('*', $scope.status));
                return qgetAwardTicketsAllMenus.promise;

            }

            $scope.actionUpdate = function (action) {
                console.log("inside function actionUpdate");
                var date = new Date();
                date.setHours(date.getHours()+1);
                console.log("Progress 1: ");

                console.log($scope.awards.awardsTicketsAll);



                if(action == 1) {
                    console.log("ACTION: 1 - Send all first ticket emails");
                    var qactionUpdate = $q.defer();

                    $scope.promise = $scope.getAwardTicketsAll();

                    $scope.promise
                        .then(function (data) {


                            console.log("A2 - getNomination");


                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2 - send ticket email");

                            if(confirm("Do you want to send out first ticket emails?")){

                                console.log($scope.awards.awardsTicketsAll);
                                angular.forEach($scope.awards.awardsTicketsAll, function(value, key){
                                    console.log("ACTION: 1 - Send all first ticket emails 2");
                                    if(!$scope.awards.awardsTicketsAll[key].Date_Ticket_Information_Email_Sent)
                                    {
                                        console.log("ACTION: 1 - Send all first ticket emails 3 - " + $scope.awards.awardsTicketsAll[key].Awards_Ticket_ID + "  - ");

                                        console.log("ACTION: 1 - Send all first ticket emails 4 - " + $scope.awards.awardsTicketsAll[key].Email);
                                        //console.log($scope.awards.awardsTickets.Awards_Ticket_ID[key]);

                                        var from_email = "Clouderty | Ticket Information " + " | Ticket Number " + $scope.awards.awardsTicketsAll[key].Awards_Ticket_ID + " <notifications@clouderty.co.uk>";
                                        var subject = $scope.awards.awardsTicketsAll[key].Customer_Name + " | " + 'Property Investors Awards';
                                        var message = "Dear " + $scope.awards.awardsTicketsAll[key].Customer_Name + ",<br /><br />" +
                                            "Ticket number: <b><u>" + $scope.awards.awardsTicketsAll[key].Awards_Ticket_ID + "</u></b><br /><br />" +
                                            "Your email address is: " + $scope.awards.awardsTicketsAll[key].Email + "<br /><br />" +
                                            "With not long to go until the Property Investors Awards, we're gearing up for our most exquisite event to date. <b><u><br /><br />Please click on the link below to select your menu choice.</u></b>" + "<br /><br />" +
                                            "" + $scope.awards.awardsTicketsAll[key].URL_Awards_Ticket + "<br /><br />" +
                                            "Menu orders are <u><b>required by 16th November</b></u>.<br /> If you do not respond by then, your meal choice will automatically become option 1 which is not vegetarian." + "<br /><br />" +
                                            "Date: Saturday 1st December 2018" + "<br /><br />" +
                                            "Time: 6.30pm until 12.30am" + "<br /><br />" +
                                            "Location: Royal National Hotel, Russell Square, WC1H ODG" + "<br /><br />" +
                                            "We have a fantastic evening planned for you and look forward to celebrating the successes of 2018 with you on the night." +
                                            "<br /><br />Kind regards,<br />" +
                                            "Property Investors Awards " + "<br />" +
                                            "Tel: 01206 700 123"  + "<br />" +
                                            "https://propertyinvestorsawards.co.uk/";

                                        $scope.postUserActivityServiceMessage = subject;
                                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                                        console.log("Email value: " + $scope.awards.awardsTicketsAll[key].Email);
                                        to_email = $scope.awards.awardsTicketsAll[key].Email;
                                        //to_email = "c.thomas@platinumcrown.co.uk";
                                        var cc = "awards@propertyinvestorsawards.co.uk";
                                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name, cc);

                                        $scope.updateAwardsTicketEmailDate($scope.awards.awardsTicketsAll[key]);
                                    }

                                })



                            }



                            return $scope.getGlobalPromise(data);
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                                console.log('A7a');
                                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                                $('.data').hide();
                            },
                            function (err) {


                                $scope.myValue = err;

                            })

                    qactionUpdate.resolve($scope.promise);


                }
                else if (action == 6) {
                    console.log("ACTION: 6");




                }else if (action == 2) {
                    console.log("ACTION: 2 - Download menus to csv");
                    var qactionUpdate = $q.defer();

                    $scope.promise = $scope.getAwardTicketsAllMenus();

                    $scope.promise
                        .then(function (data) {


                            console.log("A2 - getNomination");


                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2 - download ticket menus to csv");

                            exportCSVService.exportCSVService($scope.awards.awardsTicketsAllMenus);
                            //exportCSVService.exportCSV();

                            return $scope.getGlobalPromise(data);
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                                console.log('A7a');
                                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                                $('.data').hide();
                            },
                            function (err) {


                                $scope.myValue = err;

                            })

                    qactionUpdate.resolve($scope.promise);


                }else if (action == 3) {
                    //$scope.getDataExpired(1)
                    $scope.addLandlordRegularPaymentsTransaction();
                } else if (action == 4) {
                    //send landlord statement
                    $scope.awardsFunction('*', 703)
                }else if (action == "") {
                    console.log("Expired Tenancies within 3 Month");
                    //$scope.getDataExpired(1)
                    //$scope.getDataExpiredThreeMonth(1)
                    //$scope.progress.date_completed = null;
                } else if (action == "VIEW CURRENT") {
                    console.log("Progress 3: ");
                    //$scope.getDataExpired(1)
                    //$scope.getDataCurrent(1)
                    //$scope.progress.date_completed = null;
                }
                else if (progress == "VIEW ALL") {
                    console.log("Progress 3: ");
                    //$scope.getData($scope.pageno)

                } else{

                }

            }

            $scope.actionAwardsTicket = function (action, awardsTicket) {
                console.log("inside function actionAwardsTicket");
                var date = new Date();
                date.setHours(date.getHours()+1);
                console.log("Progress 1: ");

                if(action == 1) {
                    console.log("ACTION: 1");


                    //$scope.landlordStatementView()

                } else if (action == 6) {
                    console.log("ACTION: 6");


                    //$scope.landlordStatementLicenseView()

                } else if (action == 2) {
                    //$scope.addOutstandingAgentsManagementFee();

                    console.log("Send PIA menu email");
                    console.log(awardsTicket);
                    var from_email = "Clouderty | Ticket Information " + " | Ticket Number" + awardsTicket.Awards_Ticket_ID + " <notifications@clouderty.co.uk>";
                    var subject = awardsTicket.Customer_Name + " | " + 'Property Investors Awards';
                    var message = "Dear " + awardsTicket.Customer_Name + ",<br /><br />" +
                        "Your ticket number is: <b><u>" + awardsTicket.Awards_Ticket_ID + "</u></b><br /><br />" +
                        "With not long to go until the Property Investors Awards, we're gearing up for our most exquisite event to date. <b><u><br /><br />Please click on the link below to select your menu choice.</u></b>" + "<br /><br />" +
                        "" + awardsTicket.URL_Awards_Ticket + "<br /><br />" +
                        "Menu orders are needed by 16th November. If you do not respond by then, we'll have to assume that all of your meal will be the standard non-vegetarian choice." + "<br /><br />" +
                        "Event date: Saturday 1st December 2018" + "<br /><br />" +
                        "Location: Royal National Hotel, Russell Square, WC1H ODG" + "<br /><br />" +
                        "We have a fantastic evening planned for you and we look forward to celebrating the successes of 2018 with you on the night." +
                        "<br /><br />Kind regards," +
                        "Property Investors Awards " + "<br />" +
                        "Tel: 01206 700 123"  + "<br />" +
                        "https://propertyinvestorsawards.co.uk/";

                    $scope.postUserActivityServiceMessage = subject;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                    to_email = awardsTicket.Email;
                    var cc = "awards@propertyinvestorsawards.co.uk";

                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name, cc);

                    $scope.updateAwardsTicketEmailDate(awardsTicket);

                }else if (action == 3) {
                    //$scope.getDataExpired(1)
                    //$scope.addLandlordRegularPaymentsTransaction();
                } else if (action == 4) {
                    //send landlord statement
                    //$scope.awardsFunction('*', 703)
                }else if (action == "") {
                    console.log("Expired Tenancies within 3 Month");
                    //$scope.getDataExpired(1)
                    //$scope.getDataExpiredThreeMonth(1)
                    //$scope.progress.date_completed = null;
                } else if (action == "VIEW CURRENT") {
                    console.log("Progress 3: ");
                    //$scope.getDataExpired(1)
                    //$scope.getDataCurrent(1)
                    //$scope.progress.date_completed = null;
                }
                else if (progress == "VIEW ALL") {
                    console.log("Progress 3: ");
                    //$scope.getData($scope.pageno)

                } else{

                }

            }

            $scope.updateAwardsTicketEmailDate = function (awardsTicket) {
                console.log("inside function updateAwardsTicketEmailDate");
                console.log(awardsTicket);
                var qupdateAwardsTicketEmailDate = $q.defer();
                //$scope.awards = {};
                //console.log("$stateParams.awardsTicketID: " + $stateParams.awardsTicketID);
                //$scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                $scope.awardsData.Awards_Ticket_ID = awardsTicket.Awards_Ticket_ID;
                $scope.awardsData.Licence_Key = awardsTicket.Licence_Key;
                $scope.awardsData.Date_Ticket_Information_Email_Sent =  new Date();
                $scope.status = 24;
                qupdateAwardsTicketEmailDate.resolve($scope.awardsFunction('*', $scope.status));
                //$scope.getDirItems();
                return qupdateAwardsTicketEmailDate.promise;

            }

            $scope.getDataSearch = function(pageno) {

                $scope.awards = {};
                $scope.id = '*';


                $scope.status = 11;



                $scope.getData(pageno);

            }

            $scope.getDataSearchAwardsTable = function(pageno) {

                $scope.awards = {};
                $scope.id = '*';


                $scope.status = 112;
                $scope.awardsData.Table_No = $scope.awardsTableNo;


                $scope.getData(pageno);

            }

            $scope.loadAwardsData = function () {
                console.log("inside function loadAwardsData");


                $('.data').hide();
                var qloadAwardsData = $q.defer();
                $scope.promise = loginExternalWebPageService.init("1");

                $scope.promise
                    .then(function (data) {


                        console.log("A2 - getNomination");
                        //$scope.logoutExternalPage();
                        $rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {


                        console.log("A2 - getNomination");
                        //$scope.logoutExternalPage();
                        //return $rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - getNominations");

                        return $scope.getAwardTickets();
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                            $('.data').hide();
                        },
                        function (err) {


                            $scope.myValue = err;

                        })

                qloadAwardsData.resolve($scope.promise);

            };

            $scope.loginAwardsTicketsGuestList = function (passwordInput) {

                console.log("inside function passwordInput");
                //console.log("passwordInput: " + passwordInput);
                //console.log("$scope.passwordInput: " + $scope.passwordInput);
                if(passwordInput == 'pia2018list'){
                    console.log("inside if passwordInput awardsApplicationShow");
                    $scope.awardsGuestListShow = 1;
                    $scope.loadAwardsData();
                }

            }

            ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

            /*

            if($scope.awardsGuestListShow == 1){



            }

*/


        }

    })




    ////////////////////////// Old /////////////////////////////////////////////////////////////////////////////////

    /*
    $scope.getNominations = function () {
        console.log("inside function getNominationRequests");
        var qgetNominations = $q.defer();

        $scope.awardsData.Awards_Nomination_ID = '*';
        qgetNominations.resolve($scope.awardsFunction("*", 702));

        return qgetNominations.promise;

    }

    $scope.getCategories = function () {

        var qgetCategories = $q.defer();

        qgetCategories.resolve($scope.awardsFunction('*', 60));

        return qgetCategories.promise;

    }

    $scope.getCategory = function (dataObj) {

        console.log("inside function getCategory");
        console.log(dataObj);
        var qgetCategory = $q.defer();

        //$scope.awardsData.Category = dataObj.Category;
        //$scope.awardsData.Description = dataObj.Description;
        $scope.awardsData.Awards_Category_ID = dataObj;
        console.log("$scope.awardsData.Awards_Category_ID: " + $scope.awardsData.Awards_Category_ID);
        //console.log("$scope.awardsData.awardCategories: " + dataObj.Awards_Category_ID);

        qgetCategory.resolve($scope.awardsFunction("*", 701));

        return qgetCategory.promise;
    }

    */





}]);