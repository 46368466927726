
clouderty.controller("maintenanceCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "apiService", "$q", "truncateSliceService", "postUserActivityService", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, apiService, $q, truncateSliceService, postUserActivityService) {

    console.log($scope.$parent.obj.Task_ID);

    console.log("inside controller maintenanceCtrl");

    console.log('$scope.maintenanceobj');
    console.log($scope.maintenanceobj);
    console.log('$scope.maintenancematerialslabour');
    console.log($scope.maintenancematerialslabour);
    console.log('$scope.maintenanceid');
    console.log($scope.maintenanceid);
    console.log('$scope.descriptiontext');
    console.log($scope.descriptiontext);

    $scope.maintenance = {};
    $scope.maintenance.Materials_Required = "";
    $scope.notificationObj = {};
    $scope.notificationObj.vendors = {};
    $scope.maintenance = {};
    $scope.$parent.maintenance = {};
    $scope.maintenance.emailExternalRecipient = "";
    $scope.$parent.maintenance.emailExternalRecipient = "";

    $scope.emailExternalRecipient = "";
    $scope.$parent.emailExternalRecipient = "";

    $scope.emailExternalRecipientChange = function () {

        console.log('$scope.emailExternalRecipient: ' + $scope.maintenance.emailExternalRecipient);
        console.log('$scope.$parent.emailExternalRecipient: ' + $scope.$parent.maintenance.emailExternalRecipient);
    }

    $scope.getGlobalPromise = function () {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.maintenanceFunction = function (id, status) {

        var qMaintenancesFunction = $q.defer();
        console.log("function maintenanceFunction");
        $scope.maintenance.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.maintenance.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.maintenance.User_ID = $rootScope.decodedToken.id;
        $scope.maintenance.itemsPerPage = $scope.itemsPerPage;
        $scope.maintenance.pageno = $scope.pageno;


        $scope.maintenance.Status = status;
        $scope.maintenance.Maintenance_Account_ID = id;
        //$scope.pageno = 1;

        if ($scope.maintenance.Tenancy_ID) {
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        } else {

            $scope.maintenance.Tenancy_ID = "*";
        }


        if ($scope.search) {

            $scope.maintenance.search = $scope.search;
        } else {

            $scope.maintenance.search = "*";
        }

        $scope.maintenanceData = $scope.maintenance;
        $scope.maintenance = {};

        console.log($scope.maintenanceData);

        apiService.maintenance(id, $scope.maintenanceData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");


                if (status == 31 || status == 41) {//41 add maintenance item

                    //$scope.maintenance.Maintenance_Account_ID = $scope.maintenanceobj.Maintenance_Account_ID;
                    $scope.maintenance = response.data.data;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;
                    $scope.maintenanceMaterialsLabourViewSum = response.data.maintenanceMaterialsLabourViewSum;


                    $scope.spinnervariable = $scope.maintenance.Maintenance_Account_ID;
                    $scope.maintenanceid = $scope.maintenance.Maintenance_Account_ID;
                    $scope.spinnerobjsingleid = $scope.maintenance.Maintenance_Account_ID;

                    var urlNotification = window.location.href;
                    var descriptionNotification = "Budget item added to maintenanace account: " + $scope.maintenanceid;

                    if($state.current.name == $rootScope.stateTaskView) {

                        $scope.notificationObj.vendors = $scope.$parent.vendorTasks;
                        console.log('notificationObj.vendors');
                        console.log($scope.notificationObj.vendors);

                    }

                    $rootScope.saveNotification(6, descriptionNotification, urlNotification);

                    console.log(response);
                }
                else if(status == 4){

                    $scope.maintenance = response.data.data;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewSum = response.data.maintenanceMaterialsLabourViewSum;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;
                    $scope.maintenance.Materials_Required = decodeURIComponent($scope.maintenance.Materials_Required);

                    $scope.maintenanceid = $scope.maintenance.Maintenance_Account_ID;

                    console.log("$scope.maintenanceid:" + $scope.maintenanceid);
                }
                else {

                    $scope.maintenance = response.data.data;
                    $scope.maintenanceMaterialsLabourView = response.data.maintenanceMaterialsLabourView;
                    $scope.maintenanceMaterialsLabourViewSum = response.data.maintenanceMaterialsLabourViewSum;
                    $scope.maintenanceMaterialsLabourViewCount = response.data.maintenanceMaterialsLabourViewCount;
                    //console.log(decodeURIComponent("https%3A%2F%2Fw3schools.com%2Fmy%20test.asp%3Fname%3Dst%C3%A5le%26car%3Dsaab%C2%A3%C2%A3%C2%A3"));
                    $scope.maintenance.Materials_Required = decodeURIComponent($scope.maintenance.Materials_Required);

                }

                $scope.maintenanceVariable = response.data;
                $scope.maintenanceSpinner = 1;
                console.log("$scope.maintenanceVariable: " + $scope.maintenanceVariable);
                console.log($scope.maintenanceVariable);

                console.log("$scope.maintenance.Clients_Budget: " + $scope.maintenance.Maintenance_Account_ID);
                console.log(response);
                console.log("maintenance API");
                qMaintenancesFunction.resolve(response);

            },
            function () {
                console.log("API Service Error: maintenance")
            }
        );

        if ($scope.search == "*") {
            $scope.search = "";
        }
        else {

        }
        ;

        return qMaintenancesFunction.promise;
    }

    $scope.addMaintenanceAccountTask = function () {

        console.log("inside function addMaintenanceAccountTask");

        if (confirm("Do you want to add a maintenance account?", 'CLOUDERTY NOTIFICATION')) {
            //$scope.maintenanceMaterialsLabourView = [];
            $scope.maintenanceSpinner = "";
            $scope.maintenanceid = 0;
            //$scope.gotoAnchor('maintenance');
            console.log("$scope.$parent.obj.Task_ID" + $scope.$parent.obj.Task_ID);
            //console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.maintenanceobj.Maintenance_Account_ID);
            $scope.maintenance.Task_ID = $scope.$parent.obj.Task_ID;
            $scope.maintenance.Property_ID = $scope.$parent.obj.Property_ID;
            //$scope.maintenance.Description = dataObj.Description;
            //$scope.paymentBlockSpinner = 1;

            var qaddMaintenanceAccount = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    return $scope.maintenanceFunction("*", 4);

                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            qaddMaintenanceAccount.resolve($scope.promise);

        }


    }

    $scope.$watch('maintenancedescription', function (toVariable, fromVariable) {

        console.log('$scope.maintenanceobj');
        console.log($scope.maintenanceobj);
        console.log('$scope.maintenancematerialslabour');
        console.log($scope.maintenancematerialslabour);
        console.log('$scope.maintenanceid');
        console.log($scope.maintenanceid);
        console.log('$scope.descriptiontext');
        console.log($scope.maintenancedescription);

        $scope.maintenance.Description = $scope.maintenancedescription;


    })

    $scope.$watch('maintenanceid', function (toVariable, fromVariable) {

        if ($scope.maintenanceid > 0) {

            console.log('$scope.maintenanceobj');
            console.log($scope.maintenanceobj);
            console.log('$scope.maintenancematerialslabour');
            console.log($scope.maintenancematerialslabour);
            console.log('$scope.maintenanceid');
            console.log($scope.maintenanceid);
            console.log('$scope.descriptiontext');
            console.log($scope.descriptiontext);

            console.log("inside watch and $scope.maintenanceid is: " + $scope.maintenanceid);
            console.log("inside spinnervariable watch and fromVariable is: " + fromVariable);
            console.log("inside spinnervariable watch and toVariable is: " + toVariable);

            console.log($scope.maintenanceobj);
            console.log($scope.maintenancematerialslabour);

            $scope.maintenance = {};
            $scope.maintenanceData = {};
            $scope.maintenanceMaterialsLabourView = {};
            $scope.maintenanceMaterialsLabourViewCount = {};
            $scope.maintenanceMaterialsLabourViewSum = {};

            $(document).ready(function () {
                $('.nav-tabs li').on("click", function () {
                    console.log('is this it 1');
                    $('.nav-tabs li').removeClass("active");
                    $(this).addClass("active");
                })
            })
            $('.data').hide();

            $scope.accordion = function (element, element_data) {
                console.log('inside function accordion');
                $('.data').slideUp("fast");
                $('.chev').removeClass("fa-chevron-down");
                $('.chev').addClass("fa-chevron-left");

                if ($('.' + element_data).is(":hidden")) {
                    $('.' + element).removeClass("fa-chevron-left");
                    $('.' + element).addClass("fa-chevron-down");
                    $('.' + element_data).slideToggle("fast");
                }
                else if ($('.' + element_data).is(":visible")) {
                    $('.' + element).removeClass("fa-chevron-down");
                    $('.' + element).addClass("fa-chevron-left");
                }
            }

            $scope.getGlobalPromise = function () {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )


                return q55.promise;
            }

            $scope.getMaintenanceAccount = function () {

                console.log('inside function getMaintenanceAccount');
                var qGetMaintenanceAccount = $q.defer();
                qGetMaintenanceAccount.resolve($scope.maintenanceFunction($scope.maintenanceid, 68));

                return qGetMaintenanceAccount.promise;
            }

            $scope.addMaintenanceItem = function (dataObj) {

                console.log("inside function addMaintenanceItem");

                console.log(dataObj);

                if (confirm("Do you want to add this item?", 'Clouderty notification')) {
                    $scope.maintenance.Maintenance_Account_ID = 0;
                    //$scope.gotoAnchor('maintenance');

                    console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.maintenance.Maintenance_Account_ID);
                    //$scope.maintenance.Maintenance_Account_ID = $scope.maintenance.Maintenance_Account_ID;

                    if (dataObj.descriptionAdd){

                        $scope.maintenance.Description = dataObj.descriptionAdd;

                    }
                    else if ($scope.maintenancedescription) {

                        $scope.maintenance.Description = $scope.maintenancedescription;


                    }
                    $scope.maintenance.Quantity = dataObj.qtyAdd;
                    $scope.maintenance.Cost = dataObj.costAdd;
                    $scope.maintenance.User_ID = $rootScope.decodedToken.id;
                    //$scope.paymentBlockSpinner = 1;

                    var q = $q.defer();

                    $scope.myValue = 0;
                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A1 login external");

                            return $scope.maintenanceFunction($scope.maintenanceid, 41);

                        })
                        .then(function (data) {
                            console.log('A7');

                            $scope.myValue = data;

                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })

                    q.resolve($scope.promise);

                }


            }

            $scope.deleteMaintenanceItem = function (dataObj) {

                console.log("inside function deleteDirItem");

                console.log(dataObj);

                if (confirm("Do you want to delete this item?", 'Clouderty notification')) {
                    console.log("in function dirTests");
                    //$scope.spinnerobjsingleid = 0;
                    //$scope.spinnerobj = {};
                    //$scope.maintenancedescription = {};
                    console.log("$scope.maintenance.Maintenance_Account_ID" + $scope.maintenance.Maintenance_Account_ID);
                    $scope.maintenance.Maintenance_Account_ID = 0;
                    //$scope.spinnervariable = 0;
                    console.log("$scope.maintenance.Maintenance_Account_ID" + $scope.maintenance.Maintenance_Account_ID);

                    //$scope.maintenanceSpinner = 1;
                    //$scope.maintenanceVariable = {};
                    //$scope.maintenanceSpinner = 0;

                    //$scope.spinnerobj = {};
                    //$scope.maintenance = {};



                    //$scope.maintenanceMaterialsLabourView = [];
                    //$scope.maintenance = {};
                    //$scope.maintenanceSpinner = "";

                    console.log("$scope.processAccount.data.Maintenance_Account_ID: " + $scope.maintenance.Maintenance_Account_ID);
                    //$scope.maintenance.Maintenance_Account_ID = $scope.maintenance.Maintenance_Account_ID;
                    $scope.maintenance.Maintenance_Materials_Labour_ID = dataObj.Maintenance_Materials_Labour_ID;
                    //$scope.paymentBlockSpinner = 1;

                    var q = $q.defer();

                    $scope.myValue = 0;
                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A1 login external");

                            $scope.maintenanceFunction($scope.maintenanceid, 31);
                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2");


                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7');

                            $scope.myValue = data;

                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })

                    q.resolve($scope.promise);

                }


            }

            $scope.updateMaintenanceAccount = function () {

                console.log("inside function updateMaintenanceAccount");


                if (confirm("Do you want to update this account?", 'CLOUDERTY NOTIFICATION')) {

                    var date = new Date();
                    date.setHours(date.getHours() + 1);


                    $scope.maintenance.User_ID = $rootScope.decodedToken.id;

                    var q62 = $q.defer();

                    $scope.promise = $scope.getGlobalPromise();


                    $scope.promise
                        .then(function (data) {
                            console.log('maintenanceFunction');


                            return $scope.maintenanceFunction($scope.maintenanceid, 2);

                            //$scope.myValue = data;
                        })
                        /*.then(function (data) {
                            console.log('getMaintenanceAccount()');


                            return $scope.getMaintenanceAccount();

                            //$scope.myValue = data;
                        })*/
                        .then(function () {

                                console.log('A7b');

                                console.log("$scope.maintenance.Clients_Budget: " + $scope.maintenance.Maintenance_Account_ID);

                                //$state.reload("lettings-main-nav.lettings-side.process-account-view");

                                //$scope.myValue = data;
                            }
                            , function (err) {

                                console.log('A7c error');


                                $scope.myValue = err;
                            })



                    q62.resolve($scope.promise);


                    return q62.promise;

                }

            }

            $scope.sendQuote = function (dataObj, subTotal, maintenance) {

                console.log("inside function sendQuote");

                console.log('maintenance');
                console.log(maintenance);
                console.log('dataObj');
                console.log(dataObj);

                if (confirm("Do you want to send quote?", 'Clouderty notification')) {

                    $scope.date = new Date();

                    //$rootScope.saveNotification(7);
                    var from_email = "Clouderty | Maintenance Quote " + maintenance.Maintenance_Account_ID + " | " + maintenance.Description + " <notifications@clouderty.co.uk>";
                    var subject = "Sent by: " + $rootScope.decodedToken.Name + " | " + maintenance.Property_name;
                    var message = "Maintenance Account ID: " + dataObj[0].Maintenance_Account_ID+ "<br /><br />" +
                        "Property: " + maintenance.Property_name + "<br /><br />" +
                        "Description: " + maintenance.Description + "<br /><br />" +
                        "Quote sent by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                        "Date completed: " + $scope.date + "<br /><br />";


                    message = message + "<table style='width:100% ; border: 1px solid #ccc; text-align: left;'><thead><tr><th style='border: 1px solid #ccc;'>Description</th><th style='border: 1px solid #ccc;'>Cost</th><th style='border: 1px solid #ccc;'>Quantity</th><th style='border: 1px solid #ccc;'>Sub Total Cost</th></tr></thead><tbody>";

                    for (var i = 0; i < dataObj.length; i++)
                    {
                        message += "<tr style='border: 1px solid #ccc;'>";
                        message += "<td style='border: 1px solid #ccc;'>"+dataObj[i].Description+"</td>";
                        message += "<td style='border: 1px solid #ccc;'>&#163;"+dataObj[i].Cost+"</td>";
                        message += "<td style='border: 1px solid #ccc;'>"+dataObj[i].Quantity+"</td>";
                        message += "<td style='border: 1px solid #ccc;'>&#163;"+dataObj[i].Sub_Total_Cost+"</td>";
                        message += "</tr>";
                    }
                    message += "<tr style='border: 1px solid #ccc;'>";
                    message += "<td colspan='4' style='border: 1px solid #ccc;'>&#163;Sub-total: "+subTotal+"</td>";
                    message += "</tbody><table>";



                    $scope.postUserActivityServiceMessage = subject;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                    var to_email = $rootScope.decodedToken.Notifications_Email;
                    console.log('External email to_email:' + to_email);
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    var to_email2 = $scope.maintenance.emailExternalRecipient;
                    console.log('External email:' + $scope.maintenance.emailExternalRecipient);

                    //console.log('External email:' + $scope.$parent.maintenance.emailExternalRecipient);

                    console.log('External email to_email:' + to_email2);
                    $rootScope.sendEmail(from_email, to_email2, subject, message, $rootScope.decodedToken.Name);

                    var urlNotification = window.location.href;
                    var descriptionNotification = "Quote sent to: " + dataObj[0].Maintenance_Account_ID + " - " + maintenance.Property_name;


                    if($state.current.name == $rootScope.stateTaskView) {

                        $scope.notificationObj.vendors = $scope.$parent.vendorTasks;
                        console.log('notificationObj.vendors');
                        console.log($scope.notificationObj.vendors);

                    }

                    $rootScope.saveNotification(7, descriptionNotification, urlNotification, $scope.notificationObj);


                }


            }

            $scope.gotoAnchor = function (x) {
                console.log("inside function gotoAnchor");
                var newHash = x;
                if ($location.hash() !== newHash) {
                    // set the $location.hash to `newHash` and
                    // $anchorScroll will automatically scroll to it
                    $location.hash(x);
                } else {
                    // call $anchorScroll() explicitly,
                    // since $location.hash hasn't changed
                    $anchorScroll();
                }
            };

            $scope.vatCalculatePerItem =  function (x) {

                console.log("$scope.file.costVAT: " + $scope.file.costVAT);
                $scope.file.costVAT = 0.2 * x;

            }

            $scope.loadData = function () {

                console.log("C1 loadData maintenance");
                var qa = $q.defer();
                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("getMaintenanceAccount");
                        return $scope.getMaintenanceAccount();

                        //return $scope.getGlobalPromise(data);
                    })/*
                    .then(function (data) {

                        console.log("A1 login external");

                        return $scope.maintenanceFunction($scope.maintenanceid, 11);

                    })*/
                    .then(function (data) {
                        console.log('A7');

                        //$('.maintenance-account-data').hide();

                        //$('.data').hide();
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qa.resolve($scope.promise);
            }

            $scope.$watch('spinnerobjsingleid', function (toVariable, fromVariable) {


            })

            $scope.loadData();

        }

    })

}])