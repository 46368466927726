/**
 * Created by Aaron on 22/07/2016.
 */

clouderty.controller("exportCSVCtrl", ["$scope", "exportCSVService", "$rootScope", "$http", "$stateParams", "$state", "apiService", "reverseFilter", "sumOfValueFilter", "totalSumPriceQtyFilter", "yesNoFilter", "$filter", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$q", "$window", "$cookies", "postUserActivityService", "loginExternalWebPageService",  function ($scope, exportCSVService, $rootScope, $http, $stateParams, $state, apiService, reverseFilter, sumOfValueFilter, totalSumPriceQtyFilter, yesNoFilter, $filter, $mdDialog, $mdMedia, Upload, $timeout, $q, $window, $cookies, postUserActivityService, loginExternalWebPageService) {

    console.log("inside eportCSVCtrl");

    //console.log($scope.exportobj);

    $scope.$watch('exportobjtotalcount', function (toVariable, fromVariable) {
        console.log("inside watch exportobj");



        if($scope.exportobjtotalcount > 0){
            console.log("inside watch and scope.exportobjtotalcount is: " + $scope.exportobjtotalcount);
            console.log($scope.exportobj);

            console.log("inside spinnervariable watch and fromVariable is: " + fromVariable);
            console.log("inside spinnervariable watch and toVariable is: " + toVariable);

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )


                return q55.promise;
            }

            $scope.exportCSVConstruct = function() {

                console.log("inside function exportCSVConstruct");
                console.log($scope.exportobj);
                console.log($scope.csvheader);
                console.log($scope.filenamecsvobj);
                console.log($scope.exportobjtotalcount);
                console.log($scope.elementid);
                console.log($scope.filenamecsv);

                $scope.getHeader = $scope.csvheader;
                $scope.getElementID = $scope.elementid;

                $scope.filename = $scope.filenamecsvobj;

                console.log("$scope.filename: " + $scope.filename);
                $scope.itemsPerPage = 200000;

                $scope.exportCSVData = exportCSVService.exportCSV($scope.exportobj, $scope.getElementID);

            }

        }

    })


}])