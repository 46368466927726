/**
 * Created by Cyril on 31/07/2016.
 */


clouderty.controller("propertyAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$stateParams", "apiService", "dateInputService", function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $stateParams, apiService, dateInputService) {

    console.log("propertyAddEditCtrl");

    $scope.obj = {};
    $scope.property = {};
    $scope.tenants = {};
    $scope.searchTenant = {};
    $scope.tenantsDetailsPreUpdate = [];
    $scope.objre = new Date();





    //dateDOB()

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];

    $('.data').hide();
    $('.accordion-1-form').show();

    $scope.New_Record = false;

    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.searchProperty = function() {
        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
        //$scope.tenants = null;
        $http.get('api/tenant/' + $stateParams.id + "/" + $stateParams.accountHolderID).success(function(response) {
            $scope.tenants = response;
            //$scope.obj.D_O_B = new Date(response.D_O_B);
            //$scope.obj.D_O_B = $scope.dateDOB();
            //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
            //$scope.obj.D_O_B = $filter('date')(new Date($scope.obj.D_O_B),'dd-MM-yyyy');
            //$scope.obj.D_O_B = date;
            $scope.obj = response;
            //var date = new date("2017-01-12");

            var date = new Date($scope.obj.D_O_B);
            var day = date.getDate();
            var monthIndex = date.getMonth() + 1;
            var year = date.getFullYear();
            var newDate = day + "/" + monthIndex + "/" + year;
            console.log("newDate: " + newDate);
            $scope.obj.D_O_B = new Date(newDate);

        //$scope.obj.D_O_B = new date(newDate);
            console.log($scope.obj.D_O_B);
            //$scope.tenantsDetailsPreUpdate = response;
            $scope.obj.First_Name = response.First_Name;
            console.log(response);
            //$scope.objre.D_O_B = response.D_O_B;

            return $scope.tenants;
        })
    }

    if ($stateParams.id == null) {
        $scope.New_Record = true;
    }else{
        $scope.New_Record = false;
        $scope.obj = $scope.searchProperty();
    }



    //$scope.obj.selectedTenants = [];

    $scope.selectTenant = function(id) {
        console.log("In selected tenant");
        $http.get("api/tenant/" + id).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.Tenant_ID,
                "First_Name": response.First_Name,
                "Last_Name": response.Last_Name,
                "Email": response.Email,
                "Contact_number": response.Contact_number,
                "D_O_B": response.D_O_B
            })
        })

        $scope.search_tenant = "";
    }


    $scope.hideSearch = false;

    $scope.searchUnit = function() {
        $scope.units = null;
        $http.get('api/units/' + $scope.search_unit).success(function(response) {
            $scope.units = response;
        });
    }


    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }



    $scope.addEditProperty = function() {
        //console.log($scope.obj.User_ID);
        $scope.property.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.property.User_ID = $rootScope.decodedToken.id;
        $scope.property.Date_Added = new Date();
        $scope.property.Property_name = $scope.property.Address_1 + " " + $scope.property.Property_Number ;


        if ($stateParams.id == null) {
            if (confirm('Are you sure you want to add a property?')) {


                apiService.addProperty($scope.property).then(
                    function (response) {

                        $scope.a = response;
                        console.log($scope.a);
                        $scope.property.Property_ID = response.Property_ID

                        //////////////////////////////////////////////////////////////////////////
                        /*
                        console.log('Tenant id obj:' + $scope.obj.Tenant_ID);

                        var from_email = "Clouderty | Tenant Added | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = "office@platinumcrown.co.uk";
                        var subject = $scope.obj.First_Name + " | Created by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "Tenant name: " + $scope.obj.First_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.obj.Tenant_ID + "\n\n" +
                            "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        //console.log('Tenancy end date' + $scope.obj.Tenancy_ID);
                        $state.go('lettings-main-nav.lettings-side.lettings-add-tenancy');

                        */

                    },
                    function () {
                        console.log("There was an error in addProperty")
                    }
                )

            }
            else {

            }
        }
        else {
            if (confirm('Are you sure you want to update this tenant?')) {


                //var myJSON = JSON.stringify($scope.obj);

                //$scope.obj.D_O_B = new Date($scope.$parent.obj.D_O_B);
                $scope.tenantsDetailsPreUpdate2 = [];
                //$scope.tenantsDetails = [];
                $scope.tenantsDetailsPreUpdate2 = $scope.tenantsDetailsPreUpdate;
                //$scope.tenantsDetailsPreUpdate = $scope.obj;



                apiService.updateTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $scope.obj).then(
                    function(response) {

                        $scope.$parent.obj = $scope.searchProperty();
                        console.log(response);
                        //console.log(response.data);
                        //console.log(response.data.data);
                        //console.log($scope.obj.D_O_B );

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);

                        $state.reload("lettings-main-nav.lettings-side.lettings-edit-tenant", {id: $stateParams.id});

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
                        console.log(response);
                        console.log(response.data);
                        var from_email = "Clouderty | Tenant Details Updated | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = "office@platinumcrown.co.uk";
                        var subject = $scope.obj.First_Name + " | Tenant Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "First name: " + $scope.obj.First_Name + "\n\n" +
                            "Last Name: " + $scope.obj.Last_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.tenantsDetailsPreUpdate2.First_Name + "\n\n" +
                            "Last Name: " + $scope.tenantsDetailsPreUpdate2.Last_Name + "\n\n" +
                            "Email: " + $scope.tenantsDetailsPreUpdate2.Email + "\n\n" +
                            "DOB: " + $scope.tenantsDetailsPreUpdate.D_O_B + "\n\n" +
                            "Contact number: " + $scope.tenantsDetailsPreUpdate.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    },
                    function() {
                        alert("There was an error")
                    }
                )
            }

        }
    }
}])