/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('awardsHeader', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            pagetitlescope: '=',
            pagetitlesstring: '@'
        },
        templateUrl: 'views/awards/awards-header.html',
        //templateUrl: 'views/financials/payment-transaction-add-edit.html',
        link:function(scope,elem,attrs){

        }
    }
}])