/**
 * Created by Aaron on 02/08/2016.
 */
clouderty.controller("taskAddedEmailCtrl", ["$scope", "$http", "$uibModalInstance", "obj", "$filter", "$rootScope", function($scope, $http, $uibModalInstance, obj, $filter, $rootScope) {

    $scope.obj = obj;
    $scope.obj.Date_started = $filter('date')($scope.obj.Date_started, 'dd/MM/yyyy hh:mm:ss');
    console.log($scope.obj);

    var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
    var to_email = "aaron@platinumcrown.co.uk";
    var subject = "Task " + $scope.obj.Task_ID + ": '" + $scope.obj.Description + "' Created By: " + $rootScope.decodedToken.Name;
    var message = "Task ID: " + $scope.obj.Task_ID + "\n Task Name: " + $scope.obj.Description + "\nStarted On: " + $scope.obj.Date_started;

    $scope.ok = function () {
        $rootScope.sendEmail(from_email, to_email, subject, message);
        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}])