/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('paymentTransactionAddEdit', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            pagetitlescope: '=',
            pagetitlesstring: '@'
        },
        //templateUrl: 'views/awards/awards-header.html',
        templateUrl: 'views/financials/payment-transaction-add-edit.html',
        controller: 'paymentTransactionAddEditCtrl',
        link:function(scope,elem,attrs){

            console.log("set link function in paymentTransactionAddEditDirective");
            $rootScope.watchPaymentAddEdit = 1;
            $rootScope.paymentTransactionAddEditDorective = 1;
            //scope.$parent.watchPaymentAddEdit = 1;
            console.log("watchPaymentAddEdit: " + $rootScope.watchPaymentAddEdit);
        }
    }
}])