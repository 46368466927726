/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.factory('postUserActivityService', ["$window", "$http", "$location", function($window, $http, $location) {
    return{

        postUserActivityService: function(userID, activity){
            console.log("inside postUserActivityService");
            obj = {};
            // console.log("inside service before");

            obj.User_ID = userID
            obj.Activity = activity;
            //alert("testing");
            //Get longitude and latitude coordinates
            try {
                if (navigator.geolocation) {$window.navigator.geolocation.getCurrentPosition(function(position) {
                    //alert("testing 2");


                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    obj.Latitude = lat;
                    obj.Longitude = lng;

                    console.log("lat:" + lat + " - lng:" + lng);

                    var absUrl = $location.absUrl();
                    var date = new Date();

                    // console.log("absUrl:" + absUrl);


                    //obj.Latitude = '1111111';
                    //obj.Longitude = '2222222';
                    obj.URL_Location = absUrl;
                    obj.Activity_Time = date;

                    $http.post('api/global', obj).success(function (response) {

                    });
                    
                    //});
                    
                    console.log("inside service after");

                    }, console.log("inside postUserActivityService NOT-SUPPORTED 1"));
                    
                    } else {
                        console.log("inside postUserActivityService NOT-SUPPORTED 2");
                    }

            } catch (evt) {
                //alert("testing 3");
                console.log("geolocation not selected");


            }

        }
    };
}]);
