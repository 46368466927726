/**
 * Created by Aaron on 08/07/2016.
 */

clouderty.controller("certificatesGasSearchCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "apiService", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, apiService) {


    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("going through upload");
    $scope.certificatesGas = [];
    // page to start on
    $scope.pageno = 1;

    // reset record counter
    $scope.totalCount = 0;

    // amount of records per page
    $scope.itemsPerPage = 20;

    $scope.getData = function(pageno) {

            apiService.gasCertificates($scope.itemsPerPage, pageno, '*', '*',  '*', '*', '*', $scope.search, '*', $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                function (response) {
                    console.log("getAllCertificatesGas API");

                    //$scope.paginationData = response.data;
                    $scope.certificatesGas = response.data.data;
                    $scope.expiredGasCertificates = response.data.expiredGasCertificates;
                    //$scope.$parent.obj = response.data.data;
                    $scope.currentPage = pageno;
                    $scope.totalCount = response.data.totalCount;
                    //$scope.totalExpiredGasCertificatesCount = response.data.totalCount;

                },
                function () {
                    console.log("API Service Error: getAllCertificatesGas")
                }
            );

        /*
        $http.get("api/lettings/certificate-gas/" + $scope.itemsPerPage + "/" + pageno).success(function (response) {

            $scope.certificatesGas = response;
            $scope.currentPage = pageno;
            $scope.totalCount = response.totalCount;
            //$scope.sort('ID');
            console.log('Total count response: ' + response.totalCount);

        })*/

    }

    // activate record selector function
    $scope.getData($scope.pageno);

    // sort function
    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

}])