/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("tenantsRentalAccountBalancesCtrl", ["$scope", "$http", "$stateParams", "$filter", "Upload", "$rootScope", "$state","apiService", "$q", function ($scope, $http, $stateParams, filter, Upload, $rootScope, $state, apiService, $q)  {

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });

    $scope.tenantsRentalTransactions = [];

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.Status = "";
    $scope.search = "";
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();
    $scope.property = [];
    $rootScope.property = {};
    $rootScope.property.Property_name = "";
    $rootScope.property.Unit_number = "";
    //$rootScope.tenancy_id = $stateParams.id;
    $scope.tenananciesRelated = {};

    $scope.tenancies = [];
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.tenantsRentalTransactions = {};
    $scope.tenantsRentalTransactionsData = [];

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
        console.log("Next 6");
    }

    $scope.tenanciesFunction = function(id, status) {
        var q2 = $q.defer();

        console.log("function tenanciesFunction");
        $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
        $scope.tenancies.pageno = $scope.pageno;
        $scope.tenancies.Tenancy_ID = id;
        $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
        $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
        $scope.tenancies.Status = status;


        //$scope.pageno = 1;

        if($scope.tenancies.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }
        else{

            $scope.tenancies.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.tenancies.search = $scope.search;
        }
        else{

            $scope.tenancies.search = "*";
        }


        //console.log($scope.paymentTransactionsData);

        apiService.tenancies(id, $scope.tenancies).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
                $scope.$parent.obj = response.data.data;
                if(status == 12){

                    $scope.tenananciesRelated = response.data.data;

                    console.log("$scope.tenananciesRelated");
                }
                else{

                    $scope.$parent.obj2 = {};
                    $scope.$parent.obj = response.data;
                    $scope.tenantsRentalTransactionsData = response.data.tenantsRentalTransactionsData;
                    $scope.$parent.tenantsRentalTransactionsData = response.data.tenantsRentalTransactionsData;

                    $rootScope.Property_ID = $scope.obj.data.Property_ID;
                    $rootScope.property.Property_name = $scope.obj.data.Property_name;
                    $rootScope.property.Unit_number = $scope.obj.data.Unit_number;

                    console.log("wwwwwwwwwwwwwwwwwwwwwww >> " + $scope.tenantsRentalTransactionsData);
                    console.log($scope.tenantsRentalTransactionsData);
                }


                console.log(response);
                console.log("tenancies API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );


        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.actionUpdateRentalAccountBalance = function (action) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");

        if(action == 1) {
            console.log("ACTION: 1");

            /*
            if(!$scope.landlord.Property_ID){
                alert("Select a property");
                $scope.document_action = "";
                return "";
            }else{

            }
            $scope.landlordStatementView()
            */

        }
        else if (action == 2) {
            console.log("ACTION: 2");

            /*
            if(!$scope.landlord.Landlord_ID){
                alert("Select a Landlord");
                $scope.document_action = "";
                return "";
            }else{

            }
            $scope.landlordStatementLicenseView()
            */

        }
        else if (action == 3) {

            console.log("$scope.obj.data.Licence_Key: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  " + $scope.obj.data.Licence_Key);

            $rootScope.paymentTransactionInputs = {};
            $rootScope.paymentTransactionInputs.Status = '20';
            $rootScope.paymentTransactionInputs.Payment_Transaction_Fixed = 1;
            $rootScope.paymentTransactionInputs.Monthly_rental_price = $scope.obj.data.Monthly_rental_price;
            //$scope.pageno = 1;

            if($scope.tenancies.Tenancy_ID){
                //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{

                $scope.tenancies.Tenancy_ID = "*";
            }


            if($scope.search){

                $scope.tenancies.search = $scope.search;
            }else{

                $scope.tenancies.search = "*";
            }




            //apiService.tenancies($scope.obj.data.Tenancy_ID, $scope.tenancies);


            console.log($scope.tenancies);

            //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            $state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            var url = $state.href('lettings-main-nav.lettings-side.lettings-view-tenancy.notes', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            window.open(url,'_blank');

            console.log("View venancy agreement - room let");


        }
        else{

        }

    }

    $scope.Status = 71;
    $scope.tenanciesFunction('*', $scope.Status);
    /*
    $scope.getData = function () {



        apiService.getTenantRentBalancesAll($scope.itemsPerPage, $scope.pageno, $rootScope.decodedToken.Account_Holder_ID).then(

            function(response) {
                console.log("getTenantRentBalancesAll API");

                //$scope.paginationData = response.data.data;
                //$scope.tasks = response.data.data;
                $scope.$parent.obj = response.data;
                $scope.currentPage = $scope.pageno;
                //$scope.currentPage = pageno;
                //$scope.totalTaskCount = response.data.totalCount;

            },
            function() {
                console.log("API Service Error: getTenantRentBalancesAll")
            }
        );

    }
    */
    //$scope.getData();

}])