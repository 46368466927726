/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("tenancyViewCtrl", ["$scope", "$http", "$stateParams", "Upload", "$rootScope", "emailService", "postUserActivityService", "$filter", "apiService", "yesNoFilter", "$uibModal", "fCsv", "$timeout", "exportCSVService", "$state", "dateInputService", "$q", "openNewWindowService", function ($scope, $http, $stateParams, Upload, $rootScope, emailService, postUserActivityService, $filter, apiService, yesNoFilter, $uibModal, fCsv, $timeout, exportCSVService, $state, dateInputService, $q, openNewWindowService)  {

    //$scope.obj = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.search = "";
    $scope.progress = {};
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.payment_transaction = {};
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();
    $scope.property = [];
    $rootScope.property = {};
    $rootScope.property.Property_name = "";
    $rootScope.property.Unit_number = "";
    $rootScope.tenancy_id = $stateParams.id;
    $scope.tenananciesRelated = {};

    $scope.tenancies = [];
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.tenantsRentalTransactions = {};
    $scope.tenantsRentalTransactionsData = [];

    console.log('$stateParams.id: ' + $stateParams.id);

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.tenanciesFunction = function(id, status) {
        var q2 = $q.defer();

        console.log("function tenanciesFunction");
        $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
        $scope.tenancies.pageno = $scope.pageno;
        $scope.tenancies.Tenancy_ID = id;
        $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
        $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
        $scope.tenancies.Status = status;
        //$scope.pageno = 1;

        if($scope.tenancies.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }
        else{

            $scope.tenancies.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.tenancies.search = $scope.search;
        }
        else{

            $scope.tenancies.search = "*";
        }


        //console.log($scope.paymentTransactionsData);

        apiService.tenancies(id, $scope.tenancies).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                if(status == 12){

                    $scope.tenananciesRelated = response.data.data;

                    console.log("$scope.tenananciesRelated");
                }
                else{

                    $scope.$parent.obj2 = {};
                    $scope.$parent.obj = response.data;
                    $scope.tenantsRentalTransactionsData = response.data.tenantsRentalTransactionsData;
                    $scope.$parent.tenantsRentalTransactionsData = response.data.tenantsRentalTransactionsData;

                    $rootScope.Property_ID = $scope.obj.data.Property_ID;
                    $rootScope.property.Property_name = $scope.obj.data.Property_name;
                    $rootScope.property.Unit_number = $scope.obj.data.Unit_number;

                    console.log("wwwwwwwwwwwwwwwwwwwwwww >> " + $scope.tenantsRentalTransactionsData);
                    console.log($scope.tenantsRentalTransactionsData);
                }


                console.log(response);
                console.log("tenancies API");
                q2.resolve(response);
            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })

    //close all accordions
    $('.data').hide();
    //$scope.$parent.tenancy_id = $stateParams.id;

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.getTenancy = function () {

        var qgetTenancy62 = $q.defer();
        console.log('$stateParams.id: ' + $rootScope.tenancy_id);

        qgetTenancy62.resolve($scope.tenanciesFunction($rootScope.tenancy_id, 5));
        return qgetTenancy62.promise;
    }
    $scope.getRelatedTenancies = function () {

        var qgetRelatedTenancies = $q.defer();
        console.log('tenanciesFunction 12');

        $scope.tenancies.Tenant_ID = $scope.obj.data.Tenant_ID;
        qgetRelatedTenancies.resolve($scope.tenanciesFunction('*', 12));

        return qgetRelatedTenancies.promise;
    }
    $scope.tenancyAgreementView = function () {

        console.log("$stateParams.id: " + $stateParams.id);
        $state.go('tenancy-agreement', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});

    }

    $scope.getTenancies = function(pageno) {
        //$scope.getRentalTransactions = function(pageno) {
        console.log('Function getTenancies');
        $scope.itemsPerPage = '20';
        $scope.tenancies = [];
        var qgetTenancies64 = $q.defer();
        if ($scope.search)
        {
            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response){


                $scope.tenancies = response;
                $scope.$parent.tenancies = $scope.tenancies;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View Tenancy: ' +  $scope.tenancies.Property_name + ' - ' + $scope.tenancies.First_Name + ' - ' + $stateParams.id);
                console.log('Function getTenancies complete');
                qgetTenancies64.resolve(response);
            });

        }
        else
        {

            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response){

                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;

                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'View Tenancy: ' +  $scope.tenancies.Property_name + ' - ' + $scope.tenancies.First_Name + ' - ' + $stateParams.id);
                qgetTenancies64.resolve(response);
                $scope.sort('ID');
            });
                         }
        return qgetTenancies64.promise;
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }
    /*    /*
    $scope.uploadFile = function(file) {

        if ($scope.picFile) {
            Upload.upload({
                url: 'api/files',
                method: 'POST',
                file: file,
                data: {
                    'Account_Holder_ID': $rootScope.decodedToken.Account_Holder_ID,
                    'File_Type': 'note',
                    'ID': $scope.posted_note.note_id,
                    'File_Category': 4
                }
            }).then(function() {
                $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                    $scope.obj.notes = response.notes;
                    $scope.note_loading = false;
                })
            });
        } else {
            $http.get("api/notes/" + $scope.note.note_type + '/' + $rootScope.tenancy_id, $scope.note).success(function (response) {
                $scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })
        }

    }

    $scope.note = {};
    $scope.note.id = $stateParams.id;
    $scope.note_loading = false;


    $scope.postNote = function (note_content) {

        console.log("Am I in notes:" + note_content);

        var date = new Date();
        date.setHours(date.getHours() + 1);

        $scope.note_loading = true;
        $scope.note.user_id = $scope.decodedToken.id;
        $scope.note.note_type = "Tenancy";
        $scope.note.date = date;
        $scope.note.note = note_content;
        $scope.note.note_type_id = 1;

        var note_type = $scope.note.note_type;


        $http.post("api/notes", $scope.note)

            .success(function (response) {

                $scope.posted_note = response.posted;

                $scope.uploadFile($scope.picFile, note_type);
                $scope.note.note = "";


            })


    }
    */
    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.actionSelect = function(search_documents_action){

        if(search_documents_action == "1") {
            console.log("$scope.obj.data.Licence_Key: >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  " + $scope.obj.data.Licence_Key);

            $rootScope.paymentTransactionInputs = {};
            $rootScope.paymentTransactionInputs.Status = '20';
            $rootScope.paymentTransactionInputs.Payment_Transaction_Fixed = 1;
            $rootScope.paymentTransactionInputs.Monthly_rental_price = $scope.obj.data.Monthly_rental_price;
            //$scope.pageno = 1;

            if($scope.tenancies.Tenancy_ID){
                //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{

                $scope.tenancies.Tenancy_ID = "*";
            }


            if($scope.search){

                $scope.tenancies.search = $scope.search;
            }else{

                $scope.tenancies.search = "*";
            }




            //apiService.tenancies($scope.obj.data.Tenancy_ID, $scope.tenancies);


            console.log($scope.tenancies);

            //$state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            $state.go('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            var url = $state.href('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});
            window.open(url,'_blank');

            console.log("View venancy agreement - room let");
        }
        else if(search_documents_action == "2") {
            $state.go('tenancy-agreement-single-let', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            console.log("View venancy agreement - single let");
            var url = $state.href('tenancy-agreement-single-let', {id: $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            window.open(url,'_blank');


        }
        else if(search_documents_action == "4") {
            $state.go('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  $scope.obj.data.Tenant_ID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Edit tenant");

            var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenant', {id:  $scope.obj.data.Tenant_ID,accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            window.open(url,'_blank');

        }
        else if(search_documents_action == "3") {
            $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Edit tenancy");

            var url = $state.href('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            window.open(url,'_blank');

        }
        else if(search_documents_action == "5") {
            $scope.tenancyAgreementSend("1");
            //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $scope.obj.data.Tenancy_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
            console.log("Send tenancy");
        }
        else if(search_documents_action == "6") {
            $scope.tenancyInitialDocumentRequestSend();
            console.log("tenancyInitialDocumentRequestSend");
        }
        else {

        }
    };

    $scope.tenancyInitialDocumentRequestSend = function () {


        var confirmResponse = confirm("Send initial document request to tenant");
        console.log(confirmResponse);
        if(confirmResponse == false){


        }else{

            console.log("Promise tenancyInitialDocumentRequestSend");
            var qtenancyInitialDocumentRequestSend = $q.defer();
            $scope.url = "";
            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            //$scope.$parent.obj.DescriptionTruncated = $scope.obj.sampleText = truncateSliceService.truncateSliceService($scope.$parent.obj.Description, 25);

            var from_email = "Clouderty | Tenancy Document Request | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " <notifications@clouderty.co.uk>";
            var subject = "Sent by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " | " + "Tenancy ID: " + $scope.obj.data.Tenancy_ID;
            var message =
                "Dear " + $scope.obj.data.First_Name + ", " + "<br /><br />" +
                "Thank you for paying your non-refundable tenancy administration fee(s). You should now have received an electronic receipt for your payment." + "<br /><br />" +
                "Please send us back an electronic copy of one document from List A and two documents from List B. " + "<br /><br />" +
                "List A" + "<br /><br />" +
                "- Photographic ID – Passport or National ID card" + "<br />" +
                "- Driving licence if applicable" + "<br />" +
                "- Residence permit (if non-EU)" + "<br /><br />" +
                "List B " + "<br /><br />" +
                "- Last three months' worth of bank statements or salary slips" + "<br />" +
                "- Utility bill (gas, electricity, or water) - this cannot be a phone bill" + "<br />" +
                "Click on the link below to download the References and Personal Information form.  Print and complete the form then send it back to us by email." + "<br /><br />" +
                "http://platinumcrown.co.uk/files/PCT0004_References_and_Personal_Information.pdf " + "<br /><br />" +
                "To progress your tenancy, please send back all of the requested information to office@platinumcrown.co.uk within 5 working days. Once we have received these we'll be back in touch on what happens next." + "<br /><br />" +
                "Once we’ve verified your documentation, you will be sent a link to sign your automated tenancy agreement and pay your first month's rent." + "<br /><br />" +
                "Please find below your unique password that will enable you to do this. Please keep it safe." + "<br /><br />" +
                "Password: <strong>" + $scope.obj.data.Password + "</strong><br /><br />" +
                "On the day you move in, you will be required to show originals of all documents submitted. This is a legal requirement. If you do not bring them with you, you may not be able to move in." + "<br /><br />" +
                "Kind regards," + "<br />" +
                "Platinum Crown" + "<br /><br />" +
                "134 Hythe Hill" + "<br />" +
                "Colchester" + "<br />" +
                "Essex" + "<br />" +
                "CO1 2NF" + "<br />" +"<br />" +
                "Tel 01206 705 555" + "<br />" +
                "Fax 01206 705 556" + "<br />" +
                "www.platinumcrown.co.uk" + "<br />";

            //var to_email = "admin@platinumcrown.co.uk";
            var to_email = $rootScope.decodedToken.Notifications_Email;
            //var to_email = $rootScope.decodedToken.Accounts_Email;
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            //var to_email = $rootScope.decodedToken.Notifications_Email;


            //$rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);

            $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.tenancies.itemsPerPage = 20;
            $scope.tenancies.pageno = 1;
            $scope.tenancies.Tenancy_ID = $scope.obj.data.Tenancy_ID;
            $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
            $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
            $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
            $scope.tenancies.Licence_Key = 0;
            $scope.tenancies.Status = 39;
            $scope.tenancies.Payment_Transaction_Type_ID = "";
            $scope.tenancies.paymentTransactionInputs = "";

            $scope.tenanciesFunction($scope.obj.data.Tenancy_ID ,39);



            qtenancyInitialDocumentRequestSend.resolve($scope.promise);

            return qtenancyInitialDocumentRequestSend.promise;

        }

    }

    $scope.tenancyAgreementSend = function (status) {
        var confirmResponse = confirm("Send tenancy agreement to tenant");
        console.log(confirmResponse);
        if(confirmResponse == false){


        }else{



            //$scope.url = "";
            var url = $state.href('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});

            /*
            var url = $state.href('tenancy-agreement-single-let',
                {
                    tenancyID: $scope.obj.data.Tenancy_ID,
                    licenseKey: $scope.obj.data.Licence_Key,
                    myParam: $scope.tenancies
                });
                */
            //window.open($scope.url,'_blank');

            $scope.url2 = "https://management.clouderty.co.uk/" + url;

            console.log("$scope.url: " + $scope.url);
            console.log("$scope.url2: " + $scope.url2);

            console.log("Promise landlordStatementSend");
            var q73 = $q.defer();
            $scope.url = "";
            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            //$scope.$parent.obj.DescriptionTruncated = $scope.obj.sampleText = truncateSliceService.truncateSliceService($scope.$parent.obj.Description, 25);

            var from_email = "Clouderty | Tenancy Agreement | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " <notifications@clouderty.co.uk>";
            var subject = "Tenancy sent by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " | " + "Tenancy ID: " + $scope.obj.data.Tenancy_ID;

            /*
            var message = "Tenancy ID: " + $scope.obj.data.Tenancy_ID + "<br />" +
                "Tenancy Agreement: " +$scope.url2 + "<br /><br />" +
                "Created By: " + $rootScope.decodedToken.Name + "<br />";
            */

            var message =
                "Dear " + $scope.obj.data.First_Name + ", " + "<br /><br />" +
                "We are delighted to inform you that your details have now been processed and your automated tenancy is now ready for you to sign." + "<br /><br />" +
                "To sign your tenancy: " + "<br /><br />" +
                "1) Click on the link below." + "<br /><br />" +
                "2) Make payment for your first month's rent by scrolling to the bottom of the web-page and entering your bank details." + "<br /><br />" +
                "3) Once your payment is processed, if you are happy with the terms of the agreement, put ticks on all of the agreements clauses then enter your electronic signature and password." + "<br /><br />" +
                "4) Click on the submit button." + "<br /><br />" +
                "Tenancy Agreement: " + $scope.url2 + "<br /><br />" +
                "Once you have completed this process you will automatically be sent a receipt and an email with further information." + "<br /><br />" +
                "Kind regards," + "<br />" +
                "Platinum Crown" + "<br /><br />" +
                "134 Hythe Hill" + "<br />" +
                "Colchester" + "<br />" +
                "Essex" + "<br />" +
                "CO1 2NF" + "<br />" +"<br />" +
                "Tel 01206 705 555" + "<br />" +
                "Fax 01206 705 556" + "<br />" +
                "www.platinumcrown.co.uk" + "<br />";



            //var to_email = $rootScope.decodedToken.Accounts_Email;
            //$rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            var to_email = $rootScope.decodedToken.Notifications_Email;

            //"Note Content: " + $scope.posted_note.Description + "\n\n" +
            //"Note Content: " + $scope.posted_note.data.Description + "\n\n";
            //"Task name: " + $scope.posted_note.obj.Description;
            //$rootScope.sendEmail(from_email, to_email, subject, message);
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);

            $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.tenancies.itemsPerPage = 20;
            $scope.tenancies.pageno = 1;
            $scope.tenancies.Tenancy_ID = $scope.obj.data.Tenancy_ID;
            $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
            $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
            $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
            $scope.tenancies.Licence_Key = 0;
            $scope.tenancies.Status = 40;
            $scope.tenancies.Payment_Transaction_Type_ID = "";
            $scope.tenancies.paymentTransactionInputs = "";

            $scope.tenanciesFunction($scope.obj.data.Tenancy_ID ,$scope.tenancies.Status);

            q73.resolve($scope.promise);

            return q73.promise;


        }




    }

    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

    };

    var map;
    var geocoder;

    $scope.getTenancyPromise = function(){


        console.log("Some test 2: Add transaction");
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2");
                return $scope.getTenancy(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A5 getRelatedTenancies");
                return $scope.getRelatedTenancies();
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7');

                console.log(" A3");
                console.log("tenancy payment transaction - $scope.obj.data.Property_name: " + $scope.obj.data.Property_name);
                $scope.payment_transaction.Property_name = $scope.obj.data.Property_name;
                $rootScope.property.Property_name = $scope.obj.data.Property_name;
                $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                //$rootScope.tenancy_id = $stateParams.id;
                console.log("xxxxxxxxxxxxxx - $rootScope.Unit_number: " + $rootScope.property.Unit_number);
                console.log("xxxxxxxxxxxxxx - $rootScope.Property_name: " + $rootScope.property.Property_name);
                console.log("xxxxxxxxxxxxxx - $rootScope.tenancy_id: " + $rootScope.tenancy_id);

                $rootScope.watchNotes = 1;
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

        //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});

    };

    $scope.initMap = function() {

        geocoder = new google.maps.Geocoder();
        map = new google.maps.Map(document.getElementById('bndmap'), {
            zoom: 15,
            center: {lat: 51.8846631, lng: 0.922104},
            mapTypeId: 'terrain',
            scrollwheel: true,
        });


        //initialize();
        console.log($scope.obj.data.Postcode);
        var address = $scope.obj.data.Postcode;
        geocoder.geocode( { 'address': address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    title:"Location of user"
                });

            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });

    }

    $scope.ExportPaymentTransactions = function() {

        console.log('$scope.tenantsRentalTransactions in parent controller:' + $scope.tenantsRentalTransactions);
        console.log($rootScope.tenantsRentalTransactions.data);
        //console.log($scope.tenantsRentalTransactions);
        console.log('$scope.totalCount in parent controller:' + $scope.totalCount);

        $scope.getHeader =  ["Property Name", "First Name", "Surname", "Monthly Rent", "Amount Received", "Amount Esxpected", "Account Balance", "Current"];
        $scope.filename = "Payment Transactions - " + $scope.obj.data.Property_name + " - " + $scope.obj.data.Full_name;
        $scope.exportCSVData = exportCSVService.exportCSVService($rootScope.tenantsRentalTransactions.data);
    }

    $scope.sendEmailInternalPostNote = function(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature){

        message = message + '<br /><br />' + email_signature;

        if(to_email==null){
            alert("Enter an email address");
        } else {

            note_category_type = "Email";
            $scope.obj.select_note_type = "Email";
            //from_email = "notifications@clouderty.co.uk"

            if (unit_number == null) {


            } else {

                property = property + " - Unit" + unit_number
            }
            ;

            //$scope.a = emailService.openEmailSender(to_email, subject, message, property);
            // $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            console.log("sendEmailInternalPostNote Controller Function - 1: " + to_email);
            $scope.a = $rootScope.sendEmail('notifications@clouderty.co.uk',to_email,subject,message,$rootScope.decodedToken.Name, email_signature);
            //console.log("Email sent");
            $scope.b = sendEmailInternalPostNote.postNote(task_name, note_type, note, note_category_type, $scope, $http, $rootScope, to_email, subject, message, property, task_id, unit_number, email_signature);


            $state.reload();

        }


    };

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    if($stateParams.id) {
        $scope.getTenancyPromise();
    }

}])