
clouderty.controller("awardsNominationJudgingCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", "$window", "fileDirectoriesService", "notePostService", "dateInputService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService, $window, fileDirectoriesService, notePostService, dateInputService) {

    console.log("inside controller awardsNominationStage2AddEditCtrl");

    $('.data').hide();

    console.log("inside watch and $rootScope.watchPaymentAddEdit is: " + $rootScope.watchAwardsSurround);

    $rootScope.watchAwardsSurround = 1;


    $scope.$watch('watchAwardsSurround', function (toVariable, fromVariable) {

        console.log("inside watch and $rootScope.watchAwardsSurround is: " + $rootScope.watchAwardsSurround);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

        if(toVariable == 1){

            console.log("inside watch and obj is: " + $rootScope.watchAwardsSurround);
            console.log("inside watch and fromVariable is: " + fromVariable);
            console.log("inside watch and toVariable is: " + toVariable);

            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20;
            $scope.search = "";
            $scope.progress = {};
            $scope.DateToday = new Date();
            $scope.date = new Date();

            $scope.awardNominations = {};
            $scope.awardCategories = {};
            $scope.awardCategorySelect = {};
            $scope.awardNominationSelect = {};
            $scope.awardsNominationsSelectShow = 0;
            $scope.awardsQuestionsShow = 0;
            $scope.awards = {};
            $scope.awardNominations = {};
            $scope.awards.awardCategories = {};
            $scope.awards.awardNominationRequest = {};
            $scope.awards.awardNominator = {};
            $scope.awardsData = {};
            $scope.passwordInput = "";
            $scope.awardsApplicationShow = 0;
            $scope.countriesData = {};
            $scope.countries = {};

            $scope.nomineesDetailsBloc = 1;
            $scope.nomineesDetailsVisibleBloc = 1;
            $scope.nominationSubmittedBlock = 0;
            $scope.paymentTransactionsData = {};
            $scope.payment_transaction = {};
            $scope.payment_transaction.cardDetails = {};
            $scope.payment_transaction.adminPayment = 1;
            $rootScope.payment_transaction = {};
            $rootScope.payment_transaction.Payment_Transaction_Card_Completed = 0;

            $scope.directoryData = {};
            $scope.directoryDataRetrieved = {};
            $scope.directoryData.directoryInformation = {};
            $scope.directoryData.directoryInformation.Path2Files = {};
            $scope.directoryData.directoryInformation.Path2FilesSanitised = [];
            $scope.fileDeleteData = {};
            $scope.stateCurrent = "";

            $scope.noteGet = {};
            $scope.note_loading = false;
            $scope.posted_note = {};

            $(document).ready(function () {
                $('.nav-tabs li').on("click", function() {
                    console.log('is this it 1');
                    $('.nav-tabs li').removeClass("active");
                    $(this).addClass("active");
                })
            })
            $('.data').hide();


            //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )

                return q55.promise;

            }

            $scope.awardsFunction = function(id, status) {

                console.log("status >>>> " + status);
                var qawardsFunction = $q.defer();

                console.log("function processesAccountFunction");
                console.log("$scope.pageno" + $scope.pageno);

                $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.awardsData.id = $rootScope.decodedToken.id;
                $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
                $scope.awardsData.pageno = $scope.pageno;
                $scope.awardsData.Status = status;
                $scope.awardsData.Process_ID = id;


                if($scope.search){

                    $scope.awardsData.search = $scope.search;

                }
                else{

                    $scope.awardsData.search = "*";

                }

                console.log($scope.awardsData);
                apiService.awards(id, $scope.awardsData).then(
                    function (response) {
                        console.log("apiService awards service");

                        //$scope.awardsData.Awards_Category_Question_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                        $scope.awards.awardCategories = {};
                        if(status == 60){
                            console.log(response);
                            console.log("apiService awards service successful status 60");
                            $scope.awardCategories = response.data.awardsCategories;
                        }
                        else if(status == 12){
                            console.log(response);
                            console.log("apiService awards service successful status 12");
                            $scope.awards.awardsNominationValidation = response.data.data;
                        }
                        else if(status == 70){
                            console.log(response);

                            console.log("apiService awards service successful status 70");
                            $scope.awards.awardsNominations = response.data.awardsNominations;
                            $scope.awardsNominations = response.data.awardsNominations;

                        }//view nominations
                        else if(status == 704){
                            console.log(response);

                            console.log("apiService awards service successful status 70");
                            $scope.awards.awardsNominations = response.data.awardsNominations;
                            $scope.awardsNominations = response.data.awardsNominations;
                            $scope.awardsNominationsSelectShow = 1;
                        }//view nominations by category
                        else if(status == 71){
                            console.log(response);
                            console.log("apiService awards service successful status 71");
                            $scope.awards.awardsNomination = response.data.awardsNomination;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                            $scope.awardsQuestionsShow = 1;
                        }
                        else if(status == 64){

                            console.log(response);
                            console.log("apiService awards service successful status 64");
                            $scope.awards.awardNominationRequest.First_Name = "";
                            $scope.awards.awardNominationRequest.Last_Name = "";
                            $scope.awards.awardNominationRequest.Email = "";
                            $scope.awardNominations = response.data.data;
                            $scope.nominatorsDetailsBloc = 1;
                            $scope.nomineesDetailsVisibleBloc = 0;

                        }
                        else if(status == 641){
                            console.log(response);
                            console.log("apiService awards service successful status 641");
                            $scope.awards.awardNominator.First_Name = "";
                            $scope.awards.awardNominator.Last_Name = "";
                            $scope.awards.awardNominator.Email = "";
                            $scope.awardNominator = response.data.data;
                            $scope.nominationSubmittedBlock = 1;

                        }
                        else if(status == 80){//update awards question answer
                            console.log(response);
                            console.log("apiService awards service successful status 70");
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }//update awards question answer
                        else if(status == 81){//update property details question completed
                            console.log("apiService awards service successful status 81");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                        }//update property details question completed
                        else if(status == 82){//update awards question completed
                            console.log("apiService awards service successful status 82");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards question completed
                        else if(status == 83){//update property details question answer
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update property details question answer
                        else if(status == 84){//update awards files displayed
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards files displayed

                        console.log("awards API successful");

                        console.log(response);

                        qawardsFunction.resolve(response);


                    },
                    function () {

                        console.log("API Service Error: awards");

                    }

                );

                return qawardsFunction.promise;

            }

            $scope.paymentTransactionsFunction = function (id, status) {
                console.log("function paymentTransactionsFunction");

                //$scope.paymentTransactionsData = $scope.payment_transaction;
                $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.paymentTransactionsData.Property_ID = 0;

                console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);

                var q2 = $q.defer();
                console.log($scope.paymentTransactionsData);

                apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                    function (response) {
                        $scope.paymentTransactions = response.data;


                        $scope.payment_transaction.adminPayment = 0;

                        console.log($scope.paymentTransactions);
                        console.log("API Service: paymentTransactions");
                        console.log(response);
                        console.log("response.Payment_Transaction_ID: " + response.Payment_Transaction_ID);
                        console.log("response.data.Payment_Transaction_ID: " + response.data.Payment_Transaction_ID);
                        //console.log("response.Payment_Transaction_Card_Completed: >>>>>>>>>>> " + response.Payment_Transaction_Card_Completed);
                        console.log($scope.paymentTransactions);
                        $scope.payment_transaction.Payment_Transaction_ID = $scope.paymentTransactions.Payment_Transaction_ID;
                        console.log("$scope.payment_transaction.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        $scope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $rootScope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $scope.payment_transaction.Failed_Response_Message = $scope.paymentTransactions.Failed_Response_Message;
                        $scope.payment_transaction.First_Payment = $scope.paymentTransactions.First_Payment;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Amount = $scope.paymentTransactions.Payment_Transactions_Recurring_Amount;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Order_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_Order_ID;
                        $scope.payment_transaction.Payment_Transactions_Recurring_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_ID;

                        if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){
                            console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.paymentTransactions.Payment_Transactions_Recurring_Completed);

                            $rootScope.payment_transaction.Payment_Transactions_Recurring_Completed = $scope.paymentTransactions.Payment_Transactions_Recurring_Completed;

                        }

                        if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1'){
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " SUCCESSFUL";
                            $rootScope.paymentResultText = $scope.paymentResultText;

                            $scope.payment_transaction.paymentDiv = '0';
                            //check if this is a tenancy signup rent payment

                            console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv $state.current.name: " + $state.current.name);
                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                console.log("check if this is a tenancy signup rent payment");



                                //var el = document.getElementById("agreeToClauses");
                                //el.scrollIntoView();
                                if (!$rootScope.Rent_Payment_ID_First) {
                                    //check if a first rent payment exists if not add payment id to tenancy
                                    console.log("check if a first rent payment exists if not add payment id to tenancy");
                                    $rootScope.Rent_Payment_ID_First = $scope.paymentTransactions.Payment_Transaction_ID;
                                    //$scope.tenanciesFunction($stateParams.tenancyID, '25');

                                }

                            }


                        }else{
                            //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            console.log("API Service: paymentTransactions");
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " FAILED - " + $scope.payment_transaction.Failed_Response_Message;
                            $rootScope.paymentResultText = $scope.paymentResultText;
                        }

                        console.log("$scope.paymentTransactions.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.payment_transaction.Payment_Transactions_Recurring_Completed);
                        console.log("$scope.paymentTransactions.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                        q2.resolve(response);
                    },
                    function () {
                        console.log("There was an error in paymentTransactions");
                    }
                )
                //});
                return q2.promise;
            }

            $scope.getNominations = function () {

                var qgetNominations = $q.defer();

                qgetNominations.resolve($scope.awardsFunction('*', 70));

                return qgetNominations.promise;

            }

            $scope.getNominationsCategory = function () {

                var qgetNominations = $q.defer();

                qgetNominations.resolve($scope.awardsFunction('*', 704));

                return qgetNominations.promise;

            }

            $scope.getCategories = function () {

                var qgetCategories = $q.defer();

                qgetCategories.resolve($scope.awardsFunction('*', 60));

                return qgetCategories.promise;

            }

            $scope.openFile = function (dirData) {

                console.log("inside function openFile");






                console.log(dirData);
                console.log(dirData.path);
                console.log($scope.directoryData.directoryInformation.http);
                console.log($scope.directoryData.directoryInformation.pageURL1);
                console.log($scope.directoryData.directoryInformation.pageURL3);

                var path = dirData.path;

                path = path.replace("..", "");

                console.log(path);

                var filePath = $scope.directoryData.directoryInformation.http + $scope.directoryData.directoryInformation.pageURL1 + '/' + $scope.directoryData.directoryInformation.pageURL3 + path;

                //filePath = "http://localhost/clouderty_do/uploads/nominations/1/Landlord%20Statement%20Transactions%20(11)%20-%20Copy.csv"

                console.log(filePath);
                window.open(filePath,'_blank');

            }

            $scope.getNomination = function (awardsNominationID) {
                console.log("inside function getNomination");
                var qgetNomination = $q.defer();
                $scope.awards = {};

                $scope.awardsQuestionsShow = 1;
                console.log("awardsNominationID: " + awardsNominationID);
                //$scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                $scope.awardsData.Awards_Nomination_ID = awardsNominationID;
                qgetNomination.resolve($scope.awardsFunction($scope.awardsData.Awards_Nomination_ID, 71));
                $scope.getDirItems();
                return qgetNomination.promise;

            }

            $scope.addNominator = function () {

                console.log("inside function addNominator");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nominator?")){
                    $scope.awardsData.First_Name = $scope.awards.awardNominator.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominator.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominator.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominator.Contact_number;

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 641));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nominator Added " + '18' + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Adam' + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + "18" + "<br /><br />" +
                                "Awards Category: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Name: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Contact Number: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Email: " + $scope.date + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.getCategory = function (awardCategory) {

                console.log("awardCategory.Category: " + awardCategory.Category);
                console.log("awardCategory.Awards_Category_ID: " + awardCategory.Awards_Category_ID);


                console.log("awardCategory: " + awardCategory);
                console.log(awardCategory);
                $scope.awardsData.Category = awardCategory.Category;
                $scope.awardsData.Awards_Category_ID = awardCategory;

                $scope.getNominationsCategory();
            }

            $scope.loginJudge = function (passwordInput) {

                console.log("inside function passwordInput");
                //console.log("passwordInput: " + passwordInput);
                //console.log("$scope.passwordInput: " + $scope.passwordInput);
                if(passwordInput == 'judging2018'){
                    console.log("inside if passwordInput awardsApplicationShow");
                    $scope.awardsApplicationShow = 1;

                }

            }

            $scope.getDirItems = function (pageno) {

                console.log("in function dirTests");

                $scope.paymentBlockSpinner = 1;

                var q = $q.defer();

                $scope.myValue = 0;
                //$scope.promise = loginExternalWebPageService.init("1");
                $scope.pageno = pageno;

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");
                        //$rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - apiService.fileDirectories");
                        $scope.directoryData = [];
                        console.log("$scope.pageno: " + $scope.pageno);

                        $scope.directoryData.Path1 = "nominations";
                        $scope.directoryData.Path2 = $scope.awardsData.Awards_Nomination_ID;
                        $scope.directoryData.Status = 51;
                        $scope.directoryData.id = 0;
                        $scope.directoryData.pageno = $scope.pageno;


                        fileDirectoriesService.getFiles($scope.directoryData).then(
                            function (response) {
                                console.log("successful api call fileDirectoriesService.getFiles");
                                console.log(response);
                                $scope.directoryData.directoryInformation = response;
                                $scope.totalCount = response.Path2FilesSanitisedCount;
                                $scope.currentPage = $scope.pageno;
                                console.log(response.Path2FilesSanitisedCount);

                            },
                            function () {

                                console.log("API Service Error: awards");

                            }
                        );

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7 $scope.directoryData.directoryInformation');

                        if($scope.directoryData.directoryInformation){

                            $scope.directoryData.directoryInformation.Path2FilesSanitised = [];

                            $scope.directoryData.directoryInformation.Path2FilesSanitised = $scope.directoryData.directoryInformation.Path2FilesSanitised;
                            console.log($scope.directoryData.directoryInformation);
                            console.log($scope.directoryData.directoryInformation.Path2Files);

                        }
                        else {


                            $scope.directoryData = {};
                            $scope.directoryData.directoryInformation = {};
                            $scope.directoryData.directoryInformation.Path2FilesSanitised = [];

                            $scope.directoryData.directoryInformation.Path2FilesSanitised = $scope.directoryData.directoryInformation.Path2FilesSanitised;
                            console.log($scope.directoryData.directoryInformation);
                            console.log($scope.directoryData.directoryInformation.Path2Files);

                        }

                        $scope.myValue = data;

                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

            }

            $scope.loadAwardsData = function () {
            console.log("inside function loadAwardsData");


            $('.data').hide();
            var qloadAwardsData = $q.defer();
            $scope.promise = $scope.getCategories();

            $scope.promise
                .then(function (data) {


                    console.log("A2 - getGlobalPromise");
                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {
                    console.log("A1 - getNominations");



                    return $scope.getNominations();
                })
                /*.then(function (data) {

                    if(!$scope.awards.awardsNominationValidation){
                        console.log("A1 - awardsNominationValidation is not valid");
                        alert('Your nomination link is invalid. You will be redirected to the nominations page.')
                        $window.open("https://propertyinvestorsawards.co.uk/nominate/", "_self");
                        return  $q.reject('awardsNominationValidation is not valid');
                    }
                    console.log("A2 - getNomination");


                    return $scope.getNomination(data);
                })
                .then(function (data) {

                    console.log("A2 - getCategories");

                    return $scope.getCategories(data);
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A3");
                    //$scope.getCountries();
                    //$scope.getDirItems();
                    return $scope.getDirItems(data);
                    //return $scope.getGlobalPromise(data);
                })*/
                .then(function (data) {
                    console.log('A7a');
                    console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                    $('.data').hide();
                }, function (err) {


                    $scope.myValue = err;
                })

            qloadAwardsData.resolve($scope.promise);

        }

            ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

            $scope.loadAwardsData();

            $('.data').hide();

            //////////////////////////  Old Functions  /////////////////////////////////////////////////////////////////////////////////

        }

    })

}])