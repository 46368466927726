/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("tenantViewingsSearchCtrl", ["$scope", "$http", "$rootScope", "$q",  "$state", "postUserActivityService", "apiService", "$uibModal", "$stateParams", "$state", "apiService", "dateInputService", "moment",  function ($scope, $http, $rootScope, $q, $state, postUserActivityService, apiService, $uibModal, $stateParams, $state, apiService, dateInputService, moment) {

    console.log("Clouderty administrator:" + $rootScope.decodedToken.Clouderty_System_Administrator);
    console.log("Rootscope user level: " + $rootScope.decodedToken.User_Level);
    console.log("Rootscope account holder ID: " + $rootScope.decodedToken.Account_Holder_ID);
    $scope.tenancies = [];
    $scope.tenantViewings = {};
    $scope.tenantViewings.tenantViewingsStatus = {};
    $scope.obj = {};
    $scope.tenancy = [];
    $scope.tenanciesExport = [];
    $scope.tenanciesExportHeaders = [];
    $scope.tenanciesExportHeaders = JSON.stringify($scope.csvHeader);
    $scope.tenanciesExport2 = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.tenancyID = "current";
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.filename = "test" + ".csv";
    $scope.compareToDate = '0';

    $scope.getArray5 = [{a: 1, b:2}];
    //$scope.getArray4 = '[' +  '{a: 1, b:2}' + ']';
    $scope.getArray3 = function () { return [{a: 1, b:2}];};
    $scope.getArray6 = function () { return $scope.tenanciesExport;};
    //$scope.getArray7 = $scope.tenanciesExport();
    $scope.getArray2 = function () { $scope.getArray2 = [{a: 1, b:2}, {a:3, b:4}]; };


    /////////////////// Cancel previous AJAX code (A)
    var canceller, isSending = false;
    ///////////////////  Cancel previous AJAX code (A) end



    $scope.tenanciesExport = function () {
        var deferred = $q.defer();
        $scope.itemsPerPage = 10000;
        deferred.resolve($scope.tenancies);
        console.log($scope.tenancies);
        return deferred.promise;

    }


    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }


    $scope.estatesViewAll = function () {

        apiService.getEstates($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.estates = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    }

    $scope.getData = function(pageno) {
        $scope.tenantViewings = [];
        var q = $q.defer();

        if($scope.search){
            console.log("trying search")

        }
        else if(!$scope.search){
            $scope.search = "*";
            console.log("search null")
        }
        $scope.tenantViewings = null;



        if($stateParams.compareToDate == '1'){


            $scope.compareToDate = $stateParams.compareToDate;

        }else if($stateParams.compareToDate == '2'){


            $scope.compareToDate = $stateParams.compareToDate;

        }else{


        }
        if ($rootScope.decodedToken.User_Type_ID == 1) {

            apiService.getAllTenantViewings($scope.itemsPerPage, pageno, $rootScope.decodedToken.id, $rootScope.decodedToken.User_Type_ID, $scope.compareToDate, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                function (response) {
                    console.log('apiService getTenantViewing');


                    console.log("gettenantViewings API");
                    $scope.tenantViewings = response.data.data;
                    $scope.currentPage = pageno;
                    $scope.totalCount = response.data.totalCount;
                    //$scope.totalCount = response.data.totalCount;
                    $scope.rentTotal = response.data.rentTotal;

                    q.resolve(response);

                },
                function () {
                    console.log("API Service: getAllTenantViewings")
                }
            );

        }else if ($rootScope.decodedToken.User_Type_ID == 2) {

            apiService.getAllTenantViewings($scope.itemsPerPage, pageno, $rootScope.decodedToken.id, $rootScope.decodedToken.User_Type_ID, $scope.compareToDate, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                function (response) {
                    console.log('apiService getTenantViewing');


                    console.log("gettenantViewings API");
                    $scope.tenantViewings = response.data.data;
                    $scope.currentPage = pageno;
                    $scope.totalCount = response.data.totalCount;
                    //$scope.totalCount = response.data.totalCount;
                    $scope.rentTotal = response.data.rentTotal;

                    q.resolve(response);

                },
                function () {
                    console.log("API Service: getAllTenantViewings")
                }
            );
        }

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        }

        return q.promise;
        //}
    }

    $scope.getDataProperties = function(pageno) {
        $scope.tenancies = [];
        $scope.landlord.Property_ID = $scope.landlord.Property;
        $scope.getData(1);

    }

    $scope.getAllTenanciesCurrentEstate = function(pageno) {
        $scope.tenancies = [];

        $scope.landlord.Estate_ID = $scope.landlord.Estate;
        $scope.getData(1);


    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }



    $scope.actionUpdate = function (progress) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");

        if(progress == "0") {
            //$scope.progress.date_completed = date;
            console.log("All VIEWINGS");
            $scope.tenantViewings = [];
            $scope.compareToDate = progress;
            $scope.getData(1);
            $scope.loadDataThenMap();
        } else if (progress == "1") {
            console.log("TODAY'S VIEWINGS");
            $scope.tenantViewings = [];
            $scope.compareToDate = progress;
            $scope.getData(1);
            $scope.loadDataThenMap();
        } else if (progress == "2") {
            console.log("TOMORROW'S VIEWINGS");
            $scope.compareToDate = progress;
            $scope.tenantViewings = [];
            $scope.getData(1);
            $scope.loadDataThenMap();
        } else if (progress == "EXPIRED TENANCIES WITHIN 3 MONTH") {

        } else{

        }
 
    }


    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };



    $scope.updateTenantViewingStatus = function(Tenant_Viewing_Status_ID, Tenant_Viewing_ID) {
        console.log("Tenant_Viewing_Status_ID: " + Tenant_Viewing_Status_ID);
        console.log("Tenant_Viewing_ID: " + Tenant_Viewing_ID);

        // $scope.obj.Date_Added = dateInputService.dateInputDayService($scope.obj.Date_Added, 0);
        $scope.tenantViewings.Viewing_Date = dateInputService.dateInputDayService($scope.tenantViewings.Viewing_Date, 0);
        $scope.tenantViewings.Viewing_Time = dateInputService.timeInputService($scope.tenantViewings.Viewing_Time, 60);
        console.log('apiService updateTenantViewing - b');
        apiService.updateTenantViewing(Tenant_Viewing_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.tenantViewings).then(
            function(response) {
                console.log('apiService updateTenantViewing - bb');
                apiService.getTenantViewing(Tenant_Viewing_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                    function (response) {
                        console.log('apiService getTenantViewing');

                        $scope.a = [];
                        $scope.a = response.data.data;
                        //$scope.obj.Date_Added = dateInputService.dateInputDayService($scope.obj.Date_Added, 0);
                        $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.a.Viewing_Date, 0);
                        $scope.obj.Viewing_Time = dateInputService.timeInputService($scope.a.Viewing_Time, 60);


                        console.log('Tenant_Viewing_ID a:' + $scope.a.Tenant_Viewing_ID);
                        console.log('Tenant_Viewing_ID obj:' + $scope.obj.Tenant_Viewing_ID);
                        var from_email = "Clouderty | Tenant Viewing Updated | " + $scope.obj.Property_name + " |" + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = $scope.obj.Property_name + " | " + $scope.obj.Full_Name  + " | " + $scope.obj.Unit_number + " | Updated by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + $scope.obj.Tenancy_ID;
                        var message = "Tenant name: " + $scope.obj.Full_Name + "\n\n" +
                            "Address: " + $scope.obj.Property_name + "\n\n" +

                            "Unit number: " + $scope.obj.Unit_number + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Viewing Date: " + $scope.obj.Viewing_Date + "\n\n" +
                            "Viewing Time: " + $scope.obj.Viewing_Time + "\n\n" +
                            "Tenant Viewing Status: " + $scope.obj.Tenant_Viewing_Status_ID + "\n\n" +
                            "Tenant Viewing ID: " + $scope.obj.Tenant_Viewing_ID + "\n\n" +
                            "Notes: " + $scope.obj.Notes + "\n\n" +
                            "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenant-viewing', {id: $scope.obj.Tenant_Viewing_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                        $state.go($state.current, {}, {reload: true});

                        //$state.reload('lettings-main-nav.lettings-side.lettings-tenant-viewings-search');
                    },
                    function() {
                        console.log("There was an error API getTenantViewing")
                    }
                )
            },
            function() {
                console.log("There was an error")
            }
        )



    }

    $scope.getTenantViewingStatus = function() {

        apiService.getTenantViewingStatus().then(
            function(response) {

                $scope.tenantViewingsStatus = response.data.data;
                //$scope.tenantViewings.tenantViewingsStatus = response.data.data;
                console.log("API getTenantViewingStatus: " + response.data.Status);

            },
            function() {
                console.log("Where in");
                console.log("There was an error in API getTenantViewingStatus")
            }
        )

    }


    $scope.updateTenantViewingStatus2 = function(tenancyID, current) {



        apiService.updateTenantViewingStatus($scope.tenancy.Tenant_Viewing_ID, $scope.tenancy.Tenant_Viewing_Status_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {


                if (confirm('Are you sure you want to change the status of this tenancy to not current?')) {


                    console.log("Tenancy ID: " + tenancyID);
                    $scope.Tenant_Name = "";
                    if ($scope.tenancyCurrentStatus.Last_name == null) {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name + " " + $scope.tenancyCurrentStatus.Last_Name;
                    } else {
                        $scope.Tenant_Name = $scope.tenancyCurrentStatus.First_Name;
                    }
                    console.log($scope.Tenant_Name);

                    if ($scope.tenancyCurrentStatus.Current == 1) {

                        $scope.currentStatus = "Current";

                    } else if ($scope.tenancyCurrentStatus.Current == -1) {

                        $scope.currentStatus = "Not Current";

                    } else if ($scope.tenancyCurrentStatus.Current == 0) {

                        $scope.currentStatus = "Not Current";

                    }
                    $scope.tenantViewingStatus = {};
                    $scope.tenantViewingStatus = response.data.data2[0];

                    var from_email = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus + " |" + " <notifications@clouderty.co.uk>";
                    var subject = "Clouderty | Tenancy Status Change | " + $scope.Tenant_Name + " | " + $scope.currentStatus + " | Changed by: " + $rootScope.decodedToken.Name + "| Tenancy ID: " + tenancyID;
                    var message = "Tenancy ID: " + tenancyID + "\n" +
                        "Changed by: " + $rootScope.decodedToken.Name + "\n" +
                        "Tenant's name: " + $scope.Tenant_Name + "\n" +
                        "Property: " + $scope.tenancyCurrentStatus.Property_name + "\n" +
                        "Unit: " + $scope.tenancyCurrentStatus.Unit_number + "\n\n" +
                        "Date changed: " + Date() + "\n\n" +
                        "Current tenancy status: " + $scope.currentStatus + "\n";

                    var to_email = $rootScope.decodedToken.Notifications_Email;
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = $scope.tenancyCurrentStatus.Property_name + " Unit " + $scope.tenancyCurrentStatus.Unit_number + " - " + $scope.Tenant_Name + " - Tenancy ID:" + tenancyID;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy Status Change - Not Current - ' + $scope.postUserActivityServiceMessage);

                }


                console.log("Route reload ");
                $state.reload("lettings-main-nav.lettings-side.lettings-search-tenancy");

            },
            function() {
                console.log("API Service: updateTenantViewingStatus")
            }
        );

        $scope.currentStatus = 0;
        console.log("Current  current: " + current);

    }


    $scope.getTenantViewingStatus();
    $scope.estatesViewAll();
    $scope.propertiesViewAll();
    $scope.getData($scope.pageno);

    //////////// Google map code ////////////

    $scope.loadDataThenMap = function() {

        console.log("C1 loadDataThenMap");
        var q = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getData($scope.pageno);

        $scope.promise
            .then(function (data) {

                console.log("A2 loadDataThenMap");
                $scope.showMapMultipleMarkersDetails();
                return "";
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);
    }

    $scope.showMapMultipleMarkersDetails = function () {

        $scope.markers = [];

        geocoder = new google.maps.Geocoder();
        geocoderCentre = new google.maps.Geocoder();

        $scope.tenantViewings.forEach(function (item) {
            geocoder.geocode({
                'address': item['Postcode']
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    $scope.lat = results[0].geometry.location.lat();
                    $scope.long = results[0].geometry.location.lng();
                }
                var i = 0;
                console.log("array: " + i)
                $scope.markers.push({
                    id: Date.now() + i,
                    coords: {
                        latitude: $scope.lat,
                        longitude: $scope.long

                    },
                    "show": false,
                    closeClick: 'none',
                    "sessions": item['area']
                });
                $scope.$evalAsync();
                console.log("array: " + i)
                i++
            });

        });
        console.log("resultsCentre3: " + $scope.postcodeCentre);
        $scope.widget = {
            "map": {
                "center": {
                    "latitude": 51.8846964,
                    "longitude": 0.9216240000000653
                    //"position": $scope.postcodeCentre

                },
                "zoom": 12,
                "options": {
                    "minZoom": 3,
                    "scrollwheel": true
                }
            }
        }

    }

    $scope.loadDataThenMap();

    //////////// Google map code end ////////////

}]);