/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("awardsNominationsManageCtrl", ["$scope", "$rootScope",  "$http", "$stateParams", "$state", "apiService", "fCsv",  "exportCSVService", "dateInputService", "$filter", "$interval", "$q", "openNewWindowService", "$route", function ($scope, $rootScope, $http, $stateParams, $state, apiService, fCsv, exportCSVService, dateInputService, $filter, $interval, $q, openNewWindowService, $route) {

    console.log("inside controller awardsNominationsManageCtrl");

    $('.data').hide();

    $scope.pageno = 1;
    $scope.totalCount = 20;
    $scope.itemsPerPage = 20;
    $scope.search = "";
    $scope.progress = {};
    $scope.DateToday = new Date();
    $scope.date = new Date();

    $scope.awardNominations = {};
    $scope.awardCategories = {};
    $scope.awards = {};
    $scope.awards.awardCategories = {};
    $scope.awards.awardNominationRequest = {};
    $scope.awards.awardsNominationRequest = {};
    $scope.awards.awardNominator = {};
    //$scope.awards.awardCategoriesSelect = {};
    $scope.awardsData = {};
    $scope.countriesData = {};
    $scope.countries = {};

    $scope.nomineesDetailsBloc = 1;
    $scope.nomineesDetailsVisibleBloc = 1;
    $scope.nominationSubmittedBlock = 0;
    $scope.paymentTransactionsData = {};
    $scope.payment_transaction = {};
    $scope.payment_transaction.cardDetails = {};

    $scope.directoryData = {};
    $scope.directoryDataRetrieved = {};
    $scope.directoryData.directoryInformation = {};
    $scope.directoryData.directoryInformation.Path2Files = {};
    $scope.fileDeleteData = {};
    $scope.stateCurrent = "";

    $scope.noteGet = {};
    $scope.note_loading = false;
    $scope.posted_note = {};

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )

        return q55.promise;

    }

    $scope.awardsFunction = function(id, status) {

        console.log("status >>>> " + status);
        var qawardsFunction = $q.defer();

        console.log("function processesAccountFunction");
        console.log("$scope.pageno" + $scope.pageno);

        $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.awardsData.id = $rootScope.decodedToken.id;
        $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
        $scope.awardsData.pageno = $scope.pageno;
        $scope.awardsData.Status = status;
        $scope.awardsData.Process_ID = id;


        if($scope.search){

            $scope.awardsData.search = $scope.search;

        }
        else{

            $scope.awardsData.search = "*";

        }

        console.log($scope.awardsData);

        apiService.awards(id, $scope.awardsData).then(
            function (response) {
                console.log("apiService awards service");

                //$scope.awardsData.Awards_Category_Questions_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                if(status == 60){
                    console.log(response);
                    console.log("apiService awards service successful status 60");
                    $scope.awardCategories = response.data.awardsCategories;

                }
                else if(status == 12){
                    console.log(response);
                    console.log("apiService awards service successful status 12");
                    $scope.awards.awardsNominationValidation = response.data.data;
                }
                else if(status == 70 || status == 701 || status == 702 || status == 703){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNominations = response.data.awardsNominations;
                    $scope.awards.awardsNominationsCount = response.data.awardsNominationsCount;
                    $scope.totalCount = response.data.awardsNominationsCount;
                    /*
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                    */
                }
                else if(status == 71){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNomination = response.data.awardsNomination;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }
                else if(status == 72){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNominationRequest = response.data.awardsNominationRequest;
                    /*
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                    */
                }
                else if(status == 64){

                    console.log(response);
                    console.log("apiService awards service successful status 64");
                    $scope.awards.awardNominationRequest.First_Name = "";
                    $scope.awards.awardNominationRequest.Last_Name = "";
                    $scope.awards.awardNominationRequest.Email = "";
                    $scope.awardNominations = response.data.data;
                    $scope.nominatorsDetailsBloc = 1;
                    $scope.nomineesDetailsVisibleBloc = 0;

                }
                else if(status == 641){
                    console.log(response);
                    console.log("apiService awards service successful status 641");
                    $scope.awards.awardNominator.First_Name = "";
                    $scope.awards.awardNominator.Last_Name = "";
                    $scope.awards.awardNominator.Email = "";
                    $scope.awardNominator = response.data.data;
                    $scope.nominationSubmittedBlock = 1;

                }
                else if(status == 80){//update awards question answer
                    console.log(response);
                    console.log("apiService awards service successful status 70");
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }//update awards question answer
                else if(status == 81){//update property details question completed
                    console.log("apiService awards service successful status 81");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                }//update property details question completed
                else if(status == 82){//update awards question completed
                    console.log("apiService awards service successful status 82");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards question completed
                else if(status == 83){//update property details question answer
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update property details question answer
                else if(status == 84){//update awards files displayed
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards files displayed
                console.log("awards API successful");

                console.log(response);

                qawardsFunction.resolve(response);


            },
            function () {

                console.log("API Service Error: awards");

            }

        );

        return qawardsFunction.promise;

    }

    $scope.getData = function(pageno) {
        console.log("inside function getData");

        var qgetData = $q.defer();
        $scope.awards.awardsNominations = [];

        canceller = $q.defer();

        /*
        if ($scope.search) {
            console.log("trying search")
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {
            $scope.search = "*";
            console.log("search null")
        }

        */
        $scope.currentPage = pageno;
        $scope.pageno = pageno;

        qgetData.resolve($scope.awardsFunction('*', $scope.status));

        return qgetData.promise;

    }

    $scope.getNominations = function () {
        console.log("inside function getNominationRequests");
        var qgetNominations = $q.defer();

        $scope.awardsData.Awards_Nomination_ID = '*';
        qgetNominations.resolve($scope.awardsFunction("*", 702));

        return qgetNominations.promise;

    }

    $scope.getCategories = function () {

        var qgetCategories = $q.defer();

        qgetCategories.resolve($scope.awardsFunction('*', 60));

        return qgetCategories.promise;

    }

    $scope.getCategory = function (dataObj) {

        console.log("inside function getCategory");
        console.log(dataObj);
        var qgetCategory = $q.defer();

        //$scope.awardsData.Category = dataObj.Category;
        //$scope.awardsData.Description = dataObj.Description;
        $scope.awardsData.Awards_Category_ID = dataObj;
        console.log("$scope.awardsData.Awards_Category_ID: " + $scope.awardsData.Awards_Category_ID);
        //console.log("$scope.awardsData.awardCategories: " + dataObj.Awards_Category_ID);

        qgetCategory.resolve($scope.awardsFunction("*", 701));

        return qgetCategory.promise;
    }

    $scope.actionUpdate = function (action) {
        console.log("inside function actionUpdate");
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");

        if(action == 1) {
            console.log("ACTION: 1");


            //$scope.landlordStatementView()

        } else if (action == 6) {
            console.log("ACTION: 6");


            //$scope.landlordStatementLicenseView()

        } else if (action == 2) {
            //$scope.addOutstandingAgentsManagementFee();

        }else if (action == 3) {
            //$scope.getDataExpired(1)
            $scope.addLandlordRegularPaymentsTransaction();
        } else if (action == 4) {
            //send landlord statement
            $scope.awardsFunction('*', 703)
        }else if (action == "") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            //$scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            //$scope.getDataCurrent(1)
            //$scope.progress.date_completed = null;
        }
        else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            //$scope.getData($scope.pageno)

        } else{

        }

    }

    $scope.loadAwardsData = function () {
        console.log("inside function loadAwardsData");


        $('.data').hide();
        var qloadAwardsData = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {


                console.log("A2 - getNomination");


                return $scope.getNominations(data);
            })
            .then(function (data) {

                console.log("A2 - getCategories");

                return $scope.getCategories(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7a');
                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                $('.data').hide();
            }, function (err) {


                $scope.myValue = err;
            })

        qloadAwardsData.resolve($scope.promise);

    };

    $scope.loadAwardsData();
    ////////////////////////// Old /////////////////////////////////////////////////////////////////////////////////


    /*
    $scope.properties = [];
    $scope.properties.Property_ID = null;
    $scope.myJsonString = [];
    $scope.getHeader = [];
    $scope.tenancyID = "current";
    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;
    $scope.landlord.Payment_Transaction_Category = "";
    $scope.landlord.Payment_Transaction_Category_ID = 0;
    $scope.landlord.landlord_payment_transaction_year_cbo = "";
    $scope.landlord.landlord_transaction_year = 0;
    $scope.landlord.landlord_transaction_month_cbo = "";
    $scope.landlord.landlord_transaction_month = 0;
    $scope.landlord.incomeExpenditure = "*";
    $scope.landlord.Transaction_Date = new Date();
    //$scope.landlord.Transactions_Before_Date = new Date();
    $scope.landlord.Transaction_Cut_of_Date = new Date();
    $scope.filename = "test" + ".csv";
    $scope.paymentTransactionCategories = {};
    $scope.landlordSelected = {};
    $scope.landlordRegularCosts = {};
    $scope.landlordRegularCosts = null;
    $scope.landlordInformation = {};
    $scope.dirPagData = {};

    $scope.landlord.yearRelatedTo = "0";
    $scope.landlord.monthRelatedTo = "0";

    $scope.transactionTotalRent  = {};
    $scope.transactionTotalAgentManagementFee  = {};
    $scope.transactionTotalRentXAgentCommission  = {};
    $scope.transactionTotalAgentManagementFeeBalance  = {}

    $(document).ready(function () {
        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })

    $('.data').hide();

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.getTotal = function(){

        console.log("inside function getTotal");
        console.log($scope.landlordRegularCosts);
        var total = 0;
        for(var i = 0; i < $scope.landlordRegularCosts.length; i++){
            var landlordRegularCosts = $scope.landlordRegularCosts[i];
            total += landlordRegularCosts.Landlord_Regular_Cost;
        }

        $scope.sumLandlordRegularTransactions = total;
        return total;
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.landlordInformation = function(id, status) {
        console.log("function landlordInformation");


        console.log($scope.paymentTransactionsData);



        if ($scope.search) {
            console.log("trying search")
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {
            $scope.search = "*";
            $scope.landlord.search = $scope.search;
            console.log("search null")
        }

        var q2 = $q.defer();

        console.log("function landlordStatementTransactions");
        $scope.landlord.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.landlord.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.landlord.User_ID = $rootScope.decodedToken.id;
        $scope.landlord.itemsPerPage = $scope.itemsPerPage;
        $scope.landlord.pageno = $scope.pageno;
        $scope.landlord.Payment_Transaction_ID = id;
        //$scope.landlord.Vendor_ID = "*";
        //$scope.landlord.Property_ID = "0";
        $scope.landlord.Status = status;
        console.log("$scope.landlord.User_ID: " + $scope.landlord.User_ID);

        apiService.landlordStatementTransactions(id, $scope.landlord).then(
            //apiService.landlordStatementTransactions(itemsPerPage, $scope.pageno, searchcriteria, status, paymentTransactionID, paymentTransactionCategoryID, yearRelatedTo, monthRelatedTo, propertyID, accountHolderID, cloudertySystemAdministrator).then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API landlordStatementTransactions");

                $scope.landlord.Licence_Key = response.data.data.Licence_Key;
                console.log(response);
                console.log($scope.landlord.Licence_Key);
                //$scope.currentPage = $scope.pageno;
                //$scope.totalCount = response.data.totalCount;
                //$scope.transactionTotal = response.data.transactionTotal;
                console.log("API landlordStatementTransactions");

                if ($scope.search == "*") {
                    $scope.search = "";
                }
                else {

                }
                q2.resolve(response);

            }
        )

        if ($scope.search == "*") {
            $scope.search = "";
        }
        else {

        }

        return q2.promise;
    }

    $scope.landlordStatementTransactions = function(id, status) {
        console.log("function landlordStatementTransactions");
        console.log("status: " + status + " | " + " id: " + id);

        var qlandlordStatementTransactions = $q.defer();
        console.log($scope.paymentTransactionsData);

        if(status == "2"){

            var url = $state.href('lettings-main-nav.financials-side.payment-transaction-edit', {id:  id});
            window.open(url,'_blank');
            landlordStatementTransactions.resolve($scope.getGlobalPromise(data));

        }
        else{

            if(status != 161 && status != 42 && status != 43 && status != 44 && status != 32 && status != 21){
                //do this if not landlord regular costs
                $scope.landlordTransactions = [];
            }


            if ($scope.search) {
                console.log("trying search");
                $scope.landlord.search = $scope.search;

            }
            else if (!$scope.search) {
                $scope.search = "*";
                $scope.landlord.search = $scope.search;
                console.log("search null")
            }



            console.log("function landlordStatementTransactions");
            $scope.landlord.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.landlord.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.landlord.User_ID = $rootScope.decodedToken.id;
            $scope.landlord.itemsPerPage = $scope.itemsPerPage;
            $scope.landlord.pageno = $scope.pageno;
            $scope.landlord.Payment_Transaction_ID = id;
            //$scope.landlord.Vendor_ID = "*";
            //$scope.landlord.Property_ID = "0";
            $scope.landlord.Status = status;
            console.log("$scope.landlord.User_ID: " + $scope.landlord.User_ID);

            console.log($scope.landlord);
            apiService.landlordStatementTransactions(id, $scope.landlord).then(
               function (response) {

                    //$scope.landlords = response.data;
                    console.log("API landlordStatementTransactions");
                    console.log(response);


                    if(status == 161 || status == 43 || status == 32){

                        $scope.landlordRegularCosts  = response.data.landlordRegularTransactions;
                        $scope.transactionTotalRent  = response.data.transactionTotalRent;
                        $scope.transactionTotalAgentManagementFee  = response.data.transactionTotalAgentManagementFee;
                        $scope.transactionTotalRentXAgentCommission  = response.data.transactionTotalRentXAgentCommission;
                        $scope.transactionTotalAgentManagementFeeBalance  = response.data.transactionTotalAgentManagementFeeBalance;

                        console.log($scope.landlordRegularCosts);
                        console.log(response);
                        console.log('landlordRegularCosts');


                    }
                    else if(status == 42 || status == 44){

                    }
                    else if(status == 21){

                        $scope.landlordInformation = response.data.data;

                    }
                    else{

                        $scope.landlordTransactions = response.data.data;
                        console.log($scope.landlordTransactions);
                        console.log($scope.landlordTransactions.length);
                        $scope.currentPage = $scope.pageno;
                        $scope.totalCount = response.data.totalCount;
                        $scope.transactionTotal = response.data.transactionTotal;

                    }

                    if(status == 12){
                        console.log("API close accordions");
                        $('.data').hide();
                        //$scope.getTotal();
                    }

                    if ($scope.search == "*") {
                        $scope.search = "";
                    }
                    else {

                    }

                    if ($scope.search == "*") {
                        $scope.search = "";
                    }
                    else {

                    }

                    qlandlordStatementTransactions.resolve(response);



                }

            )


            console.log("qlandlordStatementTransactions.promise");
            return qlandlordStatementTransactions.promise;

        }
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.getData = function(pageno) {
        console.log("inside function getData");

        var qgetData = $q.defer();
        $scope.landlordTransactions = [];

        canceller = $q.defer();

        if ($scope.search) {
            console.log("trying search")
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {
            $scope.search = "*";
            console.log("search null")
        }

        $scope.currentPage = pageno;
        $scope.pageno = pageno;

        qgetData.resolve($scope.landlordStatementTransactions('*', $scope.status));

        return qgetData.promise;

    }

    $scope.landlordStatementTransactionsAll = function (staus) {
        var qlandlordStatementTransactionsAll = $q.defer();
        console.log("inside function landlordStatementTransactionsAll");
        $scope.landlordTransactions = [];

        if ($scope.search) {

            console.log("trying search");
            $scope.landlord.search = $scope.search;

        }
        else if (!$scope.search) {

            $scope.search = "*";
            console.log("search null");

        }
        //var q = $q.defer();
        $scope.itemsPerPage = '100000000';

        if(staus == ""){

        }
        else{

        }

        console.log('entering landlordStatementTransactions');
        //$scope.promise = $scope.landlordStatementTransactions('*', $scope.status);

        return $scope.landlordStatementTransactions('*', $scope.status).then(
            function(response){
                console.log('inside landlordStatementTransactions');

                if ($scope.search == "*") {
                    $scope.search = "";
                }
                else {
                }
                    qlandlordStatementTransactionsAll.resolve(response);


            }
        );

        console.log('after landlordStatementTransactions');





    }

    $scope.sort = function(keyname){
        console.log("inside sort function");
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;

        console.log("$scope.getPropertiesSearch landlord");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties  = response.data;
                //console.log("$scope.properties.Property_ID searchProperties: " + $scope.properties[1].Property_ID);
            },
            function() {

                console.log("There was an error getPropertiesSearch")

            }
        )
    }

    $scope.selectLandlord = function () {

        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {
                console.log("A1");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2");
                $scope.search = $scope.landlord.Landlord_ID;
                console.log("landlord_id: " + $scope.search);

                //$scope.landlordSelect($scope.landlord.Landlord_ID);
                $scope.search = "";
                $scope.status = 11;
                $scope.landlordStatementTransactions("0", $scope.status);
                console.log("promise selectLandlord >>>>>>>>>>>>> " );
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2b");
                console.log("promise selectLandlord >>>>>>>>>>>>> " );
                //$scope.landlord.Landlord_ID = $scope.landlord.Landlord_ID;

                $scope.status = 21;
                $scope.landlordStatementTransactions("*", $scope.status);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('A7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);


    }

    $scope.landlordStatementView = function () {
        var url = "";

        url = $state.href('landlord-statement',
            {
                itemsPerPage: '200',
                pagenumber: '1',
                propertyID: $scope.landlord.Property_ID,
                accountHolderID: $rootScope.decodedToken.Account_Holder_ID,
                yearRelatedTo:  $scope.landlord.yearRelatedTo,
                monthRelatedTo: $scope.landlord.monthRelatedTo
            });
        window.open(url,'_blank');




        console.log("Promise landlordStatementView");

        var q71 = $q.defer();
        $scope.url = "";
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();


        q71.resolve($scope.promise);

        return q71.promise;

    }

    $scope.landlordStatementLicenseView = function () {
        var url = "";
        console.log("$scope.landlordSelected.Licence_Key: " + $scope.landlord.Licence_Key);
        url = $state.href('landlord-statement-license',
            {
                landlordID: $scope.landlordInformation.Landlord_ID,
                licenseKey: $scope.landlordInformation.Licence_Key
            });
        window.open(url,'_blank');




        console.log("Promise landlordStatementView");

        var q71 = $q.defer();
        $scope.url = "";
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();


        q71.resolve($scope.promise);

        return q71.promise;

    }

    */

    /*
    $scope.landlordStatementSend = function () {

        $scope.url = "";
        $scope.url = $state.href('landlord-statement',
            {
                itemsPerPage: '200',
                pagenumber: '1',
                propertyID: $scope.landlord.Property_ID,
                accountHolderID: $rootScope.decodedToken.Account_Holder_ID,
                yearRelatedTo:  $scope.landlord.yearRelatedTo,
                monthRelatedTo: $scope.landlord.monthRelatedTo
            });
        //window.open($scope.url,'_blank');

        $scope.url2 = "https://management.clouderty.co.uk/" + $scope.url;

        console.log("$scope.url: " + $scope.url);
        console.log("$scope.url2: " + $scope.url2);

        console.log("Promise landlordStatementSend");
        var q73 = $q.defer();
        $scope.url = "";
        $scope.myValue = 0;
        $scope.promise = $scope.getGlobalPromise();








        //$scope.$parent.obj.DescriptionTruncated = $scope.obj.sampleText = truncateSliceService.truncateSliceService($scope.$parent.obj.Description, 25);

        var from_email = "Clouderty | Landlord Statement " + $scope.landlord.yearRelatedTo  + " " + $scope.landlord.monthRelatedTo + " <notifications@clouderty.co.uk>";
        var subject = "Created by: " + $rootScope.decodedToken.Name +  " | " + "Landlord Statement ID: 2222";
        var message = "Landlord Statement ID: " + $scope.landlord.yearRelatedTo + " " + $scope.landlord.monthRelatedTo + "\n" +
            "Landlord statement: " +$scope.url2 + "\n" +
            "Created By: " + $rootScope.decodedToken.Name + "\n";



        var to_email = $rootScope.decodedToken.Accounts_Email;


        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);



        q73.resolve($scope.promise);

        return q73.promise;

    }

    $scope.landlordsViewAll = function () {

        var qlandlordsViewAll = $q.defer();

        apiService.landlordsViewAll('*', '1', $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                qlandlordsViewAll.resolve(response);
                $scope.landlords = response.data;
                console.log("API landlordsViewAll: " + $scope.landlords);
                //console.log("$rootScope.decodedToken.Account_Holder_ID: " + $rootScope.decodedToken.Account_Holder_ID);
            },
            function() {
                //console.log("We are in landlordsViewAll");
                console.log("There was an error in landlordsViewAll")
            }
        )

        return qlandlordsViewAll.promise;

    }

    $scope.landlordSelect = function (landlordID) {

        console.log("function landlordSelect >>>>>>>>>>>>> " );
        $scope.landlord.Landlord_ID = landlordID;
        $scope.landlord.Status = '21';
        $scope.landlord.Payment_Transaction_ID = '0';
        $scope.landlord.itemsPerPage = '20';
        $scope.landlord.pagenumber = '1';
        $scope.landlord.Property_ID = '0';
        $scope.landlord.accountHolderID = '0';
        $scope.landlord.yearRelatedTo = '0';
        $scope.landlord.monthRelatedTo = '0';
        //$scope.landlord.search = "*";

        apiService.landlordStatementTransactions('0', $scope.landlord).then(
            function(response) {
                $scope.landlordSelected = response.data.data;
                console.log(response);
                $scope.landlord.Licence_Key = $scope.landlordSelected.Licence_Key;
                //$scope.properties = response.data.data;
                console.log("API landlordStatementTransactions: " );
                console.log("$scope.landlord.Licence_Key: aaaaaaaaaaaaaaaaaaaaaaaaa " + $scope.landlord.Licence_Key);
            },
            function() {
                console.log("api service landlordStatementTransactions failed");
            }
        )

    }

    $scope.propertiesViewAll = function () {

        var qpropertiesViewAll = $q.defer();

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                qpropertiesViewAll.resolve(response);
                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

        return qpropertiesViewAll.promise;

    }

    $scope.paymentCategoryDescriptionViewAll = function () {

        var qpaymentCategoryDescriptionViewAll = $q.defer();

        apiService.getPaymentTransactionCategories().then(
            function(response) {

                qpaymentCategoryDescriptionViewAll.resolve(response);
                console.log(response);
                $scope.paymentTransactionCategories = response.data.data;
                console.log("API getPaymentTransactionCategory: " + response.data.data.Description);
            },
            function() {
                console.log("We are in");
                console.log("There was an error paymentCategoryDescriptionViewAll")
            }
        )

        return qpaymentCategoryDescriptionViewAll.promise;


    }

    $scope.getLandlordRegularCosts = function () {
        console.log("$scope.getLandlordRegularCosts");

        var qgetLandlordRegularCosts = $q.defer();

        //$scope.landlord.Property_ID = "0";
        $('.data').hide();
        $scope.status = 161;
        qgetLandlordRegularCosts.resolve($scope.landlordStatementTransactions('*', $scope.status));
        $('.data').hide();

        return qgetLandlordRegularCosts.promise;

    }

    $scope.addLandlordRegularPaymentTransaction = function (dataObj) {

        console.log("inside function addLandlordRegularPaymentTransaction");

        console.log(dataObj);

        if(confirm("Do you want to add this regular landlord cost?"), 'Clouderty notification'){


            console.log("dataObj.Property_ID: " + dataObj.Property_ID);
            console.log("dataObj.Landlord_Regular_Cost: " + dataObj.Landlord_Regular_Cost);
            console.log("dataObj.Payment_Transaction_Category_ID_Regular: " + dataObj.Payment_Transaction_Category_ID);
            //$scope.landlord.Property_ID = dataObj.Property_ID;
            $scope.landlord.Landlord_Regular_Cost = dataObj.costAdd;
            $scope.landlord.Payment_Transaction_Category_ID_Regular = dataObj.descriptionAdd;
            //$scope.paymentBlockSpinner = 1;

            var q = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 login external");

                    $scope.landlordStatementTransactions('*', 43);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }


    }

    $scope.addOutstandingAgentsManagementFee = function () {
        console.log("inside function addOutstandingAgentsManagementFee");



        if($scope.landlord.Transaction_Date == null){
        }else{
            //$scope.landlord.Transaction_Date = dateInputService.dateInputDayService($scope.landlord.Transaction_Date, 0);
            $scope.landlord.Transaction_Date2 = $filter('date')(new Date($scope.landlord.Transaction_Date), 'yyyy-MM-dd');
            console.log("Transaction Date: " + $scope.landlord.Transaction_Date2);
        }
        if($scope.landlord.Property_ID == null){

            alert("Select a property");
            $scope.document_action = "";
            return "";
        }else{

        }
        if(confirm("Do you want to add an agents management fee transactions?")){
            $scope.landlord.Payment_Transaction_Type_ID = "6";
            $scope.landlord.Amount = $scope.transactionTotalAgentManagementFeeBalance;
            $scope.landlordStatementTransactions('*', 42);

        }else{

        }




    }

    $scope.addLandlordPayment = function () {
        console.log("inside function addLandlordPayment");



        if($scope.landlord.Transaction_Date == null){
        }else{
            //$scope.landlord.Transaction_Date = dateInputService.dateInputDayService($scope.landlord.Transaction_Date, 0);
            $scope.landlord.Transaction_Date2 = $filter('date')(new Date($scope.landlord.Transaction_Date), 'yyyy-MM-dd');
            console.log("Transaction Date: " + $scope.landlord.Transaction_Date2);
        }
        if($scope.landlord.Property_ID == null){

            alert("Select a property");
            $scope.document_action = "";
            return "";
        }else{

        }
        if(confirm("Do you want to add the outstanding landlord payment?")){
            $scope.landlord.Payment_Transaction_Type_ID = 6;
            $scope.landlord.Payment_Transaction_Category_ID = 21;
            $scope.landlord.Amount = -($scope.transactionTotal);
            $scope.landlordStatementTransactions('*', 44);

        }else{

        }




    }

    $scope.addLandlordRegularPaymentsTransaction = function () {

        $scope.landlord.Payment_Transaction_Type_ID = "6";


        if($scope.landlord.Transaction_Date == null){
        }else{
            //$scope.landlord.Transaction_Date = dateInputService.dateInputDayService($scope.landlord.Transaction_Date, 0);
            $scope.landlord.Transaction_Date2 = $filter('date')(new Date($scope.landlord.Transaction_Date), 'yyyy-MM-dd');
            console.log("Transaction Date: " + $scope.landlord.Transaction_Date2);
        }
        if($scope.landlord.Property_ID == null){

            alert("Select a property");
            $scope.document_action = "";
            return "";
        }else{

        }
        if(confirm("Do you want to add regular transactions?")){
            console.log("User_ID: " + $rootScope.decodedToken.User_ID)
            apiService.addLandlordRegularPaymentsTransaction($scope.landlord.Transaction_Date2, $scope.landlord.Property_ID, $rootScope.decodedToken.id, $rootScope.decodedToken.Account_Holder_ID, $scope.landlord).then(
                function(response) {

                    //$scope.addLandlordRegularPaymentsTransaction = response.data.data;
                    console.log("API addLandlordRegularPaymentsTransaction: " + response.data.data);
                },
                function() {
                    console.log("API Error addLandlordRegularPaymentsTransaction");
                }
            )
        }else{



        }

    }

    $scope.deleteLandlordRegularPaymentTransaction = function (dataObj) {

        console.log("inside function deleteLandlordRegularPaymentTransaction");

        console.log(dataObj);

        if(confirm("Do you want to delete this item?"), 'Clouderty notification'){
            console.log("in function dirTests");


            console.log("dataObj.Landlord_Regular_Cost_ID: " + dataObj.Landlord_Regular_Cost_ID);
            $scope.landlord.Landlord_Regular_Cost_ID = dataObj.Landlord_Regular_Cost_ID;
            //$scope.paymentBlockSpinner = 1;

            var qdeleteLandlordRegularPaymentTransaction = $q.defer();

            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A1 landlordStatementTransactions");

                    return $scope.landlordStatementTransactions('*', 32);
                    //return $scope.getGlobalPromise(data);
                })
                .then(function (data) {
                    console.log('A7');

                    $scope.myValue = data;

                    return $scope.getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                })

            qdeleteLandlordRegularPaymentTransaction.resolve($scope.promise);

        }


        return qdeleteLandlordRegularPaymentTransaction.promise;

    }


    $scope.actionUpdatePaymentRecord = function (action, paymentTransactionID) {
        var date = new Date();
        date.setHours(date.getHours()+1);
        console.log("Progress 1: ");
        //$scope.progress.progress = $scope.obj.data.Status;

        if(action == "1") {
            console.log("ACTION: 1");


        } else if (action == "2") {

            // Delete record
            console.log("ACTION: 2");
            var q = $q.defer();
            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();


            $scope.promise
                .then(function (data) {

                    console.log("A2 landlordStatementTransactions delete record");
                    //$scope.landlordStatementTransactions($scope.itemsPerPage, $scope.pageno, '*', "2", paymentTransactionID, $scope.landlord.Payment_Transaction_Category_ID, $scope.landlord.landlord_transaction_year, $scope.landlord.landlord_transaction_month, $scope.landlord.Property_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator);
                    $scope.landlordStatementTransactions("*", "*");

                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log("A2 landlordStatementTransactions show all based on property, year, and month");
                    $scope.landlordStatementTransactions($scope.itemsPerPage, $scope.pageno, $scope.search, "0", "*", $scope.landlord.Payment_Transaction_Category_ID, $scope.landlord.landlord_transaction_year, $scope.landlord.landlord_transaction_month, $scope.landlord.Property_ID, $rootScope.decodedToken.Account_Holder_ID,  $rootScope.decodedToken.Clouderty_System_Administrator);

                    return $scope.getGlobalPromise(data);

                })
                .then(function (data) {
                    console.log('7');
                    $scope.myValue = data;
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);


            return 0;

        } else if (action == "3") {
            //$scope.getDataExpired(1)
            return $scope.addLandlordRegularPaymentsTransaction();

        } else if (action == "") {
            console.log("Expired Tenancies within 3 Month");
            //$scope.getDataExpired(1)
            //$scope.getDataExpiredThreeMonth(1)
            //$scope.progress.date_completed = null;
        } else if (action == "VIEW CURRENT") {
            console.log("Progress 3: ");
            //$scope.getDataExpired(1)
            $scope.getDataCurrent(1)
            //$scope.progress.date_completed = null;
        }
        else if (progress == "VIEW ALL") {
            console.log("Progress 3: ");
            $scope.getData($scope.pageno)

        } else{

        }

    }

    $scope.ExportLandlordStatementTransactions = function() {
        console.log("C1 ExportLandlordStatementTransactions");

        var qExportLandlordStatementTransactions = $q.defer();

        $scope.myValue = 0;
        $scope.itemsPerPage = 100000;
        if($scope.status == 161){

            $scope.status = 12;

        }
        $scope.promise = $scope.landlordStatementTransactions('*', $scope.status);

        $scope.promise
            .then(function (data) {

                console.log("A2 getGlobalPromise");
                return $scope.ExportLandlordStatementTransactionsDetails(data);
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        qExportLandlordStatementTransactions.resolve($scope.promise);



    }

    $scope.ExportLandlordStatementTransactionsDetails = function() {
        //var q = $q.defer();
        $scope.getHeader =  ["Property", "Unit", "First_Name", "Last_Name",  "Category", "Amount", "Date", "Payment_Transaction_ID", "Tenancy_ID", "Payment_Type", "Date_Received", "Description", "Proeprty_ID", "Notes", "User"];
        $scope.filename = "Landlord Statement Transactions";
        //$scope.itemsPerPage = 200000;
        //q.resolve($scope.itemsPerPage);

        $scope.exportCSVData = exportCSVService.exportCSVService($scope.landlordTransactions);
        //return q.promise;

    }

    $scope.resetVariables = function() {

        if ($scope.landlord.yearRelatedTo == 0) {
            $scope.landlord.yearRelatedTo = "";
        };
        if ($scope.landlord.landlord_transaction_month_cbo == 0) {
            $scope.landlord.landlord_transaction_month_cbo = "";
        };
        if ($scope.landlord.Payment_Transaction_Category_ID == 0) {
            $scope.landlord.Payment_Transaction_Category_ID = "";
        };
        if ($scope.landlord.Payment_Transaction_Category == 0) {
            $scope.landlord.Payment_Transaction_Category = "";
        };
        if ($scope.landlord.yearRelatedTo == 0) {
            $scope.landlord.yearRelatedTo = "";
        };
        if ($scope.landlord.monthRelatedTo == 0) {
            $scope.landlord.monthRelatedTo = "";
        };

    }

    $scope.landlordPaymentSearch = function() {

        console.log("function landlordPaymentSearch going in $scope.status: " + $scope.status);

        $scope.resetVariables();

        if(!$scope.landlord.Property && !$scope.landlord.Payment_Transaction_Category && !$scope.landlord.yearRelatedTo && !$scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 0;
        }
        if($scope.landlord.Property && $scope.landlord.Payment_Transaction_Category && $scope.landlord.yearRelatedTo && $scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 1;
        }
        else if($scope.landlord.Property && $scope.landlord.Payment_Transaction_Category && $scope.landlord.yearRelatedTo){

            $scope.status = 141;
        }
        else if($scope.landlord.Property && $scope.landlord.Payment_Transaction_Category && $scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 151;
        }
        else if($scope.landlord.Payment_Transaction_Category && $scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 153;
        }
        else if($scope.landlord.Property && $scope.landlord.yearRelatedTo){

            $scope.status = 142;
        }
        else if($scope.landlord.Payment_Transaction_Category && $scope.landlord.yearRelatedTo) {

            $scope.status = 143;

        }
        else if($scope.landlord.yearRelatedTo && $scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 144;
        }
        else if($scope.landlord.Property && $scope.landlord.Payment_Transaction_Category){

            $scope.status = 121;
        }
        else if($scope.landlord.Property && $scope.landlord.landlord_transaction_month_cbo){

            $scope.status = 152;
        }
        else if($scope.landlord.Payment_Transaction_Category) {

            $scope.status = 13;

        }
        else if($scope.landlord.Property){

            $scope.status = 12;
        }


        console.log("function landlordPaymentSearch going out $scope.status: " + $scope.status);

    }

    $scope.getDataTransactionsBeforeDate = function() {
        console.log("inside function getDataTransactionsBeforeDate");
        console.log("$scope.status: " + $scope.status);

        console.log($scope.landlord.Transactions_Before_Date);
        //$scope.landlordPaymentSearch();


        if (!$scope.landlord.Transactions_Before_Date || $scope.landlord.Transactions_Before_Date == "") {

            //$scope.landlord.Property = null;
            //$scope.landlord.landlordRegularCosts = null;
            //$scope.landlordRegularCosts = null;
            console.log("$scope.landlord.Transactions_Before_Date: " + $scope.landlord.Transactions_Before_Date);

        }
        else {

            //$scope.landlord.Transactions_Before_Date = dateInputService.dateInputDayService($scope.landlord.Transactions_Before_Date, 0);
            console.log("$scope.landlord.Transactions_Before_Date: " + $scope.landlord.Transactions_Before_Date);

        }

        if($scope.status == 161){

            $scope.status = 12;

        }
        $scope.promise = $scope.getData(1);

        $scope.promise
            .then(function () {

                $('.data').hide();

                if ($scope.landlord.Property || $scope.landlord.Property != "") {
                    console.log('A1 - promise then getLandlordRegularCosts');

                    return $scope.getLandlordRegularCosts();

                } else{
                    $('.data').hide();

                    console.log('A1 - promise then getGlobalPromise');
                    return $scope.getGlobalPromise();

                }


            })
            .then(function () {
                    console.log('A7b data');
                }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })


    }

    $scope.clearDataTransactionsBeforeDate = function() {
        console.log("inside function clearDataTransactionsBeforeDate");
        console.log("$scope.status: " + $scope.status);

        console.log($scope.landlord.Transactions_Before_Date);
        //$scope.landlordPaymentSearch();
        $scope.landlord.Transactions_Before_Date = null;

        if($scope.status == 161){

            $scope.status = 12;

        }

        $scope.getData(1);

    }

    $scope.getDataProperty = function(property) {
        console.log("inside function getDataProperty");
        console.log(property);

        $scope.landlordPaymentSearch();


        if (!$scope.landlord.Property || $scope.landlord.Property == "") {

            $scope.landlord.Property = null;
            $scope.landlord.landlordRegularCosts = null;
            $scope.landlordRegularCosts = null;
            console.log("$scope.landlord.Property: " + $scope.landlord.Property);

        }
        else {

            console.log("$scope.landlordTransactions[0]: " + $scope.landlordTransactions[0]);
            console.log("$scope.landlord.Property: " + $scope.landlordTransactions[0].Property_name);
            $scope.landlord.Property_name = $scope.landlordTransactions[0].Property_name;
            $scope.landlord.Property_ID = $scope.landlord.Property;
            console.log("$scope.landlord.Property: " + $scope.landlord.Property);



        }

        $scope.promise = $scope.getData(1);

        $scope.promise
            .then(function () {

                $('.data').hide();

                if ($scope.landlord.Property || $scope.landlord.Property != "") {
                    console.log('A1 - promise then getLandlordRegularCosts');

                    return $scope.getLandlordRegularCosts();

                } else{
                    $('.data').hide();

                    console.log('A1 - promise then getGlobalPromise');
                    return $scope.getGlobalPromise();

                }


            })
            .then(function () {
                console.log('A7b data');
            }
            , function (err) {

                console.log('A7c error');


                $scope.myValue = err;
            })


    }

    $scope.getPaymentTransactionCategoryRegular = function(objData) {
        console.log('inside function getRegularPaymentTransactionCategory');

        if(!$scope.dirPagData.descriptionAdd || $scope.dirPagData.descriptionAdd == ""){

            $scope.dirPagData.descriptionAdd = null;
            console.log("$scope.landlord.Payment_Transaction_Category: " + objData.Payment_Transaction_Category_ID);

        }
        else{

            $scope.landlord.Payment_Transaction_Category_ID_Regular = objData.Payment_Transaction_Category_ID;
            console.log("$scope.landlord.Payment_Transaction_Category: " + objData.Payment_Transaction_Category_ID);

        }



    }

    $scope.getPaymentTransactionCategoryRegularDir = function(objData) {
        console.log('inside function getPaymentTransactionCategoryRegularDir');

        if(!$scope.dirPagData.descriptionAdd || $scope.dirPagData.descriptionAdd == ""){

            $scope.dirPagData.descriptionAdd = null;
            console.log("$scope.landlord.Payment_Transaction_Category: " + objData.Payment_Transaction_Category_ID);

        }
        else{

            $scope.landlord.Payment_Transaction_Category_ID_Regular = objData.Payment_Transaction_Category_ID;
            console.log("$scope.landlord.Payment_Transaction_Category: " + objData.Payment_Transaction_Category_ID);

        }


    }

    $scope.getDataPaymentTransactionYear = function() {
        console.log("function getDataPaymentTransactionYear " + $scope.landlord.landlord_transaction_year);



        $scope.landlordPaymentSearch();



        if(!$scope.landlord.landlord_payment_transaction_year_cbo){

            //$scope.landlord.yearRelatedTo = "0";
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_year);
        }
        else{
            $scope.status = 14;
            $scope.landlord.yearRelatedTo = $scope.landlord.landlord_payment_transaction_year_cbo;
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_year);
        }


        $scope.getData($scope.pageno);

    }

    $scope.getDataPaymentTransactionMonth = function() {
        //$scope.tenancies = [];
        $scope.status = 15;

        $scope.landlordPaymentSearch();

        if(!$scope.landlord.landlord_transaction_month_cbo || $scope.landlord.landlord_transaction_month_cbo == ""){

            //$scope.landlord.monthRelatedTo = "0";
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_month);
        }
        else{

            $scope.landlord.monthRelatedTo = $scope.landlord.landlord_transaction_month_cbo;
            console.log("$scope.landlord.landlord_payment_transaction_year_cbo: " + $scope.landlord.landlord_transaction_month);
        }

        $scope.getData($scope.pageno);
    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

    $scope.actionPaymentTransactions = function(actionPaymentTransactionsSelect, Payment_Transaction_ID){

        if(actionPaymentTransactionsSelect == "1") {
            if(confirm("Do you want to remove this transaction?")) {
                console.log("Delete transaction");
                var q = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {
                        console.log("A1");
                        $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                        $scope.paymentTransactionsData.Payment_Transaction_ID = Payment_Transaction_ID;
                        $scope.paymentTransactionsFunction("*", "3");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2");

                        $scope.getData();
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);




                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            }
        }else if(actionPaymentTransactionsSelect == "2") {

            console.log("Edit Payment Transaction");

            //$scope.editPaymentTransaction(Payment_Transaction_ID);
            $scope.landlordStatementTransactions(Payment_Transaction_ID, "2")

        }else {

        }
    };

    $scope.loadPaymentTransactionData = function () {
        console.log("inside function loadPaymentTransactionData");



        console.log("$stateParams.id: " + $stateParams.id);
        //$scope.$state = $state;
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2 landlordStatementTransactions");

                return $scope.landlordStatementTransactions('*', '0');
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A3 landlordsViewAll");

                return $scope.landlordsViewAll();
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A4 propertiesViewAll");

                return $scope.propertiesViewAll();
                //return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A4 paymentCategoryDescriptionViewAll");


                return $scope.paymentCategoryDescriptionViewAll();
            }, function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

    };

    $scope.loadPaymentTransactionData();

    */

}]);