clouderty.service("UploadService", [ "moment", "fileUploadService", "Upload", "$rootScope", "apiService", "$q", 'FlashService' , '$http', '$location', '$timeout', '$httpParamSerializer', function (moment, fileUploadService, Upload, $rootScope, apiService, $q, FlashService, $http, $location, $timeout, $httpParamSerializer) {

    console.log("inside service UploadService");

    this.pdfAddHTML = function(element, fileName, attachmentFileLocation, status, to_email, from_email, subject, message) {


        'use strict';
        var service = {};
        //var file = "";

        var dataObj = {};
        dataObj.test = 1;


        $('.alert').show('');
        var doc = new jsPDF('p', 'pt', 'letter');

        var specialElementHandlers = {
            element : function (element, renderer) {
                return true;
            }
        };

        var getGlobalPromise = function() {

            var q55 = $q.defer();
            apiService.getGlobalPromise().then(
                function (response) {
                    console.log("API getGlobalPromise");
                    q55.resolve(response);
                }
            )
            return q55.promise;
        }


        var loadData = function() {

            console.log("inside function loadData");

            var qloadData = $q.defer();
            var myValue = 0;

            doc.setFontSize(16);
            doc.fromHTML($('#canvas').html(), 40, 80, {
            //doc.addHTML($('#body').html(), {
                'width': 500,
                'padding': 50,
                'margin': { top: 40, bottom: 30 },
                'pagesplit': true,
                'useFor': 'content',
                'rstz' : true,
                'elementHandlers': specialElementHandlers
            }, function (dispose) {
                var pdf = doc.output(); //returns raw body of resulting PDF returned as a string as per the plugin documentation.
                var file = doc.output('blob');
                var pdfData = new FormData();
                var fileData = new FormData();

                pdfData.append("file", pdf);
                pdfData.append('filename', fileName);
                pdfData.append('email', to_email);
                pdfData.append('method', "savePdf");

                fileData.append("file", file);
                fileData.append('filename', fileName);
                fileData.append('email', to_email);
                fileData.append('method', "savePdf");

                $rootScope.fileNamePDF = fileName + '.pdf';

                console.log("$rootScope.fileNamePDF: " + $rootScope.fileNamePDF );
                console.log("fileName: " + fileName );

                doc.setProperties({
                    name: fileName
                });


                window.open(URL.createObjectURL(file));
                console.log(URL.createObjectURL(file));

                $rootScope.file = file;

                service.uploadImage = uploadImage;

                console.log("going through upload");

                Upload.upload({
                    url: 'api/files',
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                    method: 'POST',
                    params: $httpParamSerializer(dataObj),
                    paramSerializer: '$httpParamSerializerJQLike',
                    file: $rootScope.file,
                    data: {
                        'Account_Holder_ID': '1',
                        'File_Type': 'note',
                        'ID': '22222222222',
                        'File_Category': 4,
                        'File_Name2': fileName,
                        'PDF': 1
                    }
                }).then(function (response){
                    console.log("A8 - upload image");
                    console.log(response);

                    $rootScope.fileName = fileName + "-1-" + response.data.File_ID;

                    $rootScope.fileName2 = $rootScope.fileName;
                    doc.save($rootScope.fileName2);
                    console.log("xxxxxxxxxxxxx $rootScope.fileName: " + $rootScope.fileName);


                    service.uploadImage(pdfData, function (res, err) {
                        if (err) {
                            //flashService.Error(err, false);
                            ///resetForm();
                        } else {
                            if (res.data.status == 'success') {
                                //FlashService.Success(res.data.message, false);
                                //resetForm();
                                $("html, body").animate({scrollTop: 0}, "slow");
                                $timeout(function () {
                                    $('.alert').html('');
                                    $('.alert').hide();
                                }, 5000);
                            }
                        }
                    });


                    qloadData.resolve(response);
                })
                .then(function (data) {
                    //upload pdf to server
                    console.log("A7 - email");

                    var attachmentFileName = $rootScope.fileName + ".pdf";



                    $rootScope.sendEmail(from_email,to_email,subject,message,"Tenant", attachmentFileName, attachmentFileLocation);
                    $rootScope.sendEmail(from_email,'notifications@clouderty.co.uk',subject,message,"Tenant", attachmentFileName, attachmentFileLocation);

                    return getGlobalPromise(data);

                })
                .then(function (data) {

                    console.log('A15');


                    return getGlobalPromise(data);
                }, function (err) {
                    $scope.myValue = err;
                });

            })

            return qloadData.promise;
        }

        loadData();




        return service;

        function uploadImage(user, callback) {
            return $http.post('api/pdf-upload', user, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }).then(function (res) {
                callback(res);
            }, function (err) {
                callback(null, err);
            });
        }

    }

}])
