/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("testCtrl_authenticate_user_level", ["$scope", "$http", "$stateParams", "$rootScope", "authenticateUserLevelService", "apiService", function ($scope, $http, $stateParams, $rootScope, authenticateUserLevelService, apiService) {
  
    // $rootScope.sendEmail("c.thomas@platinumcrown.co.uk","admin@platinumcrown.co.uk","Test Email","This is a test","Rose")

    console.log("User level in Controller: " + $rootScope.decodedToken.User_Level);

    $scope.testFunction1 = function () {

        if( $scope.a = authenticateUserLevelService.authenticateUserLevelService($rootScope.decodedToken.User_Level, 1, "Testing") == "Exit"){ return; }

        console.log("Outside if statement");
    }


    $scope.getDataCurrent = function(pageno) {
        $scope.tenancies = [];
        $scope.pageno = 1;
        $scope.totalCount = 0;
        $scope.itemsPerPage = 20;


        if ($scope.search)
        {

            apiService.getAllTenanciesSearchCurrent($scope.itemsPerPage, $scope.pageno, $scope.search).then(

                function(response) {
                    console.log("getAllTenanciesSearchCurrent API");
                    $scope.tenancies = response.data;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.totalCount;
                    $scope.sort('ID');
                },
                function() {
                    alert("There was an error: getAllTenanciesSearchCurrent API")
                }
            )


        }
        else
        {

            apiService.getAllTenanciesCurrent($scope.itemsPerPage, $scope.pageno).then(

                function(response) {
                    console.log("getAllTenanciesCurrent API");
                    $scope.tenancies = response.data;
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.totalCount;
                    console.log("getAllTenanciesCurrent pageno:" + pageno);
                    $scope.sort('ID');
                },
                function() {
                    alert("There was an error: getTimesheets API")
                }
            );



        }
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }


    $scope.getDataCurrent($scope.pageno);


}])