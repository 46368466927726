/**
 * Created by Cyril on 31/07/2016.
 */


clouderty.controller("customerAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$stateParams", "apiService", "dateInputService", "$q", "fileUploadService", "$mdDialog", "$mdMedia", "postUserActivityService", function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $stateParams, apiService, dateInputService, $q, fileUploadService, $mdDialog, $mdMedia, postUserActivityService) {

    $scope.obj = {};
    $scope.objBackup = {};
    $scope.tenants = {};
    $scope.searchTenant = {};
    $scope.tenantsDetailsPreUpdate = [];
    $scope.objre = new Date();

    //$scope.obj.contactNumberCheckExisting = null;
    //$scope.obj.emailCheckExisting = null;

    console.log("State ParamsID: = " + $stateParams.id);

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];
    $scope.New_Record = false;
    $('.data').hide();
    $('.accordion-1-form').show();



    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.uploadFile = function () {
        var file = $scope.myFile;
        var uploadUrl = "api/tenants-upload-csv", //Url of web service
            promise = fileUploadService.uploadFileToUrl(file, uploadUrl);

        promise.then(function (response) {
            $scope.serverResponse = response;
            console.log("resp after promise",response);
        }, function () {
            $scope.serverResponse = 'An error has occurred';
        })
    };

    $scope.searchTenant = function() {

        //$http.get('api/tenant/' + $stateParams.id + "/" + $stateParams.accountHolderID).success(function(response) {
        apiService.getTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenants = response.data.data;
                $scope.obj = response.data.data;
                $scope.$parent.objBackup = angular.copy($scope.obj);
                console.log("Set objBackup: " + $scope.$parent.objBackup);
                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);


                $scope.obj.First_name = response.data.data.First_name;

                //console.log("newDate: " + newDate);
                console.log($scope.obj.D_O_B);

                //console.log(response);

                //return $scope.tenants;
            },
            function() {
                console.log("There was an error apiService.getTenant")
            }
        )

    }


    if ($stateParams.id == null) {
        $scope.New_Record = true;
    }else{
        $scope.New_Record = false;
        $scope.obj = $scope.searchTenant();
    }



    //$scope.obj.selectedTenants = [];

    $scope.selectTenant = function(id) {
        console.log("In selected tenant");
        $http.get("api/tenant/" + id).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.Tenant_ID,
                "First_name": response.First_name,
                "Last_Name": response.Last_Name,
                "Email": response.Email,
                "Contact_number": response.Contact_number,
                "D_O_B": response.D_O_B
            })
        })

        $scope.search_tenant = "";
    }


    $scope.hideSearch = false;

    $scope.searchUnit = function() {
        $scope.units = null;
        $http.get('api/units/' + $scope.search_unit).success(function(response) {
            $scope.units = response;
        });
    }


    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }


    $scope.addEditCustomerDetails = function() {

        //console.log($scope.obj.User_ID);
        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        console.log("$rootScope.decodedToken.Account_Holder_ID: " + $rootScope.decodedToken.Account_Holder_ID);
        $scope.obj.User_ID = $rootScope.decodedToken.id;
        $scope.obj.Date_Added = new Date();
        //console.log('Date added: ' + $scope.obj.Date_Added);
        $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 1);
        //$scope.obj.D_O_B = new Date();
        console.log("Date DOB: " + $scope.obj.D_O_B);

        if ($stateParams.id == null) {
            if (confirm('Are you sure you want to add a customer?')) {

                apiService.createCustomer($scope.obj).then(
                    function (response){

                //$http.post('api/tenant', $scope.obj).success(function (response) {

                    console.log(response);
                    console.log($scope.obj.First_name);
                    console.log($scope.obj.Surname);
                    //console.log($scope.obj.D_O_B);
                    $scope.obj.Customer_ID = response.Customer_ID

                    //////////////////////////////////////////////////////////////////////////

                    console.log('Customer id obj:' + $scope.obj.Customer_ID);

                    var from_email = "Clouderty | Tenant Added | " + $scope.obj.First_name + " <notifications@clouderty.co.uk>";
                    var to_email = $rootScope.decodedToken.Notifications_Email;
                    var subject = $scope.obj.First_name + " | Created by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Customer_ID;
                    var message = "Tenant name: " + $scope.obj.First_name + "\n\n" +
                        "Email: " + $scope.obj.Email + "\n\n" +
                        "DOB: " + $scope.obj.D_O_B + "\n\n" +
                        "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                        "Time/Date: " + Date() + "\n\n" +
                        "Tenant ID: " + $scope.obj.Customer_ID + "\n\n" +
                        "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                    //console.log('Tenancy end date' + $scope.obj.Tenancy_ID);
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenant Added: ' + $scope.obj.First_name + ' - ' + $scope.obj.Customer_ID);
                    //$state.go('lettings-main-nav.lettings-side.lettings-add-tenancy');

                //});

                },
                function () {
                    console.log("There was an error in addProperty")
                }
            )


            }
            else {

            }
        }
        else {
            if (confirm('Are you sure you want to update this customer?')) {


                $scope.tenantsDetailsPreUpdate2 = [];
                $scope.tenantsDetailsPreUpdate2 = $scope.tenantsDetailsPreUpdate;
                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, -1);


                apiService.updateTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $scope.obj).then(
                    function(response) {

                        $scope.$parent.obj = apiService.getTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID).then(
                            function(response) {
                                $scope.tenants = response.data.data;
                                $scope.obj = response.data.data;
                                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);
                                $scope.obj.First_name = response.data.data.First_name;
                                return $scope.tenants;
                            },
                            function() {
                                console.log("There was an error apiService.getTenant")
                            }
                        )



                        console.log(response);


                        console.log(response);
                        console.log(response.data);
                        var from_email = "Clouderty | Tenant Details Updated | " + $scope.obj.First_name + " <notifications@clouderty.co.uk>";
                        var to_email = $rootScope.decodedToken.Notifications_Email;
                        var subject = $scope.obj.First_name + " | Tenant Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "First name: " + $scope.obj.First_name + "\n\n" +
                            "Surname: " + $scope.obj.Surname + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.$parent.objBackup.First_name + "\n\n" +
                            "Surname: " + $scope.$parent.objBackup.Surname + "\n\n" +
                            "Email: " + $scope.$parent.objBackup.Email + "\n\n" +
                            "DOB: " + $scope.$parent.objBackup.D_O_B + "\n\n" +
                            "Contact number: " + $scope.$parent.objBackup.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenant Updated: ' + $scope.obj.First_name + ' - ' + $scope.obj.Tenant_ID);
                        $state.reload("lettings-main-nav.lettings-side.lettings-edit-tenant", {id: $stateParams.id});

                    },
                    function() {
                        console.log("There was an error");
                    }
                )
            }

        }

    }



    $scope.addEditCustomer = function() {

        console.log("addEditCustomer");
        console.log("$stateParams.id: " + $stateParams.id );
        if ($stateParams.id == null) {
            if (confirm('Are you sure you want to add a customer?')) {
                console.log("C1");
                var q = $q.defer();
                $scope.myValue = 0;
                /* TEMP
                $scope.promise = $scope.checkTenantExistingEmail();

                $scope.promise
                    .then(function (data) {
                        console.log("C2 $scope.emailCheckExisting: " + $scope.obj.emailCheckExisting);
                        return $scope.checkTenantExistingContactNumber(data);
                    })
                    .then(function (data) {
                        console.log("C3 $scope.emailCheckExisting: " + $scope.obj.emailCheckExisting);
                        console.log("C4 $scope.checkTenantExistingEmail: " + $scope.obj.contactNumberCheckExisting);
                        if ((!$scope.obj.emailCheckExisting && !$scope.obj.contactNumberCheckExisting)) {
                            console.log("C6");
                            return $scope.addEditTenantDetails(data);
                        } else {
                            console.log("C7");
                            alert('Tenant already exists so will not be added. Email.');
                            return "";
                        }
                    })
                    .then(function (data) {
                        console.log('7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })
                q.resolve($scope.promise);
                */
                $scope.addEditCustomerDetails();//Delete later
            }
        }else{
            console.log("Attempt to update customer");
            $scope.addEditCustomerDetails();
        }


    }

    /*TEMP
    $scope.checkTenantExistingEmail = function () {
        console.log("A2 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
        var q = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.obj.Email + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(response) {
                    $scope.obj.emailCheckExisting = null;
                    console.log("A3 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    if ($scope.obj.Email) {

                        $scope.obj.emailCheckExisting = response.data.data;
                        console.log("A4 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);

                        if($scope.obj.emailCheckExisting.length == 0){
                            console.log("B4b $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            $scope.obj.emailCheckExisting = null;
                        }else{
                            console.log("B4c $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            //$scope.obj.contactNumberCheckExisting = null;

                        }

                    }else{
                        console.log("A5 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                        //$scope.obj.emailCheckExisting = [];
                        console.log("A6 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    }
                    console.log("A7 $scope.checkTenantExistingEmail: " + $scope.obj.emailCheckExisting);
                    q.resolve(response);
                })

        return q.promise;

    }

    $scope.checkTenantExistingContactNumber = function () {
        console.log("B2 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
        var q = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.obj.Contact_number + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(response) {
                    $scope.obj.contactNumberCheckExisting = null;
                    console.log("B3 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                    if ($scope.obj.Contact_number) {
                        $scope.obj.contactNumberCheckExisting = response.data.data;
                        console.log("B4 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting.length);
                        console.log("B4-- $scope.contactNumberCheckExisting: " + response.data.length);
                        console.log("B4--- $scope.contactNumberCheckExisting: " + response.data.data.length);
                        if($scope.obj.contactNumberCheckExisting.length == 0){
                            console.log("B4b $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            $scope.obj.contactNumberCheckExisting = null;
                        }else{
                            console.log("B4c $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                            //$scope.obj.contactNumberCheckExisting = null;

                        }
                    }else{

                        //$scope.obj.contactNumberCheckExisting = [];
                        console.log("B5 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
                    }

                    q.resolve(response);
                })
        console.log("B6 $scope.contactNumberCheckExisting: " + $scope.obj.contactNumberCheckExisting);
        return q.promise;

    }
    END TEMP */


    /*
    $scope.checkTenantExisting = function () {
        var q = $q.defer();

        $scope.myValue = 0;
        $scope.promise = $scope.checkTenantExistingEmail();

        $scope.promise
            .then(function (data) {
                console.log('1');
                return $scope.checkTenantExistingContactNumber(data);
            })
            .then(function (data) {
                console.log('2');
                return $scope.addEditTenantDetails(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })
        q.resolve($scope.promise);
    }
    */
}])