
clouderty.controller("spinnerLoadCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", "$window", "fileDirectoriesService", "notePostService", "dateInputService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService, $window, fileDirectoriesService, notePostService, dateInputService) {

    console.log("inside controller spinnerLoadCtrl");

    console.log("inside watch and scope.spinnervariable is: " + $scope.spinnervariable);
    console.log("inside watch and scope.spinnerobj is: " + $scope.spinnerobj);
    console.log($scope.spinnerobj);
    $scope.spinnerviewvariableCtrl = null;

    $scope.spinnerType = "";



    $scope.$watch('spinnerobjsingleid', function (toVariable, fromVariable){

        if($scope.spinnerobjsingleid > 0){
            console.log("inside watch and scope.spinnerobj is: " + $scope.spinnerobjsingleid);
            console.log($scope.spinnerobjsingleid);
            console.log("inside watch and scope.spinnervariable is: " + $scope.spinnervariable);
            console.log("inside spinnervariable watch and fromVariable is: " + fromVariable);
            console.log("inside spinnervariable watch and toVariable is: " + toVariable);
            $scope.spinnerviewvariableCtrl = 1;
        }
    })

    $scope.$watch('spinnerobj', function (toVariable, fromVariable){

        if($scope.spinnerobj) {
            console.log("inside watch and scope.spinnerobj is: " + $scope.spinnerobjsingleid);
            console.log($scope.spinnerobj);
            console.log("inside watch and scope.spinnervariable is: " + $scope.spinnervariable);
            console.log("inside spinnervariable watch and fromVariable is: " + fromVariable);
            console.log("inside spinnervariable watch and toVariable is: " + toVariable);
            $scope.spinnerviewvariableCtrl = 1;
        }
    })

}])