/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("dateInputService", ["moment", function (moment) {

    this.timeInputService = function(time, number) {

        /*
        console.log("dateInputDayService");
        var date_string = date;
        var dateVariable = new Date(date_string);

        date = new Date(dateVariable.setDate(dateVariable.getDate() + number));
        console.log("dateInputDayService: " + date);
        return date;*/

        var date_string = time;
        var timeHourVariable = new Date(date_string);
        var TimeHour_Final_timeZone = timeHourVariable.getTimezoneOffset();

        console.log("number: " + number);
        //number = number * 60;
        TimeHour_Final_timeZone = TimeHour_Final_timeZone + number;
        console.log("number :" + number);
        //console.log("TimeHour_Final_timeZone: " + TimeHour_Final_timeZone);


        console.log(moment(time, "HH:mm").toDate());
        time = moment(time, "HH:mm").toDate();

        //time = moment(time).add('HH', number);
        /*
        console.log(moment(time).add(60, 'H'));
        console.log(moment(time).add(60, 'HH'));
        console.log(moment(time).add(1, 'H'));
        console.log(moment(time).add(1, 'HH'));
        console.log(moment(time).add(60, 'h'));
        console.log(moment(time).add(60, 'hh'));
        console.log(moment(time).add(1, 'hh'));
        */
        console.log(moment(time).add(number, 'h'));
        time = moment(time).add(number, 'h');
        //time = moment(time).add('HH:mm', 60);
        //time = moment(time).add(TimeHour_Final_timeZone, 'HH');
        console.log("timeInputService service");
        //time = new Date(time.setDate(dateVariable.getDate() + number));

        //time = time.setTime(time.getTime() + (1*60*60*1000));
        console.log(time);
        return time;

    }

    this.dateInputDayService = function(date, number) {

        console.log("dateInputDayService");
        var date_string = date;
        var dateVariable = new Date(date_string);

        date = new Date(dateVariable.setDate(dateVariable.getDate() + number));
        console.log("dateInputDayService: " + date);
        return date;

    }

    this.dateInputMonthService = function(date, number) {
        console.log("dateInputMonthService: "   + date);
        var date_string = date;
        var dateVariable = new Date(date_string);

        //date = new Date(tenancy_start_date.setDate(tenancy_start_date.getDate() + number));
        date = new Date(dateVariable.setMonth(dateVariable.getMonth() + number));

        console.log("dateInputMonthService: "   + date);
        return date;

    }

    this.dateInputYearService = function(date, number) {

        var date_string = date;
        var dateVariable = new Date(date_string);

        //date = new Date(tenancy_start_date.setDate(tenancy_start_date.getDate() + number));
        date = new Date(dateVariable.setYear(dateVariable.getFullYear() + number));

        console.log("dateInputYearService: "   + date);
        return date;

    }

}])
