/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('dynamicSelectInput', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'E',
        scope: {
            ngFieldLevel: '@'
        },
        replace: true,
        template: function ($scope, $element, $attrs) {
            var html = "";
            if ($rootScope.decodedToken.User_Level > $attrs.ngFieldLevel) {
                html = '<select class="form-input">' + $element.html() + '</select>';
            } else if ($rootScope.decodedToken.User_Level == $attrs.ngFieldLevel) {
                html = '<select class="form-input" disabled></select>';
            } else {
                html = '<span></span>';
            }
            return html;
        }
    }
}])