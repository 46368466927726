
clouderty.controller("awardsSurroundCtrl", ["$scope", "$rootScope", "$http", "$stateParams", "$state", "$q", "$window", "$cookies", '$http', 'apiService', "loginExternalWebPageService", "stateAuthCheckService",  function ($scope, $rootScope, $http, $stateParams, $state, $q, $window, $cookies, $http, apiService, loginExternalWebPageService, stateAuthCheckService) {

    console.log("inside controller awardsSurroundCtrl");

    var vm = this;
    $scope.submitText = "Send Email";
    $scope.preview = "./preview.png";
    $rootScope.watchAwardsSurround = 0;

    $("html body").css("overflow-y","auto"); //enables multiple print pages
    //$('.data').hide();

    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {

            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })


    console.log("this.self.name awardsSurroundCtrl >>> " + $state.current.name);

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////


    $scope.loginExternalWebPage = function (){
        console.log("inside function loginExternalWebPage");

        var q5 = $q.defer();
        var deferred = $q.defer();
        var promise = loginExternalWebPageService.init(1);

        promise
            .then(function (data) {

                console.log("A2 getGlobalPromise");
                $rootScope.decode();
                //return $scope.getPaymentTransactionCategoriesAll(data);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A2a loginfunction");

                console.log("this.self.name >>> " + $state.current.name);
                var qstateAuthCheckService = $q.defer();
                stateAuthCheckService.runAuthCheck($state.current.name).then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        qstateAuthCheckService.resolve(response);

                    }
                )

                return qstateAuthCheckService.promise;

            })
            .then(function (data) {

                console.log("A2 getGlobalPromise");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {


                console.log("A9 set watch variable");
                $rootScope.watchAwardsSurround = 1;

                console.log("$rootScope.watchAwardsSurround: " + $rootScope.watchPaymentAddEdit);

            }, function (err) {
                myValue = err;
            })

        q5.resolve(promise);

    }

    $scope.print = function () {
        window.print();
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.accordion = function(element, element_data) {
        console.log("inside function accordion");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
            $('.data').hide();
        }
    }

    $scope.accordionOpen = function(element, element_data) {
        console.log("inside function accordionOpen");


            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");

    }

    $scope.scrollTo = function(element){
        scrollToService.scroll(element);
    }

    ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

    $scope.loginExternalWebPage();

    $scope.accordionOpen('ticket-information', 'ticket-information-data');

    $('.data').hide();

}])