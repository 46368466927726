/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("tenancyAddEditCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$stateParams", "apiService", "dateInputService", "moment", "postUserActivityService", "$q", "randomTextGeneratorService", function ($scope, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $stateParams, apiService, dateInputService, moment, postUserActivityService, $q, randomTextGeneratorService) {

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.obj = {};
    $scope.objBackup = {};
    //$scope.obj.Tenancy_end_date = new moment();
    //$scope.obj.Tenancy_start_date = new Date();
    $scope.tenants = {};
    $scope.tenancy = {};
    $scope.searchTenant = {};
    $scope.searchTenancy = {};
    $scope.monthsAdded = 0;
    $scope.objre = new Date();

    //console.log("State ParamsID: = " + $stateParams.id);

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];

    $('.data').hide();
    $('.accordion-1-form').show();

    $scope.New_Record = false;

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });

    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.searchTenant = function() {
        $scope.tenants = null;

        console.log("apiService.searchTenant");
        apiService.searchTenant($scope.search_tenant, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenants = response.data;
            },
            function() {
                console.log("There was an error apiService.searchTenant")
            }
        )
    }

    $scope.selectTenantAdd = function(id) {
        $scope.obj.selectedTenants = [];
        console.log("Getting tenancy information: selectTenant - tenant id: " + id);
        $http.get("api/tenant/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.data.Tenant_ID,
                "First_Name": response.data.First_Name,
                "Last_Name": response.data.Last_Name,
                "Email": response.Email,
                "Contact_number": response.data.Contact_number,
                "D_O_B": response.data.D_O_B
            })
            $scope.obj.Tenant_ID = response.data.Tenant_ID;
            if (response.Last_Name == null){$scope.obj.Last_Name = " ";} else { };
            //If no last name is present attach blank value to obj scope
            $scope.obj.Full_Name = response.data.First_Name + "" + $scope.obj.Last_Name;
            $scope.obj.Contact_number = response.data.Contact_number;
            $scope.obj.Email = response.data.Email;

            console.log('Tenant id:' + $scope.obj.Tenant_ID);
        })

        $scope.search_tenant = "";

    }


    $scope.selectTenant = function(id) {
        $scope.obj.selectedTenants = [];
        console.log("Getting tenancy information: selectTenant - tenant id: " + $scope.obj.Tenant_ID);
        $http.get("api/tenant/" + $scope.obj.Tenant_ID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.data.Tenant_ID,
                "First_Name": response.data.First_Name,
                "Last_Name": response.data.Last_Name,
                "Email": response.Email,
                "Contact_number": response.data.Contact_number,
                "D_O_B": response.data.D_O_B
            })
            $scope.obj.Tenant_ID = response.data.Tenant_ID;
            if (response.Last_Name == null){$scope.obj.Last_Name = " ";} else { };
            //If no last name is present attach blank value to obj scope
            $scope.obj.Full_Name = response.data.First_Name + "" + $scope.obj.Last_Name;
            $scope.obj.Contact_number = response.data.Contact_number;
            $scope.obj.Email = response.data.Email;

            console.log('Tenant id:' + $scope.obj.Tenant_ID);
        })

        $scope.search_tenant = "";

    }

    $scope.getTenancy = function() {

        //console.log("Getting tenancy information: getTenancy");
        apiService.getTenancy($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                $scope.tenancies = response.data;
                $scope.obj = response.data.data;
                $scope.$parent.objBackup = angular.copy($scope.obj);

                $scope.obj.Tenancy_start_date = dateInputService.dateInputDayService($scope.obj.Tenancy_start_date, 0);
                $scope.obj.Tenancy_end_date = dateInputService.dateInputDayService($scope.obj.Tenancy_end_date, 0);
                $scope.obj.Date_leaving = dateInputService.dateInputDayService($scope.obj.Date_leaving, 0);
                $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);
                console.log("$scope.obj.Tenancy_start_date point 1: " +  $scope.obj.Tenancy_start_date);
                console.log("$scope.obj.Tenancy_start_date point filter: " +  Date($scope.obj.Tenancy_start_date));

                console.log("$scope.obj.Tenancy_end_date point 2: " +  $scope.obj.Tenancy_end_date);

                $scope.selectTenant($scope.obj.Tenant_ID);
                $scope.selectUnit($scope.obj.Unit_ID);


                return $scope.obj;
            },
            function() {
                console.log("There was an error apiService.searchTenant")
            }
        )
    }


    $scope.dateOptions = {
        dateFormat: 'dd/mm/yyyy',
    }

    if ($stateParams.id == null) {
        $scope.obj.selectedTenants = [];
        $scope.obj.selectedUnits = [];
        $scope.New_Record = true;
    }else{
        $scope.New_Record = false;

        $scope.obj = $scope.getTenancy();
    }




    $scope.hideSearch = false;



    $scope.searchUnit = function() {
        $scope.units = null;

        apiService.searchUnits($scope.search_unit, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data.data;
            },
            function() {
                console.log("There was an error apiService.searchUnits")
            }
        )

    }


    $scope.selectUnit = function(id) {
        $scope.obj.selectedUnits = [];

        apiService.getUnit(id, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

        //$http.get("api/unit/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.obj.selectedUnits.push({
                "Unit_ID": response.data.data.Unit_ID,
                "Address": response.data.data.Address_1,
                "Unit_Number": response.data.data.Unit_number,
                "Town": response.data.data.Town,
                "City": response.data.data.City,
                "Postcode": response.data.data.Postcode
            })

            $scope.obj.Address_1 =  response.data.data.Address_1;
            $scope.obj.Property_name =  response.data.data.Property_name;
            $scope.obj.Postcode =  response.data.data.Postcode;
            $scope.obj.Unit_number =  response.data.data.Unit_number;
            $scope.obj.Unit_ID =  response.data.data.Unit_ID;

        //})
            }
        )
        $scope.search_unit = "";
        $scope.hideSearch = true;
    }

    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }

    $scope.addMonths = function(months) {
        $scope.monthsAdded = months;

        console.log("addMonths");

        $scope.obj.Tenancy_end_date = dateInputService.dateInputMonthService($scope.obj.Tenancy_start_date, months);


        $scope.obj.Tenancy_end_date = dateInputService.dateInputDayService($scope.obj.Tenancy_end_date, -1);

    };

    $scope.addTenancy = function() {



        var q5 = $q.defer();
        $scope.myValue = 0;
        //$scope.promise = loginExternalWebPageService.init("1");
        $scope.promise = $scope.getGlobalPromise();


        $scope.promise
            .then(function (data) {

                console.log("A2a");
                $scope.obj.User_ID = $rootScope.decodedToken.id;
                console.log($scope.obj.User_ID);
                $scope.obj.Licence_Key = $scope.generate(50);
                $scope.obj.Password = $scope.generate(10);
                console.log("$scope.obj.Licence_Key: " + $scope.obj.Licence_Key);
                console.log("$scope.obj.Password: " + $scope.obj.Password);





//*
                $scope.obj.Tenancy_start_date = dateInputService.dateInputDayService($scope.obj.Tenancy_start_date, 0);
                $scope.obj.Tenancy_end_date = dateInputService.dateInputDayService($scope.obj.Tenancy_end_date, 0);

                if($scope.obj.Date_leaving){
                    console.log("Date leaving 1: " + $scope.obj.Date_leaving);
                    $scope.obj.Date_leaving = dateInputService.dateInputDayService($scope.obj.Date_leaving, 0);

                }else{

                    console.log("Date leaving 2: null");
                    $scope.obj.Date_leaving = 0;
                }

                if($scope.obj.Date_leaving){
                    console.log("D_O_B 1: " + $scope.obj.Date_leaving);
                    $scope.obj.D_O_B = dateInputService.dateInputDayService($scope.obj.D_O_B, 0);

                    //$scope.obj.D_O_B = new Date("01-12-2018");

                }else{

                    console.log("D_O_B 2: null");
                    $scope.obj.D_O_B = 0;
                }


                console.log("Start Date: " + $scope.obj.Tenancy_start_date);
                console.log("End Date: " + $scope.obj.Tenancy_end_date);


//*/
                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {
                console.log("inside ");








                if ($stateParams.id == null) {//add tenancy

                    if (confirm('Are you sure you want to create a tenancy?')) {

                        $scope.obj.Current = '1';

                        if ($scope.obj.Monthly_rental_price == 0) {
                            confirm('Ensure that you enter a monthly rental amount');
                            return 0;
                        } else if ($scope.obj.Monthly_rental_price == null) {
                            confirm('Ensure that you enter a monthly rental amount');
                            return 0;
                        } else if ($scope.obj.Rent_Day == null) {
                            confirm('Ensure that you enter a tenancy rent day');
                            return 0;
                        } else if ($scope.obj.Rent_Day == "") {
                            confirm('Ensure that you enter a tenancy rent day');
                            return 0;
                        }

                        if (!$scope.obj.DPS_Repayment_ID) {
                            $scope.obj.DPS_Repayment_ID = "";
                        }

                        //console.log('Tenant id 1:' + $scope.obj.Tenancy_ID);
                        //console.log('Tenant id selectedTenant:' + $scope.selectedTenant.Tenant_ID);

                        $http.post('api/tenancy', $scope.obj).success(function (response) {

                            $scope.obj.Tenancy_ID = response.Tenancy_ID;
                            console.log('response.Tenancy_ID:' + response.Tenancy_ID);
                            console.log('Tenancy id 2:' + $scope.obj.Tenancy_ID);

                            apiService.getTenancy($scope.obj.Tenancy_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                                function (response) {
                                    console.log('apiService getTenancy');



                                    $scope.a = [];
                                    $scope.a = response.data.data;

                                    console.log('Tenancy id a:' + $scope.a.Tenancy_ID);
                                    console.log('Tenancy id obj:' + $scope.obj.Tenancy_ID);
                                    var from_email = "Clouderty | Tenancy Created | " + $scope.obj.Property_name + " |" + " <notifications@clouderty.co.uk>";
                                    var to_email = "office@platinumcrown.co.uk";
                                    var subject = $scope.obj.Property_name + " | " + $scope.obj.Full_Name + " | Created by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + $scope.obj.Tenancy_ID;
                                    var message = "Tenant name: " + $scope.obj.Full_Name + "\n\n" +
                                        "Address: " + $scope.obj.Property_name + "\n\n" +

                                        "Unit number: " + $scope.obj.Unit_number + "\n\n" +
                                        "Email: " + $scope.obj.Email + "\n\n" +
                                        "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                                        "Time/Date: " + Date() + "\n\n" +
                                        "Tenancy ID: " + $scope.obj.Tenancy_ID + "\n\n" +
                                        "Created By: " + $rootScope.decodedToken.Name + "\n\n";


                                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                                    $state.go('lettings-main-nav.lettings-side.lettings-view-tenancy.notes', {id: $scope.obj.Tenancy_ID});

                                },
                                function () {
                                    console.log("There was an error")
                                }
                            )

                        });


                    } else {
                        // Do nothing!
                    }

                }
                else {//update tenancy

                    if (confirm('Are you sure you want to update this tenancy?')) {
                        //$scope.obj.Tenancy_start_date = dateInputService.dateInputDayService($scope.obj.Tenancy_start_date, 0);
                        console.log("test1");

                        $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                        $scope.obj.itemsPerPage = $scope.itemsPerPage;
                        $scope.obj.pageno = $scope.pageno;
                        $scope.obj.Tenancy_ID = $stateParams.id;

                        console.log("test2");
                        if ($scope.obj.Monthly_rental_price == 0) {
                            confirm('Ensure that you enter a monthly rental amount');
                            return 0;
                        } else if ($scope.obj.Monthly_rental_price == null) {
                            confirm('Ensure that you enter a monthly rental amount');
                            return 0;
                        } else if ($scope.obj.Rent_Day == null) {
                            confirm('Ensure that you enter a tenancy rent day');
                            return 0;
                        } else if ($scope.obj.Rent_Day == "") {
                            confirm('Ensure that you enter a tenancy rent day');
                            return 0;
                        }
                        console.log("test2");
                        if (!$scope.obj.DPS_Repayment_ID) {
                            $scope.obj.DPS_Repayment_ID = "";
                        }

                        if($scope.obj.Tenancy_ID){
                            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
                        }else{

                            $scope.obj.Tenancy_ID = "*";
                        }

                        if($scope.search){

                            $scope.obj.search = $scope.search;
                        }else{

                            $scope.obj.search = "*";
                        }

                        console.log("test4");

                        /*
                        $scope.obj.Tenancy_start_date = new Date("2018-12-03");
                        console.log($scope.obj.Tenancy_start_date);

                        $scope.obj.Tenancy_end_date = new Date("02-12-2018");
                        console.log($scope.obj.Tenancy_end_date);

                        */
                        //$scope.obj.Date_leaving = new Date();

                        //$scope.obj.Date_leaving = new Date("01-12-2018");

                        //$scope.obj.D_O_B = new Date("01-12-2018");
                        //console.log($scope.obj.Date_leaving);
                        console.log(JSON.stringify($scope.obj));

                        //confirm($scope.obj);
                        //$scope.obj.Tenancy_end_date = dateInputService.dateInputDayService($scope.obj.Tenancy_end_date, 0);
                        //$scope.obj.Date_leaving = dateInputService.dateInputDayService($scope.obj.Date_leaving, 0);


                        $scope.obj.Status = 21;

                        apiService.tenancies($stateParams.id, $scope.obj).then(
                            function(response) {
                                console.log("test5");
                                $scope.$parent.obj = $scope.getTenancy();
                                console.log(response);

                                $state.go('lettings-main-nav.lettings-side.lettings-edit-tenancy', {id:  $stateParams.id, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});

                                //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
                                console.log(response);
                                console.log(response.data);
                                var from_email = "Clouderty | Tenancy Details Updated | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                                var to_email = $rootScope.decodedToken.Notifications_Email;
                                var subject = $scope.obj.First_Name + " | Tenancy Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                                var message = "First name: " + $scope.obj.First_Name + "<br /><br />" +
                                    "Last Name: " + $scope.obj.Last_Name + "<br /><br />" +
                                    "Email: " + $scope.obj.Email + "<br /><br />" +
                                    "DOB: " + $scope.obj.D_O_B + "<br /><br />" +
                                    "Tenancy_start_date: " + $scope.obj.Tenancy_start_date + "<br /><br />" +
                                    "Tenancy_end_date: " + $scope.obj.Tenancy_end_date + "<br /><br />" +
                                    "Date_leaving: " + $scope.obj.Date_leaving + "<br /><br />" +
                                    "Monthly_rental_price: " + $scope.obj.Monthly_rental_price + "<br /><br />" +
                                    "Contact number: " + $scope.obj.Contact_number + "<br /><br /><br /><br />" +
                                    "Time/Date: " + Date() + "<br /><br />" +
                                    "Tenant ID: " + $scope.obj.Tenant_ID + "<br /><br />" +
                                    "Updated By: " + $rootScope.decodedToken.Name + "<br /><br /><br /><br />" +
                                    "PREVIOUS DETAILS: " + "<br /><br />" +
                                    "First name: " + $scope.$parent.objBackup.First_Name + "<br /><br />" +
                                    "Last Name: " + $scope.$parent.objBackup.Last_Name + "<br /><br />" +
                                    "Email: " + $scope.$parent.objBackup.Email + "<br /><br />" +
                                    "DOB: " + $scope.$parent.objBackup.D_O_B + "<br /><br />" +
                                    "Tenancy_start_date: " + $scope.$parent.objBackup.Tenancy_start_date + "<br /><br />" +
                                    "Tenancy_end_date: " + $scope.$parent.objBackup.Tenancy_end_date + "<br /><br />" +
                                    "Date_leaving: " + $scope.$parent.objBackup.Date_leaving + "<br /><br />" +
                                    "Monthly_rental_price: " + $scope.$parent.objBackup.Monthly_rental_price + "<br /><br />" +
                                    "Contact number: " + $scope.$parent.objBackup.Contact_number + "<br /><br /><br /><br />" +
                                    "Tenant ID: " + $scope.$parent.objBackup.Tenant_ID + "<br /><br />";


                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                                $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Tenancy updated by ' + $rootScope.decodedToken.Name + ' - Tenant ID: ' + $scope.$parent.objBackup.Tenant_ID);

                            },
                            function() {
                                console.log("There was an error")
                            }
                        )


                    }

                }

                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {






                console.log('A11');
                $scope.myValue = data;

            }, function (err) {
                $scope.myValue = err;
            })

        q5.resolve($scope.promise);


        return q5.promise;


    }




    $scope.randomPassword = function(length){
        console.log("inside function randomPassword");
        var chars = "abcdefghijklmnopqrstuvwxyz!@#-^ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    }


    $scope.generate = function(value){

        console.log("inside function generate");
        $scope.randomPasswordText = randomTextGeneratorService.generator(value);
        return $scope.randomPasswordText;
    }

}])