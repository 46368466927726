clouderty.service("twillioService", [ '$http', function ($http) {

    'use strict';


    var service = {};


    service.sendMessage = sendMessage;

    return service;

    function sendMessage(user,callback) {
        console.log("inside sendMessage")
        return $http.post('api/twillio-sms', user,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        }).then(function(res){
            callback(res);
        }, function(err){
            callback(null,err);
        });
    }
   /*
     this.sendMessage = function (user,callback) {
        console.log("inside sendMessage")
        return $http.post('api/twillio-sms', user,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        }).then(function(res){
            callback(res);
        }, function(err){
            callback(null,err);
        });
    }
         */
}])
