/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("tenantsPotentialAddCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "apiService", "$q", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, apiService, $q) {

    $scope.search
    $scope.progress = {};
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];

    $scope.tenant_potential = {};


    $scope.tenant_potential.contactNumberCheckExisting = null;
    $scope.tenant_potential.emalCheckExisting = null;

    $scope.hideBusinessSearch = false;

    $scope.unitBlock = { result: false};
    $scope.unitBlock.result = false;

    $scope.New_Record = false;
    $('.data').hide();
    $('.accordion-1-form').show();



    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $(".unitBlock-result").hide();
    //$scope.unitBlock-result = false;

    $(".mobile-notes-button").click(function() {
        $('.content').animate({
            scrollTop: $(".notes-search-section").offset().top
        }, 500);
        $(".mobile-notes-button").hide();
    });

    $('.progress-select').select2({
        minimumResultsForSearch: -1
    });


    $scope.getBusinesses = function () {

        $scope.business = null;
        $http.get('api/businesses-search/' + $scope.search_business).success(function(response) {
            $scope.businesses = response;
        })
    }


    $scope.selectBusiness = function (business_id, business_name, Bank_ref) {
        $scope.search_business = "";
        $scope.hideBusinessSearch = true;
        $scope.selected_business_name = business_name;

        $scope.financial_transaction.Business_ID = business_id;
        console.log("Bank ref: " + Bank_ref);
        $scope.financial_transaction.Business_Bank_ref = Bank_ref;
        console.log("Bank ref: 2" + $scope.financial_transaction.Business_Bank_ref);
    }


    $scope.getPropertiesSearch = function () {
        $scope.properties = null;

        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data;
                $scope.tenant_potential.Property_ID = $scope.properties.Property_ID;
                console.log("in getPropertiesSearch");

            },
            function() {
                console.log("We are in getPropertiesSearch");
            }
        )


    }

    $scope.getProperties = function () {

        apiService.getProperty($scope.tenant_potential.Property_ID, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.units = response.data.Units;
                $scope.tenant_potential.units  = response.data.Units;
                //$scope.tenant_potential.Unit_number = $scope.units[0].Unit_number;
                //$scope.tenant_potential.Unit_ID = $scope.units[0].Unit_ID;


                console.log("in getProperty");

            },
            function() {
                console.log("We are in getProperty");
            }
        )


    }

    $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {
        $scope.search_property = "";
        $scope.hidePropertySearch = true;
        $scope.properties.Property_name = property_name;
        $scope.tenant_potential.Property_ID = property_id;
        //$scope.financial_transaction.selected_property_name = property_name;
        //$scope.financial_transaction.Property_name = property_name;
        //$scope.financial_transaction.Address_1 = address_1;
        //$scope.financial_transaction.Bank_ref = bank_ref;
        //console.log("Bank_Ref: " + $scope.financial_transaction.Bank_ref);
        //$scope.financial_transaction.Property_ID = property_id;
        $scope.getProperties();
    }

    $scope.getUnit = function () {
        //$scope.tenant_potential.Unit_number = $scope.tenant_potential.units.Unit_number;
        $scope.tenant_potential.Unit_ID = $scope.tenant_potentials.units.Unit_ID;
        $scope.tenant_potential.Unit_number = $scope.tenant_potentials.units.Unit_number;
        console.log("Unit_number 2: " + $scope.tenant_potential.Unit_number);
        console.log("Unit_ID 2: " + $scope.tenant_potential.Unit_ID);
    }


    $scope.addTenantPotentialDetails = function() {


        $scope.a =  {};
        console.log("IN start" + $scope.tenant_potential.Accommodation_Type);
        //$scope.a = Transaction;
        //console.log("addTransaction - Test 1 - Payment Type: " + Payment_Type);



        //$scope.b = [];
        //console.log("addTransaction - Test 2 - tenancyid = " + $stateParams.id);
        //$scope.b = response;
        $scope.a.Accommodation_Type = $scope.tenant_potential.Accommodation_Type;

        $scope.a.First_Name = $scope.tenant_potential.First_Name;
        $scope.a.Surname = $scope.tenant_potential.Surname;

        $scope.a.Contact_number = $scope.tenant_potential.Contact_number;
        $scope.a.Email = $scope.tenant_potential.Email;
        $scope.a.Date_Added = $scope.tenant_potential.Date_Added;
        $scope.a.Budget = $scope.tenant_potential.Budget;
        $scope.a.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID
        $scope.a.Area = $scope.tenant_potential.Area;
        $scope.a.Room_Size = $scope.tenant_potential.Room_Size;
        $scope.a.Furnished = $scope.tenant_potential.Furnished;
        $scope.a.Lead_Source = $scope.tenant_potential.Lead_Source;
        $scope.a.Occupation = $scope.tenant_potential.Occupation;
        $scope.a.Notes = $scope.tenant_potential.Notes;
        $scope.a.Property_Type = $scope.tenant_potential.Property_Type;
        $scope.a.Property_ID = $scope.tenant_potential.Property_ID;
        $scope.a.Unit_ID = $scope.tenant_potential.Unit_ID;
        $scope.a.Contacted_by_User = $scope.tenant_potential.Contacted_by_User;
        $scope.a.Status = $scope.tenant_potential.Status;
        $scope.a.Viewing_Date = $scope.tenant_potential.Viewing_Date;
        $scope.a.Accommodation_Type = $scope.tenant_potential.Accommodation_Type;
        $scope.a.Room_Type = $scope.tenant_potential.Room_Type;
        $scope.a.Viewing_Time = $scope.tenant_potential.Viewing_Time;

        //$scope.a.Viewing_Time = $filter('date')($scope.tenant_potential.Viewing_Time,'HH:mm:ss Z');

        if (confirm('Are you sure you want to add a potential tenant?')) {

            $http.post('api/tenants_potential_add', $scope.a).success(function(response) {
                //console.log("Cash successful - Test 3" + response);
                //console.log("IN tenants_potential_add");
                q.resolve(response);
            });


            //console.log("Payment type: " + $scope.financial_transaction.Transaction_Type);

            var from_email = "Clouderty | Potential Tenant Added | " + $scope.tenant_potential.First_Name + " | " + $scope.tenant_potential.Contact_number + " <notifications@clouderty.co.uk>";
            var subject = "Clouderty | Potential Tenant Added | " + $scope.tenant_potential.First_Name + " | " + $rootScope.decodedToken.Name + " | " + $scope.tenant_potential.Contact_number;
            var message = "Status: " + $scope.tenant_potential.Status + "\n\n" +
                "Potential tenant added by: " + $rootScope.decodedToken.Name + "\n\n" +
                //"Tenant Details" + "\n" +
                //"Name: " + $scope.obj.data.Full_name + "\n" +
                //"Email: " + $scope.obj.data.Email + "\n" +
                //"Address: " + $scope.obj.data.Address_1  + " Unit " + $scope.obj.data.Unit_number + "\n" +
                "Contact number: " + $scope.tenant_potential.Contact_number + "\n" +
                "Email: " + $scope.tenant_potential.Email + "\n\n" +
                "Budget:" + " " + $filter('currency')($scope.tenant_potential.Budget, '£') + "\n" +
                "Date added:" + " " + $scope.tenant_potential.Date_Added + "\n" +
                "Notes: " + $scope.tenant_potential.Notes + "\n\n";

            var to_email = $rootScope.decodedToken.Notifications_Email;

            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
            $state.reload()

        }
        else{

        }

    }
    $scope.addTenantPotential = function() {

        var q = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.checkTenantExisting();

        $scope.promise
            .then(function (data) {
                console.log('4');
                return $scope.checkTenantExistingEmail(data);
            })
            .then(function (data) {
                console.log('5');
                if(!$scope.tenant_potential.emalCheckExisting){

                    return $scope.addTenantPotentialDetails(data);
                }else{
                    alert('Tenant already exists so will not be added. Email.');
                    return "";
                }
            })
            .then(function (data) {
                console.log('5');
                if(!$scope.tenant_potential.contactNumberCheckExisting){

                    return $scope.addTenantPotentialDetails(data);
                }else{
                    alert('Tenant already exists so will not be added. Contact number.');
                    return "";
                }
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })
        q.resolve($scope.promise);
    }


    $scope.checkTenantExistingEmail = function () {

        var q = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.tenant_potential.Email + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(data) {

                    if ($scope.tenant_potential.Email) {

                        $scope.tenant_potential.emalCheckExisting = data.data.data;
                    }else{

                        $scope.tenant_potential.emalCheckExisting = null;
                    }
                    q.resolve(data);
                })

        return q.promise;

    }

    $scope.checkTenantExistingContactNumber = function () {

        var q = $q.defer();

        $http.get("api/tenants_potential_search/2000/1/" + $scope.tenant_potential.Contact_number + "/" + $rootScope.decodedToken.Account_Holder_ID)
            .then(
                function(response) {

                    if ($scope.tenant_potential.Contact_number) {
                        $scope.tenant_potential.contactNumberCheckExisting = response.data.data
                    }else{

                        $scope.tenant_potential.contactNumberCheckExisting = null;
                    }

                    q.resolve(response);
                })

        return q.promise;

    }

    $scope.checkTenantExisting = function () {
        var q = $q.defer();

        $scope.myValue = 0;
        $scope.promise = $scope.checkTenantExistingEmail();

        $scope.promise
            .then(function (data) {
                console.log('1');
                 return $scope.checkTenantExistingEmail(data);
            })
            .then(function (data) {
                console.log('2');
                return $scope.checkTenantExistingContactNumber(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })
        q.resolve($scope.promise);
        return q.promise;
    }



 


}]);