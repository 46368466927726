/**
 * Created by Aaron on 08/07/2016.
 */

clouderty.controller("certificatesElectricSearchCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "$filter", "Upload", "emailService", "emailService",  "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", function($scope, $http, $stateParams, $state, $uibModal, filter,  Upload, emailService,  postNoteService, sendEmailInternalPostNote, $rootScope, $window) {



    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("going through upload");
    $scope.certificatesElectric = [];
    // page to start on
    $scope.pageno = 1;

    // reset record counter
    $scope.totalCount = 0;

    // amount of records per page
    $scope.itemsPerPage = 20;
    console.log("Next 2");
    $scope.getData = function(pageno) {
        console.log("Next 3");

        $http.get("api/lettings/certificate-electric/" + $scope.itemsPerPage + "/" + pageno).success(function (response) {
            console.log("Next 4");
            $scope.certificatesElectric = response;
            $scope.currentPage = pageno;
            $scope.totalCount = response.totalCount;
            //$scope.sort('ID');
            console.log('Total count response: ' + response.totalCount);


        })

    }




    // activate record selector function
    $scope.getData($scope.pageno);
    console.log("Next 5");
    // sort function
    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
        console.log("Next 6");
    }
    console.log("Next 7");



}])