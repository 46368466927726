/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("scrollToService", ["$http", "$q", "fCsv", "$timeout", function ($http, $q, fCsv, $timeout) {


    this.scroll = function(element) {


        console.log('inside service scrollToService - element: ' + element);
        var elmnt = document.getElementById(element);
        elmnt.scrollIntoView();

        return "";

    }

}])
