/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("tenantsRentExpectedCtrl", ["$scope", "$http", "$stateParams", "$filter", "Upload", "$rootScope", "$state", function ($scope, $http, $stateParams, filter, Upload, $rootScope, $state)  {



    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });

    $scope.tenantsRentalTransactions = [];
    // page to start on
    $scope.pageno = 1;

    // reset record counter
    $scope.totalCount = 0;


    // amount of records per page
    $scope.itemsPerPage = 20;


    $scope.getData = function(pageno) {


        $scope.tenantsRentExpected = [];

        if ($scope.search)
        {
            console.log('Scope 1:' + $scope.search);
            $http.get("api/tenants-rent-expected/" + $scope.itemsPerPage + "/" + pageno).success(function (response) {
            //$http.get("api/tenants-rent-expected/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function (response) {
                $scope.tenantsRentExpected = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                console.log('totalCount: ' + response.totalCount);
                console.log('Tenants rental transactions - with search');
            })
        }
        else
        {
            $http.get("api/tenants-rent-expected/" + $scope.itemsPerPage + "/" + pageno).success(function(response){
                console.log('Tenants rental transactions - no search');
                $scope.tenantsRentExpected = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;


            });
        }
    }

    console.log("Initiate getData");
    $scope.getData($scope.pageno);

    /* activate record selector function determined by if tenancy id present
    //if ($stateParams.id = 'undefined') {
    if ($state.is('lettings-main-nav.lettings-side.tenants-rental-transactions-search')) {
     console.log('Tenancy ID 1');
        $scope.getData($scope.pageno);
        //$scope.getDataTenancies($scope.pageno);
    }
    else {
     console.log('Tenancy ID 2: $stateParams.id');
        $scope.getDataTenancies($scope.pageno);
    }
    */
    // sort function
    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
        console.log("Next 6");
    }


}])