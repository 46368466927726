/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("processAccountAddEditCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "dateInputService", "moment", "scrollToService", "scrollToService", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, dateInputService, moment, scrollToService) {

    console.log("inside processAccountAddEditCtrl")

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.search = ""

    $scope.New_Record = "";
    $scope.pageBlock = '1';
    $scope.progress = {};
    $scope.pageBlock = '0';
    $scope.testBlock = '5';
    $scope.counter = '0';
    $scope.message = "";
    $rootScope.pageBlockSpinner = 0;

    $scope.business = {};
    $scope.hideBusinessSearch = false;
    $scope.properties = [];
    $scope.units = [];
    $scope.unitText = "";
    $scope.unitBlock = {result: false};
    $scope.unitBlock.result = false;
    $scope.tenancies = [];
    $scope.property = {};
    $scope.property.Status = 0;

    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;


    $scope.propertiesInput = {};
    $scope.search_property = "";
    $scope.hidePropertySearch = 0;
    $scope.hidePropertySearchEncase = 1;
    $scope.propertyAddBlock = 0;
    $scope.propertyTypes = {};
    $scope.processesAccountData = {};

    $scope.processes_account = {};
    $scope.processes_account.tenancies = {};
    $scope.customers = {};
    $scope.awardsData = {};
    $scope.awardCategories = {};
    $scope.awards = {};
    $scope.awards.awardsNominations = {};

    $scope.payment_transaction = {};
    $scope.payment_transaction.selectedCustomers = [];
    $scope.payment_transaction.selectedCustomers.Units = [];
    $scope.search_customer = "";
    $scope.search_customer_awards = "";

    console.log("$stateParams.processAccountID: " + $stateParams.processAccountID);

    if (!$stateParams.processAccountID) {
        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
        console.log("$stateParams.processAccountID == null");

        $scope.New_Record = true;
    }

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.processesAccountFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        if(!$scope.processes_account.Scheduled_Completion_Date){

        }else{
            if(status == 4){
                $scope.processesAccountData.Scheduled_Completion_Date = dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0);
                //$scope.processesAccountData.Scheduled_Completion_Date = $scope.processes_account.Scheduled_Completion_Date;
                console.log($scope.processesAccountData.Scheduled_Completion_Date);
                console.log(dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0));
                //$scope.processesAccountData.Scheduled_Completion_Date = "2018-01-01";

            }else{

                $scope.processesAccountData.Scheduled_Completion_Date = dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0);
                console.log($scope.processesAccountData.Scheduled_Completion_Date);
            }

        }


        if(!$scope.processes_account.Process_Type_ID){

        }else{
            $scope.processesAccountData.Process_Type_ID = $scope.processes_account.Process_Type_ID;
        }

        if(!$scope.processes_account.Property_ID){

        }else{

            $scope.processesAccountData.Property_ID = $scope.processes_account.Property_ID;

        }

        if(!$scope.processes_account.Unit_ID){

        }else{

            $scope.processesAccountData.Unit_ID = $scope.processes_account.Unit_ID;

        }

        if(!$scope.processes_account.Tenancy_ID){

        }else{

            $scope.processesAccountData.Tenancy_ID = $scope.processes_account.Tenancy_ID;

        }

        if(!$scope.processes_account.Customer_ID){

        }else{

            $scope.processesAccountData.Customer_ID = $scope.processes_account.Customer_ID;

        }

        if(!$scope.processes_account.Debt_Account){

        }else{

            $scope.processesAccountData.Debt_Account = $scope.processes_account.Debt_Account;

        }

        if(!$scope.processes_account.Maintenance_Account){

        }else{

            $scope.processesAccountData.Maintenance_Account = $scope.processes_account.Maintenance_Account;

        }

        console.log($rootScope.decodedToken.Clouderty_System_Administrator);
        $scope.processesAccountData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.processesAccountData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.processesAccountData.itemsPerPage = $scope.itemsPerPage;
        $scope.processesAccountData.pageno = $scope.pageno;



        $scope.processesAccountData.User_ID = $rootScope.decodedToken.id;
        $scope.processesAccountData.Status = status;
        $scope.processesAccountData.Procress_ID = id;

        if($scope.processesAccountData.Procress_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            $scope.processesAccountData.Procress_ID = "*";
        }

        if($scope.search){

            $scope.processesAccountData.search = $scope.search;
        }
        else{

            $scope.processesAccountData.search = "*";
        }

        var qprocessesAccountFunction2 = $q.defer();

        console.log($scope.processesAccountData);
        apiService.processes(id, $scope.processesAccountData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                $scope.processData = response.data.data;

                console.log("status: " + status);
                if(status == 4){

                    $scope.processAccount = response.data.data;
                    $scope.processAccountID = response.data.Process_Account_ID;
                    $scope.processAccountAdded = '1';
                    console.log("Process added");
                    console.log(response);

                }
                if(status == 63){

                    $scope.processTypes = response.data.processTypes;
                }

                console.log("processes API successful");

                console.log(response);
                qprocessesAccountFunction2.resolve(response);


            },
            function () {
                console.log("API Service Error: processes")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qprocessesAccountFunction2.promise;
    }

    $scope.customersFunction = function (id, status) {
        console.log("function customersFunction");

        $scope.paymentTransactionsData = $scope.payment_transaction;
        $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.paymentTransactionsData.Status = status;
        $scope.paymentTransactionsData.search = $scope.search_customer;
        $scope.paymentTransactionsData.pageno = 1;
        $scope.paymentTransactionsData.itemsPerPage = 100;

        if(status == 5){
            console.log("status should be 5: >>>> " + status);

            $scope.paymentTransactionsData.Customer_ID = id;

        }

        var q21 = $q.defer();
        apiService.customers(id, $scope.paymentTransactionsData).then(
            function (response) {
                $scope.customers = response.data.data;

                if(status == 5){

                    $scope.processesAccountData.Customer_ID = $scope.customers.Customer_ID;

                }
                console.log($scope.customers);
                console.log("API Service: customers");
                q21.resolve(response);
            },
            function () {
                console.log("There was an error in api service customers");
            }
        )
        //});
        return q21.promise;
    }

    $scope.awardsFunction = function(id, status) {

        console.log("status >>>> " + status);
        var qawardsFunction = $q.defer();

        console.log("function processesAccountFunction");
        console.log("$scope.pageno" + $scope.pageno);

        $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.awardsData.id = $rootScope.decodedToken.id;
        $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
        $scope.awardsData.pageno = $scope.pageno;
        $scope.awardsData.Status = status;
        $scope.awardsData.Process_ID = id;


        if($scope.search){

            $scope.awardsData.search = $scope.search;

        }
        else{

            $scope.awardsData.search = "*";

        }

        apiService.awards(id, $scope.awardsData).then(
            function (response) {
                console.log("apiService awards service");

                //$scope.awardsData.Awards_Category_Questions_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                if(status == 60){
                    console.log(response);
                    console.log("apiService awards service successful status 60");
                    $scope.awardCategories = response.data.awardsCategories;

                }
                else if(status == 12){
                    console.log(response);
                    console.log("apiService awards service successful status 12");
                    $scope.awards.awardsNominationValidation = response.data.data;
                }
                else if(status == 70){
                    console.log(response);
                    console.log("apiService awards service successful status 70");
                    $scope.awards.awardsNominations = response.data.awardsNominations;
                    console.log($scope.awards.awardsNominations);
                }
                else if(status == 71){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNomination = response.data.awardsNomination;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }
                else if(status == 72){
                    console.log(response);
                    console.log("apiService awards service successful status 71");
                    $scope.awards.awardsNominationRequest = response.data.awardsNominationRequest;
                    /*
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                    */
                }
                else if(status == 64){

                    console.log(response);
                    console.log("apiService awards service successful status 64");
                    $scope.awards.awardNominationRequest.First_Name = "";
                    $scope.awards.awardNominationRequest.Last_Name = "";
                    $scope.awards.awardNominationRequest.Email = "";
                    $scope.awardNominations = response.data.data;
                    $scope.nominatorsDetailsBloc = 1;
                    $scope.nomineesDetailsVisibleBloc = 0;

                }
                else if(status == 641){
                    console.log(response);
                    console.log("apiService awards service successful status 641");
                    $scope.awards.awardNominator.First_Name = "";
                    $scope.awards.awardNominator.Last_Name = "";
                    $scope.awards.awardNominator.Email = "";
                    $scope.awardNominator = response.data.data;
                    $scope.nominationSubmittedBlock = 1;

                }
                else if(status == 80){//update awards question answer
                    console.log(response);
                    console.log("apiService awards service successful status 70");
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                }//update awards question answer
                else if(status == 81){//update property details question completed
                    console.log("apiService awards service successful status 81");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;
                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                }//update property details question completed
                else if(status == 82){//update awards question completed
                    console.log("apiService awards service successful status 82");

                    console.log(response);

                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards question completed
                else if(status == 83){//update property details question answer
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update property details question answer
                else if(status == 84){//update awards files displayed
                    console.log("apiService awards service successful status 83");

                    console.log(response);

                    $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                    $scope.awards.awardsQuestions = response.data.awardsQuestions;

                }//update awards files displayed
                console.log("awards API successful");

                console.log(response);

                qawardsFunction.resolve(response);


            },
            function () {

                console.log("API Service Error: awards");

            }

        );

        return qawardsFunction.promise;

    }

    $rootScope.paymentResultText = "";

    $scope.getProcessTypes = function () {
        console.log("$scope.getPaymentTransactionTypes");
        var qgetProcessTypes41 = $q.defer();


        qgetProcessTypes41.resolve($scope.processesAccountFunction("*", 63));

        return qgetProcessTypes41.promise;
    }

    $scope.getProcessType = function (processType) {
        console.log("inside function getProcessType");
        $scope.processes_account.Debt_Account = processType.Debt_Account;
        $scope.processes_account.Maintenance_Account = processType.Maintenance_Account;
        $scope.processes_account.Process_Type_ID = processType.Process_Type_ID;
        $scope.processes_account.Description = processType.Description;
        $scope.processes_account.Awards = processType.Awards;
        console.log("$scope.processes_account.Process_Type_ID: " + $scope.processes_account.Process_Type_ID);
        console.log("$scope.processes_account.Debt_Account: " + $scope.processes_account.Debt_Account);
        console.log("$scope.processes_account.Maintenance_Account: " + $scope.processes_account.Maintenance_Account);
        console.log("$scope.processes_account.Description: " + $scope.processes_account.Description);


    }

    $scope.getCustomers = function () {
        console.log("We are in in getCustomers");
        $scope.customersFunction($scope.paymentTransactionsData.Customer_ID, 5);

    }

    $scope.searchCustomer = function (search_customer) {
        $scope.search_customer = search_customer;
        $scope.customers = null;
        console.log("searchCustomer function search_customer: " + search_customer);
        console.log("searchCustomer function $scope.search_customer: " + $scope.search_customer);
        $scope.customersFunction("*", 61);
    }

    $scope.selectCustomer = function (customer_id, first_name, surname) {
        console.log("selectProperty in");
        $scope.customersFunction(customer_id, 5);
        //$http.get("api/customers/" + customer_id).success(function (response) {
        $scope.payment_transaction.selectedCustomers.push({
            "Customer_ID": customer_id,
            "First_name": first_name,
            "Surname": surname
        })

        //console.log("$scope.payment_transaction.Customer_ID: " + $scope.payment_transaction.Customer_ID);

        //$scope.getCustomers();

        $scope.search_customer = "";
        $scope.hideCustomerSearch = true;
    }

    $scope.deselectCustomer = function (index) {
        $scope.payment_transaction.selectedCustomers.splice(index, 1);
        $scope.hideCustomerSearch = false;
        //$scope.units = [];
        $scope.customers = {};
    }

    $scope.getCustomersSearch = function () {
        $scope.customers = null;
        console.log("$scope.getCustomersSearch");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.customers = response.data;
            },
            function () {
                console.log("There was an error")
            }
        )
    }

    $scope.getAwardNomination = function (dataObj) {
        console.log("inside function getPropertyType");

        console.log("dataObj.Awards_Nomination_ID: " + dataObj.Awards_Nomination_ID);
        console.log(dataObj);
        $scope.processesAccountData.Awards_Nomination_ID = dataObj.Awards_Nomination_ID;


    }

    //////////////////////////  searchProperty  /////////////////////////////////////////////////////////////////////////////////

    $scope.propertiesFunction = function(id, status) {

        $scope.propertiesInput.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.propertiesInput.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.propertiesInput.itemsPerPage = $scope.itemsPerPage;
        $scope.propertiesInput.pageno = $scope.pageno;
        $scope.propertiesInput.Property_ID = id;
        $scope.propertiesInput.Estate_ID = '0';
        $scope.propertiesInput.User_ID = $rootScope.decodedToken.id;
        //$scope.propertiesInput.Property_ID = '0';
        $scope.propertiesInput.Status = status;

        if($scope.propertiesInput.search){

        }else if($scope.search){

            $scope.propertiesInput.search = $scope.search;
        }else{

            $scope.propertiesInput.search = "*";
        }

        //$scope.obj.search = "*";

        console.log("$scope.propertiesInput >>>>>>>>>>>>>>>>>");
        console.log($scope.propertiesInput);

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.properties(id, $scope.propertiesInput).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                    //$scope.$parent.obj2 = {};
                    $scope.properties = response.data.data;

                console.log(response);
                console.log("tenancies API");

            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.displayAddPropertyBlock = function(dataObj) {
        console.log("inside function displayAddPropertyBlock");

        $scope.propertyAddBlock = 1;

    }

    $scope.addProperty = function(dataObj) {
        console.log("inside function addProperty");

        $scope.propertyAddBlock = 1;

        if ($stateParams.id == null) {
            if (confirm('Are you sure you want to this property?')) {



                $scope.property.Status = 4;
                $scope.property.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.property.User_ID = $rootScope.decodedToken.id;
                $scope.property.Date_Added = new Date();
                $scope.property.Property_name = $scope.property.Address_1 + " " + $scope.property.Property_Number ;

                apiService.properties('*', $scope.property).then(
                    function (response) {

                        $scope.a = response;
                        console.log($scope.a);
                        $scope.property.Property_ID = response.Property_ID

                        //////////////////////////////////////////////////////////////////////////
                        /*
                        console.log('Tenant id obj:' + $scope.obj.Tenant_ID);

                        var from_email = "Clouderty | Tenant Added | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = "office@platinumcrown.co.uk";
                        var subject = $scope.obj.First_Name + " | Created by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "Tenant name: " + $scope.obj.First_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.obj.Tenant_ID + "\n\n" +
                            "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                        //console.log('Tenancy end date' + $scope.obj.Tenancy_ID);
                        $state.go('lettings-main-nav.lettings-side.lettings-add-tenancy');

                        */

                    },
                    function () {
                        console.log("There was an error in addProperty")
                    }
                )

            }
            else {

            }
        }
        /*
        else {
            if (confirm('Are you sure you want to update this tenant?')) {


                //var myJSON = JSON.stringify($scope.obj);

                //$scope.obj.D_O_B = new Date($scope.$parent.obj.D_O_B);
                $scope.tenantsDetailsPreUpdate2 = [];
                //$scope.tenantsDetails = [];
                $scope.tenantsDetailsPreUpdate2 = $scope.tenantsDetailsPreUpdate;
                //$scope.tenantsDetailsPreUpdate = $scope.obj;



                apiService.updateTenant($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $scope.obj).then(
                    function(response) {

                        $scope.$parent.obj = $scope.searchProperty();
                        console.log(response);
                        //console.log(response.data);
                        //console.log(response.data.data);
                        //console.log($scope.obj.D_O_B );

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);

                        $state.reload("lettings-main-nav.lettings-side.lettings-edit-tenant", {id: $stateParams.id});

                        //$scope.obj.D_O_B = new Date($scope.obj.D_O_B);
                        console.log(response);
                        console.log(response.data);
                        var from_email = "Clouderty | Tenant Details Updated | " + $scope.obj.First_Name + " <notifications@clouderty.co.uk>";
                        var to_email = "office@platinumcrown.co.uk";
                        var subject = $scope.obj.First_Name + " | Tenant Details Updated by: " + $rootScope.decodedToken.Name + " | Tenant ID: " + $scope.obj.Tenant_ID;
                        var message = "First name: " + $scope.obj.First_Name + "\n\n" +
                            "Last Name: " + $scope.obj.Last_Name + "\n\n" +
                            "Email: " + $scope.obj.Email + "\n\n" +
                            "DOB: " + $scope.obj.D_O_B + "\n\n" +
                            "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                            "Time/Date: " + Date() + "\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n" +
                            "Updated By: " + $rootScope.decodedToken.Name + "\n\n\n\n" +
                            "PREVIOUS DETAILS: " + "\n\n" +
                            "First name: " + $scope.tenantsDetailsPreUpdate2.First_Name + "\n\n" +
                            "Last Name: " + $scope.tenantsDetailsPreUpdate2.Last_Name + "\n\n" +
                            "Email: " + $scope.tenantsDetailsPreUpdate2.Email + "\n\n" +
                            "DOB: " + $scope.tenantsDetailsPreUpdate.D_O_B + "\n\n" +
                            "Contact number: " + $scope.tenantsDetailsPreUpdate.Contact_number + "\n\n\n\n" +
                            "Tenant ID: " + $scope.tenantsDetailsPreUpdate.Tenant_ID + "\n\n";


                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    },
                    function() {
                        alert("There was an error")
                    }
                )
            }

        }
        */

    }

    $scope.getPropertyTypes = function () {
        console.log("We are in in getPropertyTypes");

        var qGetPropertyTypes = $q.defer();

        $scope.property.Status = 15;
        $scope.property.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.property.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.property.User_ID = $rootScope.decodedToken.id;
        $scope.property.Date_Added = new Date();

        apiService.properties('*', $scope.property).then(
            function (response) {

                $scope.propertyTypes = response.data.data;
                qGetPropertyTypes.resolve(response);
                console.log("response: " + response);

                return response;
            },
            function () {
                console.log("There was an error in getPropertyTypes")
            }
        )

        return qGetPropertyTypes.promise;
    }

    $scope.getPropertyType = function (dataObj) {
        console.log("inside function getPropertyType");

        console.log("dataObj.Property_Type_ID: " + dataObj.Property_Type_ID);
        console.log("dataObj.Property_Type_ID: " + dataObj.Property_Type);
        $scope.property.Property_Type_ID = dataObj.Property_Type_ID;


    }

    $scope.searchProperty = function (search_property) {
        //$scope.properties = null;
        console.log("searchProperty in");
        $scope.properties = [];
        $scope.hidePropertySearch = 0;
        $scope.hidePropertySearchEncase = 0;

        $scope.propertiesInput.search = search_property;
        console.log("$scope.search_property: " + search_property);

        console.log("$scope.search_property: " + $scope.search_property);
        $scope.propertiesFunction('*', 94);

    }

    $scope.processes_account.selectedProperties = [];

    $scope.processes_account.selectedProperties.Units = [];

    $scope.selectPropertyEdit = function(id) {
        console.log("selectProperty in");
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzz");
        apiService.getProperty(id, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                //$http.get("api/property/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                $scope.processes_account.selectedProperties.push({
                    "Property_ID": response.data.data.Property_ID,
                    "Address": response.data.data.Address_1,
                    "Town": response.data.data.Town,
                    "City": response.data.data.City,
                    "Postcode": response.data.data.Postcode
                })
                $scope.processes_account.Address_1 = response.data.data.Address_1;
                $scope.processes_account.Property_name = response.data.data.Property_name;
                $scope.processes_account.Postcode = response.data.data.Postcode;
                $scope.processes_account.Unit_number = response.data.data.Unit_number;
                $scope.processes_account.Unit_ID = response.data.data.Unit_ID;
                angular.forEach(response.Units, function (unit, index) {
                    $scope.processes_account.selectedProperties.Units.push({
                        "Unit_ID": unit.Unit_ID,
                        "Unit_Number": unit.Unit_number
                    })
                })
            },
            function () {
                console.log("There was an error in getProperties")
            }
        )
    }

    $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {

        delete $scope.search_property;
        $scope.hidePropertySearch = 1;
        $scope.hidePropertySearchEncase = 1;

        var qselectProperty23 = $q.defer();
        console.log("selectProperty in property_id: >>>>>>>> " + property_id);
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzzbbbbbbbbbbbbbbbbbbbb");
        $http.get("api/property/" + property_id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.processes_account.selectedProperties.push({
                "Property_ID": response.data.Property_ID,
                "Address": response.data.Address_1,
                "Town": response.data.Town,
                "City": response.data.City,
                "Postcode": response.data.Postcode
            })
            angular.forEach(response.Units, function (unit, index) {
                $scope.processes_account.selectedProperties.Units.push({
                    "Unit_ID": unit.Unit_ID,
                    "Unit_Number": unit.Unit_number
                })
            })
            console.log(response);
            console.log(response.data);

            $scope.processes_account.Property_ID = property_id;
            $scope.selected_property_name = property_name;
            $scope.processes_account.selected_property_name = property_name;
            $scope.processes_account.Property_name = property_name;
            $scope.processes_account.Address_1 = address_1;
            $scope.processes_account.Bank_ref = bank_ref;
            //$rootScope.property.Property_name = property_name;
            //$scope.createPaymentIDPaymentTypeProperty();

            console.log("$scope.processes_account.Property_ID: " + $scope.processes_account.Property_ID);

            $scope.getProperties();

            qselectProperty23.resolve(response);

        })



        return qselectProperty23.promise;


    }

    $scope.deselectProperty = function (index) {
        $scope.processes_account.selectedProperties.splice(index, 1);
        $scope.hidePropertySearch = false;
        $scope.units = [];
        $scope.properties = [];
    }

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;
        console.log("$scope.getPropertiesSearch");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.properties = response.data;
            },
            function () {
                console.log("There was an error")
            }
        )
    }

    $scope.getProperties = function () {
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmm");
        if($scope.processes_account.Property_ID){
            console.log("We are in in getProperties if statement");
            apiService.getProperty($scope.processes_account.Property_ID, $rootScope.decodedToken.Account_Holder_ID).then(
                function (response) {
                    $scope.units = response.data.units;
                    //$scope.processes_account.Unit_number = response.data.units.Unit_number;
                    //$rootScope.property.Unit_number = response.data.units.Unit_number;
                    console.log("$scope.units: " + $scope.units);
                    console.log("$scope.processes_account.Unit_number: " + $scope.processes_account.Unit_number);

                    return response;
                },
                function () {
                    console.log("There was an error in getProperties")
                }
            )

        }

    }
    
    //////////////////////////  Units  /////////////////////////////////////////////////////////////////////////////////

    $scope.getUnit = function () {
        $scope.processes_account.Unit_number = $scope.processes_account.units.Unit_number;
        console.log("Unit_ID: " + $scope.processes_account.units.Unit_ID);

        apiService.getTenanciesUnit($scope.processes_account.units.Unit_ID, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.tenancies = response.data;
                $scope.processes_account.tenancies = response.data;
                $scope.processes_account.Unit_number = $scope.processes_account.tenancies.Unit_number;

                console.log(response.data);

                console.log("$scope.processes_account.Unit_number: " + $scope.processes_account.Unit_number);

                //if(!$scope.processes_account.Unit_number){
                //$scope.unitText = "";
                //}else{
                $scope.unitText = " U " + $scope.processes_account.Unit_number;
                //}

                console.log($scope.tenancies.data);
            },
            function () {
                console.log("There was an error in getTenanciesUnit")
            }
        )


    }

    //////////////////////////  Tenancies  /////////////////////////////////////////////////////////////////////////////////

    $scope.getTenancy = function () {

        $scope.processes_account.tenantNameText = "";
        $scope.processes_account.Customer_ID = $scope.processes_account.tenancies.Customer_ID;
        $scope.processes_account.Tenancy_ID = $scope.processes_account.tenancies.Tenancy_ID;

        $scope.processes_account.First_Name = $scope.processes_account.tenancies.First_Name;
        $scope.processes_account.tenantNameText = $scope.processes_account.First_Name;
        $scope.processes_account.Last_Name = $scope.processes_account.tenancies.Last_Name;

        if($scope.processes_account.tenancies.Last_Name){

            $scope.processes_account.tenantNameText = $scope.processes_account.tenantNameText + " " + $scope.processes_account.Last_Name;

        }

        $scope.processes_account.Unit_number = $scope.processes_account.tenancies.Unit_number;

        console.log("Tenancy ID: " + $scope.processes_account.Tenancy_ID);
        console.log("Unit_number: " + $scope.processes_account.Unit_number);

        console.log("Customer_ID: " + $scope.processes_account.Customer_ID);
        $scope.unitText = " U " + $scope.processes_account.Unit_number;

    }

    $scope.addProcessAccount = function (processAccount) {
        console.log("addTransaction.");

        $rootScope.pageBlockSpinner = 1;

        var qaddProcessAccount = $q.defer();

        if(confirm("Do you want to add a process account?") == true){

            $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
            $scope.processes_account.pageno = $scope.pageno;

            $scope.processes_account.addButton = '1';
            delete $scope.processes_account.addButton.value;
            delete $scope.processes_account.addButton;

            $scope.a = [];

            $scope.a = processAccount;





            var myValue = 0;
            var promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log('A7b hide data');
                    return $scope.processesAccountFunction(0, 4);
                })
                .then(function () {

                        console.log('A7b hide data');

                        if(confirm("Do you want to go to the new account?")){

                            console.log('A7b hide data');
                            $state.go('lettings-main-nav.lettings-side.process-account-view.notes', {processAccountID:  $scope.processAccountID})

                        }else{
                            $state.reload('lettings-main-nav.lettings-side.process-account-add-edit');
                        }

                })
                .then(function () {

                        console.log('A7c send email confirmation');
                        $scope.date = new Date();
                        $scope.propertyText = "";


                        $scope.propertyText = $scope.processes_account.Property_name;

                        if($scope.processes_account.Unit_number){
                            $scope.propertyText = $scope.propertyText + " U " + $scope.processes_account.Unit_number;
                        }

                        var from_email = "Clouderty | Process Account Created " + $scope.processAccountID + " | " + $scope.processes_account.Description + " <notifications@clouderty.co.uk>";
                        var subject = $rootScope.decodedToken.Name + " | " + $scope.propertyText + " | " + $scope.processes_account.Description;
                        var message = "Process Account ID: " + $scope.processAccountID + "<br /><br />" +
                            "Property: " + $scope.propertyText + "<br /><br />" +
                            "Process type: " + $scope.processes_account.Description + "<br /><br />" +
                            "Process created by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                            "Date created: " + $scope.date + "<br /><br />";

                        var message =  message + "Tenant Name: " + $scope.processes_account.tenantNameText + "<br /><br />";

                        $scope.postUserActivityServiceMessage = subject;
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                        to_email = $rootScope.decodedToken.Notifications_Email;

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                        $rootScope.pageBlockSpinner = 0;

                    }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

            qaddProcessAccount.resolve($scope.promise);





        }else{
            console.log("No account added");

        }
    }

    $scope.loadProcessData = function () {
        console.log("inside function loadProcessData");



        console.log("$stateParams.id: " + $stateParams.id);
        //$scope.$state = $state;
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2");
                //return $scope.getPaymentTransactionCategoriesAll(data);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A3 getProcessTypes()");

                //return $scope.getPaymentTransactionTypes(data);
                return $scope.getProcessTypes(data);
            })
            .then(function (data) {

                console.log("A4 getPropertyTypes");

                return $scope.getPropertyTypes(data);
            })
            .then(function (data) {
                console.log("A4 awardsFunction");

                $scope.awardsFunction('*', 70);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                $scope.paymentBlockSpinner = '0';
                $scope.pageBlock = '1';
                return $scope.getGlobalPromise(data);


            })
            .then(function (data) {
                console.log('A7a');

                return $scope.getGlobalPromise(data);

                //$scope.myValue = data;
            },
                function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

    }

    $scope.initialPageLoad = function() {
        console.log("inside function initialPageLoad");
        var qinitialPageLoad66 = $q.defer();


        qinitialPageLoad66.resolve($scope.obj = $scope.loadProcessData());


        return qinitialPageLoad66.promise;


    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.initialPageLoad();
}]);