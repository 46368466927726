/**
 * Created by Aaron on 04/07/2016.
 */
clouderty.controller("accountHolderManageCtrl", ["$scope", "$rootScope", "$state", "postUserActivityService", "$location", "$http", "$window", "$cookies", "$q", "apiService", function ($scope, $rootScope, $state, postUserActivityService, $location, $http, $window, $cookies, $q, apiService) {
    console.log("inside controller accountHolderUserAddEditCtrl");

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 2000000;

    $scope.obj = {};
    $scope.accountHolderData = {};
    $scope.users = {};
    $scope.usersTypes = {};
    $scope.usersLevels = {};

    $scope.newUserSpinner = 0;

    if($rootScope.decodedToken.Account_Administrator == 1){
        console.log("Account administrator confirmed - " + $rootScope.decodedToken.Account_Administrator);

        //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

        $scope.getGlobalPromise = function() {

            var q55 = $q.defer();
            apiService.getGlobalPromise().then(
                function (response) {

                    //$scope.landlords = response.data;
                    console.log("API getGlobalPromise");

                    q55.resolve(response);

                }
            )


            return q55.promise;
        }

        $scope.accountHoldersFunction = function(id, status) {

            console.log("function processesAccountFunction");
            //alert("function processesAccountFunction");

            console.log("$scope.pageno" + $scope.pageno);
            $scope.accountHolderData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.accountHolderData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.accountHolderData.id = $rootScope.decodedToken.id;
            $scope.accountHolderData.User_ID = $rootScope.decodedToken.id;
            $scope.accountHolderData.itemsPerPage = $scope.itemsPerPage;
            $scope.accountHolderData.pageno = $scope.pageno;
            $scope.accountHolderData.Status = status;


            if($scope.search){

                $scope.accountHolderData.search = $scope.search;

            }
            else{

                $scope.accountHolderData.search = "*";

            }

            var qaccountHoldersFunction = $q.defer();

            console.log($scope.accountHolderData);


            apiService.accountHoldersUsers(id, $scope.accountHolderData).then(
                function (response) {
                    console.log("inside apiService accountHoldersUser within controller accountHolderManageCtrl");


                    console.log("status: " + status);
                    if(status == 11){

                        $scope.users = response.data.users;
                        $scope.users.totalCount = response.data.totalCount;
                        $scope.newUserSpinner = 0;
                    }
                    else if(status == 12){

                        $scope.usersTypes = response.data.usersTypes;

                    }
                    else if(status == 13){

                        $scope.usersLevels = response.data.usersLevels;

                    }
                    else if(status == 31){


                    }
                    else if(status == 41){


                    }
                    console.log("processes API successful");

                    console.log(response);
                    qaccountHoldersFunction.resolve(response);

                },
                function () {
                    console.log("API Service Error: accountHoldersUsers")
                }

            );

            if($scope.search == "*"){
                $scope.search = "";
            }
            else{

            };

            return qaccountHoldersFunction.promise;

        }

        $scope.editUser = function(paginateResult) {
            console.log("inside function editUser");

            console.log("paginateResult" + paginateResult);
            console.log(paginateResult);


            paginateResult.edit = 1;



            /*

            $scope.accountHolderData.User_ID_Update = dataObj.User_ID;


            if(confirm("Do you want to edit the account belonging to " + dataObj.User_Name, 'CLOUDERTY NOTIFICATION')){

                $scope.newUserSpinner = 1;

                var qdeleteUser = $q.defer();

                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");

                        $scope.accountHoldersFunction('*', 31);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A2");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');

                        $scope.myValue = data;

                        return $scope.accountHoldersFunction('*', 11);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qdeleteUser.resolve($scope.promise);


            }

            */

        }

        $scope.saveUser = function(paginateResult) {
            console.log("inside function saveUser");

            console.log("paginateResult" + paginateResult);
            console.log(paginateResult);


            paginateResult.edit = 1;



            /*

            $scope.accountHolderData.User_ID_Update = dataObj.User_ID;


            if(confirm("Do you want to edit the account belonging to " + dataObj.User_Name, 'CLOUDERTY NOTIFICATION')){

                $scope.newUserSpinner = 1;

                var qdeleteUser = $q.defer();

                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");

                        $scope.accountHoldersFunction('*', 31);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A2");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');

                        $scope.myValue = data;

                        return $scope.accountHoldersFunction('*', 11);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qdeleteUser.resolve($scope.promise);


            }

            */

        }


        $scope.deleteUser = function(dataObj) {
            console.log("inside function getAccountHolder");
            $scope.accountHolderData.User_ID_Update = dataObj.User_ID;

            if(confirm("Do you want to deletE the account belonging to " + dataObj.User_Name, 'CLOUDERTY NOTIFICATION')){

                $scope.newUserSpinner = 1;

                var qdeleteUser = $q.defer();

                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");

                        $scope.accountHoldersFunction('*', 31);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A2");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');

                        $scope.myValue = data;

                        return $scope.accountHoldersFunction('*', 11);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qdeleteUser.resolve($scope.promise);


            }


        }

        $scope.getAccountHolder = function() {
            console.log("inside function getAccountHolder");
            $scope.obj.Account_Holders_Name = $rootScope.decodedToken.Account_Holder;
            $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.obj.Contact_Number = $rootScope.decodedToken.Contact_Number;
            $scope.obj.Notifications_Email = $rootScope.decodedToken.Notifications_Email;
            $scope.obj.Accounts_Email = $rootScope.decodedToken.Accounts_Email;

        }

        $scope.getUser = function() {
            console.log("inside function getUser");
            $scope.obj.User_Name = $rootScope.decodedToken.Name;
            $scope.obj.User_Email = $rootScope.decodedToken.Email;

        }

        $scope.addUserAccount = function (dataObj) {

            console.log("inside function addMaintenanceItem");

            console.log(dataObj);

            $scope.newUserSpinner = 1;

            if(confirm("Do you want to add a user?"), 'Clouderty notification'){
                //$scope.maintenanceMaterialsLabourView = [];
                //$scope.maintenanceSpinner = "";
                //$scope.gotoAnchor('maintenance');

                $scope.accountHolderData.Password = dataObj.passwordAdd;
                $scope.accountHolderData.User_Name = dataObj.usernameAdd;
                $scope.accountHolderData.User_Email = dataObj.emailAdd;
                $scope.accountHolderData.User_Type_ID = dataObj.accountTypeAdd;
                $scope.accountHolderData.User_Level_ID = dataObj.userLevelAdd;
                //$scope.paymentBlockSpinner = 1;

                var q = $q.defer();

                $scope.myValue = 0;
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");

                        $scope.accountHoldersFunction('*', 41);
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log("A2");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');

                        $scope.myValue = data;

                        return $scope.accountHoldersFunction('*', 11);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

            }


        }

        $scope.loadPageData = function () {
            console.log("inside function loadPageData");

            //$scope.$state = $state;
            var q = $q.defer();
            $scope.promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log("A2");

                    //return $scope.getPaymentTransactionCategoriesAll(data);
                    return $scope.getGlobalPromise(data);
                })
                .then(function (data) {

                    console.log("A3 getUsers");



                    return $scope.accountHoldersFunction('*', 11);
                })
                .then(function (data) {

                    console.log("A2 getUserTypes");

                    return $scope.accountHoldersFunction('*', 12);
                })
                .then(function (data) {

                    console.log("A2 getUserTypes");

                    return $scope.accountHoldersFunction('*', 13);
                })
                .then(function (data) {
                    console.log('A7a');
                    return $scope.getGlobalPromise(data);

                    //$scope.myValue = data;
                }, function (err) {
                    $scope.myValue = err;
                })

            q.resolve($scope.promise);

        }

        $scope.initialPageLoad = function() {
            console.log("inside function initialPageLoad");
            var qinitialPageLoad66 = $q.defer();

            $scope.status = 0;
            qinitialPageLoad66.resolve($scope.loadPageData());


            return qinitialPageLoad66.promise;


        }

        //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

        $scope.getAccountHolder();
        $scope.getUser();
        $scope.initialPageLoad();

        //$scope.accountHoldersFunction('*', 11);
        //$scope.accountHoldersFunction('*', 12);

        //////////////////////////  Redundant  /////////////////////////////////////////////////////////////////////////////////


    }
    else{
        alert("Authorisation declined");
        $state.go('landlords-main-nav.landlords-tasks-side.main-dashboard');
    }

}])