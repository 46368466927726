/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.factory('randomTextGeneratorService', ["$window", "$http", "$location", function($window, $http, $location) {
    return{

        generator: function(value){
            console.log("inside service randomTextGeneratorService generator");
            var chars = "abcdefghijklmnopqrstuvwxyz-ABCDEFGHIJKLMNOP1234567890";
            var pass = "";
            for (var x = 0; x < value; x++) {
                var i = Math.floor(Math.random() * chars.length);
                pass += chars.charAt(i);
            }


            var randomPasswordText = pass;
            console.log("randomPasswordText" + randomPasswordText);
            return randomPasswordText;
        }
    };
}]);
