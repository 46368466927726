/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("statesCtrl", ["$scope", "$http", "$stateParams", "Upload", "$rootScope", "emailService", "postUserActivityService", "$filter", "apiService", "yesNoFilter", "$uibModal", "fCsv", "$timeout", "exportCSVService", "$state", "dateInputService", "$q", "fileUploadService", "notePostService", "truncateSliceService", function ($scope, $http, $stateParams, Upload, $rootScope, emailService, postUserActivityService, $filter, apiService, yesNoFilter, $uibModal, fCsv, $timeout, exportCSVService, $state, dateInputService, $q, fileUploadService, notePostService, truncateSliceService)  {

    console.log("inside controller notesCtrl");

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.search = "";
    $scope.progress = {};

    $scope.stateProcessAccountView = "lettings-main-nav.lettings-side.process-account-view.notes";
    $scope.statePaymentTransactionAddSurround = "lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add";
    $scope.stateTenancyAgreementExternal = "tenancy-agreement-room.payment";
    $scope.stateTenancyView = "lettings-main-nav.lettings-side.lettings-view-tenancy.notes";
    $scope.stateTaskView = "lettings-main-nav.tasks-side.task-view.notes";
    $scope.stateTaskContractorsView = "contractors-main-nav.contractors-tasks-side.task-view.notes";
    $scope.stateCurrent = "";

    $scope.noteSpinner = 0;

    $scope.statesInput = [];

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.openMailSender = function(emailId,subject,message, property){

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

    };

    $scope.statesFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        $scope.statesInput.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.statesInput.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.statesInput.itemsPerPage = $scope.itemsPerPage;
        $scope.statesInput.pageno = $scope.pageno;
        $scope.statesInput.Status = status;
        $scope.statesInput.State_ID = id;

        if($scope.statesInput.State_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            $scope.statesInput.State_ID = "*";
        }

        if($scope.search){

            $scope.statesInput.search = $scope.search;
        }
        else{

            $scope.statesInput.search = "*";
        }

        var qstatesFunction = $q.defer();

        console.log($scope.statesInput);
        apiService.states(id, $scope.statesInput).then(
            function (response) {

                console.log("status: " + status);


                $scope.states = response.data.data;
                $scope.stateAdded = 1;


                $scope.totalCount = response.data.totalCount;


                console.log("states API successful");

                console.log(response);
                qstatesFunction.resolve(response);


            },
            function () {
                console.log("API Service Error: states")
            }

        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qstatesFunction.promise;
    }


    console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);

    if($state.current.name == $scope.stateProcessAccountView) {


    }
    else if($state.current.name == $scope.stateTenancyView) {

    }
    else if($state.current.name == $scope.stateTaskView) {


    }
    else if(1){

    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.loadProcessDataStateProcessAccountView();

}])