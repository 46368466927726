/**
 * Created by Cyril on 27/07/2016.
 */
clouderty.directive('routeLoadingIndicator', function($rootScope) {

    return {
        restrict:'E',
            template:"<h1 ng-if='isRouteLoading'>Loading...</h1>",
        link:function(scope, elem, attrs){
        scope.isRouteLoading = false;

        $rootScope.$on('$routeChangeStart', function(){
            scope.isRouteLoading = true;
        });

        $rootScope.$on('$routeChangeSuccess', function(){
            scope.isRouteLoading = false;
        });
    }
    };
});
/*
clouderty.directive('demoFileModel', ["$parse", function ($parse) {
    return {
        restrict: 'A', //the directive can be used as an attribute only


        link: function (scope, element, attrs) {
            var model = $parse(attrs.demoFileModel),
                modelSetter = model.assign; //define a setter for demoFileModel

            //Bind change event on the element
            element.bind('change', function () {
                //Call apply on scope, it checks for value changes and reflect them on UI
                scope.$apply(function () {
                    //set the model value
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);

*/