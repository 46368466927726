/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("tenantViewingAddEditCtrl", ["$scope", "stateAuthCheckService", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "$stateParams", "apiService", "dateInputService", "moment",  "$q", function ($scope, stateAuthCheckService, $rootScope, $state, $location, $http, $window, $stateParams, $rootScope, $filter, $stateParams, apiService, dateInputService, moment, $q) {


    //stateAuthCheckService.runAuthCheckRootScope('lettings-main-nav.financials-side.dashboard-financial');

    $scope.obj = {};
    $scope.users = {};
    $scope.obj.users = {};
    $scope.tenantViewingsStatus = {};
    $scope.obj.tenantViewingsStatus = {};
    $scope.objBackup = {};
    //$scope.obj.Tenancy_end_date = new moment();
    //$scope.obj.Tenancy_start_date = new Date();
    $scope.tenants = {};
    $scope.tenancy = {};
    $scope.searchTenant = {};
    $scope.searchTenancy = {};
    $scope.monthsAdded = 0;
    $scope.objre = new Date();
    $scope.obj.User_ID_Viewing_By = null;
    $scope.obj.Tenant_Viewing_Status_ID = null;

    $scope.obj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
    $scope.obj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
    //console.log("State ParamsID: = " + $stateParams.id);

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];

    $('.data').hide();
    $('.accordion-1-form').show();

    $scope.New_Record = false;

    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });

    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.searchTenant = function() {
        $scope.tenants = null;

        console.log("apiService.searchTenant");
        apiService.searchTenant($scope.search_tenant, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenants = response.data;
            },
            function() {
                console.log("There was an error apiService.searchTenant")
            }
        )
    }

    $scope.selectTenantAdd = function(id) {
        $scope.obj.selectedTenants = [];
        console.log("Getting tenancy information: selectTenant - tenant id: " + id);
        $http.get("api/tenant/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.data.Tenant_ID,
                "First_Name": response.data.First_Name,
                "Last_Name": response.data.Last_Name,
                "Email": response.Email,
                "Contact_number": response.data.Contact_number,
                "D_O_B": response.data.D_O_B
            })
            $scope.obj.Tenant_ID = response.data.Tenant_ID;
            if (response.Last_Name == null){$scope.obj.Last_Name = " ";} else { };
            //If no last name is present attach blank value to obj scope
            $scope.obj.Full_Name = response.data.First_Name + "" + $scope.obj.Last_Name;
            $scope.obj.Contact_number = response.data.Contact_number;
            $scope.obj.Email = response.data.Email;

            console.log('Tenant id:' + $scope.obj.Tenant_ID);
        })

        $scope.search_tenant = "";

    }

    $scope.selectTenant = function(id) {
        $scope.obj.selectedTenants = [];
        console.log("Getting tenancy information: selectTenant - tenant id: " + $scope.obj.Tenant_ID);
        $http.get("api/tenant/" + $scope.obj.Tenant_ID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.obj.selectedTenants.push({
                "Tenant_ID": response.data.Tenant_ID,
                "First_Name": response.data.First_Name,
                "Last_Name": response.data.Last_Name,
                "Email": response.Email,
                "Contact_number": response.data.Contact_number,
                "D_O_B": response.data.D_O_B
            })
            $scope.obj.Tenant_ID = response.data.Tenant_ID;
            if (response.Last_Name == null){$scope.obj.Last_Name = " ";} else { };
            //If no last name is present attach blank value to obj scope
            $scope.obj.Full_Name = response.data.First_Name + "" + $scope.obj.Last_Name;
            $scope.obj.Contact_number = response.data.Contact_number;
            $scope.obj.Email = response.data.Email;

            console.log('Tenant id:' + $scope.obj.Tenant_ID);
        })

        $scope.search_tenant = "";

    }

    $scope.getUsers = function() {

        var q3 = $q.defer();
        apiService.usersViewAll($rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function (response) {

                $scope.users = {};
                $scope.obj.users = {};

                $scope.users = response.data.data;
                $scope.obj.users = response.data.data;

                if ($stateParams.id != null) {
                    console.log("$stateParams.id $scope.obj.users.User_ID : " + $scope.obj.users.User_ID);
                    console.log("$stateParams.id $scope.obj.User_ID : " + $scope.obj.User_ID);
                    $scope.obj.users.User_ID = $scope.obj.User_ID_Viewing_By;
                    $scope.users.User_ID = $scope.obj.User_ID_Viewing_By;
                }

                q3.resolve(response);

            },
            function () {
                console.log("There was an error API getUsers")
            }
        )
        return q3.promise;
    }

    $scope.getTenantViewing = function() {
        var q = $q.defer();
        //console.log("Getting tenancy information: getTenancy");
        apiService.getTenantViewing($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
            function(response) {

                //$scope.tenancies = response.data;
                $scope.obj = response.data.data;
                $scope.$parent.objBackup = angular.copy($scope.obj);
                //$scope.obj.users.User_ID  = $scope.obj.User_ID_Viewing_By;
                $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.obj.Viewing_Date, 0);
                $scope.obj.Viewing_Time = moment($scope.obj.Viewing_Time, "HH:mm").toDate();

                //var date_string = $scope.obj.Viewing_Time;
                //var dateVariable = new Date(date_string);




                //console.log("$scope.obj.Tenancy_end_date point 2: " +  $scope.obj.Tenancy_end_date);

                $scope.selectTenant($scope.obj.Tenant_ID);
                $scope.selectUnit($scope.obj.Unit_ID);

                q.resolve(response);
                //return $scope.obj;
            },
            function() {
                console.log("There was an error apiService.searchTenant")
            }
        )
        return q.promise;
    }

    $scope.dateOptions = {
        dateFormat: 'dd/mm/yyyy',
    }

    if ($stateParams.id == null) {
        $scope.obj.selectedTenants = [];
        $scope.obj.selectedUnits = [];
        $scope.New_Record = true;
    }
    else{
        $scope.New_Record = false;

        $scope.obj = $scope.getTenantViewing();
    }

    $scope.hideSearch = false;

    $scope.searchUnit = function() {
        $scope.units = null;
        console.log("$scope.search_unit: " + $scope.search_unit + "<<<");
        apiService.searchUnits($scope.search_unit, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data.data;
            },
            function() {
                console.log("There was an error apiService.searchUnits");
            }
        )

    }

    $scope.selectUnit = function(id) {
        $scope.obj.selectedUnits = [];

        apiService.getUnit(id, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
        //$http.get("api/unit/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                $scope.obj.selectedUnits.push({
                    "Unit_ID": response.data.data.Unit_ID,
                    "Address": response.data.data.Address_1,
                    "Unit_Number": response.data.data.Unit_number,
                    "Town": response.data.data.Town,
                    "City": response.data.data.City,
                    "Postcode": response.data.data.Postcode
                })

                $scope.obj.Address_1 =  response.data.data.Address_1;
                $scope.obj.Property_name =  response.data.data.Property_name;
                $scope.obj.Postcode =  response.data.data.Postcode;
                $scope.obj.Unit_number =  response.data.data.Unit_number;
                $scope.obj.Unit_ID = response.data.data.Unit_ID;
                console.log("$scope.obj.Unit_ID: " + $scope.obj.Unit_ID);

        //})
            }
        )
        $scope.search_unit = "";
        $scope.hideSearch = true;
    }

    $scope.deselectTenant = function (index) {
        $scope.obj.selectedTenants.splice(index, 1);
    }

    $scope.deselectUnit = function (index) {
        $scope.obj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }

    $scope.addTenantViewing = function() {



        $scope.obj.User_ID = $rootScope.decodedToken.id;
        console.log($scope.obj.User_ID);

        console.log('apiService getTenantViewing - c');



        if ($stateParams.id == null) {

            if (confirm('Are you sure you want to add a tenant viewing?')) {
                $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.obj.Viewing_Date, 0);
                var dateToday = new Date();
                $scope.obj.Date_Added = dateToday;
                $scope.obj.Current = '1';


                apiService.createTenantViewing($scope.obj).then(
                    function(response) {

                        $scope.obj.Tenant_Viewing_ID = response.data.Tenant_Viewing_ID;
                        console.log('response.Tenant_Viewing_ID:' + response.Tenant_Viewing_ID);
                        //console.log('Tenancy id 2:' + $scope.obj.Tenancy_ID);

                        //$scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.obj.Viewing_Date, 0);
                        //$scope.obj.Viewing_Time = moment($scope.obj.Viewing_Time, "HH:mm").toDate();

                        apiService.getTenantViewing($scope.obj.Tenant_Viewing_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                            function (response) {
                                console.log('apiService getTenantViewing');

                                $scope.a = [];
                                $scope.a = response.data.data;

                                console.log("$scope.obj.Viewing_Date point 0: " +  $scope.obj.Viewing_Date);
                                $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.a.Viewing_Date, 0);
                                console.log("$scope.obj.Viewing_Date point 0: " +  $scope.obj.Viewing_Date);


                                console.log(' $scope.obj.Viewing_Time');
                                $scope.obj.Viewing_Time = dateInputService.timeInputService($scope.a.Viewing_Time, 0);

                                console.log('Tenant_Viewing_ID a:' + $scope.a.Tenant_Viewing_ID);
                                console.log('Tenant_Viewing_ID obj:' + $scope.obj.Tenant_Viewing_ID);
                                var from_email = "Clouderty | Tenant Viewing Created | " + $scope.obj.Property_name + " |" + " <notifications@clouderty.co.uk>";
                                var to_email = $rootScope.decodedToken.Notifications_Email;
                                var subject = $scope.obj.Property_name + " | " + $scope.obj.Full_Name  + " | " + $scope.obj.Unit_number + " | Created by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + $scope.obj.Tenancy_ID;
                                var message = "Tenant name: " + $scope.obj.Full_Name + "\n\n" +
                                    "Address: " + $scope.obj.Property_name + "\n\n" +

                                    "Unit number: " + $scope.obj.Unit_number + "\n\n" +
                                    "Email: " + $scope.obj.Email + "\n\n" +
                                    "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                                    "Viewing Date: " + $scope.obj.Viewing_Date + "\n\n" +
                                    "Viewing Time: " + $filter('date')($scope.obj.Viewing_Time,'HH:mm:ss') + $scope.obj.Viewing_Time + "\n\n" +
                                    "Tenant Viewing ID: " + $scope.obj.Tenant_Viewing_ID + "\n\n" +
                                    "Notes: " + $scope.obj.Notes + "\n\n" +
                                    "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                                $state.go('lettings-main-nav.lettings-side.lettings-edit-tenant-viewing', {id: $scope.obj.Tenant_Viewing_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: '1'});

                            },
                            function () {
                                console.log("There was an error")
                            }
                        )


                        console.log("API createTenantViewing: " );
                    },
                    function() {
                        console.log("We are in createTenantViewing");
                    }
                )





            } else {
                // Do nothing!
            }

        }else {

            if (confirm('Are you sure you want to update this tenant viewing?')) {
                console.log('apiService getTenantViewing - a');
                $scope.obj.Date_Added = dateInputService.dateInputDayService($scope.obj.Date_Added, 0);
                $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.obj.Viewing_Date, 0);
                //$scope.obj.Viewing_Time = dateInputService.timeInputService($scope.obj.Viewing_Time, 0);
                console.log('apiService getTenantViewing - b');
                apiService.updateTenantViewing($stateParams.id, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator, $scope.obj).then(
                    function(response) {
                        console.log('apiService getTenantViewing - bb');
                        apiService.getTenantViewing($scope.obj.Tenant_Viewing_ID, $rootScope.decodedToken.Account_Holder_ID, $rootScope.decodedToken.Clouderty_System_Administrator).then(
                            function (response) {
                                console.log('apiService getTenantViewing');

                                $scope.a = [];
                                $scope.a = response.data.data;
                                //$scope.obj.Date_Added = dateInputService.dateInputDayService($scope.obj.Date_Added, 0);
                                $scope.obj.Viewing_Date = dateInputService.dateInputDayService($scope.a.Viewing_Date, 0);
                                $scope.obj.Viewing_Time = dateInputService.timeInputService($scope.a.Viewing_Time, 0);


                                console.log('Tenant_Viewing_ID a:' + $scope.a.Tenant_Viewing_ID);
                                console.log('Tenant_Viewing_ID obj:' + $scope.obj.Tenant_Viewing_ID);
                                var from_email = "Clouderty | Tenant Viewing Updated | " + $scope.obj.Property_name + " |" + " <notifications@clouderty.co.uk>";
                                var to_email = $rootScope.decodedToken.Notifications_Email;
                                var subject = $scope.obj.Property_name + " | " + $scope.obj.Full_Name  + " | " + $scope.obj.Unit_number + " | Updated by: " + $rootScope.decodedToken.Name + " | Tenancy ID: " + $scope.obj.Tenancy_ID;
                                var message = "Tenant name: " + $scope.obj.Full_Name + "\n\n" +
                                    "Address: " + $scope.obj.Property_name + "\n\n" +

                                    "Unit number: " + $scope.obj.Unit_number + "\n\n" +
                                    "Email: " + $scope.obj.Email + "\n\n" +
                                    "Contact number: " + $scope.obj.Contact_number + "\n\n\n\n" +
                                    "Viewing Date: " + $scope.obj.Viewing_Date + "\n\n" +
                                    "Viewing Time: " +  $filter('date')($scope.obj.Viewing_Time,'HH:mm:ss') + " >>>>> " + $scope.obj.Viewing_Time + "\n\n" +
                                    "Tenant Viewing Status: " + $scope.obj.Tenant_Viewing_Status_ID + "\n\n" +
                                    "Tenant Viewing ID: " + $scope.obj.Tenant_Viewing_ID + "\n\n" +
                                    "Notes: " + $scope.obj.Notes + "\n\n" +
                                    "Created By: " + $rootScope.decodedToken.Name + "\n\n";

                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                                //$state.go('lettings-main-nav.lettings-side.lettings-edit-tenant-viewing', {id: $scope.obj.Tenant_Viewing_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID});
                                $state.go($state.current, {}, {reload: true});
                            },
                            function() {
                                console.log("There was an error in apiService getTenantViewing")
                            }
                        )
                    },
                    function() {
                        console.log("There was an error")
                    }
                )

            }

        }
    }

    $scope.getUser = function() {

        $scope.obj.User_ID_Viewing_By = $scope.obj.users.User_ID;
        console.log("$scope.obj.User_ID_Viewing_By: " + $scope.obj.User_ID_Viewing_By );


    }

    $scope.getTenantViewingStatusID = function() {

        $scope.obj.Tenant_Viewing_Status_ID = $scope.obj.tenantViewingsStatus.Tenant_Viewing_Status_ID;
        console.log("$scope.obj.Tenant_Viewing_Status_ID : " + $scope.obj.Tenant_Viewing_Status_ID);

    }

    $scope.getTenantViewingStatus = function() {
        console.log("API getTenantViewingStatus");
        var q2 = $q.defer();

        apiService.getTenantViewingStatus().then(
            function(response) {

                $scope.tenantViewingsStatus = response.data.data;
                $scope.obj.tenantViewingsStatus = response.data.data;

                if ($stateParams.id != null) {
                    //console.log("$stateParams.id $scope.obj.tenantViewingsStatus.Tenant_Viewing_Status_ID : " + $scope.obj.users.Tenant_Viewing_Status_ID);
                    console.log("$stateParams.id $scope.obj.Tenant_Viewing_Status_ID : " + $scope.tenantViewingsStatus.Tenant_Viewing_Status_ID);
                    $scope.obj.tenantViewingsStatus.Tenant_Viewing_Status_ID = $scope.obj.Tenant_Viewing_Status_ID;
                    $scope.tenantViewingsStatus.Tenant_Viewing_Status_ID = $scope.obj.Tenant_Viewing_Status_ID;
                    //$scope.obj.tenantViewingsStatus.Tenant_Viewing_Status_ID = $scope.obj.Tenant_Viewing_Status_ID;

                }
                q2.resolve(response);
            },
            function() {
                console.log("Where in");
                console.log("There was an error in API getTenantViewingStatus")
            }
        )
        return q2.promise;
    }


    $scope.tenancyDocumentsRequestSend = function (status) {
        var confirmResponse = confirm("Send tenancy agreement to tenant");
        console.log(confirmResponse);
        if(confirmResponse == false){


        }else{



            //$scope.url = "";
            var url = $state.href('tenancy-agreement-room.payment', {tenancyID: $scope.obj.data.Tenancy_ID, licenseKey: $scope.obj.data.Licence_Key, myParam: $scope.tenancies});

            /*
            var url = $state.href('tenancy-agreement-single-let',
                {
                    tenancyID: $scope.obj.data.Tenancy_ID,
                    licenseKey: $scope.obj.data.Licence_Key,
                    myParam: $scope.tenancies
                });
                */
            //window.open($scope.url,'_blank');

            $scope.url2 = "https://management.clouderty.co.uk/" + url;

            console.log("$scope.url: " + $scope.url);
            console.log("$scope.url2: " + $scope.url2);

            console.log("Promise landlordStatementSend");
            var q73 = $q.defer();
            $scope.url = "";
            $scope.myValue = 0;
            $scope.promise = $scope.getGlobalPromise();

            //$scope.$parent.obj.DescriptionTruncated = $scope.obj.sampleText = truncateSliceService.truncateSliceService($scope.$parent.obj.Description, 25);

            var from_email = "Clouderty | Tenancy Agreement | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " <notifications@clouderty.co.uk>";
            var subject = "Tenancy sent by: " + $rootScope.decodedToken.Name + " | " + $scope.obj.data.First_Name  + " | " + $scope.obj.data.Address_1 + " | " + "Tenancy ID: " + $scope.obj.data.Tenancy_ID;

            /*
            var message = "Tenancy ID: " + $scope.obj.data.Tenancy_ID + "<br />" +
                "Tenancy Agreement: " +$scope.url2 + "<br /><br />" +
                "Created By: " + $rootScope.decodedToken.Name + "<br />";
            */

            var message =
                "Dear " + $scope.obj.data.First_Name + ", " + "<br /><br />" +
                "We are delighted to inform you that your details have now been processed and your automated tenancy is now ready for you to sign." + "<br /><br />" +
                "To sign your tenancy: " + "<br /><br />" +
                "1) Click on the link below." + "<br /><br />" +
                "2) Make payment for your first month's rent by scrolling to the bottom of the web-page and entering your bank details." + "<br /><br />" +
                "3) Once your payment is processed, if you are happy with the terms of the agreement, put ticks on all of the agreements clauses then enter your electronic signature and password." + "<br /><br />" +
                "4) Click on the submit button." + "<br /><br />" +
                "Tenancy Agreement: " + $scope.url2 + "<br /><br />" +
                "Once you have completed this process you will automatically be sent a receipt and an email with further information." + "<br /><br />" +
                "Kind regards," + "<br />" +
                "Platinum Crown" + "<br /><br />" +
                "134 Hythe Hill" + "<br />" +
                "Colchester" + "<br />" +
                "Essex" + "<br />" +
                "CO1 2NF" + "<br />" +"<br />" +
                "Tel 01206 705 555" + "<br />" +
                "Fax 01206 705 556" + "<br />" +
                "www.platinumcrown.co.uk" + "<br />";



            //var to_email = $rootScope.decodedToken.Accounts_Email;
            //$rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            var to_email = $rootScope.decodedToken.Notifications_Email;

            //"Note Content: " + $scope.posted_note.Description + "\n\n" +
            //"Note Content: " + $scope.posted_note.data.Description + "\n\n";
            //"Task name: " + $scope.posted_note.obj.Description;
            //$rootScope.sendEmail(from_email, to_email, subject, message);
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);

            $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.tenancies.itemsPerPage = 20;
            $scope.tenancies.pageno = 1;
            $scope.tenancies.Tenancy_ID = $scope.obj.data.Tenancy_ID;
            $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
            $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
            $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
            $scope.tenancies.Licence_Key = 0;
            $scope.tenancies.Status = 40;
            $scope.tenancies.Payment_Transaction_Type_ID = "";
            $scope.tenancies.paymentTransactionInputs = "";

            $scope.tenanciesFunction($scope.obj.data.Tenancy_ID ,$scope.tenancies.Status);

            q73.resolve($scope.promise);

            return q73.promise;


        }




    }


    $scope.loadData = function() {

        console.log("C1 loadDataThenMap");
        var q4 = $q.defer();
        $scope.myValue = 0;
        $scope.promise = $scope.getTenantViewingStatus();

        $scope.promise/*
            .then(function (data) {

                console.log("A3");

                return $scope.getTenantViewing(data);
            })*/
            .then(function (data) {

                console.log("A2");

                return $scope.getTenantViewingStatus(data);
            })
            .then(function (data) {

                console.log("A3");

                return $scope.getUsers(data);
            })
            .then(function (data) {
                console.log('7');
                $scope.myValue = data;
            }, function (err) {
                $scope.myValue = err;
            })

        q4.resolve($scope.promise);
    }

    if ($stateParams.id != null) {
        $scope.loadData();
    }
    else{
        $scope.getUsers();
        $scope.getTenantViewingStatus();

    }

}])