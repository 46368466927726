
clouderty.controller("tenancyAgreementSurroundCtrl", ["$scope", "$rootScope", "$http", "$stateParams", "$state", "apiService", "reverseFilter", "sumOfValueFilter", "totalSumPriceQtyFilter", "yesNoFilter", "$filter", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$q", "$window", "$cookies", "postUserActivityService", "loginExternalWebPageService", "pdfCreateService", "scrollToService", "FlashService", 'UploadService', '$http', '$location', '$anchorScroll', function ($scope, $rootScope, $http, $stateParams, $state, apiService, reverseFilter, sumOfValueFilter, totalSumPriceQtyFilter, yesNoFilter, $filter, $mdDialog, $mdMedia, Upload, $timeout, $q, $window, $cookies, postUserActivityService, loginExternalWebPageService, pdfCreateService, scrollToService, FlashService, UploadService, $http, $location, $anchorScroll) {

    $scope.paymentView = 1;
    $scope.submitSignedTenancyButton = 0;
    $scope.tenancies = [];
    $rootScope.Property_ID = "";
    $rootScope.property = {};
    $scope.obj = {};
    //$scope.obj.data = {};
    //$scope.obj.data.Property_ID = "";

    $scope.init = function () {
        //$rootScope.logoutExternalPage();
        console.log("init inside ");


        $scope.submitSignedTenancyButton = 1;
        $scope.submitTenancySpinner = "5";
        $rootScope.tenancyAgreementBlock = null;
        // Delete record
        console.log("ACTION: 1");
        var q5 = $q.defer();
        $scope.myValue = 0;
        $scope.promise = loginExternalWebPageService.init("1");
        //$scope.promise = $scope.getGlobalPromise();


        $scope.promise
            .then(function (data) {

                console.log("A2a loginfunction");
                $rootScope.decode();
                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {
                //delete $rootScope.tenancyBlock;
                console.log("inside tenancyAgreementSurroundCtrl");
                console.log("show tenancy id fro stateParams");

                console.log("A3 init");
                console.log($stateParams);
                console.log("StateParams: " + $stateParams);
                console.log("$rootScope.tenancy_id: " + $rootScope.tenancy_id);

                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {
                console.log("A6 decode");
                console.log("main area");

                $scope.pageno = 1;
                $scope.totalCount = 0;
                $scope.itemsPerPage = 20;


                $scope.search = "";
                $scope.progress = {};
                $scope.business = {};
                $scope.properties = {};
                $scope.units = [];

                $scope.LandlordRegularCosts = [];
                $scope.paymentTransactionCategory = [];
                $scope.paymentTransactionCategories = [];
                $scope.hideBusinessSearch = false;
                $scope.DateToday = new Date();
                $scope.date = new Date();
                $scope.property = [];
                $rootScope.property = {};
                $rootScope.property.Property_name = "";
                $rootScope.property.Unit_number = "";


                $scope.tenancies = [];
                $scope.monthsExpiring = 0;
                $scope.landlord = [];
                $scope.landlord.Estate = "";
                $scope.landlord.Estate_ID = 0;
                $scope.landlord.Property = "";
                $scope.landlord.Property_ID = 0;

                $scope.submitSignedTenancyButton = 1;

                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx $rootScope.tenancy_id: " + $rootScope.tenancy_id);
                return $scope.tenanciesFunction($rootScope.tenancy_id, '20');
            })
            .then(function (data) {
                console.log("A7a decode");
                if($scope.tenancyData.Rent_Payment_ID_First){
                    console.log("we know first rent has been paid");
                    if($scope.tenancyData.Rent_Payment_ID_First){
                        console.log("check if tenancy passwords match");

                        $scope.tenancies.Password = $scope.passwordInput;
                        $scope.tenanciesFunction($rootScope.tenancy_id, '34');
                    }
                }
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A8 add tenancy signed date");
                ///*

                if($scope.tenancyData.Rent_Payment_ID_First){
                    console.log("we know first rent has been paid");
                    if($scope.tenancyData.Rent_Payment_ID_First){
                        console.log("check if tenancy signed date exists");

                        console.log("$scope.tenancyData.Date_Tenancy_Signed >> " + $scope.tenancyData.Date_Tenancy_Signed);

                        if(!$scope.tenancyData.Date_Tenancy_Signed){

                            //disable tenancy sign inputs
                            $scope.isDisabledAfterTenancySubmitted = true;
                            console.log("add tenancy signed date");

                            //date signed text
                            $scope.date = new Date();


                            $scope.tenanciesFunction($rootScope.tenancy_id, '36');

                        }

                    }

                }
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {
                //date signed text
                $scope.scrollTo('scrollTenancySubmitted');
                console.log("A8a scrollTenancySubmitted");
                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {
                console.log("A9 convertPDf <<<<<<<<<<<");


                /*
                var to_email = "cyril.thomas.uk@gmail.com";
                var attachmentFileLocation = "../uploads/";
                var fileName = 'tenancy-agreement';
                var from_email = "Clouderty | Tenancy Signed " + " | " + " <notifications@clouderty.co.uk>";
                var subject = "Created by Cyril" ;
                var message = "Test content 1: " + "<br />" +
                    "Test content 2: " + "<br />";

                pdfCreateService.pdfAddImage(fileName, attachmentFileLocation, status, to_email, from_email, subject, message);
                */


                console.log('$rootScope.Property_ID >>>>>>>>>>>>>>>>>>>>>: ' + $rootScope.Property_ID);
                console.log("$rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>>" + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);
                $scope.submitSignedTenancyButton = 0;
                //$rootScope.tenancyBlock = 1;
                $rootScope.tenancyAgreementBlock = 1;
                console.log('A11');
                $scope.myValue = data;


                $state.reload("tenancy-agreement-room");
                //$window.location.reload();



                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {

                console.log('A11 convertPDf A9 convertPDf <<<<<<<<<<<');
                $scope.convertPDf();

                return $scope.getGlobalPromise(data);
            }, function (err) {
                $scope.myValue = err;
            })

        q5.resolve($scope.promise);


        return q5.promise;


    }

    $scope.submitSignedTenancy = function (tenancyID) {
        console.log('inside function submitSignedTenancy');
        $scope.init();
    }

    $scope.print = function () {
        window.print();
    }

    $("html body").css("overflow-y","auto"); //enables multiple print pages

    $scope.tenanciesFunction = function(id, status) {


        console.log("function tenanciesFunction");

        $scope.tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.tenancies.itemsPerPage = $scope.itemsPerPage;
        $scope.tenancies.pageno = $scope.pageno;
        $scope.tenancies.Tenancy_ID = id;
        $scope.tenancies.Estate_ID = $scope.landlord.Estate_ID;
        $scope.tenancies.Property_ID = $scope.landlord.Property_ID;
        $scope.tenancies.monthsExpiring = $scope.monthsExpiring;
        $scope.tenancies.Licence_Key = $rootScope.licenseKey;
        $scope.tenancies.Status = status;
        $scope.tenancies.Payment_Transaction_Type_ID = "";
        $scope.tenancies.paymentTransactionInputs = "";

        if($scope.obj.data.Signature){
            console.log("$scope.obj.data.Signature xxxxxxxxxxxxxxxxxxxxxx " + $scope.obj.data.Signature);
            $scope.tenancies.Signature_Signed_Tenancy_Text = $scope.obj.data.Signature;
        }

        if(status =='25'){
            $scope.tenancies.Payment_Transaction_ID =  $rootScope.Rent_Payment_ID_First;

        } else{

        }


        if(status =='36'){

            $rootScope.tenancySubmitted = '';
            $rootScope.tenancySubmitted = '1';
            $scope.tenancies.tenancySubmitted =  $rootScope.tenancySubmitted;

        } else{

        }


        if($scope.tenancies.Tenancy_ID){
            //console.log("$scope.tasksFunction.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
        }else{

            $scope.tenancies.Tenancy_ID = "*";
        }


        if($scope.search){

            $scope.tenancies.search = $scope.search;
        }else{

            $scope.tenancies.search = "*";
        }

        //$scope.obj.search = "*";

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        console.log($scope.tenancies);
        apiService.tenancies(id, $scope.tenancies).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
                /*
                $scope.currentPage = $scope.pageno;
                $scope.totalCount = response.data.totalCount;
                $scope.paginationData = response.data.data;
                $scope.tenancies = response.data.data;
                $scope.rentTotal = response.data.rentTotal;
                $scope.totalTaskCount = response.data.totalCount;

                $scope.$parent.obj2 = {};
                $scope.$parent.obj = response.data;

                $rootScope.Property_ID = $scope.obj.data.Property_ID;
                $rootScope.property.Property_name = $scope.obj.data.Property_name;
                $rootScope.property.Unit_number = $scope.obj.data.Unit_number;
                console.log("xxxxxxxxxxxxxx - $rootScope.Unit_number: " + $rootScope.property.Unit_number);
                console.log("xxxxxxxxxxxxxx - $rootScope.Property_name: " + $rootScope.property.Property_name);
                console.log("xxxxxxxxxxxxxx - $rootScope.tenancy_id: " + $rootScope.tenancy_id);

                $scope.unitText = " U " + $rootScope.property.Unit_number;

                $rootScope.payment_transaction.Receipt_Name = $rootScope.property.Property_name + " " + $scope.unitText + " - ";
                console.log("xxxxxxxxxxxxxx - $rootScope.payment_transaction.Receipt_Name: " + $rootScope.payment_transaction.Receipt_Name);
                $scope.payment_transaction.Receipt_Name = $rootScope.payment_transaction.Receipt_Name;

                */

                if(status == 20){
                    $scope.tenancyData = response.data.data;
                    console.log($scope.tenancyData);

                    if($scope.tenancyData.Rent_Payment_ID_First){

                        //console.log("response.data.Rent_Payment_ID_First >>> " + response.data.Rent_Payment_ID_First);
                        console.log("First rent has been paid >>> " + $scope.tenancyData.Rent_Payment_ID_First);
                    }else{
                        alert("no first rent payment present");
                    }

                }else if(status == 25){
                    console.log("add first rent");

                    $scope.tenancySignedFirstRent = response.data;

                }else if(status == 34){
                    console.log("check if password matches");

                    $scope.tenancyPasswordMatches = response.data;
                    console.log($scope.tenancyPasswordMatches);

                }


                console.log("tenancies API");

                console.log(response);
                q2.resolve(response);


            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })

    $scope.accordion = function(element, element_data) {
        console.log("inside function accordion");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
            $('.data').hide();
        }
    }

    var vm = this;
    $scope.submitText = "Send Email";

    $scope.preview = "./preview.png";

    $scope.convertPDf = function() {

        console.log("inside function convertPDf");
        console.log("$rootScope.decodedToken.Notifications_Email >>> " + $rootScope.decodedToken.Notifications_Email);
        var date = new Date();

        var element = "maincont";
        var to_email = $scope.obj.data.Email;
        var attachmentFileLocation = "../uploads/";
        var fileName = 'tenancy-agreement';
        var from_email = "Clouderty | Tenancy Agreement " + " | " + $scope.obj.data.Property_name + " Unit " + $scope.obj.data.Unit_number + " | " + "<notifications@clouderty.co.uk>";
        var subject = "Welcome to Platinum Crown | Signed by " + $scope.obj.data.First_Name;
        var message =
            "Dear " + $scope.obj.data.First_Name + ", " + "<br /><br />" +
            "We would like to welcome you as a tenant to Platinum Crown and have " +
            "provided you below useful information for the start of your tenancy. " + "<br /><br />" +
            "Property address: " + $scope.obj.data.Address_1 + ", " + $scope.obj.data.Postcode + "<br /><br />" +
            "Date signed: " + date + "<br /><br />" +
            "Please find a copy of your tenancy agreement attached." + "<br /><br />" +
            "Your How to Rent Guide can be downloaded at http://platinumcrown.co.uk/files/How-To-Rent.pdf" + "<br /><br />" +
            "House information can be downloaded at http://platinumcrown.co.uk/files/House-Information.pdf" + "<br /><br />" +
            "<strong><u>Tenancy</u></strong>" + "<br />" +
            "Thank you for signing your tenancy agreement.  You have agreed to pay your rent on day <u>" + $scope.obj.data.Rent_Day + "</u> of each month. " +
            "Your tenancy and your tenancy expires on " + $scope.obj.data.Tenancy_end_date + "." + "<br /><br />" +
            "<strong><u>Renewal</u></strong>" + "<br />" +
            "The cost for renewing your tenancy is &#163;80.  Tenancies can be renewed from any period between 3-12 months in duration.  30 days before the end of your tenancy you either need to terminate your tenancy by writing to office@platinumcrown.co.uk or pay your renewal fee and state how long you would like to extend your tenancy by.  All tenants have to be on a current contract." + "<br /><br />" +
            "<strong><u>Periodic Payments</u></strong>" + "<br />" +
            "Thanks for providing us with your bank card details.  Periodic rent payment has been scheduled to come direct from your bank account on the " + $scope.obj.data.Rent_Day + " of each month. " +
            "It is your responsibility to inform us if your bank card details change during your tenancy.  If your rent fails because you haven't let us know then charges will apply. " + "<br /><br />" +
            "<strong><u>Difficulty paying the rent" + "</u></strong><br />" +
            "We want to work with you if you have any difficulties at all with paying your rent.  To do this, you need to contact Platinum Crown immediately by emailing office@platinumcrown.co.uk or phone so that we can work with you to keep the rental account up-to-date.  If you do not contact us, interest and penalty charges will accrue on your total debt and we may have no alternative but to take legal action." + "<br /><br />" +
            "<strong><u>End of Tenancy</u></strong>" + "<br />" +
            "On the last day of your tenancy, our Maintenance Manager will collect your keys and inspect your room at an appointed time previously arranged with you.  You are required to be present. If your keys can't be collected, it is your responsibility to post the keys back to us by recorded delivery to avoid being charged. Please ensure your room is left clear of rubbish and clean and tidy." + "<br /><br />" +
            "<strong><u>House Rules</u></strong>" + "<br />" +
            "Please find attached a copy of the house rules sent to all our tenants so that all housemates can enjoy their tenancy." + "<br /><br />" +
            "Furthermore, please find attached the government booklet entitled 'How to Rent'.  If you need any clarification with regard to this booklet please do let me know. " + "<br /><br />" +
            "If you have any issues at all with the property, other tenants, or your room please do not hesitate to contact  me and email our administration team at office@platinumcrown.co.uk." + "<br /><br />" +
            "We look forward to your tenancy with us." + "<br /><br />" +
            "Kind regards," + "<br />" +
            "Platinum Crown" + "<br /><br />" +
            "134 Hythe Hill" + "<br />" +
            "Colchester" + "<br />" +
            "Essex" + "<br />" +
            "CO1 2NF" + "<br />" +"<br />" +
            "Tel 01206 705 555" + "<br />" +
            "Fax 01206 705 556" + "<br />" +
            "www.platinumcrown.co.uk" + "<br />";

        UploadService.pdfAddHTML(element,fileName, attachmentFileLocation, status, to_email, from_email, subject, message);

        console.log("exiting function convertPDf");


    }

    $scope.resetForm = function () {
        angular.element("input[type='file']").val(null);
        $('#image-preview').attr('src', $scope.preview);
        $scope.filename = null;
        $scope.email = null;
    }

    $scope.tenancyAgreementConditionAgreed = function(tenancyAgreementConditionJoinID, agreed) {

        $scope.submitSignedTenancyButton = 1;
        $scope.submitTenancySpinner = "5";

        // Delete record
        console.log("ACTION: 1");
        var q5 = $q.defer();
        $scope.myValue = 0;
        $scope.promise = loginExternalWebPageService.init("1");
        //$scope.promise = $scope.getGlobalPromise();


        $scope.promise
            .then(function (data) {

                console.log("A2a loginfunction");
                //$rootScope.decode();
                return $scope.getGlobalPromise(data);

            })
            .then(function (data) {


                //if (confirm('Do you agree to this condition?')) {

                    console.log("Test 1");

                    console.log("Tenancy Agreement Condition ID: " + tenancyAgreementConditionJoinID);

                    if(agreed == "true"){
                        var Agreed = 1;

                        var subjectText = "Tenancy agreement condition agreed";
                        //var subject = "Clouderty | " + subjectText + "  | " + $scope.paymentTransactions.Description + " | Created by: " + $rootScope.decodedToken.Name + "| Cash Payment ID: " + $scope.paymentTransactions.CP_Transaction_ID;

                    }else if(agreed == "false"){
                        var Agreed = 0;
                        var subjectText = "Tenancy agreement condition not agreed";
                        //var subject = "Clouderty | " + subjectText + " | " + $scope.paymentTransactions.Description + " | Created by: " + $rootScope.decodedToken.Name + "| Cash Payment ID: " + $scope.paymentTransactions.CP_Transaction_ID;

                    }

                    //$scope.tenancies = {};


                    $scope.pageno = 1;
                    $scope.totalCount = 0;
                    $scope.itemsPerPage = 20;


                    $scope.search = "";
                    $scope.progress = {};
                    $scope.business = {};
                    $scope.properties = {};
                    $scope.units = [];

                    $scope.LandlordRegularCosts = [];
                    $scope.paymentTransactionCategory = [];
                    $scope.paymentTransactionCategories = [];
                    $scope.hideBusinessSearch = false;
                    $scope.DateToday = new Date();
                    $scope.date = new Date();
                    $scope.property = [];
                    $rootScope.property = {};
                    $rootScope.property.Property_name = "";
                    $rootScope.property.Unit_number = "";


                    //$scope.tenancies = [];
                    $scope.monthsExpiring = 0;
                    $scope.landlord = [];
                    $scope.landlord.Estate = "";
                    $scope.landlord.Estate_ID = 0;
                    $scope.landlord.Property = "";
                    $scope.landlord.Property_ID = 0;

                    $scope.tenancies.Agreed_Checkbox = agreed;
                    $scope.tenancies.Agreed = Agreed;
                    $scope.tenancies.Tenancy_Agreement_Conditions_Join_Tenancy_ID = tenancyAgreementConditionJoinID;
                    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk tenancyAgreementConditionID: " + $scope.tenancies.Tenancy_Agreement_Condition_ID);

                    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk $rootScope.tenancy_id: " + $rootScope.tenancy_id);
                    console.log($scope.tenancies);
                    $scope.tenanciesFunction($rootScope.tenancy_id, '37');

                //}





            })
            .then(function (data) {

                console.log('$rootScope.Property_ID >>>>>>>>>>>>>>>>>>>>>: ' + $rootScope.Property_ID);
                console.log("$rootScope.payment_transaction.Payment_Transaction_Card_Completed >>>>>>>>>>>>>>>>>>>>>>>" + $rootScope.payment_transaction.Payment_Transaction_Card_Completed);
                $scope.submitSignedTenancyButton = 0;
                //$rootScope.tenancyBlock = 1;
                $rootScope.tenancyAgreementBlock = 1;
                console.log('A11');
                $scope.myValue = data;

            }, function (err) {
                $scope.myValue = err;
            })

        q5.resolve($scope.promise);


        return q5.promise;






    }

    $scope.scrollTo = function(element){
        scrollToService.scroll(element);
    }

    $scope.gotoAnchor = function(x) {
        console.log("inside function gotoAnchor");
        var newHash = x;
        if ($location.hash() !== newHash) {
            // set the $location.hash to `newHash` and
            // $anchorScroll will automatically scroll to it
            $location.hash(x);
        } else {
            // call $anchorScroll() explicitly,
            // since $location.hash hasn't changed
            $anchorScroll();
        }
    };

}])