/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("processesAddEditCtrl", [ "fileUploadService", "$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "dateInputService", "moment", "scrollToService", "scrollToService", function(fileUploadService, $scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, dateInputService, moment, scrollToService) {

    console.log("inside processAccountAddEditCtrl")

    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;
    $scope.search = ""

    $scope.New_Record = "";
    $scope.pageBlock = '1';
    $scope.progress = {};
    $scope.pageBlock = '0';
    $scope.testBlock = '5';
    $scope.counter = '0';
    $scope.message = "";
    $rootScope.pageBlockSpinner = 0;
    $scope.resultTextBlock = 0;

    $scope.business = {};
    $scope.hideBusinessSearch = false;
    $scope.properties = [];
    $scope.units = [];
    $scope.unitText = "";
    $scope.unitBlock = {result: false};
    $scope.unitBlock.result = false;
    $scope.tenancies = [];

    $scope.monthsExpiring = 0;
    $scope.landlord = [];
    $scope.landlord.Estate = "";
    $scope.landlord.Estate_ID = 0;
    $scope.landlord.Property = "";
    $scope.landlord.Property_ID = 0;


    $scope.propertiesInput = {};
    $scope.search_property = "";
    $scope.hidePropertySearch = 0;
    $scope.hidePropertySearchEncase = 1;

    $scope.processesData = {};
    $scope.processesAccountData = {};

    $scope.processes_account = {};
    $scope.processes_account.tenancies = {};

    console.log("$stateParams.processAccountID: " + $stateParams.processAccountID);

    if (!$stateParams.processAccountID) {
        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);
        console.log("$stateParams.processAccountID == null");

        $scope.New_Record = true;
    }

    //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

    $scope.processesAccountFunction = function(id, status) {

        console.log("function processesAccountFunction");
        //alert("function processesAccountFunction");

        if(!$scope.processes_account.Scheduled_Completion_Date){

        }else{
            if(status == 4){
                $scope.processesAccountData.Scheduled_Completion_Date = dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0);
                //$scope.processesAccountData.Scheduled_Completion_Date = $scope.processes_account.Scheduled_Completion_Date;
                console.log($scope.processesAccountData.Scheduled_Completion_Date);
                console.log(dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0));
                //$scope.processesAccountData.Scheduled_Completion_Date = "2018-01-01";

            }else{

                $scope.processesAccountData.Scheduled_Completion_Date = dateInputService.dateInputDayService($scope.processes_account.Scheduled_Completion_Date, 0);
                console.log($scope.processesAccountData.Scheduled_Completion_Date);
            }

        }


        if(!$scope.processes_account.Process_Type_ID){

        }else{
            $scope.processesAccountData.Process_Type_ID = $scope.processes_account.Process_Type_ID;
        }

        if(!$scope.processes_account.Property_ID){

        }else{

            $scope.processesAccountData.Property_ID = $scope.processes_account.Property_ID;

        }

        if(!$scope.processes_account.Unit_ID){

        }else{

            $scope.processesAccountData.Unit_ID = $scope.processes_account.Unit_ID;

        }

        if(!$scope.processes_account.Tenancy_ID){

        }else{

            $scope.processesAccountData.Tenancy_ID = $scope.processes_account.Tenancy_ID;

        }

        if(!$scope.processes_account.Customer_ID){

        }else{

            $scope.processesAccountData.Customer_ID = $scope.processes_account.Customer_ID;

        }

        console.log($rootScope.decodedToken.Clouderty_System_Administrator);
        $scope.processesAccountData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.processesAccountData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.processesAccountData.itemsPerPage = $scope.itemsPerPage;
        $scope.processesAccountData.pageno = $scope.pageno;



        $scope.processesAccountData.User_ID = $rootScope.decodedToken.id;
        $scope.processesAccountData.Status = status;
        $scope.processesAccountData.Procress_ID = id;

        if($scope.processesAccountData.Procress_ID){
            //console.log("$scope.tasksFunction.processes_account_ID" + $scope.paymentTransactionsData.processes_account_ID);
        }
        else{

            $scope.processesAccountData.Procress_ID = "*";
        }

        if($scope.search){

            $scope.processesAccountData.search = $scope.search;
        }
        else{

            $scope.processesAccountData.search = "*";
        }

        var qprocessesAccountFunction2 = $q.defer();

        console.log($scope.processesAccountData);
        apiService.processes(id, $scope.processesAccountData).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                $scope.processData = response.data.data;

                console.log("status: " + status);
                if(status == 4){

                    $scope.processAccount = response.data.data;
                    $scope.processAccountID = response.data.Process_Account_ID;
                    $scope.processAccountAdded = '1';
                    console.log("Process added");
                    console.log(response);

                }
                if(status == 63){

                    $scope.processTypes = response.data.processTypes;
                }

                console.log("processes API successful");

                console.log(response);
                qprocessesAccountFunction2.resolve(response);


            },
            function () {
                console.log("API Service Error: processes")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return qprocessesAccountFunction2.promise;
    }

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )


        return q55.promise;
    }

    $rootScope.paymentResultText = "";

    $scope.getProcessTypes = function () {
        console.log("$scope.getPaymentTransactionTypes");
        var qgetProcessTypes41 = $q.defer();


        qgetProcessTypes41.resolve($scope.processesAccountFunction("*", 63));

        return qgetProcessTypes41.promise;
    }

    $scope.getProcessType = function (precessType) {
        console.log("inside function getProcessType");

        $scope.processes_account.Process_Type_ID = precessType.Process_Type_ID;
        $scope.processes_account.Description = precessType.Description;
        console.log("$scope.processes_account.Process_Type_ID: " + $scope.processes_account.Process_Type_ID);

    }

    $scope.uploadFile = function () {


        $rootScope.pageBlockSpinner = 1;
        $scope.processesData.tableName = "csv_test";

        var file = $scope.myFile;
        var uploadUrl = "api/upload-csv", //Url of web service
        promise = fileUploadService.uploadFileToUrl(file, uploadUrl, $scope.processesData);

        promise.then(function (response) {
                $scope.serverResponse = response;
                console.log("resp after promise",response);
                $rootScope.pageBlockSpinner = 0;
            })
            .then(function (data) {

                $scope.resultTextBlock = 1;
                $scope.processAccountResultText = "PROCESS SUCCESSFULLY ADDED";
                return $scope.getGlobalPromise(data);
            }, function () {
                $rootScope.pageBlockSpinner = 0;
                $scope.serverResponse = 'An error has occurred';
            })
    };

    //////////////////////////  searchProperty  /////////////////////////////////////////////////////////////////////////////////

    $scope.propertiesFunction = function(id, status) {

        $scope.propertiesInput.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.propertiesInput.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.propertiesInput.itemsPerPage = $scope.itemsPerPage;
        $scope.propertiesInput.pageno = $scope.pageno;
        $scope.propertiesInput.Property_ID = id;
        $scope.propertiesInput.Estate_ID = '0';
        $scope.propertiesInput.User_ID = $rootScope.decodedToken.id;
        //$scope.propertiesInput.Property_ID = '0';
        $scope.propertiesInput.Status = status;

        if($scope.propertiesInput.search){

        }else if($scope.search){

            $scope.propertiesInput.search = $scope.search;
        }else{

            $scope.propertiesInput.search = "*";
        }

        //$scope.obj.search = "*";

        console.log("$scope.propertiesInput >>>>>>>>>>>>>>>>>");
        console.log($scope.propertiesInput);

        var q2 = $q.defer();
        //console.log($scope.paymentTransactionsData);

        apiService.properties(id, $scope.propertiesInput).then(
            function (response) {
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");

                    //$scope.$parent.obj2 = {};
                    $scope.properties = response.data.data;

                console.log(response);
                console.log("tenancies API");

            },
            function () {
                console.log("API Service Error: tenancies")
            }
        );

        if($scope.search == "*"){
            $scope.search = "";
        }
        else{

        };

        return q2.promise;
    }

    $scope.searchProperty = function (search_property) {
        //$scope.properties = null;
        console.log("searchProperty in");
        $scope.properties = [];
        $scope.hidePropertySearch = 0;
        $scope.hidePropertySearchEncase = 0;

        $scope.propertiesInput.search = search_property;
        console.log("$scope.search_property: " + search_property);

        console.log("$scope.search_property: " + $scope.search_property);
        $scope.propertiesFunction('*', 94);

    }

    $scope.processes_account.selectedProperties = [];

    $scope.processes_account.selectedProperties.Units = [];

    $scope.selectPropertyEdit = function(id) {
        console.log("selectProperty in");
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzz");
        apiService.getProperty(id, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                //$http.get("api/property/" + id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
                $scope.processes_account.selectedProperties.push({
                    "Property_ID": response.data.data.Property_ID,
                    "Address": response.data.data.Address_1,
                    "Town": response.data.data.Town,
                    "City": response.data.data.City,
                    "Postcode": response.data.data.Postcode
                })
                $scope.processes_account.Address_1 = response.data.data.Address_1;
                $scope.processes_account.Property_name = response.data.data.Property_name;
                $scope.processes_account.Postcode = response.data.data.Postcode;
                $scope.processes_account.Unit_number = response.data.data.Unit_number;
                $scope.processes_account.Unit_ID = response.data.data.Unit_ID;
                angular.forEach(response.Units, function (unit, index) {
                    $scope.processes_account.selectedProperties.Units.push({
                        "Unit_ID": unit.Unit_ID,
                        "Unit_Number": unit.Unit_number
                    })
                })
            },
            function () {
                console.log("There was an error in getProperties")
            }
        )
    }

    $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {

        delete $scope.search_property;
        $scope.hidePropertySearch = 1;
        $scope.hidePropertySearchEncase = 1;

        var qselectProperty23 = $q.defer();
        console.log("selectProperty in property_id: >>>>>>>> " + property_id);
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmmzzzzzzzzzzzzzzzbbbbbbbbbbbbbbbbbbbb");
        $http.get("api/property/" + property_id + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function (response) {
            $scope.processes_account.selectedProperties.push({
                "Property_ID": response.data.Property_ID,
                "Address": response.data.Address_1,
                "Town": response.data.Town,
                "City": response.data.City,
                "Postcode": response.data.Postcode
            })
            angular.forEach(response.Units, function (unit, index) {
                $scope.processes_account.selectedProperties.Units.push({
                    "Unit_ID": unit.Unit_ID,
                    "Unit_Number": unit.Unit_number
                })
            })
            console.log(response);
            console.log(response.data);

            $scope.processes_account.Property_ID = property_id;
            $scope.selected_property_name = property_name;
            $scope.processes_account.selected_property_name = property_name;
            $scope.processes_account.Property_name = property_name;
            $scope.processes_account.Address_1 = address_1;
            $scope.processes_account.Bank_ref = bank_ref;
            //$rootScope.property.Property_name = property_name;
            //$scope.createPaymentIDPaymentTypeProperty();

            console.log("$scope.processes_account.Property_ID: " + $scope.processes_account.Property_ID);

            $scope.getProperties();

            qselectProperty23.resolve(response);

        })



        return qselectProperty23.promise;


    }

    $scope.deselectProperty = function (index) {
        $scope.processes_account.selectedProperties.splice(index, 1);
        $scope.hidePropertySearch = false;
        $scope.units = [];
        $scope.properties = [];
    }

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;
        console.log("$scope.getPropertiesSearch");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.properties = response.data;
            },
            function () {
                console.log("There was an error")
            }
        )
    }

    $scope.getProperties = function () {
        console.log("We are in in getProperties xxxxxxxxxxxxxxxxxxxxxxxxxxxxxmmmmmmmmmmmmm");
        if($scope.processes_account.Property_ID){
            console.log("We are in in getProperties if statement");
            apiService.getProperty($scope.processes_account.Property_ID, $rootScope.decodedToken.Account_Holder_ID).then(
                function (response) {
                    $scope.units = response.data.units;
                    //$scope.processes_account.Unit_number = response.data.units.Unit_number;
                    //$rootScope.property.Unit_number = response.data.units.Unit_number;
                    console.log("$scope.units: " + $scope.units);
                    console.log("$scope.processes_account.Unit_number: " + $scope.processes_account.Unit_number);

                    return response;
                },
                function () {
                    console.log("There was an error in getProperties")
                }
            )

        }

    }
    
    //////////////////////////  Units  /////////////////////////////////////////////////////////////////////////////////

    $scope.getUnit = function () {
        $scope.processes_account.Unit_number = $scope.processes_account.units.Unit_number;
        console.log("Unit_ID: " + $scope.processes_account.units.Unit_ID);

        apiService.getTenanciesUnit($scope.processes_account.units.Unit_ID, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {
                $scope.tenancies = response.data;
                $scope.processes_account.tenancies = response.data;
                $scope.processes_account.Unit_number = $scope.processes_account.tenancies.Unit_number;

                console.log(response.data);

                console.log("$scope.processes_account.Unit_number: " + $scope.processes_account.Unit_number);

                //if(!$scope.processes_account.Unit_number){
                //$scope.unitText = "";
                //}else{
                $scope.unitText = " U " + $scope.processes_account.Unit_number;
                //}

                console.log($scope.tenancies.data);
            },
            function () {
                console.log("There was an error in getTenanciesUnit")
            }
        )


    }

    //////////////////////////  Tenancies  /////////////////////////////////////////////////////////////////////////////////

    $scope.getTenancy = function () {

        $scope.processes_account.tenantNameText = "";
        $scope.processes_account.Customer_ID = $scope.processes_account.tenancies.Customer_ID;
        $scope.processes_account.Tenancy_ID = $scope.processes_account.tenancies.Tenancy_ID;

        $scope.processes_account.First_Name = $scope.processes_account.tenancies.First_Name;
        $scope.processes_account.tenantNameText = $scope.processes_account.First_Name;
        $scope.processes_account.Last_Name = $scope.processes_account.tenancies.Last_Name;

        if($scope.processes_account.tenancies.Last_Name){

            $scope.processes_account.tenantNameText = $scope.processes_account.tenantNameText + " " + $scope.processes_account.Last_Name;

        }

        $scope.processes_account.Unit_number = $scope.processes_account.tenancies.Unit_number;

        console.log("Tenancy ID: " + $scope.processes_account.Tenancy_ID);
        console.log("Unit_number: " + $scope.processes_account.Unit_number);

        console.log("Customer_ID: " + $scope.processes_account.Customer_ID);
        $scope.unitText = " U " + $scope.processes_account.Unit_number;

    }

    $scope.addProcessAccount = function (processAccount) {
        console.log("addTransaction.");


        var qaddProcessAccount = $q.defer();

        if(confirm("Do you want to add a process account?") == true){

            $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
            $scope.processes_account.pageno = $scope.pageno;

            $scope.processes_account.addButton = '1';
            delete $scope.processes_account.addButton.value;
            delete $scope.processes_account.addButton;

            $scope.a = [];

            $scope.a = processAccount;





            var myValue = 0;
            var promise = $scope.getGlobalPromise();

            $scope.promise
                .then(function (data) {

                    console.log('A7b hide data');
                    return $scope.processesAccountFunction(0, 4);
                })
                .then(function () {

                        console.log('A7b hide data');

                        if(confirm("Do you want to go to the new account?")){

                            console.log('A7b hide data');
                            $state.go('lettings-main-nav.lettings-side.process-account-view.notes', {processAccountID:  $scope.processAccountID})

                        }else{
                            $state.reload('lettings-main-nav.lettings-side.process-account-add-edit');
                        }

                })
                .then(function () {

                        console.log('A7c send email confirmation');
                        $scope.date = new Date();
                        $scope.propertyText = "";


                        $scope.propertyText = $scope.processes_account.Property_name;

                        if($scope.processes_account.Unit_number){
                            $scope.propertyText = $scope.propertyText + " U " + $scope.processes_account.Unit_number;
                        }

                        var from_email = "Clouderty | Process Account Created " + $scope.processAccountID + " | " + $scope.processes_account.Description + " <notifications@clouderty.co.uk>";
                        var subject = $rootScope.decodedToken.Name + " | " + $scope.propertyText + " | " + $scope.processes_account.Description;
                        var message = "Process Account ID: " + $scope.processAccountID + "<br /><br />" +
                            "Property: " + $scope.propertyText + "<br /><br />" +
                            "Process type: " + $scope.processes_account.Description + "<br /><br />" +
                            "Process created by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                            "Date created: " + $scope.date + "<br /><br />";

                        var message =  message + "Tenant Name: " + $scope.processes_account.tenantNameText + "<br /><br />";

                        $scope.postUserActivityServiceMessage = subject;
                        $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                        to_email = $rootScope.decodedToken.Notifications_Email;

                        $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                    }
                , function (err) {

                    console.log('A7c error');


                    $scope.myValue = err;
                })

            qaddProcessAccount.resolve($scope.promise);





        }else{
            console.log("No account added");

        }
    }

    $scope.addProcesses = function (processes) {
        console.log("in function addProcesses");
        $scope.processesAccountFunction(0, 41);
        /*
             var qaddProcesses = $q.defer();

             if(confirm("Do you want to add a process account?") == true){

                 $scope.processes_account.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                 $scope.processes_account.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                 $scope.processes_account.itemsPerPage = $scope.itemsPerPage;
                 $scope.processes_account.pageno = $scope.pageno;

                 $scope.processes_account.addButton = '1';
                 delete $scope.processes_account.addButton.value;
                 delete $scope.processes_account.addButton;

               ope.a = [];

                 $scope.a = processAccount;






                 var myValue = 0;
                 var promise = $scope.getGlobalPromise();

                 promise
                     .then(function (data) {

                         console.log('A7b hide data');
                         return $scope.processesAccountFunction(0, 4);
                     })
                     .then(function () {

                         console.log('A7b hide data');

                         if(confirm("Do you want to go to the new account?")){

                             console.log('A7b hide data');
                             $state.go('lettings-main-nav.lettings-side.process-account-view.notes', {processAccountID:  $scope.processAccountID})

                         }else{
                             $state.reload('lettings-main-nav.lettings-side.process-account-add-edit');
                         }

                     })
                     .then(function () {

                             console.log('A7c send email confirmation');
                             $scope.date = new Date();
                             $scope.propertyText = "";


                             $scope.propertyText = $scope.processes_account.Property_name;

                             if($scope.processes_account.Unit_number){
                                 $scope.propertyText = $scope.propertyText + " U " + $scope.processes_account.Unit_number;
                             }

                             var from_email = "Clouderty | Process Account Created " + $scope.processAccountID + " | " + $scope.processes_account.Description + " <notifications@clouderty.co.uk>";
                             var subject = $rootScope.decodedToken.Name + " | " + $scope.propertyText + " | " + $scope.processes_account.Description;
                             var message = "Process Account ID: " + $scope.processAccountID + "<br /><br />" +
                                 "Property: " + $scope.propertyText + "<br /><br />" +
                                 "Process type: " + $scope.processes_account.Description + "<br /><br />" +
                                 "Process created by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                 "Date created: " + $scope.date + "<br /><br />";

                             var message =  message + "Tenant Name: " + $scope.processes_account.tenantNameText + "<br /><br />";

                             $scope.postUserActivityServiceMessage = subject;
                             $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                             to_email = $rootScope.decodedToken.Notifications_Email;

                             $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                         }
                         , function (err) {

                             console.log('A7c error');


                             $scope.myValue = err;
                         })

                 qaddProcesses.resolve(promise);





        }else{
            console.log("No account added");

        }

        */
    }

    $scope.loadProcessData = function () {
        console.log("inside function loadProcessData");



        console.log("$stateParams.id: " + $stateParams.id);
        //$scope.$state = $state;
        var q = $q.defer();
        $scope.promise = $scope.getGlobalPromise();

        $scope.promise
            .then(function (data) {

                console.log("A2");

                //return $scope.getPaymentTransactionCategoriesAll(data);
                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                console.log("A3 getProcessTypes()");

                //return $scope.getPaymentTransactionTypes(data);
                return $scope.getProcessTypes(data);
            })
            .then(function (data) {

                console.log("A4");

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                return $scope.getGlobalPromise(data);
            })
            .then(function (data) {

                $scope.paymentBlockSpinner = '0';
                $scope.pageBlock = '1';
                return $scope.getGlobalPromise(data);


            })
            .then(function (data) {
                console.log('A7a');

                return $scope.getGlobalPromise(data);

                //$scope.myValue = data;
            },
                function (err) {
                $scope.myValue = err;
            })

        q.resolve($scope.promise);

    }

    $scope.initialPageLoad = function() {
        console.log("inside function initialPageLoad");
        var qinitialPageLoad66 = $q.defer();


        qinitialPageLoad66.resolve($scope.obj = $scope.loadProcessData());


        return qinitialPageLoad66.promise;


    }

    //////////////////////////  Auto Load  /////////////////////////////////////////////////////////////////////////////////

    $scope.initialPageLoad();
}]);