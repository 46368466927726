/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("truncateSliceService", [function () {

    this.truncateSliceService = function(stringToTruncate, characterLimit) {


        //var a = $scope.obj.sampleText;
        //var n = 8;
        var stringToTruncateOriginal = stringToTruncate;
        var str = stringToTruncate.replace(stringToTruncate.slice(characterLimit), "");

        if(stringToTruncateOriginal == str){


        }else{

            str = str + "...";

        }

        console.log("Truncated word: >>>>>>>> " + str);

        console.log("timeInputService");
        return str;

    }


}])
