/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('maintenance', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            maintenanceobj: '=', //scope dynamic variable
            maintenancematerialslabour: '=', //scope dynamic variable
            maintenanceid: '=', //scope dynamic variable
            spinnertype: '=', //scope dynamic id variable - 1: ID - 2: object not null
            spinnerobj: '=', //scope dynamic variable
            maintenancedescription: '=', //scope dynamic variable
            descriptiontext: '@' //string variable
        },
        templateUrl: 'views/maintenance/maintenance.html',
        link:function(scope,elem,attrs){

        },
        controller: 'maintenanceCtrl'
    }
}])