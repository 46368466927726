/**
 * Created by Aaron on 29/07/2016.
 */
clouderty.directive('cloudertyAdminHide', ["$rootScope", function ($rootScope) {
    return {
        restrict: 'EA',
        scope: {       // create an isolate scope
            spinnervariable: '=', //scope dynamic variable
            spinnerviewvariable: '=', //scope dynamic variable
            spinnerobj: '=', //scope dynamic variable
            spinnerobjsingleid: '=', //scope dynamic variable
            spinnertype: '=', //scope dynamic variable - 1: ID - 2: object not null
            loadingtext: '@' //string variable
        },
        link:function(scope,elem,attrs){
            self.isVisible = false;
        },
        controller: 'cloudertyAdminHideCtrl'
    }
}])