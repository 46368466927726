/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("stateAuthCheckService", ['apiService', '$q', '$rootScope', '$state', '$timeout',  function (apiService, $q, $rootScope, $state, $timeout) {

    console.log("stateAuthCheckService service");

    this.runAuthCheckRootScope = function(stateName) {

        var deferred = $q.defer();

        console.log("runAuthCheckRootScope");
        var statesObj = {};
        var statesObjResolved = {};
        var myValue = "";

        statesFunction = function() {

            var q55555 = $q.defer();

            statesObj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;    statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            statesObj.User_Level = $rootScope.decodedToken.User_Level;
            statesObj.User_Type_ID = $rootScope.decodedToken.User_Type_ID;
            statesObj.pageno = 1;
            statesObj.itemsPerPage = 20;
            statesObj.Account_Holder_ID = 1;
            statesObj.Status = 5;
            //statesObj.search = 'lettings-main-nav.financials-side.dashboard-financial';
            statesObj.search = stateName;
            console.log(statesObj);

            /*
            apiService.states('*', statesObj).then(
                function (response) {
                    console.log(response);
                    statesObjResolved = response.data.data;
                    q55555.resolve(response);
                }
            )
            */
            console.log($rootScope.statesList);

            var statePreLoadedObj = $rootScope.statesList.data;
            var stateJoinPreLoadedObj = $rootScope.statesList.dataStateJoins;
            $rootScope.statePreLoadedObjResolved = null;

            angular.forEach(statePreLoadedObj, function(value, index){

                console.log('A');

                //console.log(index);
                //console.log(value.State_Description);

                var statePreLoadedObjStateID = value.State_ID;
                var statePreLoadedObjUserLevel = value.User_Level;
                var statePreLoadedObjStateDescription = value.State_Description;


                console.log('statePreLoadedObjStateID : ' + statePreLoadedObjStateID);
                console.log('statePreLoadedObjUserLevel : ' + statePreLoadedObjUserLevel);
                console.log('statePreLoadedObjStateDescription : ' + statePreLoadedObjStateDescription);
                console.log('statePreLoadedObj : ' + statePreLoadedObj);
                console.log('stateJoinPreLoadedObj : ' + stateJoinPreLoadedObj);


                angular.forEach(stateJoinPreLoadedObj, function(value, index){
                    console.log('B');
                    var stateJoinPreLoadedObjStateID = value.State_ID;
                    console.log('statePreLoadedObjStateDescription : ' + statePreLoadedObjStateDescription);
                    console.log('stateName: ' + stateName);

                    if(statePreLoadedObjStateDescription == stateName){
                        console.log('statePreLoadedObjStateDescription: ' + statePreLoadedObjStateDescription);
                        console.log('stateName: ' + stateName);

                        if(statePreLoadedObjStateID == stateJoinPreLoadedObjStateID){
                            console.log('C');
                            console.log('statesObj.User_Type_ID: ' + statesObj.User_Type_ID);
                            console.log('value.User_Type_ID: ' + value.User_Type_ID);
                            console.log('statesObj.User_Level: ' + statesObj.User_Level);
                            console.log('statePreLoadedObjUserLevel: ' + statePreLoadedObjUserLevel);
                            if((statesObj.User_Type_ID == value.User_Type_ID) && (statesObj.User_Level >= statePreLoadedObjUserLevel)){
                                console.log('D');
                                console.log('value.State_User_Type_Join_ID: ' + value.State_User_Type_Join_ID);
                                $rootScope.statePreLoadedObjResolved = value.State_User_Type_Join_ID;

                                q55555.resolve($rootScope.statePreLoadedObjResolved);
                            }
                        }
                    }

                    if($rootScope.statePreLoadedObjResolved == null){
                        q55555.resolve(null);
                    }


                })

            });


            return q55555.promise;
        }


        var promise = statesFunction();

        promise
            .then(function () {
                console.log("A2");
                $timeout(function() {
                    console.log("$rootScope.statePreLoadedObjResolved >>> " + $rootScope.statePreLoadedObjResolved);
                    if($rootScope.statePreLoadedObjResolved != null){
                        console.log('State found');
                        return deferred.resolve($rootScope.statePreLoadedObjResolved);
                    }else{
                        console.log('No state found');
                        //alert('Access denied');


                        $state.go('login.signin');
                        deferred.reject();
                    }
                });

            })
            .then(function (data) {
                console.log('A7');
                myValue = data;
            }, function (err) {
                myValue = err;
            })

        deferred.resolve(promise);

        return deferred.promise;
    }
    this.runAuthCheck = function(stateName) {

        var deferred = $q.defer();

        console.log("hello");
        var statesObj = {};
        var statesObjResolved = {};
        var myValue = "";

        statesFunction = function() {

            var q55555 = $q.defer();

            statesObj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;    statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            statesObj.User_Level = $rootScope.decodedToken.User_Level;
            statesObj.User_Type_ID = $rootScope.decodedToken.User_Type_ID;
            statesObj.pageno = 1;
            statesObj.itemsPerPage = 20;
            statesObj.Account_Holder_ID = 1;
            statesObj.Status = 5;
            statesObj.search = stateName;
            console.log(statesObj);

            apiService.states('*', statesObj).then(
                function (response) {
                    console.log(response);
                    statesObjResolved = response.data.data;
                    q55555.resolve(response);
                }
            )

            return q55555.promise;
        }

        var promise = statesFunction();

        promise
            .then(function () {
                console.log("A2");
                $timeout(function() {
                    console.log("statesObjResolved.Active >>> " + statesObjResolved.Active);
                    if(statesObjResolved.Active == 1){
                        console.log('State found');
                        return deferred.resolve(statesObjResolved);
                    }else{
                        console.log('No state found');
                        $state.go('login.signin');
                        deferred.reject();
                    }
                });

            })
            .then(function (data) {
                console.log('A7');
                myValue = data;
            }
            , function (err) {
                myValue = err;
            })

        deferred.resolve(promise);
        return deferred.promise;
    }
    this.getStates = function() {


        console.log("getStates");
        var deferred = $q.defer();

        var statesObj = {};
        var statesObjResolved = {};
        var myValue = "";

        console.log("$rootScope.decodedToken.Account_Holder_ID: " + $rootScope.decodedToken.Account_Holder_ID);

        statesFunction = function() {
            console.log("statesFunction");
            var q55555 = $q.defer();

            statesObj.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            statesObj.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            statesObj.User_Level = $rootScope.decodedToken.User_Level;
            statesObj.User_Level_ID = $rootScope.decodedToken.User_Level_ID;
            statesObj.User_Type_ID = $rootScope.decodedToken.User_Type_ID;
            statesObj.pageno = 1;
            statesObj.itemsPerPage = 20;
            statesObj.Status = 0;
            //statesObj.search = stateName;
            console.log(statesObj);

            apiService.states('*', statesObj).then(
                function (response) {
                    console.log(response);
                    statesObjResolved = response.data.data;
                    $rootScope.statesList = response.data;
                    q55555.resolve(response);
                }
            )

            return q55555.promise;
        }

        var promise = statesFunction();

        promise
            .then(function () {
                console.log("A2");
                $timeout(function() {
                    //console.log("statesObjResolved.Active >>> " + statesObjResolved.Active);
                    //if(statesObjResolved.Active == 1){
                    console.log('States loaded');
                    console.log(statesObjResolved);
                    return deferred.resolve(statesObjResolved);
                    //}else{
                      //  console.log('No state found');
                        //$state.go('login.signin');
                        //deferred.reject();
                    //}
                });

            })
            .then(function (data) {
                console.log('A7');
                myValue = data;
            }, function (err) {
                myValue = err;
            })

        deferred.resolve(promise);
        return deferred.promise;
    }

}])
