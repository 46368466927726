/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("paymentTransactionRecurringAddEditCtrl", ["$scope", "$http", "$stateParams", "$state", "$uibModal", "Upload", "emailService", "postNoteService", "sendEmailInternalPostNote", "$rootScope", "$window", "$filter", "postUserActivityService", "$q", "apiService", "yesNoFilter", function($scope, $http, $stateParams, $state, $uibModal, Upload, emailService, postNoteService, sendEmailInternalPostNote, $rootScope, $window, $filter, postUserActivityService, $q, apiService, yesNoFilter) {
    console.log("End 1");

    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {
            console.log('is this it 1');
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })


    //close all accordions
    $('.data').hide();
    $('.accordion-1-form').show();

    $scope.search
    $scope.progress = {};
    $scope.payment_transaction_recurring = {};









    $scope.business = {};
    $scope.properties = {};
    $scope.units = [];
    $scope.LandlordRegularCosts = [];
    $scope.paymentTransactionCategory = [];
    $scope.paymentTransactionCategories = [];
    $scope.hideBusinessSearch = false;
    $scope.DateToday = new Date();
    $scope.date = new Date();

    $scope.selected_property_name = "";

    $scope.itemsPerPage = 20;
    $scope.pageno = 1;
    $scope.totalCount = 0;



    $scope.addPaymentRecurringPayment = function(Transaction) {

        $scope.a =  {};
        $scope.a = Transaction;

        if(Transaction.First_Payment_Date == null){
            confirm('Enter the date of first payment')
            return "";
        }else if(Transaction.Day_of_Payment == null){
            confirm('Enter the day of payment')
            return "";
        }else if(Transaction.No_of_Payments == null){
            confirm('Enter number of payments')
            return "";
        }else if(Transaction.Tenancy_ID == null){
            confirm('Select a tenancy')
            return "";
        }else{
        }

        $http.get("api/tenancy/" + $scope.payment_transaction_recurring.Tenancy_ID + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response)  {

            $scope.b = [];
            $scope.b = response;
            $scope.a.Rental_Amount = $scope.b.data.Monthly_rental_price;

            //$scope.a.Payment_Transaction_Category_ID = 1;
            //$scope.a.Type = 'Testing';
            //$scope.a.Payment_ID = 'Test';
            //$scope.a.Status = "Active";
            //$scope.a.Amount = "33333";
            //$scope.a.Day_of_Payment = "1";
            //$scope.a.First_Payment = "12/12/2012";
            //$scope.a.No_of_Payments = "99999999";
            //$scope.a.Notes = "Test";
            //$scope.a.Description = "Test";
            //$scope.a.Miscellaneous_Check_Box = "0";
            //$scope.a.Date_Payments_Stopped = "11/11/2011";
            //$scope.a.PTR_Form_Signed = 1;
            //$scope.a.PTR_Agreement_Signed = 1;
            //$scope.a.PTR_Agreement_Signed_Date = "10/10/2017";
            //$scope.a.Business_ID = "1";
            //$scope.a.Property_ID = "1";
            //$scope.a.Tenancy_ID = 1;
            //$scope.a.Last_four_digits_bank_card = "1111";
            $scope.a.User_ID = '1';





            /*
            $scope.a.Cheque_processed = 0;
            if ($scope.a.Payment_Transaction_Type == "CHEQUE") {
                $scope.a.Cheque = 1;
            }else{
                $scope.a.Cheque = 0;
            }
            $scope.a.Received = 0;
            $scope.a.Business_ID = 1;
            $scope.a.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID
            $scope.a.User_ID = $rootScope.decodedToken.id;
            $scope.a.Date_Received = $scope.DateToday;
            if(!$scope.payment_transaction.Notes){
                $scope.payment_transaction.Notes = "None"
            }else{
            }

            console.log($scope.payment_transaction.Date);
            var payment_transaction_date = $filter('date')($scope.payment_transaction.Date, 'dd') + "/" + $filter('date')($scope.payment_transaction.Date, 'MM') + "/" + $filter('date')($scope.payment_transaction.Date, 'yyyy');
            $scope.a.Date = $scope.payment_transaction.Date;
            */

            if (confirm('Are you sure you want to add a recurring payment?')) {

                apiService.addPaymentTransactionRecurring($scope.a).then(
                    function (response) {
                        $scope.a = response;
                        console.log($scope.a);
                    },
                    function () {
                        alert("There was an error in addPaymentTransactionRecurring")
                    }
                )

            }else{
                return;
            }


            var from_email = "Clouderty | Recurring Payment Setup  | " + "Rent" + " | " + $scope.payment_transaction_recurring.Property_name + " Unit " + $scope.payment_transaction_recurring.Unit_number  + " <notifications@clouderty.co.uk>";
            var subject = "Clouderty | Recurring Payment Setup | " + $scope.payment_transaction_recurring.Property_name + " Unit " + $scope.payment_transaction_recurring.Unit_number + " | " + "Rent" + " | "  + $rootScope.decodedToken.Name + " | " + $scope.payment_transaction_recurring.Property_name;
            var message = "Setup by: " + $rootScope.decodedToken.Name + "\n\n" +
                "Business: Platinum Crown" + "\n" +
                "Date setup: " + $scope.payment_transaction_recurring.Date_Entered + "\n" +
                "Payment category: Rent" + "\n" +
                "Payment property name: " + $scope.payment_transaction_recurring.Property_name + "\n\n" +
                "Amount:" + " " + $filter('currency')($scope.payment_transaction_recurring.Rental_Amount, '£') + "\n" +
                "Description: " + $scope.payment_transaction_recurring.Description + "\n\n";

            var to_email = "office@platinumcrown.co.uk";

            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
            $('.data').hide();



        })
    }

    //$scope.addTransaction();

    $scope.getPropertiesSearch = function () {
        $scope.properties = null;
        console.log("$scope.getPropertiesSearch");
        apiService.searchProperties($scope.search_property, $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.properties  = response.data;

            },
            function() {
                alert("There was an error")
            }
        )
    }

    $scope.getProperties = function () {
        console.log("We are in in getProperties");
        apiService.getProperty($scope.payment_transaction_recurring.Property_ID , $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.units = response.data.units;
                $scope.payment_transaction_recurring.Property_name = response.data.data.Property_name;
                $scope.payment_transaction_recurring.Bank_ref = response.data.data.Bank_ref;
                console.log($scope.payment_transaction_recurring.Property_name);
            },
            function() {
                alert("There was an error in getProperties")
            }
        )
    }

    $scope.selectProperty = function (property_id, property_name, bank_ref, address_1) {
        $scope.search_property = "";
        $scope.hidePropertySearch = true;
        $scope.selected_property_name = property_name;
        $scope.payment_transaction_recurring.selected_property_name = property_name;
        $scope.payment_transaction_recurring.Property_name = property_name;
        $scope.payment_transaction_recurring.Address_1 = address_1;
        $scope.payment_transaction_recurring.Bank_ref = bank_ref;
        console.log("Bank_Ref: " + $scope.payment_transaction_recurring.Bank_ref);
        $scope.payment_transaction_recurring.Property_ID = property_id;
        console.log("Property_ID: " + $scope.payment_transaction_recurring.Property_ID);
        $scope.getProperties();
        //$scope.getLandlordRegularCosts();
    }


    $scope.getUnit = function () {

        $scope.payment_transaction_recurring.Unit_number = $scope.payment_transaction_recurring.units.Unit_number;

        console.log("Unit_ID: " + $scope.payment_transaction_recurring.units.Unit_ID);

        $scope.payment_transaction_recurring.Date_Entered = $scope.date;
        console.log("Date: " + $scope.payment_transaction_recurring.Date_Entered);
        var dateFilitered = $filter('date')($scope.payment_transaction_recurring.Date_Entered, 'yyyy') + $filter('date')($scope.payment_transaction_recurring.Date_Entered,'MMMM');
        dateFilitered = $filter('uppercase')(dateFilitered);
        var dateFiliteredSpace = $filter('date')($scope.payment_transaction_recurring.Date_Entered, 'yyyy') + " " + $filter('date')($scope.payment_transaction_recurring.Date_Entered,'MMMM');

        $scope.payment_transaction_recurring.Description = "Recurring Payment - " + $scope.selected_property_name + " Unit " + $scope.payment_transaction_recurring.Unit_number + " - " + dateFiliteredSpace;
        $scope.payment_transaction_recurring.Payment_ID = $scope.payment_transaction_recurring.Bank_ref + "U" + $scope.payment_transaction_recurring.Unit_number + "RENTRPT" + dateFilitered
        $scope.payment_transaction_recurring.Payment_ID = $filter('uppercase')($scope.payment_transaction_recurring.Payment_ID);
        apiService.getTenanciesUnit($scope.payment_transaction_recurring.units.Unit_ID , $rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {
                $scope.tenancies = response.data;
                $scope.payment_transaction_recurring.tenancies = response.data;
                console.log($scope.tenancies.data);
            },
            function() {
                alert("There was an error in getTenanciesUnit")
            }
        )
    }

    $scope.getTenancy = function () {
        $scope.payment_transaction_recurring.Tenancy_ID = $scope.payment_transaction_recurring.tenancies.Tenancy_ID;
        console.log("Tenancy ID: " + $scope.payment_transaction_recurring.Tenancy_ID);
    }

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    console.log("End 10");
}]);