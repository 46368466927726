/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("stringModifierService", ["$http", "$q", "$rootScope", "Upload", "fileUploadService", "apiService", "truncateSliceService", function ($http, $q, $rootScope, Upload, fileUploadService, apiService, truncateSliceService) {

    this.replaceHTMLTags = function (stringContent) {
        console.log("inside service replaceHTMLTags");
        var testString = stringContent;
        testString = testString.replace(/(\(><.*\))/g, '   ');
        testString = testString.replace(/<br/g, '   ');
        testString = testString.replace(/>/g, '   ');
        testString = testString.replace(/--/g, '   ');
        testString = testString.replace(/\//g, '   ');
        console.log(testString);

        return testString;

    }



}])
