/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("testCtrl2", ["$scope", "$mdDialog", function ($scope, $mdDialog) {

    $scope.hide = function() {
        $mdDialog.hide();
    };
    $scope.cancel = function() {
        $mdDialog.cancel();
    };
    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };


}])