/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.controller("notificationsCtrl", ["$scope", "$http", "$state", "$stateParams", "$rootScope", "authenticateUserLevelService", "apiService", "$q", "$mdDialog", "$mdMedia", "Upload", "$timeout", "$filter", "dateInputService", "pdfCreateService", "notificationsService", "$location", "$document", function ($scope, $http, $state, $stateParams, $rootScope, authenticateUserLevelService, apiService, $q, $mdDialog, $mdMedia, Upload, $timeout, $filter, dateInputService, pdfCreateService, notificationsService, $location, $document) {
    console.log("inside controller notificationsCtrl");

    $scope.notificationData = {};
    $scope.notificationAlertsViewedToggle = 0;
    $rootScope.notificstionAlertLastID = 0;
    $scope.notificationList = '';
    $rootScope.count = 0;
    $rootScope.countNotificationsExisting = 0;
    $rootScope.notificationsAlertsViewToggle = 0;
    $scope.notificationObj = {};

    //cyril created function testNotifications
    // Hide dropdown if clicked somewhere 'outside'

    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )

        return q55.promise;

    }


    $rootScope.fetchNotification = function () {
        console.log("inside function fetchNotification");
        //console.log("URL >>> " + $location.absUrl());

        $scope.notificationData.User_ID_Action = $rootScope.decodedToken.id;
        $scope.notificationData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
        $scope.notificationData.User_ID_Account_Holder = $rootScope.decodedToken.Account_Holder_ID;
        $scope.notificationData.Account_Administrator = $rootScope.decodedToken.Account_Administrator;
        $scope.notificationData.User_ID_Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
        $scope.notificationData.fetchnot = true;
        $scope.notificationData.Status = 1;

        console.log("$scope.notificationData");
        console.log($scope.notificationData);

        apiService.notifications('*', $scope.notificationData).then(
            function(response) {

                $rootScope.notificationListDropdown = response.data.data;
                $scope.notificationList = response.data.data;
                $rootScope.count = response.data.unreadCount;



                console.log("$rootScope.notificationList");
                console.log($scope.notificationList);
                $rootScope.notificstionAlertLastID = 0;
                $rootScope.notificationsAlertsCountChange();
            });

    }

    /*
    $scope.notificationsAlertsCountChange = function () {
        console.log("inside function notificationsAlertsCountChange");
        var audioElement = document.createElement('audio');

        //browser notification count
        //var title = document.title;
        var title = "Clouderty - The all-in-one property management software";
        //var titleOriginal = document.title;
        var newTitle = '(' + $rootScope.count + ') ' + title;

        console.log("$rootScope.count: " + $rootScope.count);
        console.log("$rootScope.countNotificationsExisting: " + $rootScope.countNotificationsExisting);

        if($rootScope.countNotificationsExisting == 0){
            console.log("if countNotificationsExisting");
            if($rootScope.count > 0){
                $rootScope.countNotificationsExisting = $rootScope.count;

                if($rootScope.count > 0){

                    newTitle = '(' + $rootScope.count + ') ' + title;
                    document.title = newTitle;

                }else{

                    document.title = title;

                }


            }


        }else if($rootScope.countNotificationsExisting != $rootScope.count){
            console.log("if countNotificationsExisting 2");
            $rootScope.countNotificationsExisting = $rootScope.count;

            //browser notification count

            if($rootScope.count > 0) {

                newTitle = '(' + $rootScope.count + ') ' + title;
                document.title = newTitle;

            }else{

                document.title = title;

            }


            audioElement.setAttribute('src', "sounds/angular-beep.mp3");
            audioElement.play();

        }

        console.log("$rootScope.count 2: " + $rootScope.count);
        console.log("$rootScope.countNotificationsExisting 2: " + $rootScope.countNotificationsExisting);

    }
*/
    $scope.notificationsAlertsView = function () {

        console.log("inside function notificationsAlertsView");


        var x = document.getElementById("notificationsAlerts");
        console.log("notificationsAlerts: " + x.style.display);

        if (x.style.display === "none") {
            x.style.display = "block";
            $rootScope.notificationsAlertsViewToggle = 1;
        } else if (x.style.display === "") {
            x.style.display = "block";
            $rootScope.notificationsAlertsViewToggle = 1;
        } else {
            x.style.display = "none";
        }

    }

    // function for fetching the incoming notification on body load
    $scope.onInit = function() {


        $scope.notificationList = '*';

    }

    // watch function for checking whether new notifications comes or not

    $scope.$watch('notificstionAlertLastID' , function(newValues, oldValues) {

        console.log("watch notificstionAlertLastID >>> ");
        console.log(newValues);
        console.log(oldValues);
        console.log($scope.notificationList);

        if($rootScope.notificstionAlertLastID == 1){
            $rootScope.notificstionAlertLastID = 0;
            console.log("inside watch notificstionAlertLastID == 1");

            $scope.notificationData.User_ID_Action = $rootScope.decodedToken.id;
            $scope.notificationData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.notificationData.User_ID_Account_Holder = $rootScope.decodedToken.Account_Holder_ID;
            $scope.notificationData.Account_Administrator = $rootScope.decodedToken.Account_Administrator;
            $scope.notificationData.User_ID_Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.notificationData.fetchnot = true;
            $scope.notificationData.Status = 1;


            console.log($scope.notificationData);
            $rootScope.fetchNotification();


        }

    });

    // Update the Notification read count on click of notification bell.

    $scope.updateNotificationStatus = function() {
        console.log("inside function updateNotificationStatus");

        //$rootScope.count = null;


        //$scope.notificationAlertsViewedToggle
        if($rootScope.count != 0){
            $rootScope.count = null;
            console.log("$scope.count != 0");

            $scope.notificationAlertsViewedToggle = 1;
            $scope.notificationData.User_ID_Action = $rootScope.decodedToken.id;
            $scope.notificationData.User_ID_Account_Holder = $rootScope.decodedToken.Account_Holder_ID;
            $scope.notificationData.User_ID_Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.notificationData.fetchnot = true;
            $scope.notificationData.Status = 2;
            $scope.notificationData.fetchnot = true;

            $scope.notificationData.updateStatus = true;
            $scope.notificationData.user_id_pm_1 = 10;

            console.log("$scope.notificationData");
            console.log($scope.notificationData);

            apiService.notifications('*', $scope.notificationData).then(
                function(response) {
                    //if(err){
                    //flashService.Error(err,false);
                    //}else{
                    $scope.notificationList = response.data.data;
                    $rootScope.notificationListDropdown = response.data.data;

                    $rootScope.count = null;

                    //$rootScope.count = response.data.unreadCount;

                    $rootScope.notificationsAlertsCountChange();
                    //}

                    /*
                    if($rootScope.count > 0) {

                        newTitle = '(' + $rootScope.count + ') ' + title;
                        document.title = newTitle;

                    }else{

                        document.title = title;

                    }
                    */
                });

        }
        else{

            console.log("$scope.count == 0");
            //$scope.notificationAlertsViewedToggle == 0;

        }




    };

    // function for fetching the incoming notification on body load
    $scope.updateClickedStatus = function(notificationId) {
        console.log(notificationId);
        $rootScope.count = "";
        notificationsService.updateClickedStatus(notificationId , function(res,err) {
            if(err){
                flashService.Error(err,false);
                resetForm();
            }else{
                if(res.data.status=='success'){
                    flashService.Success(res.data.message,false);
                }
            }
        });
    }

    $scope.changeState = function() {
        console.log('changeState');
    }

    $rootScope.notificstionAlertLastID = 1;



}])