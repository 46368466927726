/**
 * Created by Aaron on 04/07/2016.
 */
//Application URL routing.

clouderty.config(['$stateProvider','$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.otherwise('/'); //if URL not defined, redirect user to '/'

        //$locationProvider.html5Mode(true).hashPrefix('!');


        $stateProvider

        /*-------------------------------LOGIN ROUTES-------------------------------*/
            /*
            .state('login', {
                templateUrl: 'views/login/login-header.html',
                data: {auth: "NotLoggedIn"}
            })
            .state('login.signin', {
                url: '/login',
                templateUrl: 'views/login/login-panel.html',
                controller: 'loginCtrl',
                data: {auth: "NotLoggedIn"}
            })
            */
            .state('login', {
                templateUrl: '',
                data: {auth: "NotLoggedIn"}
            })
            .state('login.sign-up', {
                url: '/',
                templateUrl: 'views/sign-up/sign-up.html',
                controller: 'loginCtrl',
                data: {auth: "NotLoggedIn"}
            })
            .state('login.log-in', {
                url: '/login',
                templateUrl: 'views/login/login.html',
                controller: 'loginCtrl',
                data: {auth: "NotLoggedIn"}
            })
            /*-------------------------------SIGNUPS ROUTES-------------------------------*/
            .state('signup-header', {
                templateUrl: 'views/signups/signup-header.html',
                data: {auth: "NotLoggedIn"}
            })
            .state('signup-header.signup', {
                url: '/signup',
                templateUrl: 'views/signups/signup-panel.html',
                controller: 'signupCtrl',
                data: {auth: "NotLoggedIn"}
            })

            /*------------------------------- ACCOUNT HOLDERS -------------------------------*/

            /*
            .state('lettings-main-nav.lettings-side.account-holder-user-add.edit', {
                url: '/lettings/add-edit-acccount-holder-user',
                templateUrl: 'views/account-holders-users/account-holder-user-add-edit.html',
                controller: 'accountHolderUserAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            */
            .state('lettings-main-nav.lettings-side.account-holder-manage', {
                url: '/lettings/acccount-holder-manage/:id/:accountHolderID',
                templateUrl: 'views/account-holders-users/account-holder-manage.html',
                controller: 'accountHolderManageCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*-------------------------------FILE NAMES AND IDS ROUTES-------------------------------*/
            .state('lettings-main-nav.lettings-side.file-names-ids-create', {
                url: '/file-names-ids-create',
                templateUrl: 'views/file-names-and-ids/file-names-and-ids-create.html',
                controller: 'fileNamesIDsCreateCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.financials-side.file-names-ids-create', {
                url: '/financials/file-names-ids-create',
                templateUrl: 'views/file-names-and-ids/file-names-and-ids-create.html',
                controller: 'fileNamesIDsCreateCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- LANDLORDS ROUTES -------------------------------*/
            .state('lettings-main-nav.lettings-side.landlord-statement-transactions-search', {
                url: '/landlords/landlord-statement-transactions-search/:itemsPerPage/:pagenumber',
                templateUrl: 'views/landlords/landlord-statement-transactions-search.html',
                controller: 'landlordStatementTransactionsSearchCtrl',
                //data: { auth: "LoggedIn", type: "Lettings" }
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('landlord-statement', {
                url: '/landlord-statement/:itemsPerPage/:pagenumber/:propertyID/:accountHolderID/:yearRelatedTo/:monthRelatedTo',
                templateUrl: 'views/document_templates/landlord-statement-template.html',
                controller: 'landlordStatementTemplateCtrl',
                data: { auth: "LoggedInExternal", type: "Lettings" }
            })
            .state('landlord-statement-license', {
                url: '/landlord-statement/:landlordID/:licenseKey',
                templateUrl: 'views/document_templates/landlord-statement-template.html',
                controller: 'landlordStatementTemplateCtrl',
                data: { auth: "LoggedInExternal", type: "Lettings" }
                //data: { auth: "LoggedInExternal", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.landlord-statement-view', {
                url: '/landlords/landlord-statement-view/:itemsPerPage/:pagenumber/:propertyID/:accountHolderID/:yearRelatedTo/:monthRelatedTo',
                templateUrl: 'views/landlords/landlord-statement-view.html',
                controller: 'landlordStatementViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('landlords-main-nav', {
                templateUrl: 'views/nav/main.html',
                data: { auth: "LoggedIn", type: "Landlord" },
                controller: "mainNavCtrl"
            })
            .state('external-main-nav', {
                templateUrl: 'views/nav/external-main.html',
                data: { auth: "LoggedIn", type: "Landlord" },
                controller: "mainNavCtrl"
            })
            .state('landlords-main-nav.landlords-tasks-side', {
                templateUrl: 'views/nav/lettings-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Landlord" }
            })

            .state('landlords-main-nav.landlords-tasks-side.main-dashboard', {
                url: '/landlords',
                templateUrl: 'views/dashboard/dashboard.html',
                controller: 'dashboardCtrl',
                data: { auth: "LoggedIn", type: "Landlord" }
            })
            .state('landlords-main-nav.landlords-tasks-side.tasks-search', {
                url: '/contractors/tasks',
                templateUrl: 'views/tasks/tasks-search.html',
                controller: 'tasksSearchCtrl',
                data: { auth: "LoggedIn", type: "Landlord" }
            })





            /*------------------------------- LANDLORDS ROUTES END -------------------------------*/
            /*------------------------------- LETTINGS ROUTES -------------------------------*/
            //NAVIGATION ROUTES
            .state('main-nav', {
                templateUrl: 'views/nav/main.html',
                data: { auth: "LoggedIn", type: "Lettings" },
                controller: "mainNavCtrl"
            })
            .state('lettings-main-nav', {
                templateUrl: 'views/nav/main.html',
                data: { auth: "LoggedIn", type: "Lettings" },
                controller: "mainNavCtrl"/*,
                resolve: {
                    statesLoad: ['stateAuthCheckService', '$q', function (stateAuthCheckService, $q) {
                        var deferred = $q.defer();
                        console.log("state auth: " + this.self.name);
                        deferred.resolve(stateAuthCheckService.getStates());
                        console.log('Template mainNavCtrl resolve end <<<<<<<<<<');
                        return deferred.promise;
                    }]
                }*/
            })

            .state('lettings-main-nav.lettings-side', {
                templateUrl: 'views/nav/lettings-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })


            //DASHBOARD ROUTES
            .state('lettings-main-nav.dashboard-side', {
                templateUrl: 'views/nav/dashboard-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.main-dashboard', {
                url: '/lettings',
                templateUrl: 'views/dashboard/dashboard.html',
                controller: 'dashboardCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.main-dashboard-vendors', {
                url: '/vendors',
                templateUrl: 'views/dashboard/dashboard.html',
                controller: 'dashboardCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })


            /* TENANTS */

            .state('lettings-main-nav.lettings-side.lettings-search-tenant', {
                url: '/lettings/tenant-search',
                templateUrl: 'views/lettings/tenant-search.html',
                controller: 'tenantSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-view-tenant', {
                url: '/tenant/:id',
                templateUrl: 'views/lettings/tenant-add-edit.html',
                controller: 'tenantViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-add-tenant', {
                url: '/lettings/add-edit-tenant',
                templateUrl: 'views/lettings/tenant-add-edit.html',
                controller: 'tenantAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-edit-tenant', {
                url: '/lettings/add-edit-tenant/:id/:accountHolderID',
                templateUrl: 'views/lettings/tenant-add-edit.html',
                controller: 'tenantAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })



            /* TENANCIES */

            .state('lettings-main-nav.lettings-side.lettings-add-tenancy', {
                url: '/lettings/add-edit-tenancy',
                templateUrl: 'views/lettings/tenancy-add-edit.html',
                controller: 'tenancyAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-edit-tenancy', {
                url: '/lettings/add-edit-tenancy/:id/:accountHolderID',
                templateUrl: 'views/lettings/tenancy-add-edit.html',
                controller: 'tenancyAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-search-tenancy', {
                url: '/lettings/view-tenancy',
                templateUrl: 'views/lettings/tenancy-search.html',
                controller: 'tenancySearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-search-tenancy-becoming-available', {
                url: '/lettings/view-tenancy-becoming-available/:becomingAvailable',
                templateUrl: 'views/lettings/tenancy-search.html',
                controller: 'tenancySearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-view-tenancy', {
                templateUrl: 'views/lettings/tenancy-view.html',
                controller: 'tenancyViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-view-tenancy.notes', {
                url: '/lettings/view-tenancy/:id/:accountHolderID',
                views : {
                    '' : {
                        templateUrl: 'views/notes/notes.html'

                    },
                    'notesTemplate' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl'

                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        controller: 'paymentTransactionAddEditCtrl'

                    }
                }
            })
            .state('tenancy-agreement-room', {
                templateUrl: 'views/document_templates/tenancy-agreement-surround.html',
                controller: 'tenancyAgreementSurroundCtrl',
                data: { auth: "LoggedInExternal", type: "Lettings" }/*,
                resolve: {
                    auth: ['stateAuthCheckService', '$q', '$rootScope', 'loginExternalWebPageService', '$http', '$window', 'apiService', '$cookieStore', function (stateAuthCheckService, $q, $rootScope, loginExternalWebPageService, $http, $window, apiService, $cookieStore) {
                        var deferred = $q.defer(); console.log("state auth: " + this.self.name);
                        console.log('auth resolve');

                        //console.log('$rootScope.decodedToken.Account_Holder_ID: ' + $rootScope.decodedToken.Account_Holder_ID);

                        getGlobalPromise = function() {
                            var q55 = $q.defer();
                            apiService.getGlobalPromise().then(
                                function (response) {
                                    console.log("API getGlobalPromise");
                                    q55.resolve(response);
                                }
                            )
                            return q55.promise;
                        }

                        console.log('checking $rootScope.testcase = 1');
                        console.log($rootScope.noFromState);
                        //console.log($rootScope.decodedToken.Account_Holder_ID);
                        console.log('about to enter $rootScope.decodedToken.Account_Holder_ID');
                        if($rootScope.noFromState == 1){

                            var q5 = $q.defer();
                            var promise = loginExternalWebPageService.init("1");
                            myValue = 0;

                            promise
                                .then(function (data) {

                                    console.log("A2a loginfunction");


                                    var login_details = {};

                                    login_details.email = $cookieStore.get('userName');
                                    login_details.password = $cookieStore.get('password')

                                    return $http.post("api/authenticate", login_details)
                                        .success(function(response) {

                                            if (response.success == false) {
                                                alert("Incorrect Login Details");
                                            }
                                            else  {
                                                if ($window.sessionStorage.token) {
                                                    $window.sessionStorage.removeItem("token");
                                                }
                                                $window.sessionStorage.token = response.token;
                                                $rootScope.decode();

                                            }
                                        })

                                })
                                .then(function (data) {

                                    console.log("A2a loginfunction");

                                    $rootScope.decode();
                                    return deferred.resolve(stateAuthCheckService.runAuthCheck(this.self.name));

                                }, function (err) {
                                    myValue = err;
                                })

                            q5.resolve(promise);

                        }
                        else{
                            deferred.resolve(stateAuthCheckService.runAuthCheck(this.self.name));
                        }

                        return deferred.promise;
                    }]
                }*/
            })
            .state('tenancy-agreement-room.payment', {
                url: '/lettings/tenancy-agreement/:tenancyID/:licenseKey',
                templateUrl: 'views/document_templates/tenancy-agreement-rooms.html',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                params: { myParam: null }/* ,
                resolve: {
                    obj: ['$q', '$stateParams', 'apiService', 'auth', '$timeout', '$rootScope', function ($q, $stateParams, apiService, auth, $timeout, $rootScope) {
                        var deferred3 = $q.defer();

                        deferred3.resolve(auth);

                        return deferred3.promise;
                    }],
                }*/,
                views : {
                    '': {
                        url: '/lettings/tenancy-agreement/:tenancyID/:licenseKey',
                        templateUrl: 'views/document_templates/tenancy-agreement-rooms.html',
                        controller: 'tenancyAgreementCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }/*,
                        resolve: {
                            auth4: ['$q', 'obj', function ($q, obj) {
                                var deferred5 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);

                                deferred5.resolve(obj);

                                return deferred5.promise;
                            }]
                        }*/

                    },
                    'tenancyAgreementSign': {
                        templateUrl: 'views/document_templates/tenancy-agreement-sign.html',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        controller: 'paymentTransactionAddEditCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }/*,
                        resolve: {
                            auth5: ['$q', 'obj', '$timeout', function ($q, obj, $timeout) {
                                var deferred6 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);
                                //$timeout(function () {
                                    deferred6.resolve(obj);
                                //}, 15000)
                                return deferred6.promise;
                            }]
                        }*/

                    }
                }
            })
            .state('tenancy-agreement-room.paymentOllllld', {
                url: '/lettings/tenancy-agreement/:tenancyID/:licenseKey',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                params: { myParam: null } /*,
                resolve: {
                    obj: ['$q', '$stateParams', 'apiService', 'auth', '$timeout', '$rootScope', function ($q, $stateParams, apiService, auth, $timeout, $rootScope) {
                        var deferred3 = $q.defer();
                        console.log("tenancyID: " + $stateParams.tenancyID);
                        console.log("tenancyID: " + $stateParams.licenseKey);
                        deferred3.resolve(auth);

                        var deferred4 = $q.defer();
                        var promise = statesFunction();

                        var tenancies = {};

                        tenancies.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        tenancies.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                        tenancies.itemsPerPage = 20;
                        tenancies.pageno = 1;
                        tenancies.Tenancy_ID = $stateParams.tenancyID;
                        tenancies.Estate_ID = 0;
                        tenancies.Property_ID = 0;
                        tenancies.monthsExpiring = 0;
                        tenancies.Licence_Key = $stateParams.licenseKey;
                        tenancies.Status = 20;
                        tenancies.Payment_Transaction_Type_ID = "";
                        tenancies.paymentTransactionInputs = "";
                        tenancies.search = "*";

                        console.log(tenancies);

                        promise
                            .then(function () {
                                console.log("A2");
                                //$timeout(function() {
                                apiService.tenancies(tenancies.Tenancy_ID, tenancies).then(
                                    function (response) {

                                        console.log('tenancies route resolve >>>>');
                                        console.log(response);
                                        deferred4.resolve(response.data);

                                    },
                                    function () {
                                        console.log("API Service Error: processes")
                                    }
                                );
                                //});

                            })
                            .then(function (data) {
                                console.log('A7');
                                myValue = data;
                            }, function (err) {
                                myValue = err;
                            })

                        return deferred4.promise;
                    }],
                },
                views : {
                    '': {
                        templateUrl: 'views/document_templates/tenancy-agreement-rooms.html',
                        controller: 'tenancyAgreementCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" },
                        resolve: {
                            auth4: ['$q', 'obj', function ($q, obj) {
                                var deferred5 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);

                                deferred5.resolve(obj);

                                return deferred5.promise;
                            }]
                        }

                    },
                    'tenancyAgreementSign': {
                        templateUrl: 'views/document_templates/tenancy-agreement-sign.html',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        controller: 'paymentTransactionAddEditCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" },
                        resolve: {
                            auth5: ['$q', 'obj', '$timeout', function ($q, obj, $timeout) {
                                var deferred6 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);
                                //$timeout(function () {
                                    deferred6.resolve(obj);
                                //}, 15000)
                                return deferred6.promise;
                            }]
                        }

                    }
                }*/
            })

            .state('tenancy-agreement-single-let', {
                url: '/lettings/tenancy-agreement-single-let/:id/:accountHolderID/:cloudertySystemAdministrator',
                templateUrl: 'views/document_templates/tenancy-agreement-single-let.html',
                controller: 'tenancyAgreementCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-add-tenant-viewing', {
                url: '/lettings/add-edit-tenant-viewing',
                templateUrl: 'views/lettings/tenant-viewing-add-edit.html',
                controller: 'tenantViewingAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-edit-tenant-viewing', {
                url: '/lettings/add-edit-tenant-viewing/:id/:accountHolderID/:cloudertySystemAdministrator',
                templateUrl: 'views/lettings/tenant-viewing-add-edit.html',
                controller: 'tenantViewingAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.lettings-tenant-viewings-search', {
                url: '/lettings/view-tenant-viewings',
                templateUrl: 'views/lettings/tenant-viewings-search.html',
                controller: 'tenantViewingsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-tenant-viewings-search-filtered', {
                url: '/lettings/view-tenant-viewings/:compareToDate',
                templateUrl: 'views/lettings/tenant-viewings-search.html',
                controller: 'tenantViewingsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            /*------------------------------- VEHICLES -------------------------------*/
            .state('lettings-main-nav.lettings-side.vehicle-search', {
                url: '/lettings/vehicle-search',
                templateUrl: 'views/vehicles/vehicle-search.html',
                controller: 'vehicleSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- CERTIFICATES -------------------------------*/
            .state('lettings-main-nav.lettings-side.certificates-assessments-search', {
                url: '/lettings/certificates-assessments-search',
                templateUrl: 'views/certificates-assessments/certificates-assessments-search.html',
                controller: 'certificatesAssessmentsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.certificates-gas-search', {
                url: '/lettings/certificates-gas-search',
                templateUrl: 'views/lettings/lettings-certificates-gas-search.html',
                controller: 'certificatesGasSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.certificates-electric-search', {
                url: '/lettings/certificates-electric-search',
                templateUrl: 'views/lettings/lettings-certificates-electric-search.html',
                controller: 'certificatesElectricSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            /*------------------------------- INSPECTION REPORTS -------------------------------*/

            .state('lettings-main-nav.lettings-side.inspection-report-add', {
                url: '/lettings/inspection-report-add',
                templateUrl: 'views/inspection-reports/inspection-report-add-edit.html',
                controller: 'inspectionReportAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- PROPERTIES AND UNITS ROUTES -------------------------------*/

            .state('lettings-main-nav.lettings-side.property-add', {
                url: '/lettings/property-add-edit',
                templateUrl: 'views/properties-units/property-add-edit.html',
                controller: 'propertyAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.property-search', {
                url: '/lettings/property-search',
                templateUrl: 'views/properties-units/property-search.html',
                controller: 'propertySearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.units-search', {
                url: '/lettings/units-search',
                templateUrl: 'views/properties-units/units-search.html',
                controller: 'unitsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.units', {
                url: '/lettings/units/:id/:itemsPerPage/:pagenumber/:status/:accountHolderID/:cloudertySystemAdministrator',
                templateUrl: 'views/properties-units/units-search.html',
                controller: 'unitsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })


            /*------------------------------- TASKS ROUTES -------------------------------*/

            .state('lettings-main-nav.tasks-side', {
                templateUrl: 'views/nav/tasks-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.tasks-side.tasks-search', {
                url: '/lettings/tasks',
                templateUrl: 'views/tasks/tasks-search.html',
                controller: 'tasksSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.tasks-side.task-view', {
                url: '/lettings/tasks/view-task/:id',
                templateUrl: 'views/tasks/tasks-view.html',
                controller: 'taskViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.tasks-side.task-view.notes', {
                url: '/task/:id',
                views : {
                    '' : {

                        templateUrl: 'views/notes/notes.html',
                        //controller: 'taskViewCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    },
                    'notesTemplate' : {

                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    }
                }
            })
            .state('lettings-main-nav.tasks-side.task-view.notes.completed', {
                url: '/lettings/tasks/view-task-completed/:id',
                templateUrl: 'views/notes/notes.html',
                controller: 'taskViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.tasks-side.task-add', {
                url: '/lettings/tasks/tasks-add-edit',
                templateUrl: 'views/tasks/tasks-add-edit.html',
                controller: 'taskAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.tasks-side.task-edit', {
                url: '/lettings/tasks/tasks-add-edit/:id/:accountHolderID/:cloudertySystemAdministrator',
                templateUrl: 'views/tasks/tasks-add-edit.html',
                controller: 'taskAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- VENDORS ROUTES -------------------------------*/

            .state('lettings-main-nav.vendors-side', {
                templateUrl: 'views/nav/vendors-side.html',
                data: { auth: "LoggedIn", type: "Lettings" },
                controller: "sideMenuCtrl"
            })
            .state('lettings-main-nav.vendors-side.vendor-search', {
                url: '/lettings/vendors',
                templateUrl: 'views/vendors/vendors-search.html',
                controller: 'vendorsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.vendors-side.vendor-edit', {
                url: '/lettings/vendors/vendor-add-edit/:id',
                templateUrl: 'views/vendors/vendors-add-edit.html',
                controller: 'vendorsAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.vendors-side.vendor-add', {
                url: '/lettings/vendors/vendor-add-edit',
                templateUrl: 'views/vendors/vendor-add-edit.html',
                controller: 'vendorAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- USER CONTRACTORS ROUTES -------------------------------*/
            .state('contractors-main-nav', {
                templateUrl: 'views/nav/contractors-main.html',
                data: { auth: "LoggedIn", type: "Contractor" },
                controller: "mainNavCtrl"
            })
            .state('contractors-main-nav.contractors-tasks-side', {
                templateUrl: 'views/nav/contractors-tasks-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })
            .state('contractors-main-nav.contractors-tasks-side.tasks-search', {
                url: '/contractors/tasks',
                templateUrl: 'views/tasks/tasks-search.html',
                controller: 'tasksSearchCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })
            .state('contractors-main-nav.contractors-tasks-side.task-view', {
                templateUrl: 'views/tasks/tasks-view.html',
                controller: 'taskViewCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })
            .state('contractors-main-nav.contractors-tasks-side.task-view.notes', {
                url: '/contractors/tasks/view-task/:id',
                views : {
                    '' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl',
                        data: { auth: "LoggedIn", type: "Contractor" }

                    },
                    'notesTemplate' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl',
                        data: { auth: "LoggedIn", type: "Contractor" }

                    }
                }
            })
            /*
            .state('contractors-main-nav.contractors-tasks-side.task-view.notes', {
                url: '/contractors/tasks/view-task/:id',
                templateUrl: 'views/notes/notes.html',
                controller: 'taskViewCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })
            */
            .state('contractors-main-nav.contractors-tasks-side.task-add', {
                url: '/contractors/tasks/tasks-add-edit',
                templateUrl: 'views/tasks/tasks-add-edit.html',
                controller: 'taskAddEditCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            /*------------------------------- TIMESHEET ROUTES -------------------------------*/


            .state('lettings-main-nav.tasks-side.timesheet-add-edit', {
                url: '/contractors/timesheets/timesheet-add-edit',
                templateUrl: 'views/timesheets/timesheet-add-edit.html',
                controller: 'timesheetAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('contractors-main-nav.contractors-tasks-side.timesheet-add-edit', {
                url: '/contractors/timesheets/timesheet-add-edit',
                templateUrl: 'views/timesheets/timesheet-add-edit.html',
                controller: 'timesheetAddEditCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            .state('contractors-main-nav.contractors-tasks-side.timesheet-search', {
                url: '/contractors/timesheets/timesheet-search',
                templateUrl: 'views/timesheets/timesheet-search.html',
                controller: 'timesheetSearchCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            .state('lettings-main-nav.tasks-side.timesheet-search', {
                url: '/lettings/timesheets/timesheet-search',
                templateUrl: 'views/timesheets/timesheet-search.html',
                controller: 'timesheetSearchCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            /*------------------------------- METER READING ROUTES -------------------------------*/

            ///////// Lettings

            .state('lettings-main-nav.tasks-side.meter-reading-add-edit', {
                url: '/contractors/properties-units/meter-reading-add',
                templateUrl: 'views/properties-units/meter-reading-add-edit.html',
                controller: 'meterReadingsAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.tasks-side.meter-readings-search', {
                url: '/lettings/properties-units/meter-readings-search',
                templateUrl: 'views/properties-units/meter-readings-search.html',
                controller: 'meterReadingsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            ///////// Vendors

            .state('contractors-main-nav.contractors-tasks-side.meter-reading-add-edit', {
                url: '/contractors/properties-units/meter-reading-add',
                templateUrl: 'views/properties-units/meter-reading-add-edit.html',
                controller: 'meterReadingsAddEditCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            .state('contractors-main-nav.contractors-tasks-side.meter-readings-search', {
                url: '/contractors/properties-units/meter-readings-search',
                templateUrl: 'views/properties-units/meter-readings-search.html',
                controller: 'meterReadingsSearchCtrl',
                data: { auth: "LoggedIn", type: "Contractor" }
            })

            /*------------------------------- TEST ROUTES -------------------------------*/
            .state('lettings-main-nav.lettings-side.notification', {
                url: '/notification',
                templateUrl: 'views/notifications/notifications-menu-dropdown.html',
                controller: 'notificationsCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.all-notifications', {
                url: '/all-notifications',
                templateUrl: 'views/notifications/notifications-view-all.html',
                controller: 'notificationsCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.test', {
                url: '/tests/password_create',
                templateUrl: 'views/tests/password_create.php',
                controller: 'testCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.epdq-live', {
                url: '/epdq/epdq-live',
                templateUrl: 'views/tests/epdq-live.php',
                controller: 'testCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.epdq-test', {
                url: '/tests/epdq-test',
                templateUrl: 'views/tests/epdq-test.php',
                controller: 'testCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('contractors-main-nav.contractors-tasks-side.test-angular2', {
                url: '/global',
                templateUrl: 'views/tests/test_angular2.html',
                controller: 'testCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('contractors-main-nav.contractors-tasks-side.test-angular.get', {
                url: '/global',
                templateUrl: 'views/tests/test_angular2.html',
                controller: 'testCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.file', {
                url: '/test/pdfcreate',
                templateUrl: 'views/tests/template-view.html',
                controller: 'templateCtrl'
            })//pdf creation by Anuj Oberoi
            /*------------------------------- POTENTIAL TENANTS ROUTES -------------------------------*/

            .state('lettings-main-nav.lettings-side.tenants-potential-search', {
                url: '/lettings/tenants_search',
                templateUrl: 'views/lettings/tenants-search.html',
                controller: 'tenantsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            /*
        .state('lettings-main-nav.lettings-side.tenants-potential-search', {
            url: '/lettings/tenants_potential_search',
            templateUrl: 'views/lettings/tenants-potential-search.html',
            controller: 'tenantsPotentialSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })*/
            .state('lettings-main-nav.lettings-side.tenants-potential-add', {
                url: '/lettings/tenants_potential_add',
                templateUrl: 'views/lettings/tenants-potential-add.html',
                controller: 'tenantsPotentialAddCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.lettings-side.tenants-potential-search.id', {
                url: '/lettings/tenants_potential_search/:id',
                templateUrl: 'views/lettings/tenants-potential-search.html',
                controller: 'tenantsPotentialSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- USER ACTIVITY ROUTES -------------------------------*/

            .state('lettings-main-nav.lettings-side.user-activity-search', {
                url: '/user-activity-search',
                templateUrl: 'views/user-activity/user-activity-search.html',
                controller: 'userActivitySearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.user-activity-view', {
                url: '/user-activity-view/:id/:cloudertySystemAdministrator',
                templateUrl: 'views/user-activity/user-activity-view.html',
                controller: 'userActivityViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- INVOICES ROUTES -------------------------------*/

            .state('lettings-main-nav.financials-side.payment-transaction-request', {
                url: '/payment-transaction-request',
                templateUrl: 'views/financials/payment-transaction-request.html',
                //controller: 'paymentTransactionsSearchCtrl',
                controller: 'paymentTransactionRequestCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('payment-transaction-request-invoice', {
                url: '/payment-transaction-request-invoice/:id',
                templateUrl: 'views/financials/payment-transaction-request-invoice.html',
                //controller: 'paymentTransactionsSearchCtrl',
                controller: 'paymentTransactionRequestCtrl',
                //data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*------------------------------- AWARDS ROUTES -------------------------------*/

            .state('awards', {
                templateUrl: 'views/awards/awards-template-surround.html',
                controller: 'awardsSurroundCtrl',
                data: { auth: "LoggedInExternal", type: "Lettings" }
            })
            .state('awards-internal', {
                templateUrl: 'views/awards/awards-template-surround.html',
                controller: 'awardsSurroundCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('awards.nomination-requests', {
                url: '/awards/nomination-requests',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-requests-add-edit.html',
                        controller: 'awardsNominationRequestsAddEditCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                    }
                }
            })
            .state('awards.nomination-stage-2-add-edit', {
                url: '/awards/awards-nomination-stage-2-add-edit/:awardsNominationID/:licenseKey',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-stage-2-add-edit.html',
                        controller: 'awardsNominationStage2AddEditCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                        /*,
                        resolve: {
                            auth4: ['$q', 'obj', function ($q, obj) {
                                var deferred5 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);

                                deferred5.resolve(obj);

                                return deferred5.promise;
                            }]
                        }*/

                    }
                }
            })
            .state('awards.nomination-judging', {
                url: '/awards/awards-nomination-judging',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-judging.html',
                        controller: 'awardsNominationJudgingCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                        /*,
                        resolve: {
                            auth4: ['$q', 'obj', function ($q, obj) {
                                var deferred5 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);

                                deferred5.resolve(obj);

                                return deferred5.promise;
                            }]
                        }*/

                    }
                }
            })
            .state('awards.ticket', {
                url: '/awards/ticket/:awardsTicketID/:licenceKey',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-ticket.html',
                        controller: 'awardsTicketCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                        /*,
                        resolve: {
                            auth4: ['$q', 'obj', function ($q, obj) {
                                var deferred5 = $q.defer();
                                console.log('obj resolve');
                                console.log(obj);

                                deferred5.resolve(obj);

                                return deferred5.promise;
                            }]
                        }*/

                    }
                }
            })
            .state('lettings-main-nav.lettings-side.tickets-manage', {
                url: '/awards/tickets-manage',
                data: { auth: "LoggedIn", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-tickets-manage.html',
                        controller: 'awardsTicketsManageCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    }
                }
            })
            .state('tickets-guest-list', {
                url: '/awards/tickets-guest-list',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-tickets-guest-list.html',
                        controller: 'awardsTicketsGuestListCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }

                    }
                }
            })
            .state('awards.buy-tickets', {
                url: '/awards/secure-tickets',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-buy-tickets.html',
                        controller: 'awardsBuyTicketsCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }
                    }
                }
            })
            .state('awards.public-vote', {
                url: '/awards/public-vote/:awardsCategoryID',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-public-vote.html',
                        controller: 'awardsNominationPublicVoteCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }

                    }
                }
            })
            .state('awards.public-vote-email-confirmation', {
                url: '/awards/public-vote-email-confirmation/:awardsCategoryID/:awardsNominationPublicVoteID/:licenseKey',
                data: { auth: "LoggedInExternal", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-public-vote-email-confirmation.html',
                        controller: 'awardsNominationPublicVoteEmailConfirmationCtrl',
                        data: { auth: "LoggedInExternal", type: "Lettings" }

                    }
                }
            })
            .state('lettings-main-nav.lettings-side.nomination-create', {
                url: '/awards/nomination-create',
                data: { auth: "LoggedIn", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nomination-create.html',
                        controller: 'awardsNominationCreateCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    }
                }
            })
            .state('lettings-main-nav.lettings-side.awards-process-account-view', {
                url: '/lettings/awards-process-account/:processAccountID',
                views: {
                    '': {
                        templateUrl: 'views/processes/awards-process-account-view.html',
                        data: {auth: "LoggedIn", type: "Lettings"},
                        controller: 'processAccountViewCtrl'

                    }
                }

            })
            .state('lettings-main-nav.lettings-side.awards-process-account-view.notes', {
                url: '/awards/:processAccountID',
                data: { auth: "LoggedIn", type: "Lettings" },
                views : {
                    'notesTemplate' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }/*,
                        resolve: {
                            auth2: ['$q', 'processAccount', function ($q, processAccount) {
                                var deferred2 = $q.defer();
                                console.log('processAccount notesTemplate resolve');
                                //console.log(processAccount);
                                //console.log(processAccount.data);

                                //console.log(processAccount.data.data);

                                //console.log(processAccount.data.data.Process_Type_ID);
                                deferred2.resolve(processAccount);
                                return deferred2.promise;
                            }]
                        }*/
                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        data: { auth: "LoggedIn", type: "Lettings" },
                        controller: 'paymentTransactionAddEditCtrl'/*,
                        resolve: {
                            auth4: ['stateAuthCheckService', '$q', function (stateAuthCheckService, $q) {
                                var deferred = $q.defer();
                                console.log("state auth4: " + this.self.name);
                                deferred.resolve(stateAuthCheckService.runAuthCheckRootScope(this.self.name));

                                console.log("state auth4:b " + this.self.name);
                                return deferred.promise;
                            }]
                        }*/
                    }

                }
            })
            .state('lettings-main-nav.lettings-side.awards-process-account-search', {
                url: '/processes/awards-process-account-search',
                templateUrl: 'views/awards/awards-process-account-search.html',
                controller: 'awardsProcessAccountsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.nominations-manage', {
                url: '/awards/nominations-manage',
                data: { auth: "LoggedIn", type: "Lettings" },
                views : {
                    '': {
                        templateUrl: 'views/awards/awards-nominations-manage.html',
                        controller: 'awardsNominationsManageCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    }
                }
            })


            /*------------------------------- PROCESSES ROUTES -------------------------------*/
            .state('lettings-main-nav.lettings-side.processes-add-edit', {
                url: '/processes/processes-add-edit/:processTypeID',
                templateUrl: 'views/processes/processes-add-edit.html',
                controller: 'processesAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.process-account-add-edit', {
                url: '/processes/process-account-add-edit/:processAccountID',
                templateUrl: 'views/processes/process-account-add-edit.html',
                controller: 'processAccountAddEditCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.process-account-search', {
                url: '/processes/process-account-search',
                templateUrl: 'views/processes/process-account-search.html',
                controller: 'processAccountsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.process-account-view', {
                url: '/lettings/process-account/:processAccountID',
                views: {
                    '': {
                        templateUrl: 'views/processes/process-account-view.html',
                        data: {auth: "LoggedIn", type: "Lettings"},
                        controller: 'processAccountViewCtrl'

                    }
                }

            })
            .state('lettings-main-nav.lettings-side.process-account-view.notes', {
                url: '/view/:processAccountID',
                data: { auth: "LoggedIn", type: "Lettings" },
                views : {
                    'notesTemplate' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'notesCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }/*,
                        resolve: {
                            auth2: ['$q', 'processAccount', function ($q, processAccount) {
                                var deferred2 = $q.defer();
                                console.log('processAccount notesTemplate resolve');
                                //console.log(processAccount);
                                //console.log(processAccount.data);

                                //console.log(processAccount.data.data);

                                //console.log(processAccount.data.data.Process_Type_ID);
                                deferred2.resolve(processAccount);
                                return deferred2.promise;
                            }]
                        }*/
                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        data: { auth: "LoggedIn", type: "Lettings" },
                        controller: 'paymentTransactionAddEditCtrl'/*,
                        resolve: {
                            auth4: ['stateAuthCheckService', '$q', function (stateAuthCheckService, $q) {
                                var deferred = $q.defer();
                                console.log("state auth4: " + this.self.name);
                                deferred.resolve(stateAuthCheckService.runAuthCheckRootScope(this.self.name));

                                console.log("state auth4:b " + this.self.name);
                                return deferred.promise;
                            }]
                        }*/
                    }

                }
            })
            .state('lettings-main-nav.lettings-side.lettings-view-tenancy-arrears-account', {
                templateUrl: 'views/lettings/tenancy-arrears-account-view.html',
                controller: 'tenantArrearsAccountViewCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })
            .state('lettings-main-nav.lettings-side.lettings-view-tenancy-arrears-account.notes', {
                url: '/lettings/view-tenancy-arrears-account/:tenancyID',
                views : {
                    '' : {
                        templateUrl: 'views/notes/notes.html',
                        data: { auth: "LoggedIn", type: "Lettings" }
                    },
                    'notesTemplate' : {
                        templateUrl: 'views/notes/notes.html',
                        controller: 'tenantArrearsAccountViewCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    },
                    'paymentTransactionAddEdit' : {
                        templateUrl: 'views/financials/payment-transaction-add-edit.html',
                        controller: 'paymentTransactionAddEditCtrl',
                        data: { auth: "LoggedIn", type: "Lettings" }

                    }
                }

            })

            /*------------------------------- FINANCIAL TRANSACTIONS ROUTES -------------------------------*/

            .state('lettings-main-nav.financials-side.payment-transactions-search', {
                url: '/payment-transactions-search',
                templateUrl: 'views/financials/payment-transactions-search.html',
                controller: 'paymentTransactionsSearchCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            .state('lettings-main-nav.financials-side', {
                templateUrl: 'views/nav/financials-side.html',
                controller: 'sideMenuCtrl',
                data: { auth: "LoggedIn", type: "Lettings" }
            })

            /*
        .state('lettings-main-nav.financials-side.dashboard-financial', {
            url: '/dashboard-financial',
            templateUrl: 'views/dashboard/dashboard-financial.html',
            controller: 'dashboardFinancialCtrl',
            data: { auth: "LoggedIn", type: "Lettings" },
            resolve: {
                "accommodation": function ($q, $timeout) {
                    var myFriend = $q.defer();
                    $timeout(function () {
                        myFriend.resolve({
                            hotelName: function () {
                                return "My Friend's friend's hotel";
                            },
                            roomNo: function () {
                                return "404";
                            }
                        });
                    }, 5000);
                    return myFriend.promise;
                }
            }
        })
           */
        .state('lettings-main-nav.financials-side.dashboard-financial', {
            url: '/dashboard-financial',
            views : {
                '' : {
                    templateUrl: 'views/dashboard/dashboard-financial.html',
                    data: { auth: "LoggedIn", type: "Lettings" },
                    controller: 'dashboardFinancialCtrl' /*,
                   resolve: {
                        auth: ['stateAuthCheckService', '$q', function (stateAuthCheckService, $q) {
                            var deferred = $q.defer();
                            console.log("state auth: " + this.self.name);


                            deferred.resolve(stateAuthCheckService.runAuthCheckRootScope(this.self.name));
                            return deferred.promise;
                        }]
                    }*/
                },
                'testView' : {
                    templateUrl: 'views/signups/signup-panel.html',
                    controller: 'signupCtrl'
                }
            }

        })
        .state('lettings-main-nav.financials-side.payment-transactions-recurring-search', {
            url: '/payment-transactions-recurring-search',
            templateUrl: 'views/financials/payment-transactions-recurring-search.html',
            controller: 'paymentTransactionsRecurringSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })

        .state('lettings-main-nav.financials-side.card-transactions-search', {
            url: '/card-transactions-search',
            templateUrl: 'views/financials/card-transactions-search.html',
            controller: 'cardTransactionsSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })

        .state('lettings-main-nav.financials-side.cash-transactions-search', {
            url: '/cash-transactions-search',
            templateUrl: 'views/financials/cash-transactions-search.html',
            controller: 'cashTransactionsSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })

        .state('lettings-main-nav.lettings-side.payment-transaction-add-template', {
            templateUrl: 'views/financials/payment-transaction-add-edit-surround.html',
            controller: 'paymentTransactionAddEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.financials-side.payment-transaction-add-template', {
            templateUrl: 'views/financials/payment-transaction-add-edit-surround.html',
            controller: 'paymentTransactionAddEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.lettings-side.payment-transaction-add-template.payment-transaction-add', {
            url: '/payment-transaction-add-edit',
            views : {
                '' : {
                    templateUrl: 'views/financials/payment-transaction-add-edit-surround.html',
                    controller: 'paymentTransactionAddEditCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                },
                'paymentTransactionAddEdit' : {
                    templateUrl: 'views/financials/payment-transaction-add-edit.html',
                    controller: 'paymentTransactionAddEditCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                }
            }
        })

        .state('lettings-main-nav.financials-side.payment-transaction-add-template.payment-transaction-add', {
            url: '/financials/payment-transaction-add-edit',
            views : {
                '' : {
                    templateUrl: 'views/financials/payment-transaction-add-edit-surround.html',
                    controller: 'paymentTransactionAddEditCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                },
                'paymentTransactionAddEdit' : {
                    templateUrl: 'views/financials/payment-transaction-add-edit.html',
                    controller: 'paymentTransactionAddEditCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                }
            }
        })
        .state('lettings-main-nav.financials-side.payment-transaction-edit', {
            url: '/financials/payment-transaction-add-edit/:id',
            templateUrl: 'views/financials/payment-transaction-add-edit.html',
            controller: 'paymentTransactionAddEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.financials-side.payment-transaction-recurring-add', {
            url: 'financials/payment-transaction-recurring-add',
            templateUrl: 'views/financials/payment-transaction-recurring-add-edit.html',
            controller: 'paymentTransactionRecurringAddEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.financials-side.financial-transaction-view-edit', {
            url: 'financials/financial-transaction-view-edit/:id',
            templateUrl: 'views/financials/financial-transaction-view-edit.html',
            controller: 'financialTransactionViewEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })


        .state('lettings-main-nav.lettings-side.tenants-rental-transactions-search', {
            url: '/lettings/tenants-rental-transactions/:accountHolderID',
            templateUrl: 'views/lettings/tenants-rental-transactions-search.html',
            controller: 'tenantsRentalTransactionsCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })

        .state('lettings-main-nav.lettings-side.tenants-rental-account-balances', {
            url: '/lettings/tenants-rental-account-balances/:accountHolderID',
            templateUrl: 'views/lettings/tenants-rental-account-balances.html',
            controller: 'tenantsRentalAccountBalancesCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('tenancy-rental-statement-license', {
            url: '/tenants-rental-statement/:tenancyID/:licenseKey',
            templateUrl: 'views/document_templates/tenancy-rental-statement-template.html',
            controller: 'tenancyRentalStatementTemplateCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('tenancy-rental-statement', {
            url: '/tenants-rental-statement/:itemsPerPage/:pagenumber/:propertyID/:accountHolderID/:yearRelatedTo/:monthRelatedTo',
            templateUrl: 'views/document_templates/tenancy-rental-statement-template.html',
            controller: 'tenancyRentalStatementTemplateCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.lettings-side.tenancy-rental-statement-view', {
            url: '/landlords/landlord-tenants-rental-view/:itemsPerPage/:pagenumber/:propertyID/:accountHolderID/:yearRelatedTo/:monthRelatedTo',
            templateUrl: 'views/landlords/tenancy-rental-statement-view.html',
            controller: 'tenancyRentalStatementTemplateCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.lettings-side.tenants-rent-expected-search', {
            url: '/tenants-rent-expected',
            templateUrl: 'views/lettings/tenants-rent-expected-search.html',
            controller: 'tenantsRentExpectedCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })


        /*------------------------------- CUSTOMERS ROUTES -------------------------------*/

        .state('lettings-main-nav.financials-side.customer-add-edit', {
            url: '/business/customer-add-edit',
            templateUrl: 'views/business/customer-add-edit.html',
            controller: 'customerAddEditCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })

        /*------------------------------- SMS ROUTES -------------------------------*/

        .state('lettings-main-nav.lettings-side.twillio', {
            url: '/twillio',
            templateUrl: 'views/global/sms_send_twillio.html',
            controller: 'twillioCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('twillio-incoming-message', {
            url: '/messages',
            data: { auth: "LoggedInExternal", type: "Lettings" },
            resolve: {
                obj: ['loginExternalWebPageService', '$timeout', '$cookieStore', 'apiService', '$q', '$rootScope', '$http', '$window', function (loginExternalWebPageService, $timeout, $cookieStore, apiService, $q, $rootScope, $http, $window) {
                    console.log("state auth: " + this.self.name);

                    getGlobalPromise = function() {
                        var q55 = $q.defer();
                        apiService.getGlobalPromise().then(
                            function (response) {
                                //console.log("API getGlobalPromise");
                                q55.resolve(response);
                            }
                        )
                        return q55.promise;
                    }



                    console.log('checking $rootScope.testcase = 1');
                    console.log($rootScope.noFromState);

                    if($rootScope.noFromState == 1) {
                        console.log('$rootScope.testcase = 1');
                        var q5 = $q.defer();
                        var promise = loginExternalWebPageService.init("1");
                        myValue = 0;

                        promise
                            .then(function (data) {

                                console.log("A2a loginfunction");

                                var login_details = {};

                                login_details.email = $cookieStore.get('userName');
                                login_details.password = $cookieStore.get('password')

                                console.log(login_details);

                                return $http.post("api/authenticate", login_details)
                                    .success(function (response) {

                                        if (response.success == false) {
                                            alert("Incorrect Login Details");
                                        }
                                        else {
                                            if ($window.sessionStorage.token) {
                                                $window.sessionStorage.removeItem("token");
                                            }
                                            $window.sessionStorage.token = response.token;
                                            $rootScope.decode();

                                        }
                                    })

                            })
                            .then(function (data) {

                                $timeout(function() {
                                    console.log("in route sendTextMessage");
                                    return apiService.smsReceive().then(
                                        function (response) {

                                        }

                                    )

                                },5000);


                            })
                            .then(function (data) {


                                $rootScope.decode();
                                //return deferred.resolve(stateAuthCheckService.runAuthCheck(this.self.name));

                                return getGlobalPromise();

                            }, function (err) {
                                myValue = err;
                            })

                        q5.resolve(promise);
                    }
                }],
            },
            views : {
                '': {

                    templateUrl: 'views/tests/sms_receive_twillio.html',
                    controller: 'twillioSMSReceiveCtrl',
                    data: { auth: "LoggedInExternal", type: "Lettings" },

                }

            }
        })

        /*------------------------------- CAROUSEL SLIDER -------------------------------*/
        .state('external-main-nav.job-search', {
            url: '/jobs/job-search',
            templateUrl: 'views/jobs/job-search.html',
            controller: 'jobSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('lettings-main-nav.lettings-side.job-bid-search', {
            url: '/jobs/job-bid-search',
            templateUrl: 'views/jobs/job-bid-search.html',
            controller: 'jobSearchCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('external-main-nav.job-view', {
            url: '/job/job-view/:id',
            templateUrl: 'views/jobs/job-view.html',
            controller: 'jobViewCtrl',
            data: { auth: "LoggedIn", type: "Lettings" }
        })
        .state('external-main-nav.job-view.notes', {
            url: '/job/:id',
            views : {
                '' : {

                    templateUrl: 'views/notes/notes.html',
                    //controller: 'taskViewCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                },
                'notesTemplate' : {

                    templateUrl: 'views/notes/notes.html',
                    controller: 'notesCtrl',
                    data: { auth: "LoggedIn", type: "Lettings" }

                }
            }
        })
        /*------------------------------- CAROUSEL SLIDER -------------------------------*/
        .state('search', {
            url: '/partners',
            templateUrl: 'views/global/slider-carousel.html',
            controller: 'templateCtrl'
        })

    }

])