/**
 * Created by Aaron on 14/07/2016.
 */
clouderty.controller("timesheetSearchCtrl", ["$scope", "$rootScope", "$http", "apiService", function($scope, $rootScope, $http, apiService) {


   apiService.getTimesheets($rootScope.decodedToken.Account_Holder_ID).then(
     function(response) {
         // Stores the response from the API into the scope, making it accessible by Angular
         console.log("getTimesheets API");
         $scope.timesheets = response.data;
     },
     //On error
     function() {
         alert("There was an error: getTimesheets API")
     }
   )


   $scope.sort = function(keyname){
      $scope.sortKey = keyname;   //set the sortKey to the param passed
      $scope.reverse = !$scope.reverse; //if true make it false and vice versa
   }

   
}])