/*(function () {
  'use strict';
  angular
  .module('app')
  .controller('templateCtrl', templateCtrl)

  templateCtrl.$inject = ['$http', '$location', "$stateParams", '$scope', 'FlashService' , 'UploadService'];

  function templateCtrl( $http , $location, $stateParams, $scope, FlashService, UploadService ) {
*/

clouderty.controller("twillioSMSReceiveCtrl", ['$http', '$location', "$stateParams", '$scope', 'flashService' , 'twillioService', '$timeout', function ($http , $location, $stateParams, $scope, flashService, twillioService, $timeout)  {

    console.log("in twillioCtrl");

    $scope.sendTextMessage = function () {
        console.log("in function sendTextMessage");

        return $http.post("api/twillio-sms-receive");

    }

    $scope.sendTextMessage();

}])