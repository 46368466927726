/**
 * Created by Aaron on 14/07/2016.
 */


clouderty.filter('formatDateService', function(dateFilter) {
    var formattedDate = '';
    return function(dt) {
        console.log(new Date(dt.split('-').join('/')));
        formattedDate = dateFilter(new Date(dt.split('-').join('/')), 'd/M/yyyy');
        return formattedDate;
    }

});