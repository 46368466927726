/**
 * Created by Cyril on 14/07/2016.
 */
clouderty.service("pdfCreateService", ["moment", "fileUploadService", "Upload", "$rootScope", "apiService", "$q", 'FlashService' , 'UploadService', '$http', '$location',  function (moment, fileUploadService, Upload, $rootScope, apiService, $q, FlashService, UploadService, $http, $location) {

    this.pdfAddImage = function(fileName, attachmentFileLocation, status, to_email, from_email, subject, message) {
        'use strict';
        console.log("inside service pdfCreateService pdfAddImage");




                var vm = this;
                var submitText = "Send Email";
                var preview = "./preview.png";

                function convertPDf() {
                    var doc =  new jsPDF('p','mm','a4');
                    var specialElementHandlers = {
                        '#maincont': function (element, renderer) {
                            return true;
                        }
                    };

                    doc.fromHTML($('body').html(), 15, 15, {
                        'width': 100,
                        'margin': 1,
                        'pagesplit': true,
                        'elementHandlers': specialElementHandlers
                    }, function (dispose) {
                        var pdf = doc.output(); //returns raw body of resulting PDF returned as a string as per the plugin documentation.
                        var data = new FormData();
                        var filename = vm.filename;
                        var email    =    vm.email;

                        data.append("file" , pdf);
                        data.append('filename', filename);
                        data.append('email', email);
                        data.append('method', "savePdf");

                        UploadService.uploadImage(data,function(res,err) {
                            if(err){
                                FlashService.Error(err,false);
                                resetForm();
                            }  else{
                                if(res.data.status=='success'){
                                    FlashService.Success(res.data.message,false);
                                    //resetForm();
                                    $("html, body").animate({ scrollTop: 0 }, "slow");
                                }
                            }
                        });
                    });
                }

                function resetForm() {
                    vm.myFile = null;
                    angular.element("input[type='file']").val(null);
                    $('#image-preview').attr('src', preview);
                    vm.filename = null;
                    vm.email = null;
                }


                convertPDf();

            }

    this.pdfAddHTML = function(element, fileName, attachmentFileLocation, status, to_email, from_email, subject, message) {

        'use strict';
        var service = {};
        //var file = "";


        $('.alert').show('');
        var doc = new jsPDF('p', 'pt', 'letter');

        var specialElementHandlers = {
            element : function (element, renderer) {
                return true;
            }
        };

        var getGlobalPromise = function() {

            var q55 = $q.defer();
            apiService.getGlobalPromise().then(
                function (response) {
                    console.log("API getGlobalPromise");
                    q55.resolve(response);
                }
            )
            return q55.promise;
        }


        var loadData = function() {

            var q = $q.defer();
            var myValue = 0;

            doc.setFontSize(16);

            doc.fromHTML($('body').html(), 40, 80, {
                'width': 500,
                'margin': 1,
                'pagesplit': true,
                'elementHandlers': specialElementHandlers
            }, function (dispose) {
                var pdf = doc.output(); //returns raw body of resulting PDF returned as a string as per the plugin documentation.
                var file = doc.output('blob');
                var pdfData = new FormData();
                var fileData = new FormData();
                //var filename = $scope.filename;
                //var email = $scope.email;

                pdfData.append("file", pdf);
                pdfData.append('filename', fileName);
                pdfData.append('email', to_email);
                pdfData.append('method', "savePdf");

                fileData.append("file", file);
                fileData.append('filename', fileName);
                fileData.append('email', to_email);
                fileData.append('method', "savePdf");

                $rootScope.fileNamePDF = fileName + '.pdf';

                console.log("$rootScope.fileNamePDF: " + $rootScope.fileNamePDF );
                console.log("fileName: " + fileName );

                doc.setProperties({
                    name: fileName
                });


                window.open(URL.createObjectURL(file));
                console.log(URL.createObjectURL(file));

                $rootScope.file = file;

                service.uploadImage = uploadImage;

                console.log("going through upload");
                return Upload.upload({
                    url: 'api/files',
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                    method: 'POST',
                    file: $rootScope.file,
                    data: {
                        'Account_Holder_ID': '1',
                        'File_Type': 'note',
                        'ID': '22222222222',
                        'File_Category': 4,
                        'File_Name2': fileName,
                        'PDF': 1
                    }
                })
                    .then(function (response){

                        console.log(response);

                        $rootScope.fileName = fileName + "-1-" + response.data.File_ID;

                        $rootScope.fileName2 = $rootScope.fileName;
                        doc.save($rootScope.fileName2);
                        console.log("xxxxxxxxxxxxx $rootScope.fileName: " + $rootScope.fileName);


                        //anuj's code starts

                        /*
                        data.append("file" , pdf);
                        data.append('filename', $rootScope.fileName);
                        data.append('email', email);
                        data.append('method', "savePdf");
                        */

                        service.uploadImage(pdfData, function (res, err) {
                            if (err) {
                                //flashService.Error(err, false);
                                ///resetForm();
                            } else {
                                if (res.data.status == 'success') {
                                    //FlashService.Success(res.data.message, false);
                                    //resetForm();
                                    $("html, body").animate({scrollTop: 0}, "slow");
                                    $timeout(function () {
                                        $('.alert').html('');
                                        $('.alert').hide();
                                    }, 5000);
                                }
                            }
                        });

                    })
                    .then(function (data) {
                        //upload pdf to server
                        console.log("A7 - email");

                        var attachmentFileName = $rootScope.fileName + ".pdf";

                        /*
                           //var to_email = "cyril.thomas.uk@gmail.com";
                           var attachmentFileLocation = ".;


                           var from_email = "Clouderty | Tenancy Signed " + " | " + " <notifications@clouderty.co.uk>";
                           var subject = "Created by Cyril" ;
                           var message = "Test content 1: " + "<br />" +
                               "Download file: " + attachmentFileName + "<br />" +
                               "Filename: " + attachmentFileLocation + attachmentFileName + "<br />";
                         */
                        $rootScope.sendEmail(from_email,to_email,subject,message,"Tenant", attachmentFileName, attachmentFileLocation);

                        return getGlobalPromise(data);

                    });

            })


        }

        loadData();




        return service;

        function uploadImage(user, callback) {
            return $http.post('api/pdf-upload', user, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }).then(function (res) {
                callback(res);
            }, function (err) {
                callback(null, err);
            });
        }

        /*
        function resetForm() {
            angular.element("input[type='file']").val(null);
            $('#image-preview').attr('src', $scope.preview);
            $scope.filename = null;
            $scope.email = null;
        }
*/

    }

    /*
        this.pdfAddHTML = function(fileName, attachmentFileLocation, status, to_email, from_email, subject, message) {
            'use strict';
            var service = {};


            service.uploadImage = uploadImage;

            return service;

            function uploadImage(user,callback) {
                return $http.post('api/pdf-upload', user,{
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined}
                }).then(function(res){
                    callback(res);
                }, function(err){
                    callback(null,err);
                });
            }

        }

    */




}])
