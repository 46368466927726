
clouderty.controller("awardsNominationStage2AddEditCtrl", ["$scope",  "$rootScope", "$http", "$stateParams", "$state", "apiService", "loginExternalWebPageService", "$q", "apiService", "postUserActivityService", "$window", "fileDirectoriesService", "notePostService", "dateInputService", function ($scope, $rootScope, $http, $stateParams, $state, apiService, loginExternalWebPageService, $q, apiService, postUserActivityService, $window, fileDirectoriesService, notePostService, dateInputService) {

    console.log("inside controller awardsNominationStage2AddEditCtrl");

    $('.data').hide();

    console.log("inside watch and $rootScope.watchPaymentAddEdit is: " + $rootScope.watchAwardsSurround);

    $scope.$watch('watchAwardsSurround', function (toVariable, fromVariable) {

        console.log("inside watch and $rootScope.watchAwardsSurround is: " + $rootScope.watchAwardsSurround);
        console.log("inside watch and fromVariable is: " + fromVariable);
        console.log("inside watch and toVariable is: " + toVariable);

        if(toVariable == 1){

            console.log("inside watch and obj is: " + $rootScope.watchAwardsSurround);
            console.log("inside watch and fromVariable is: " + fromVariable);
            console.log("inside watch and toVariable is: " + toVariable);

            $scope.pageno = 1;
            $scope.totalCount = 0;
            $scope.itemsPerPage = 20000;
            $scope.search = "";
            $scope.progress = {};
            $scope.DateToday = new Date();
            $scope.date = new Date();

            $scope.awardNominations = {};
            $scope.awardCategories = {};
            $scope.awards = {};
            $scope.awards.awardCategories = {};
            $scope.awards.awardNominationRequest = {};
            $scope.awards.awardNominator = {};
            $scope.awardsData = {};
            $scope.countriesData = {};
            $scope.countries = {};

            $scope.nomineesDetailsBloc = 1;
            $scope.nomineesDetailsVisibleBloc = 1;
            $scope.nominationSubmittedBlock = 0;
            $scope.paymentTransactionsData = {};
            $scope.payment_transaction = {};
            $scope.payment_transaction.cardDetails = {};
            $scope.payment_transaction.adminPayment = 1;
            $rootScope.payment_transaction = {};
            $rootScope.payment_transaction.Payment_Transaction_Card_Completed = 0;

            $scope.directoryData = {};
            $scope.directoryDataRetrieved = {};
            $scope.directoryData.directoryInformation = {};
            $scope.directoryData.directoryInformation.Path2Files = {};
            $scope.fileDeleteData = {};
            $scope.stateCurrent = "";

            $scope.noteGet = {};
            $scope.note_loading = false;
            $scope.posted_note = {};

            $(document).ready(function () {
                $('.nav-tabs li').on("click", function() {
                    console.log('is this it 1');
                    $('.nav-tabs li').removeClass("active");
                    $(this).addClass("active");
                })
            })
            $('.data').hide();

            //////////////////////////  Functions  /////////////////////////////////////////////////////////////////////////////////

            $scope.nomineeType = {
                Nominee_Type_ID: '1'
            };

            $scope.getGlobalPromise = function() {

                var q55 = $q.defer();
                apiService.getGlobalPromise().then(
                    function (response) {

                        //$scope.landlords = response.data;
                        console.log("API getGlobalPromise");

                        q55.resolve(response);

                    }
                )

                return q55.promise;

            }

            $scope.awardsFunction = function(id, status) {

                console.log("status >>>> " + status);
                var qawardsFunction = $q.defer();

                console.log("function processesAccountFunction");
                console.log("$scope.pageno" + $scope.pageno);

                $scope.awardsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.awardsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.awardsData.id = $rootScope.decodedToken.id;
                $scope.awardsData.itemsPerPage = $scope.itemsPerPage;
                $scope.awardsData.pageno = $scope.pageno;
                $scope.awardsData.Status = status;
                $scope.awardsData.Process_ID = id;


                if($scope.search){

                    $scope.awardsData.search = $scope.search;

                }
                else{

                    $scope.awardsData.search = "*";

                }

                console.log($scope.awardsData);
                apiService.awards(id, $scope.awardsData).then(
                    function (response) {
                        console.log("apiService awards service");

                        //$scope.awardsData.Awards_Category_Question_Join_ID = dateInputService.dateInputDayService($scope.awardsQuestion.Answer, 0);

                        if(status == 60){
                            console.log(response);
                            console.log("apiService awards service successful status 60");
                            $scope.awardCategories = response.data.awardsCategories;

                        }
                        else if(status == 12){
                            console.log(response);
                            console.log("apiService awards service successful status 12");
                            $scope.awards.awardsNominationValidation = response.data.data;
                        }
                        else if(status == 71){
                            console.log(response);
                            console.log("apiService awards service successful status 71");
                            $scope.awards.awardsNomination = response.data.awardsNomination;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsQuestionsCount = response.data.awardsQuestionsCount;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }
                        else if(status == 64){

                            console.log(response);
                            console.log("apiService awards service successful status 64");
                            $scope.awards.awardNominationRequest.First_Name = "";
                            $scope.awards.awardNominationRequest.Last_Name = "";
                            $scope.awards.awardNominationRequest.Email = "";
                            $scope.awardNominations = response.data.data;
                            $scope.nominatorsDetailsBloc = 1;
                            $scope.nomineesDetailsVisibleBloc = 0;

                        }
                        else if(status == 641){
                            console.log(response);
                            console.log("apiService awards service successful status 641");
                            $scope.awards.awardNominator.First_Name = "";
                            $scope.awards.awardNominator.Last_Name = "";
                            $scope.awards.awardNominator.Email = "";
                            $scope.awardNominator = response.data.data;
                            $scope.nominationSubmittedBlock = 1;

                        }
                        else if(status == 80){//update awards question answer
                            console.log(response);
                            console.log("apiService awards service successful status 70");
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            //$scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            //$scope.awards.awardsPropertyDealDetailsCount = response.data.awardsPropertyDealDetailsCount;
                        }//update awards question answer
                        else if(status == 81){//update property details question completed
                            console.log("apiService awards service successful status 81");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;
                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;


                        }//update property details question completed
                        else if(status == 82){//update awards question completed
                            console.log("apiService awards service successful status 82");

                            console.log(response);

                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards question completed
                        else if(status == 83){//update property details question answer
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update property details question answer
                        else if(status == 84){//update awards files displayed
                            console.log("apiService awards service successful status 83");

                            console.log(response);

                            $scope.awards.awardsPropertyDealDetails = response.data.awardsPropertyDealDetails;
                            $scope.awards.awardsQuestions = response.data.awardsQuestions;

                        }//update awards files displayed
                        console.log("awards API successful");

                        console.log(response);

                        qawardsFunction.resolve(response);


                    },
                    function () {

                        console.log("API Service Error: awards");

                    }

                );

                return qawardsFunction.promise;

            }

            $scope.paymentTransactionsFunction = function (id, status) {
                console.log("function paymentTransactionsFunction");

                //$scope.paymentTransactionsData = $scope.payment_transaction;
                $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.paymentTransactionsData.Property_ID = 0;

                console.log("$state.current.name >>>>>>>>>>>> aaaaaaaaaaaaaaaa >>>>>>>>>>>>>>>>> : " + $state.current.name);

                var q2 = $q.defer();
                console.log($scope.paymentTransactionsData);

                apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                    function (response) {
                        $scope.paymentTransactions = response.data;


                        $scope.payment_transaction.adminPayment = 0;

                        console.log($scope.paymentTransactions);
                        console.log("API Service: paymentTransactions");
                        console.log(response);
                        console.log("response.Payment_Transaction_ID: " + response.Payment_Transaction_ID);
                        console.log("response.data.Payment_Transaction_ID: " + response.data.Payment_Transaction_ID);
                        //console.log("response.Payment_Transaction_Card_Completed: >>>>>>>>>>> " + response.Payment_Transaction_Card_Completed);
                        console.log($scope.paymentTransactions);
                        $scope.payment_transaction.Payment_Transaction_ID = $scope.paymentTransactions.Payment_Transaction_ID;
                        console.log("$scope.payment_transaction.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        $scope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $rootScope.payment_transaction.Payment_Transaction_Card_Completed = $scope.paymentTransactions.Payment_Transaction_Card_Completed;
                        $scope.payment_transaction.Failed_Response_Message = $scope.paymentTransactions.Failed_Response_Message;
                        $scope.payment_transaction.First_Payment = $scope.paymentTransactions.First_Payment;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Amount = $scope.paymentTransactions.Payment_Transactions_Recurring_Amount;
                        $scope.payment_transaction.Payment_Transactions_Recurring_Order_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_Order_ID;
                        $scope.payment_transaction.Payment_Transactions_Recurring_ID = $scope.paymentTransactions.Payment_Transactions_Recurring_ID;

                        if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){
                            console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.paymentTransactions.Payment_Transactions_Recurring_Completed);

                            $rootScope.payment_transaction.Payment_Transactions_Recurring_Completed = $scope.paymentTransactions.Payment_Transactions_Recurring_Completed;

                        }

                        if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1'){
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " SUCCESSFUL";
                            $rootScope.paymentResultText = $scope.paymentResultText;

                            $scope.payment_transaction.paymentDiv = '0';
                            //check if this is a tenancy signup rent payment

                            console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv $state.current.name: " + $state.current.name);
                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                console.log("check if this is a tenancy signup rent payment");



                                //var el = document.getElementById("agreeToClauses");
                                //el.scrollIntoView();
                                if (!$rootScope.Rent_Payment_ID_First) {
                                    //check if a first rent payment exists if not add payment id to tenancy
                                    console.log("check if a first rent payment exists if not add payment id to tenancy");
                                    $rootScope.Rent_Payment_ID_First = $scope.paymentTransactions.Payment_Transaction_ID;
                                    //$scope.tenanciesFunction($stateParams.tenancyID, '25');

                                }

                            }


                        }else{
                            //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                            console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                            console.log("API Service: paymentTransactions");
                            $scope.paymentResultText = "PAYMENT " + $scope.payment_transaction.Payment_Transaction_ID + " FAILED - " + $scope.payment_transaction.Failed_Response_Message;
                            $rootScope.paymentResultText = $scope.paymentResultText;
                        }

                        console.log("$scope.paymentTransactions.Payment_Transaction_ID: " + $scope.payment_transaction.Payment_Transaction_ID);
                        console.log("$scope.paymentTransactions.Payment_Transactions_Recurring_Completed: " + $scope.payment_transaction.Payment_Transactions_Recurring_Completed);
                        console.log("$scope.paymentTransactions.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);
                        q2.resolve(response);
                    },
                    function () {
                        console.log("There was an error in paymentTransactions");
                    }
                )
                //});
                return q2.promise;
            }

            $scope.getCategories = function () {

                var qgetCategories = $q.defer();

                qgetCategories.resolve($scope.awardsFunction('*', 60));

                return qgetCategories.promise;

            }

            $scope.makeDir = function () {

                console.log("in function makeDir");

                $scope.paymentBlockSpinner = 1;

                var qmakeDir = $q.defer();
                var qcreateDirectory = $q.defer();
                $scope.myValue = 0;
                var promise = loginExternalWebPageService.init("1");

                promise
                    .then(function (data) {

                        console.log("A1 login external");
                        $rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - apiService.fileDirectories");



                        $scope.directoryData = {};
                        $scope.directoryData.Path1 = "nominations";
                        $scope.directoryData.Path2 = $stateParams.awardsNominationID;
                        $scope.directoryData.Status = 41;
                        $scope.directoryData.id = 0;

                        fileDirectoriesService.createDirectory($scope.directoryData).then(
                            function (response) {

                                console.log("service fileDirectoriesService createDirectory");
                                $scope.directoryDataRetrieved = response;
                                console.log(response.data);
                                console.log(response.Path2);
                                $scope.directoryDataRetrieved.Path2 = response.data.Path2;
                                qcreateDirectory.resolve(response);


                            }
                        );

                        qcreateDirectory.promise;
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7 - directoryDataRetrieved');

                        $scope.myValue = data;


                        console.log($scope.directoryDataRetrieved);
                        console.log("$scope.directoryDataRetrieved.Path2: " + $scope.directoryDataRetrieved.Path2);

                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                qmakeDir.resolve(promise);
                return qmakeDir.promise;
            }

            $scope.getDirItems = function (pageno) {

                console.log("in function dirTests");

                $scope.paymentBlockSpinner = 1;

                var q = $q.defer();

                $scope.myValue = 0;
                $scope.promise = loginExternalWebPageService.init("1");
                $scope.pageno = pageno;

                $scope.promise
                    .then(function (data) {

                        console.log("A1 login external");
                        $rootScope.decode();

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2 - apiService.fileDirectories");
                        $scope.directoryData = [];
                        console.log("$scope.pageno: " + $scope.pageno);

                        $scope.directoryData.Path1 = "nominations";
                        $scope.directoryData.Path2 = $stateParams.awardsNominationID;
                        $scope.directoryData.Status = 51;
                        $scope.directoryData.id = 0;
                        $scope.directoryData.pageno = $scope.pageno;


                        fileDirectoriesService.getFiles($scope.directoryData).then(
                            function (response) {
                                console.log("successful api call fileDirectoriesService.getFiles");
                                console.log(response);
                                $scope.directoryData.directoryInformation = response;
                                $scope.totalCount = response.Path2FilesSanitisedCount;
                                $scope.currentPage = $scope.pageno;
                                console.log(response.Path2FilesSanitisedCount);

                            },
                            function () {

                                console.log("API Service Error: awards");

                            }
                        );

                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7 $scope.directoryData.directoryInformation');

                        $scope.directoryData.directoryInformation.Path2FilesSanitised = [];

                        $scope.directoryData.directoryInformation.Path2FilesSanitised = $scope.directoryData.directoryInformation.Path2FilesSanitised;
                        console.log($scope.directoryData.directoryInformation);
                        console.log($scope.directoryData.directoryInformation.Path2Files);

                        $scope.myValue = data;

                        return $scope.getGlobalPromise(data);
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);

            }

            $scope.deleteDirItem = function (dirData) {

                console.log("inside function deleteDirItem");

                if(confirm("Do you want to delete file " + dirData + "?", "Clouderty Notification")){
                    $scope.directoryData.directoryInformation = [];

                    console.log("in function dirTests");
                    console.log(dirData);

                    $scope.paymentBlockSpinner = 1;

                    var q = $q.defer();

                    $scope.myValue = 0;
                    $scope.promise = loginExternalWebPageService.init("1");

                    $scope.promise
                        .then(function (data) {

                            console.log("A1 login external");
                            $rootScope.decode();

                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {

                            console.log("A2 - apiService.fileDirectories");

                            $scope.fileDeleteData.Path1 = "nominations";
                            $scope.fileDeleteData.Path2 = $stateParams.awardsNominationID;
                            $scope.fileDeleteData.Status = 31;
                            $scope.fileDeleteData.id = 0;
                            $scope.fileDeleteData.PathFile = dirData;

                            fileDirectoriesService.getFiles($scope.fileDeleteData).then(
                                function (response) {

                                    console.log(response);
                                    $scope.directoryData.directoryInformation = response;
                                },
                                function () {

                                    console.log("API Service Error: awards");

                                }
                            );

                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7 $scope.directoryData.directoryInformation');

                            $scope.myValue = data;

                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })

                    q.resolve($scope.promise);

                }


            }

            $scope.openFile = function (dirData) {

                console.log("inside function openFile");






                console.log(dirData);
                console.log(dirData.path);
                console.log($scope.directoryData.directoryInformation.http);
                console.log($scope.directoryData.directoryInformation.pageURL1);
                console.log($scope.directoryData.directoryInformation.pageURL3);

                var path = dirData.path;

                path = path.replace("..", "");

                console.log(path);

                var filePath = $scope.directoryData.directoryInformation.http + $scope.directoryData.directoryInformation.pageURL1 + '/' + $scope.directoryData.directoryInformation.pageURL3 + path;

                //filePath = "http://localhost/clouderty_do/uploads/nominations/1/Landlord%20Statement%20Transactions%20(11)%20-%20Copy.csv"

                console.log(filePath);
                window.open(filePath,'_blank');

            }

            $scope.getNomination = function () {
                console.log("inside function getNomination");
                var qgetNomination = $q.defer();


                console.log("$stateParams.awardsNominationID: " + $stateParams.awardsNominationID);
                $scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                qgetNomination.resolve($scope.awardsFunction($stateParams.awardsNominationID, 71));

                return qgetNomination.promise;

            }

            $scope.updateQuestionsCompletedPropertyDetails = function (awardsQuestion) {
                console.log('inside function updateQuestionsCompleted');
                console.log(awardsQuestion);
                console.log('$scope.awardsQuestion.Awards_Category_Question_ID: ' + awardsQuestion.Awards_Category_Question_ID);
                console.log('$scope.awardsQuestion.Completed_Checkbox: ' + awardsQuestion.Completed_Checkbox);
                console.log('$scope.awardsQuestion.Answer: ' + awardsQuestion.Answer);

                var q62 = $q.defer();
                var date = new Date();
                date.setHours(date.getHours() + 1);

                console.log('awardsQuestion.Awards_Category_Question_Join_ID: ' + awardsQuestion.Awards_Category_Question_Join_ID);
                $scope.awardsData.Awards_Category_Question_Join_ID = awardsQuestion.Awards_Category_Question_Join_ID;

                if(awardsQuestion.Input_Type_ID == 4){

                    console.log('awardsData.Answer: ' + awardsQuestion.Answer);

                    $scope.awardsData.Answer = dateInputService.dateInputDayService(awardsQuestion.Answer, 0);

                    console.log('$scope.awardsData.Answer: ' + $scope.awardsData.Answer);

                }

                //$scope.processes_account.Date_Completed = date;
                if(awardsQuestion.Completed_Checkbox == 'true'){
                    $scope.awardsData.Completed = 1;

                    //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
                }else if(awardsQuestion.Completed_Checkbox == 'false'){
                    $scope.awardsData.Completed = 0;
                }

                //$scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;
                $scope.awardsFunction(0, 81);

                return q62.promise;
            }

            $scope.updateQuestionsCompleted = function (awardsQuestion) {


                console.log('inside function updateQuestionsCompleted');
                var q62 = $q.defer();
                //$rootScope.noteSpinner = 0;

                console.log(awardsQuestion);
                console.log('$scope.awardsQuestion.Awards_Category_Question_ID: ' + awardsQuestion.Awards_Category_Question_ID);
                console.log('$scope.awardsQuestion.Completed_Checkbox: ' + awardsQuestion.Completed_Checkbox);
                //$scope.awardsQuestion.Completed = 1;
                var date = new Date();
                date.setHours(date.getHours() + 1);

                $scope.awardsData.Awards_Category_Question_Join_ID = awardsQuestion.Awards_Category_Question_Join_ID;
                console.log('$scope.awardsQuestion.Awards_Category_Question_Join_ID: ' + awardsQuestion.Awards_Category_Question_Join_ID);
                //$scope.processes_account.Date_Completed = date;
                if(awardsQuestion.Completed_Checkbox == 'true'){
                    $scope.awardsData.Completed = 1;

                    //$scope.sendEmailInternalPostNote(to_email,subject,message, property, task_name, note_type, note, note_category_type, task_id, unit_number, email_signature);
                }else if(awardsQuestion.Completed_Checkbox == 'false'){
                    $scope.awardsData.Completed = 0;
                }

                //$scope.processes_account.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;
                $scope.awardsFunction(0, 82);

                //$scope.postProcessNote(processLinkedToAccount);

                //alert("This process has been updated: " + processLinkedToAccount.Process_Description)
                return q62.promise;
            }

            $scope.validateNominationLink = function () {
                console.log("inside function getNomination");
                var qgetNomination = $q.defer();


                console.log("$stateParams.awardsNominationID: " + $stateParams.awardsNominationID);
                console.log("$stateParams.awardsNominationID: " + $stateParams.Licence_Key);

                $scope.awardsData.Awards_Nomination_ID = $stateParams.awardsNominationID;
                $scope.awardsData.Licence_Key = $stateParams.licenseKey;
                qgetNomination.resolve($scope.awardsFunction($stateParams.awardsNominationID, 12));


                return qgetNomination.promise;

            }

            $scope.getCountries = function () {

                var qgetCountries = $q.defer();

                qgetCountries.resolve(
                    apiService.countries('*', $scope.countriesData).then(
                        function (response) {
                            $scope.countries = response.data.data;
                            qgetCountries.resolve(response);
                        }
                    )
                );

                return qgetCountries.promise;

            }

            $scope.addNomination = function () {

                console.log("inside function addNomination");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nomination?")){
                    $scope.awardsData.First_Name = $scope.awards.awardNominationRequest.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominationRequest.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominationRequest.Contact_number;
                    $scope.awardsData.Address_1 = $scope.awards.awardNominationRequest.Address_1;
                    $scope.awardsData.Address_2 = $scope.awards.awardNominationRequest.Address_2;
                    $scope.awardsData.Town = $scope.awards.awardNominationRequest.Town;
                    $scope.awardsData.City_Region = $scope.awards.awardNominationRequest.City_Region;
                    $scope.awardsData.Postcode = $scope.awards.awardNominationRequest.Postcode;

                    /*
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    $scope.awardsData.Email = $scope.awards.awardNominationRequest.Email;
                    */

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 64));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nomination Submitted " + $scope.Awards_Nomination_Request_ID + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Adam' + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + $scope.Awards_Nomination_Request_ID + "<br /><br />" +
                                "Awards Category: " + $scope.awardsData.Description + "<br /><br />" +
                                "Nominee Name: " + $scope.awardsData.First_Name + "<br /><br />" +
                                "Nominee Contact Number: " + $scope.awardsData.Contact_number + "<br /><br />" +
                                "Nominee Email: " + $scope.awardsData.Email + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.addNominator = function () {

                console.log("inside function addNominator");
                var qaddNomination = $q.defer();

                if(confirm("Do you want to add this nominator?")){
                    $scope.awardsData.First_Name = $scope.awards.awardNominator.First_Name;
                    $scope.awardsData.Last_Name = $scope.awards.awardNominator.Last_Name;
                    $scope.awardsData.Email = $scope.awards.awardNominator.Email;
                    $scope.awardsData.Contact_number = $scope.awards.awardNominator.Contact_number;

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2");
                            //$scope.getCategories();


                            return qaddNomination.resolve($scope.awardsFunction('*', 641));
                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7a');
                            console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                            var from_email = "Clouderty | Awards Nominator Added " + '18' + " | " + 'Property Deal of the Year' + " <notifications@clouderty.co.uk>";
                            var subject = $rootScope.decodedToken.Name + " | " + 'Adam' + " | " + 'Property Deal of the Year';
                            var message = "Awards Nomination Request ID: " + "18" + "<br /><br />" +
                                "Awards Category: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Name: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Contact Number: " + 'sdfdsfsds' + "<br /><br />" +
                                "Nominee Email: " + $scope.date + "<br /><br />";


                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);


                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })


                }else{


                }


                return qaddNomination.promise;

            }

            $scope.getCategory = function () {

                $scope.awardsData.Description = $scope.awards.awardCategories.Description;
                $scope.awardsData.Awards_Category_ID = $scope.awards.awardCategories.Awards_Category_ID;
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Description);
                console.log("$scope.awardsData.awardCategories: " + $scope.awards.awardCategories.Awards_Category_ID);

            }

            $scope.getCountry = function () {

                $scope.awardsData.Country_ID = $scope.awards.countries.Country_ID;
                $scope.awardsData.Country = $scope.awards.countries.Country;
                console.log("$scope.awardsData.Country_ID: " + $scope.awardsData.Country_ID);
                console.log("$scope.awardsData.Country: " + $scope.awardsData.Country);

            }

            $scope.loadAwardsData = function () {
                console.log("inside function loadAwardsData");


                $('.data').hide();
                var qloadAwardsData = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {

                        console.log("A1 - validateNominationLink");


                        return $scope.validateNominationLink(data);
                    })
                    .then(function (data) {

                        if(!$scope.awards.awardsNominationValidation){
                            console.log("A1 - awardsNominationValidation is not valid");
                            alert('Your nomination link is invalid. You will be redirected to the nominations page.')
                            $window.open("https://propertyinvestorsawards.co.uk/nominate/", "_self");
                            return  $q.reject('awardsNominationValidation is not valid');
                        }
                        console.log("A2 - getNomination");


                        return $scope.getNomination(data);
                    })
                    .then(function (data) {

                        console.log("A2 - getCategories");

                        return $scope.getCategories(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A3");
                        //$scope.getCountries();
                        $scope.getDirItems($scope.pageno);
                        return $scope.getCountries(data);
                        //return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7a');
                        console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);


                        $('.data').hide();
                        return $scope.getGlobalPromise(data);
                    }, function (err) {


                        $scope.myValue = err;
                    })

                qloadAwardsData.resolve($scope.promise);

            };

            $scope.addPaymentTransactions = function () {
                console.log("Some test 1: Add transaction");
                //if (confirm("Do you want to add this transaction?")) {
                console.log("Add transaction");


                if(confirm('Confirm administration payment', 'CLOUDERTY NOTIFICATION')){


                    var q = $q.defer();

                    $scope.payment_transaction.adminPayment = 0;

                    $scope.promise = $scope.getGlobalPromise();

                    $scope.promise
                        .then(function (data) {

                            console.log("A2 - paymentTransactionsFunction");

                            $scope.paymentBlockSpinner = '1';
                            $scope.pageBlock = '0';
                            $window.scrollTo(0, 0);

                            var date = new Date();
                            //$scope.paymentTransactionsData.Amount = $scope.payment.obj.Monthly_rental_price;
                            $scope.paymentTransactionsData.Amount = 104.4;

                            $scope.paymentTransactionsData.CVC = $scope.payment_transaction.cardDetails.CVC;
                            $scope.paymentTransactionsData.Date = date;
                            $scope.paymentTransactionsData.Full_Name = $scope.payment_transaction.cardDetails.Full_Name;
                            $scope.paymentTransactionsData.Card_Number = $scope.payment_transaction.cardDetails.Card_Number;
                            $scope.paymentTransactionsData.Expiry = $scope.payment_transaction.cardDetails.Expiry;
                            $scope.paymentTransactionsData.Payment_Transaction_Type_ID = 2;
                            $scope.paymentTransactionsData.Status = 4;
                            $scope.paymentTransactionsData.Payment_Transaction_Category_ID = 50;

                            $scope.paymentTransactionsData.Payment_Transaction_Category_Description = "AWARDSNOMINATION" + $scope.awards.awardsNomination.Awards_Nomination_ID;
                            $scope.paymentTransactionsData.Description = "AWARDSNOMINATION" + $scope.awards.awardsNomination.Awards_Nomination_ID;
                            $scope.paymentTransactionsData.Payment_ID = "AWARDSNOMINATION" + $scope.awards.awardsNomination.Awards_Nomination_ID;

                            $scope.payment_transaction.Receipt_Name = $scope.paymentTransactionsData.Payment_Transaction_Category_Description;
                            $scope.paymentTransactionsData.Receipt_Name = $scope.payment_transaction.Receipt_Name;

                            //clear variables
                            $scope.payment_transaction.cardDetails.Card_Number = "";

                            return $scope.paymentTransactionsFunction("*", "4");

                            //return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log("A3");




                            if($scope.payment_transaction.Payment_Transaction_Card_Completed == '1' && $scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Awards Application Payment Successful " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                var message = "Payment Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "\n\n" +
                                    "Payment ID: " + $scope.paymentTransactionsData.Description + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Date received: " + $scope.payment_transaction.Date + "<br />" +
                                    "Amount:" + " &#163;104.04" + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";



                            }else{

                                if($scope.payment_transaction.Payment_Transaction_Type_ID != 9){
                                    //$scope.payment_transaction.Payment_Transaction_Card_Completed = '0';
                                    console.log("$scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                    var from_email = "Clouderty | Awards Application Payment Failed " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                    var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                    var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "<br /><br />" +
                                        "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                        "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                        "Date received: " + $scope.payment_transaction.Date + "<br />" +
                                        "Amount:" + " &#163;104.04" + "<br />" +
                                        "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";

                                }else{

                                }


                            }



                            //var to_email = $rootScope.decodedToken.Accounts_Email;



                            $scope.postUserActivityServiceMessage = subject;
                            $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);

                            //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            to_email = $rootScope.decodedToken.Notifications_Email;

                            $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            console.log("cccccccccccccccccccccccccccccccccccccccccccccc  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                            if($scope.paymentTransactions.Payment_Transactions_Recurring_Completed == "1"){

                                console.log("sssssssssssssssssssssssssssssssssssssssssss  $scope.payment_transaction.Payment_Transaction_Card_Completed: " + $scope.payment_transaction.Payment_Transaction_Card_Completed);

                                var from_email = "Clouderty | Recurring Payment Setup " + $scope.payment_transaction.Payment_Transaction_ID + " | " + $scope.payment_transaction.Business_Bank_refCust + " <notifications@clouderty.co.uk>";
                                var subject = $rootScope.decodedToken.Name + " | Amount  &#163;" + $scope.payment_transaction.Recurring_Payment_Amount + " | " + $scope.payment_transaction.Payment_Transaction_Category_Description + " | " + $scope.payment_transaction.Payment_Transaction_Type_Description;
                                var message = "Payment  Transaction ID: " + $scope.payment_transaction.Payment_Transaction_ID + "\n\n" +
                                    "Payment  ID: " + $scope.payment_transaction.Payment_ID + "<br /><br />" +
                                    "Payment taken by: " + $rootScope.decodedToken.Name + "<br /><br />" +
                                    "Business: " + $scope.payment_transaction.Business_Bank_ref + "<br />" +
                                    "Customer name: " + $scope.payment_transaction.customer.First_name + "<br />" +
                                    "Start date: " + $scope.payment_transaction.Date + "<br />" +
                                    "Payment category: " + $scope.payment_transaction.Payment_Transaction_Category_Description + "<br />" +
                                    "Payment type: " + $scope.payment_transaction.Payment_Transaction_Type_Description + "<br /><br />" +
                                    "Amount:" + " &#163;" + $scope.payment_transaction.Recurring_Payment_Amount + "<br />" +
                                    "Notes: " + $scope.payment_transaction.Notes + "<br /><br />";

                                $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                            }





                            $scope.payment_transaction.cardDetails.Full_Name = "";
                            $scope.payment_transaction.cardDetails.Card_Number = "";
                            $scope.payment_transaction.cardDetails.Expiry = "";
                            $scope.payment_transaction.Amount = "";
                            $scope.payment_transaction.cardDetails.CVC = "";
                            //$scope.payment_transaction.cardDetails.Recurring_Payment = "";


                            //$rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);
                            $scope.paymentBlockSpinner = '0';
                            $scope.pageBlock = '1';
                            //$('.data').hide();




                            $scope.message = message;
                            if($state.current.name == $scope.stateTenancyView){

                                //add details to a note
                                if ($rootScope.tenancy_id) {

                                    $scope.postNote();
                                    //notePostService.postNote($scope.message);
                                    //$scope.postNote($scope.message);
                                }
                            }



                            $window.scrollTo(0, 0);
                            return $scope.getGlobalPromise(data);
                        })
                        .then(function (data) {
                            console.log('A7');



                            if($state.current.name == $scope.stateTenancyAgreementExternal) {
                                console.log(">>>>>>>>>>> $state analysis: " + $state.current.name);

                                console.log("check if this is a tenancy signup rent payment");
                                console.log("$scope.scrollTo: >>>>>>>>>>> scrollSignature");
                                $scope.scrollTo('scrollSignature');

                            }
                            $scope.myValue = data;

                            return $scope.getGlobalPromise(data);
                        }, function (err) {
                            $scope.myValue = err;
                        })

                    q.resolve($scope.promise);



                }




            };

            $scope.uploadFile = function (description, file, fileTypeID) {
                console.log("inside function uploadFile");

                var quploadFile = $q.defer();
                var date = new Date();
                var fileTypeDescription = "";


                date.setHours(date.getHours() + 1);

                console.log(fileTypeID);

                if(fileTypeID == 5){

                    fileTypeDescription = "Floor_Plan";

                }
                else if(fileTypeID == 4){

                    fileTypeDescription = "Pictures";

                }
                else if(fileTypeID == 6){

                    fileTypeDescription = "Testimonials";

                }
                else if(fileTypeID == 7){

                    fileTypeDescription = "Other";

                }


                $scope.note_loading = true;
                //$scope.noteGet.Process_Account_ID = $stateParams.processAccountID;
                //$scope.noteGet.Tenancy_ID = $scope.processAccount.Tenancy_ID;
                $scope.noteGet.User_ID = $rootScope.decodedToken.id;
                //$scope.noteGet.Notes_Type = "Arrears";
                $scope.noteGet.Date_Entered = date;
                $scope.noteGet.Note_Content = description;
                //$scope.noteGet.Note_Type_ID = 3;
                //$scope.noteGet.Processes_Linked_to_Account_ID = 5544;
                $scope.noteGet.picFile = file;
                $scope.noteGet.picFile.fileTypeDescription = fileTypeDescription;
                $scope.noteGet.picFile.fileTypeID = fileTypeID;

                console.log(file);
                console.log(file.$ngfName);
                console.log("$scope.noteGet.dirPath: " + $scope.noteGet.dirPath);
                console.log("$scope.note.Note_Type_ID: " + $scope.noteGet.Note_Type_ID);

                var myValue = 0;
                var promise = $scope.makeDir();

                console.log($scope.noteGet);

                promise
                    .then(function (response) {
                        console.log("A1a - makeDir");

                        console.log(response);

                        return $scope.getGlobalPromise();

                    })//post make directory if it doesn't exist
                    .then(function (data) {
                        console.log("A1a - makeDir");

                        return $scope.getGlobalPromise(data);
                    })//post make directory if it doesn't exist
                    .then(function (data) {

                        console.log("A1a - postNote");
                        $scope.directoryData = {};

                        $scope.noteGet.dirPath = $scope.directoryDataRetrieved.Path2;
                        console.log("$scope.directoryDataRetrieved.Path2: " + $scope.directoryDataRetrieved.Path2);
                        console.log("$scope.noteGet.dirPath: " + $scope.noteGet.dirPath);

                        //$scope.posted_note = notePostService.postNote($scope.noteGet);
                        $scope.note_loading = false;
                        file.picFile = null;
                        file.picFileImage = null;
                        //return $scope.getGlobalPromise(data);

                        return $scope.posted_note = notePostService.postNote($scope.noteGet);
                    })//post note
                    .then(function (data) {

                        console.log("A1c");

                        console.log($scope.posted_note);
                        console.log("console.log($scope.posted_note) >>>> " + $scope.posted_note);

                        $scope.note_loading = false;

                        return $scope.getGlobalPromise(data);
                    })//set variables
                    .then(function (data) {

                        console.log("A5 ");

                        $scope.note_loading = false;
                        $scope.picFile = null;
                        $scope.picFileImage = null;
                        $scope.getDirItems(1);
                       //$scope.posted_note.note = "";
                        //$scope.obj.note = "";

                        return $scope.getGlobalPromise(data);
                    })//reset variables
                    .then(function (data) {
                            console.log('A7 notes $state.reload');
                            //$state.reload($scope.stateCurrent);


                            myValue = data;
                        }//reload notes
                        ,function (err) {
                            myValue = err;
                        })//end promise

                quploadFile.resolve(promise);

            }

            $scope.postQuestionAnswer = function (awardsQuestion, file, index) {

                if(confirm("Are you sure you want to add/update this question?") == true){

                    $rootScope.noteSpinner = 0;
                    console.log("awardsQuestion.Answer: " + awardsQuestion.Answer);
                    console.log("awardsQuestion.Answer: " + awardsQuestion);
                    console.log("awardsQuestion.Awards_Category_Question_Join_ID: " + awardsQuestion.Awards_Category_Question_Join_ID);
                    console.log("index: " + index);
                    awardsQuestion.index = index;


                    console.log("index: " + index);
                    console.log(file);
                    console.log("file: " + file);
                    $scope.awardsData.Answer = awardsQuestion.Answer;
                    $scope.awardsData.Awards_Category_Question_ID = awardsQuestion.Awards_Category_Question_ID;
                    $scope.awardsData.Awards_Category_Question_Join_ID = awardsQuestion.Awards_Category_Question_Join_ID;
                    $scope.awardsFunction(awardsQuestion.Awards_Nomination_ID, 80);

                }

            }

            $scope.postQuestionAnswerPropertyDetails = function (awardsQuestion, file, index) {

                if(confirm("Are you sure you want to add/update this question?") == true){

                    $rootScope.noteSpinner = 0;
                    console.log("awardsQuestion.Answer: " + awardsQuestion.Answer);
                    console.log("awardsQuestion.Answer: " + awardsQuestion);
                    console.log("awardsQuestion.Awards_Category_Question_Join_ID: " + awardsQuestion.Awards_Category_Question_Join_ID);
                    console.log("index: " + index);
                    awardsQuestion.index = index;



                    console.log("index: " + index);
                    console.log(file);
                    console.log("file: " + file);
                    $scope.awardsData.Answer = awardsQuestion.Answer;
                    $scope.awardsData.Awards_Category_Question_ID = awardsQuestion.Awards_Category_Question_ID;
                    $scope.awardsData.Awards_Category_Question_Join_ID = awardsQuestion.Awards_Category_Question_Join_ID;
                    $scope.awardsFunction(awardsQuestion.Awards_Nomination_ID, 83);

                }

            }


            $scope.uploadQuestionAnswer = function (processLinkedToAccount, file) {

                console.log('arrearsProcessesLinkedToAccount.NotesAdd: ' + processLinkedToAccount.NotesAdd);
                console.log(file);
                var date = new Date();
                date.setHours(date.getHours() + 1);

                $scope.note_loading = true;
                $scope.note.Process_Account_ID = $stateParams.processAccountID;
                $scope.note.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                $scope.note.Tenancy_ID = $scope.processAccount.Tenancy_ID;
                $scope.note.User_ID = $scope.decodedToken.id;
                $scope.note.Notes_Type = "Processes";
                $scope.note.Date_Entered = date;
                $scope.note.Note_Type_ID = 3;
                $scope.note.Processes_Linked_to_Account_ID = processLinkedToAccount.Processes_Linked_to_Account_ID;
                $scope.note.picFile = file;
                $scope.note.Payment_Plan = $scope.processes_account.Payment_Plan;
                $scope.note.Description = processLinkedToAccount.Description;
                $scope.note.Process_Account_ID = processLinkedToAccount.Process_Account_ID;
                $scope.note.sendEmailNotificationNot = 1;

                if(processLinkedToAccount.Property_name){
                    $scope.note.Address_1 = processLinkedToAccount.Address_1;
                    $scope.note.Property_name = processLinkedToAccount.Property_name;
                }


                if(processLinkedToAccount.NotesAdd){
                    console.log(processLinkedToAccount);
                    console.log("processLinkedToAccount.NotesAdd: " + processLinkedToAccount.NotesAdd);
                    $scope.note.note = processLinkedToAccount.Process_Description + " - " + processLinkedToAccount.NotesAdd;

                }else{
                    processLinkedToAccount.NotesAdd = "";
                }

                if(processLinkedToAccount.Completed_Checkbox == 'true'){
                    console.log(processLinkedToAccount);
                    $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Completed - ' + processLinkedToAccount.Process_Description;
                    $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Completed - ' + processLinkedToAccount.Process_Description;


                    if(processLinkedToAccount.NotesAdd){
                        $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;
                        $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;


                    }

                }else if(processLinkedToAccount.Completed_Checkbox == 'false'){
                    console.log(processLinkedToAccount);

                    $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Not complete - ' + processLinkedToAccount.Process_Description;
                    $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + " - " + 'Not complete - ' + processLinkedToAccount.Process_Description;


                    if(processLinkedToAccount.NotesAdd){
                        $scope.note.Note_Content = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;
                        $scope.note.note = processLinkedToAccount.Processes_Linked_to_Account_ID + ' - ' + processLinkedToAccount.Process_Description + " - " +  processLinkedToAccount.NotesAdd;


                    }
                }

                if($scope.paymentPlanModification == 1){
                    $scope.note.Note_Content = "Debt account updated. Debt Account ID: " + $scope.tenancies.tenancyDebtAccount.Debt_Account_ID;
                    $scope.note.note = "Debt account updated. Debt Account ID: " + $scope.tenancies.tenancyDebtAccount.Debt_Account_ID;
                    "Payment plan details: " + $scope.note.Payment_Plan_Details;
                }

                var qpostProcessNote = $q.defer();
                var myValue = 0;
                var promise = $scope.getGlobalPromise();

                promise
                    .then(function (data) {

                        console.log("A1a - postNote");
                        noteService.postNote($scope.note);

                        return $scope.getGlobalPromise(data);
                    })//post note
                    .then(function (data) {

                        console.log("A1c");

                        return $scope.getGlobalPromise(data);
                    })//set variables
                    .then(function (data) {
                        //send email and attachement
                        console.log("A4 - send email and attachement");


                        return $scope.getGlobalPromise(data);

                    })//send email
                    .then(function (data) {

                        console.log("A5 ");


                        return $scope.getGlobalPromise(data);
                    })//reset variables
                    .then(function (data) {
                            console.log('A7 notes $state.reload');
                            $state.reload('lettings-main-nav.lettings-side.process-account-view.notes');
                            $scope.paymentPlanModification == 0;

                            myValue = data;
                        }//reload notes
                        ,function (err) {
                            myValue = err;
                        })//end promise

                qpostProcessNote.resolve(promise);

                return qpostProcessNote.promise;
            }

            ////////////////////////// Auto Load /////////////////////////////////////////////////////////////////////////////////

            $scope.loadAwardsData();

            $('.data').hide();


        }

    })



}])