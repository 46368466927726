/**
 * Created by Aaron on 26/07/2016.
 */
clouderty.directive('dynamicTextInput', ["$rootScope","$compile", function ($rootScope, $compile) {
    return {
        restrict: 'E',
        scope: {
            ngFieldLevel: '@'
        },
        replace: true,
        template: function($scope, $element, $attrs) {
            var html = "";
            if ($rootScope.decodedToken.User_Level > $attrs.ngFieldLevel) {
                html = '<input class="form-input" type="text">';
            } else if ($rootScope.decodedToken.User_Level == $attrs.ngFieldLevel)  {
                html = '<input class="form-input" type="text" disabled>';
            } else {
                html = '<span></span>';
            }

            return html;
        }
    }
}]);