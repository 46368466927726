/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("userActivityViewCtrl", ["$scope", "$http", "$stateParams", "Upload", "$rootScope", "emailService", function ($scope, $http, $stateParams, Upload, $rootScope, emailService)  {
    $(document).ready(function () {

        $('.nav-tabs li').on("click", function() {
            $('.nav-tabs li').removeClass("active");
            $(this).addClass("active");
        })
    })

    $scope.$parent.tenancy_id = $stateParams.id;
    
    $scope.getTenant = function () {
        $http.get("api/tenant/" + $stateParams.id).success(function(response)  {
            $scope.$parent.obj = response;
            $('.data').hide();
        })
    }


    $scope.tenancies = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;


    $scope.getTenantRentalTransactions3 = function () {

        console.log('new tests');
        $scope.tenancies = [];

        $http.get("api/tenant_rental_transactions/" + $stateParams.id).success(function(response)  {
            $scope.$parent.obj = response;
            $scope.tenancies = response;
            $scope.currentPage = 1;
            $scope.totalCount = response.totalCount;
            $scope.sort('ID');

            //$('.data').hide();
        })
    }
    
    console.log('test 1');

    /*     Rental Income     */


        $scope.tenancies = [];
        $scope.pageno = 1;
        $scope.totalCount = 0;
        $scope.itemsPerPage = 20;


    $scope.getRentalTransactions = function(pageno) {

        console.log('test 2');
        $scope.tenancies = [];

        if ($scope.search)
        {
            //$http.get("/tenant/rental_transactions/" + $stateParams.id).success(function(response){
            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search).success(function(response){
            //$http.get("api/tenant_rental_transactions/" + $scope.itemsPerPage + "/" + pageno + "/" + $scope.search  + "/" + $stateParams.id).success(function(response){
                console.log('we are in');
                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
            });

        }
        else
        {
            $http.get("api/tenancy/" + $scope.itemsPerPage + "/" + pageno).success(function(response){
                console.log('we are in the wrong one');
                $scope.tenancies = response;
                $scope.currentPage = pageno;
                $scope.totalCount = response.totalCount;
                $scope.sort('ID');
            });
        }

    }



    console.log('test 4');

    $scope.getRentalTransactions($scope.pageno);
    //$scope.getTenantRentalTransactions3();

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }



    if($stateParams.id) {
        console.log('Does it start here');
        $scope.getTenant();

    }

    $scope.uploadFile = function(file) {

        if ($scope.picFile) {
            Upload.upload({
                url: 'api/files',
                method: 'POST',
                file: file,
                data: {
                    'Account_Holder_ID': $rootScope.decodedToken.Account_Holder_ID,
                    'File_Type': 'note',
                    'ID': $scope.posted_note.note_id,
                    'File_Category': 4
                }
            }).then(function() {
                $http.get("api/notes/" + $scope.note.note_type + '/' + $stateParams.id, $scope.note).success(function (response) {
                    $scope.obj.notes = response.notes;
                    $scope.note_loading = false;
                })
            });
        } else {
            $http.get("api/notes/" + $scope.note.note_type + '/' + $stateParams.id, $scope.note).success(function (response) {
                $scope.obj.notes = response.notes;
                $scope.note_loading = false;
            })
        }

    }

    $scope.note = {};
    $scope.note.id = $stateParams.id;
    $scope.note_loading = false;

    $scope.postNote = function () {
        var date = new Date();
        date.setHours(date.getHours()+1);
        
        $scope.note_loading = true;
        $scope.note.user_id = $scope.decodedToken.id;
        $scope.note.note_type = "Tenancy";
        $scope.note.date = date;

        $http.post("api/notes", $scope.note)
            .success(function (response) {
                $scope.posted_note = response.posted;

                $scope.uploadFile($scope.picFile)

                $scope.note.note = "";
            })
    }

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");
        
        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.openMailSender = function(emailId,subject,message, property){

        //$scope.obj.select_note_type = "Email";

        $scope.a = emailService.openEmailSender(emailId,subject,message, property);

        //$scope.b = postNoteService.postNote('Test Task', 'Task', 'Content of test note', 'Email', $scope, $http, $rootScope);
        //postNote("ssssssssss", 'Task', obj.note, obj.select_note_type);
        //console.log("What now3");

    };    
    
    
}])