/**
 * Created by Aaron on 25/07/2016.
 */


clouderty.controller("emailCtrl", ["$scope", "$http", "$stateParams", "$uibModal", "Upload", "$rootScope", function($scope, $http, $stateParams, $uibModal, Upload, $rootScope) {

    $scope.sendEmailWithConfirmation = function () {
        //Send SES Email code


        if (confirm('Are you sure you want to send an email notification?')) {

            var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
            var to_email = "c.thomas@platinumcrown.co.uk";
            var subject = "Subject Line Test" + "| By: " + $rootScope.decodedToken.Name;
            var message = "Line q" + "\n" +
                "Line 2" + "\n";
            //$rootScope.sendEmail(from_email, to_email, subject, message);
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            // Email code
            
        } else {
            // Do nothing!
        }
        //alert("Hello! I am an alert box!");

    }


    $scope.sendEmailAutomatic = function () {
        //Send SES Email code
 
            var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
            var to_email = "c.thomas@platinumcrown.co.uk";
            var subject = "Note ID: 2| Created On Task: '" + "'| By: " + $rootScope.decodedToken.Name;
            var message = "Note ID: 2" + "\n" +
                "As Part Of Task: 2" + "\n";
            //$rootScope.sendEmail(from_email, to_email, subject, message);
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            // Email code



    }

    $scope.sendEmailTest = function () {
        //Send SES Email code

        alert("Hello! I am an alert box!");

        var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
        var to_email = "c.thomas@platinumcrown.co.uk";
        var subject = "Note ID: 2| Created On Task: '" + "'| By: " + $rootScope.decodedToken.Name;
        var message = "Note ID: 2" + "\n" +
            "As Part Of Task: 2" + "\n";
        //$rootScope.sendEmail(from_email, to_email, subject, message);
        $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
        // Email code
    }

    $scope.sendEmailNotes = function () {
        //Send SES Email code

        //alert("Hello! I am an alert box!");


        if (confirm('Are you sure you want to send an email notification?')) {

            var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
            var to_email = "c.thomas@platinumcrown.co.uk";
            var subject = "Note ID: " + $scope.posted_note.note_id + "| Created On Task: '" + $scope.posted_note.id + "'| By: " + $rootScope.decodedToken.Name;
            var message = "Note ID: " + $scope.posted_note.note_id + "\n" +
                "As Part Of Task: " + $scope.posted_note.id + "\n" +
                "Created By: " + $rootScope.decodedToken.Name + "\n" +
                "Note Content: " + $scope.posted_note.note;
            //$rootScope.sendEmail(from_email, to_email, subject, message);
            $rootScope.sendEmail(from_email,to_email,subject,message,$rootScope.decodedToken.Name);
            // Email code

        } else {
            // Do nothing!
        }
    }   
    
    
    /*

    // $scope.obj = obj;

    // $("#mailDiv").load('/clouderty/amazon-ses-smtp-sample.php');

    // var from_email = "Clouderty Notifications <notifications@clouderty.co.uk>";
    // var to_email = "aaron@platinumcrown.co.uk";
    // var subject = "Task " + $scope.obj.data.Task_ID + " Complete: " + $scope.obj.data.Description + " - Completed By: " + $scope.obj.data.Created_By;
    // var message = "Task ID: " + $scope.obj.data.Task_ID + "\n Task Name: " + $scope.obj.data.Description + "\nStarted On: " + $scope.obj.data.Date_started + " | Date Completed  " + $scope.obj.data.Date_completed + "\n Completed By: " + $scope.obj.data.Created_By;


    // $scope.ok = function () {
    // $rootScope.sendEmail(from_email, to_email, subject, message);
    //  $uibModalInstance.close();
    // };

    // $scope.cancel = function () {
    //  $uibModalInstance.dismiss('cancel');
    // };



/*

// Take variables from php form

    // $realname = $_POST['realname'];
    $realname = "Cyril Thomas";
    // $clientemail = $_POST['email'];
    $clientemail = "accounts@platinumcrown.co.uk";
    $sendersemail = "notifications@clouderty.co.uk";


// Replace sender@example.com with your "From" address.
// This address must be verified with Amazon SES.
    define('SENDER', $sendersemail);

// Replace recipient@example.com with a "To" address. If your account
// is still in the sandbox, this address must be verified.
    define('RECIPIENT', $clientemail);

// Replace smtp_username with your Amazon SES SMTP user name.
    define('USERNAME','AKIAII5XCG2DH6OCPKPA');

// Replace smtp_password with your Amazon SES SMTP password.
    define('PASSWORD','Agbl3P/iQ3Of60XS6zJTYC8JTtiTgi/MrbFhr3gddZDh');

// If you're using Amazon SES in a region other than US West (Oregon),
// replace email-smtp.us-west-2.amazonaws.com with the Amazon SES SMTP
// endpoint in the appropriate region.
    define('HOST', 'email-smtp.us-west-2.amazonaws.com');

    // The port you will connect to on the Amazon SES SMTP endpoint.
    define('PORT', '587');

// Other message information
    define('SUBJECT','Clouderty Notification');

    $body = "Dear ".$realname.",\n\n";
    $body .= "This is a test.\n\n";
    $body .= "Kind regards,\n";
    $body .= "Clouderty\n\n";
    $body .= "The Colchester Centre\n";
    $body .= "Contrarian Group\n";
    $body .= "Hawkins Road\n";
    $body .= "Colchester\n";
    $body .= "Essex\n";
    $body .= "CO2 8JX\n";
    $body .= "http://www.essexbedstoday.co.uk";
    define('BODY',$body);

    require_once 'Mail.php';

    $headers = array (
            'From' => SENDER,
        'To' => RECIPIENT,
        'Subject' => SUBJECT);

    $smtpParams = array (
            'host' => HOST,
        'port' => PORT,
        'auth' => true,
        'username' => USERNAME,
        'password' => PASSWORD
    );

    // Create an SMTP client.
    $mail = Mail::factory('smtp', $smtpParams);

// Send the email.
    $result = $mail->send(RECIPIENT, $headers, BODY);


    if (PEAR::isError($result)) {
        // echo("Email not sent. " .$result->getMessage() ."\n");
        echo $mess;
        echo '<BR>Click <a href="javascript:history.back(1)">Here</a> to try again';

    } else {
        echo '<META HTTP-EQUIV="refresh" content="0;URL=index.html">';

        // echo("Email sent!"."\n");
    }






     */



}])
    
