clouderty.service("notificationsService", [ '$http', '$q', "$httpParamSerializer", "$stateParams", "$state", "$rootScope", function ($http, $q, $httpParamSerializer, $stateParams, $state, $rootScope) {
        'use strict';

    console.log("inside service notificationsService");
        var canceller, isSending = false;
        var service = {};

        //service.saveNotification = saveNotification;
        //service.fetchNotifications = fetchNotifications;
        service.updateNotificationStatus = updateNotificationStatus;
        service.updateClickedStatus = updateClickedStatus;
        return service;

        /*
        function saveNotification(notification,callback) {
            console.log("inside service function saveNotification");
            var audioElement = document.createElement('audio');
            audioElement.setAttribute('src', "sounds/angular-beep.mp3");
            audioElement.play();
            return $http.post('api/notifications', notification,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){

                    $rootScope.notificstionAlertLastID = res.notificstionAlertLastID;
				  	callback(res);

				  }, function(err){
						callback(null,err);
				});
        }

        */
        /*
        function fetchNotifications(dataObj) {

        	console.log("inside fetchNotifications");
        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor

        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.
            //var User_ID_Action = 1; // Here need to pass logged in session user Id. = 10; // Here need to pass logged in session user Id.

            console.log("dataObj: ");
            console.log(dataObj);

            if(isSending) {
                canceller.resolve()
            }
            isSending = true;
            canceller = $q.defer();

			if(dataObj.User_ID_Action){

                //dataObj.fetchnot = true;

                console.log("dataObj");
                console.log(dataObj);

                return $http({
                    method: 'GET',
                    //url: 'api/notifications?fetchnot=true&User_ID_Action='+dataObj.User_ID_Action,
                    url: 'api/notifications',
                    params: $httpParamSerializer(dataObj),
                    paramSerializer: '$httpParamSerializerJQLike'
                    ///timeout: canceller.promise
                }).success(function (response) {
                    isSending = false;
                    console.log("success");
                });




			}


        }
        */
        function updateNotificationStatus(callback) {
            console.log("inside service function updateNotificationStatus");

        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor


        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.

            return $http({
                method: 'GET',
                //url: 'api/notifications?fetchnot=true&User_ID_Action='+dataObj.User_ID_Action,
                //url: 'api/notifications',
                url: 'api/notifications?updateStatus=true&user_id_pm_1='+user_id_pm_1,
                //params: $httpParamSerializer(dataObj),
                //paramSerializer: '$httpParamSerializerJQLike'
                ///timeout: canceller.promise
            }).success(function (response) {
                isSending = false;
                console.log("success");
            });

            /*
        	return $http.get('api/template_php_mysql_function.php?updateStatus=true&user_id_pm_1='+user_id_pm_1,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){
						callback(res);
				  }, function(err){
						callback(null,err);
				});
				*/
        }

        function updateClickedStatus(notificationId , callback) {


        	// Here needs to pass logged In Role as parameters

        	// Example if Property manager 1 logged In then you need to pass user_id_pm_1
        	// Example if Property manager 2 logged In then you need to pass user_id_pm_2
        	// Example if Assistant manager logged In then you need to pass user_id_am
        	// Example if Assigned Vendor logged In then you need to pass user_id_vendor


        	var user_id_pm_1 = 10; // Here need to pass logged in session user Id.
        	return $http.get('api/template_php_mysql_function.php?updateClickedStatus=true&user_id_pm_1='+user_id_pm_1+'&notification_id='+notificationId,{
					transformRequest: angular.identity,
					headers: {'Content-Type': undefined}
				  }).then(function(res){
						callback(res);
				  }, function(err){
						callback(null,err);
				});
        }

}]);
