/**
 * Created by Aaron on 06/07/2016.
 */
clouderty.controller("vendorAddEditCtrl", ["$scope", "$http", "$stateParams", "$rootScope", function ($scope, $http, $stateParams, $rootScope) {

    $scope.tenancyobj = {};

    $scope.days =  [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
    ];

    $('.data').hide();
    $('.select-tenant-form').show();

    function scanInThisObject() {
        $scope.inthissectionobj = [{}];

        $('.accordionactive').next().find(".formfield").each(function () {
            console.log("hi");
            if ($(this).attr("required")) {
                $scope.inthissectionobj.push(
                    {
                        Name: $(this).attr("id"),
                        Field_Name: $(this).attr("name"),
                        Completed: false
                    }
                )
            }
        })
    }

    $scope.New_Record = false;

    if ($stateParams.id) {
        $scope.New_Record = true;
    }


    scanInThisObject();

    $(".accordion").click(function () {
        $(".accordion").removeClass("accordionactive");
        $(this).addClass("accordionactive");
        scanInThisObject();
    })

    $scope.accordion = function(element, element_data) {
        $('.data').slideUp("fast");
        $('.chev').removeClass("fa-chevron-down");
        $('.chev').addClass("fa-chevron-left");

        if ($('.'+element_data).is(":hidden")) {
            $('.'+element).removeClass("fa-chevron-left");
            $('.'+element).addClass("fa-chevron-down");
            $('.'+element_data).slideToggle("fast");
        }
        else if ($('.'+element_data).is(":visible")) {
            $('.'+element).removeClass("fa-chevron-down");
            $('.'+element).addClass("fa-chevron-left");
        }
    }

    $scope.searchTenant = function() {
        $scope.tenants = null;
        $http.get('api/tenants/' + $scope.search_tenant).success(function(response) {
            $scope.tenants = response;
        })
    }

    $scope.tenancyobj.selectedTenants = [];

    $scope.selectTenant = function(id) {

        $http.get("api/tenant/" + id).success(function (response) {
            $scope.tenancyobj.selectedTenants.push({
                "Tenant_ID": response.Tenant_ID,
                "First_Name": response.First_Name,
                "Last_Name": response.Last_Name,
                "Email": response.Email,
                "Contact_number": response.Contact_number,
                "D_O_B": response.D_O_B
            })
        })

        $scope.search_tenant = "";
    }


    $scope.hideSearch = false;

    $scope.searchUnit = function() {
        $scope.units = null;
        $http.get('api/units/' + $scope.search_unit).success(function(response) {
            $scope.units = response;
        });
    }

    $scope.tenancyobj.selectedUnits = [];
    $scope.selectUnit = function(id) {

        $http.get("api/unit/" + id).success(function (response) {
            $scope.tenancyobj.selectedUnits.push({
                "Unit_ID": response.Unit_ID,
                "Address": response.Address_1,
                "Unit_Number": response.Unit_number,
                "Town": response.Town,
                "City": response.City,
                "Postcode": response.Postcode
            })

        })

        $scope.search_unit = "";
        $scope.hideSearch = true;
    }

    $scope.deselectTenant = function (index) {
        $scope.tenancyobj.selectedTenants.splice(index, 1);
    }
    $scope.deselectUnit = function (index) {
        $scope.tenancyobj.selectedUnits.splice(index, 1);
        $scope.hideSearch = false;
    }

    $scope.addMonths = function(months) {
        var date_string = $scope.tenancyobj.Tenancy_start_date;
        var tenancy_start_date = new Date(date_string);

        $scope.tenancyobj.Tenancy_end_date = new Date(tenancy_start_date.setMonth(tenancy_start_date.getMonth() + months));

    };

    $scope.addTenancy = function() {
        $scope.tenancyobj.User_ID = $rootScope.decodedToken.id;
        console.log($scope.tenancyobj.User_ID);
        $http.post('api/tenancy', $scope.tenancyobj).success(function(response) {
            console.log(response);
        });
    }

}])