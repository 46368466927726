/**
 * Created by Aaron on 08/07/2016.
 */
clouderty.controller("paymentTransactionsSearchCtrl", ["$scope", "$rootScope", "$state", "$location", "$http", "$window", "$stateParams", "$rootScope", "$filter", "postUserActivityService", "authenticateUserLevelService", "apiService", "$q", function ($scope, $rootScope, $state, $location, $http,  $window, $stateParams, $rootScope, $filter, postUserActivityService, authenticateUserLevelService, apiService, $q) {


    $(document).ready(function () {
        $('.dropdown').on("click", function() {
            console.log("Hello");
        });
    });
    console.log("Test A");

    $scope.tenancies = [];
    $scope.pageno = 1;
    $scope.totalCount = 0;
    $scope.itemsPerPage = 20;

    $scope.paymentTransactions = [];
    $scope.payment_transaction = {};
    $scope.payment_transaction.paymentTransactionTypes = {};
    $scope.paymentTransactionsData = {};
    $scope.properties = {};


    $scope.getGlobalPromise = function() {

        var q55 = $q.defer();
        apiService.getGlobalPromise().then(
            function (response) {

                //$scope.landlords = response.data;
                console.log("API getGlobalPromise");

                q55.resolve(response);

            }
        )
        return q55.promise;
    }

    $scope.paymentTransactionsFunction = function(id, status) {
        console.log("function paymentTransactionsFunction");

        //$scope.paymentTransactionsData.search = "*";

        var q2 = $q.defer();
        console.log($scope.paymentTransactionsData);

        if(status == 2){
            q2.resolve($state.go('lettings-main-nav.financials-side.payment-transaction-edit', {id:  id}));

        }else{
            console.log("$rootScope.decodedToken.Clouderty_System_Administrator" + $rootScope.decodedToken.Clouderty_System_Administrator);
            $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
            $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
            $scope.paymentTransactionsData.itemsPerPage = $scope.itemsPerPage;
            $scope.paymentTransactionsData.pageno = $scope.pageno;
            $scope.paymentTransactionsData.Status = status;

            if($scope.paymentTransactionsData.Payment_Transaction_ID){
                console.log("$scope.paymentTransactionsData.Payment_Transaction_ID" + $scope.paymentTransactionsData.Payment_Transaction_ID);
            }else{
                $scope.paymentTransactionsData.Payment_Transaction_ID = "*";
            }


            if($scope.search){
                $scope.paymentTransactionsData.search = $scope.search;
            }else{
                $scope.paymentTransactionsData.search = "*";
            }

            apiService.paymentTransactions(id, $scope.paymentTransactionsData).then(
                function (response) {
                    $scope.currentPage = $scope.pageno;
                    $scope.totalCount = response.data.totalCount;
                    $scope.paymentTransactions = response.data;
                    console.log("API Service: paymentTransactions");
                    q2.resolve(response);
                },
                function () {
                    console.log("There was an error in paymentTransactions");
                }
            )
        }


        return q2.promise;
    }

    $scope.getData = function(pageno) {
        //$scope.tenancies = [];
        //$scope.paymentTransactions = [];
        $scope.paymentTransactions.totalCount = null;

        //$scope.paymentTransactionsData.Status = '0';

        $scope.currentPage = pageno;
        $scope.pageno = pageno;
        $scope.paymentTransactionsFunction("*","0");

    }

    $scope.getPaymentTypes = function () {
        $scope.payment_transaction.Payment_Transaction_Type_ID = $scope.payment_transaction.paymentTransactionTypes.Payment_Transaction_Type_ID;
        //$scope.payment_transaction.Payment_Transaction_Type_Description = $scope.payment_transaction.paymentTransactionTypes.Description;
        //console.log("Payment_Transaction_Type_ID: " + $scope.payment_transaction.Payment_Transaction_Type_ID);
        //console.log("Payment_Transaction_Type_Description: " + $scope.payment_transaction.Payment_Transaction_Type_Description);
        //console.log("$rootScope.property.Property_name : " + $rootScope.property.Property_name);
        //console.log("$rootScope.property.Unit_number : " + $rootScope.property.Unit_number);

        //$scope.payment_transaction.Property_name = $rootScope.property.Property_name;
        //$scope.payment_transaction.Unit_number = $rootScope.property.Unit_number;
        //$scope.unitText = " U " + $rootScope.property.Unit_number;


        //$scope.createPaymentIDPaymentTypePropertyBusiness();
    }

    $scope.getDataSearch = function(pageno) {

        console.log("inside function getDataSearch");

        $scope.currentPage = pageno;
        $scope.pageno = pageno;
        $scope.paymentTransactionsData.pageno = pageno;
        $scope.paymentTransactionsData.search = $scope.search;

        $scope.paymentTransactions.totalCount = "";
        $scope.paymentTransactions.data = [];

        console.log("$scope.search >>>>> " + $scope.search);


        $scope.paymentTransactionsFunction("*", 7);
        /*
        apiService.getPaymentTransactionsSearch($scope.itemsPerPage, pageno, $scope.search, $rootScope.decodedToken.Account_Holder_ID).then(
            function (response) {

                console.log("Test B");
                //if( $scope.a = authenticateUserLevelService.authenticateUserLevelService($rootScope.decodedToken.User_Level, 1, "View payment transactions") == "Exit"){ return; }
                $scope.paymentTransactions = response.data;
                $scope.currentPage = pageno;
                $scope.totalCount = response.data.totalCount;
                console.log("getPaymentTransactionsSearch")

            },
            function () {
                console.log("There was an error in getPaymentTransactionsSearch")
            }
        )
        */

    }

    $scope.paymentReceivedAccountsDept = function(cpTransactionID, received) {

        if (confirm('Are you mark the payment receipt as received by accounts?')) {

            console.log("Test 1");

            console.log("Transaction ID: " + cpTransactionID);

            if(received == "true"){
                var Received = 1;

                var subjectText = "Payment received by accounts";
                var subject = "Clouderty | " + subjectText + "  | " + $scope.paymentTransactions.Description + " | Created by: " + $rootScope.decodedToken.Name + "| Cash Payment ID: " + $scope.paymentTransactions.CP_Transaction_ID;

            }else if(received == "false"){
                var Received = 0;
                var subjectText = "Payment not received by accounts";
                var subject = "Clouderty | " + subjectText + " | " + $scope.paymentTransactions.Description + " | Created by: " + $rootScope.decodedToken.Name + "| Cash Payment ID: " + $scope.paymentTransactions.CP_Transaction_ID;

            }
            apiService.updatePaymentTransactionReceived(cpTransactionID, Received, $rootScope.decodedToken.Account_Holder_ID, $scope.decodedToken.Clouderty_System_Administrator).then(
                function (response) {

                    //$scope.paymentTransactions = {};
                    console.log("Received: " + Received);
                    $scope.paymentTransactionsEmail = response.data.data2;

                    var from_email = "Clouderty | Payment received by accounts | " + $scope.paymentTransactionsEmail.Description + " |" + " <notifications@clouderty.co.uk>";
                    var message = "Payment ID: " + $scope.paymentTransactionsEmail.Payment_ID + "\n" +
                        "Transaction ID: " + $scope.paymentTransactionsEmail.Payment_Transaction_ID + "\n" +
                        "Card payment reference: " + $scope.paymentTransactionsEmail.Amount + "\n" +
                        "Created By: " + $rootScope.decodedToken.Name + "\n" +
                        "Date payment received: " + $scope.paymentTransactionsEmail.Date + "\n\n" +
                        "Date of notification: " + Date()+ "\n\n" +
                        "Category: " + $scope.paymentTransactionsEmail.Category + "\n" +
                        "Payment amount: " + $scope.paymentTransactionsEmail.Amount;

                    var to_email = $rootScope.decodedToken.Accounts_Email;
                    $rootScope.sendEmail(from_email, to_email, subject, message, $rootScope.decodedToken.Name);

                    $scope.postUserActivityServiceMessage = subject;
                    $scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, $scope.postUserActivityServiceMessage);
                    alert(subjectText);
                    /*
                        $http.post("api/payment-transactions-update/" + cpTransactionID + "/" + '1' + "/" + $rootScope.decodedToken.Account_Holder_ID).success(function(response){


                        });
                    */


                },
                function () {
                    console.log("There was an error in API updatePaymentTransactionReceived")
                }
            )



            console.log("Route reload ");
            //$state.reload("lettings-main-nav.financials-side.payment-transactions-search");

        }
        else{
            console.log("Transaction ID: " + cpTransactionID);


            $http.post("api/payment-transactions-search/" + cpTransactionID + "/" + '-1').success(function(response){
            });

        }

    }

    $scope.editPaymentTransaction = function(paymentTransactionID) {

        console.log("Payment Transaction ID = " + paymentTransactionID);

        var url = $state.href('lettings-main-nav.financials-side.payment-transaction-edit', {id:  paymentTransactionID});
        window.open(url,'_blank');
        //$state.go('lettings-main-nav.financials-side.payment-transaction-edit', {id:  paymentTransactionID});

        //$scope.a = postUserActivityService.postUserActivityService($rootScope.decodedToken.id, 'Edit Tenancy: ' +  $scope.tenancy.Property_name + ' - ' + $scope.tenancy.First_Name + ' - ' + $scope.tenancy.Tenancy_ID);

       // console.log("apiService.searchTenancy");

    }

    $scope.actionPaymentTransactions = function(actionPaymentTransactionsSelect, Payment_Transaction_ID){

        if(actionPaymentTransactionsSelect == "1") {
            if(confirm("Do you want to remove this transaction?")) {
                console.log("Delete transaction");
                var q = $q.defer();
                $scope.promise = $scope.getGlobalPromise();

                $scope.promise
                    .then(function (data) {
                        console.log("A1");
                        $scope.paymentTransactionsData.Clouderty_System_Administrator = $rootScope.decodedToken.Clouderty_System_Administrator;
                        $scope.paymentTransactionsData.Account_Holder_ID = $rootScope.decodedToken.Account_Holder_ID;
                        $scope.paymentTransactionsData.Payment_Transaction_ID = Payment_Transaction_ID;
                        $scope.paymentTransactionsFunction("*", "3");
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {

                        console.log("A2");

                        $scope.getData();
                        return $scope.getGlobalPromise(data);
                    })
                    .then(function (data) {
                        console.log('A7');
                        $scope.myValue = data;
                    }, function (err) {
                        $scope.myValue = err;
                    })

                q.resolve($scope.promise);




                //$state.go('lettings-main-nav.tasks-side.task-edit', {id: $scope.obj.Task_ID, accountHolderID: $rootScope.decodedToken.Account_Holder_ID, cloudertySystemAdministrator: $rootScope.decodedToken.Clouderty_System_Administrator});
            }
        }else if(actionPaymentTransactionsSelect == "2") {

            console.log("Edit Payment Transaction");

            //$scope.editPaymentTransaction(Payment_Transaction_ID);
            $scope.paymentTransactionsFunction(Payment_Transaction_ID, "2")

        }else {

        }
    };

    $scope.paymentTransactionsData.Status = '0';
    $scope.getData($scope.pageno);

    $scope.sort = function(keyname){
        $scope.sortKey = keyname;   //set the sortKey to the param passed
        $scope.reverse = !$scope.reverse; //if true make it false and vice versa
    }

    $scope.open = function (size) {
        $uibModal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'views/modals/email-confirm.html',
            controller: 'emailConfirmCtrl',
            size: size,
            resolve: {
                obj: function () {
                    return $scope.obj;
                }
            }
        });
    };

    $scope.propertiesViewAll = function () {

        apiService.getProperties($rootScope.decodedToken.Account_Holder_ID).then(
            function(response) {

                $scope.properties = response.data.data;
                console.log("API propertiesViewAll: " );
            },
            function() {
                console.log("We are in propertiesViewAll");
            }
        )

    };

    $scope.getPropertyPaymentTransactions = function(property) {
        console.log("inside getPropertyPaymentTransactions - Property ID: " + property);
        console.log(property);


        $scope.paymentTransactionsData.Property_ID = property;
        $scope.Property_ID = property;
        $scope.status = 88;
        $scope.paymentTransactions = [];
        $scope.paymentTransactionsFunction(property.Property_ID, $scope.status);
        //$scope.tenanciesFunction(0, 88);
        //$scope.getData(1);

    }

    $scope.propertiesViewAll();
    $scope.getPaymentTypes();

}]);